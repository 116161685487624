//import logo from './logo.svg';
import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import logo from './images/logo.png';


class Home extends Component {
  render() {
    return (
      <Jumbotron>
        <h1 className="home-app-name">
          COF
          <img src={logo} alt="FinanZero logo" />
        </h1>
      </Jumbotron>
    );
  }
}

export default Home;
