import {Meta} from "../../types/metas";

export const cronTasks : Meta = {
  "switchStatus": {
    "path": "switchStatus",
    "label": "Ações",
    "section": "no-section",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": () => false,
  },
  "_id": {
    "path": "_id",
    "label": "id",
    "section": "no-section",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "name": {
    "path": "name",
    "label": "Nome",
    "section": "Task",
    "dataType": "string",
    "inputType": "text",
    "tableProps": {
      "shortLabel": "Task",
      "width": '30ch',
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": 'left',
      "isKey": true,
    },
    "isMandatory": (application) => true,
  },
  "description": {
    "path": "description",
    "label": "Descrição do evento",
    "section": "Task",
    "dataType": "string",
    "inputType": "text",
    "tableProps": {
      "viewsTable": "",
      "width": "60ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": 'left',
    },
    "isMandatory": (application) => true
  },
  "disabled": {
    "path": "disabled",
    "label": "Desabilitado",
    "section": "Task",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": "true", "description": "Sim" },
        { "code": "false", "description": "Não" }
      ],
    },
    "tableProps": {
      "shortLabel": "",
      "filter":{
        "type": 'SelectFilter',
        "options": "filters.disabled"
       },
       "viewsTable": "",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => true,
  },
  "schedule.schedule": {
    "path": "schedule",
    "label": "Agendamento",
    "section": "no-section",
    "dataType": "map",
    "inputType": "text",
    "tableProps": {
      "viewsTable": "",
      "width": "25ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "schedule.min": {
    "path": "schedule.min",
    "label": "Minutos",
    "section": "schedule",
    "dataType": "string",
    "inputType": "text",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (value) => false,
    "pattern": /^(?!\s*$).+/,
    "validationMessage": "Estrutura de cron, caso queira omitir este campo o valor deve ser * ",
    "tip": "Estrutura de CRON",
  },
  "schedule.hour": {
    "path": "schedule.hour",
    "label": "Horas",
    "section": "schedule",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (value) => false,
    "pattern": /^(?!\s*$).+/,
    "validationMessage": "Estrutura de cron, caso queira omitir este campo o valor deve ser * ",
    "tip": "Estrutura de CRON",

  },
  "schedule.day": {
    "path": "schedule.day",
    "label": "Dia",
    "section": "schedule",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (value) => false,
    "pattern": /^(?!\s*$).+/,
    "validationMessage": "Estrutura de cron, caso queira omitir este campo o valor deve ser * ",
    "tip": "Estrutura de CRON",
  },
  "schedule.month": {
    "path": "schedule.month",
    "label": "Mês",
    "section": "schedule",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (value) => false,
    "pattern": /^(?!\s*$).+/,
    "validationMessage": "Estrutura de cron, caso queira omitir este campo o valor deve ser * ",
    "tip": "Estrutura de CRON",
  },
  "schedule.weekDay": {
    "path": "schedule.weekDay",
    "label": "Dia da semana",
    "section": "schedule",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (value) => false,
    "pattern": /^(?!\s*$).+/,
    "validationMessage": "Estrutura de cron, caso queira omitir este campo o valor deve ser * ",
    "tip": "Estrutura de CRON",
  },
  "action.target": {
    "path": "action.target",
    "label": "Target",
    "section": "action",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": [
        { "code": "sqs", "description": "SQS" },
        { "code": "post-api", "description": "post-api" },
        { "code": "get-api", "description": "get-api" }
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "11ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "left",
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "action.queueName": {
    "path": 'action.queueName',
    "label": "Fila",
    "section": "action",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "14ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "action.path": {
    "path": "action.path",
    "label": "Path da ação",
    "section": "action",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "50ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "action.body": {
    "path": "action.body",
    "label": "body",
    "section": "body",
    "dataType": "json",
    "inputType": "code",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "width": "15ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
};


