import React, { Component } from 'react';
import { FzCard, FzRow, FzCol, FzSplitLine } from '../ui/fz/layout/index';
import FieldConnected from '../ui/components/form/field-connected';
import messagesMetadata from '../biz/metadatas/message-metadata';
import { communication_test } from '../biz/metadatas/message-metadata';
import { FzModal } from '../ui/fz/fz-modal'
import api from '../api/client-api';
import { getCookie } from '../api/helpers';
import { sendNotificationsTest } from '../api/client-messages';

export default class MessageRuleDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trace: true,
      showTestEmail: false
    }
  }

  createStructure(props) {
    const { ns, message } = props;
    const fieldPaths = this.getFieldPathsFromMetadata();
    const fields = fieldPaths.map(field => {
      return (
        <FieldConnected
          key={field}
          meta={messagesMetadata[field]}
          data={message}
          ns={ns}
        />
      );
    });

    return this.buildForm(fields);
  }

  buildForm(fields) {
    let rows = [];
    let lastRow = [];
    fields.forEach(element => {
      lastRow.push(
        <FzCol span={6} key={element.key}>
          {element}
        </FzCol>
      );

      if (lastRow.length >= 2) {
        rows.push(lastRow);
        lastRow = [];
      }
    });

    // If the 'lastRow' variable is not empty, it means that there is a missing row in the 'rows' variable
    if (lastRow.length > 0) {
      rows.push(lastRow);
      lastRow = [];
    }

    const form = rows.map((rowContent, index) => {
      return <FzRow key={`${index}content`}>{rowContent}</FzRow>;
    });
    return form;
  }

  getFieldPathsFromMetadata() {
    let fieldPaths = [];
    for (const key in messagesMetadata) {
      if (messagesMetadata.hasOwnProperty(key) && key !== '_id') {
        fieldPaths.push(key);
      }
    }
    return fieldPaths;
  }


  render() {
    const form = this.createStructure(this.props);
    const { message } = this.props

    return (
      <FzCard variant="primary">
        <FzCard.Heading>
          <FzSplitLine>
            <FzSplitLine.Left>
              <FzCard.Title>Editar dados da Regra de Mensagens</FzCard.Title>
            </FzSplitLine.Left>
            <FzSplitLine.Right>
              <NotificationTest
                nameEvent={message.event}
                ns={this.props.ns}
                message={this.props.message}
              />
            </FzSplitLine.Right>
          </FzSplitLine>
        </FzCard.Heading>

        <FzCard.Body>
          {form}
        </FzCard.Body>
      </FzCard>
    );
  }
}

class NotificationTest extends React.Component {
  constructor(props) {
    super(props)

    this.sendComunicationTest = this.sendComunicationTest.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)    
  }

  sendComunicationTest() {
    const { ns, message } = this.props
    const user = api.getCurrentUser()

    const payload = {
      "ContextParams": {
        "applicationQuality": "A",
        "bidQuality": "A",
        "token": getCookie("fz-cof-token"),
        "userQuality": "A"
      },
      "Event": message.event,
      "Sent": new Date(),
      "UserId": user.id,
      "Test": true,
      ...Object.keys(communication_test).reduce((resultSoFar, current) => {
        if(ns.getChanged(current)) {
          return { ...resultSoFar, [current]: ns.getChanged(current) }
        }
        return resultSoFar
      }, {})  
    }
    
    if (payload.Iteration === undefined) {
      delete payload.Iteration;
    }

    sendNotificationsTest(payload)
      .then(() => {
        ns.saveChange("ApplicationId", "")
        ns.saveChange("BidId", "")
        ns.saveChange("ContextParams.product", "")
        alert("Mensagens enviadas com sucesso")
      })
      .catch((error) => {
        console.error("Erro ao tentar enviar teste" + error);
      })
  }
  handleButtonClick() {
    const { ns } = this.props
    const user = api.getCurrentUser()
    if (ns.isEditing()) {
      const base = ns.getChanged()
      const d = {
        ...base,
        Iteration: 1,
        Event: base.event,
        UserId: user.id,
        Sent: base.created,
        TestTelephone: user.telephoneNumber,
        TestEmail: user.email,
      }
      ns.startEditing(d)
    } else {
      const base = ns.get("message")
      const data = {
        ...base,
        Iteration: 1,
        Event: base.event,
        UserId: user.id,
        Sent: base.created,
        TestTelephone: user.telephoneNumber,
        TestEmail: user.email,
      }
      ns.startEditing(data)
    }
  }

  render() {
    const { ns, nameEvent } = this.props
    const data = ns.getChanged()

    return (
      <FzModal
        headerTitle={`Teste de regra geral do evento: ${nameEvent}`}
        containerStyle={{ marginTop: "50px" }}
        handleSuccessButton={this.sendComunicationTest}
        successText="Enviar"
        showButtonText="Testar Mensagem"
        handleButtonClick={this.handleButtonClick}
      >
        <FieldConnected meta={communication_test['ApplicationId']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['ContextParams.product']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['BidId']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['Iteration']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['Event']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['UserId']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['Sent']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['TestTelephone']} data={data} ns={ns} />
        <FieldConnected meta={communication_test['TestEmail']} data={data} ns={ns} />
      </FzModal>
    )
  }
}
