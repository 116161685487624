import React, { useState, useEffect } from 'react';

import {
  dashboardFormalizationStats, capacityReviewPivotMeta
} from "../../../biz/metadatas/capacity-review";
import { setTTL } from '../../../business/misc-functions';
import { FzCard } from '../../fz/layout/FzCard';
import { StatsDetailHeader } from "../../../statistics/stats-components";
import { periodColumn } from "../../../components/table-standard";
import clone from "lodash/cloneDeep"
import { getFomalizationDashboard } from "../../../api/formalization";
import { FzTable } from "../../fz/grid/table";
import { FzCheckbox } from "../../fz/form";


interface CapacityReviewProps {
  ns: any;
  toggleBlocking: (
    callback?: any
  ) => void;
}

function getTotalCapacity(rawData) {
  const data = rawData.map(eachDay => {
    const countersValues = eachDay.counters.map(it => {
      const total = it[1] + it[2] + it[6] + it[8];
      const total_P = (Number(it["1_P"].replace("%", "")) +
        Number(it["2_P"].replace("%", "")) +
        Number(it["6_P"].replace("%", "")) +
        Number(it["8_P"].replace("%", ""))).toFixed(1).toString() + "%"
      return { ...it, total, total_P };
    });
    return { ...eachDay, "counters": countersValues }
  })
  return data
}

function buildPeriodFilters(statisticsData) {
  let periods = {};
  if (statisticsData) {
    for (var i = 0; i < statisticsData.length; ++i) {
      let p = statisticsData[i]._id;
      if (p && !periods[p]) {
        periods[p] = periodColumn(p);
      }
    }
  }
  return periods;
};

export function CapacityReview({ ns, toggleBlocking }: CapacityReviewProps) {

  const [currPeriod, setCurrPeriod] = useState(false)
  const [checked, setChecked] = useState(false)
  const [requestState, setRequestState] = useState("idle")

  useEffect(() => {

    getStatistics()
  }, [])

  async function getStatistics() {
    try {
      setRequestState("requesting")
      toggleBlocking();
      const data = await getFomalizationDashboard(); // getFilters()
      data.statistics = getTotalCapacity(data.statistics);
      ns.set("capacity-review", data, setTTL(5))
      ns.set("filter_periods", buildPeriodFilters(data.statistics), null)
      toggleBlocking();
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      toggleBlocking()
    }
  }

  function pivotData(data, metas, pivotMetas, filterColumnName, filterValue, pivotColumnName) {

    let rows = ["1", "2", "6", "8", "4", "16", "32", "32768", "total"]
    if (checked) {
      rows = ["1_P", "2_P", "6_P", "8_P", "4_P", "16_P", "32_P", "32768_P", "total_P"]
    }

    let filteredData = data.filter((it) => {
      return it[filterColumnName] === filterValue
    });

    let pivotColumns
    if (filteredData.length > 0) {
      pivotColumns = filteredData[0].counters.map(it => it._id).sort()
    } else {
      return null
    }

    pivotValues = []
    if (filteredData.length) {
      rows.forEach((col, k) => {
        let v = { pivot: metas[col].label, key: k }
        pivotColumns.forEach(pivot => {

          v[pivot] = filteredData[0].counters.filter(it => it[pivotColumnName] === pivot)
            .reduce((previous, it) => {
              return it[col] + previous
            }, 0)
        })
        pivotValues.push(v)
      })
    }

    resultMetas = clone(pivotMetas)
    pivotColumns.forEach(col => {
      let newCol = clone(pivotMetas["template"])
      newCol.path = col
      newCol.label = col
      newCol.tableProps.hidden = false
      resultMetas[col] = newCol
    })
  }


  function buildMetaForPercentage2Pivot(metaBase) {
    const cloneMeta = clone(metaBase);
    const metaForPercentage = Object.keys(cloneMeta)
      .reduce((resultSoFar, key) => {

        const metaField = cloneMeta[key];
        if (key === "template") {
          metaField.tableProps.columnFormat = "normalizePercentageDataForPageCapacityReview"
        }
        return { ...resultSoFar, [key]: metaField };
      }, {});
    return metaForPercentage;
  }

  function buildMetaForPercentage(metaBase) {
    const cloneMeta = clone(metaBase);
    const metaForPercentage = Object.keys(cloneMeta).reduce((resultSoFar, key) => {

      if (key === "_id") {
        return { ...resultSoFar };
      }

      const metaField = cloneMeta[key];
      metaField.path = metaField.path + "_P";
      return { ...resultSoFar, [key + "_P"]: metaField };
    }, {});
    return metaForPercentage;
  }

  function trClassName(row, rowIndex) {
    return rowIndex < 4 ? true : false;
  }

  function handleChangeCheckbox(value) {
    setChecked(value)
  }

  function handleChangePeriod(value) {
    setCurrPeriod(value)
  }


  let pivotValues
  let resultMetas
  let data = ns.get("capacity-review");
  if (!data && requestState === "requesting") {
    return null
  }

  let filterValues = ns.get("filter_periods")
  console.log(filterValues)
  if (!filterValues) {
    return null
  }

  let selectedPeriod = currPeriod || Object.keys(filterValues || { "": "" })[0]

  let pivotMeta = capacityReviewPivotMeta
  let meta = dashboardFormalizationStats

  if (checked) {
    pivotMeta = buildMetaForPercentage2Pivot(capacityReviewPivotMeta)
    meta = buildMetaForPercentage(dashboardFormalizationStats)
  }


  pivotData(data.statistics, meta, pivotMeta, "_id", selectedPeriod, "_id")

  let data_table_1
  let data_table_2
  let meta_table_1
  let meta_table_2
  if (pivotValues && resultMetas) {
    data_table_1 = pivotValues.filter(it => it.pivot === "Total Elegíveis")
    data_table_2 = pivotValues.filter(it => it.pivot !== "Total Elegíveis")
    meta_table_1 = clone(resultMetas)
    meta_table_1["pivot"].label = "Capacity Review"
    meta_table_2 = resultMetas

  }

  return (
    <FzCard>
      <FzCard.Header>
        <FzCard.Title>
          <StatsDetailHeader
            title={'Formalization Dashboard'}
            stats={data}
            periods={filterValues}
            currPeriod={selectedPeriod}
            onChange={handleChangePeriod} />
        </FzCard.Title>
      </FzCard.Header>
      <FzCard.Body>
        <FzCard>
          <FzCard.Body>
            <FzCheckbox checked={checked} inline={true} onChange={handleChangeCheckbox}>Visualização porcentagem</FzCheckbox>
            <FzTable
              ns={ns}
              data={data_table_1}
              metas={meta_table_1}
              exportDownload={false}
              clipboard={false}
              rowDisabled={trClassName}
            />
            <br />
            <FzTable
              ns={ns}
              data={data_table_2}
              metas={meta_table_2}
              exportDownload={false}
              clipboard={false}
              rowDisabled={trClassName}
            />
          </FzCard.Body>
        </FzCard>
      </FzCard.Body>
    </FzCard>
  )
}




