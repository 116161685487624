const buildFilterWithParam = (data, metas) => {};

const buildFilter = data => {
  let keyList = {};
  if (data.length === 0) return {};

  for (let index = 0; index < data.length; index++) {
    keyList[data[index].event] = data[index].event;
  }
  return keyList;
};

export default {
  buildFilterWithParam,
  buildFilter,
};
