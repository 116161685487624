import { useState, useEffect } from 'react'
import moment from 'moment'
import api from '../../../../api/client-api'
import { BoaVistaReportsContainer } from '../../../../Containers/BoaVista/index'
import { getAnalyticsBoaVistaReports, getValidatorsBoaVistaReports } from '../../../../services/api/bureau/bureau'; // TODO refactor name duplication
import { filterDomainDescription } from '../../../../utils/filterDomainDescription';

function BoaVistaReports({ ns }) {
  const [typeView, setTypeView] = useState('');
  const [data, setData] = useState<any>({ statistics: [] });
  const [showTost, setShowTost] = useState(false);
  const [documentDomains, setDocumentDomains] = useState([])

   useEffect(() => {
    ns.startEditing({
      "from": moment().subtract(10, "days").startOf("day").toISOString(),
      "to": moment().endOf("day").toISOString()
    });
    getDomains()
  }, []);
  
  async function getDomains() {
    let documentDomains = await api.domains.getDomainValues("formalization-documents")

    documentDomains = filterDomainDescription(documentDomains)

    setDocumentDomains(documentDomains)
  }

  const handlePage = (pageType: string): void => {
    setTypeView(pageType);
  }

  async function getSummaraiesData(period: string) {

    try {
      const response = await getValidatorsBoaVistaReports(period)
      setData(response.data);
    } catch (error) {
      setShowTost(true);
    }

  }

  async function getAnalyticsData(filter: any) {
    try {
      let analyticsResult = await getAnalyticsBoaVistaReports(filter)
      setData({ statistics: analyticsResult.data });
    } catch (error) {
      setShowTost(true)
    }
  }

  const handleSearchReport = (filter) => {

    if (typeView === "summaries") {
      getSummaraiesData(filter.period);
    } else {
      getAnalyticsData(filter);
    }
  }

  const { role = 'no-role' } = api.getCurrentUser()
  const filters = ns.getChanged()
  const handleTostClose = () => setShowTost(false)

  return (
    <BoaVistaReportsContainer
      ns={ns}
      handlePage={handlePage}
      typeView={typeView}
      handleSearchReport={handleSearchReport}
      data={data}
      role={role}
      filters={filters}
      showTost={showTost}
      handleTostClose={handleTostClose}
      documentDomains={documentDomains}
    />
  )
}

export { BoaVistaReports }