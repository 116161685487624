
// Imports
import { Modal } from 'react-bootstrap';
import get from 'lodash/get';
import { formatMinutesSeconds } from '../../../ui/fz/formatter/column-formatter';


const ModalQueueDetails = ({showModal,hideModal,infoModal}) => {

  const metaOrder = {
    "1" : "First in - first out",
    "-1" :"Last in - first out",
  }

  function showProducts(){
    let products = get(infoModal, "products")
    return products ? products.map((p)=>{return p+";"}) : ""
  }

  function showCadencia(){
    let cadencia = get(infoModal, "cadencia")
    return cadencia ? cadencia.map((c)=>{return "D+"+c+";"}) : ""
  }

  function showOrder(){
    let order = get(infoModal, "orderBy") + ""
    return metaOrder[order.toString()]
  }

  function showSuppliers(){
    let suppliers = get(infoModal, "suppliers")
    return suppliers ? suppliers.map((supplier,i)=>{
      if (typeof supplier == "string") {
        const supArr = supplier.split("|");
        return <p key={i} style={{marginBottom:"5px"}}>{supArr[0]}/Incluir/{supArr[1]}</p>
      } else {
        const branches = supplier["branches"].join(' | ')
        return <p key={i} style={{marginBottom:"5px"}}>{supplier.supplier}/Incluir/{branches}</p>
      }
    }) : ""
  }

  function showEvents(){
    let events = get(infoModal, "events")
    return events ? events.map((event,i)=>{return <p key={i} style={{marginBottom:"5px"}}>{event}</p>}) : ""
  }

  return (
    <div style={{padding: "20px"}}>

        <Modal style={{zIndex : "10000"}} centered show={showModal} onHide={hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div style={{width:"100%",textAlign:"center"}}>Detalhes da Fila</div>
              <div style={{width:"100%",textAlign:"center",fontSize:"20px"}}>{`${get(infoModal, "queueName")} - Application e Data de Criação`}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{fontWeight:"bold",margin:"0px"}}>Consultor parametrizado</p>
              <p>{get(infoModal, "linkedUsers")}</p>
              <p style={{fontWeight:"bold",margin:"0px"}}>Delay de entrada do cliente</p>
              <p>{formatMinutesSeconds(get(infoModal, "delay"))}</p>
              <p style={{fontWeight:"bold",margin:"0px"}}>Validade em dias (tickets)</p>
              <p>{get(infoModal, "validade")}</p>
              <p style={{fontWeight:"bold",margin:"0px"}}>Cadência</p>
              <div style={{marginBottom:"10px"}}>{showCadencia()}</div>
              <p style={{fontWeight:"bold",margin:"0px"}}>Produto</p>
              <div style={{marginBottom:"10px"}}>{showProducts()}</div>
              <p style={{fontWeight:"bold",margin:"0px"}}>Parceiro/Inclusão-Exclusão/Branch</p>
              <div style={{marginBottom:"10px"}}>{showSuppliers()}</div>
              <p style={{fontWeight:"bold",margin:"0px"}}>Ordenação</p>
              <div style={{marginBottom:"10px"}}>{showOrder()}</div>
              <p style={{fontWeight:"bold",margin:"0px"}}>Eventos</p>
              <div style={{marginBottom:"10px"}}>{showEvents()}</div>
            </div>
          </Modal.Body>
        </Modal>

    </div>
  )
}

export { ModalQueueDetails }