import moment from 'moment';
import { Meta } from "../../../../types/metas";

export const overwriteDocumentsMeta: Meta = {
  'period': {
    "path": "period",
    "label": "Period",
    "dataType": "date",
    "tableProps": {
      rowSpan: "2",
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
  },
  'supplier': {
    "path": "supplier",
    "label": "Parceiro",
    "dataType": "string",
    "tableProps": {
      rowSpan: "2",
      'isKey': true,
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  'screener': {
    "path": "screener",
    "label": "Screener",
    "dataType": "string",
    "tableProps": {
      rowSpan: "2",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "width": "22ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  'total': {
    "path": "total",
    "label": "Total de Docs Alterados",
    "dataType": "number",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "count-address": {
    "path": "count-address",
    "label": "C. Endereço",
    "dataType": "string",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "C. Endereço",
            dataAlign: "left",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "width": "16ch",
            "columnFormat": "amountColumn",
          },
          {
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "16ch",
          }]
      },
      "columnFormat": "amountColumn",
      "formatExtraData": "total",
    },
  },
  "count-income": {
    "path": "count-income",
    "label": "C. Renda",
    "dataType": "string",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "C. Renda",
            dataAlign: "left",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "16ch",
          }]
      },
      "columnFormat": "amountColumn",
      "formatExtraData": "total",
    },
  },
  "count-income60": {
    "path": "count-income60",
    "label": "C. Renda (60d)",
    "dataType": "string",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "C. Renda (60d)",
            dataAlign: "left",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "16ch",
          }]
      },
      "columnFormat": "amountColumn",
      "formatExtraData": "total",
    },
  },
  "count-income90": {
    "path": "count-income90",
    "label": "C. Renda (90d)",
    "dataType": "string",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            "label": "C. Renda (90d)",
            dataAlign: "left",
            row: '0',
            colSpan: '2',
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "16ch",
          }]
      },
      "columnFormat": "amountColumn",
      "formatExtraData": "total",
    },
  },
  "count-rg-front": {
    "path": "count-rg-front",
    "label": "RG frente",
    "dataType": "string",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            "label": "RG frente",
            dataAlign: "left",
            row: '0',
            colSpan: '2',
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "16ch",
          }]
      },
      "columnFormat": "amountColumn",
      "formatExtraData": "total",
    },
  },
  "count-rg-back": {
    "path": "count-rg-back",
    "label": "RG verso",
    "dataType": "string",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            "label": "RG verso",
            dataAlign: "left",
            row: '0',
            colSpan: '2',
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "16ch",
          }]
      },
      "columnFormat": "amountColumn",
      "formatExtraData": "total",
    },
  },
}

export const overwriteDocumentAnalyticsMeta: Meta = {
  'applicationId': {
    "path": "applicationId",
    "label": "App Id",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      'isKey': true,
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  'dateAction': {
    "path": "dateAction",
    "label": "Data da alteração",
    "dataType": "date",
    "tableProps": {
      "width": "10em",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
  },
  'screener': {
    "path": "screener",
    "label": "Screener",
    "dataType": "string",
    "tableProps": {
      "filter": { "type": 'TextFilter', "delay": 500 },
      "width": "22ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  'documentType': {
    "path": "documentType",
    "label": "Documento",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getDescriptionsByDomains",
      "formatExtraData": "formalization-documents",
    },
  },
  'supplier': {
    "path": "supplier",
    "label": "Parceiro",
    "dataType": "string",
    "tableProps": {
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  'screenedCount': {
    "path": "screenedCount",
    "label": "Numero de Passagem",
    "dataType": "number",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  }
}

export const panelOverwriteDocuments: Meta = {
  "createdAfter": {
    "path": "createdAfter",
    "label": "De:",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    isMandatory: (app) => false
  },

  "createdBefore": {
    "path": "createdBefore",
    "label": "Até:",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    isMandatory: (app) => false
  },
}