import { Meta } from "../../types/metas";

export const InputLayoutForm = {
  name: {
    path: 'name',
    dataType: 'text',
    label: 'Nome',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  supplierInternalName: {
    path: 'supplierInternalName',
    dataType: 'text',
    label: 'Parceiro',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'select',
    section: 'inputLayoutForm',
    inputProps: {
      options: [{ code: "", description: "Selecione o parceiro..." }],
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: (value) => value === "undefined" ? "" : value,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  encoding: {
    path: 'encoding',
    dataType: 'text',
    label: 'Encoding',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  separator: {
    path: 'separator',
    dataType: 'text',
    label: 'Separador',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  numberOfHeaderLines: {
    path: 'numberOfHeaderLines',
    dataType: 'number',
    label: 'Número de Linhas do Cabeçalho',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'number',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  value: {
    path: 'value',
    dataType: 'text',
    label: 'Valor Total',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  installmentAmount: {
    path: 'installmentAmount',
    dataType: 'text',
    label: 'Valor das Parcelas',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  installment: {
    path: 'installment',
    dataType: 'number',
    label: 'Nº de parcelas',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'number',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  interestRate: {
    path: 'interestRate',
    dataType: 'text',
    label: '% de Juros',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  comission: {
    path: 'comission',
    dataType: 'text',
    label: 'Comissão',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  referenceNumberDays: {
    path: 'referenceNumberDays',
    dataType: 'number',
    label: 'Nº de Dias p/ Busca da Conciliação',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'number',
    section: 'inputLayoutForm',
    inputProps: {
      maxValue: 90,
      minValue: 1,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  field_0: {
    path: 'field_0',
    dataType: 'text',
    label: 'Campo',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  type_0: {
    path: 'type_0',
    dataType: 'text',
    label: 'Tipo',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  format_0: {
    path: 'format_0',
    dataType: 'text',
    label: 'Formato',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'select',
    section: 'inputLayoutForm',
    inputProps: {
      options: [
        { code: 'string', description: 'Texto' },
        { code: 'int', description: 'Inteiro' },
        { code: 'date', description: 'Data' },
        { code: 'float', description: 'Decimal' },
        { code: 'bool', description: 'Booleano' },
      ],
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  size_0: {
    path: 'size_0',
    dataType: 'text',
    label: 'Tamanho',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'inputLayoutForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  mapField_0: {
    path: 'mapField_0',
    dataType: 'text',
    label: 'De/Para',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'select',
    filterType: "domain",
    section: 'inputLayoutForm',
    inputProps: {
      domainName: "mapFields-gringotts",
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
}

export const InputLayoutColumns: Meta = {
  "name": {
    "path": "name",
    "label": "Nome",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "encoding": {
    "path": "encoding",
    "label": "Encoding",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "separator": {
    "path": "separator",
    "label": "Separador",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "numberOfHeaderLines": {
    "path": "numberOfHeaderLines",
    "label": "Cabeçalho",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "_id": {
    "path": "_id",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
