import { Application } from "../../biz/metadatas/application"
import { createExportBasedOnMetas } from "./csv-exporter-utils/exporting-utils"

const columnsToExport = [
  "id",
  "deletedReason",
  "deletedCustomerReason",
  "deletedDate",
  "deletedPreviousApplicationState",
]

function getCsvDeletedReport(jsonList) {
  return createExportBasedOnMetas(Application.fields, columnsToExport, jsonList)
}

export { getCsvDeletedReport }
