import React, { PureComponent } from 'react';
import { FzCard, FzBadge } from "../layout";
import { PieChart, Pie, Sector, Cell } from 'recharts';


type FzPieData = {
  name: string;
  [x: string]: string | number
}[]

type FzPieAgent = string

type FzPieDataProps = {
  data: FzPieData,
  agent: FzPieAgent
}


class FzPieChart extends PureComponent < FzPieDataProps, any> {
  constructor(props) {
    super(props)

    this.state = { data: [] }
    this.onPieEnter = this.onPieEnter.bind(this)
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

  render() {
    const { data, agent } = this.props

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count ${value}`}</text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`(Rate ${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };



    return (
      <FzCard>
        <FzCard.Body>
          <FzBadge>{agent}</FzBadge>
          <PieChart width={600} height={500}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx={300}
              cy={200}
              labelLine={false}
              innerRadius={100}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
            >
              {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index]} />)
              }
            </Pie>
          </PieChart>
        </FzCard.Body>
      </FzCard>
    );
  }
}

export { FzPieChart }