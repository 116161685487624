import { validateRequestedAmount, validateRequestedaMaxInstallment } from './metadatas/application'
import { validateAccount } from '../biz/bank-validator'

// value, data, ns, metaField   --> assinatura

const email = async ({ value, data, ns, metaField }) => {
  return emailValid(value)
}

const personalNumber = async ({ value, data, ns, metaField }) => {
  return cpfValid(value)
}

const personName = async ({ value, data, ns, metaField }) => {
  return !(/\\w{2,99}\\s+\\w{2,99}/.test(value))
}

const RG = async ({ value, data, ns, metaField }) => {
 if( !value){
  return false
 }
  let regex = null
  switch (data.RGDocType) {
    case "id":
      regex = /^\d+$/
      break;
    case "passport":
      regex = /^[a-zA-Z]{1,2}.*[0-9]+/
      break;
    case "rne":
      regex = /^[A-Za-z0-9]+-?[A-Za-z0-9]$/
      break;
    case "professionalcouncil":
      regex = /^\d+$/
      break;
    case "driver":
      regex = /[0-9]{4}/
      break;
      case "rg":
    default: 
    regex =/^[\da-z][\da-z.-]{5,}[\da-z]{1}$/
      break;
  }
  const validated = String(value).match(regex)
  return Boolean(validated)
};


const appliedAmount = async ({ value, data, ns, metaField }) => {
  return validateRequestedAmount(value, data)
}

const maxInstallment = async ({ value, data, ns, metaField }) => {
  return validateRequestedaMaxInstallment(value, data)
}

const registrationNumber = async ({ value, data, ns, metaField }) => {

  if (value.length > 0 && value.length <= 8) {
    if (value.indexOf("-") !== -1) {
      return /[A-Z]{3}-[0-9]{4}/.test(value) && !ns.isInvalid('vehicleSecurity.registrationNumber')
    }
    return /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/.test(value) && !ns.isInvalid('vehicleSecurity.registrationNumber')
  }
}

const actionValidation = async ({ value, data, ns, metaField }) => {
  if (metaField.path === "pages.0.action") {
    return value === "new"
  }
  if (metaField.path === `pages.${data.pages.length -1}.action`) {
    return value === "completed"
  }

  return value === "put" || value === "save" || value === "validatePolicies"
}

const householdMonthlyIncome = async ({ value, data, ns, metaField }) => {
  return (/[0-9]+/.test(value) && value >= 3000)
}

const monthlyIncome = async ({ value, data, ns, metaField }) => {
  return (value >= 954 && value <= 60000)
}

const companyRegistrationNumber = async ({ value, data, ns, metaField }) => {
  return CNPJvalid(value)
}

const reference1Name = async ({ value, data, ns, metaField }) => {
  return (value) => (/\\w{2,99}\\s+\\w{2,99}/.test(value))
}

const bankName = async ({ value, data, ns, metaField }) => {
  return value !== '000' && data.accountType !== null
}

const accountNumber = async ({ value, data, ns, metaField }) => {
  if(value.slice(-2, -1) !== "-") {
    return false;
  }

  return validateAccount(value, data).then(() => true).catch(() => false)
}

const vehicleSecurityBrand = async ({ value, data, ns, metaField }) => {
  const brands = ns.getShared("CACHE.domains.brands", [])
  const { modelYear } = data.vehicleSecurity
  if (modelYear && brands && value) {
    return brands.filter(brand => brand.description === value).length > 0
  }
}

const vehicleSecurityModel = async ({ value, data, ns, metaField }) => {
  const models = ns.getShared("CACHE.domains.models", [])
  const { modelYear, brand } = data.vehicleSecurity
  if (modelYear && brand && value) {
    return models.filter(model => model.description === value).length > 0
  }
}

const modelYear = async ({ value, data, ns, metaField }) => {
  const modelYears = ns.getShared("CACHE.domains.model-year", [])
  const { manufacturingYear } = data.vehicleSecurity
  if (manufacturingYear && value) {
    return modelYears.filter(modelYear => modelYear.code === value).length > 0
  }
}

const value = async ({ value, data, ns, metaField }) => {
  const { appliedAmount } = data
  return appliedAmount < Math.floor(value * 0.8)
}


const cpfValid = (value) => {

  var regex = /^\d{11}$/;
  if (!regex.test(value)) {
    return false
  }
  // Now validade CPF check digts
  var real_number, check_digits, sum, i, calculated, all_equal;
  // case 1: all digits are the same
  all_equal = 1;
  for (i = 0; i < value.length - 1; i++) {
    if (value.charAt(i) !== value.charAt(i + 1)) {
      all_equal = 0;
      break;
    }
  }
  if (all_equal) {
    return false;
  }
  // Calculate first chheck-digit
  real_number = value.slice(0, 9);
  check_digits = value.slice(9);
  sum = 0;
  for (i = 10; i > 1; i--) {
    sum += real_number.charAt(10 - i) * i;
  }
  calculated = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (calculated.toString() !== check_digits.charAt(0)) {
    return false;
  }
  // Calculate last chheck-digit
  real_number = value.slice(0, 10);
  sum = 0;
  for (i = 11; i > 1; i--) {
    sum += real_number.charAt(11 - i) * i;
  }
  calculated = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (calculated.toString() !== check_digits.charAt(1)) {
    return false;
  }
  return true
}

const CNPJvalid = (cnpj) => {

  cnpj = cnpj ? cnpj.toString() : '';

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '' || cnpj.length != 14) {
    return false;
  }
  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999") {
    return false;
  }

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
    return false;

  return true;

}

//RFC 2822
const emailValid = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;
  const rrej = /(\.{2,})|(\*{2,})|(\+{2,})/

  return re.test(email) && !rrej.test(email);
}


export default {
  actionValidation,
  email,
  personalNumber,
  personName,
  RG,
  appliedAmount,
  maxInstallment,
  registrationNumber,
  householdMonthlyIncome,
  monthlyIncome,
  companyRegistrationNumber,
  reference1Name,
  bankName,
  accountNumber,
  vehicleSecurityBrand,
  vehicleSecurityModel,
  modelYear,
  value
}