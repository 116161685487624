import React from 'react';
import { getApplicationIntegrationOverview } from '../../../api/applications';
import { FzTable } from '../../fz/grid/table'
import { integrationSummary } from '../../../biz/metadatas/integration-metadata'
import { FzCard } from '../../fz/layout/index';

export default class IntegrationSummary extends React.Component {

  constructor(props) {
    super(props)
    this.state = { requested: false }
    this.getStatistics = this.getStatistics.bind(this)
  }

  getStatistics(channel, supplier, period, ns) {
    this.setState({ requested: true }, function () {
      this.props.toggleBlocking(() => {
        getApplicationIntegrationOverview(channel, supplier, period)
          .then((resultSummary) => {
            ns.set("integrationSummary_" + channel + supplier + period, resultSummary.statistics);
            this.setState({ requested: false }, () => {
              this.props.toggleBlocking()
            });
          })
          .catch((err) => {
            console.warn("API ERROR", err);
            this.props.toggleBlocking()
          });
      })
    });
  }

  render() {
    let { ns, supplier, period,  channel, header } = this.props

    if (!supplier || !period ) {
      return null
    }

    let data = ns.get("integrationSummary_" + channel + supplier + period)
    if (data  === undefined && !this.state.requested) {
      this.getStatistics(channel, supplier, period, ns, "integrationSummary");
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h2">{header}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            exportDownload
            pagination
            ns={ns}
            context={"integrationSummary"}
            instance={supplier}
            data={data || []}
            metas={integrationSummary}
            visibleColumns={["key", "period", "reserved", "reservedAmount", "apiAccepted", "sent", "sentAmount", "rejected", "approved", "limitBids", "fastBids", "expired",
              "approvedAmount", "interestRate", "timeToBid", "userAccepted", "acceptedAmount", "paidAmount", "paid"]}
          />
        </FzCard.Body>
      </FzCard>


    );
  }
}
