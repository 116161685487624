import { ReprocessingSearchMeta, ReprocessingSearchQueryMeta, ItemListType } from '../../biz/metadatas/bifrost/reprocessing-metadata';
import { customLink } from '../../components/table-standard';
import { MetaField } from '../../types/metas';
import { FzIcons } from '../../ui/fz/form/icon';
import { FzTable } from '../../ui/fz/grid/table';
import { FzBadge, FzCard, FzCol, FzRow } from '../../ui/fz/layout';

export const ReprocessingListContainer = ({ ns, reprocessingList, suppliersList }) => {
  return (
    <FzTable
      ns={ns}
      data={reprocessingList}
      metas={ReprocessingSearchMeta}
      pagination={true}
      customColumnFormatters={{
        _id: (cell: string) => customLinkInSamePage('/manager/reprocessing/' + cell, '...' + cell.slice(-6)),
        cardId: (cell: string) => customLink('https://finanzero.atlassian.net/browse/' + cell, cell),
        executed: (cell: string) => booleanBadgeFormatter(cell),
        funnelStage: (cell: string) => funnelStageFormatter(cell),
        supplierInternalName: (_, row) => getSupplierName(row.query.supplierInternalName, suppliersList)
      }}
      exportDownload={false}
      rowExpand={{ expandComponent: (row) => expandRow(row.query, suppliersList) }}
      clipboard={false}
      visibleColumns={['id', 'cardId', 'supplierName','funnelStage', 'created', 'userName', 'executed']}
    />
  )
}

function customLinkInSamePage(url: string, label: string) {
  return (
    <>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a> <a href={url}>{label}</a>
    </>
  )
}

function booleanBadgeFormatter(cell: string) {
  return (
    <FzBadge
      fzStyle={Boolean(cell) ? "success" : "alert"}
    >
      {Boolean(cell) ? "Sim" : "Não"}
    </FzBadge>
  )
}

function funnelStageFormatter(cell: string) {
  const funnelStagesEnum = {
    lead: 'Em cadastro',
    hasBids: 'Aguardando aceite',
    followUp: 'Em formalização',
    archived: 'Paga',
    closed: 'Encerrada',
    excluded: 'Excluída',
  };
  return funnelStagesEnum[cell] || "Etapa inválida"

  // TODO: each funnel stage should have its own color, like this:
  //  lead: { label: 'Em cadastro', color: 'gray' },' },
  //  hasBids: { label: 'Aguardando aceite', color: 'yellow' },
  //  followUp: { label: 'Em formalização', color: 'orange' },
  //  archived: { label: 'Paga', color: 'reen' },
  //  closed: { label: 'Encerrada', color: 'black' },
  //  excluded: { label: 'Excluída', color: 'red' },
}

function expandRow(queryItens: { [x: string]: string | number; }, suppliersList) {
  const content = Object.keys(queryItens).map(item => {
    const metaInfo = ReprocessingSearchQueryMeta[item]
    if (!metaInfo) {
      return
    }
    return (
      <FzCol key={item} span={4}>
        <div style={{ paddingBottom: 10, whiteSpace: 'normal' }}>
          <strong>{metaInfo.label}</strong> {customFormatter(metaInfo, queryItens[item], suppliersList)}
        </div>
      </FzCol>
    )
  })

  return (
    <FzCard>
      <FzCard.Body>
        <FzRow>
          {content}
        </FzRow>
      </FzCard.Body >
    </FzCard>
  )
}

function customFormatter(metaInfo: MetaField, value: string | number, suppliersList: ItemListType[]) {
  switch (metaInfo.dataType) {
    case "dateTime":
      return new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(value));

    case "bool":
    case "boolean":
      return Boolean(value) ? "Sim" : "Não";

    case "string":
      if (metaInfo.path === "supplierInternalName") {
        return getSupplierName(value, suppliersList);
      } else if (Array.isArray(value)) {
        return value.join(", ");
      }
      return String(value);

    default:
      return value
  }
}

function getSupplierName(internalName: string | number, suppliersList: ItemListType[]) {
  const supplier = suppliersList.find(supplier => supplier.code === internalName);
  return supplier ? supplier.description : internalName
}