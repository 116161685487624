import React from 'react'
import api from '../../../api/client-api';
import { getAllSuppliers } from '../../../api/suppliers';
import { Formalizations } from './formalizations'
import { filterDomainDescription } from '../../../utils/filterDomainDescription';

export class Formalization extends React.Component {

  constructor(props) {
    super(props);
    this.state = { documentDomains: [], allSuppliers: {} };
  }

  async componentDidMount() {
    let documentDomains = await api.domains.getDomainValues("formalization-documents")

    documentDomains = filterDomainDescription(documentDomains)

    const allSuppliers = await getAllSuppliers()

    let tmp = {};
    for (let i = 0; i < allSuppliers.length; i++) {
      tmp[allSuppliers[i].internalName] = allSuppliers[i];
    }

    this.setState({ documentDomains, allSuppliers: tmp })
  }

  render() {
    const { ns, app, bid, formalizationData } = this.props

    let formalizationPanel = null
    if (formalizationData) {
      let isAdmin = api.isUserInRole("admin") || api.isUserInRole("advisor") || api.isUserInRole("sales")
      formalizationPanel = (
        <Formalizations
          api={api}
          application={app}
          bid={bid}
          formalization={formalizationData}
          ns={ns}
          isAdmin={isAdmin}
          documentDomains={this.state.documentDomains}
          allSuppliers={this.state.allSuppliers}
        />
      )
    }

    return (
      formalizationPanel
    )
  }
}