import React from 'react'
import { FzCard, FzRow, FzCol } from '../../fz/layout/index'
import FieldConnected from '../../components/form/field-connected'

class GetPassword extends React.Component {

  render() {
    let { ns, user, errorMessage, metas } = this.props

    if (user === undefined) {
      user = ns.getChanged()
    }

    let header = null
    if (errorMessage) {
      header = (
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">{errorMessage}</FzCard.Title>
        </FzCard.Heading>
      )
    }

    const { email, name, firstPassword, secondPassword, } = metas
    const fields = { email, name, firstPassword, secondPassword }

    const fieldElms = Object.keys(fields).map((field) => {
      const readOnly = (fields[field].path === "email" || fields[field].path === "name")
      return <FieldConnected key={field} meta={fields[field]} data={user} ns={ns} readOnly={readOnly} />
    })

    var rows = []
    for (var iteratorFieldElms = 0; iteratorFieldElms < fieldElms.length; iteratorFieldElms++) {
      if (iteratorFieldElms % 2 === 0) {
        var lastRow = []
        rows.push(lastRow)
      }
      lastRow.push(<FzCol key={iteratorFieldElms} >{fieldElms[iteratorFieldElms]}</FzCol>)
    }

    var fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>
    })


    return (
      <FzCard bsStyle="danger">
        {header}
        <FzCard.Body>
          {fieldsContent}
        </FzCard.Body>
      </FzCard>
    )
  }
}

export { GetPassword }