import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tooltip, OverlayTrigger, Accordion, Breadcrumb } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container';
import { Badge } from 'react-bootstrap';
import { Jumbotron } from 'react-bootstrap';
import { FzIcons } from '../form/icon';

import '../style/layout.css';


class FzForm extends React.Component {

  static propTypes = {
    as: PropTypes.string,
    inline: PropTypes.bool,
    validated: PropTypes.bool,
    bsPrefix: PropTypes.string,
  };

  render() {
    const { as, inline, children } = this.props;
    return <Form inline={inline}>{children}</Form>;
  }
}

FzForm.Control = ({
  as,
  name,
  placeholder,
  value,
  children,
  type,
  readOnly,
  disabled,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  style,
  isValid,
  isInvalid
}) => <Form.Control
  type={type}
  readOnly={readOnly}
  disabled={disabled}
  as={as}
  name={name}
  placeholder={placeholder}
  value={value}
  style={style}
  onChange={onChange}
  onKeyDown={onKeyDown}
  onFocus={onFocus}
  onBlur={onBlur}
  isValid={isValid}
  isInvalid={isInvalid}
>{children}</Form.Control>;

FzForm.defaultProps = {
  value: null
}

FzForm.Control.propTypes = {
  type: PropTypes.string,
  as: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  style: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  bsSize: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool
};


FzForm.Control.Feedback = ({ children }) => {
  return (
    <Form.Control.Feedback type="invalid">
      {children}
    </Form.Control.Feedback>
  )
};

FzForm.Label = ({ children }) => {
  return <Form.Label >{children}</Form.Label>
};

FzForm.Label.propTypes = {
  fzStyle: PropTypes.string,
};

FzForm.Check = ({ name, checked, onChange, inline, children, isDisabled, type = 'checkbox', value = null }) => {
  const props = value ? { value } : {}
  return <Form.Check name={name} inline={inline}>
    <Form.Check.Input disabled={isDisabled} checked={checked} type={type} isValid onChange={onChange} {...props} />
    <Form.Label>{children}</Form.Label>
  </Form.Check>;
};

FzForm.Check.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

FzForm.Static = ({ type, className, children }) => {
  return <Form.Label type={type} className={className}>{children}</Form.Label>;
};

FzForm.Static.propTypes = {
  type: PropTypes.string,
};

// TODO - Precisa implementar o CardGroup
class FzCardGroup extends React.Component {

  static propTypes = {
    bsPrefix: PropTypes.string, //versão nova tem
  };

  render() {
    const { id, children, as, bsPrefix } = this.props;
    return (
      <div
        id={id}
        as={as}
        bsprefix={bsPrefix || 'card-group'}
      >
        {children}
      </div>);
  }

}

const cardStyles = {
  'disable': '',
  'discrete': 'default',
  'regular': 'success',
  'attention': 'warning',
  'alert': 'danger',
};

class FzCard extends React.Component {

  static propTypes = {
    onClick: PropTypes.func,
    onToggle: PropTypes.func,
    fzStyle: PropTypes.string,
    expanded: PropTypes.bool,
    nested: PropTypes.bool,
  };

  render() {
    const { fzStyle, onClick, onToggle, expanded, children, nested } = this.props;
    const bsStyle = cardStyles[fzStyle];
    let className = '';
    if (fzStyle === 'discrete') {
      className += ' fz-discrete';
    }
    if (nested) {
      className += ' fz-nested';
    }
    return (
      <Card variant={bsStyle} className={className} onClick={onClick} onToggle={onToggle}
        expanded={expanded}> {children} </Card>
    );
  }
}


FzCard.Heading = ({ fzStyle, children }) => {
  fzStyle = fzStyle ? fzStyle : {}
  return (
    <Card.Header style={fzStyle}> {children} </Card.Header>
  )
};

FzCard.Heading.propTypes = {
  children: PropTypes.element.isRequired,
};

FzCard.Title = ({ fzStyle, onClick, toggle, children }) => {
  fzStyle = fzStyle ? fzStyle : {}
  return <Card.Title style={fzStyle} onClick={onClick}
    toggle={toggle}><React.Fragment>{children}</React.Fragment></Card.Title>;
};

FzCard.Title.propTypes = {
  fzStyle: PropTypes.object,
  toggle: PropTypes.bool,
  onClick: PropTypes.func,
  componentClass: PropTypes.string
};

FzCard.Body = ({ fzStyle, children }) => {
  fzStyle = fzStyle ? fzStyle : {}
  return (
    <Card.Body style={fzStyle}> {children} </Card.Body>
  )
};

FzCard.Body.propTypes = {
  fzStyle: PropTypes.object,
  collapsible: PropTypes.bool,
};

const FzGrid = ({ children }) => {
  return <Container><React.Fragment>{children}</React.Fragment></Container>;
};
FzGrid.propTypes = {
  fzStyle: PropTypes.string,
};

const rowStyles = {
  'alert': 'alert-line',
  'discrete': 'discrete-line',
  'disabled': 'discrete-line',
};

class FzRow extends React.Component {
  render() {
    const bsClass = rowStyles[this.props.fzStyle];
    return <Row bsclass={bsClass}><React.Fragment>{this.props.children}</React.Fragment></Row>;
  }
}

FzRow.propTypes = {
  fzStyle: PropTypes.string,
};

class FzCol extends React.Component {
  static defaultProps = {
    span: 6,
  };
  static propTypes = {
    fzStyle: PropTypes.string,
    span: PropTypes.number,
    offset: PropTypes.number,
    hidden: PropTypes.bool
  };

  render() {
    const { span, offset, children, hidden } = this.props;
    return <Col hidden={hidden} sm={span} offset={offset}><React.Fragment>{children}</React.Fragment></Col>;
  }
}

const Left = props => <div className="fz-splitline-left"><React.Fragment>{props.children}</React.Fragment></div>;
const Right = props => <div className="fz-splitline-right"><React.Fragment>{props.children}</React.Fragment></div>;

class FzSplitLine extends React.Component {

  static Left = Left;
  static Right = Right;
  static propTypes = {
    fzStyle: PropTypes.string,
    up: PropTypes.bool,
    down: PropTypes.bool,
  };

  render() {
    let s = '';
    if (this.props.up) {
      s = ' fz-up';
    } else if (this.props.down) {
      s = ' fz-down';
    }
    return (
      <div className={'fz-splitline' + s}><React.Fragment>{this.props.children}</React.Fragment></div>
    );
  }
}

const badgeStyles = {
  'disable': '',
  'discrete': 'default',
  'regular': 'info',
  'success': 'success',
  'attention': 'warning',
  'alert': 'danger',
  'gray': 'secondary'
};

class FzBadge extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    fzStyle: PropTypes.string,
    tip: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element]),
    glyph: PropTypes.string,
  };

  render() {
    const { title, fzStyle, children, glyph, tip, className } = this.props;
    let content = <React.Fragment>{children}</React.Fragment>;
    const bsStyle = badgeStyles[fzStyle];
    if (glyph) {
      content =
        <React.Fragment>< FzIcons icon={glyph} fzStyle={{ width: '15px', height: '15px' }} />{content}</React.Fragment>;
    }

    const badge = <Badge className={className} variant={bsStyle} title={title}>{content}</Badge>;
    if (tip) {
      return (<OverlayTrigger
        placement="right"
        overlay={<Tooltip id="tooltip">{tip}</Tooltip>}>
        {badge}
      </OverlayTrigger>);

    }
    return badge;
  }
}

const FzRemarks = ({ children }) => {
  return <div className="fz-remarks"><React.Fragment>{children}</React.Fragment></div>;
};


const FzJumbotron = ({ fzStyle, children }) => {
  return <Jumbotron>{children}</Jumbotron>;
};

FzJumbotron.propTypes = {
  fzStyle: PropTypes.string,
};


const FzFormLabel = ({ fzStyle, children }) => {
  return <Form.Label>{children}</Form.Label>;
};

FzFormLabel.propTypes = {
  fzStyle: PropTypes.string,
};

const FzFormCheck = ({ name, checked, onChange, inline, children, isDisabled }) => {
  return <Form.Check disabled={isDisabled} name={name} checked={checked} onChange={onChange} inline={inline}>{children}</Form.Check>;
};

FzFormCheck.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,

};

export const FzAccordion = ({ children }) => {
  return (
    <Accordion>
      {children}
    </Accordion>
  )
}

FzAccordion.propTypes = {
  eventKey: PropTypes.string,
};

FzAccordion.Toggle = ({ FzElementType, fzStyle, eventKey, children }) => {
  return (
    <Accordion.Toggle as={FzElementType} eventKey={eventKey} style={fzStyle} children={children}>
      {children}
    </Accordion.Toggle>
  )
}


FzAccordion.Toggle.propTypes = {
  FzElementType: PropTypes.string,
  fzStyle: PropTypes.object,
  eventKey: PropTypes.string,
};

FzAccordion.Collapse = ({ children, eventKey, className }) => {
  return (
    <Accordion.Collapse eventKey={eventKey} className={className}>
      {children}
    </Accordion.Collapse>
  )
}

const FzBreadcrumb = (props) => {
  return <Breadcrumb> {props.children} </Breadcrumb>
}
FzBreadcrumb.propTypes = {
  children: PropTypes.array
}

FzBreadcrumb.Item = (props) => {
  return <Breadcrumb.Item href={props.href}> {props.children} </Breadcrumb.Item>
}
FzBreadcrumb.Item.propTypes = {
  href: PropTypes.string
}

export {
  FzCardGroup,
  FzCard,
  FzForm,
  FzRow,
  FzCol,
  FzSplitLine,
  FzBadge,
  FzGrid,
  FzRemarks,
  FzJumbotron,
  FzBreadcrumb,
};