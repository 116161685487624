import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { get as getLodash, omit } from 'lodash'

const keyValues = values => Object.keys(omit(values, ['total', 'description']))
const buildData = values => values.reduce((current, next) => {
  const keys = keyValues(next)
  keys.forEach(element => {
    const findItem = current.find(item => item.name === element)
    if(findItem) {
      current = current.map(item => {
        if(item.name === element) {
          return ({ 
            ...item, 
            data: [...item.data, next[element]]
          })
        }
        return item
    })
    } else {
      current.push({ name: element, data: [next[element]]})
    }
  })
  return current
}, [])

const SummariesGraphics = ({
  tableData,
}) => {
  return (
    <div>
      <div style={{ width: "100%", padding: "20px", display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "65%" }}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={{
              chart: {
                type: 'bar',
              },
              title: { text: 'Aprovação de selfies' },
              subtitle: { text: 'Totais de selfies Aprovada e Rejeitadas pela Único por Parceiros' },
              xAxis: {
                categories: ['Selfies aprovadas pela Único', 'Selfies NÃO aprovadas pela Único']
              },
              navigator: { enabled: false },
              rangeSelector: { enabled: false },
              scrollbar: { enabled: false },
              series: buildData(getLodash(tableData, ['approvals', 'data'], [])),
            }}
          />
        </div>
        <div  style={{ width: "30%" }}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={{
              navigator: { enabled: false },
              rangeSelector: { enabled: false },
              scrollbar: { enabled: false },
              chart: {
                type: "pie"
              },
              title: {
                text: "Total de envios"
              },
              subtitle: { text: 'Totais de envios para Único por Parceiros' },
              credits: {
                enabled: false
              },
              tooltip: {
                split: false
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: false
                  },
                  showInLegend: false
                }
              },
              series: [
                {
                  name: "",
                  color: "#006600",
                  lineWidth: 1,
                  marker: {
                    enabled: false,
                    symbol: "circle",
                    radius: 3,
                    states: {
                      inactive: {
                      },
                      hover: {
                        enabled: true,
                        lineWidth: 1
                      }
                    }
                  },
                  data: buildData(getLodash(tableData, ['totals', 'data'], []))
                  .map(({ name, data = []}) => ({ name, y: data[0] || 0, sliced: true }))
                }
              ]
            }}
          />
        </div>
      </div>    
    </div>
  )
}

export { SummariesGraphics }
