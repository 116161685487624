import { exportColumnsList } from "./csv-exporter-utils/export-columns-list";
import { jsonToCsv } from "./csv-exporter-utils/exporting-utils";
import { arrayToString, numberToString } from "./csv-exporter-utils/provide-marketing-csv-utils";

// All this reports are built based on backend columns
function _provideExplicitFullCVSOptions (outputFormat) {
  const formattedPaths = { "trackingdata.clientId": numberToString };
  const formattedArrayPath = { 
    "trackingdatahistory.completed.utm_campaign": (value) => arrayToString("{type:completed}", value, "utms.utm_campaign"),
    "trackingdatahistory.completed.utm_source": (value) => arrayToString("{type:completed}", value, "utms.utm_source"),
    "trackingdatahistory.completed.utm_medium": (value) => arrayToString("{type:completed}", value, "utms.utm_medium"),
    "trackingdatahistory.completed.createdat": (value) => arrayToString("{type:completed}", value, "createdat"),
    "trackingdatahistory.acceptedOffer.utm_campaign": (value) => arrayToString("{type:acceptedOffer}", value, "utms.utm_campaign"),
    "trackingdatahistory.acceptedOffer.utm_source": (value) => arrayToString("{type:acceptedOffer}", value, "utms.utm_source"),
    "trackingdatahistory.acceptedOffer.utm_medium": (value) => arrayToString("{type:acceptedOffer}", value, "utms.utm_medium"),
    "trackingdatahistory.acceptedOffer.createdat": (value) => arrayToString("{type:acceptedOffer}", value, "createdat"),
  }
  const fieldsSet = exportColumnsList(outputFormat);
  const fields = fieldsSet.map(key => ({
    key, 
    name: key, 
    format: formattedPaths[key],
    arrayFormat: formattedArrayPath[key]
  }));
  return fields
}

function getCsvBasedOnFullCPReport(jsonList, outputFormat) {
  const allColumnsToExport = _provideExplicitFullCVSOptions(outputFormat)
  return jsonToCsv(jsonList, allColumnsToExport);
}

export { getCsvBasedOnFullCPReport }
