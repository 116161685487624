import fileDownload from "js-file-download"
import { getCsvSummariesMarketingReport } from './summaries-marketing-report'
import { getCsvBasedOnFullCPReport } from './full-cp-based-reports'
import { getCsvDeletedReport } from './deleted-reason-report'
import { getCsvFinancialPaidReport } from './financial-paid-report'
import { getCsvAnalyticsMarketingReport } from './analytics-marketing-report'

async function performCSVExport (jsonList, outputFormat) {
  let csv

  switch (outputFormat) {
    case 'summaries-marketing':
      csv = getCsvSummariesMarketingReport(jsonList)
      break;

    case 'analytics-marketing':
      csv = await getCsvAnalyticsMarketingReport(jsonList)
      break;

    case "financial-paid":
      csv = getCsvFinancialPaidReport(jsonList)
      break;
    
    case "deleted-reason":
      csv = getCsvDeletedReport(jsonList)
      break;

    default:
      csv = getCsvBasedOnFullCPReport(jsonList, outputFormat)
      break;
  }

  fileDownload(csv, `applications-export-${outputFormat}.csv`);
}

export { performCSVExport }
