import React from 'react';
import copy from 'copy-to-clipboard';
import { FzCard, FzCol, FzRow, FzBadge } from '../fz/layout/index'
import { FzForm } from '../fz/layout/index'
import { FzButton } from '../fz/form/button'
import { formatPeriod, formatCurrency } from '../fz/formatter/data-formatter'
import get from 'lodash/get'
import '../fz/style/blocks/paid-application.css';
import { FzIcons } from '../fz/form/icon';
import { getUserById } from '../../api/users';

// reportedByUserId
class PaidApplication extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.showReportedByUserId = this.showReportedByUserId.bind(this)
  }


  async showReportedByUserId(reportedByUserId) {
    const user = await getUserById(reportedByUserId)
    if (user) {
      this.setState({ reportedByUserId: user.name })
    }
  }



  render() {
    const { application } = this.props
    const paid = application.paid || {}

    return (
      <div>
        <FzCard>
          <FzCard.Heading>
            Detalhes do Pago
          </FzCard.Heading>
          <FzCard.Body>
            <FzRow>
              <FzCol span="6">
                <FzForm.Label>Parceiro</FzForm.Label>
                <p>{paid.supplierInternalName}</p>
              </FzCol>
              <FzCol>
                <React.Fragment>
                  <FzForm.Label>Data</FzForm.Label>
                  <p>{formatPeriod(true, paid.paidDate)}</p>
                </React.Fragment>
              </FzCol>
            </FzRow>
            <FzRow>

              <FzCol>
                <FzForm.Label>Valor</FzForm.Label>
                <p>{formatCurrency(paid.paidAmount)}</p>
              </FzCol>
              <FzCol>
                {get(paid, 'partnerUrl') ? (
                  <React.Fragment>
                    <FzForm.Label>Pós Pago</FzForm.Label>
                    <div className="fz-paid-application--btn">
                      <FzButton fzStyle="default" onClick={() => copy(paid.partnerUrl, { format: "text/plain" })}>
                        < FzIcons icon={"copy"} fzStyle={{ width: "15px", height: "15px" }} /> Copiar link
                    </FzButton>
                    </div>
                  </ React.Fragment>
                ) : null}
              </FzCol>

              <FzCol>
                <FzForm.Label>Reportado por</FzForm.Label>
                {this.state.reportedByUserId ?
                  (<p><FzBadge fzStyle={"regular"} >{this.state.reportedByUserId}</FzBadge></p>) :
                  (<FzButton block onClick={() => this.showReportedByUserId(paid.reportedByUserId)}>Ver</FzButton>)}
              </FzCol>
            </FzRow>
          </FzCard.Body>

        </FzCard>
      </div>
    )
  }
}

export { PaidApplication }
