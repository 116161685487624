import React from 'react'
import BlockUi from 'react-block-ui'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzButton } from '../../../ui/fz/form'
import { InputLayoutColumns } from '../../../biz/metadatas/InputLayout'
import { InputLayoutResponse } from '../../../services/api/inputlayout/types'
import SplitLine from '../../../components/split-line'

interface Types {
  ns: any,
  data: Array<InputLayoutResponse>,
  handleGoToEditInputLayout: (inputLayout: InputLayoutResponse) => void,
  loading: boolean,
  history: any
}

const InputLayoutList = ({ ns, data, handleGoToEditInputLayout, loading, history }: Types) => {
  const goToInputLayout = (_, row) => (
    <FzButton
      customStyle={{
        borderColor: '#5923DD',
        width: '100%',
        color: '#fff',
        background: '#5923DD'
      }}
      size="small"
      fzStyle=""
      onClick={() => handleGoToEditInputLayout(row)}
    >
      Editar
    </FzButton>
  )

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <div style={{ display: "flex", padding: "20px" }}>
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              <SplitLine>
                <SplitLine.Left>
                  Input Layout - lista
                </SplitLine.Left>
                <SplitLine.Right>
                  <FzButton
                    size="small"
                    fzStyle="regular"
                    onClick={() => history.push("add")}
                  >
                    Criar Input Layout
                  </FzButton>
                </SplitLine.Right>
              </SplitLine>
            </FzCard.Title>
          </FzCard.Header>
          <FzCard.Body>
            <FzTable
              data={data}
              metas={InputLayoutColumns}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              customColumnFormatters={{
                _id: (cell, row) => goToInputLayout(cell, row)
              }}
            />
          </FzCard.Body>
        </FzCard>
      </div>
    </BlockUi>
  )
}

export default InputLayoutList
