/**API informations */
// "hostName": "api4",
// "serverVersion": "published20200123_160243-0-gf83234fd-f83234fd",
// "numCPU": 2,
// "goRoutines": 154,
// "mallocs": 1302609873,
// "frees": 1302501746,
// "stackInuse": 1736704,
// "pauseTotalSec": 0,
// "numGC": 10728,
// "heapInuse": 13025280,
// "heapAlloc": 9908216,
// "heapIdle": 857653248,
// "heapReleased": 855457792,
// "heapObjects": 108127,
// "authCacheObjects": 1117
export const apiMemory = {
  hostName: {
    path: 'hostName',
    dataType: 'text',
    label: 'Host',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '6ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: true,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    tip: undefined
  },
  serverVersion: {
    path: 'serverVersion',
    dataType: 'text',
    label: 'Versão',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '30ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  numCPU: {
    path: 'numCPU',
    dataType: 'int',
    label: 'CPUs',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '5ch',
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  goRoutines: {
    path: 'goRoutines',
    dataType: 'int',
    label: 'Go Routines',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '5ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "numberColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  authCacheObjects: {
    path: 'authCacheObjects',
    dataType: 'int',
    label: 'Auth Cache',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '7ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "numberColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  heapAlloc: {
    path: 'heapAlloc',
    dataType: 'int',
    label: 'Heap Alloc',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '5ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "memoryColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  heapObjects: {
    path: 'heapObjects',
    dataType: 'int',
    label: 'Heap Objects',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '5ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "numberColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  heapInuse: {
    path: 'heapInuse',
    dataType: 'int',
    label: 'Heap in use',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '5ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "memoryColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  heapIdle: {
    path: 'heapIdle',
    dataType: 'int',
    label: 'Heap Idle',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '7ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "memoryColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  },
  heapReleased: {
    path: 'heapReleased',
    dataType: 'int',
    label: 'Heap Released',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '7ch',
      dataSort: true,
      dataAlign: 'right',
      columnFormat: "memoryColumn",
      isKey: false,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    }
  }
}