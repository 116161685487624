import { useState, useEffect } from 'react';
import qs from 'qs';
import { screeningTMAMeta } from '../../../../biz/metadatas/stats/screening/tma-individual';
import { FzCard, FzSplitLine } from '../../../fz/layout/index';
import { customLink, periodColumn } from '../../../../components/table-standard';
import { FzNavSelect } from '../../../fz/nav';
import { StatsDetailHeader } from '../../../../statistics/stats-components';
import { FzTable } from '../../../fz/grid/table';
import { setTTL } from '../../../../business/misc-functions';
import { getAllSuppliers } from '../../../../api/suppliers';
import { _getExtraData } from '../../../../components/table-standard';
import { formatMinutesSeconds } from '../../../fz/formatter/column-formatter';
import { FzSelect } from '../../../fz/form/old-simple-input';
import { getScreeningTMA } from '../../../../services/api/screening';

export function ScreeningTMA (props) {

  const [requestState, setRequestState] = useState("idle")
  const [suppliersList, setSuppliersList] = useState([])
  const [specificPeriod, setSpecificPeriod] = useState(null)
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({
    period: "day",
    supplierInternalName: null
  })

  useEffect(() => {
    if(urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    buildSuppliersList()
    getQueryStringFromUrl()
  }, [])
  
  
  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }
  
  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({path:newurl},'',newurl);
    getScreeningReportTMA()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if(params.period || params.supplierInternalName) {
      setFilters(params)
    }else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getScreeningReportTMA() {
    const { ns } = props;
    setRequestState("requesting") 
    const TMAResults = await getScreeningTMA(getFilters());
    ns.set("screeningTMA", TMAResults.data.statistics, setTTL(5))
    ns.set("screeningTMAResponse", TMAResults)
    await ns.saveChange("filter_periods", buildPeriodFilters(TMAResults.data.statistics), null)
    setRequestState("idle")
  }

  function handleChangePeriod(value){
    setSpecificPeriod(value)
  }
  
  function buildPeriodFilters(statisticsData) {
    let periods = {};
    if (statisticsData) {
      for (var i = 0; i < statisticsData.length; ++i) {
        let p = statisticsData[i].period;
        if (p && !periods[p]) {
          periods[p] = periodColumn(p);
        }
      }
    }
    return periods;
  };



  function buildSuppliersList() {
    getAllSuppliers()
      .then((suppliers) => {
        const suppliersList = suppliers.map(x => ({
          code: x.internalName,
          description: x.name
        }))
        setSuppliersList(suppliersList)
      })
      .catch((err) => {
        console.warn("API ERROR", err);
      })
      .finally(() => setRequestState("idle"))
  }

  function changeSupplierInternalName(value) {
    if(value && value != 'undefined') {
      setFilters({...filters, supplierInternalName: value})
    }else {
      setFilters({...filters, supplierInternalName: null})
    }
  }

  const { ns } = props;
  let filterValues = ns.getChanged("filter_periods") || [];
  let TMADataResult = ns.get('screeningTMA', [])
  let selectedPeriod = specificPeriod || Object.keys(filterValues || { "": "" })[0];
  if(selectedPeriod) {
    TMADataResult = TMADataResult.filter((it) => {
      return it['period'] === selectedPeriod
    })
  }

  let selectProps = {
    readOnly: false,
    value: filters.supplierInternalName,
    name: "Parceiro",
    placeholder: "Parceiro",
    onChange: (v) => {
      changeSupplierInternalName(v)
    },
    options: [...suppliersList],
    fzStyle: { width: 'auto' },
  }

  return (
    <FzCard>
      <FzCard.Body>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title> 
              <FzSplitLine> 
                <FzSplitLine.Left>
                  <label className="form-label" style={{fontSize: '1rem'}}>
                    Parceiro*
                  </label>
                  <div>
                    {suppliersList && <FzSelect {...selectProps} />}
                  </div>
                </FzSplitLine.Left>
                <FzSplitLine.Right>
                  <FzNavSelect 
                    value={filters.period}
                    options={[
                      { "code": "day", "description": "Daily" },
                      { "code": "week", "description": "Weekly" },
                      { "code": "month", "description": "Monthly" },
                    ]}
                    onChange={(value) => {
                      setFilters({...filters, period: value})
                      setSpecificPeriod(null)
                    }}
                  /> 
                </FzSplitLine.Right>
              </FzSplitLine>
              <div className="mt-3">
                <StatsDetailHeader 
                  title="TMA Individual"
                  stats={ns.get("screeningTMAResponse")} 
                  periods={filterValues} 
                  currPeriod={selectedPeriod} 
                  onChange={handleChangePeriod} 
                /> 
              </div>
            </FzCard.Title>
          </FzCard.Heading>
        </FzCard>
        <FzCard>
          <FzCard.Body>
            <FzTable
              data={TMADataResult}
              metas={screeningTMAMeta}
              context={"screeningTMA"}
              ns={ns}
              pagination
              customColumnFormatters={{
                analysisTime: (row) => formatMinutesSeconds(row),
                screenerName: (cell, row) => {
                  if(!cell){
                    cell = row.screenerId
                  }
                  const url = '/admusers/screener/'+row.screenerId
                  return customLink(url, cell)
                }
              }}
              visibleColumns={[
                "key",
                "screenerName",
                "total",
                "pended",
                "success",
                "rejected",
                "closed",
                "analysisTime",
              ]}
              />
          </FzCard.Body>
        </FzCard>
      </FzCard.Body>
    </FzCard >
  )
}