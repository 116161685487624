// Imports
import { FzIcons } from '../../../ui/fz/form/icon';
import get from 'lodash/get';
import './style.css';

const QueueActionsPause = ({ isLoading, handleDetailsModalOpen, handlePostponeModalOpen, navigateToHermesEditQueue, row, setInfo }) => {
  return (
    <div className="btn-group-queues">
      <button
        className="btn btn-primary mr-10"
        onClick={() => {
          handleDetailsModalOpen(row);
        }}
      >
        Ver Detalhes
      </button>
      <div className="btn-actions">
        <button
          disabled={isLoading}
          className="btn btn-primary"
          onClick={() => {
            navigateToHermesEditQueue(get(row, '_id'));
          }}
        >
          <FzIcons
            icon={'pencil'}
            fzStyle={{ width: '15px', height: '15px' }}
          />
        </button>
        <button
          disabled={isLoading}
          className="btn btn-warning"
          onClick={() => {
            setInfo(row);
            handlePostponeModalOpen();
          }}
        >
          <FzIcons
            icon={'pause-circle'}
            fzStyle={{ width: '15px', height: '15px' }}
          />
        </button>
      </div>
    </div>
  );
};

const QueueActionsDelete = ({ isLoading, handleDetailsModalOpen, handleDeleteModalOpen, navigateToHermesEditQueue, row, setInfo, triggerAction }) => {
  return (
    <div className="btn-group-queues">
      <button
        className="btn btn-primary mr-10"
        onClick={() => {
          setInfo(row);
          handleDetailsModalOpen(row);
        }}
      >
        Ver Detalhes
      </button>
      <div className="btn-actions">
        <button
          disabled={isLoading}
          className="btn btn-primary"
          onClick={() => {
            navigateToHermesEditQueue(get(row, '_id'));
          }}
        >
          <FzIcons
            icon={'pencil'}
            fzStyle={{ width: '15px', height: '15px' }}
          />
        </button>
        <button
          disabled={isLoading}
          className="btn btn-success"
          onClick={() => {
            triggerAction('start', row);
          }}
        >
          <FzIcons
            icon={'play-circle'}
            fzStyle={{ width: '15px', height: '15px' }}
          />
        </button>
        <button
          disabled={isLoading}
          className="btn btn-danger"
          onClick={() => {
            setInfo(row);
            handleDeleteModalOpen();
          }}
        >
          <FzIcons icon={'trash'} fzStyle={{ width: '15px', height: '15px' }} />
        </button>
      </div>
    </div>
  );
};

export { QueueActionsPause, QueueActionsDelete };
