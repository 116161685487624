import React from 'react';
import moment from 'moment-timezone';

const formatPeriod = (isTz, period) => {
  if (!period) {
    return ""
  }
  const t = moment(period)
  if (/\d{4}-W\d*/.test(period)) {
    return isTz ? "week " + t.tz('America/Sao_Paulo').format("DD/MMM/YY") : "w " + t.format("DD/MMM/YY")
  } else if (/\d{4}-\d{1,2}$/.test(period)) {
    return isTz ? t.tz('America/Sao_Paulo').format("MMM/YY") : t.format("MMM/YY")
  } else if (/\d{4}-\d{2}-\d{2}/.test(period)) {
    return isTz ? t.tz('America/Sao_Paulo').format("D/M, ddd") : t.format("D/M, ddd")
  }
  return period
}

const formatPeriodWithTime = (isTz, period) => {
  const t = moment(period)
  if (/\d{4}-W\d*/.test(period)) {
    const title = t.format('MMMM Do YYYY, h:mm:ss a')
    return (
      <span title={title}>
        {isTz ? "week " + t.tz('America/Sao_Paulo').format("DD/MMM/YY") : "w " + t.format("DD/MMM/YY")}
      </span>)
  } else if (/\d{4}-\d{1,2}$/.test(period)) {
    const title = t.format('MMMM Do YYYY, h:mm:ss a')
    return (
      <span title={title}>
        {isTz ? t.tz('America/Sao_Paulo').format("MMM/YY") : t.format("MMM/YY")}
      </span>)
  } else if (/\d{4}-\d{2}-\d{2}/.test(period)) {
    const title = t.format('MMMM Do YYYY, h:mm:ss a')
    return (<span title={title}>
      {isTz ? t.tz('America/Sao_Paulo').format("D/M, ddd") : t.format("D/M, ddd")}
    </span>)
  }
  return period;
}

const formatDateWithTime = (value = null) => {
  if(value) {
    return moment(value).format("DD/MM/yyyy HH:mm:ss A").toString()
  }

  return 'invalid date'
}

const formatDateWithTimeAndWeekDay = (value = null) => {
  if(value) {
    return moment(value).format("DD/MM, ddd HH:mm").toString()
  }

  return 'invalid date'
}

const formatPeriodForExport = (period) => {
  let period_BR = moment.tz(period, 'America/Sao_Paulo');
  return period_BR.format();
}

const formatCurrency = (cell, row) => {
  if (!cell) {
    return null
  }
  let value = cell.toLocaleString("pt-BR")
  return value
}

const formatMillisecondsToDate = (value = 0) => {
  if (value > 0) {
    const hours = moment(value).format("hh:mm:ss").toString()
    return hours.replace(/(\d{2})?:(\d{2})?:(\d{2})/, "$1h $2m $3s")
  }
  return ''
}

const momentFormatUTC = (value, format) => {
  return moment(value).tz('UTC').format(format)
}

export {
  formatPeriod,
  formatCurrency,
  formatPeriodForExport,
  formatPeriodWithTime,
  formatDateWithTime,
  formatDateWithTimeAndWeekDay,
  formatMillisecondsToDate,
  momentFormatUTC
}