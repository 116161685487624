import { FiltersType } from '../../../biz/metadatas/aggregate-queues-metadata';
import { ItemListType } from '../../../biz/metadatas/bifrost/reprocessing-metadata';

export const supplierItem = ({ internalName: code, name: description }) => ({
  code,
  description,
});
export const sortSuppliers = (
  previousSupplier: ItemListType,
  nextSupplier: ItemListType
) => previousSupplier.description.localeCompare(nextSupplier.description, 'pt');
export const distinctSuppliers = (
  supplier: ItemListType,
  index: number,
  suppliersList: any[]
) =>
  suppliersList.findIndex(
    (searchedSupplier) =>
      searchedSupplier.code === supplier.code &&
      searchedSupplier.description === supplier.description
  ) == index;

export function normalizeSuppliersList(
  allSuppliers: { internalName: string; name: string }[]
) {
  try {
    const validators = [
      { internalName: 'cbssformalization', name: 'Acesso' },
      { internalName: 'hs', name: 'HS' },
      { internalName: 'unico', name: 'Único' },
      { internalName: 'hs-v2', name: 'HS v2' },
    ];

    allSuppliers.push(...validators);

    const suppliersNormalize = allSuppliers
      .map(supplierItem)
      .sort(sortSuppliers)
      .filter(distinctSuppliers);

    return suppliersNormalize;
  } catch (error) {
    throw error;
  }
}

export function parseParams(params: FiltersType) {
  const timeZoneOffSet = new Date().getTimezoneOffset() * 60 * 1000;

  if (params.createdAfter && !isNaN(Date.parse(String(params.createdAfter)))) {
    params.createdAfter = new Date(params.createdAfter).toJSON();
  } else {
    params.createdAfter = new Date(
      new Date().setHours(-(24 * 7), 0, 0, 0) - timeZoneOffSet
    ).toJSON();
  }

  if (
    params.createdBefore &&
    !isNaN(Date.parse(String(params.createdBefore)))
  ) {
    params.createdBefore = new Date(params.createdBefore).toJSON();
  } else {
    params.createdBefore = new Date(
      new Date().setHours(23, 59, 59, 0) - timeZoneOffSet
    ).toJSON();
  }

  if (
    params.includeQueuesInternalName &&
    typeof params.includeQueuesInternalName === 'string'
  ) {
    params.includeQueuesInternalName =
      params.includeQueuesInternalName.split(',');
  }

  if (
    params.excludeQueuesInternalName &&
    typeof params.excludeQueuesInternalName === 'string'
  ) {
    params.excludeQueuesInternalName =
      params.excludeQueuesInternalName.split(',');
  }
}

export function getUrlParams(params: FiltersType, isAdvanced: boolean) {
  let parsedParams = {
    ...(params.createdAfter && {
      createdAfter: new Date(params.createdAfter).toJSON(),
    }),
    ...(params.createdBefore && {
      createdBefore: new Date(params.createdBefore).toJSON(),
    }),
    ...(params.supplierInternalName && {
      supplierInternalName: params.supplierInternalName,
    }),
    ...(params.active !== '' && { active: params.active }),
    ...(params.canceled !== '' && { canceled: params.canceled }),
    ...(params.timeout !== '' && { timeout: params.timeout }),
    ...(params.limit !== '' && { limit: params.limit }),
  };
  if (isAdvanced) {
    return {
      ...parsedParams,
      ...(params.includeQueuesInternalName && {
        includeQueuesInternalName: params.includeQueuesInternalName.toString(),
      }),
      ...(params.excludeQueuesInternalName && {
        excludeQueuesInternalName: params.excludeQueuesInternalName.toString(),
      }),
    };
  }
  return {
    ...parsedParams,
    ...(params.queueInternalName && {
      queueInternalName: params.queueInternalName,
    }),
  };
}
