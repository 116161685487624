import { Meta } from "../../types/metas"

export const uploadLayoutForm: Meta = {
  product: {
    path: "product",
    label: "Produto",
    inputType: "select",
    inputProps: {
      domainName: "products",
      filteredFrom: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    isMandatory: () => true,
    isReadOnly: () => false
  },
  supplierInternalName: {
    path: "supplierInternalName",
    label: "Supplier",
    inputType: "select",
    inputProps: {
      options: [],
    },
    isMandatory: () => true,
    isReadOnly: () => false
  },
  referenceDate: {
    path: "referenceDate",
    label: "Data de Referência",
    isMandatory: () => true,
    isReadOnly: () => false,
    inputType: "date"
  },
  layout:{
    path: "layout",
    label: "Layout",
    inputType: "select",
    inputProps: {
      options: [],
    },
    isMandatory: () => true,
    isReadOnly: () => false
  },
}

export const listFilesColumns: Meta = {
  "fileName": {
    "path": "fileName",
    "label": "Nome",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "250px",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "id": {
    "path": "id",
    "label": "ID do Arquivo",
    "tableProps": {
      "hidden": false,
      "width": "250px",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "status": {
    "path": "status",
    "label": "Status",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "product": {
    "path": "product",
    "label": "Produto",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "processStartDate": {
    "path": "processStartDate",
    "label": "Início do Processamento",
    "tableProps": {
      "hidden": false,
      "width": "175px",
      "dataSort": true,
      'expandable': false,
      'columnFormat': 'dateTimeColumn',
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "processEndDate": {
    "path": "processEndDate",
    "label": "Término do Processamento",
    "tableProps": {
      "hidden": false,
      "width": "175px",
      "dataSort": true,
      'expandable': false,
      'columnFormat': 'dateTimeColumn',
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "TotalRows": {
    "path": "TotalRows",
    "label": "Processadas",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "TotalFound": {
    "path": "TotalFound",
    "label": "Conciliadas",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "TotalNG": {
    "path": "TotalNG",
    "label": "Não Conciliadas",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "export": {
    "path": "export",
    "label": "Exportar Não Conciliadas",
    "tableProps": {
      "hidden": false,
      "width": "125px",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "action": {
    "path": "action",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "380px",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "marcar_pago": {
    "path": "marcar_pago",
    "label": "Marcar Pago",
    "tableProps": {
      "hidden": false,
      "width": "145px",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}

