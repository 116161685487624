import { Meta } from '../../types/metas';

export const dashboardFormalizationStats: Meta = {
  "_id": {
    "path": "_id",
    "dataType": "string",
    "label": "ID",
    isMandatory: () => true,
    "tableProps": {
      "viewsTable": undefined,
      "hidden": true,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "1": {
    "path": "1",
    "label": "Não iniciado",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "2": {
    "path": "2",
    "label": "Parcial",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "6": {
    "path": "6",
    "label": "Parcialmente recusado",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "8": {
    "path": "8",
    "label": "Aguardando Assinatura",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "4": {
    "path": "4",
    "label": "Enviado pro banco",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "16": {
    "path": "16",
    "label": "Finalizado (Assinado)",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "32": {
    "path": "32",
    "label": "Pago",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "32768": {
    "path": "32768",
    "label": "Cancelado",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "total": {
    "path": "total",
    "label": "Total Elegíveis",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
};

export const capacityReviewPivotMeta: Meta = {
  "key": {
    "path": "key",
    "dataType": "number",
    "label": "#",
    "tableProps": {
      "width": "3ch",
      "hidden": true,
      "isKey": true,
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": () => false
  },
  "pivot": {
    "path": "pivot",
    "dataType": "string",
    "label": "Status da Formalização",
    "tableProps": {
      "width": "35ch",
      "dataAlign": "left"
    },
    "isMandatory": () => false
  },
  "template": {
    "path": "template",
    "dataType": "number",
    "label": "template",
    "tableProps": {
      "width": "10ch",
      "hidden": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "exportFormat": "formatCurrency",
    },
    "isMandatory": () => false
  }
}