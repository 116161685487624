import React, { Component } from 'react';
import { StatsDetailHeader } from '../.././../statistics/stats-components';
import { buildPeriodFilters, buildProductFilters, buildSupplierFilters } from '../../../components/table-standard';
import { setTTL } from '../../../business/misc-functions';
import { getStatisticsFunnelFormalizations } from '../../../api/stats';
import { pivotMeta } from '../../../biz/metadatas/funnel'
import { formalization_funnel } from '../../../biz/metadatas/formalization-metadata'
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { FzCard } from '../../fz/layout/index';
import { FzPivotTable } from '../../fz/grid/pivot-table'
import { getCurrentSupplier } from '../../../api/auth';
import { getAllProducts } from '../../../api/applications';

class FunnelFormalizations extends Component {
  constructor(props) {
    super(props)
    this.state = { requestState: "idle", currPeriod: "", currProduct: "CP", currPivot: "product", allProductsList: [] };

    this.getStatistics = this.getStatistics.bind(this)
    this.handleChangePeriod = this.handleChangePeriod.bind(this);
    this.handleProductSelect = this.handleProductSelect.bind(this)
    this.handlePivotSelect = this.handlePivotSelect.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }


  componentDidMount() {
    this.loadAllProducts();
  }

  async loadAllProducts() {
    const productsResp = await getAllProducts();
    this.setState({
      allProductsList: productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    });
  }

  handleChangePeriod = (value) => {
    this.setState({ currPeriod: value })
  }

  handleProductSelect = (value) => {
    this.setState({ currProduct: value })
  }

  handlePivotSelect = (value) => {
    this.setState({ currPivot: value })
  }

  getStatistics() {
    const { ns, period } = this.props
    this.setState({ requestState: "requesting" }, function () {
      this.props.toggleBlocking(() => {
        getStatisticsFunnelFormalizations(period)
          .then((data) => {
            const supplier = getCurrentSupplier()
            if (supplier !== null) {
              data.statistics = data.statistics.filter(it => it.supplier === supplier.internalName)
            }
            ns.set("formalizationOverview" + period, data, setTTL(5))
            ns.set("filter_periods_" + this.state.currProduct, buildPeriodFilters(data.statistics), null)
            ns.set("filter_product_" + this.state.currProduct, buildProductFilters(data.statistics), null)
            ns.set("filter_suppliers_" + this.state.currProduct, buildSupplierFilters(data.statistics), null)
            this.setState({ requestState: "idle" }, () => this.props.toggleBlocking());
          })
          .catch((err) => {
            console.warn("API ERROR", err)
            this.props.toggleBlocking()
          })
      })
    })
  }

  handleSelect(periodo) {
    this.setState({ currentSelected: periodo }, () => this.getStatistics(this.state.currentSelected))
  }


  render() {
    const { ns, period } = this.props;

    let rawData = ns.get("formalizationOverview" + period);
    let { requestState } = this.state;

    if (requestState === "requesting") {
      return null
    }

    if (!rawData && period !== undefined) {
      this.getStatistics()
      return null
    }

    let data
    if (rawData) {
      data = rawData.statistics
    }

    let filterValues = this.state.currPivot === "product" ?
      ns.get("filter_periods_" + this.state.currProduct) :
      ns.get("filter_product_" + this.state.currProduct)

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title>
            <StatsDetailHeader stats={data}
              title="Funnel Operational Overview"
              periods={filterValues}
              currPeriod={this.state.currPeriod}
              onChange={this.handleChangePeriod} />
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzCard>
            <FzCard.Heading>
              <Navbar fluid>
                <Nav variant="tabs" activeKey={this.state.currProduct} onSelect={this.handleProductSelect} >
                  {this.state.allProductsList
                    .map(it => <NavItem eventKey={it.code} title={it.description}> {it.description}</NavItem>)}
                </Nav>
                <Nav variant="tabs" activeKey={this.state.currPivot} onSelect={this.handlePivotSelect} pullRight>
                  <NavItem eventKey="" disabled={true} >Pivot</NavItem>
                  <NavItem eventKey="period" >Period</NavItem>
                  <NavItem eventKey="product" >product</NavItem>
                </Nav>
              </Navbar>
            </FzCard.Heading>
            <FzCard.Body>
              <FzPivotTable
                data={data || []}
                ns={ns}
                metas={formalization_funnel}
                pivotMetas={pivotMeta}
                context={"formalizationoverview_" + this.state.currProduct}
                filterColumnName={this.state.currPivot === "product" ? "period" : "product"}
                filterValue={this.state.currPeriod}
                pivotColumnName={this.state.currPivot}
              />
            </FzCard.Body>
          </FzCard>
        </FzCard.Body>
      </FzCard>
    )
  }
}

export { FunnelFormalizations }
