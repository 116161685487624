import React from 'react'
import { FzCard, FzRow, FzCol } from '../fz/layout/index'
import { getParameterFzScore } from '../../api/parameters-service'
import { FzTable } from '../fz/grid/table';
import { paramFzScore } from '../../biz/metadatas/parameters'
import { FzNavSelect } from '../fz/nav';
import { FzButton } from '../fz/form';
import FieldConnected from '../components/form/field-connected';

export class SystemFzScore extends React.Component {

  constructor(props) {
    super(props);
    this.state = { requestState: "idle" };
    this.getFzScore = this.getFzScore.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.expandComponent = this.expandComponent.bind(this)
  }


  expandComponent(row, ns) {

    const meta = {
      "for": {
        "path": "for",
        "label": "Field",
        "section": undefined,
        "dataType": "dataType",
        "inputType": "text",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined,
        },
        "tableProps": {
          "viewsTable": "",
          "hidden": null,
          "shortLabel": null,
          "width": "7ch",
          "filter": null,
          "dataSort": true,
          "dataAlign": "left",
          "columnFormat": null,
          "isKey": null,
          "exportFormat": null,
          "rowSpan": null,
          "sortFunc": null
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": null,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined,
      },
      "weight": {
        "path": "weight",
        "label": "Weight",
        "section": undefined,
        "dataType": "number",
        "inputType": "number",
        "inputProps": {
          "domainName": undefined,
          "filteredFrom": undefined,
          "options": undefined,
          "maxValue": undefined,
          "minValue": undefined,
          "maxSize": undefined,
          "minSize": undefined,
          "autoComplete": undefined,
          "mask": undefined,
          "removeMask": undefined,
          "decimalDigits": 8
        },
        "relatedFields": undefined,
        "validationMessage": undefined,
        "pattern": undefined,
        "placeholder": undefined,
        "tip": null,
        "product": undefined,
        "isMandatory": (application) => false,
        "isReadOnly": undefined,
        "isVisible": undefined,
        "normalize": undefined,
        "extraValidation": undefined,
        "hookOnSave": undefined,
      },
    }

    return row.options.map(it => {

      return (
        <FzCard>
          <FzCard.Body>
            <FzRow>
              <FzCol span={6}>
                <FieldConnected ns={ns} meta={meta.for} data={it} readOnly={true} />
              </FzCol>
              <FzCol span={6}>
                <FieldConnected ns={ns} meta={meta.weight} data={it} readOnly={true} />
              </FzCol>
            </FzRow>
          </FzCard.Body>
        </FzCard>
      )
    })
  }

  handleSelect(productNick) {
    this.setState({ currentProduct: productNick }, () => this.getFzScore(this.state.currentProduct))
  }


  getFzScore(product) {
    const { ns } = this.props
    this.setState({ requestState: "requesting" }, function () {
      getParameterFzScore(product)
        .then((data) => {
          ns.set("fzScore", data)
          this.setState({ requestState: "idle" });
        })
        .catch((err) => {
          console.warn("API ERROR", err)
        })
    });

  }


  showOptions(cell, row) {
    if (cell === "select") {

      return (<FzButton fzStyle={"attention"} onClick={() => {}} block >{"More"}</FzButton>)
    }
  }


  render() {

    const { ns } = this.props;

    let data = ns.get("fzScore");
    let { requestState } = this.state;

    if (!data && requestState === "requesting") {
      return null
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzNavSelect 
            selected={this.state.currentProduct} 
            onChange={this.handleSelect} 
            options={[
              { "code": "CP", "description": "CP" },
              { "code": "Refin", "description": "Refin" },
              { "code": "HE", "description": "HE" },
            ]} 
          />
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={data}
            metas={paramFzScore}
            ns={ns}
            exportDownload={false}
            clipboard={false}
            visibleColumns={["id", "product", "field", "weight"]}
            customColumnFormatters={{
              operation: (cell, row) => this.showOptions(cell, row)
            }}
            rowExpand={{
              expandComponent: (row) => this.expandComponent(row, ns),
              expandableRow: (row) => row.operation === "select"
            }}
          />
        </FzCard.Body>
      </FzCard>
    )
  }
}