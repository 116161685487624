import React from 'react';
import {Button  } from 'react-bootstrap';
import { FzCard, FzBadge } from '../fz/layout/index'
import { FzTable } from '../fz/grid/table'
import { campaignApplicant } from '../../biz/metadatas/campaign'
import { FzIcons } from '../fz/form/icon';



export class  CampaignApplciationTable extends React.Component {
  constructor(props) {
    super(props)

    this.activeColumn = this.activeColumn.bind(this)
    this.applicationColumn = this.applicationColumn.bind(this)
    this.linkColumn = this.linkColumn.bind(this)

  }

  activeColumn(cell, row){
    if (!row["active"]) {
      return <FzBadge fzStyle="success">Sim</FzBadge>
    } else {
      return <FzBadge fzStyle="default">Não</FzBadge>
    }
  }

  applicationColumn(cell, row){
    const { history} = this.props
    const detailURL = "/sales/"
    if (cell) {
      return (<Button title={row} onClick={() => history.push(detailURL + cell)} bsSize="xsmall">< FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Ver
                        </Button>);
    }
  }

  linkColumn(cell, row) {
    let endDate = new Date(row["endDate"])
    if (endDate > new Date() && row["active"]) {
      let newURL = "https://finanzero.com.br/campaign/" + row["supplierInternalName"] + "/" + row["campaign"] + "/" + row["originalApplicationId"];
      return (<Button title={row} onClick={() => { let win = window.open(newURL, '_blank'); win.focus(); }}
        bsSize="xsmall">< FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Abrir
                        </Button>);
    }
  }

  campaignColumn(cell, row){
    const { history } = this.props
    return (<Button onClick={() => history.push('/sales/campaign/' + cell)} bsSize="xsmall">< FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Ver
    </Button>);
  }


  render(){
    const { ns, data } = this.props

    if (!data) {
      return null
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title className="h2">Campanhas encontradas</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={data}
            metas={campaignApplicant}
            ns={ns}
            visibleColumns={["id", "campaign", "email", "personalNumber", "telephoneNumber", "active", "convertedDate", "originalApplicationId", "newApplicationId", "link"]}
            />
        </FzCard.Body>
      </FzCard>
    );
  }
}
