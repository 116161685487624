
import moment from 'moment'

import { Meta } from "../../types/metas";

export let applicationFilters: Meta = {
  "createdAfter": {
    "path": "createdAfter",
    "label": "Criada (UTC):",
    "section": "filter-application",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": [],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "createdBefore": {
    "path": "createdBefore",
    "label": "Até (UTC):",
    "section": "filter-application",
    "dataType": "dateTime",
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "product": {
    "path": "product",
    "label": "Qual produto:",
    "section": "filter-application",
    "dataType": "string",
    "inputType": "select",
    "tableProps": {
      "viewsTable": "application",
      "hidden": false,
      "shortLabel": "Produto",
      "width": "8ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "Escolha um produto",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (value) => value === "undefined" ? "" : value,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "completedForm": {
    "path": "completedForm",
    "label": "Preenchimento no Site:",
    "section": "filter-application",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": [
        { "code": '', "description": "Indiferente" },
        { "code": 'false', "description": "Incompleto" },
        { "code": 'true', "description": "Completo" }
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (value) => value === "undefined" ? "" : value,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "personalNumbers": {
    "path": "personalNumbers",
    "label": "CPF:",
    "section": "filter-application",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": "111.111.111-11",
      "removeMask": true
    },
    "tableProps": {
      "viewsTable": "application",
      "hidden": false,
      "shortLabel": undefined,
      "width": "20ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "Numero de CPF inválido",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => true,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (e) => {
      let reCPF = /\d+/g
      let cpfs = e || ""
      cpfs = cpfs.replace(/\.|-/g, "");
      let out : string[] = []
      let result
      while ((result = reCPF.exec(cpfs)) !== null) {
        let x = "00000000000" + result[0]
        out.push(x.slice(-11))
      }
      return out.join(",")
    },
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "applicationStates": {
    "path": "applicationStates",
    "label": "Status da proposta:",
    "section": "filter-application",
    "dataType": "number",
    "inputType": "multiselect",
    "filterType": "domain",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": [
        //{ code: '', description: "Todos" },
        { code: 'init', description: "Em cadastro" },
        { code: 'lead', description: "Cadastrada" },
        { code: 'started', description: "Em análise" },
        { code: 'has-bids', description: "Proposta enviada" },
        { code: 'follow-up', description: "Em follow-up" },
        { code: 'closed', description: "Encerrada" },
        { code: 'paid', description: "Paga" },
        { code: 'excluded', description: "Proposta excluida" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": [],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "telephoneNumber": {
    "path": "telephoneNumber",
    "label": "Telefone",
    "section": "personal-data",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": "tel",
      "mask": "(11) 1 1111 1111",
      "removeMask": true
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "Informe um número com DDD: (xx) xxxxx-xxxx",
    "pattern": /^\d{10,11}$/,
    "placeholder": "(xx) xxxxx xxxx",
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => true,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (value) => { return value !== undefined ? value.toLowerCase() : value },
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "zipStart": {
    "path": "zipStart",
    "label": "CEP Inicial:",
    "section": "filter-geo-local",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": "#####-###",
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": /^\d{5}-?\d{3}$/,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (value) => value.replace("-", ""),
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "zipEnd": {
    "path": "zipEnd",
    "label": "CEP Final:",
    "section": "filter-geo-local",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": "#####-###",
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": /^\d{5}-?\d{3}$/,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (value) => value.replace("-", ""),
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "state": {
    "path": "state",
    "label": "Estado:",
    "section": "filter-geo-local",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "domainName": "states",
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": "address-level1",
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "Informe o estado de residência",
    "pattern": /^[A-Z]{2}$/,
    "placeholder": "Selecione ...",
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "city": {
    "path": "city",
    "label": "Cidade:",
    "section": "filter-geo-local",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": "address-level2",
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": "application",
      "hidden": true,
      "shortLabel": undefined,
      "width": "25ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "Informe a cidade de residência",
    "pattern": /^.{3,60}$/,
    "placeholder": "Cidade",
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "trackingData.utm_campaign": {
    "path": "trackingData.utm_campaign",
    "label": "Campanha:",
    "section": "filter-mkt",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": "application",
      "hidden": true,
      "shortLabel": undefined,
      "width": "20ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": false,
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "trackingData.utm_source": {
    "path": "trackingData.utm_source",
    "label": "Origem:",
    "section": "filter-mkt",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": "application",
      "hidden": true,
      "shortLabel": undefined,
      "width": "20ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": false,
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "trackingData.utm_medium": {
    "path": "trackingData.utm_medium",
    "label": "Mídia:",
    "section": "filter-mkt",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": "application",
      "hidden": true,
      "shortLabel": undefined,
      "width": "20ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": false,
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "trackingData.utm_content": {
    "path": "trackingData.utm_content",
    "label": "Conteúdo da campanha:",
    "section": "filter-mkt",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": "application",
      "hidden": true,
      "shortLabel": undefined,
      "width": "20ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": false,
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": ["CP", "HE", "Refin"],
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "trackingdata.creationPage": {
    "path": "trackingdata.creationPage",
    "label": "Página de Criação:",
    "section": "filter-mkt",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },

    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "trackingdata.referrer": {
    "path": "trackingdata.referrer",
    "label": "Site Indicador:",
    "section": "filter-mkt",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined,
    },
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "7ch",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": undefined,
      "exportFormat": undefined,
      "rowSpan": undefined,
      "sortFunc": undefined
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    "isMandatory": (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "paidAfter": {
    "path": "paidAfter",
    "label": "Paga depois (UTC):",
    "section": "filter-paid",
    "dataType": "dateTime",
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": [],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "paidBefore": {
    "path": "paidBefore",
    "label": "Paga antes (UTC):",
    "section": "filter-paid",
    "dataType": "dateTime",
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": [],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "paidSupplier": {
    "path": "paidSupplier",
    "label": "Paga por (parceiro):",
    "section": "filter-paid",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": [{ code: "", description: "Selecione o parceiro..." }],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
    },
    "relatedFields": undefined,
    "validationMessage": "",
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": [],
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": (value) => value === "undefined" ? "" : value,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
}