import { Meta } from "../../../types/metas";

export const deletedReasonsMeta: Meta = {
  "period": {
    "path": "period",
    "label": "Período",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      "isKey": true,
      "columnFormat": "periodColumnTz",
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "no-offers": {
    "path": "no-offers",
    "label": "Não recebi nenhuma oferta",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "email-spam": {
    "path": "email-spam",
    "label": "Recebo e-mails em excesso",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "dont-want-expose-personal-data": {
    "path": "dont-want-expose-personal-data",
    "label": "Não quero deixar meus dados com a Finanzero",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "no-more-needed": {
    "path": "no-more-needed",
    "label": "Não preciso mais de empréstimo",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "other-reasons": {
    "path": "other-reasons",
    "label": "Outros motivos",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
