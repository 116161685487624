import moment from 'moment'
import { Meta } from '../../../types/metas'

export const ticketsReportFilters: Meta = {
  "from": {
    "path": "from",
    "label": "Desde (0h)",
    "dataType": "moment",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isReadOnly": () => false,
    "isMandatory": (application) => false,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T03") : value,
  },
  "to": {
    "path": "to",
    "label": "Até",
    "dataType": "moment",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().add(+2, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    "isReadOnly": () => false,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T03") : value,
  },
  "notInitialized": {
    "path": "notInitialized",
    "label": "Não iniciados",
    "dataType": "boolean",
    "inputType": "checkbox",
    "isMandatory": (application) => false,
    "isReadOnly": () => false,
  }
}


export const ticketsReportTableMeta : Meta = {
  "applicationId": {
    "path": "applicationId",
    "dataType": "id",
    "label": "App Id",
    "tableProps": {
      "viewsTable": "",
      "width": "14ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "applicationIdColumn",
      "isKey": true,
    },
  },
  "created": {
    "path": "created",
    "dataType": "date",
    "label": "Data de criação",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "12ch",
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "periodWithTimeColumnTz",
      "exportFormat": "formatPeriodForExport",
    },
    "tip": "Data de criação do ticket",
  },
  "closedDate": {
    "path": "closedDate",
    "dataType": "date",
    "label": "Data de Fechamento",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumnTz",
      "exportFormat": "formatPeriodForExport",
    },
    "tip": "Data de Fechamento do ticket",
  },
  "_id": {
    "path": "_id",
    "dataType": "id",
    "label": "Ticket Id",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": false,
      "dataAlign": "center",
    },
    "tip": "Ticket Id"
  },
  "queueName": {
    "path": "queueName",
    "dataType": "string",
    "label": "Nome da Fila",
    "tableProps": {
      "hidden": false,
      "width": "34ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
    "tip": "Nome da Fila que gerou o ticket"
  },
  "event": {
    "path": "event",
    "dataType": "string",
    "label": "Evento",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "30ch",
      "columnFormat": "getDescriptionsByDomainsOrKeepOriginalValue",
      "formatExtraData": "events-hermes",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
    "tip": "Evento que gerou o ticket"
  },
  "relation": {
    "path": "relation",
    "dataType": "string",
    "label": "Relação",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "30ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
    "tip": "Relação do ticket"
  },
  "outcome": {
    "path": "outcome",
    "dataType": "string",
    "label": "Status final",
    "tableProps": {
      "viewsTable": "",
      "width": "38ch",
      "dataSort": true,
      "columnFormat": "getDescriptionsByDomainsOrKeepOriginalValue",
      "formatExtraData": "attendance-treatments",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
  },
  "closedReason": {
    "path": "closedReason",
    "dataType": "string",
    "label": "Motivo de Fechamento",
    "tableProps": {
      "viewsTable": "",
      "width": "20ch",
      "dataSort": true,
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
  },
  "closedByUserName": {
    "path": "closedByUserName",
    "dataType": "string",
    "label": "Analista",
    "tableProps": {
      "viewsTable": "",
      "width": "29ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
  },
  "cadencia": {
    "path": "cadencia",
    "dataType": "number",
    "label": "Cadência",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataAlign": "center",
    },
  },
  "contacts.notificationsSent": {
    "path": "contacts.notificationsSent",
    "dataType": "string",
    "label": "Notificações cliente",
    "tableProps": {
      "viewsTable": "",
      "columnFormat": "notificationConcatWithPipe",
      "width": "10ch",
      "dataAlign": "center",
    },
  },
  "contacts.moreNotifications": {
    "path": "contacts.moreNotifications",
    "dataType": "string",
    "label": "Demais notificações",
    "tableProps": {
      "viewsTable": "",
      "columnFormat": "notificationConcatWithPipe",
      "width": "30ch",
      "dataAlign": "center",
    },
  },
}