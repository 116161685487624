import { useState, useEffect } from 'react';
import qs from 'qs';
import { setTTL } from "../../../business/misc-functions";
import { FzTable } from "../../fz/grid/table";
import { FzNavSelect } from "../../fz/nav";
import { FzCard } from "../../fz/layout/FzCard";
import { productivitySummary } from "../../../biz/metadatas/sales-metadata";
import { FzSplitLine } from '../../fz/layout';
import { StatsDetailHeader } from '../../../statistics/stats-components';
import { getAgentsProductivity, getProductivityDS } from '../../../api/crm';

interface CrmProductivityProps {
  ns: any;
  toggleBlocking: (
    callback?: any
  ) => void;
}

export function CrmProductivity({ ns, toggleBlocking }: CrmProductivityProps) {

  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({ period: "day" })
  const [showDataStudio, setShowDataStudio] = useState(false)
  const [dataStudioValue, setDataStudioValue] = useState(null)

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
    getProductivityDS().then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get Productivity Data Studio iframe", err)
    })
  }, [])

  async function getStatistics() {
    try {
      setRequestState("requesting")
      toggleBlocking();
      const crmProductivityResults = await getAgentsProductivity(getFilters());
      ns.set("contactsAgent", crmProductivityResults, setTTL(5));
      toggleBlocking();
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      toggleBlocking()
    }
  }

  function handleShowDataStudio() {
    setShowDataStudio(!showDataStudio)
  }

  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
    getStatistics()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  let data = ns.get("contactsAgent", {});
  if (!data && requestState === "requesting") {
    return null
  }

  return (
    <>
    {!showDataStudio && dataStudioValue ? <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>Data Studio View</button> : <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>COF View</button>}
    {showDataStudio && dataStudioValue ? <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} />
    :
    <FzCard>
      <FzCard.Header>
        <FzCard.Title>
          <FzSplitLine>
            <FzSplitLine.Left></FzSplitLine.Left>
            <FzSplitLine.Right>
              <FzNavSelect
                value={filters.period}
                options={[
                  { "code": "day", "description": "Daily" },
                  { "code": "week", "description": "Weekly" },
                  { "code": "month", "description": "Monthly" },
                ]}
                onChange={(value) => {
                  setFilters({ ...filters, period: value })
                }}
              />
            </FzSplitLine.Right>
          </FzSplitLine>
          <div className="mt-3">
            <StatsDetailHeader
              title={'Crm Productivity'}
              periods={undefined}
              currPeriod={undefined}
              onChange={undefined}
              stats={data}
            />
          </div>
        </FzCard.Title>
      </FzCard.Header >
      <FzCard.Body>
        <FzTable
          exportDownload
          pagination
          ns={ns}
          context={"statsSalesCallPerAgent"}
          data={data.statistics}
          metas={productivitySummary}
          visibleColumns={["key", "period", "agent", "count_opportunities", "count_uploads", "count_paid", "count_upload_paid"]}
        />
      </FzCard.Body>
    </FzCard>}
    </>
  );
}

