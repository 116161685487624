import React from 'react';
import copy from "copy-to-clipboard";
import { useState } from "react";
import { ToggleButton } from "react-bootstrap";
import { FzButton } from "../../ui/fz/form";
import { FzIcons } from "../../ui/fz/form/icon";
import { fzTableDoExport } from "../../ui/fz/formatter/export-data";
import { FzSelectVisibleColumns } from "../../ui/fz/grid/toolbar-custom";
import { FzToggleButtonGroup } from "../FzToggleButtonGroup";
import { FzToolbarCustomProps } from './types';

export function FzToolbarCustom({
  exportDownload = true,
  clipboard = true,
  selectColumns = true,
  data,
  metas,
  columnsPaths,
  context,
  ns,
  keyToolBar,
}: FzToolbarCustomProps) {
  const [visibleColumns, setVisibleColumns] = useState<string[]>([])

  function handleClipBoardCsv() {
    return fzTableDoExport(data, metas, columnsPaths, false, context)
  }

  function handleExportCSV() {
    fzTableDoExport(data, metas, columnsPaths, true, context)
  }

  function handleChangePanels(columns) {
    setVisibleColumns(columns)
  }

  let select = visibleColumns.indexOf("select-columns") >= 0 ?
    <FzSelectVisibleColumns
      metas={metas}
      columnsPaths={columnsPaths}
      ns={ns}
      context={'hs'}
    /> : null;


  return (
    <div key={keyToolBar}>
      <div style={{ margin: "0 0 5px", alignItems: "start", display: "flex", gap: "4px" }}>
        {clipboard !== false ? (
          <FzButton
            fzStyle={"regular"}
            size="sm"
            onClick={() => {
              const csvData = handleClipBoardCsv()
              return csvData ? copy(csvData, { format: "text/plain" }) : undefined
            }}
          >
            <FzIcons icon={"copy"} fzStyle={{ width: "15px", height: "13px", margin: "0 3px 0 0" }} />
            Copiar
          </FzButton>
        )
          : (null)}

        {exportDownload !== false ? (
          <FzButton
            size="sm"
            fzStyle="info"
            onClick={handleExportCSV}>
            <FzIcons
              icon={"file-export"}
              fzStyle={{ width: "15px", height: "13px", margin: "0 3px 0 0" }}
            />
            Exportar dados em CSV
          </FzButton>) : (null)}

        { selectColumns ? (
          <FzToggleButtonGroup
            name={''}
            size='sm'
            type="checkbox"
            value={visibleColumns}
            onChange={handleChangePanels}
          >
            <ToggleButton
              variant="default"
              value={"select-columns"}
            >
              Selecione as colunas
            </ToggleButton>
          </FzToggleButtonGroup>
        ) : (null)}
      </div>
      <div style={{ display: "block" }}>
        { select }
      </div>
    </div>
  )

}