import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Field from '../components/field';
import { Namespace } from '../scripts/state/redux_ns';
import { FormattedDate, FormattedTime } from 'react-intl';
import SplitLine from "../components/split-line";
import { addComment } from '../api/applications'
import { FzCard } from '../ui/fz/layout/index'
import { FzForm } from '../ui/fz/layout/index'
import { FzIcons } from '../ui/fz/form/icon';


const ExistingComments = ({ ns, data, ...props }) => {
  let actualComents = [];

  const tooltip = (type) => <Tooltip id="tooltip">Foi enviado um <strong>{type}</strong> com esse comentário para o cliente.</Tooltip>;

  for (let i = 0; i < data.comments.length; ++i) {
    actualComents.push(
      <Field
        key={i}
        cols={0}
        readOnly
        type="text"
        multiline={true}
        path={"comments[" + i + "].comment"}
        label={(<span>
          {data.comments[i].userName} em <FormattedDate value={data.comments[i].created} /> <FormattedTime value={data.comments[i].created} />
          {data.comments[i].notify && <OverlayTrigger placement="right" overlay={tooltip("email")}>< FzIcons icon={"envelope"} fzStyle={{ width: "15px", height: "15px" }} /></OverlayTrigger>}
          {data.comments[i].notifySMS && <OverlayTrigger placement="right" overlay={tooltip("SMS")}>< FzIcons icon={"comment"} fzStyle={{ width: "15px", height: "15px" }} /></OverlayTrigger>}
        </span>)}
        ns={ns}
        data={data}
        {...props}
      />
    );


  }
  if (actualComents.length > 0) {
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Comentários anteriores</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {actualComents}
        </FzCard.Body>
      </FzCard>
    );
  } else {
    return null;
  }
}

const initialState = Object.freeze({ comment: "", notify: false, notifySMS: false });

class NewComment extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this)
    this.sendComment = this.sendComment.bind(this)
  }

  handleChange(e) {
    this.setState({ comment: e.target.value })
  }

  componentDidMount() {
    if (this.props.readOnly) {
      console.warn("NewComment does not work as a read-only componnent")
    }
  }

  sendComment() {
    if (this.state.notifySMS && this.state.comment.length > 140) {
      return alert("Texto é muito grande para enviar por SMS");
    }
    addComment(this.props.data.id, this.state.comment, this.state.notify, this.state.notifySMS).then(application => {
      if (this.props.onApplicationUpdated) {
        this.props.onApplicationUpdated(application)
      }
      this.setState({ comment: "", notify: false, notifySMS: false })
    }).catch(err => {
      console.warn("addComent ERROR:", err);
      this.props.history.push("/sales")
    })
  }

  render() {
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Novo Comentário</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzForm.Control
            bsSize="small"
            type="text"
            as="textarea"
            value={this.state.comment}
            name="comment"
            onChange={this.handleChange}
          />
          {this.state.notifySMS &&
            <SplitLine className="splitline-align-up">
              <SplitLine.Left>{this.state.comment.length > 140 && <strong className="text-danger">Texto é muito grande para enviar por SMS</strong>}</SplitLine.Left>
              <SplitLine.Right>{this.state.comment.length}/140</SplitLine.Right>
            </SplitLine>
          }
          <FzForm.Check checked={this.state.notify} onChange={(e) => this.setState({ notify: (e.target.checked) })} >
            Enviar email para o cliente com o comentário
                    </FzForm.Check>
          <FzForm.Check checked={this.state.notifySMS} onChange={(e) => this.setState({ notifySMS: (e.target.checked) })} >
            Enviar SMS para o cliente com o comentário
                    </FzForm.Check>
          <ButtonGroup className="bottom-toolbar">
            <Button disabled={this.props.ns.isEditing()} bsStyle="primary" onClick={() => this.sendComment()}>Gravar Comentário</Button>
          </ButtonGroup>
        </FzCard.Body>
      </FzCard>
    );
  }
}

ExistingComments.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.object.isRequired
};

export { ExistingComments, NewComment };