import React, { useState, useEffect } from 'react';
import qs from 'qs';

import { getQualifiedLeads } from "../../../api/stats";
import { setTTL } from "../../../business/misc-functions";
import { FzTable } from "../../fz/grid/table";
import { buildPeriodFilters } from '../../../components/table-standard';
import { FzCard } from "../../fz/layout/FzCard";
import { StatsDetailHeader } from '../.././../statistics/stats-components';
import { FzNavSelect } from "../../fz/nav";
import { FzSplitLine } from '../../fz/layout';

interface qualifeidLeadsProps {
  ns: any;
  metas: {};
  toggleBlocking: (
    callback?: any
  ) => void;
}

export function QualifiedLeads({ ns, toggleBlocking, metas }: qualifeidLeadsProps) {

  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({ period: "day" })

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])


  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
    getStatistics()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getStatistics() {

    try {
      setRequestState("requesting")
      toggleBlocking();
      const QualifiedLeadsResults = await getQualifiedLeads(getFilters());
      ns.set("qualified", QualifiedLeadsResults, setTTL(5))
      ns.set("filter_periods", buildPeriodFilters(QualifiedLeadsResults.statistics))
      toggleBlocking();
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      toggleBlocking()
    }
  }

  let data = ns.get("qualified", {});

  if (!data && requestState === "requesting") {
    return null
  }

  return (
    <>
      <FzCard>
        <FzCard.Header>
          <FzCard.Title>
            <FzSplitLine>
              <FzSplitLine.Left></FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzNavSelect
                  value={filters.period}
                  options={[
                    { "code": "day", "description": "Daily" },
                    { "code": "week", "description": "Weekly" },
                    { "code": "month", "description": "Monthly" },
                  ]}
                  onChange={(value) => {
                    setFilters({ ...filters, period: value })
                  }}
                />
              </FzSplitLine.Right>
            </FzSplitLine>
            <div className="mt-3">
              <StatsDetailHeader
                title={'Qualified Leads'}
                periods={undefined}
                currPeriod={undefined}
                onChange={undefined}
                stats={data}
              />
            </div>
          </FzCard.Title>
        </FzCard.Header>
        <FzCard.Body>
          <FzTable
            exportDownload
            ns={ns}
            context={"qualified"}
            data={data.statistics}
            metas={metas}
            visibleColumns={["key", "period", "startedBy", "product", "quantity", "averageAmount", "serasaScore", "salesPriority"]}
            clipboard
          />
        </FzCard.Body>
      </FzCard>
    </>
  )
}