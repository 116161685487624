import { Meta } from "../../types/metas"

export const paid: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",

      "width": "50",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
    "isMandatory": () => false,
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    "isMandatory": () => false,
  },
  "product": {
    "path": "product",
    "dataType": "string",
    "label": "Product",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: 'filters.product', condition: 'eq' },
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": () => false,
  },
  "supplier": {
    "path": "supplier",
    "dataType": "string",
    "label": "Supplier",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": () => false,
  },
  "count": {
    "path": "count",
    "dataType": "number",
    "label": "Count",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": () => false,
  },
  "paidAvg": {
    "path": "paidAvg",
    "dataType": "number",
    "label": "Average Paid Amount",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
    },
    "isMandatory": () => false,
  },
  "hoursAvg": {
    "path": "hoursAvg",
    "dataType": "number",
    "label": "Average Time to Pay (h)",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
    },
    "isMandatory": () => false,
  },
  "paidAmount": {
    "path": "paidAmount",
    "dataType": "number",
    "label": "Total Paid Amount",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": 'amountColumn',
    },
    "isMandatory": () => false,
  },

}
