import React, { Component } from 'react';
import { FzJumbotron, FzRow, FzCol, FzCard } from '../fz/layout/index'
import { FzButton } from '../fz/form'
import isEmpty from 'lodash/isEmpty';
import { normalizeCPF, normalizePhone, normalizeEmail } from '../../business/application'
import { CampaignApplciationTable } from './campaign-applicants-table'
import { startCustomerService } from '../../api/crm';
import { getUsersByFilter } from '../../api/auth';
import api from '../../api/client-api';
import { CrmChannel } from '../../crm/components'
import { FzCardGroup } from '../fz/layout/index'
import { FzForm } from '../fz/layout/index'
import { FzAlert } from '../fz/form/alert';
import { ClientsTable } from '../blocks/crm/clientsTable'
import { getTicketById } from '../../services/api/hermes';
import { getByID } from '../../api/applications';

export class SalesFindApplication extends Component {
  constructor(props) {
    super(props)
    this.doFindCustomer = this.doFindCustomer.bind(this);
    this.state = { filter: {}, errorMessage: null }
    this.newApplications = this.newApplications.bind(this);
    this.apiError = this.apiError.bind(this);
    this.setCPFFilter = this.setCPFFilter.bind(this)
    this.setEmailFilter = this.setEmailFilter.bind(this)
    this.setCelularFilter = this.setCelularFilter.bind(this)
    this.setTicketFilter = this.setTicketFilter.bind(this)
    this.sendOnEnter = this.sendOnEnter.bind(this)
    this.processCustomerData = this.processCustomerData.bind(this);
    this.startService = this.startService.bind(this);
    this.getChannel = this.getChannel.bind(this)
    this.setChannel = this.setChannel.bind(this)
    this.SharedNSPath = "sales." + this.props.category + ".channel"
    this.props.ns.setShared("crm.category", this.props.category)
    this.basePath = this.props.category === "manual-ative" ? "/sales/manual" : "/sales/in"
  }

  getChannel() {
    return this.props.ns.getShared(this.SharedNSPath, "chat")
  }

  getCategory() {
    return this.props.ns.getShared("crm.category") || this.props.category
  }

  startService(userId) {
    startCustomerService(userId, this.getCategory(), this.getChannel())
      .then(cd => { this.processCustomerData(cd) })
      .catch(this.apiError)
  }
  sendOnEnter(event) {
    if (event.keyCode === 13) {
      this.doFindCustomer();
    }
  }
  newApplications(applications) {
    this.props.ns.set("applications", applications)
  }
  apiError(err) {
    console.warn(err)
    this.setState({ errorMessage: "Nenhum cliente encontrado" })
  }

  processCustomerData(cd) {
    if (!cd) {
      this.props.ns.unset("clients")
    } else {
      const ns = this.props.ns.copy("/sales/customer/" + cd.customer.id)
      ns.set("customer", cd);
      this.props.ns.set("customer", cd)

      cd.applications.forEach(app => {
        var ns1 = this.props.ns.copy("/sales/" + app.id);

        ns1.set("customer", cd);

        ns.set("crm.treatments." + app.id, "");
        ns.set("crm.treatmentsArray." + app.id, []);
        ns1.set("crm.treatments." + app.id, "");
        ns1.set("crm.treatmentsArray." + app.id, []);

      });

      this.props.history.push(this.basePath + "/customer/" + cd.customer.id)
    }
  }

  setEmailFilter(name) {
    if (this.state.filter.email) {
      let email = normalizeEmail(this.state.filter.email);
      let _filter = this.state.filter;
      if (email && email.length > 0) {
        _filter[name] = email
      } else {
        delete _filter[name]
      }
      this.setState({ filter: _filter })
    }
  }

  setTicketFilter(name) {
    if (this.state.filter.ticketId) {
      let ticketId = this.state.filter.ticketId;
      let _filter = this.state.filter;
      if (ticketId && ticketId.length > 0) {
        _filter[name] = ticketId
      } else {
        delete _filter[name]
      }
      this.setState({ filter: _filter })
    }
  }

  setChannel(value, ns) {
    ns.setShared(this.SharedNSPath, value)
  }

  setCelularFilter(name, value) {
    if (this.state.filter.telephoneNumber) {
      let cel = normalizePhone(this.state.filter.telephoneNumber);
      let _filter = this.state.filter;
      if (cel && cel.length > 0) {
        _filter[name] = cel
      } else {
        delete _filter[name]
      }
      this.setState({ filter: _filter })
    }
  }

  setCPFFilter(name, value) {
    if (this.state.filter.personalNumber) {
      let cpf = normalizeCPF(this.state.filter.personalNumber);
      let _filter = this.state.filter;
      if (cpf && cpf.length > 0) {
        _filter[name] = cpf
      } else {
        delete _filter[name]
      }
      this.setState({ filter: _filter })
    }
  }

  changeField(name, val) {
    let filter = this.state.filter;
    if (val.length > 0) {
      filter[name] = val;
      this.setState({ errorMessage: null })
    } else {
      delete filter[name]
    }
    this.setState({ filter: filter }, () => {

      const hooks = {
        "telephoneNumber": this.setCelularFilter,
        "personalNumber": this.setCPFFilter,
        "email": this.setEmailFilter,
        "ticketId": this.setTicketFilter
      }
      const functionHook = hooks[name]

      functionHook(name, val)
    })
  }

  async doFindCustomer() {
    this.props.ns.unset("applications")
    this.props.ns.unset("applicants")
    this.props.ns.unset("clients")
    if (isEmpty(this.state.filter)) {
      this.setState({ errorMessage: "Preencha um ou mais valores para procurar uma proposta." })
    } else {
      let _filter = this.state.filter
      if (_filter["personalNumber"] && _filter["personalNumber"].length > 0) {
        _filter["personalNumber"] = normalizeCPF(_filter["personalNumber"])
      } else if (_filter["ticketId"] && _filter["ticketId"].length > 0) {
        try {
          const ticketResult = await getTicketById(_filter["ticketId"])
          const app = await getByID(ticketResult.data.applicationId)
          if (app && app.customerId) {
            _filter["personalNumber"] = normalizeCPF(app.personalNumber)
          }
        } catch (error) {
          console.error(error)
        }
      }

      getUsersByFilter(_filter).then(usrs => {
        if (usrs.length > 1) {
          this.props.ns.set("clients", usrs)
        } else {
          if (api.isUserSales() && !usrs[0].active) {
            this.setState({ errorMessage: "Cliente expirado" })
          } else {
            this.startService(usrs[0].id)
          }
        }
      }).catch(this.apiError)


    }
  }

  render() {
    let errorMessage
    if (this.state.errorMessage) {
      errorMessage = <FzAlert fzStyle="alert">{this.state.errorMessage}</FzAlert>
    }
    let clientsTable
    let clients = this.props.ns.get("clients")
    if (clients && clients.length > 0) {
      clientsTable = <ClientsTable clients={clients} startService={this.startService} ns={this.props.ns} />
    }
    const campaignApplicants = <CampaignApplciationTable ns={this.props.ns} data={this.props.ns.get("applicants")} history={this.props.history} />

    return (
      <FzJumbotron>
        <FzCardGroup id="1">
          <FzCard>
            <FzCard.Heading>
              <FzCard.Title componentClass="h3">Iniciar atendimento</FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
              <FzRow>
                <FzCol span={3}>
                  <FzForm.Label>Email</FzForm.Label>
                  <FzForm.Control
                    name="email"
                    type="text"
                    onBlur={(e) => this.setEmailFilter(e.target.name, e.target.value)}
                    onKeyDown={this.sendOnEnter}
                    value={this.state.filter.email || ""}
                    onChange={(e) => this.changeField(e.target.name, e.target.value)} />
                </FzCol>
                <FzCol span={3}>
                  <FzForm.Label>Celular</FzForm.Label>
                  <FzForm.Control
                    name="telephoneNumber"
                    value={this.state.filter.telephoneNumber || ""}
                    onBlur={(e) => this.setCelularFilter(e.target.name, e.target.value)}
                    onKeyDown={this.sendOnEnter}
                    type="text"
                    onChange={(e) => this.changeField(e.target.name, e.target.value)} />
                </FzCol>
                <FzCol span={3}>
                  <FzForm.Label>CPF</FzForm.Label>
                  <FzForm.Control
                    name="personalNumber"
                    value={this.state.filter.personalNumber || ""}
                    onBlur={(e) => this.setCPFFilter(e.target.name, e.target.value)}
                    onKeyDown={this.sendOnEnter}
                    type="text"
                    onChange={(e) => this.changeField(e.target.name, e.target.value)} />
                </FzCol>
                <FzCol span={3}>
                  <FzForm.Label>Ticket Id</FzForm.Label>
                  <FzForm.Control
                    name="ticketId"
                    value={this.state.filter.ticketId || ""}
                    onBlur={(e) => this.setTicketFilter(e.target.name, e.target.value)}
                    onKeyDown={this.sendOnEnter}
                    type="text"
                    onChange={(e) => this.changeField(e.target.name, e.target.value)} />
                </FzCol>
              </FzRow>
            </FzCard.Body>
          </FzCard>
          {errorMessage}
          <FzCard>
            <FzCard.Body>
              <FzRow>
                <FzCol span={6}>
                  <FzForm.Label>Canal de atendimento:</FzForm.Label>
                  <CrmChannel
                    name="channel"
                    onChange={(e) => this.setChannel(e, this.props.ns)}
                    value={this.getChannel()} />
                </FzCol>
                <FzCol span={6}>
                  <FzButton onClick={this.doFindCustomer} fzStyle="discrete">Buscar Cliente</FzButton>
                </FzCol>
              </FzRow>
            </FzCard.Body>
          </FzCard>
          {clientsTable}
          {campaignApplicants}
        </FzCardGroup>
      </FzJumbotron>
    );
  }
}
