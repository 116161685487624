const isValidElectricBillNumber = (eletricBillNumber) => {
  const { number, date } = eletricBillNumber

  if(number.length === 0) {
    return {
      isValid: false,
      message: "O campo Número da Conta é obrigatório"
    }
  } else if(date.length === 0) {
    return {
      isValid: false,
      message: "O campo Data é obrigatório"
    }
  }

  return {
    isValid: true,
    message: ""
  }
}

export default isValidElectricBillNumber;
