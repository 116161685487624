import React, { useEffect, useState } from 'react';
import api from '../../../../api/client-api'
import { useHistory } from "react-router-dom";
import { routes, screeningRoot } from '../screening-index';
import {
  getFormalizationDocumentUrl,
  setDocAsPending,
  setDocAsValid,
  setDocAsRejected,
  setFieldsOverride,
  postponeAnalisys,
  uploadDocumentScreening,
  documentsToInsertScreening
} from '../../../../services/api/screening';
import { IndividualAnalizeContainer } from '../../../../Containers/Screening/IndividualAnalize';
import { formatterFile } from '../../../../api/formatter-file';
import { Screening } from './types';

const goTo = (history, subPath) => {
  history.push(screeningRoot + subPath)
}

const setAsPending = async (documentType, ruleKeysToSend, entireListOfRules, ns, callback, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow, history) => {

  try {
    const screeningResponse = await setDocAsPending(documentType, { 'pendedReasons': ruleKeysToSend })
    ns.setShared("SCREENING.screening_response", screeningResponse.data)
    if (callback) {
      ns.clear()
      callback()
    }
  } catch (err) {
    let error: $TsFixMe = err
    if (error.response.status === 404) {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost('Está análise não esta mais disponível, pegue o próximo novamente')
      seColorTost('danger')
      setShow(true)
      setTimeout(function () {
        if (history.location.pathname !== "/screening/queue") {
          history.push("/screening/queue")
        }
      }, 3000);
    } else {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost(error.response.data.message)
      seColorTost('danger')
      setShow(true)
    }
  }
}

const handleSetDocsValid = async (documentType, ns, callback, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow, history) => {
  try {
    const screeningResponse = await setDocAsValid(documentType)
    ns.setShared("SCREENING.screening_response", screeningResponse.data)

    if (callback) {
      ns.clear()
      callback()
    }
  } catch (err) {
    let error: $TsFixMe = err
    if (error.response.status === 404) {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost('Está análise não esta mais disponível, pegue o próximo novamente')
      seColorTost('danger')
      setShow(true)
      setTimeout(function () {
        if (history.location.pathname !== "/screening/queue") {
          history.push("/screening/queue")
        }
      }, 3000);
    } else {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost(error.response.data.message)
      seColorTost('danger')
      setShow(true)
    }
  }
}

const setRejectApplication = async (documentType, reason, ns, callback, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow, history) => {
  try {
    const screeningResponse = await setDocAsRejected(documentType, { reason })
    ns.setShared("SCREENING.screening_response", screeningResponse.data)
    if (callback) {
      ns.clear()
      callback()
    }
  } catch (err) {
    let error: $TsFixMe = err
    if (error.response.status === 404) {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost('Está análise não esta mais disponível, pegue o próximo novamente')
      seColorTost('danger')
      setShow(true)
      setTimeout(function () {
        if (history.location.pathname !== "/screening/queue") {
          history.push("/screening/queue")
        }
      }, 3000);
    } else {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost(error.response.data.message)
      seColorTost('danger')
      setShow(true)
    }
  }
}

function loadDomains(data, ns) {
  const { customerFields } = data
  const domains = customerFields.filter(it => it.type === 'domain')
  domains.forEach(async (current) => {
    let domainResult = await api.domains.getDomainValues(current.domainPath)
    ns.setShared(`SCREENING.domains.${current.domainPath}`, domainResult)
  });
}
function loadDocumentSubTypes(ns) {
  const domains = [{ domainPath: 'rg-document-type' }, { domainPath: 'income-proof-document' }]
  domains.forEach(async (current) => {
    let domainResult = await api.domains.getDomainValues(current.domainPath)
    ns.setShared(`SCREENING.domains.${current.domainPath}`, domainResult)
  });
}

function handleClickImage(setShowFullScreen, applicationId, bidId, documentType, setUrlImage, documentId) {
  const url = getFormalizationDocumentUrl(applicationId, bidId, documentType, 'png', documentId);
  setUrlImage(url);
  setShowFullScreen(true);
}

export function IndividualAnalysis(props) {

  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, seColorTost] = useState('')
  const [editingFields, setEditingFields] = useState(false)

  const { documentType, ns } = props
  const history = useHistory()
  const [showRejectDocModal, setShowRejectDocModal] = useState(false)
  const [showFullScreen, setShowFullScreen] = useState(false)
  const [urlImage, setUrlImage] = useState('')
  const [reason, setReason] = useState('')
  const [uploadModal, setUploadModal] = useState(false)
  const [postponeModalOpen, setPostponeModalOpen] = useState(false);

  const screening_response: Screening = ns.getShared("SCREENING.screening_response", {})
  const formalization_documents = ns.getShared("SCREENING.domains.formalization_documents", [])
  // joining incomeDomains and rgDomains to create documentsSubTypesDescriptions
  const incomeDomains = ns.getShared("SCREENING.domains.income-proof-document", [])
  const rgDomains = ns.getShared("SCREENING.domains.rg-document-type", [])
  const documentsSubTypesDescriptions = [...incomeDomains, ...rgDomains]

  useEffect(() => {
    loadDomains(screening_response, ns)
    loadDocumentSubTypes(ns)
    ns.clear()

    // if (doc && doc.state === 'validated' && doc.lastState === 'validated' && doc.isValidated) {
    //   goTo(history, nextPage)
    // }
  }, [])

  const handleAskNewBureauDoc = async () => {
    try {
      if (!doc) return

      const screeningResponse = await setDocAsValid(doc.documentType)
      ns.setShared("SCREENING.screening_response", screeningResponse.data)

      const documentTypeWithoutBV = doc.documentType.replace("-boa-vista", "")
      const newScreeningResponse = await setDocAsPending(documentTypeWithoutBV, { 'pendedReasons': ['new-doc-is-needed'] })
      ns.setShared("SCREENING.screening_response", newScreeningResponse.data)
      goTo(history, nextPage)
    } catch (err) {
      console.error(err)
    }
  }

  function handleClose() {
    setShow(false)
  }

  function toggleEditingFields() {
    setEditingFields(!editingFields)
  }

  /**
   * Open postpone modal
   */
  function handlePostponeModalOpen() {
    setPostponeModalOpen(true);
  }

  /**
   * Close postpone modal
   */
  function handlePostponeModalClose() {
    setPostponeModalOpen(false);
  }

  /**
   * Postpone screening
   */
  async function handlePostpone() {
    try {
      const response = await postponeAnalisys();

      if (response.status === 200) {
        ns.clear()
        history.push(`/screening/queue`);
        return;
      }
    } catch (err) {
      console.error({ err });
    }

  }

  async function modalClosure(maxAttempts = 5) {
    return async function showModal(docDescription: string) {
      if (!show) {
        setHeaderMessageTost('Tela inserida')
        setBodyMessageTost(`Uma nova tela do documento ${docDescription} foi inserida.`)
        seColorTost('success')
        setShow(true)
      } else if (maxAttempts > 0) {
        maxAttempts--;
        setTimeout(() => showModal(docDescription), 2500)
      }
    }
  }


  /**
   * Documents to insert
   */
  async function handleDocumentsToInsert(values) {

    if (values.length === 0) {
      setHeaderMessageTost('Erro ao inserir')
      setBodyMessageTost(`Selecione pelo menos uma opção para continuar.`)
      seColorTost('danger')
      setShow(true)
      return null 
    }

    try {
      const data = values.map(document => {
        if (document.indexOf('boa-vista') !== -1) {
          return {
            documentType: document.replace("-boa-vista", ""),
            finanzeroGenerated: true
          }
        }
        return {
          documentType: document,
          finanzeroGenerated: false
        }

      })

      const screeningResponse = await documentsToInsertScreening({
        documentsToInsert: data
      })

      if (screeningResponse.status === 200) {
        data.map( async ({ documentType }) => {
          const docDescription = getDescription(documentType)
          if (screeningResponse.data.documents.findIndex((it) => it.documentType === documentType) !== -1) {
            const showModal = await modalClosure()
            showModal(docDescription)
          }
        })
      }

      ns.setShared("SCREENING.screening_response", screeningResponse.data)
    } catch (error) {
      setHeaderMessageTost('Erro ao inserir')
      setBodyMessageTost(`Erro ao tentar inserir documento.`)
      seColorTost('danger')
      setShow(true)
      console.error(error)
    }
  }

  /**
     * Get description of document
     */
  function getDescription(documentType) {
    const domain = ns.getShared(`SCREENING.domains.formalization_documents`)
    if (Array.isArray(domain)) {
      const value = domain.find(it => it.code === documentType)?.description ?? "Não encontrado";
      return value
    }
  }

  if (!screening_response.id && !formalization_documents.length) {
    return <div>Não foi possível baixar os dados dos documentos.</div>
  }

  const applicationId = screening_response.applicationId
  const bidId = screening_response.bidId
  let currDocumentIndex: number = 0

  //  Checking if analysis is continuing from previous one 
  let DEFAULT_ROUTE = props.continuing ? routes.CONTINUE_ANALYSIS : routes.INDIVIDUAL_ANALYSIS
  let DEFAULT_FINAL_ROUTE = props.continuing ? routes.CONTINUE_FINAL_ANALYSIS : routes.FINAL_ANALYSIS
  if(props.continuing){
    screening_response.documents
    screening_response.documents = screening_response.documents.filter((obj) => {
      return obj.lastState === 'pending'
    })
  }

  const doc = screening_response.documents ? screening_response.documents.find((doc, index) => {
    if (doc.documentType === documentType) {
      currDocumentIndex = index
      return true
    }
  }) : null


  if (!doc) return <div>Documento não encontrado.</div>
  const documentSubType = documentsSubTypesDescriptions.find(item => item.code == doc.documentSubType);
  const docDomains = formalization_documents.find((docDomain) => {
    return docDomain.code === doc.documentType
  })

  let nextPage = ""
  let previousPage = ""

  if (screening_response.documents
    && screening_response.documents[currDocumentIndex + 1]
    && screening_response.documents[currDocumentIndex + 1].documentType) {
    nextPage = DEFAULT_ROUTE + "/" + screening_response.documents[currDocumentIndex + 1].documentType
  } else {
    nextPage = DEFAULT_FINAL_ROUTE
  }

  if (screening_response.documents
    && screening_response.documents[currDocumentIndex - 1]
    && screening_response.documents[currDocumentIndex - 1].documentType) {
    previousPage = DEFAULT_ROUTE + "/" + screening_response.documents[currDocumentIndex - 1].documentType
  }

  const partnerSpecificChecklist = doc.rules.filter(rule => {
    return !!rule.supplierInternalName
  })

  const nonPartnerSpecificChecklist = doc.rules.filter(rule => {
    return !rule.supplierInternalName
  })

  const partnerSpecificChecklistMeta = {
    "path": "partnerSpecificChecklist",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "options": partnerSpecificChecklist.map(rule => { return { code: rule.key, description: rule.description } }),
      "order": "left-to-right",
      "direction": "column",
    },
    isMandatory: (application) => false,
    "isReadOnly": () => false,
  }

  const nonPartnerSpecificChecklistMeta = {
    "path": "nonPartnerSpecificChecklist",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "options": nonPartnerSpecificChecklist.map(rule => { return { code: rule.key, description: rule.description } }),
      "order": "left-to-right",
      "direction": "column",
    },
    isMandatory: (application) => false,
    "isReadOnly": () => false,
  }

  const handleUploadFileScreening = async file => {
    try {
      const payload = await formatterFile(file)
      const response = await uploadDocumentScreening({ ...payload, documentType: doc.documentType })
      setEditingFields(true)
      ns.stopEditing()
      ns.setShared("SCREENING.screening_response", response.data)
      setUploadModal(false)
      setHeaderMessageTost('Edição de arquivo')
      setBodyMessageTost("Alterações salvas com sucesso!")
      seColorTost('success')
      setShow(true)
    } catch (error) {
      let err: any = error
      console.error(err)
      setUploadModal(false)
    }
  }

  const partnerSpecificChecklistFromNs = ns.getChanged("partnerSpecificChecklist") || []
  const nonPartnerSpecificChecklistFromNs = ns.getChanged("nonPartnerSpecificChecklist") || []
  const nOfSelect = partnerSpecificChecklistFromNs.length + nonPartnerSpecificChecklistFromNs.length

  const totalNumberOfDocs = screening_response.documents.length
  const currentDocIndex = screening_response.documents.findIndex(doc => { return doc.documentType === documentType })

  const ruleKeysNotToSend = partnerSpecificChecklistFromNs.concat(nonPartnerSpecificChecklistFromNs)

  const optionsReasons = doc.rejects.map(reject => {
    return { code: reject.key, description: reject.description }
  })
  // TODO: change to mimetype later
  const fmt = doc.documentType === 'address-eletricbill-number' ? '' : 'png';
  const documentUrl = getFormalizationDocumentUrl(applicationId, bidId, doc.documentType, fmt, doc.userDocumentId);

  partnerSpecificChecklistMeta.inputProps.options.sort(function (a, b) {
    return (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0);
  });

  nonPartnerSpecificChecklistMeta.inputProps.options.sort(function (a, b) {
    return (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0);
  });

  optionsReasons.sort(function (a, b) {
    return (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0);
  });

  return (
    <>
      <IndividualAnalizeContainer
        handleSuccessButton={handleDocumentsToInsert}
        ns={ns}
        showFullScreen={showFullScreen}
        urlImage={urlImage}
        doc={doc}
        docDomains={docDomains}
        screening_response={screening_response}
        nOfSelect={nOfSelect}
        documentUrl={documentUrl}
        ruleKeysNotToSend={ruleKeysNotToSend}
        previousPage={previousPage}
        editingFields={editingFields}
        currDocumentIndex={currDocumentIndex}
        applicationId={applicationId}
        bidId={bidId}
        setUrlImage={setUrlImage}
        uploadModal={uploadModal}
        optionsReasons={optionsReasons}
        reason={reason}

        nextPage={nextPage}
        setHeaderMessageTost={setHeaderMessageTost}
        setBodyMessageTost={setBodyMessageTost}
        seColorTost={seColorTost}
        setShow={setShow}
        postponeModalOpen={postponeModalOpen}

        handlePostponeModalClose={handlePostponeModalClose}
        handlePostpone={handlePostpone}

        setAsPending={setAsPending}
        setShowFullScreen={setShowFullScreen}
        handleSetDocsValid={handleSetDocsValid}
        goTo={goTo}
        setReason={setReason}
        setShowRejectDocModal={setShowRejectDocModal}
        handleClose={handleClose}
        handlePostponeModalOpen={handlePostponeModalOpen}
        setUploadModal={setUploadModal}
        handleAskNewBureauDoc={handleAskNewBureauDoc}
        toggleEditingFields={toggleEditingFields}
        getFormalizationDocumentUrl={getFormalizationDocumentUrl}
        handleClickImage={handleClickImage}
        handleUploadFileScreening={handleUploadFileScreening}
        showRejectDocModal={showRejectDocModal}
        setRejectApplication={setRejectApplication}

        documentSubType={documentSubType}
        totalNumberOfDocs={totalNumberOfDocs}
        currentDocIndex={currentDocIndex}
        partnerSpecificChecklistMeta={partnerSpecificChecklistMeta}
        nonPartnerSpecificChecklistMeta={nonPartnerSpecificChecklistMeta}
        formalization_documents={formalization_documents}

        colorTost={colorTost}
        show={show}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        setFieldsOverride={setFieldsOverride}
      />
    </>
  )
}