import React, { Component } from 'react'
import get from "lodash/get"
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

import { Namespace } from '../../../scripts/state/redux_ns';
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { fzTableDoExport } from '../formatter/export-data';
import { getAllColumns } from "./helpers-table"
import { FzIcons } from '../form/icon';
import { FzButton } from '../form';
import '../style/grid.css';
import CSVReader from 'react-csv-reader'
import UploadDocument from '../../../application-components/upload-document';
import { Button } from 'react-bootstrap';

export const getVisibleColumns = (
  visibleColumns,
  metas,
  ns,
  context,
  instance,
  hasSubsetVisibleColumns) => {
  let cols

  if (context && hasSubsetVisibleColumns !== true) {
    cols = ns.getShared("DynamicTable." + (instance || context))
  }

  if (hasSubsetVisibleColumns) {

    const dynamicColumns = ns.getShared("DynamicTable." + (instance || context))
    cols = visibleColumns.reduce((acc, current) => {
      if (current.indexOf("suppliers") === -1) {
        return [...acc, current]
      }

      if (Array.isArray(dynamicColumns) && dynamicColumns.indexOf(current) >= 0) {
        return [...acc, current]
      }

      return [...acc]
    }, [])
  }
  return cols || visibleColumns || getAllColumns(metas)
}


export class FzSelectVisibleColumns extends Component {

  constructor(props) {
    super(props);
    this.allOptions = []

    this.handleChange = this.handleChange.bind(this)

    const columnsPaths = getAllColumns(this.props.metas)


    for (let path of columnsPaths) {
      let colObject = get(this.props.metas, path)
      if (!colObject) {
        console.warn("FzSelectVisibleColumns: Invalid key")
      } else {
        this.allOptions.push({
          key: path,
          value: colObject.path,
          label: colObject.tableProps.shortLabel || colObject.label,
          isKey: colObject.tableProps.isKey
        })
      }
    }
  }

  handleChange(selected) {
    const { ns } = this.props
    let sel = []
    for (var key of this.allOptions) {
      if (selected.indexOf(key.key) >= 0) {
        sel.push(key.key)

      }
    }

    ns.setShared("DynamicTable." + (this.props.instance || this.props.context), sel)
  }

  render() {
    let selectedOptions = this.props.columnsPaths
    if (!selectedOptions || !this.allOptions) {
      return null
    }

    const listItems = this.allOptions.map((it, index) => {
      return (
        <ToggleButton
          className='ml-1 mb-1'
          type="button"
          variant="outline-primary"
          key={index}
          disabled={it.isKey}
          value={it.key}
          style={{ borderRadius: '3px', fontWeight: 'bold' }}
        >
          {it.label || it.tableProps.shortLabel}
        </ToggleButton>
      )
    })
    return (
      <ToggleButtonGroup
        type="checkbox"
        value={selectedOptions}
        onChange={this.handleChange}
        size="sm"
        style={{ display: "block" }}
      >
        {listItems}
      </ToggleButtonGroup>
    )
  }
}
export class FzTableCustomToolBar extends Component {
  constructor(props) {
    super(props)
    this.state = { showPanels: [], valueClipboard: "" }
    this.changePanels = this.changePanels.bind(this)
    this.exportCSV = this.exportCSV.bind(this)
    this.clipboardCsv = this.clipboardCsv.bind(this)
    this.renderToggleSelectColumn = this.renderToggleSelectColumn.bind(this)
    this.handleUploadCsvFile = this.handleUploadCsvFile.bind(this)
  }

  clipboardCsv() {
    const { data, metas, columnsPaths, context } = this.props
    return fzTableDoExport(data, metas, columnsPaths, false, context)
  }

  exportCSV() {
    const { data, metas, columnsPaths, context } = this.props
    fzTableDoExport(data, metas, columnsPaths, true, context)
  }

  changePanels(visiblePanels) {
    this.setState({ showPanels: visiblePanels })
  }

  renderToggleSelectColumn() {
    return <ToggleButtonGroup
      variant="default"
      size='sm'
      type="checkbox"
      value={this.state.showPanels}
      onChange={this.changePanels}
    >
      <ToggleButton
        variant="default"
        value={"select-columns"}
      >
        Selecione as colunas
      </ToggleButton>
    </ToggleButtonGroup>;
  }

  handleUploadCsvFile(event) {
    const dataForm = new FormData()
    for (const file of event.target.files) {
      dataForm.append('file', file)
    }
    this.props.importCsv.onUploadFile(dataForm, event)
  }

  render() {
    const { exportDownload, importCsv, handleUploadFile } = this.props

    let select = this.state.showPanels.indexOf("select-columns") >= 0 ?
      <FzSelectVisibleColumns {...this.props} /> : null;

    return (
      <div>
        <div style={{ margin: "0 0 5px", alignItems: "start", display: "flex", gap: "4px" }}>
          {this.props.clipboard !== false ? (
            <FzButton
              fzStyle={"regular"}
              size="sm"
              onClick={() => copy(this.clipboardCsv(), { format: "text/plain" })}
            >
              <FzIcons icon={"copy"} fzStyle={{ width: "15px", height: "13px", margin: "0 3px 0 0" }} />
              Copiar
            </FzButton>
          ) : (null)}

          {exportDownload !== false ? (
            <FzButton
              size="sm"
              fzStyle="info"
              onClick={this.exportCSV}>
              <FzIcons
                icon={"file-export"}
                fzStyle={{ width: "15px", height: "13px", margin: "0 3px 0 0" }}
              />
              Exportar dados em CSV
            </FzButton>) : (null)}

          {
            importCsv && (
              <UploadDocument
                handleUploadFile={this.handleUploadCsvFile}
              >
                <div
                  className="btn"
                  style={{
                    backgroundColor: '#723EDF',
                    color: '#fff',
                    padding: '0.25rem 0.5rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5',
                    borderRadius: '0.2rem',
                  }}
                  role="button"
                >
                  <FzIcons
                    icon={"file-import"}
                    fzStyle={{ width: "15px", height: "13px", margin: "0 3px 0 0" }}
                  />
                  Importar dados CSV
                </div>
              </UploadDocument>
            )}
          {
            this.props.context && this.props.selectColumns !== false ? (
              this.renderToggleSelectColumn()
            ) : (null)
          }
        </div>
        <div style={{ display: "block" }}>
          {select}
        </div>
      </div>
    )
  }
}

FzTableCustomToolBar.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }),
  context: PropTypes.string,
  instance: PropTypes.string,
  metas: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  columnsPaths: PropTypes.arrayOf(PropTypes.string)
}
