
import React, { Component } from 'react';
import { FzCard, FzBadge } from '../../fz/layout/index'
import { Button } from 'react-bootstrap';
import { FzIcons } from '../../fz/form/icon';
import { FzTable } from '../../fz/grid/table'
import api from '../../../api/client-api'
import { users } from '../../../biz/metadatas/users';

export class ClientsTable extends React.Component<any, any>  {
  constructor(props) {
    super(props)

    this.idColumn = this.idColumn.bind(this)
    this.createdColumn = this.createdColumn.bind(this)
    this.showApplicationForUser = this.showApplicationForUser.bind(this)
  }

  showApplicationForUser(usr) {
    this.props.startService(usr.id);
  }

  idColumn(cell, row) {
    return (
      <Button
        title={row['id']}
        onClick={() => this.showApplicationForUser(row)}
        size="sm"
        disabled={!row.active && api.isUserSales()}
      >
        Atender&nbsp;
        < FzIcons icon={"phone-volume"} fzStyle={{ width: "15px", height: "15px" }} />
      </Button>
    );
  };

  createdColumn(cell, row) {
    const createdDate = new Date(Date.parse(cell))
    let alert
    if (!row.active) {
      alert = <FzBadge fzStyle="alert">Inativo</FzBadge>

    }
    return (
      <span>{createdDate.toLocaleDateString('pt-BR')} {alert}</span>
    );
  };


  render() {

    return (
      <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title>
            Múltiplos Clientes Encontrados
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={this.props.clients}
            metas={users}
            ns={this.props.ns}
            visibleColumns={["id", "created", "name", "personalNumber", "telephoneNumber", "email"]}
            customColumnFormatters={{
              id: (cell, row) => this.idColumn(cell, row),
              created: (cell, row) => this.createdColumn(cell, row),
            }}
            exportDownload={false}
            clipboard={false}
          />
        </FzCard.Body>
      </FzCard>
    );
  }
}