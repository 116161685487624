import React from 'react'
import BlockUi from 'react-block-ui';
import { Jumbotron, Button, ButtonGroup } from 'react-bootstrap';
import { FzCol, FzRow, FzSplitLine } from '../../../ui/fz/layout';
import FieldConnected from '../../../ui/components/form/field-connected';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzCardGroup } from '../../../ui/fz/layout/';
import { UpsellBannerForm } from '../../../biz/metadatas/upsellBanner';
import { UpsellBanner } from '../../../services/api/dory/type';
import { validateAppField } from "../../../biz/validate-application";
import { FzToast } from '../../../ui/fz/form/notification/toast';

const EditUpsell = ({ 
  ns, 
  handleSubmit, 
  formData = {}, 
  goBack,
  toastInfo,
  handleToastClose,
  loading,
}) => {
  const formItems = Object.keys(UpsellBannerForm);
  
  const notAllowEditField = (field) => {
    const metaData = UpsellBannerForm[field]
    switch (field) {
      case "name":
        metaData.isReadOnly = () => true
        return metaData;
      default:
        return metaData;
    }
  } 

  const RenderFormItem = () => {
    return formItems.map((item, index) => (
      <FzCol span={6} key={index}>
        <FieldConnected
          meta={notAllowEditField(item)}
          data={formData}
          ns={ns}
        />
      </FzCol>
    ));
  }

  const formFieldIsEmpty = async data => {
    const checkIsEmptyFields = await Promise.all(
      formItems.map(item => {
        return validateAppField({ 
          value: data[item], 
          data, 
          ns, 
          metaField: UpsellBannerForm[item],
        })
      })
    )

    const invalidFields = checkIsEmptyFields
      .filter(({ valid }) => !valid)
      .map(({ path }) => path)

    ns.set("INVALID", invalidFields)

    return invalidFields
  }

  const handleSubmitAndCheckForm = () => {
    const values: UpsellBanner = ns.getChanged();
    formFieldIsEmpty(values)
      .then(fields => handleSubmit(values, fields))
  }

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <Jumbotron>
        <ButtonGroup>
          <Button 
            variant="primary"
            onClick={handleSubmitAndCheckForm}
          >
            Gravar
          </Button>
          <Button
            variant="default"
            onClick={goBack}
          >
            Cancelar
          </Button>
        </ButtonGroup>
        <FzCardGroup bsPrefix="upsell-form">
          <FzCard>
            <FzCard.Header>
              <FzSplitLine>
                <FzSplitLine.Left>
                  <FzCard.Title>Editar Upsell</FzCard.Title>
                </FzSplitLine.Left>
              </FzSplitLine>
            </FzCard.Header>

            <FzCard.Body>
                <FzRow>
                  {RenderFormItem()}
                </FzRow>
            </FzCard.Body>
          </FzCard>
        </FzCardGroup>
        <FzToast {...toastInfo} close={handleToastClose} />
      </Jumbotron>
    </BlockUi>
  )
}

export default EditUpsell;
