import { useState } from "react";
import { FzField } from "../../../../fz/form"
import { FzSelect } from "../../../../fz/form/old-simple-input";

export function RenderInput({ meta, priority, ns, index, supplier, onChange, options, isEditing }) {
  const [value, setValue] = useState((getDescription() || meta.path != "supplierInternalName") ? priority[meta.path] : "");

  (value != "") ? (getDescription()) ? options.push(getDescription()) : {} : {};

  const setOptions = new Set();
  options = options.filter((item) => {
    const duplicatedOptions = setOptions.has(item.code);
    setOptions.add(item.code);
    return !duplicatedOptions;
  });

  meta.readOnly = !isEditing.isEditing;

  function getDescription() {
    if (meta.path == "supplierInternalName") {
      return supplier.suppliers.reduce((prev, s) => {
        if (s.code == priority[meta.path]) {
          return {
            code: value,
            description: s.description
          }
        }
        return prev
      }, {});
    } else if (meta.path == "branchCode") {
      return supplier.suppliers.reduce((prev, el) => {
        if (priority["supplierInternalName"] == el.code) {
          return el.branches.reduce((prevBranch, branch) => {
            if (branch.code == priority[meta.path]) {
              return {
                code: value,
                description: branch.description
              }
            }
            return prevBranch;
          }, {});
        }
        return prev;
      }, {});
    }
  }

  function handleChangeValue(e) {
    onChange({
      path: meta.path,
      index,
      value: e
    });
    setValue(e);
  }

  function InputTextOrNumber() {
    return <FzField
      name={meta.path}
      dataType={meta.dataType}
      onChange={handleChangeValue}
      inputType={meta.inputType}
      readOnly={meta.readOnly}
      customClass={meta.customClass}
      value={value}
      label={meta.label}
      placeHolder={meta.placeholder}
      mandatory={meta.isMandatory}
      validationMessage={meta.validationMessage}
      invalid={ns.isInvalid(meta.path)}
      isJSON={meta.isJSON}
      informationMessage={meta.informationMessage}
    />
  }

  function SelectFilter() {
    return (
      <div>
        <label>{meta.label}{meta.isMandatory ? " *" : ""}</label>
        <FzSelect
          placeHolder={meta.path == "branchCode" ? "Todas as branches" : undefined}
          value={value}
          onChange={handleChangeValue}
          name={meta.path}
          readOnly={meta.readOnly}
          options={options}
        />
      </div>
    );
  }

  const typeInput = {
    text: <InputTextOrNumber />,
    number: <InputTextOrNumber />,
    select: <SelectFilter />

  }

  return typeInput[meta.inputType]
}