const formatToFloat = (value = "") =>{
  let result = 0
  if(value) {
    result = parseFloat(
      value
        .replace(/\./g, '')
        .replace(/,/g, '.')
    );
  }

  return result
}

const formatCurrency = function(amount) {
  let result = amount && parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
  return result && (
    result.substring(0, result.length - 3) 
    + "," 
    + result.substring(result.length - 2, result.length)
  )
}

export {
  formatToFloat,
  formatCurrency,
}
