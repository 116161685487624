import React from 'react';
import { CustomerDetails } from '../ui/blocks/crm/customer-details';
import { CustomerApplications } from '../ui/blocks/crm/customer-applications';
import { setCustomerDataToNs } from "../biz/user";
import { FzCardGroup, FzJumbotron } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';

class CustomerConsole extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editingCustomer: false }
    this.exitCustomerView = this.exitCustomerView.bind(this);
    this.editCustomer = this.editCustomer.bind(this)
    this.basePath = this.props.basePath
  }
  editCustomer() {
    this.setState({ editingCustomer: true })
  }
  exitCustomerView() {
    this.props.history.push(this.basePath)
  }
  componentDidMount() {
    setCustomerDataToNs(this.props.match.params.customerId, this.props.ns)
  }
  render() {
    const { ns } = this.props
    const cd = ns.get("customer")
    if (!cd) {
      return null
    }
    let serverMessages = null
    if (cd.serverMessages) {
      serverMessages = []
      cd.serverMessages.forEach((m, i) => {
        serverMessages.push(<p key={i}>{m}</p>)
      });
      serverMessages = <FzAlert fzStyle="attention">{serverMessages}</FzAlert>
    }
    return (
      <FzJumbotron>
        <FzCardGroup id="1">
          <CustomerDetails customer={cd.customer} ns={this.props.ns} />
          {serverMessages}
        </FzCardGroup>
        <CustomerApplications
          customer={cd.customer}
          applications={cd.applications}
          ns={this.props.ns}
          history={this.props.history}
          basePath={this.basePath}
          onNeedUpdate={() => ns.unset("customer")}
          priorityAttendance={ns.get("priorityAttendance")}
        />

      </FzJumbotron>
    );
  }
}

export { CustomerConsole }
