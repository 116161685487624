import api from "../../../api/client-api"
import { deleteCustomerWithReason } from "../../../api/users"

import { Modal } from 'react-bootstrap';
import { FzField } from "../../fz/form/field";
import {
  ExcludeApplicationsResponse,
  normalizeCardId,
  CounterType,
  isCardIdValid,
  StateType
} from '../../../biz/metadatas/delete-user';
import { useEffect, useState } from 'react';
import { FzButton, FzButtonGroup } from '../../fz/form';

export function DeleteCustomerModal({ ns, history, closeStateModals }) {
  const [usersToExclude, setUsersToExclude] = useState<Array<string>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [cardId, setCardId] = useState<string>();
  const [isCardIdValidState, setIsCardIdValidState] = useState<boolean>(false);
  const [counter, setCounter] = useState<CounterType>({
    usersCount: 0,
    applicationsCount: 0,
    campaignsCount: 0,
    errorsCount: 0
  });

  const user = api.getCurrentUser();
  const btns = [
    {
      description: "Cancelar",
      fzStyle: "regular",
      onClick: () => closeModal(),
      itemDisabled: () => false
    },
    {
      description: "Confirmar Exclusão",
      fzStyle: "alert",
      onClick: () => handleConfirmButton(cardId, usersToExclude, setCounter),
      itemDisabled: () => handleDisableConfirmButton(cardId, isCardIdValidState)
    },
  ]

  useEffect(() => {
    getUsersToExclude(ns, usersToExclude, setUsersToExclude)
    setShowModal(true)
  }, []);

  useEffect(() => {
    const isValid = isCardIdValid(String(cardId));
    setIsCardIdValidState(isValid)
  }, [cardId])


  useEffect(() => {
    if (counter.usersCount > 0) {
      setDeleted(true)
    } else if (counter.errorsCount > 0) {
      console.error("some errors has occour while trying to delete users", counter);
    }
  }, [counter]);

  function closeModal() {
    setShowModal(false)
    closeStateModals()
  }

  return (
    <>
      <Modal style={{ marginTop: "50px" }} show={showModal} dialogClassName="modal-form" keyboard={true}>
        <Modal.Header><h4 style={{ marginLeft: "auto", marginRight: "auto" }}>Exclusão de usuário</h4></Modal.Header>
        <Modal.Body>
          {!deleted && <>
            <strong>Atenção:</strong> essa operação irá tentar excluir <strong>{typeof usersToExclude === "string" ? 1 : usersToExclude.length} proposta(s)</strong> e a(s) <strong>campanha(s)</strong> relacionadas.
            <p></p>
            <FzField
              name="cardId"
              label="Card ID*"
              dataType="string"
              inputType="text"
              doNormalize={(value: string) => normalizeCardId(value)}
              invalid={!isCardIdValidState}
              onChange={(value) => setCardId(value)}
              placeHolder={"HD-1234"}
              value={cardId}
            ></FzField>
            <p>Usuário logado: <b>{user.name} ({user.email})</b></p></>}
          {deleted && <>
            Usuários excluídos: {counter.usersCount} <br />
            Applications excluídas: {counter.applicationsCount} <br />
            Campanhas excluídas: {counter.campaignsCount} <br />
            Houveram erros?: {counter.errorsCount ? `Sim: ${counter.errorsCount} erros` : "Não"} <br />
          </>}
        </Modal.Body>
        <Modal.Footer>
          {!deleted &&
            <FzButtonGroup
              buttons={btns}
            />
          }
          {deleted &&
            <FzButton
              onClick={() => { closeModal(); history.push("/applications/") }}
              fzStyle="primary">Fechar</FzButton>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}

function handleDisableConfirmButton(cardId: string | undefined, isCardIdValidState: boolean): boolean {
  if (cardId && isCardIdValidState) {
    return false
  }
  return true
}

function getUsersToExclude(ns: any, usersToExclude: Array<string>, setUsersToExclude: StateType) {
  const appsToExclude = ns.getChanged() || ns.get("application");

  if (!appsToExclude) {
    console.error("couldnt find application to exclude", appsToExclude);
    return;
  }
  let idsToDelete: Array<string>;

  if (Array.isArray(appsToExclude)) {
    idsToDelete = appsToExclude.reduce((acc: Array<string>, app: { customerId: string; }) => {
      return app && app.customerId ? [...acc, app.customerId] : [...acc];
    }, []);
  } else {
    idsToDelete = appsToExclude && [appsToExclude.customerId]
  }

  if (Array.isArray(idsToDelete) && idsToDelete.length > 0) {
    const uniqueIds = idsToDelete.filter(customerId => !usersToExclude.includes(customerId));
    setUsersToExclude([...usersToExclude, ...uniqueIds]);
  }
}

function countResults(results: Array<ExcludeApplicationsResponse>) {
  const counterResults: CounterType = results.reduce((acc, response) => {
    if (response.userId) {
      acc.usersCount += 1;
      acc.applicationsCount += response.applicationsCount;
      acc.campaignsCount += response.campaignsCount;
    } else {
      acc.errorsCount += 1;
    }
    return acc;
  }, { usersCount: 0, applicationsCount: 0, campaignsCount: 0, errorsCount: 0 });
  return counterResults;
}

async function handleConfirmButton(cardId: string | undefined, usersToExclude: Array<string>, setCounter: StateType) {
  if (!cardId) {
    return;
  }

  let results: Array<ExcludeApplicationsResponse> = []
  for await (const userId of usersToExclude) {
    try {
      results.push(await deleteCustomerWithReason(userId, "force-delete", cardId, true))
    } catch (error) {
      console.error("API Error ", error)
    }
  }

  const counterResults = countResults(results)
  setCounter(counterResults)
}