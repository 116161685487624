import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import EditUpsellContainer from '../../../../Containers/UpsellBanner/Edit'
import { PutUpsellBanner, GetIdUpsellBanner } from '../../../../services/api/dory'
import { UpsellBanner } from '../../../../services/api/dory/type'

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}

const EditUpsell = ({ ns }) => {
  const { upsellId } = useParams<{ upsellId: string }>();
  let history = useHistory();

  const [formData, setFormData] = useState({});
  const [toastInfo, setToastInfo] = useState(toastInitialConfig);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    buildform()
    return function willMount() {
      ns.clear();
    }
  }, [])

  const handleToastClose = () => 
    setToastInfo(toastInitialConfig);

  const handleSubmit = async (values: UpsellBanner, fieldsInvalid) => {
    try {
      if(fieldsInvalid <= 0) {
        const payload = {
          title: values.title,
          highlight: values.highlight,
          subtitle: values.subtitle,
          link: values.link,
          image: values.image,
          name: values.name,
          gaEvent: values.gaEvent
        }
        setLoading(true)
        await PutUpsellBanner(upsellId, payload);
        goToUpsellList();
        ns.unset("upsellForm");
        setLoading(false)
        ns.stopEditing();
      }
    } catch (error) {
      setToastInfo({...toastInfo, show: true });
    }
  }

  const goToUpsellList = () => {
    ns.stopEditing();
    history.push("/upsell/list");
  }


  const buildform = async () => {
    try {
      const { data = {}} = await GetIdUpsellBanner(upsellId)
      ns.set("upsellForm", data);
      ns.startEditing(data);
      setFormData(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setToastInfo({...toastInfo, show: true });
    }
  }

  return (
    <EditUpsellContainer 
      ns={ns} 
      handleSubmit={handleSubmit} 
      formData={formData}
      goBack={goToUpsellList}
      toastInfo={toastInfo}
      handleToastClose={handleToastClose}
      loading={loading}
    />
  )
}

export default EditUpsell;
