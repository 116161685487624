import React, { ReactNode } from 'react'
import { FzIcons } from '../ui/fz/form/icon'
interface UploadDocumentProps {
  children?: ReactNode;
  handleUploadFile: (file) => void;
}

const UploadDocument = ({ 
  children, 
  handleUploadFile, 
}: UploadDocumentProps
) => {
  const renderContent = (
    children 
      ? children
      : (
        <FzIcons 
          icon={"long-arrow-up"} 
          fzStyle={{ width: "15px", height: "15px" }}
        />
      )
  ) 

  return (
    <label style={{ display: "inline-block" }}>
      {renderContent}
      <input 
        name="upload-file"
        type="file" 
        onChange={handleUploadFile} 
        style={{ display: "none" }} 
      />
    </label>
  )
}

export default UploadDocument