import React from 'react';
import { FzRow, FzCol } from '../../fz/layout/index';
import { ExistingComments, NewComment } from '../../../application-components/comments';
import { CustomerValidate } from '../../../crm/active-applications';
import { FzTable } from '../../fz/grid/table';
import { Application } from '../../../biz/metadatas/application';
import api from '../../../api/client-api'
import { AuditLog } from '../application-components/audit-log';

class ApplicationDetail extends React.Component {
  render() {
    const { ns, app, history, customer } = this.props
    const auditlog = []
    let actions = (
      <FzRow>
        <FzCol span={6}>
          <NewComment ns={ns} data={app} history={history} onApplicationUpdated={this.props.onNeedUpdate} />
          <ExistingComments ns={ns} data={app} />
        </FzCol>
        <FzCol span={6}>
          <AuditLog auditLog={auditlog} ns={ns} />
        </FzCol>
      </FzRow>);

    return (
      <div>
        <CustomerValidate app={app} customer={customer} />
        {actions}
      </div>
    );
  }
}

class CustomerLeads extends React.Component {
  constructor(props) {
    super(props)
    this.expandComponent = this.expandComponent.bind(this)
  }

  expandComponent(row, customer, ns, onNeedUpdate) {
    return !api.isUserInRole("admin") ? (<ApplicationDetail app={row}
      customer={customer}
      ns={ns}
      onNeedUpdate={onNeedUpdate} />) : null
  }

  render() {
    const { priorityAttendance, customer, ns, onNeedUpdate, applications } = this.props

    if (!applications) {
      return null
    }

    const expanding = []
    if (priorityAttendance) {
      expanding.push(priorityAttendance.id)
    }

    return (
      <FzTable
        data={applications}
        ns={ns}
        metas={Application.fields}
        visibleColumns={["id", "created", "applicationState", "product", "appliedAmount"]}
        rowExpand={{
          expandComponent: (row) => this.expandComponent(row, customer, ns, onNeedUpdate),
          onExpandRow: () => expanding
        }}
        context={"customerLeads"}
      />
    );
  }
}
export { CustomerLeads }



