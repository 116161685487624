import api from './client-api';

export const getUsers = (role, email, personalNumber, telephoneNumber) => {
  var params = ""
  if (email && email !== "" && email !== null)
    params = params + "&email=" + email
  if (personalNumber && personalNumber !== "" && personalNumber !== null)
    params = params + "&personalNumber=" + personalNumber
  if (telephoneNumber && telephoneNumber !== "" && telephoneNumber !== null)
    params = params + "&telephoneNumber=" + telephoneNumber

  return api.Get('/auth/all?pageSize=200&role=' + role + params);
}

export const updateUser = (userId, user) => {
  return api.Put('/auth/' + userId, user);
}

export const createUser = (user) => {
  return api.Post('/auth', user);
}

export const getUserById = (id) => {
  return api.Get('/auth/' + id);
}

export const activatePassword = (id, password) => {
  let data = { newPassword: password }
  return api.Put("/auth/" + id + "/password", data);
}

export const deactivatePassword = (id) => {
  return api.Delete("/auth/" + id + "/password");
}

export const deleteCustomer = (customerId) => {
  return api.Delete("/auth/customer/" + customerId)
}

/**
 * Represents the response object returned by the '/auth/customer/:id' API endpoint.
 *
 * @typedef {object} ExcludeApplicationsResponse
 * @property {string} userId - The ID of the user that was excluded from the applications.
 * @property {number} applicationsCount - The number of applications that were excluded.
 * @property {number} campaignsCount - The number of campaigns that were excluded.
 */

/**
 * Deletes a customer with the specified ID and a reason for deletion.
 * The reason is stored in the database as 'deletedReason' and as 'customerDeletedReason'.
 *
 * @param {string} customerId - The ID of the customer to delete.
 * @param {string} reason - The reason for deleting the customer, should be a domain from "deleted-reasons".
 * @param {string} [customerReason] - Additional reason for deletion, should be the Help Desk ID or the custom reason provided by the customer.
 * @param {boolean} [force] - Whether to force the deletion of the customer and associated data.
 *
 * @returns {Promise<ExcludeApplicationsResponse>} A Promise that resolves to the API response object.
 */
export const deleteCustomerWithReason = (customerId, reason, customerReason = null, force = false) => {
  let url = '/auth/customer/' + customerId + '?reason=' + reason;
  if (customerReason) {
    url = url.concat('&customerReason=' + customerReason);
  }
  if (force) {
    url = url.concat('&force=' + force);
  }
  return api.Delete(url);
};