import React, { useState } from 'react';
import { FzButton } from '../../fz/form'
import { useHistory } from "react-router-dom";
import { routes, ScreeningTitle } from './screening-index';
import { FzToast } from '../../fz/form/notification/toast'
import { claimNextScreening } from '../../../services/api/screening';




const Queue = (props) => {
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, seColorTost] = useState('')
  const history = useHistory();

  function handleClose() {
    setShow(false)
  }

  return <>
    <ScreeningTitle step={routes.QUEUE} />
    <div style={{ padding: "2rem", textAlign: "center", border: "solid 1px lightgrey", background: "lightgrey" }}>
      <div>
        <FzButton fzStyle="attention" onClick={async () => {

          try {
            const ScreeningResponse = await claimNextScreening()

            if (ScreeningResponse.status === 204) {
              setHeaderMessageTost('Screening')
              setBodyMessageTost("Hm, parece que não temos novos clientes na fila. Pausa para um café :D")
              seColorTost('warning')
              setShow(true)
            } else {
              props.ns.setShared("SCREENING.screening_response", ScreeningResponse.data)
              setHeaderMessageTost('Screening')
              setBodyMessageTost('Análise Iniciada')
              seColorTost('success')
              setShow(true)
              setTimeout(() => {
                history.push("/screening/initial-analysis")
              }, 3000)
            }
          } catch (error) {
            if (error?.response?.status === 409) {
              setHeaderMessageTost('Screening')
              setBodyMessageTost('Hum, você tem uma avaliação em andamento aguarde...')
              seColorTost('warning')
              setShow(true)
              setTimeout(() => {
                history.push("/screening/initial-analysis")
              }, 3000)
            } else {
              setHeaderMessageTost('Screening error')
              setBodyMessageTost(error?.response?.data?.message)
              seColorTost('danger')
              setShow(true)
            }
          }

        }}>Pegar próximo item da fila</FzButton>
      </div>
    </div>
    <FzToast
      fzStyle={colorTost}
      close={handleClose}
      show={show}
      delay={5000}
      headerMessageTost={headerMessageTost}
      bodyMessageTost={bodyMessageTost}
      autoHide='true'
    >
    </FzToast>
  </>

}

export {
  Queue
}