import api from './client-api';

// LEGACY CODE
export const zipCodeAddress = (zipCode) => {
  return api.Get('/zipcode/' + zipCode)
}

export const addressByZipCode = (filters) => {
  return api.Get(`/zipcode/${filters.zipCode}`)
}

export const cityByName = (filters) => {
  return api.Get(`/ziptown/${filters.state}/${filters.city}`)
}
export const cityByIBGE = (filters) => {
  return api.Get(`/ziptown/ibge/${filters.ibge}`)
}