import React from 'react'
import { getParameterKeys, getParameterValue, saveParameterValue, deleteParameterKey } from '../../../api/parameters-service'
import { FzButton } from '../../fz/form'
import { FzCard, FzSplitLine } from '../../fz/layout/index'
import { domainsParameters } from '../../../biz/metadatas/parameters'
import clone from "lodash/cloneDeep"
import {PresenterParametersSystem } from './presenterParametersSystem'

class EditSystemParam extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      domainName: this.props.match.params.domainName,
      dynamicMetas: {},
      requestState: "idle",
    }
    props.ns.startEditing()
    this.onCancel = this.onCancel.bind(this)
    this.onSave = this.onSave.bind(this)
    this.delete = this.delete.bind(this)
    this.createdButtons = this.createdButtons.bind(this)
    this.backToPage = this.backToPage.bind(this)
    this.getValidMeta = this.getValidMeta.bind(this)
  }

  createdButtons(field, ns, needDisabled) {

    return [
      {
        fzStyle: "",
        description: "Editar",
        onClick: () => this.editing((
          domainsParameters[this.state.domainName] === undefined ? 
          ns.get("dynamicMetas")[field].path : 
          domainsParameters[this.state.domainName][field].path
        )),
        itemDisabled: () => { },
        className: `editar-${field}`
      },
      {
        fzStyle: "regular",
        description: "Gravar",
        onClick: () => this.onSave((
          domainsParameters[this.state.domainName] === undefined ? 
          ns.get("dynamicMetas")[field].path : 
          domainsParameters[this.state.domainName][field].path
        )),
        itemDisabled: () => needDisabled,
      },
      {
        fzStyle: "attention",
        description: "Cancelar",
        onClick: this.onCancel,
        itemDisabled: () => needDisabled,
      },
      {
        fzStyle: "alert",
        description: "Deletar",
        onClick: () => this.delete((
          domainsParameters[this.state.domainName] === undefined ? 
          ns.get("dynamicMetas")[field].path : 
          domainsParameters[this.state.domainName][field].path
        )),
        itemDisabled: () => { },
      }
    ]
  }

  async getAllKeyByDomain(domainName) {
    const { ns } = this.props;   
    if(this.state.requestState === 'idle') {
      try {
        this.setState({ requestState: 'requesting' });
        const resultAllKeys = await getParameterKeys(domainName); 
        let data = {}
        const dynamicMetas = await resultAllKeys.reduce(async(prev, curr)=> {
          const acc = await prev;  
          const result = await getParameterValue(domainName, curr)
            data[curr] = result.value
            const testeMetas = clone(
                      domainsParameters['domainDefault'].template
                    );
            testeMetas.path = curr;
            testeMetas.label = curr;
           acc.push({[curr] : testeMetas});
           return acc;
        },Promise.resolve([]));
        ns.set('dynamicMetas', Object.assign({}, ...dynamicMetas));
        ns.startEditing({ ...ns.getChanged(), ...data });
      } catch (error) {
        this.setState({ requestState: 'error' });
      }
    }
  }

  editing(field) {
    this.setState({ editingField: field })
  }

  onCancel(field) {
    const { ns } = this.props
    ns.stopEditing()
    this.setState({ editingField: "" })
    ns.startEditing()
  }

  onSave(field) {
    const { ns } = this.props
    const value = ns.getChanged(field)
    const type = domainsParameters[this.state.domainName][field].dataType
    const originType = {
      "number": "int",
      "float64": "float64",
      "date": "Time",
      "map": "map",
    }[type]
    const data = { value, originType }
    saveParameterValue(this.state.domainName, field, data)
      .then(data => 
        this.setState({ editingField: value })
      )
      .catch(err => 
        console.err(err)
      )
  }

  delete(field) {
    let confirmation = confirm("Are you sure you want to delete")
    if ( confirmation === true ) {
      deleteParameterKey(this.state.domainName, field)
      .then(() => {
        this.setState({ editingField: "" })
        this.props.history.push("/parameters/config")
      })
      .catch((err) => {
        console.err(err)
      })
    }
  }


  backToPage() {
    return this.props.history.push("/parameters/config/")
  }

  getValidMeta(dynamicMetas, field) {
    return (domainsParameters[this.state.domainName] === undefined ? dynamicMetas[field] : domainsParameters[this.state.domainName][field])
  }

  render() {
    const { ns } = this.props

    let data = ns.getChanged();
    let { requestState } = this.state;

    if (!data) {
      if (requestState === "idle") {
        this.getAllKeyByDomain(this.state.domainName)
      }
      return null
    }

    if (!data && requestState === "requesting") {
      return null
    }

    const dynamicMetas = ns.get("dynamicMetas")
    
    const fieldsEleme = (
      domainsParameters[this.state.domainName] === undefined ? 
      Object.keys(dynamicMetas) : 
      Object.keys(domainsParameters[this.state.domainName])).map(field => {

        let buttons = this.createdButtons(field, ns, 
          (domainsParameters[this.state.domainName] === undefined ? 
            true : 
            this.state.editingField !== domainsParameters[this.state.domainName][field].path)
          );
      
        return (
          <PresenterParametersSystem
            key={field}
            meta={this.getValidMeta(dynamicMetas, field)}
            data={data}
            ns={ns}
            buttons={buttons}
            readOnly={(
              domainsParameters[this.state.domainName] === undefined ? 
              true : 
              this.state.editingField !== domainsParameters[this.state.domainName][field].path
            )}
          />
        )
      })

    return (
      <FzCard >
        <FzCard.Heading>
          <FzSplitLine>
            <FzSplitLine.Left>
              <FzCard.Title>
                {this.state.domainName}
              </FzCard.Title>
            </FzSplitLine.Left>
            <FzSplitLine.Right>
              {
                <FzButton fzStyle="attention" onClick={() => this.backToPage()}>
                  Voltar
                </FzButton>
              }
            </FzSplitLine.Right>
          </FzSplitLine>
        </FzCard.Heading>
        <FzCard.Body>
          {fieldsEleme}
        </FzCard.Body>
      </FzCard>
    )
  }
}

export { EditSystemParam }

