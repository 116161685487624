import { ReactNode } from "react";
import { FzCard } from "../../ui/fz/layout/FzCard";
import { FzNavSelect } from "../../ui/fz/nav";
import { SummariesReport } from "./SummariesReport";
import { FieldValidationContainerProps } from "./types";

export function FieldValidationContainer({
  ns,
  data,
  handleSearchReport
}: FieldValidationContainerProps) {
  const contents = {
    summaries: (
      <SummariesReport
        ns={ns}
        data={data}
        handleSearchReport={handleSearchReport}
      />
    ),
  }

  return (
    <>
      <FzCard>
        {contents["summaries"] || 'Aguardando seleção de relatório'}
      </FzCard>
    </>
  )
}

