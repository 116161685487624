import React from 'react'
import { FzForm, FzRow, FzCard, FzCol, FzBadge, FzAccordion } from "../fz/layout/index";
import { FzAlert } from '../fz/form/alert'
import { FzTable } from "../fz/grid/table";
import { bidsAnalytic } from '../../biz/metadatas/stats-bids'

import { AnalysisPanel } from '../blocks/screening/analysis-table'

import { Badge, Button, Form, FormGroup, Modal } from 'react-bootstrap'
import copy from 'copy-to-clipboard';
import api from '../../api/client-api';
import { refuseBid, acceptBid, reSendEmailBid } from "../../api/applications";
import { getFormalization } from '../../api/formalization'

import moment from 'moment';
import { AlternateOffers } from './alternateOffers'
import { get } from 'lodash'
import { Formalization } from './formalization/formalization'
import { FzIcons } from '../fz/form/icon';
import { EditBid } from '../blocks/editBit'
import { getCurrentUser } from '../../api/auth';
import { FzButton } from '../fz/form';
import { amountColumn, pctColumn } from '../../components/table-standard';
import { ConfirmationSteps } from './confirmation-steps';
const AUCTION_ACTION_RESEND_EMAIL = 'resend-email';
const AUCTION_ACTION_ACCEPT_BID = 'accept-bid';



export class RefuseBid extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      open: false,
      refuseReason: "",
      refuseValues: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveButton = this.saveButton.bind(this);

  }

  onKeyPressEvent(event) {
    if (event.keyCode === 27) {
      this.props.closeButton();
    }

  }
  componentDidMount() {
    api.domains.getDomainValues("bid-refused-reason")
      .then((domainValues) => this.setState({ "refuseValues": domainValues }))
      .catch((err) => console.warn(err))
  }

  handleChange(e) {
    this.setState({ refuseReason: e.target.value })
  }

  saveButton() {
    refuseBid(this.props.app.id, this.props.ns.get('bid').id, this.state.refuseReason)
      .then((newapp) => {
        let treatment = this.props.ns.get("crm.treatments." + this.props.app.id) ? this.props.ns.get("crm.treatments." + this.props.app.id) + " * Bid Recusado" : "Bid Recusado";
        let treatmentArray = this.props.ns.get("crm.treatmentsArray." + this.props.app.id) ? this.props.ns.get("crm.treatmentsArray." + this.props.app.id) : [];
        treatmentArray.push(this.state.refuseReason);
        this.props.ns.set("crm.treatments." + this.props.app.id, treatment);
        this.props.ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);
        this.props.ns.set("crm.postpone." + this.props.app.id, moment().add(+15, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate())

        this.props.ns.set('application', newapp)
        this.props.closeButton()
      })
      .catch((error) => { console.warn("Error in refuseBid", error) })
  }
  getValidationState() {
    return 'success';
  }


  render() {



    let options
    if (this.state.refuseValues) {
      options = this.state.refuseValues.map((v) => <option key={v.code} value={v.code}>{v.description}</option>)
    }
    return (
      <Modal style={{ marginTop: "50px" }} show={this.props.show} onHide={this.props.closeButton} dialogClassName="modal-form" keyboard={true} >
        <Modal.Body>
          <Form horizontal>
            <FormGroup>
              <FzCol componentClass={FzForm.Label} sm={3}>
                Razão da rejeição
              </FzCol>
              <FzCol span={4}>
                <FzForm.Control
                  as="select"
                  name="closedReasonList"
                  placeholder="selecione..."
                  value={this.state.refuseReason}
                  onChange={this.handleChange}>
                  <option value="">Selecione...</option>
                  {options}
                </FzForm.Control>
              </FzCol>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.closeButton}>Cancelar</Button>
          <Button fzStyle="primary" onClick={this.saveButton} disabled={this.state.refuseReason.length === 0}>Confirmar Rejeição</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export class BidDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = { formalizationState: undefined }
  }

  componentDidMount() {
    api.domains.getDomainValues("formalization-state")
      .then((domainValues) => this.setState({ "formalizationState": domainValues }))
      .catch((err) => console.warn(err))
  }

  render() {
    const { ns, app, bid } = this.props

    let editingBid = null
    let alternateOffers = null
    let formalizationData = null

    const user = getCurrentUser()
    let preferredPartners = user.preferredPartners || []

    if ((app.applicationState === 8 || app.applicationState === 64) &&
      !bid.rejected &&
      bid.simulation &&
      api.isUserInRole("advisor") &&
      preferredPartners.indexOf(bid.supplier.internalName) !== -1
    ) {
      editingBid = <EditBid ns={ns} app={app} bid={bid} />
    }

    if (bid.formalizationId) {
      formalizationData = ns.get(bid.formalizationId)
      if (formalizationData === undefined) {
        getFormalization(app.id, bid.id)
          .then((f) => {
            ns.set(f.id, f)
          });
      }
    }


    let FormalizationState_Signing

    if (this.state.formalizationState &&
      Array.isArray(this.state.formalizationState) &&
      this.state.formalizationState.length > 0) {
      FormalizationState_Signing = this.state.formalizationState.find((it) => it.code === "8").code
    }

    if (bid.alternateOffers && bid.alternateOffers.length > 0) {

      if (api.isUserInRole("admin")) {
        alternateOffers = < AlternateOffers ns={ns} bid={bid} app={app} />
      } else if (!formalizationData && !bid.accepted) {
        alternateOffers = < AlternateOffers ns={ns} bid={bid} app={app} />
      } else if (
        formalizationData &&
        formalizationData.formalizationState <= parseInt(FormalizationState_Signing) &&
        ["cbss"].indexOf(bid.supplier.internalName) > -1) {
        alternateOffers = < AlternateOffers ns={ns} bid={bid} app={app} />
      }
    }

    let formalizationPanel = null
    if (formalizationData) {
      formalizationPanel = < Formalization
        ns={ns}
        app={app}
        bid={bid}
        formalizationData={formalizationData}
      />
    }

    let bidDetails = "Bid feito em " + moment(bid.created).format("DD, HH:mm:ss") + ", válido até " + moment(bid.validUntil).format("DD/MM, HH:mm:ss")
    if (bid.accepted) {
      bidDetails += ", aceito por " + bid.acceptedByName
    }

    const signature = get(formalizationData, ['signature'], { created: null, sentDate: null })

    return (<div>
      <div className="complementary-details">{bidDetails}</div>
      {editingBid}
      {alternateOffers}
      <ConfirmationSteps ns={ns} app={app} bid={bid} />
      {formalizationPanel}
      {signature.created &&
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title>Assinatura do Contrato</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            Data de Assinatura: {moment(signature.created).format('DD/MM/YYYY')}<br />
          </FzCard.Body>
        </FzCard>
      }
      {
        formalizationData && formalizationData.validationSteps ?
          <AnalysisPanel
            validationSteps={formalizationData.validationSteps}
            validationStepsHistory={formalizationData.validationStepsHistory}
            ns={ns}
          /> : null
      }
    </div>
    )
  }
}

export class BidsPanel extends React.Component {
  constructor(...props) {
    super(...props)

    this.state = { openModalDetails: false, bidId: null, bid: null }
    this.bidStatus = this.bidStatus.bind(this)
    this.showRefuseBidModal = this.showRefuseBidModal.bind(this)
    this.closeRefuseBidModal = this.closeRefuseBidModal.bind(this)
    this.expandComponent = this.expandComponent.bind(this)
    this.fnAcceptBid = this.fnAcceptBid.bind(this)
    this.handleSetModal = this.handleSetModal.bind(this)

  }

  fnAcceptBid(bid) {
    acceptBid(this.props.app.id, bid.id)
      .then((newapp) => {
        this.props.ns.set('application', newapp)

        let treatment = this.props.ns.get("crm.treatments." + this.props.app.id) ? this.props.ns.get("crm.treatments." + this.props.app.id) + " * Bid aceito" : "Bid aceito";
        let treatmentArray = this.props.ns.get("crm.treatmentsArray." + this.props.app.id) ? this.props.ns.get("crm.treatmentsArray." + this.props.app.id) : [];
        treatmentArray.push(AUCTION_ACTION_ACCEPT_BID);
        this.props.ns.set("crm.treatments." + this.props.app.id, treatment);
        this.props.ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);
      })
      .catch((error) => { console.warn("Error in acceptBid", error) })
  }

  bidStatus(cell, bid, formatExtraData, idx, application) {

    if (!bid) {
      return <div>******* ERROR ********</div>
    }
    let bidStatusBar = [];
    let supplierRejected = application.supplierData && application.supplierData[bid.supplier.internalName] && application.supplierData[bid.supplier.internalName].rejected;


    if (application.paid && application.paid.supplierInternalName === bid.supplier.internalName) {
      bidStatusBar.push(<FzBadge key={0} fzStyle="success" >Pago</FzBadge>);
      return <div>{bidStatusBar}</div>;
    }

    if (bid.accepted) {
      bidStatusBar.push(<FzBadge className='mr-1' key={1} fzStyle="success" >Aceito</FzBadge>)
    }

    if (supplierRejected) {
      bidStatusBar.push(<FzBadge className='mr-1' key={2} fzStyle="alert" >Cancelado</FzBadge>);
      return <div>{bidStatusBar}</div>;
    }

    if (bid.refused) {
      bidStatusBar.push(<FzBadge className='mr-1' key={3} fzStyle="attention" >Recusado</FzBadge>)
    }

    if (bid.expired) {
      bidStatusBar.push(<FzBadge className='mr-1' key={4} fzStyle="alert" >Expirado</FzBadge>)
    }

    if (bid.canUpdateBid) {
      bidStatusBar.push(<FzBadge className='mr-1' key={4} fzStyle="success" >Editar oferta</FzBadge>)
    }

    if (bid.confirmationUrl && bid.accepted) {
      // Important! This compenent (CopyToClipboard) requires One (and only one) child to properlly work
      bidStatusBar.push(
        <FzButton
          className='mr-1'
          tip='Copiar link'
          size="sm"
          fzStyle='discrete'
          onClick={() => copy(bid.confirmationUrl, { format: "text/plain" })}
        >
          <FzIcons icon={"copy"} fzStyle={{ width: "15px", height: "15px" }} />
        </FzButton>
      )
    }

    if (bid.accepted) {
      bidStatusBar.push(
        <FzButton
          className='mr-1'
          tip='Reenviar Email'
          key={bid.id}
          size="sm"
          fzStyle='discrete'
          onClick={() => this.fnResendEmail(bid)}
        >
          <FzIcons icon={"envelope"} fzStyle={{ width: "15px", height: "15px" }} />
        </FzButton>
      )
    }

    if (api.isUserInRole("advisor")) {
      if (!bid.rejected && !bid.accepted && (!bid.alternateOffers || bid.alternateOffers.length === 0)) {
        bidStatusBar.push(
          <FzButton
            key={5}
            fzStyle="success"
            size="sm"
            disabled={this.props.ns.isEditing()}
            onClick={() => {
              this.fnAcceptBid(bid)
            }}>
            Aceitar esta oferta
          </FzButton>
        )
      }

      if (bid.alternateOffers && bid.alternateOffers.length > 0) {
        bidStatusBar.push(
          <FzBadge key={6} fzStyle="success" >
            <Badge>{bid.alternateOffers.length}</Badge> ofertas alternativas
          </FzBadge>
        )
      }
    }

    if (api.isUserInRole("advisor") && !bid.accepted) {
      bidStatusBar.push(
        <FzButton
          key={7}
          fzStyle="attention"
          size="sm"
          disabled={this.props.ns.isEditing()}
          onClick={() => this.showRefuseBidModal(bid)}>
          Não interessado
        </FzButton>
      );
      bidStatusBar.push(
        <RefuseBid
          key={8}
          show={this.state.showRefuseBid}
          closeButton={this.closeRefuseBidModal}
          ns={this.props.ns}
          app={application}
          bid={bid} />
      )
    }

    return (<div> {bidStatusBar} </div>)
  }

  handleId(cell, bid) {
    return (<FzButton size="sm" tip='Ver Detalhes' onClick={() => this.handleSetModal(cell, bid)}>Detalhes</FzButton>)
  }



  fnResendEmail = (bid) => {
    reSendEmailBid(this.props.app.id, bid.id)
      .then((newapp) => {
        let treatment = this.props.ns.get("crm.treatments." + this.props.app.id) ? this.props.ns.get("crm.treatments." + this.props.app.id) + " * E-mail de aceite reenviado" : "E-mail de aceite reenviado";
        let treatmentArray = this.props.ns.get("crm.treatmentsArray." + this.props.app.id) ? this.props.ns.get("crm.treatmentsArray." + this.props.app.id) : [];
        treatmentArray.push(AUCTION_ACTION_RESEND_EMAIL);

        this.props.ns.set("crm.treatments." + this.props.app.id, treatment);
        this.props.ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);

      })
      .catch((error) => {
        console.warn("Error in reSendEmailBid", error)
      })
  }

  closeRefuseBidModal() {
    this.setState({ showRefuseBid: false });
  }

  showRefuseBidModal = (bid) => {
    this.props.ns.set('bid', bid);
    this.setState({ showRefuseBid: true });
  }

  expandComponent(ns, app, bid, e) {
    return (< BidDetails ns={ns} app={app} bid={bid} />)
  }

  handleSetModal(id, bid) {
    this.setState({ bidId: id, bid: bid, openModalDetails: !this.state.openModalDetails })
  }

  render() {
    let { app, ns } = this.props

    let bids = []
    if (app && app.auction && app.auction.bids) {
      bids = app.auction.bids
    }

    let bidsData
    let columnVisible = app.product === "HE" ?
      ["id", "supplier.name", "approvedAmount", "monthlyInstallment", "firstInstallment", "lastInstallment", "totalValueInstallments", "approvedPeriodMonths", "paymentMethod", "accepted"] :
      ["id", "supplier.name", "approvedAmount", "monthlyInstallment", "approvedPeriodMonths", "interestRate", "paymentMethod", "accepted"]
    // Criar uma estrutura flat 

    if (bids.length > 0) {
      bidsData = (
        <FzTable
          ns={ns}
          data={bids}
          hover={true}
          metas={bidsAnalytic}
          visibleColumns={columnVisible}
          rowExpand={{
            expandComponent: (bid, e) => this.expandComponent(ns, app, bid),
            expandableRow: () => !api.isUserInRole("franchisee")

          }}
          customColumnFormatters={{
            accepted: (cell, bid, formatExtraData, idx) => this.bidStatus(cell, bid, formatExtraData, idx, app),
            id: (cell, bid, formatExtraData, idx) => this.handleId(cell, bid, formatExtraData, idx, app),
          }}
          exportDownload={false}
          clipboard={false}
        />
      )
    } else {
      bidsData = <FzAlert fzStyle="attention">Nenhuma oferta ainda ...</FzAlert>
    }
    return (
      <div>
        <FzCard>
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">Propostas</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            {bidsData}
          </FzCard.Body>
        </FzCard>
        <Modal
          size="lg"
          centered
          show={this.state.openModalDetails}
          onHide={() => this.handleSetModal()}
        >
          <Modal.Header>
            <Modal.Title >{`Detalhes da oferta ${this.state.bid && this.state.bid.supplier.name}`} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.bid && (
              <>
                <FzRow>
                  <FzCol span={4}>
                    <strong>Total Parcelas</strong>
                    <div>{amountColumn(this.state.bid.totalValueInstallments)}</div>
                  </FzCol>

                  <FzCol span={4}>
                    <strong>1ª Parcela</strong>
                    <div>{amountColumn(this.state.bid.firstInstallment)}</div>
                  </FzCol>

                  <FzCol span={4}>
                    <strong>Últ. Parcela</strong>
                    <div>{amountColumn(this.state.bid.lastInstallment)}</div>
                  </FzCol>
                </FzRow>

                <FzRow>
                  <FzCol>
                    <strong>Juros</strong>
                    <div>{pctColumn(this.state.bid.interestRate)}</div>
                  </FzCol>
                  <FzCol>

                    <strong>C.E.T</strong>
                    <div>{pctColumn(this.state.bid.totalAnnualInterestRate)}</div>
                  </FzCol>
                </FzRow>

                <FzRow>
                  <FzCol>
                    <strong>Loja</strong>
                    <div>{this.state.bid.supplier.branchCode}</div>
                  </FzCol>
                </FzRow>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>

    )
  }
}

