import React from 'react';
import { FzMenuAdminMis } from '../src/ui/components/nav/menu-admin-mis';
import { FzMenuAffiliate } from '../src/ui/components/nav/menu-affiliate';
import { FzMenuSupplier } from '../src/ui/components/nav/menu-supplier';
import { FzMenuAdvisorSales } from '../src/ui/components/nav/menu-advisor-sales';
import { FzMenuScreener } from '../src/ui/components/nav/menu-screener';

import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import { FzAppBarMobile } from '../src/ui/components/nav/app-bar-mobile';
import { FzAppBarDesktop } from '../src/ui/components/nav/app-bar-desktop';
import '../src/ui/fz/style/module/main-layout.css';
import { getCurrentUser } from './api/auth';
import { FzMenuLogOut } from './ui/components/nav/menu-logout';
import { get } from 'lodash';

const drawerWidth = 240;
const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `calc(${drawerWidth}px)`,
    },
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  children?: any;
  history?: any;
}

export function MainLayout(props: Props) {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const user = getCurrentUser();
  let bodyMenu;

  if (user) {
    bodyMenu = getBodyMenu(user, props);
  }

  const isLogin = get(
    props, 
    [
      'history', 
      'location', 
      'pathname',
    ], 
    'no-found',
  ) === "/login"

  return (
    <div>
      <div>
        <Hidden smUp implementation="css">
          <FzAppBarMobile user={user} handleDrawerToggle={handleDrawerToggle} isLogin={isLogin} />
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobssile.
            }}
          >
            {user && bodyMenu}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <FzAppBarDesktop
            user={user}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
            isLogin={isLogin}
          />
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            {user && bodyMenu}
          </Drawer>
        </Hidden>
      </div>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}

function getBodyMenu(user: any, props: Props) {
  let bodyMenu;
  switch (user.role) {
    case 'admin':
      bodyMenu = <FzMenuAdminMis history={props.history} />;
      break;
    case 'mis':
      bodyMenu = <FzMenuAdminMis history={props.history} />;
      break;
    case 'affiliate':
      bodyMenu = <FzMenuAffiliate history={props.history} />;
      break;
    case 'supplier':
      bodyMenu = <FzMenuSupplier history={props.history} />;
      break;
    case 'advisor':
      bodyMenu = <FzMenuAdvisorSales history={props.history} />;
      break;
    case 'sales':
      bodyMenu = <FzMenuAdvisorSales history={props.history} />;
      break;
    case 'screener':
      bodyMenu = <FzMenuScreener history={props.history} />;
      break;
    default:
      bodyMenu = <FzMenuLogOut history={props.history} />;
      break;
  }
  return bodyMenu;
}
