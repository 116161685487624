import React from 'react';
import { FzRow, FzCol, FzCard, FzJumbotron } from '../fz/layout/index';
import { getCookie } from '../../api/helpers';
import { FzButton } from '../fz/form/button';
import { FzSelect } from '../fz/form/old-simple-input';

class OCRDocuments extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      documentType: "rg-back",
      score: "0",
      paid: "true",
      active: "true",
      validated: "true",
      userDocuments: []
    }
    this.viewDocument = this.viewDocument.bind(this)
    this.toggleActive = this.toggleActive.bind(this)
    this.togglePaid = this.togglePaid.bind(this)
    this.toggleValidated = this.toggleValidated.bind(this)
    this.handleScoreChange = this.handleScoreChange.bind(this)
    this.handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this)
    this.callApi = this.callApi.bind(this)
  }

  viewDocument(userDocument, showSibling = false) {
    window.open(`${this.props.api.baseUrl}documents/${showSibling ? userDocument.documentSibling : userDocument.id}?fmt=png&token=${getCookie("fz-cof-token")}`)
  }

  togglePaid() {
    this.setState({
      paid: (this.state.paid === "true" ? "false" : "true")
    }, function(){
      this.callApi()
    })
  }

  toggleActive() {
    this.setState({
      active: (this.state.active === "true" ? "false" : "true")
    }, function(){
      this.callApi()
    })
  }

  toggleValidated() {
    this.setState({
      validated: (this.state.validated === "true" ? "false" : "true")
    }, function(){
      this.callApi()
    })
  }

  handleScoreChange(score) {
    this.setState({score}, function(){
      this.callApi()
    })
  }

  handleDocumentTypeChange(documentType) {
    this.setState({documentType}, function(){
      this.callApi()
    })
  }

  callApi() {
    const { documentType, score, paid, active, validated } = this.state
    this.props.api.Get(`/documents/${documentType}/${score}/${paid}/${active}/${validated}`)
      .then(userDocuments => {
        this.setState({ userDocuments })
      })
      .catch(err => {
        console.error(err)
      })
  }

  componentDidMount() {
    this.callApi()
  }


  render() {

    const userDocuments = this.state.userDocuments.map(userDocument => {
      const fields = Object.keys(userDocument.documentTags).map(key => {
        return (
          <FzRow><FzCol span={5}>{key}: </FzCol><FzCol span={2}>{userDocument.documentTagsScores[key]}</FzCol><FzCol span={5}>{userDocument.documentTags[key]}</FzCol></FzRow>
        )
      })
      return (
        <div>
          <FzCol span={4}>
            <FzCard>
              <FzCard.Title>{userDocument.fullName}</FzCard.Title>
              <FzCard.Body>
                <FzRow><FzCol>documentType:</FzCol> <FzCol>{userDocument.documentType}</FzCol></FzRow>
                <FzRow>fullDescription: {userDocument.fullDescription.concatenatedDescription}</FzRow>
                <FzRow><FzCol span={12}>Fields: </FzCol><FzCol span={12}>{fields}</FzCol></FzRow>
                <FzRow><FzCol>ocrScore: </FzCol><FzCol>{userDocument.ocrScore}</FzCol></FzRow>
                <FzRow><FzCol>paid: </FzCol><FzCol>{userDocument.paid}</FzCol></FzRow>
                <FzRow>
                  <FzButton onClick={() => this.viewDocument(userDocument, false)}>View Document</FzButton>
                  <FzButton onClick={() => this.viewDocument(userDocument, true)}>View Related Document</FzButton>
                </FzRow>
              </FzCard.Body>
            </FzCard>
          </FzCol>
        </div>)
    })
    const options = ["0","1","2","3","4","5","6","7","8","9","10","11"].map(score => {return {code: score, description: score}})
    const documentTypes = ["rg-back", "income", "address"].map(score => {return {code: score, description: score}})
    const scores = (<FzSelect onChange={this.handleScoreChange} options={options} value={this.state.score} />)
    const documentType = (<FzSelect onChange={this.handleDocumentTypeChange} options={documentTypes} value={this.state.documentType} />)
    const paidButton = (<FzButton onClick={this.togglePaid} >{this.state.paid === "true" ? "Sim" : "Não"}</FzButton>)
    const activeButton = (<FzButton onClick={this.toggleActive} >{this.state.active === "true" ? "Sim" : "Não"}</FzButton>)
    const validatedButton = (<FzButton onClick={this.toggleValidated} >{this.state.validated === "true" ? "Sim" : "Não"}</FzButton>)

    return (
      <FzJumbotron>
        <FzCard>
          <FzCard.Heading>
            <FzRow>
            <FzCol span={3}> Document Type: {documentType}</FzCol>
            <FzCol span={3}> Score: {scores}</FzCol>
            <FzCol span={3}> Pago: {paidButton} </FzCol>
            <FzCol span={3}>Documento ativo? (active): {activeButton} </FzCol>
            <FzCol span={3}>Documento válido? (ocr): {validatedButton} </FzCol>
            </FzRow>
          </FzCard.Heading>
        </FzCard>
        <FzRow>
          {userDocuments}
        </FzRow>
      </FzJumbotron>
    )
  }
}

export { OCRDocuments }
