import { useHistory } from "react-router-dom";
import { FzButton } from "../../ui/fz/form";
import { FzIcons } from "../../ui/fz/form/icon";
import { PaginatorProps } from "./types";



export function Paginator({
  root,
  className,
  previousDisabled,
  previous,
  nextDisable,
  next }: PaginatorProps) {

  const history = useHistory();
  return <div className={className} style={{ display: "flex" }}>
    <FzButton fzStyle={"outline-secondary"} disabled={previousDisabled} onClick={() => {
      history.push(root + previous)
    }}>
      <FzIcons icon={"arrow-left"} fzStyle={{ width: "15px", height: "15px" }} />
    </FzButton>
    <FzButton className={"ml-1"} disabled={nextDisable} fzStyle={"outline-secondary"} onClick={() => {
      history.push(root + next)
    }}>
      <FzIcons icon={"arrow-right"} fzStyle={{ width: "15px", height: "15px" }} />
    </FzButton>
  </div>
}