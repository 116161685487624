export const normalizeApplication = (app, idx, page, pageSize) => {
  if (app === null) {
    alert('Erro: Verifique se o usuário possui filas válidas associadas à ele. \n Caso possua verifique se as mesmas não estão vazias no momento.')
    return;
  }
  return new Promise((resolve, reject) => {
    app.fzKey = idx+(page-1)*pageSize;
    resolve(app);
  })
}

export const normalizeApplications = (appList, page = 1, pageSize = 50) => {
  return Promise.all(appList.map((app, idx) => normalizeApplication(app, idx, page, pageSize)))
}
