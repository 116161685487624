import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ResponseTimeSummary } from './agent-queues/responseTimeSumary/ResponseTimeSumary'
import { QueuesTable } from './agent-queues/queuesTable/QueuesTable'
import { QueuesSummary } from './agent-queues/queuesSummary/QueuesSummary'
import { TimedOutSummary } from './agent-queues/timedOutSummary/TimedOutSummary'
import { CanceledSummary } from './agent-queues/canceledSummary/CanceledSummary'
import { ListQueue } from './list-queue';
import { FzCard, FzJumbotron } from '../../fz/layout/index';
import { FzIcons } from '../../fz/form/icon';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

export function AgentHome(props) {

  const [selectedView, setSelectedView] = useState(props.match.params.queryType)

  function handleSelect(e) {
    handleReload()
    props.history.push('/manager/agents/' + props.match.params.agent + '/' + e)
    setSelectedView(e)
  }

  function handleReload() {
    if (props.match.params.queryType) {
      props.ns.unset(props.match.params.queryType)
    }
  }


  let nav = (<Navbar>
    <Navbar.Brand>
      Agente: {props.match.params.agent}
    </Navbar.Brand>

    <Nav activeKey={selectedView} variant="pills" onSelect={handleSelect}  >
      <Nav.Link eventKey="openQueues" >Filas ativas</Nav.Link>
      <Nav.Link eventKey="summary24h" >Resumo 24h</Nav.Link>
      <Nav.Link eventKey="timedOut" >Expiradas 7dd</Nav.Link>
      <Nav.Link eventKey="canceled" >Canceladas 24h</Nav.Link>
      <Nav.Link eventKey="responseTime" >Tempo de Resposta</Nav.Link>
    </Nav>
    <Button onClick={handleReload}>< FzIcons icon={"repeat"} fzStyle={{ width: "15px", height: "15px" }} /></Button>
  </Navbar>
  )

  let innerContent
  switch (props.match.params.queryType) {
    case "openQueues":
      if (props.match.params.queueName) {
        innerContent = (<ListQueue ns={props.ns} agent={props.match.params.agent} queue={props.match.params.queueName} queryType={props.match.params.queryType} />)
      } else {
        innerContent = (<QueuesTable ns={props.ns} pathname={props.location.pathname} name={props.match.params.queryType} agent={props.match.params.agent} />);
      }
      break;
    case "summary24h":
      if (props.match.params.queueName) {
        innerContent = (<ListQueue ns={props.ns} agent={props.match.params.agent} queue={props.match.params.queueName} queryType={props.match.params.queryType} />)
      } else {
        innerContent = (<QueuesSummary ns={props.ns} name={props.match.params.queryType} agent={props.match.params.agent} />);
      }
      break;
    case "timedOut":
      if (props.match.params.queueName) {
        innerContent = (<ListQueue ns={props.ns} agent={props.match.params.agent} queryType={props.match.params.queryType} queue={props.match.params.queueName} />)
      } else {
        innerContent = (<TimedOutSummary ns={props.ns} pathname={props.location.pathname} name={props.match.params.queryType} agent={props.match.params.agent} />);
      }
      break;
    case "canceled":
      if (props.match.params.queueName) {
        innerContent = (<ListQueue ns={props.ns} agent={props.match.params.agent} queryType={props.match.params.queryType} queue={props.match.params.queueName} />)
      } else {
        innerContent = (<CanceledSummary ns={props.ns} pathname={props.location.pathname} name={props.match.params.queryType} agent={props.match.params.agent} />);
      }
      break;
    case "responseTime":
      innerContent = (<ResponseTimeSummary ns={props.ns} name={props.match.params.queryType} agent={props.match.params.agent} />);
      break;
    default:
      innerContent = null;
      break;

  }
  return (
    <FzJumbotron>
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">{nav}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {innerContent}
        </FzCard.Body>
      </FzCard>
    </FzJumbotron>
  )
}