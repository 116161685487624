import { useEffect, useState } from "react"
import { FzButton } from "../button";
import { DropdownIconsBullets } from "./Dropdown";
import { icons } from "./icons";
import { FzField } from "../field";

export default function FieldsListWithIcons(props) {
  const { changeField, readOnly, index, domains, element, removeField } = props;

  const [iconDefault, setIconDefault] = useState(element.icon);
  const [open, setOpen] = useState(false);
  const [listIcon, setListIcon] = useState([]);
  const [listIconFiltered, setListIconFiltered] = useState([]);

  useEffect(() => {
    const list = Object.keys(icons).map((item, index) => {
      return item;
    });

    setListIcon(list);
    setListIconFiltered(list);
  }, []);

  function handleChangeOpen(state) {
    setOpen(state)
  }

  function Search() {
    if (iconDefault && !open) {
      return (
        <div className="w-100">
          <div className="row p-0 w-100 m-0">
            <div className="col-md-10 pl-0 pr-2">
              <button disabled={readOnly} className="btn btn-light border w-100">
                Selecionado: <i className={iconDefault}></i>
              </button>
            </div>
            <FzButton
              disabled={readOnly}
              onClick={() => {
                handleChangeOpen(true);
              }}
              className="btn btn-primary col-md-2 d-flex align-items-center justify-content-center"
            >
              <i className="fa-light fa-pen"></i>
            </FzButton>
          </div>
        </div>
      )
    }

    return (
      <div
        disabled={readOnly}
        className={`form-control`}
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleChangeOpen(true);
        }}
      >
        <i className="fa-light fa-magnifying-glass mr-2"></i>
        <span className="overflow-none">
          Pesquisar ícone
        </span>
      </div>
    )
  }

  let metaFields = [
    {
      name: "icon",
      dataType: "icon",
      label: "Icone:",
      col: "12"
    },
    {
      name: "text",
      dataType: "string",
      label: "Texto:",
      inputType: "text",
      fzStyle: "small",
      placeHolder: "Digite um texto",
      col: "12"

    }
  ];

  const metaFieldsConfirmation = [
    {
      name: "title",
      dataType: "string",
      label: "Titulo:",
      inputType: "text",
      fzStyle: "small",
      placeHolder: "Digite um titulo",
      col: "12"
    }
  ];

  if (domains && domains.length > 0) {
    metaFieldsConfirmation.push({
      name: "refusereason",
      dataType: "string",
      label: "Motivo:",
      inputType: "select",
      fzStyle: "small",
      placeHolder: "Selecione o motivo de recusa",
      col: "12",
      inputProps: {
        options: domains.filter((domain) => domain.filter === "accept")
      }
    })
  }

  if (element.type == "confirmation") {
    metaFields = [...metaFieldsConfirmation, ...metaFields];
  }

  return (
    <div className="row border m-1 p-2 rounded-10">
      {
        metaFields.map((field, i) => {
          if (field.dataType == "icon") {
            return (
              <div key={i} className={`form-group col-md-${field.col}`}>
                <label className="form-label">{field.label}</label>
                <Search />
                <DropdownIconsBullets
                  changeField={changeField}
                  index={index}
                  handleChangeOpen={setOpen}
                  icons={icons}
                  iconDefault={iconDefault}
                  listIcon={listIcon}
                  listIconFiltered={listIconFiltered}
                  open={open}
                  setIconDefault={setIconDefault}
                  setListIconFiltered={setListIconFiltered}
                />
              </div>
            )
          } else {
            return (
              <div key={i} className={`col-md-${field.col}`}>
                <FzField
                  readOnly={readOnly}
                  fzStyle="small"
                  onChange={(e) => {
                    changeField(index, e, field.name)
                  }}
                  value={element[field.name]}
                  {...field}
                />
              </div>
            )
          }
        })
      }

      <div className="col-md-12">
        <FzButton
          fzStyle="alert"
          disabled={readOnly}
          onClick={() => removeField(index)}
          className={'w-100 d-flex align-items-center justify-content-center'}
          customStyle={{ marginLeft: '0px', marginBottom: '0.5rem', height: "80%" }}>
          Deletar item
        </FzButton>
      </div>
    </div>

  )
}