export const agentQueuesSummary = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      rowSpan: "2",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "queue": {
    "path": "queue",
    "label": "Fila",
    "tableProps": {
      rowSpan: "2",
      "width": "25ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "left",
      "exportFormat": undefined,
    },
  },
  "qtty": {
    "path": "qtty",
    "label": "Total",
    "tableProps": {
      rowSpan: "2",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "formatExtraData": undefined,
    },
  },
  "timedOut": {
    "path": "timedOut",
    "label": "Expiradas",
    "tableProps": {
      rowSpan: "2",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Expiradas",
            dataAlign: "left",
            "width": "8ch",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "8ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "8ch",
          }
        ]
      },
      "formatExtraData": "qtty",
    },
  },
};