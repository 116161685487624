import { Meta } from "../../../../types/metas";

export const pendencyDocumentsMeta: Meta = {
  'key': {
    "path": "key",
    "label": "#",
    "dataType": "number",
    "tableProps": {
      rowSpan: "2",
      'hidden': true,
      'isKey': true,
      "viewsTable": "key",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "documentType": {
    "path": "documentType",
    "label": "Documento",
    "dataType": "string",
    "tableProps": {
      rowSpan: "2",
      "viewsTable": "documentType",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  'totalAnalyzedOfDoc': {
    "path": "totalAnalyzedOfDoc",
    "label": "Total Analyzed",
    "dataType": "number",
    "tableProps": {
      rowSpan: "2",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  'total': {
    "path": "total",
    "label": "Total Pending",
    "dataType": "number",
    "tableProps": {
      rowSpan: "2",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "supplier": {
    "path": "supplier",
    "label": "Supplier",
    "dataType": "number",
    "tableProps": {
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '3',
            "label": "Supplier",
            dataAlign: "left",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Analyzed",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "formatExtraData": "supplierTotalAnalyzed",
          },
          {
            row: '1',
            "label": "Pending",
            dataAlign: "left",
            "columnFormat": "amountColumn"
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]

      },

      "columnFormat": "amountColumn",
      "formatExtraData": "supplierTotalAnalyzed",
    },
    "isMandatory": (application) => false,
  },
}
