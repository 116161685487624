import React, { Component } from "react";
import PropTypes from "prop-types";
import { Namespace } from '../../../scripts/state/redux_ns';
import { FzField } from "../../fz/form/field";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import hooks from "./hooks";
import { getDomain } from "../../../scripts/utils/cache-helpers";
import { filterDomains } from "../../../biz/metadatas/application";
import { validateAppField } from "../../../biz/validate-application";
import { filterDomainDescription } from "../../../utils/filterDomainDescription";

// Field renders a field specified in the 'path' prop of the object in the 'data'
// Field is responsible for getting the current value for the field and setting
// up the onChange function that saves the modified value in the namespace.

class FieldConnected extends Component {
  constructor(props) {
    super(props);

    this.changeField = this.changeField.bind(this);
    this.checkIsReadOnly = this.checkIsReadOnly.bind(this);
  }

  changeField = value => {
    const { meta, ns, index } = this.props;
    if (meta.path.indexOf("$") > -1) {
      meta.path = meta.path.replace("$", index)
    }
    const oldValue = ns.getChanged(meta.path);
    // console.log("saveChange", meta.path, value)
    ns.saveChange(meta.path, value);
    ns.set("dirty", true);

    validateAppField({ value, data: ns.getChanged(), ns, metaField: meta })
      .then(validatedField => {
        if (validatedField.valid) {
          if (meta.hookOnSave) {
            const hook = hooks[meta.hookOnSave];
            hook(value, oldValue, ns, meta)
              .then(() => ns.setValid(meta.path))
              .catch(() => ns.setInvalid(meta.relatedFields || meta.path));
          } else {
            ns.setValid(meta.path);
          }
        } else {
          ns.setInvalid(meta.relatedFields || meta.path);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  checkIsReadOnly(ns, meta) {
    if (meta.isReadOnly === undefined) { //check if meta prop isReadOnly is defined
      if (ns.isEditing()) { //check if user is editing the form
        if (Array.isArray(ns.editingFields()) && ns.editingFields().length > 0) {
          if (ns.editingFields().indexOf(meta.path) > -1) {
            return false
          }
          return true
        }
        return false
      } else {
        return true
      }
    } else {
      return meta.isReadOnly(ns) || this.props.readOnly;
    }
  }

  render() {
    const { data, meta, ns, index , code, utc, displayTimeZone} = this.props;

    if (!ns.isEditing() && meta && meta.inputProps && meta.inputProps.filteredFrom) {
      meta.inputProps.filteredFrom = undefined;
    }
    let readOnly = this.checkIsReadOnly(ns, meta)
    let value;
    
    if (!readOnly && ns.isEditing()) {
      value = ns.getChanged(meta.path);
    } else {
      value = get(data, meta.path);
    }

    if(typeof meta.isVisible === "function" && !meta.isVisible(data, code) && meta.cleanIfHidden && ns.isEditing() && value && data.branches[code].fakeBidLayout) {
      value = meta.dataType === "number" ? 0 : "";
      this.changeField(value)
    }

    let inputPropsClone = cloneDeep(meta.inputProps || {});

    if (meta.inputType === "select" || meta.inputType === "auto-complete" || meta.inputType === "multiselect" || meta.inputType === "multiselect-autocomplete") {
      if (
        typeof meta.inputProps.domainName === "string" &&
        (!Array.isArray(meta.inputProps.options) ||
          meta.inputProps.options.length === 0)
      ) {
        inputPropsClone.options = getDomain(ns, meta.inputProps.domainName);

        if (meta.inputProps.domainName === 'formalization-documents') {
          inputPropsClone.options = filterDomainDescription(inputPropsClone.options)
        }
      }
    }

    if (
      inputPropsClone.options &&
      inputPropsClone.options.length > 0 &&
      inputPropsClone.filteredFrom
    ) {
      inputPropsClone.options = filterDomains(
        ns.getChanged(inputPropsClone.filteredFrom) ||
        ns.get("application." + inputPropsClone.filteredFrom),
        inputPropsClone.options
      );
    }
    const label =
      typeof meta.label === "function" ? meta.label(value, data) : meta.label;
    const tip =
      typeof meta.tip === "function" ? meta.tip(value, data) : meta.tip;
    const isUTC = utc === undefined ? meta.utc : utc;
    return (
      <FzField
        name={meta.path}
        dataType={meta.dataType}
        onChange={this.changeField}
        extraChangeEffect={this.props.extraChangeEffect}
        inputType={meta.inputType}
        inputProps={inputPropsClone}
        readOnly={readOnly}
        customClass={this.props.customClass}
        value={value}
        label={label}
        tip={tip}
        placeHolder={meta.placeholder}
        mandatory={meta.isMandatory(data)}
        validationMessage={meta.validationMessage}
        doNormalize={meta.normalize}
        invalid={ns.isInvalid(meta.path)}
        isJSON={meta.isJSON}
        informationMessage={meta.informationMessage}
        utc={isUTC}
        displayTimeZone={displayTimeZone}
      />
    );
  }
}

FieldConnected.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.object,
  meta: PropTypes.object.isRequired
};

export default FieldConnected;
