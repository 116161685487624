import api, { misApi } from './client-api';
import pickBy from 'lodash/pickBy';

import { normalizeApplication, normalizeApplications } from '../biz/normalize'
import { getAuth } from './auth'

export type applicationIntegratioResponse = {
  "created": Date,
  "lastUpdatedData": Date,
  "statisticsType": string,
  "consolidationPeriod": string,
  "queryStartDate": Date,
  "queryEndDate": Date,
  "statistics": [{}],
  "metadata": {}
}

export type creditRiskResponse = [{
  appswithsamepersonalnumemail: number,
  changeaddress: boolean,
  changeemail: boolean,
  created: string,
  dayssincefirstrejection: number,
  dayssincelastrejection: number,
  hashuser: string,
  maxappliedamount: number,
  maxmonthlyincome: number,
  minappliedamount: number,
  minmonthlyincome: number,
  monthsincefirstapplication: number,
  monthsincefirstpaid: number,
  monthsincelastapplication: number,
  monthsincelastpaid: number,
  newuser: boolean,
  product: string,
  sixmonthsrestrictions: boolean,
  sumpaidamount: number,
  sumpaidinstallments: number,
  threemonthrestrictions: boolean,
  totalapps: number,
  totalfiltered: number,
  totalpaid: number,
  totalrefused: number,
  totalrejected: number,
  totaltimeout: number,
  totalwithbid: number,
  yearrestrictions: boolean,
  _id: string,
}]

export type allProductsResponse = [
  {
    "internalName": string,
    "name": string,
    "description": string,
  }
]


export const getCreditRisk = async (filter: object, page = 1, pageSize): Promise<creditRiskResponse> => {
  var serviceUrl = '/applications/creditrisk/all?page=' + page + '&pageSize=' + (pageSize || api.DefaultAppStack) + '&orderBy=-created';
  serviceUrl += api._buildQueryString(filter)
  return api.Get(serviceUrl)
}

export const getApplicationsCount = (filter) => {
  var serviceUrl = '/applications/all/count?';
  serviceUrl += api._buildQueryString(filter)
  return api.Get(serviceUrl)
}

export const getApplications = (filter, page = 1, pageSize) => {
  var serviceUrl = '/applications/all?page=' + page + '&pageSize=' + (pageSize || api.DefaultAppStack) + '&orderBy=-created';
  serviceUrl += api._buildQueryString(filter)
  return api.Get(serviceUrl).then((data) => normalizeApplications(data, page, pageSize));
}

export const getApplicationsMe = () => {
  var serviceUrl = '/applications/me';
  return api.Get(serviceUrl);
}

export const getApplicationsForSummary = (channel, supplier, period) => {
  return api.Get('/applications/integration/summary/' + channel + "/" + supplier + "/" + period);
}

export const getApplicationIntegrationOverview = async (channel: string, supplier: string, period: string): Promise<applicationIntegratioResponse> => {
  let serviceURL = '/lending/applications/integration/' + channel + '/' + supplier + '/' + period;
  return misApi.Get(serviceURL);
}

export const getApplicationsForAnalytics = (channel, supplier, filter) => {
  var serviceUrl = '/applications/integration/analytic/' + channel + "/" + supplier + "/all?"
  serviceUrl += api._buildQueryString(filter)
  return api.Get(serviceUrl);
}

export const getFormalizationsForAnalytics = async (supplier, filter, skip, limit): Promise<any> => {
  let serviceUrl = `/formalizations/integration/analytic/${supplier}/all?limit=${limit}&skip=${skip}`
  serviceUrl += api._buildQueryString(filter)
  return api.Get(serviceUrl);
}

export const claimNext = () => {
  return api.Post("/applications/claimnext", {}).then(normalizeApplication)
}

export const getByID = (appID) => {
  return api.Get('/applications/' + appID).then(normalizeApplication);
}

export const getBySupplier = (appID: string, supplierInternalName: string) => {
  return api.Get(`/applications/${appID}?supplier=${supplierInternalName}`).then(normalizeApplication);
}

export const reSendEmailBid = (appId: string, bidId: string) => {
  var serviceUrl = '/applications/' + appId + '/bids/' + bidId + '/notify/resendaccept';
  let data = {}

  return api.Post(serviceUrl, data).then(normalizeApplication)
}

export const refuseBid = (appId, bidId, reason) => {
  var serviceUrl = '/applications/' + appId + '/bids/' + bidId + '/refuse';
  let data = {}
  if (reason) {
    data = { refusedReason: reason }
  }
  return api.Post(serviceUrl, data).then(normalizeApplication)
}

export const validatePolicy = (appId, revision) => {
  var serviceUrl = '/applications/' + appId + '/validatepolicy';
  let data = { revision }
  return api.Post(serviceUrl, data).then(normalizeApplication);
}

export const changeProduct = (appId, currentProduct, newProduct, revision) => {
  var serviceUrl = '/applications/' + appId + '/changeproduct';
  let data = { revision, currentProduct, newProduct }
  return api.Post(serviceUrl, data).then(normalizeApplication);
}


export const getApplicationsByAuthId = (userId) => {
  var serviceUrl = '/applications/user?page=1&pageSize=' + api.DefaultPage + '&orderBy=-created';
  let filter = { 'uid': userId };
  serviceUrl += api._buildQueryString(filter)
  return api.Get(serviceUrl).then(normalizeApplications)
}

export const updateAcceptedBid = (applicationId, bidId, data) => {
  return api.Put("/applications/" + applicationId + "/bids/" + bidId + '/accept', data).then(normalizeApplication)
}

export const addComment = (applicationID, comment, notify, notifySMS) => {
  let data = { comment: comment, notify, notifySMS };
  return api.Post("/applications/" + applicationID + "/comments", data).then(normalizeApplication)
}

export const finishAttendance = (applicationID, revision, callbackTime, successTalk, lockedApplications, comment, treatmentsComment, treatmentsArray, treatments, contactId, category) => {
  if (callbackTime && callbackTime.toISOString) {
    callbackTime = callbackTime.toISOString()
  }
  let data = pickBy({ revision, callbackTime, comment, treatmentsComment, treatmentsArray, contactId, treatments });
  data.category = category
  data.comment = comment
  data.lockedapplications = lockedApplications || [];
  data.successTalk = successTalk; // force sending this info even if false
  return api.Post("/applications/" + applicationID + "/attendance", data).then(normalizeApplication)
}

export const getAttachmentUrl = (applicationId, attachmentId, fmt = "pdf") => {
  return api.baseUrl + "applications/" + applicationId + "/attachment/" + attachmentId + "?fmt=" + fmt + "&token=" + getAuth()
}

export const acceptBid = (applicationId: string, bidId: string, data?: any) => {
  return api.Post("/applications/" + applicationId + "/bids/" + bidId + '/accept', data).then(normalizeApplication)
}
export const updateBid = (applicationId, bidId, data) => {
  return api.Put("/applications/" + applicationId + "/bids/" + bidId, data).then(normalizeApplication)
}


export const updateApplication = (data) => {
  data.trackingDataHistory = null
  return api.Put('/applications/' + data.id, data).then(normalizeApplication)
}

export const updateCarPlate = (appId, bidId, data) => {
  return api.Put(`/applications/${appId}/bids/${bidId}/fields-overwrite`, data).then(normalizeApplication)
}

export const uploadDocument = (applicationId, bidId, data) => {
  return api.Post("/applications/" + applicationId + "/bids/" + bidId + "/formalization/document", data)
}

export const deleteDocument = (applicationId, bidId, doc) => {
  return api.Post("/applications/" + applicationId + "/bids/" + bidId + "/formalization/documents/" + doc.documentType + "/rejected", {})
}


export const createApplication = (data) => {
  return api.Post('/applications/', data).then(normalizeApplication)
}

export const extendAuctionEndDate = (id, app) => {
  const revision = app.revision
  return api.Post("/applications/" + id + "/auction-extend-end-date", { revision })
}

export const paidLead = (application, reason, paidDate, paidAmount, remark, comissionCode) => {

  let data = {
    Reason: reason,
    Revision: application.revision,
    PaidDate: paidDate,
    Remark: remark,
    ComissionCode: comissionCode,
    SupplierInternalName: reason,
    InfoChannel: "cof"
  }
  if (paidAmount) {
    data["PaidAmount"] = paidAmount
  }

  const serviceUrl = '/applications/' + application.id + '/paid';
  return api.Post(serviceUrl, data)

}

export const getAllProducts = async (): Promise<allProductsResponse> => {
  const serviceUrl = '/products';
  
  return api.Get(serviceUrl)
}

export const getProduct = async (productName): Promise<allProductsResponse> => {
  const serviceUrl = `/products/${productName}`;
  return api.Get(serviceUrl)
}

export const updateProduct = async (productName, offersPriorities) => {
  const serviceUrl = `/products/${productName}`;
  return api.Put(serviceUrl, offersPriorities);
}

export const getAnalyticsEmailByUser = async(userId, params = {}): Promise<any>  => {
  return misApi.Get(`messages/notificationhistory/user/${userId}`, params)
}
