import React from 'react';
import { Finanvc } from '../../pages/statistics/finanvc'
import * as statsMeta from '../../../biz/metadatas/messaging-metadata'

const MessagingStats = ({ ns, activeKey, sharedNS, toggleBlocking }) => {

  switch(activeKey) {
    case "finanvc":
      return <Finanvc ns={ns} metas={statsMeta.statsMessagingFinanvc} sharedNS={sharedNS} toggleBlocking={toggleBlocking}/>;
    default:
     return null
  }

}

export default MessagingStats;

