
export const metasToColumns = (integrationsColumnsMetadata) => {
  const fields = Object.keys(integrationsColumnsMetadata)
  const allMetas = fields.reduce((prev, f) => {
    return [...prev, { key: f, ...{ ...integrationsColumnsMetadata[f] } }]
  }, [])

  return allMetas
}

export const extractMetasStructureToFlat = (metaFields) => {
  const fields = Object.keys(metaFields);
  // removed  __allColumns and __defaultVisibleColumns
  fields.splice(0, 2)
  let section = "";
  const metas = fields.reduce((prev, f) => {
    section = metaFields[f].section && section !== metaFields[f].section ? metaFields[f].section : section;
    if (['vehicleSecurity', "homeSecurity", "trackingData", "bids", "supplierData", "formalization"].indexOf(f) > -1) {
      return [...prev, ...Object.keys(metaFields[f]).map(securityField => {
        section = metaFields[f][securityField].section && section !== metaFields[f][securityField].section ? metaFields[f][securityField].section : section;
        return { ...metaFields[f][securityField], section }
      })]
    } else {
      return [...prev, { ...metaFields[f], section }]
    }
  }, [])

  return metas
}