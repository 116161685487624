import React from 'react';
import { ProductChanges } from "../../pages/statistics/product-changes";
import { QueueNow } from "../../pages/statistics/sales-queues";
import * as statsMeta from '../../../biz/metadatas/sales-metadata'

import { salesCallPerAgent } from '../../../biz/metadatas/sales-metadata'
import { salesCallPerTreatments } from '../../../biz/metadatas/sales-metadata'
import { salesProductChanges } from '../../../biz/metadatas/sales-metadata'


import { CrmContactsPerTreatments } from "../../pages/statistics/crm-treatments";
import { CrmContactsPerAgent } from "../../pages/statistics/crm-contacts";
import { CrmContactsPerAgentCharts } from "../../pages/statistics/crm-contacts-charts";
import { QualifiedLeads } from "../../pages/statistics/qualified-leads";
import { AcceptedBy } from "../../pages/statistics/accepted-by";
import { CrmProductivity } from "../../pages/statistics/crm-productivity";
import { CsTreatments } from "../../pages/statistics/cs-treatments"
import { CapacityReview } from "../../pages/statistics/capacity-review"

export const StatsSales = ({ ns, activeKey, toggleBlocking, ...props }) => {

  switch (activeKey) {
    case "qualified":
      return <QualifiedLeads ns={ns} metas={statsMeta.salesSummaries} toggleBlocking={toggleBlocking} />

    // case "acceptedby":
    //   return <AcceptedBy ns={ns} metas={statsMeta.salesSummaries} toggleBlocking={toggleBlocking} />

    case "agent":
      return <CrmContactsPerAgent ns={ns} metas={salesCallPerAgent} toggleBlocking={toggleBlocking} />

    case "agent_charts":
      return <CrmContactsPerAgentCharts ns={ns} toggleBlocking={toggleBlocking} />

    case "treatmensts":
      return <CrmContactsPerTreatments ns={ns} metas={salesCallPerTreatments} toggleBlocking={toggleBlocking} />

    case "cstreatments":
      return <CsTreatments ns={ns} metas={salesCallPerTreatments} toggleBlocking={toggleBlocking} />

    case "productchanges":
      return <ProductChanges ns={ns} metas={salesProductChanges} toggleBlocking={toggleBlocking} />

    case "advisorqueue":
      return <QueueNow ns={ns} toggleBlocking={toggleBlocking} />

    case "productivity":
      return <CrmProductivity ns={ns} toggleBlocking={toggleBlocking}></CrmProductivity>

    case "capacityreview":
      return <CapacityReview ns={ns} toggleBlocking={toggleBlocking}></CapacityReview>


    default:
      return null;
  }
}

