import React, { Component } from 'react';
import { FzField } from '../../fz/form';

export class DomainMultiSelector extends Component {

  handleSelectDomain = (domain) => {
    const { selected, dataType, onChange } = this.props;
    if (selected && selected.indexOf(domain) === -1) {
      if (dataType && dataType === 'number') {
        selected.push(Number(domain));
      } else {
        selected.push(domain);
      }
      onChange(selected);
    }
  };

  render() {
    const { selected, options } = this.props;
    if (options && options.length === 0) {
      return null;
    }

    const addedDomains = selected ? selected.map((domain, key) => {
      return <div className="dms--added-item" key={'dms--added-item-' + key}>
        {domain}
      </div>;
    }) : [];

    return (
      <div>
        <FzField
          name={'domain-multi-selector dms'}
          dataType={'text'}
          inputType={'select'}
          value={''}
          inputProps={{ options }}
          onChange={this.handleSelectDomain}/>
        <div>
          <div className={'dms--added'}>
            {addedDomains}
          </div>
        </div>
      </div>
    );
  }
}