
import { AxiosResponse } from "axios";
import { clientService } from "../../../api/axios-client-api";

const api = clientService.api.v2

export const GetAllAffiliates = async (): Promise<AxiosResponse & { data: { key: string }[] }> => {
  const affiliatesUrl = 'parameters/affiliate.webhook.url/';
  const response = await api.Get(affiliatesUrl);

  return { ...response, data: response.data.map((affiliate: string) => ({ key: affiliate, _id: affiliate })) }
}

export const GetAffiliateWebhook = async (domain: string, key: string): Promise<AxiosResponse & { data: { key: string }[] }> => {
  const affiliateWebhookUrl = `parameters/affiliate.webhook.${domain}/${key}`;
  const response = await api.Get(affiliateWebhookUrl);

  return response;
}

export const PostAffiliateWebhook = async (domain: string, key: string, value?: string): Promise<AxiosResponse & { data: { key: string }[] }> => {
  const affiliateWebhookUrl = `parameters/affiliate.webhook.${domain}/${key}`;
  const response = await api.Post(affiliateWebhookUrl, {
    value: value ?? "",
    type: "string"
  });

  return response;
}

export const PutAffiliateWebhook = async (domain: string, key: string, value?: string): Promise<AxiosResponse & { data: { key: string }[] }> => {
  const affiliateWebhookUrl = `parameters/affiliate.webhook.${domain}/${key}`;
  const response = await api.Put(affiliateWebhookUrl, {
    value: value ?? "",
    type: "string"
  });

  return response;
}