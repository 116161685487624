import React from 'react';
import Field from '../components/field';
import moment from 'moment'
import { BankName, ServerManagedDomain } from '../application-components/input-domains';
import { FzCard, FzBadge } from '../ui/fz/layout/index';

const CampaignDetails = ({ns, applicant, application, api, ...props}) => {

  if (!applicant) {
    return null
  }
  let active
  if (applicant.active && moment(applicant.endDate) > moment()) {
    active = <FzBadge fzStyle="success">Ativa</FzBadge>
  } else {
    active = <FzBadge fzStyle="attention">Inativa</FzBadge>
  }
  let priority
  if (applicant.callPriority) {
    if (applicant.callPriority.startsWith("A")) {
      priority = <FzBadge fzStyle="success">{applicant.callPriority}</FzBadge>
    } else if (applicant.callPriority.startsWith("B")) {
      priority = <FzBadge fzStyle="primary">{applicant.callPriority}</FzBadge>
    } else {
      priority = <FzBadge fzStyle="regular">{applicant.callPriority}</FzBadge>
    }
  }
  let header = <h3 className="decoratedHeader">Campanha&nbsp;{active}{priority}</h3>
  return (
    <FzCard>
        <FzCard.Heading>
            <FzCard.Title>{header}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
            <Field
                type="string"
                path="firstName"
                label="Nome"
                ns={ns}
                data={application}
                {...props}
            />
            <Field
                type="string"
                path="lastName"
                label="Sobrenome"
                ns={ns}
                data={application}
                {...props}
            />
            <Field
                type="string"
                path="campaign"
                label="Nome da campanha"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="string"
                path="email"
                label="Email"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="string"
                path="telephoneNumber"
                label="Telefone"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="string"
                path="personalNumber"
                label="CPF"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="currency"
                path="approvedAmount"
                label="Valor Aprovado"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="select"
                component={ServerManagedDomain}
                domainName="account-type"
                path="accountType"
                label="Tipo de Conta Bancária"
                title="Se é uma conta-corrente e pode ter cheques"
                ns={ns}
                api={api}
                data={application}
                {...props}
            />
            <Field
                type="select"
                component={BankName}
                api={api}
                domainName="bank-name"
                path="bankName"
                label="Banco"
                ns={ns}
                data={application}
                {...props}
            />
            <Field
                type="number"
                path="approvedPeriodMonths"
                label="Prazo Aprovado"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="currency"
                path="approvedInstallment"
                label="Prestação Mensal"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="number"
                path="interestRate"
                label="Taxa de Juros Mensal"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="number"
                path="totalAnnualInterestRateid"
                label="Taxa de Anual (C.E.T.)"
                ns={ns}
                data={applicant}
                {...props}
            />
            <Field
                type="text"
                path="remarks"
                label="Observações"
                ns={ns}
                data={applicant}
                {...props}
            />
        </FzCard.Body>
    </FzCard>
  )
}

export default CampaignDetails;
