export const exportColumnsList = (outputFormat: string): string[] => {
  const base = [
    "_id",
    "accounttype",
    "applicationstate",
    "appliedamount",
    "auction.created",
    "mediasurvey",
    "ownsvehicle",
    "auction.enddate",
    "auction.startedbyself",
    "auditevents.0.eventdate",
    "auditevents.0.eventname",
    "auditevents.0.oldvalue",
    "auditevents.0.newvalue",
    "bankname",
    "bidsBreakdown.amountMaxAccepted",
    "bidsBreakdown.amountMaxAll",
    "bidsBreakdown.amountMinAccepted",
    "bidsBreakdown.amountMinAll",
    "bidsBreakdown.amountTotalAccepted",
    "bidsBreakdown.amountTotalAll",
    "bidsBreakdown.nbBidsAccepted",
    "bidsBreakdown.nbBidsAcceptedByUser",
    "bidsBreakdown.nbBidsAcceptedByOther",
    "bidsBreakdown.nbBidsAll",
    "bidsBreakdown.nbBidsRefused",
    "bidsBreakdown.nbBidsRejected",
    "bidsBreakdown.periodMonthsMax",
    "bidsBreakdown.periodMonthsMin",
    "bidsBreakdown.periodMonthsTotal",
    "bidsBreakdown.suppliersAccepted",
    "bidsBreakdown.suppliersRejected",
    "bidsBreakdown.suppliersSent",
    "birthdate",
    "city",
    "civilstatus",
    "closed",
    "closedreason",
    "converted",
    "created",
    "education",
    "email",
    "firstname",
    "gender",
    "hascreditrestrictions"
  ];
  const baseExtended = [
    "jobposition",
    "lastname",
    "loanperiodinmonths",
    "loanreason",
    "monthlyincome",
    "householdmonthlyincome",
    "neighbourhood",
    "personalnumber",
    "product",
    "rg",
    "serasascore",
    "state",
    "telephonenumber",
    "trackingdata.referrer",
    "trackingdata.source",
    "trackingdata.utm_campaign",
    "trackingdata.utm_content",
    "trackingdata.utm_medium",
    "trackingdata.utm_source",
    "trackingdata.deviceId",
    "trackingdata.clientId",
    "trackingdata.isMobile",
    "trackingdata.browser",
    "trackingdata.browserVersion",
    "trackingdata.os",
    "trackingdata.osVersion",
  ];
  const baseExtended2 = [
    "workstatus",
    "zipcode",
    "bidsBreakdown",
    "paid.paidamount",
    "paid.paiddate",
    "paid.supplierinternalname",
  ]
  const fullCPFieldsToExport = [
    ...base,
    "housing", 
    "incomeproofdocument",
    ...baseExtended,
    ...baseExtended2,
    "fzscore",
    "trackingdata.creationPage",
    "uistate.experiments",
    "age",
    "trackingdata.creationMode",
    "trackingdata.clientApp",
    "trackingdata.FnzApp",
    "homesecurity.hasregistrationnumber",
    "homesecurity.propertyregistered",
    "externalpartners.matched",
    "externalpartners.refused",
    "trackingdatahistory.completed.type",
    "trackingdatahistory.completed.utm_campaign",
    "trackingdatahistory.completed.utm_source",
    "trackingdatahistory.completed.utm_medium",
    "trackingdatahistory.completed.createdat",
    "trackingdatahistory.acceptedOffer.type",
    "trackingdatahistory.acceptedOffer.utm_campaign",
    "trackingdatahistory.acceptedOffer.utm_source",
    "trackingdatahistory.acceptedOffer.utm_medium",
    "trackingdatahistory.acceptedOffer.createdat",
  ]
  let out: string[] = [];

  if (outputFormat === 'full-cp') {
    out = fullCPFieldsToExport;
  } else if (outputFormat === 'full-consorcio') {
    out = [...base,
      "fzscore",
      "incomeproofdocument",
      ...baseExtended,
      ...baseExtended2,
      "trackingdata.creationPage",
      "uistate.experiments",
      "age",
      "trackingdata.creationMode",
      "trackingdata.clientApp",
      "trackingdata.FnzApp",
      "securitysubtype",
      "maxinstallment"];
  } else if (outputFormat === 'full-he') {
    out = [...base,
      "homesecurity.value",
      "homesecurity.propertytype",
      "homesecurity.constructiontype",
      "homesecurity.hasexistingloans",
      "homesecurity.valueofexistingloans",
      "homesecurity.zipcode",
      "homesecurity.address1",
      "homesecurity.address2",
      "homesecurity.streetnumber",
      "homesecurity.city",
      "homesecurity.state",
      "homesecurity.neighbourhood",
      "housing",
      "fzleadquality",
      "age",
      "trackingdata.creationMode",
      "trackingdata.clientApp",
      "trackingdata.FnzApp",
      "homesecurity.hasregistrationnumber",
      "homesecurity.propertyregistered",
      "trackingdata.creationPage",
      "externalpartners.matched",
      "externalpartners.refused",
    ];
  } else if (outputFormat === 'full-fin') {
    out = [...base,
      "housing",
      "incomeproofdocument",
      ...baseExtended,
      "vehiclesecurity.value",
      "vehiclesecurity.brand",
      "vehiclesecurity.model",
      "vehiclesecurity.modelyear",
      "vehiclesecurity.manufacturingyear",
      "vehiclesecurity.color",
      "vehiclesecurity.vehicletype",
      "vehiclesecurity.downpayment",
      "vehiclesecurity.hasexistingloans",
      "vehiclesecurity.numberofexistingloans",
      "vehiclesecurity.valueofexistingloans",
      "vehiclesecurity.sellersname",
      "vehiclesecurity.sellersphone",
      "vehiclesecurity.registrationnumber",
      "vehiclesecurity.framenumber",
      "vehiclesecurity.registrationdocumentnumber",
    ...baseExtended2,
      "age",
      "externalpartners.matched",
      "externalpartners.refused",
      "vehiclesecurity.accessorypackage",
      "vehiclesecurity.fueltype",
      "vehiclesecurity.numberofdoors",
      "vehiclesecurity.modelfipe",
      "vehiclesecurity.valuefipe",
      "vehiclesecurity.codefipe",
    ];
  } else if (outputFormat === "card") {
    out = [
      ...fullCPFieldsToExport,
      "cardbillday",
    ]
  } else {
    console.error("Not supported output format")
    return out;
  }

  return out;
}