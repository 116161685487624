
import { Meta } from "../../types/metas";

export let alternateOffersMeta: Meta = {
  "offerId": {
    "path": "offerId",
    "label": "id da oferta",
    "section": "alternateOffers",
    "dataType": "number",
    "inputType": "text",
    "tableProps": {
      "width": "8rem",
      "hidden": false,
      "shortLabel": "Ações",
      "dataAlign": "right",
      "isKey": true,
    },
    "isMandatory": (value: any) => false,
  },
  "approvedAmount": {
    "path": "approvedAmount",
    "label": "Valor total aprovado",
    "section": "bid",
    "dataType": "number",
    "tableProps": {
      "width": "4rem",
      "hidden": false,
      "shortLabel": "Aprovado",
      "filter": { type: 'SelectFilter', condition: 'eq', options: "buildColumnValuesFilter" },
      "dataAlign": "right",
      "columnFormat": "formatCurrency",
      "exportFormat": "formatCurrency",
    },
    "isMandatory": (value: any) => false,
  },
  "monthlyInstallment": {
    "path": "monthlyInstallment",
    "label": "Prestação",
    "section": "bid",
    "dataType": "number",
    "tableProps": {
      "width": "4rem",
      "hidden": false,
      "dataAlign": "right",
      "columnFormat": "formatCurrency",
      "exportFormat": "formatCurrency",
    },
    "isMandatory": (value: any) => false,
  },
  "accepted": {
    "path": "auction.bids",
    "label": "Aceito",
    "section": "bid",
    "dataType": "number",
    "tableProps": {
      "width": "4rem",
      "hidden": false,
      "shortLabel": "accepted",
      "dataAlign": "right",
    },
    "isMandatory": (value: any) => false,
  },
  "approvedPeriodMonths": {
    "path": "approvedPeriodMonths",
    "label": "Meses",
    "section": "bid",
    "dataType": "number",
    "tableProps": {
      "width": "4rem",
      "hidden": false,
      "shortLabel": "Meses",
      "filter": { type: 'SelectFilter', condition: 'eq', options: "buildColumnValuesFilter" },
      "dataAlign": "right",
    },
    "isMandatory": (value: any) => false,

  },
  "interestRate": {
    "path": "interestRate",
    "label": "Taxa juros",
    "section": "bid",
    "dataType": "number",
    "tableProps": {
      "width": "4rem",
      "hidden": false,
      "dataAlign": "right",
      "columnFormat": "pctColumn",
    },
    "isMandatory": (value: any) => false,

  },
  "totalAnnualInterestRate": {
    "path": "totalAnnualInterestRate",
    "label": "C.E.T. anual",
    "section": "bid",
    "dataType": "number",
    "tableProps": {
      "width": "4rem",
      "hidden": false,
      "dataAlign": "right",
      "columnFormat": "pctColumn",
    },
    "isMandatory": (value: any) => false,
  },

  "paymentMethod": {
    "path": "paymentMethod",
    "label": "Forma de Pagamento",
    "section": "bid",
    "dataType": "string",
    "tableProps": {
      "width": "4rem",
      "dataAlign": "center",
      "columnFormat": "getDescriptionPaymentMethod"
    },
    "isMandatory": (value: any) => false,
  },

}