import React from 'react'
import BlockUi from 'react-block-ui'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzButton } from '../../../ui/fz/form'
import { UpsellBannerColumns } from '../../../biz/metadatas/upsellBanner'
import { UpsellBanner } from '../../../services/api/dory/type'
import SplitLine from '../../../components/split-line'

interface Types {
  ns: any,
  data: Array<UpsellBanner>,
  handleGoToEditUpsell: (upsellId: UpsellBanner) => void,
  loading: boolean,
  history: any
}

const UpsellList = ({ ns, data, handleGoToEditUpsell, loading, history }: Types) => {
  const goToScreening = (upsellId, row) => {
    const { active = null } = row
    const ButtonComponent = (
      active 
        ? (
          <FzButton
            customStyle={{
              borderColor: '#5923DD',
              width: '100%',
              color: '#fff',
              background: '#5923DD'
            }}
            size="small"
            fzStyle=""
            onClick={() => handleGoToEditUpsell(row)}
          >
            Editar
          </FzButton>
        )
      : ""
    )

    return ButtonComponent
  }

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <div style={{ display: "flex", padding: "20px" }}>
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              <SplitLine>
                <SplitLine.Left>
                  Upsell Banners
                </SplitLine.Left>
                <SplitLine.Right>
                  <FzButton
                    size="small"
                    fzStyle="regular"
                    onClick={() =>  history.push("add")}
                  >
                    Criar upsell
                  </FzButton>
                </SplitLine.Right>
              </SplitLine>
            </FzCard.Title>

          </FzCard.Header>
          <FzCard.Body>
            <FzTable
              data={data}
              metas={UpsellBannerColumns}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              customColumnFormatters={{ 
                _id: (cell, row) => goToScreening(cell, row)
              }}
            />
          </FzCard.Body>
        </FzCard>
      </div>
    </BlockUi>
  )
}

export default UpsellList