'use strict';

import { clientService } from '../../../api/axios-client-api';
import {
  FinanvcRequestInterface,
  FinanvcResponseInterface,
} from '../../../biz/metadatas/url-shortener-metadata';

const finanvcApi = clientService.finanvcApi.v1;

export const postURLShortener = async (
  payload: FinanvcRequestInterface
): Promise<any | FinanvcResponseInterface> => {
  return finanvcApi.Post('admin/url', payload);
};
