const sumDig = (n) => {
  let a = 0;
  while (n > 0) {
    a = a + n % 10;
    n = parseInt(n / 10, 10);
  }
  return a;
}

const isValidIMEI = (n) => {
  let s = n.toString();
  let len = s.length;

  if(len === 0) {
    return {
      isValid: false,
      message: "Campo Imei é Obrigatório!"
    }
  }

  if (len != 15) {
    return {
      isValid: false,
      message: "Imei digitado está inválido!"
    }
  }

  let sum = 0;
  for (let i = len; i >= 1; i--) {
    let d = (n % 10);

    if (i % 2 == 0) {
      d = 2 * d;
    }

    sum += sumDig(d);
    n = parseInt(n / 10, 10);
  }

  const isValid = (sum % 10 == 0);
  return {
    isValid,
    message: ""
  }
}

export default isValidIMEI;
