import React, { PureComponent } from 'react';
import { FzCard, FzBadge } from "../layout";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


type FzBarData =
  {name: string} &
  {[x: string ] : number}[]

type FzBarAgent = string

type FzBarDataProps = {
  data: FzBarData,
  agent: FzBarAgent
}

class FzBarChart extends PureComponent< FzBarDataProps > {

  render() {

    const { data, agent } = this.props

    return (
      <FzCard>
        <FzCard.Body>
          <FzBadge>{agent}</FzBadge>
          <BarChart width={600} height={500} data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={'Atendido com sucesso'} stackId="a" fill='#0088FE' />
            <Bar dataKey={'Não consegui falar'} stackId="a" fill='#00C49F' />
            <Bar dataKey={'Sem tratativa'} stackId="a" fill='#FFBB28' />
            <Bar dataKey={'Atendido pelo time'} stackId="a" fill='#FF8042' />
            <Bar dataKey={"Total"} stackId="a" fill='#8884d8' />
          </BarChart>
        </FzCard.Body>
      </FzCard>

    );
  }
}

export { FzBarChart }