import { useState, useEffect } from 'react';
import { FzCard } from '../../fz/layout/FzCard';
import { FzButton } from '../../fz/form';
import FieldConnected from '../../components/form/field-connected';
import moment from 'moment';
import Papa from 'papaparse';
import { get } from 'lodash';
import { IBlockListRequest,IBlockListResponse, MetaBlocklist } from '../../../biz/metadatas/blocklist/blocklist-report';
import { BlocklistReportContainers } from '../../../Containers/Blocklist/BlocklistReportContainers';
import { disableBlocklist, upsertBlocklist, getAllBlocklist } from '../../../services/api/bureau';
import { MyCustomBodyModalBlocklist } from '../../blocks/blocklist-components/blocklist-modal';
import { optionsType, toastDefaultConfig, toastMessages } from '../../blocks/blocklist-components/blocklist-custom-fields';


export function BlocklistReport({ ns, history }) {
  const allowedType = ["email", "telephone", "personalnumber", "docid", "deviceid"]
  const allowedTypeRevert = ["Email", "Telefone", "CPF", "Deviceid"]
  const [toast, setToast] = useState(toastDefaultConfig);
  const [dataBlockList, setDataBlockList] = useState<IBlockListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  var data = ns.getShared("filters") || ns.getChanged();

  useEffect(()=>{
    ns.startEditing({
      startDate: moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0 }).local(),
      endDate: moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).local()
    })
  }, [])

  const handleToast = (type) => {
    setToast({
      ...get(toastMessages, type, {}),
      show: true,
      delay: 5000,
      autoHide: true,
      close: () => setToast(toastDefaultConfig),
    });
  };

  const handleUpdateBlocklist = async (blocklist, fieldName, value): Promise<void> => {
    try {
      const payload: IBlockListRequest = {
        ...blocklist,
        [fieldName]: value,
      };
      payload["type"] = allowedTypeRevert.indexOf(payload["type"])>-1 ? optionsType.find( it => it.description === payload.type)?.code ?? "Não Encontrado" : "docid";  
      payload["onblocklist"] = payload["onblocklist"] === 'true' 
      await disableBlocklist(payload);
      handleToast('update.success');
    } catch (error) {
      console.error(error);
      handleToast('update.error');
    }
  };

  const handleInsert = (newBlockList: IBlockListRequest): void => {
    if (!newBlockList.type || !newBlockList.value || !newBlockList.fullblock) {
      handleToast('insert.error');
      return;
    } 

    let data: IBlockListRequest 
    let fullblocking = newBlockList.fullblock.toString() === 'true' ? true : false
    if (allowedType.indexOf(newBlockList.type) === -1) {
      data = {
        type: 'docid',
        value: newBlockList.value,
        doctype: newBlockList.type,
        fullblock: fullblocking,
      };
    } else {
      data = {
        type: newBlockList.type,
        value: newBlockList.value,
        doctype: '',
        fullblock: fullblocking,
      };
    }
    
    upsertBlocklist([data])
      .then(() => handleToast('insert.success'))
      .catch(() => handleToast('insert.error'))
    
  };

  const handleUploadFile = async (_, event): Promise<void> => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          await saveFile(results)
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      handleToast('insert.error');
    }
  };


  const saveFile = async (fileCSV) => {
    try {
      if (fileCSV?.data.length > 0) {
        let data: IBlockListRequest[] = []
        let hasError: boolean = false;

        fileCSV.data.map((item: IBlockListRequest) => {
          if (allowedType.indexOf(item.type.toLowerCase()) >= 0) {
            let validateItem = item
            validateItem.type = item.type.toLowerCase()
            validateItem.fullblock = item.fullblock.toString().toLowerCase() === 'true' ? true : false

            data.push(validateItem);
          } else {
            console.error(item);
            hasError = true;
            handleToast('import.error');
            return;
          }
        });

        if (!hasError) {
          await upsertBlocklist(data);
          handleToast('import.success');
          return;
        }
      } 

      console.error(fileCSV?.data);
      handleToast('import.error');
      return;
    } catch (error) {
      console.error(error);
      handleToast('insert.error');
    }
  };

  const completeBlocklistPeriod = async (data): Promise<void> => {
    data = data.map(entry => {
      entry = {
        ...entry,
        daysinblocklist: Math.floor((new Date(entry.onblocklistuntil).getTime()-new Date().getTime())/ (1000 * 3600 * 24)),
        outcount: Math.floor((new Date().getTime()-new Date(entry.created).getTime())/ (1000 * 3600 * 24)),
      }
      entry.type = entry.type==="docid" ? optionsType.find( it => it.code === entry.doctype)?.description : optionsType.find( it => it.code === entry.type)?.description;
      return entry
    })
    setDataBlockList(data);
  };

  const getBlocklist = async (startDate, endDate): Promise<void> => {
    try {
      ns.startEditing({
        startDate: startDate,
        endDate: endDate
      })
      setLoading(true)
      setDataBlockList([]);
      getAllBlocklist(ns.getChanged()).then((data) => {completeBlocklistPeriod(data.data);setLoading(false);});
    } catch (error) {
      console.error(error);
      handleToast('get.error');
      setLoading(false)
    }
  };

  const createCustomModalBody = (columns, ignoreEditable) => {
    return (
      <MyCustomBodyModalBlocklist 
        columns={ columns }
        ignoreEditable={ ignoreEditable }
      />
    );
  }

  return (
    <>
      <FzCard>
        <div
          className={'fz-panel'}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={'fz-panel-border-padding'}>
            <FieldConnected
              meta={MetaBlocklist.startDate}
              data={data}
              ns={ns}
            />
          </div>

          <div className={'fz-panel-border-padding'}>
            <FieldConnected
              meta={MetaBlocklist.endDate}
              data={data}
              ns={ns}
            />
          </div>

          <div className={'fz-panel-border-padding'}>
            <FzButton
              onClick={async () => {
                const startDate = ns.getChanged('startDate');
                const endDate = ns.getChanged('endDate');

                await getBlocklist(startDate, endDate);
              }}
              tip={'buscar'}
              disabled={loading}
              className={null}
              block={true}
              fzStyle={'regular'}
            >
              Buscar
            </FzButton>
          </div>
        </div>
      </FzCard>

      <BlocklistReportContainers
        data={dataBlockList}
        handleUpdateBlocklist={handleUpdateBlocklist}
        handleInsert={handleInsert}
        handleUploadFile={handleUploadFile}
        insertModalBody={createCustomModalBody}
        meta={MetaBlocklist}
        ns={ns}
        toast={toast}
      />
    </>
  );
}
