import { Meta, MetaField, MetaWithFunctions } from '../../../types/metas';

export const ReprocessingSearchQueryMeta: Meta = {
  dateFrom: {
    path: 'dateFrom',
    label: 'De:',
    dataType: 'dateTime',
    isMandatory: () => false,
  },

  dateTo: {
    path: 'dateTo',
    label: 'Até:',
    dataType: 'dateTime',
    normalize: () => {},
    isMandatory: () => false,
  },

  supplierInternalName: {
    path: 'supplierInternalName',
    label: 'Parceiro:',
    dataType: 'string',
    isMandatory: () => false,
  },

  timeouted: {
    path: 'timeouted',
    label: 'Expiradas:',
    dataType: 'bool',
    normalize: (value) => {
      if (value !== '' && Boolean(value)) {
        return Boolean(value) ? 'Sim' : 'Não';
      }
    },
    isMandatory: () => false,
  },

  cancelled: {
    path: 'cancelled',
    label: 'Canceladas:',
    dataType: 'bool',
    normalize: (value) => {
      if (value !== '' && Boolean(value)) {
        return Boolean(value) ? 'Sim' : 'Não';
      }
    },
    isMandatory: () => false,
  },

  queue: {
    path: 'queue',
    label: 'Fila reprocessada:',
    dataType: 'string',
    isMandatory: () => false,
  },

  includedQueues: {
    path: 'includedQueues',
    label: 'Filas inclusas:',
    dataType: 'string',
    isMandatory: () => false,
  },

  excludedQueues: {
    path: 'excludedQueues',
    label: 'Filas excluidas:',
    dataType: 'string',
    isMandatory: () => false,
  },
};

export const ReprocessingSearchMeta: MetaWithFunctions = {
  id: {
    path: '_id',
    dataType: 'string',
    label: 'Id',
    tableProps: {
      viewsTable: '',
      filter: {
        type: 'TextFilter',
        delay: 500,
      },
      dataSort: false,
      dataAlign: 'center',
      isKey: true,
    },
    isMandatory: () => false,
  },

  createdFrom: {
    path: 'createdFrom',
    label: 'De:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      maxValue: new Date(),
      minValue: new Date(),
    },
    isMandatory: () => true,
  },

  createdTo: {
    path: 'createdTo',
    label: 'Até:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      maxValue: new Date(),
      minValue: new Date(),
    },
    isMandatory: () => true,
  },

  supplierInternalName: (suppliers: ItemListType[]): MetaField => {
    const options = suppliers || [];
    return {
      path: 'supplierInternalName',
      label: 'Parceiro',
      dataType: 'string',
      inputType: 'nav-dropdown',
      inputProps: {
        options: options,
      },
      isMandatory: () => false,
    };
  },

  supplierName: {
    path: 'supplierInternalName',
    label: 'Parceiro',
    dataType: 'string',
    isMandatory: () => false,
    tableProps: {
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
    },
  },

  userId: (users: ItemListType[]): MetaField => {
    const options = users || [];
    return {
      path: 'userId',
      label: 'Usuário',
      dataType: 'string',
      inputType: 'nav-dropdown',
      inputProps: {
        options: options,
      },
      isMandatory: () => false,
      tableProps: {
        dataSort: true,
        isKey: false,
        dataAlign: 'center',
      },
    };
  },

  cardId: {
    path: 'cardId',
    label: 'Card ID',
    dataType: 'string',
    inputType: 'text',
    inputProps: {},
    validationMessage: 'Card ID inválido',
    pattern: /^[a-zA-Z]{2,}\d{1,}$|^[a-zA-Z]{2,}-\d{1,}$/,
    isMandatory: () => false,
    tableProps: {
      viewsTable: '',
      isKey: false,
      filter: {
        type: 'TextFilter',
        delay: 500,
      },
      dataSort: true,
      dataAlign: 'center',
    },
  },

  limit: {
    path: 'limit',
    label: 'Limit',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 1000, description: '1000' },
        { code: 500, description: '500' },
        { code: 250, description: '250' },
        { code: 100, description: '100' },
        { code: 50, description: '50' },
        { code: 20, description: '20' },
        { code: 10, description: '10' },
      ],
    },
    normalize: (value) => {
      return value === 'undefined' ? '' : value;
    },
    isMandatory: () => false,
  },

  funnelStage: {
    path: 'funnelStage',
    label: 'Etapa do funil',
    tableProps: {
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
      filter: {
        type: 'SelectFilter',
        options: "funnelDescriptions"
      },
    },
    normalize: (value) => funnelDescriptions[value] || value,
    isMandatory: () => false,
  },

  created: {
    path: 'created',
    label: 'Reprocessado em',
    tableProps: {
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
      columnFormat: 'dateTimeColumn',
    },
    isMandatory: () => false,
  },

  userName: {
    path: 'userName',
    label: 'Reprocessado por',
    tableProps: {
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
    },
    isMandatory: () => false,
  },

  executed: {
    path: 'executed',
    label: 'Executado',
    dataType: 'bool',
    tableProps: {
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
      width: '10ch',
    },
    normalize: (value) => value === "true" ? "Sim" : "Não",
    isMandatory: () => false,
  },

  queuesId: {
    path: 'queuesId',
    label: 'Filas',
    dataType: 'string',
    inputType: 'textarea',
    normalize: (value) => {
      if (Array.isArray(value)) {
        return value.toString();
      }
      return value;
    },
    isMandatory: () => false,
  },

  applications: {
    path: 'applications',
    label: 'Propostas',
    dataType: 'string',
    inputType: 'textarea',
    normalize: (value) => {
      if (Array.isArray(value)) {
        return value.toString();
      }
      return value;
    },
    isMandatory: () => false,
  },

  // TODO: userEmail, userId should be added?
};

const funnelDescriptions = {
  lead: 'Em cadastro',
  hasBids: 'Aguardando aceite',
  followUp: 'Em formalização',
  archived: 'Paga',
  closed: 'Encerrada',
  excluded: 'Excluída',
};

export type ItemListType = {
  code: string;
  description: string;
};

export type UserType = {
  id: string;
  name: string;
  group: string;
  active: boolean;
};

export type FiltersType = {
  userId: string;
  supplierInternalName: string;
  createdFrom: string | Date;
  createdTo: string | Date;
  limit: number;
  offset: number;
};

export type ReprocessingResponseType = {
  _id: string;
  userId: string;
  userName: string;
  userEmail: string;
  queuesId: Array<string>;
  applications?: Array<string>;
  funnelStage: string;
  cardId: string;
  created: string | Date;
  executed: boolean;
  query: ReprocessingQueryResponseType;
};

type ReprocessingQueryResponseType = {
  dateFrom: string | Date;
  dateTo: string | Date;
  supplierInternalName: string;
  timeouted: boolean;
  cancelled: boolean;
  queue?: string;
  includedQueues?: Array<string> | string;
  excludedQueues?: Array<string> | string;
};
