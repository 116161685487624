import { clone, get } from 'lodash';
import moment from 'moment';
import FieldConnected from '../../../../ui/components/form/field-connected';
import { FzIcons } from '../../../../ui/fz/form/icon';
import { momentFormatUTC } from '../../../../ui/fz/formatter/data-formatter';

const EDITABLE_FIELDS = [
  'fullName',
  'firstName',
  'lastName',
  'mothersName',
  'address1',
  'RG',
  'RGExpeditionDate',
  'city',
  'neighbourhood',
  'state',
  'address2',
  'streetNumber',
  'zipCode',
  'monthlyIncome',
  'birthDate',
  'jobPosition',
  'workStatus',
  'bankName',
  'agency',
  'accountNumber',
  'accountType',
];

function createMetaFields(fields, ns) {
  let meta = {};
  fields.map((field) => {
    let currentField = clone(field);
    const fieldName = currentField.path;

    meta[fieldName] = currentField;
    meta[fieldName].isMandatory = (application) => false;
    meta[fieldName].dataType = currentField.type;
    meta[fieldName].inputProps = {
      altInputLayout: true,
    };

    if (fieldName === 'RGExpeditionDate' || fieldName === 'birthDate') {
      meta[fieldName].inputProps = {
        mask: '11/11/1111',
        altInputLayout: true,
      };
      meta[fieldName].pattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    }

    if (meta[fieldName].type === 'domain') {
      meta[fieldName].inputProps.domainName = currentField.domainPath;
      meta[fieldName].inputType = 'select';
      if (fieldName == 'jobPosition' && ns.getChanged()) {
        meta[fieldName].inputProps.filteredFrom = 'workStatus';
      }
    }

    if (EDITABLE_FIELDS.indexOf(fieldName) < 0) {
      meta[fieldName].isReadOnly = () => true;
    }

    if (fieldName === 'fullName') {
      meta[fieldName].pattern = /(\D{2,60})\s(\D{2,60}\s?)+$/;
    }

    if (fieldName === 'personalNumber') {
      meta[fieldName].inputProps = {
        mask: '111.111.111-11',
        removeMask: true,
        altInputLayout: true,
      };
      meta[fieldName].pattern = /^\d{11}$/;
    }

    if (fieldName === 'zipCode') {
      meta[fieldName].inputProps = {
        autoComplete: 'postal-code',
        mask: '#####-###',
        altInputLayout: true,
      };
      meta[fieldName].pattern = /^\d{5}-?\d{3}$/;
    }
  });
  return meta;
}

function createDataForFields(data, ns) {
  let dataObj = {};
  data.map((currentField) => {
    if (
      currentField.path === 'RGExpeditionDate' ||
      currentField.path === 'birthDate'
    ) {
      const dateFormatted = momentFormatUTC(currentField.value, 'DD/MM/yyyy');
      dataObj[currentField.path] = dateFormatted;
    } else {
      dataObj[currentField.path] = currentField.value;
    }
  });
  return dataObj;
}

function getDescription(applicationField, ns) {
  const domain = ns.getShared(
    `SCREENING.domains.${applicationField.domainPath}`
  );
  if (Array.isArray(domain)) {
    const value =
      domain.find((it) => it.code === applicationField.value)?.description ??
      'Não encontrado';
    return value;
  }
}

const ApplicationFieldsForDocumentContainer = (props) => {
  const {
    data,
    ns,
    handleEditing,
    isEditing,
    setHeaderMessageTost,
    setBodyMessageTost,
    seColorTost,
    setShow,
    setFieldsOverride,
  } = props;
  if (!data || !data.length) {
    return null;
  }

  const RGDocTypeField = data.find((field) => field.path === 'RGDocType');
  const description = getDescription(RGDocTypeField, ns);
  const RGField = data.find((field) => field.path === 'RG') || {};
  RGField.label = description;

  const fields = data.filter((field) => field.path !== 'RGDocType');
  const meta = createMetaFields(fields, ns);
  const parsedData = createDataForFields(data, ns);
  const invalidForm = ns.get('INVALID', []).length > 0;

  function showSuccess() {
    setHeaderMessageTost('Edição de dados');
    setBodyMessageTost('Alterações salvas com sucesso!');
    seColorTost('success');
    setShow(true);
  }

  function getChangedValues(oldObj, newObj) {
    const resultObj = {};
    const fields = Object.keys(oldObj);

    fields.map((field) => {
      if (field === 'monthlyIncome') {
        let value = get(newObj, field);
        if (typeof value === 'string') {
          // checking if montlhyIncome is a Number
          newObj[field] = value.replace(',', '.'); // If monthlyIncome is a String we replace commas for dots
        }
      }

      if (get(oldObj, field) !== get(newObj, field)) {
        resultObj[field] = get(newObj, field);
      }

      if (
        (field === 'RGExpeditionDate' || field === 'birthDate') &&
        get(oldObj, field) !== get(newObj, field)
      ) {
        let value = get(newObj, field);
        resultObj[field] = moment(
          `${value.split('/')[2]}/${value.split('/')[1]}/${value.split('/')[0]}`
        ).toISOString();
      }
    });

    return { fieldsOverrides: resultObj };
  }

  return (
    <div className={props.className} style={props.style}>
      <div className="row screening-edit-bid">
        {fields.map((applicationField, index) => {
          return (
            <div className="col-lg-4 col-md-6">
              <FieldConnected
                meta={meta[applicationField.path]}
                ns={ns}
                data={parsedData}
              />
            </div>
          );
        })}
      </div>
      <div className="row">
        {!isEditing ? (
          <div className="col-12">
            <button
              className="screening-button edit-button"
              onClick={() => {
                handleEditing();
                ns.startEditing(parsedData);
              }}
            >
              <FzIcons
                icon="pencil"
                fzStyle={{
                  height: '16px',
                  marginRight: '5px',
                  verticalAlign: 'text-top',
                }}
              />
              Editar
            </button>
          </div>
        ) : (
          <div className="col-12">
            <button
              className="screening-button save-button"
              disabled={invalidForm}
              onClick={async () => {
                try {
                  const response = await setFieldsOverride(
                    getChangedValues(parsedData, ns.getChanged())
                  );
                  handleEditing();
                  ns.stopEditing();
                  ns.setShared('SCREENING.screening_response', response.data);
                  showSuccess();
                } catch (err) {
                  let error: $TsFixMe = err;
                  setHeaderMessageTost('Screening error');
                  if (error && error.response) {
                    setBodyMessageTost(error.response.data.message);
                  }
                  seColorTost('danger');
                  setShow(true);
                }
              }}
            >
              Salvar
            </button>
            <button
              className="screening-button cancel-button"
              onClick={() => {
                handleEditing();
                ns.stopEditing();
              }}
            >
              Cancelar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export { ApplicationFieldsForDocumentContainer };
