import React from 'react'
import { Jumbotron, Button, ButtonGroup } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import FieldConnected from '../../../ui/components/form/field-connected';
import { FzCol, FzRow, FzSplitLine } from '../../../ui/fz/layout';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzCardGroup } from '../../../ui/fz/layout/';
import { UpsellBanner } from '../../../services/api/dory/type';
import { validateAppField } from "../../../biz/validate-application";
import { FzToast } from '../../../ui/fz/form/notification/toast';

const EditInputLayout = ({
  inputLayoutForm,
  metaDataForm,
  ns, 
  handleSubmit, 
  goBack,
  toastInfo,
  handleToastClose,
  loading,
  handleAddFieldConnected,
  handleRemoveItem,
  fieldConnectedItens,
  suppliersOptions,
  mapFieldOptions,
}) => {
  const formItems = Object.keys(inputLayoutForm);
  const RenderFormItem = () => {
    return formItems.map((item, index) => {
      const fieldColumn = item.includes("_0")
      const spanSize = (
        fieldColumn ? (
          item.includes('field_0')
          ? 3
          : 2
        ) : item.includes("referenceNumberDays") ? 12 : 6
      )
      const meta = metaDataForm[item]

      return (
        <FzCol span={spanSize} key={index}>
          <FieldConnected
            meta={meta}
            ns={ns}
          />
        </FzCol>
      )
    });
  }

  metaDataForm
    .supplierInternalName
    .inputProps
    .options = suppliersOptions
  
  metaDataForm
    .mapField_0
    .inputProps
    .options = mapFieldOptions

  const formFieldIsEmpty = async data => {
    const formElmentItens = Object.keys(metaDataForm);
    const checkIsEmptyFields = await Promise.all(
      formElmentItens.map(item => {
        return validateAppField({ 
          value: data[item], 
          data, 
          ns, 
          metaField: metaDataForm[item],
        })
      })
    )

    const invalidFields = checkIsEmptyFields
      .filter(({ valid }) => !valid)
      .map(({ path }) => path)

    ns.set("INVALID", invalidFields)

    return invalidFields
  }

  const handleSubmitAndCheckForm = () => {
    const values: UpsellBanner = ns.getChanged();
    formFieldIsEmpty(values)
      .then(fields => handleSubmit(values, fields))
    
  }

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <Jumbotron>
        <ButtonGroup>
          <Button 
            variant="primary"
            onClick={handleSubmitAndCheckForm}
          >
            Gravar
          </Button>
          <Button
            variant="default"
            onClick={goBack}
          >
            Cancelar
          </Button>
        </ButtonGroup>
        <FzCardGroup bsPrefix="pathname-form">
          <FzCard>
            <FzCard.Header>
              <FzSplitLine>
                <FzSplitLine.Left>
                  <FzCard.Title>Editar novo Input Layout</FzCard.Title>
                </FzSplitLine.Left>
              </FzSplitLine>
            </FzCard.Header>

            <FzCard.Body>
                <FzRow>
                  {RenderFormItem()}
                  <FzCol span={1} /> 
                  {fieldConnectedItens.map((ComponentItem, index) => 
                    <ComponentItem 
                      key={index}
                      meta={metaDataForm}
                      handleRemoveItem={handleRemoveItem(index)}
                      ns={ns}
                    />
                  )}
                  <FzCol span={6}>
                    <Button onClick={handleAddFieldConnected}>Adicionar</Button>
                  </FzCol>
                </FzRow>
            </FzCard.Body>
          </FzCard>
        </FzCardGroup>
        <FzToast {...toastInfo} close={handleToastClose} />
      </Jumbotron>
    </BlockUi>
  )
}

export default EditInputLayout;
