import React, { Component, useEffect, useState } from 'react';
import { CampaignFilter } from '../../../biz/metadatas/campaign-filter';
import { FzField, FzButton } from '../../fz/form';
import { FzCol, FzRow } from '../../fz/layout/index';
import { DomainMultiSelector } from './domain-selector';
import { getDomain } from '../../../scripts/utils/cache-helpers';
import { getApplicationStates } from '../../../business/application';
import { getAllProducts } from '../../../api/applications';

const styles = {
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  deleteButton: {
    fontSize: '18px',
  },
  inputTitle: {
    marginBottom: '8px',
  },
  spacement: {
    margin: '6px',
  },
};

export function DomainsMatch(props) {
  const { ns, data, onChange } = props;
  const [allProductsList, setAllProductsList] = useState({});

  useEffect(() => {
    const { domain, field } = data;
    loadAllProducts();
    if (field && !domain) {
      preSelectDomain(field);
    }
  }, []);

  async function loadAllProducts() {
    const productsResp = await getAllProducts();
    setAllProductsList(productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    );
  }

  useEffect(() => {
    const { domain, field } = data;
    if (field && !domain) {
      preSelectDomain(field);
    }
  }, []);

  const handleSelectDomain = (field) => {
    const selected = [];
    let applicationField = CampaignFilter.getFieldByPath(field);

    if (typeof applicationField !== 'undefined') {
      const { dataType, inputProps } = applicationField;
      const { options, domainName } = inputProps;

      const applicationDataType = dataType === 'number' ? 'number' : '';
      const applicationOptions =
        applicationField && inputProps && options ? options : undefined;
      const domain =
        applicationField && inputProps && domainName ? domainName : undefined;
      const apiDomain = getDomain(ns, domain);
      const possibleOptions =
        apiDomain && Array.isArray(apiDomain)
          ? apiDomain.filter((d) => selected.indexOf(d.code) === -1)
          : applicationOptions
          ? applicationOptions
          : [];

      onChange({
        ...data,
        dataType,
        domain,
        field,
        possibleOptions,
        selected,
        [`${applicationDataType}in`]: [],
        [`${applicationDataType}nin`]: [],
      });
    }
  };

  const preSelectDomain = (domain) => {
    const applicationField = CampaignFilter.getFieldByPath(domain);
    const dataType =
      applicationField && applicationField.dataType === 'number'
        ? 'number'
        : '';

    if (applicationField) {
      onChange({
        ...data,
        dataType: dataType,
        domain,
        field: applicationField.path,
        options: applicationField.inputProps.options,
        [`${dataType}in`]: data[`${dataType}in`],
        [`${dataType}nin`]: data[`${dataType}nin`],
        in: data[`${dataType}in`],
        nin: data[`${dataType}nin`],
      });
    }
  };

  const stringArray = (arr) => [...arr.map(el => String(el))]

  const handleChange = (from, to) => {
    const { dataType, possibleOptions, options } = data;
    const newDataType = dataType === 'number' ? 'number' : '';
    let selectedArray,
      fromArray,
      toArray = [];
    let fromData = from ? from : [];
    let toData = to ? to : [];
    let selected = fromData.concat(toData);

    const newPossibleOptions = possibleOptions ? possibleOptions.filter(
      (options) => selected.indexOf(options.code) === -1
    ) : options

    if (selected.length > 0) selectedArray = removeDuplicates(selected);
    if (fromData.length > 0) fromArray = removeDuplicates(from);
    if (toData.length > 0) toArray = removeDuplicates(to);

    onChange({
      ...data,
      [`in`]: stringArray(fromArray),
      [`nin`]: stringArray(toArray),
      [`${newDataType}in`]: fromArray,
      [`${newDataType}nin`]: toArray,
      selected: selectedArray,
      options: newPossibleOptions,
    });
  };

  const formatDomain = (possibleOptions) => {
    const labelApplicationState = getApplicationStates();
    if (possibleOptions && possibleOptions.length > 0) {
      for (let i = 0; i < possibleOptions.length; i++) {
        if (labelApplicationState[possibleOptions[i].code]) {
          possibleOptions[i].description =
            labelApplicationState[possibleOptions[i].code];
        }
      }
      return possibleOptions;
    }
    return possibleOptions;
  };

  const removeDuplicates = (array) => {
    const uniqueSet = new Set(array);
    return [...uniqueSet];
  };

  const { deleteContainer, deleteButton, inputTitle, spacement } = styles;
  const { dataType, field, domain, possibleOptions, options } = data;
  const fields = CampaignFilter;
  const appFields = {};
  const domainsOptions = Object.keys(fields)
    .map((key) => {
      appFields[key] = fields[key];
      return key;
    })
    .map((key) =>
      appFields[key].filterType === 'domain' ? appFields[key] : undefined
    )
    .filter((field) => field)
    .map((field) => ({
      code: field.path.toLowerCase(),
      originalCode: field.path,
      description: field.label,
      dataType: field.dataType,
    }));

  const newDataType = dataType === 'number' ? 'number' : '';
  let formattedPossibleOptions;

  if (domain === 'applicationstate') {
    formattedPossibleOptions = formatDomain(possibleOptions);
  } else {
    formattedPossibleOptions = possibleOptions || options;
  }

  if (domain === 'product') {
    formattedPossibleOptions = allProductsList;
  }

  return (
    <div key={`rule-domain-${Math.random()}`} className="fz-rule rule-domain">
      <FzRow>
        <FzCol span={4}>
          <h4 style={inputTitle}>Domínio</h4>

          <FzField
            name={'domains-match-name'}
            dataType={'text'}
            inputType={'select'}
            value={field}
            inputProps={{ options: domainsOptions }}
            onChange={handleSelectDomain}
          />
        </FzCol>

        <>
          <FzCol span={4}>
            <h4 style={inputTitle}>Seja:</h4>
            <DomainMultiSelector
              ns={ns}
              domain={domain}
              selected={data[`${newDataType}in`]}
              dataType={dataType}
              data={data}
              onChange={(value) =>
                handleChange(value, data[`${newDataType}nin`])
              }
              options={formattedPossibleOptions}
            />
          </FzCol>

          <FzCol span={3}>
            <h4 style={inputTitle}>Não seja:</h4>
            <DomainMultiSelector
              ns={ns}
              domain={domain}
              selected={data[`${newDataType}nin`]}
              dataType={dataType}
              onChange={(value) =>
                handleChange(data[`${newDataType}in`], value)
              }
              options={formattedPossibleOptions}
            />
          </FzCol>
        </>

        <FzCol span={1}>
          <span style={deleteContainer}>
            <span style={spacement} />
            <FzButton
              fzStyle={'alert'}
              onClick={() => {
                onChange(false);
              }}
            >
              <p style={deleteButton}>X</p>
            </FzButton>
          </span>
        </FzCol>
      </FzRow>
    </div>
  );
}
