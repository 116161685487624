import React, { Component } from 'react';
import { NavItem } from 'react-bootstrap';
import { FzCard, FzCol, FzRow, FzGrid } from '../../fz/layout/index'


import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'


import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import logo from "../../../images/logo.png";
import { StatsSales } from '../../blocks/statistics/sales-stats';
import { RevenueStats } from '../../blocks/statistics/revenue-stats';


import MktStats from '../../blocks/statistics/mkt-stats';
import FormalizationsStats from '../../blocks/statistics/formalization-stats'
import FunnelStats from '../../blocks/statistics/funnel-stats';
import FormStats from '../../blocks/statistics/form-stats'
import MessagingStats from '../../blocks/statistics/messaging-stats'
import { TableOptions } from '../../../components/table-standard'
import { getCurrentUser } from '../../../api/auth';
import { ScreeningStats } from '../../blocks/statistics/screening-stats';
import { getAllProducts } from '../../../api/applications';

export class StatsConsole extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: props.match.params.tab,
      subItem: props.match.params.subItem,
      period: props.match.params.period,
      blockStatsConsole: false,
      products: [
        'Refin', 
        'Fin', 
        'HE', 
        'Cartao', 
        'Consorcio', 
        'CP', 
        'FGTS', 
        'HL', 
        'Consignado',
        'SME',
        'SeguroRenda',
        'SeguroAutomovel',
      ],
    }

    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.handleSubPageSelect = this.handleSubPageSelect.bind(this);
    this.toggleBlocking = this.toggleBlocking.bind(this);
    var today = new Date();
    this.dateRef = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    this.currentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    this.lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
    this.beforeLastMonth = new Date(today.getFullYear(), today.getMonth() - 2, 1)
    this.subMenu = this.subMenu.bind()

  }

  handlePageSelect = (eventKey) => {
    this.props.history.push('/stats/' + eventKey)
  }

  handleSubPageSelect = (eventKey) => {
    this.props.history.push('/stats/' + this.state.tab + "/" + eventKey)
  }

  toggleBlocking(callback = function () { }) {
    this.setState({ blockStatsConsole: !this.state.blockStatsConsole }, callback);
  }

  subMenu = () => {
    const user = getCurrentUser()

    if (user && user.role !== "admin") {
      return (<FzGrid>
        <FzRow className="show-grid">
          <FzCol span={9}>
            <Nav variant="tabs" activeKey={this.state.tab} onSelect={this.handlePageSelect}>
              <NavItem eventKey="funnel" title="Application's funnel">Funnel</NavItem>
              <NavItem eventKey="mkt" title="General statistics on applications">Applications</NavItem>
              <NavItem eventKey="formalization" title="General statistics on formalizations">Formalizations</NavItem>
              <NavItem eventKey="sales" title="Sales pipeline">Sales</NavItem>
              <NavItem eventKey="revenue" title="Revenue model">Revenue Model</NavItem>
              <NavItem eventKey="form" title="Form's business and UX efficiency">Form</NavItem>
              <NavItem eventKey="messaging" title="Reports messaging">Messaging</NavItem>
            </Nav>
          </FzCol>
        </FzRow>
      </FzGrid>);
    }
  }

  componentDidMount() {
    this.getProducts()
  }

  async getProducts() {
    try {
      const products = await getAllProducts();
      this.setState({ 
        products: products.map(({ internalName }) => internalName)
      })
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    let { ns, sharedNS, api } = this.props

    let selectedStatistics
    let submenu
    switch (this.state.tab) {

      case 'funnel':
        selectedStatistics = (
          <FunnelStats 
            ns={ns} 
            api={api} 
            activeKey={this.state.subItem} 
            tableOptions={TableOptions} 
            toggleBlocking={this.toggleBlocking}
            products={this.state.products}
          />
        )
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="overview_panel" title="Operational overview">Operational Overview Panel</Nav.Link>
            <Nav.Link eventKey="partners_panel" title="Operational by supplier">Operational Partners Panel</Nav.Link>
            <Nav.Link eventKey="run_rate" title="Run Rate">Run Rate</Nav.Link>
            <Nav.Link eventKey="customers_book" title="Customers Book">Customers Book</Nav.Link>
          </Nav>
        )
        break;
      case 'sales':
        selectedStatistics = <StatsSales ns={ns} activeKey={this.state.subItem} sharedNS={sharedNS} toggleBlocking={this.toggleBlocking} />
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="qualified" title="Qualified Leads">Qualified</Nav.Link>
            <Nav.Link eventKey="acceptedby" title="Sales per Agent">Accepted By</Nav.Link>
            <Nav.Link eventKey="agent" title="Callcenter Contacts">Calls per agent</Nav.Link>
            <Nav.Link eventKey="agent_charts" title="Callcenter Contacts">Calls per agent Charts</Nav.Link>
            <Nav.Link eventKey="treatmensts" title="Callcenter Contacts per Treatments">Calls per Treatments</Nav.Link>
            <Nav.Link eventKey="cstreatments" title="Treatments per CS Care">Calls per CS Treatments</Nav.Link>
            <Nav.Link eventKey="productchanges" title="Product Changes">Product Changes</Nav.Link>
            <Nav.Link eventKey="productivity" title="Productivity">Productivity</Nav.Link>
            <Nav.Link eventKey="advisorqueue" title="Advisor queue NOW">Queue NOW</Nav.Link>
            <Nav.Link eventKey="capacityreview" title="Capacity Review">Capacity Review</Nav.Link>
          </Nav>
        )
        break;
      case 'mkt': 
        selectedStatistics = <MktStats ns={ns} activeKey={this.state.subItem} history={this.props.history} toggleBlocking={this.toggleBlocking} />
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >           
            <Nav.Link eventKey="paid" title="Paid applications">Paid</Nav.Link>
            <Nav.Link eventKey="fzscore" title="Sent applications to suppliers">FinanZero Score</Nav.Link>
            <Nav.Link eventKey="deleted-reason" title="Deleted Reasons">Razões de Exclusão</Nav.Link>
          </Nav>
        )
        break;
      case 'formalization':
        selectedStatistics = <FormalizationsStats ns={ns} activeKey={this.state.subItem} toggleBlocking={this.toggleBlocking} />
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="documents" title="Documents status">Documents</Nav.Link>
            <Nav.Link eventKey="unico" title="Único">Único</Nav.Link>
            <Nav.Link eventKey="hs" title="hs">HS</Nav.Link>
            <Nav.Link eventKey="boaVista" title="Boa Vista">Boa Vista</Nav.Link>
          </Nav>
        )
        break;
      case 'revenue':
        selectedStatistics = <RevenueStats ns={ns} activeKey={this.state.subItem} toggleBlocking={this.toggleBlocking} />
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="overview" title="Model Overview">Overview</Nav.Link>
            <Nav.Link eventKey="partners" title="Model per Partners">Per Partners</Nav.Link>
          </Nav>
        )
        break;
      case 'form':
        selectedStatistics = <FormStats ns={ns} activeKey={this.state.subItem} tableOptions={TableOptions} toggleBlocking={this.toggleBlocking} />
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="form-progression" title="Funnel form performance">General Progression</Nav.Link>
            <Nav.Link eventKey="form-validation" title="Form versions fields validation stats">Fields Validation</Nav.Link>
          </Nav>
        )
        break;
      case 'messaging':
        selectedStatistics = <MessagingStats ns={ns} activeKey={this.state.subItem} tableOptions={TableOptions} sharedNS={sharedNS} toggleBlocking={this.toggleBlocking} />
        submenu = (
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="finanvc" title="Reports finanvc">finanvc</Nav.Link>
          </Nav>
        )
        break;
      case 'screening':
        selectedStatistics = <ScreeningStats
          ns={ns}
          activeKey={this.state.subItem}
          tableOptions={TableOptions}
          sharedNS={sharedNS}
          toggleBlocking={this.toggleBlocking}
          api={api}
        />
        submenu = (api.isUserInRole("screener") ?
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="screening" title="Report Screening">Relatórios gerais</Nav.Link>
          </Nav>
          :
          <Nav className="adjusted-nav" variant="tabs" onSelect={this.handleSubPageSelect} activeKey={this.state.subItem} >
            <Nav.Link eventKey="screening" title="Report Screening">Relatórios gerais</Nav.Link>
            <Nav.Link eventKey="tma" title="TMA Individual"> TMA Individual </Nav.Link>
            <Nav.Link eventKey="pendency" title="Pendency of documents">Pendência de documentos</Nav.Link>
            <Nav.Link eventKey="causes_of_pendency" title=" Causes of Pendency">Documentos Analisados</Nav.Link>
            <Nav.Link eventKey="overwrite_documents" title="Overwrite Documents">Documentos Alterados</Nav.Link>
            <Nav.Link eventKey="prioritized_bids" title="Prioritized Bids">Propostas priorizadas</Nav.Link>
            <Nav.Link eventKey="edition_application" title="Proposed Edition">Edição de Propostas</Nav.Link>
            <Nav.Link eventKey="ocr_analytics" title="Analítico OCR">Analítico OCR</Nav.Link>
          </Nav>
        )
        break;
      default:
        selectedStatistics = <h1 className="home-app-name">COF&nbsp;<img src={logo} alt="FinanZero logo" /></h1>
    }
    let navbar = <Navbar className="report-navbar">
      {submenu}
    </Navbar>

    return (
      <BlockUi tag="div" blocking={this.state.blockStatsConsole} >
        <FzCard>
          <FzCard.Heading fzStyle={{ paddingBottom: "0", marginBottom: "0" }}>
            <FzCard.Title fzStyle={{ marginBottom: "0" }}>{navbar}</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            {selectedStatistics}
          </FzCard.Body>
        </FzCard>
      </BlockUi>
    );
  }


}
