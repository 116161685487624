import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import { FzForm } from '../ui/fz/layout/index';

class InputNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.loadCache = this.loadCache.bind(this);
    this.saveCache = this.saveCache.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  formatNumber (value) {
    if (this.props.type === "currency") {
      if (this.props.decimalDigits === 0) {
        return this.props.intl.formatNumber(value, {style: 'currency', currency: 'BRL', maximumFractionDigits:2})
      } else {
        return this.props.intl.formatNumber(value, {style: 'currency', currency: 'BRL'})
      }
    } else {
        return this.props.intl.formatNumber(value)
    }
  }
  loadCache() {
    this.setState({value: this.props.value.toString()});
  }

  saveCache() {
    if (this.state.value === undefined || this.state.value === null) {
      return;
    }
    let currentValue = this.state.value.replace(',','.');
    let dec = currentValue.indexOf('.')
    if (dec >= 0 && this.props.decimalDigits === 0) {
      currentValue = currentValue.slice(0, dec)
    }
    currentValue = Number(currentValue)
    if (isNaN(currentValue)) {
      currentValue = 0;
    }
    if (this.props.value !== currentValue) {
      this.props.onChange(currentValue);
    }
    this.setState({value: undefined});
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    if (this.props.notifyInputChanges) {
      this.props.notifyInputChanges(event.target.value);
    }
  }

  render() {
    // extract props used here and remove 'onChange' and 'notifyInputChanges' (used by methods above)
    let { type, domId, value, original, onChange, name, notifyInputChanges, ns, mask, ...props } = this.props;
    delete props.intl;
    delete props.decimalDigits;
    value = (value === undefined) || (value === null) ? '' : value;
    original = (original === undefined) || (original === null) ? '' : original;

    const content = this.state.value === undefined ? this.formatNumber(value) : this.state.value
    const changedClass = "form-control form-control" + (content !== original ? ' changed' : '');

    if (mask === undefined) {
        return (
          <FzForm.Control
            id={domId}
            type="text"
            value={content}
            disabled={props.readOnly}
            name={name}
            className={changedClass}
            onChange={this.handleChange}
            onFocus={this.loadCache}
            onBlur={this.saveCache}
            {...props}
            />
        );
    }
  }
};

InputNumber.propTypes = {
  type: PropTypes.string,
  domId: PropTypes.string,
  value: PropTypes.number,
  original: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  notifyInputChanges: PropTypes.func
};

export default injectIntl(InputNumber);

