

import api, { misApi } from './client-api';

export type affiliateOverviewResponse = {
  "created": Date,
  "lastUpdatedData": Date,
  "statisticsType": string,
  "consolidationPeriod": string,
  "queryStartDate": Date,
  "queryEndDate": Date,
  "statistics": [{}],
  "metadata": {}
}

export type affiliateCampaignOverviewResponse = {
  "created": Date,
  "lastUpdatedData": Date,
  "statisticsType": string,
  "consolidationPeriod": string,
  "queryStartDate": Date,
  "queryEndDate": Date,
  "statistics": [
    {
      "_id": number,
      "leads": number,
      "completed": number,
      "approvals": number,
      "accepted": number,
      "paid": number,
      "affiliateCommission": number,
      "period": Date,
      "affiliate": string,
      "utm_campaign": string,
      "utm_content": string,
      "key": number
    }
  ],
  "metadata": {}
}



export type affiliateResponseSummariesHistory = {

  "created": Date,
  "lastUpdatedData": Date,
  "statisticsType": string,
  "consolidationPeriod": string,
  "queryStartDate": Date,
  "queryEndDate": Date,
  "statistics": [
    {
      "_id": number,
      "filtered": number,
      "invalid": number,
      "rejected": number,
      "started": number,
      "period": Date,
      "affiliate": string,
      "transaction": string,
      "key": number
    },
  ],
  "metadata": {}

}


export type AffiliateHistoryResponse = {
  "Id": string,
  "AffiliateName": string,
  "AffiliateId": string,
  "Transaction": string,
  "Created": Date,
  "ApplicationId": string,
  "PersonalNumber": string,
  "Email": string,
  "TelephoneNumber ": string,
  "Name": string,
  "Invalid": boolean,
  "Rejected": boolean,
  "Filtered": boolean,
  "Started": boolean,
  "RejectReason": string,
  "Payload": object,
  "RejectLogs": object,
  "RejectMessages[]": string,
}


export const getAffiliateOverview = async (affiliate: string, period: string): Promise<affiliateOverviewResponse> => {
  const affiliateClear = affiliate.toLowerCase().replaceAll(" ", "")
  const serviceURL = '/lending/applications/affiliate/' + affiliateClear + '/' + period
  return await misApi.Get(serviceURL);
}

export const getAffiliatesCampaignOverview = async (affiliate: string, period: string): Promise<affiliateCampaignOverviewResponse> => {
  const serviceURL = '/lending/applications/affiliate/' + affiliate + "/campaign/" + period
  return await misApi.Get(serviceURL);
}


export const getReportAffiliateAnalyticsHistory = async (affiliateId: string, periodStartDate: string, periodEndDate: string): Promise<AffiliateHistoryResponse> => {
  let serviceURL = '/affiliates/history/all/' + affiliateId + "?"
  let filter = { "startDate": periodStartDate, "endDate": periodEndDate };
  serviceURL += api._buildQueryString(filter)
  return await api.Get(serviceURL);
}

export const getReportAffiliateSummariesHistory = async (affiliate: string, period: string): Promise<affiliateResponseSummariesHistory> => {
  const serviceURL = '/lending/applications/affiliate/' + affiliate +"/history/" + period
  return await misApi.Get(serviceURL)
}