import React, { useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import ChannelsDetail from "../ui/blocks/admin-messages/channel-detail";
import MessageRuleChannelFiltersTable from "./message-channel-filters";
import { MessageRuleFilters } from "./message-channel-filters";
import { FzTable } from "../ui/fz/grid/table";
import { tableRoleMessaging } from '../biz/metadatas/message-metadata'
import { FzCard, FzAccordion } from "../ui/fz/layout/index";
import { FzIcons } from "../ui/fz/form/icon";
import { getContextVariable } from "../api/client-messages";


const MessageRuleChannelsTable = ({
  ns,
  message,
  readOnly,
  persistChanges,
  variablesList,
}) => {

  const expandComponent = (ns, message, channel, readOnly, persistChanges, variablesList) => {
    let code = channel["index"];

    if (channel.filters) {
      return (
        <FzCard>
          <ChannelsDetail
            ns={ns}
            message={message}
            code={code}
            readOnly={readOnly}
            persistChanges={persistChanges}
            variablesList={variablesList}
          />
          <MessageRuleChannelFiltersTable
            ns={ns}
            message={message}
            code={code}
            channel={channel.filters}
            readOnly={readOnly}
            persistChanges={variablesList}
            />
        </FzCard>
      );
    } else {
      return (
        <FzCard>
          <ChannelsDetail
            ns={ns}
            message={message}
            code={code}
            readOnly={readOnly}
            persistChanges={persistChanges}
            variablesList={variablesList}
          />
        </FzCard>
      );
    }
  };

  var data = [];
  var channels = message.channels || {};
  for (var branchCode in channels || {}) {
    let x = channels[branchCode];
    x["index"] = branchCode;

    data.push(x);
  }

  return (
    <FzTable
      data={data}
      metas={tableRoleMessaging}
      exportDownload={false}
      clipboard={false}
      rowExpand={{
        expandComponent: (row) => expandComponent(ns, message, row, readOnly, persistChanges, variablesList)
      }}
      visibleColumns={["index", "type", "description", "templateid", "template", "senderemail"]}
    />
  );
};

class MessageRuleChannelsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.changeOpenState = this.changeOpenState.bind(this);
  }
  changeOpenState() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let { ns, message, code, readOnly, persistChanges, variablesList } = this.props;
    if (!message.channels[code]) return null;

    let addChannelsButton = (
      <FzCard>
        <ButtonGroup>
          <Button
            title="Remover Regra"
            onClick={() => delRules(ns, message, code)}
          >
            < FzIcons icon={"minus"} fzStyle={{ width: "15px", height: "15px" }} /> Remover Regra{" "}
          </Button>
        </ButtonGroup>
      </FzCard>
    );
    if (readOnly) {
      addChannelsButton = null;
    }

    let filterEdit = null;
    if (message.channels[code].filters)
      filterEdit = (
        <MessageRuleFilters
          ns={ns}
          code={code}
          channel={message.channels[code]}
          message={message}
          readOnly={readOnly}
          persistChanges={persistChanges}
          key={code}
          props={this.props}
        />
      );

    let addChannelFilterssButton = (
      <FzCard>
        <ButtonGroup>
          <Button
            title="Adicionar Filtros"
            onClick={e => {
              e.stopPropagation();
              addFilter(ns, message, code);
            }}
          >
            < FzIcons icon={"plus"} fzStyle={{ width: "15px", height: "15px" }} /> Adicionar Filtro
          </Button>
        </ButtonGroup>
      </FzCard>
    );
    if (readOnly) {
      addChannelFilterssButton = null;
    }

    return (
      <FzAccordion >
        <FzCard.Heading>
          <FzAccordion.Toggle FzElementType='h4' eventKey={code}>
            <FzCard.Title>
              {code !== "undefined" ? code : 0} -{" "}
              {message.channels[code].description} - {message.channels[code].type}
            </FzCard.Title>
          </FzAccordion.Toggle>
        </FzCard.Heading>
        <FzAccordion.Collapse eventKey={code}>
          <FzCard.Body>
            <ChannelsDetail
              ns={ns}
              message={message}
              code={code}
              readOnly={readOnly}
              persistChanges={persistChanges}
              variablesList={variablesList}
            />
            {addChannelsButton}
            {filterEdit}
            {addChannelFilterssButton}
          </FzCard.Body>
        </FzAccordion.Collapse>
      </FzAccordion>
    );
  }
}

function addRules(ns, message) {
  if (!message.channels) message.channels = [];

  let index = message.channels.length;
  message.channels[index] = {};

  ns.set("message", message);
}

function delRules(ns, message, index) {
  if (!message.channels) return;

  if (index >= message.channels.length) return;

  message.channels.splice(index, 1);

  ns.set("message", message);
}

function addFilter(ns, message, index) {
  if (!message.channels[index].filters) message.channels[index].filters = [];

  let index2 = message.channels[index].filters.length;
  message.channels[index].filters[index2] = {};

  ns.set("message", message);
}

const MessageRuleChannels = ({
  ns,
  api,
  message,
  readOnly,
  persistChanges,
  ...props
}) => {
  const [variablesList, setVariablesList] = React.useState([]);

  useEffect(() => {
    getContextVariable().then((resp) => {
      const variablesResp = resp?.contextVariables?.map((it) => ({
        code: it,
        description: it,
      }));

      setVariablesList(variablesResp);
    });
  }, []);

  if (!readOnly && persistChanges === undefined) {
    console.error("Callback not supplied: making component readOnly");
    readOnly = true;
  }
  let addChannelsButton = (
    <FzCard>
      <ButtonGroup>
        <Button title="Adicionar Regra" onClick={() => addRules(ns, message)}>
          < FzIcons icon={"plus"} fzStyle={{ width: "15px", height: "15px" }} /> Adicionar Regra
        </Button>
      </ButtonGroup>
    </FzCard>
  );
  if (readOnly) {
    addChannelsButton = null;
  }

  let Channels;
  if (!message.channels) {
    Channels = "";
  }
  if (readOnly) {
    Channels = (
      <MessageRuleChannelsTable
        ns={ns}
        message={message}
        readOnly={readOnly}
        persistChanges={persistChanges}
        variablesList={variablesList}
      />
    );
  } else {
    Channels = [];
    for (let code in message.channels) {
      Channels.push(
        <MessageRuleChannelsEdit
          ns={ns}
          code={code}
          message={message}
          readOnly={readOnly}
          persistChanges={persistChanges}
          key={code}
          props={props}
          variablesList={variablesList}
        />
      );
    }
  }
  return (
    <FzCard>
      <FzCard.Heading>
        <FzCard.Title componentClass="h3">
          Canais de Comunicação (Regras)
        </FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        {addChannelsButton}
        {Channels}
      </FzCard.Body>
    </FzCard>
  );
};

export default MessageRuleChannels;
