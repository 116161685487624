import api from '../../api/client-api';
import { getAllSuppliers } from '../../api/suppliers';
import { getDomains } from '../../api/domains';
import { states, manufacturingYear } from '../../biz/metadatas/application';
import cloneDeep from "lodash/cloneDeep";
import { getUtmSource } from '../../api/campaigns';
import { objectMultiDepthAccess } from '../../utils/objectMultiDepthAccess';
import { getAllSreeningRules } from '../../services/api/screening';

// {getSuppliers, getSupplierFromId, getSupplierFromName}
const getSuppliers = (ns) => {

  const cache = ns.getShared('CACHE', {});
  if (cache.suppliers !== undefined) {
    return cache.suppliers;
  }

  let supplier = api.getCurrentSupplier();

  if (api.isUserInRole('supplier') && supplier) {
    cache.suppliers = {};
    cache.suppliers[supplier.internalName] = { ...supplier };
    ns.setShared('CACHE', cache);
  } else {
    getAllSuppliers()
      .then(suppliers => {
        let tmp = {};
        for (let i = 0; i < suppliers.length; i++) {
          tmp[suppliers[i].internalName] = suppliers[i];
        }
        cache.suppliers = tmp;
        ns.setShared('CACHE', cache);
      });
  }

  return cache.suppliers;
};
const getSuppliersOptions = (ns) => {
  const suppliers = getSuppliers(ns)
  if (!suppliers) {
    return Promise.resolve([])
  }
  return Promise.resolve(Object.keys(suppliers).map((key) => ({code: key, description: suppliers[key].internalName})))
}
const getSuppliersList = async (ns) => {
  const cache = ns.getShared('CACHE', {});
  if (cache.suppliersOptions !== undefined) {
    return cache.suppliersOptions;
  }

  let supplier = api.getCurrentSupplier();
  if (api.isUserInRole('supplier') && supplier) {
    cache.suppliersOptions = [];
    cache.suppliersOptions.push({ code: supplier.internalName, description: supplier.name, auxiliaryForOrder: supplier.name.toLowerCase() });
    ns.setShared('CACHE', cache);
  } else {
    const suppliers = await getAllSuppliers()
    let supList = [];
    for (let i = 0; i < suppliers.length; i++) {
      supList.push({ code: suppliers[i].internalName, description: suppliers[i].name, auxiliaryForOrder: suppliers[i].name.toLowerCase() });
    }
    let sortedList = supList.sort(function (a, b) {
      if (a.auxiliaryForOrder > b.auxiliaryForOrder) {
        return 1;
      }
      if (a.auxiliaryForOrder < b.auxiliaryForOrder) {
        return -1;
      }
      return 0;
    })
    cache.suppliersOptions = sortedList;
    ns.setShared('CACHE', cache);
  }
  return cache.suppliersOptions;
}

let busy = false;
let domains;

const getDomain = (ns, domainName, value) => {
  if (!ns) {
    return null;
  }

  domains = ns.getShared('CACHE.domains', {});
  if (Object.keys(domains).length === 0 && !busy) {
    busy = true;
    getDomains().then(d => {
      return getSuppliersOptions(ns)
        .then((supplierOptions) => {
          return getUtmSource()
            .then((response) => {
              let utmSource = [];
              response.value.map(function (v, k) {
                utmSource.push({
                  code: v,
                  originalCode: v,
                  description: v,
                  type: "string",
                });
              });
              d['utm_source'] = utmSource;
              d['states'] = states;
              d['manufacturing-year'] = manufacturingYear;
              d['model-year'] = getModelYearDefault();
              d['document-subtypes'] = d["rg-document-type"], d["income-proof-document"]
              const mYear = ns.getChanged('vehicleSecurity.manufacturingYear', undefined);
              if (ns.isEditing() && mYear) {
                d['model-year'] = manufacturingYear.filter(e => e.code.toString() === mYear.toString() || e.code.toString() === (mYear + 1).toString());
              }
              d['suppliers'] = supplierOptions;
              ns.setShared('CACHE.domains', d);
              domains = d;
            })
          })
        // .then(() => getSuppliersList(ns))
    }).finally(() => busy = false);
  }

  if (!domainName) {
    return domains;
  }

  let domain;
  
  if(domainName.includes(".")) { 
    domain = objectMultiDepthAccess(domains, domainName);
  } else {
    domain = domains[domainName] || [];
  }

  if (!value) {
    return domain;
  }
  for (let i = 0; i < domain.length; ++i) {
    if (domain[i].code === value.toString()) {
      return domain[i].description;
    }
  }
  return null;
};

const getScreeningRulesTranslation = async (ns) => {
  const cachedRules = ns.getShared("CACHE.rules-translation", null)
  if (!cachedRules) {
    const rules = await getAllSreeningRules()
    const rulesTranslation = {}
    for (let rule in rules.data) {
      rulesTranslation[rules.data[rule].key] = rules.data[rule].description
    }
    ns.setShared("CACHE.rules-translation", rulesTranslation)
  }
}

export { getSuppliers, getDomain, getSuppliersList, getScreeningRulesTranslation };

function getModelYearDefault() {
  const date = new Date();
  const nextYear = date.getFullYear() + 1;
  const modelYeardefault = cloneDeep(manufacturingYear);
  modelYeardefault.push({ code: nextYear, description: nextYear.toString() });
  return modelYeardefault;
}
