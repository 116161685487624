import { Meta } from "../../types/metas"
import moment from 'moment'

export const paymentImport: Meta = {
  product: {
    path: "product",
    label: "Produto",
    inputType: "select",
    inputProps: {
      options: [
        { code: "CP", description: "CP" },
        { code: "HE", description: "HE" },
        { code: "Refin", description: "REFIN" },
      ]
    },
    isMandatory: () => true,
  },
  supplierInternalName: {
    path: "supplierInternalName",
    label: "Supplier",
    inputType: "select",
    inputProps: {
      options: [],
    },
    isMandatory: () => true,
  },
  paymentDate: {
    path: "paymentDate",
    label: "Data de Pagamento",
    isMandatory: () => true,
    inputType: "date"
  },
  createdFrom: {
    path: "createdFrom",
    label: "Data inicio da Busca",
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    isMandatory: () => true,
    inputType: "date"
  },
}

export const paymentBatches: Meta = {
  "id": {
    "path": "id",
    "label": "ID  ",
    "section": undefined,
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": true,
    },
    "isMandatory": (application) => false,
  },
  "created": {
    "path": "created",
    "label": "Created  ",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "width": "9ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "status": {
    "path": "status",
    "label": "Status  ",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "supplierInternalName",
    "tableProps": {
      "viewsTable": "",
      "width": "6ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "product": {
    "path": "product",
    "label": "product",
    "tableProps": {
      "viewsTable": "",
      "width": "4ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "paymentDate": {
    "path": "paymentDate",
    "label": "paymentDate",
    "tableProps": {
      "viewsTable": "",
      "width": "9ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "createdFrom": {
    "path": "createdFrom",
    "label": "createdFrom",
    "tableProps": {
      "viewsTable": "",
      "width": "9ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "qtdOk": {
    "path": "qtdOk",
    "label": "qtdOk",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "qtdErrors": {
    "path": "qtdErrors",
    "label": "qtdErrors",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
}

export const paymentBatchesItens: Meta = {
  "id": {
    "path": "id",
    "label": "ID  ",
    "section": undefined,
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": true,
      "hidden": true
    },
    "isMandatory": (application) => false,
  },
  "created": {
    "path": "created",
    "label": "Created  ",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "status": {
    "path": "status",
    "label": "Status  ",
    "tableProps": {
      "viewsTable": "",
      "width": "4ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "reason": {
    "path": "reason",
    "label": "Reason  ",
    "tableProps": {
      "viewsTable": "",
      "width": "20ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "payoutImportInfoId": {
    "path": "paymentinfoid",
    "label": "Payment Info Id  ",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "applicationId": {
    "path": "applicationId",
    "label": "applicationId",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "installments": {
    "path": "installments",
    "label": "Installments",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "paymentDate": {
    "path": "paymentDate",
    "label": "Data de pagamento",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "value": {
    "path": "value",
    "label": "Value",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "commission": {
    "path": "commission",
    "label": "Commission",
    "tableProps": {
      "viewsTable": "",
      "width": "4ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "product": {
    "path": "product",
    "label": "product",
    "tableProps": {
      "viewsTable": "",
      "width": "4ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "supplierApplicationId": {
    "path": "supplierApplicationId",
    "label": "supplierApplicationId",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "discoveredApplication": {
    "path": "discoveredApplication",
    "label": "Discovered Application",
    "tableProps": {
      "viewsTable": "",
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
}

export const reportAPaid: Meta = {
  "payedAppReasonStatus": {
    "path": "payedAppReasonStatus",
    "label": "Parceiro",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": undefined,
    },
    "validationMessage": undefined,
    "placeholder": undefined,
    "isMandatory": (application) => true,
    "isReadOnly": () => false
  },
  "paidDate": {
    "path": "paidDate",
    "label": "Data do pagamento",
    "dataType": "date",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().add(+1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "validationMessage": undefined,
    "placeholder": undefined,
    "isMandatory": (application) => true,
    "isReadOnly": () => false
  },
  "paidAmount": {
    "path": "paidAmount",
    "label": "Valor Pago",
    "dataType": "number",
    "inputType": "number",
    "placeholder": undefined,
    "isMandatory": (application) => true,
    "isReadOnly": () => false
  },
}


