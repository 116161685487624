import React, {Component} from 'react';

const Left = props => <div className="splitline-left">{props.children}</div>;
const Right = props => <div className="splitline-right">{props.children}</div>;

class SplitLine extends Component {

  static Left = Left;
  static Right = Right;

  static defaultProps = {
    className: "splitline",
  };

  render() {
    return (
      <div className={this.props.className}>{this.props.children}</div>
    );
  }
}

export default SplitLine;