import { Meta, MetaWithFunctions, MetaField } from '../../types/metas'

export let panelControlCampaigns: MetaWithFunctions = {
  "typeView": {
    "path": "typeView",
    "label": "Tipo de visualização",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "analytics", description: "Analytics" },
        { "code": "summaries", description: "Summaries" }
      ],
    },
    "isMandatory": (application) => true,
  },
  "filterTypeAnalytics": {
    "path": "filterTypeAnalytics",
    "label": "Tipo de busca",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "event", description: "Evento" },
        { "code": "campaignName", description: "Filtro" }
      ],
    },
    "isMandatory": (application) => true,
  },
  "filterTypeSummary": {
    "path": "filterTypeSummary",
    "label": "Tipo de busca",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { code: "event", description: "Evento" },
        { code: "campaignName", description: "Filtro" }
      ],
    },
    "isMandatory": (application) => true,
  },
  "filterTypeSelected": (filterType: string, filterTypeValues: { 'code': string, 'description': string }[]): MetaField => {
    let labelName
    switch (filterType) {
      case 'event':
        labelName = 'Selecione um Evento'
        break

      case 'campaignName':
        labelName = 'Selecione uma Campanha'
        break

      default:
        labelName = ''
    }

    return {
      "path": "filterTypeSelected",
      "label": labelName,
      "dataType": "string",
      "inputType": "nav-dropdown",
      "inputProps": {
        "options": filterTypeValues || [],
      },
      "isMandatory": (application) => true,
    }
  },
  "agregado": {
    "path": "agregado",
    "label": "Agregado por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "day", "description": "Daily" },
        { "code": "week", "description": "Weekly" },
        { "code": "month", "description": "Month" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "isManual": {
    "path": "isManual",
    "label": "Tipo de Envio",
    "dataType": "string",
    "inputType": "nav-dropdown",
    "inputProps": {
      "options": [
        { "code": 'false', "description": "Automático" },
        { "code": 'true', "description": "Manual" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "type": {
    "path": "type",
    "label": "Tipo",
    "dataType": "string",
    "inputType": "nav-dropdown",
    "inputProps": {
      "options": [
        { "code": "online", "description": "Online" },
        { "code": "offline", "description": "Offline" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "startDate": {
    "path": "startDate",
    "label": "Data Inicial",
    "dataType": 'moment',
    "inputType": "dateV2",
    "inputProps": {
      // "minValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      // "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => true,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T00") : value,
  },
  "endDate": {
    "path": "endDate",
    "label": "Data Final",
    "dataType": 'moment',
    "inputType": "dateV2",
    "inputProps": {
      // "minValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      // "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T00") : value,
    "isMandatory": (application) => true,
  },
}

export const campaignApplicant = {
  "campaign": {
    "path": "campaign",
    "dataType": "id",
    "label": "Campaign id",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "15ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "total": {
    "path": "total",
    "dataType": "number",
    "label": "Total",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
    },
    "tip": "Total approved applicants"
  },
  "active": {
    "path": "active",
    "label": "Active",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "activeColumn",
    },
    "tip": "Non converted applicants"
  },
  "converted": {
    "path": "converted",
    "label": "Converted",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "convertedColumn",
    },
    "tip": "Applicants who accepts the offer"
  },
  "canceled": {
    "path": "canceled",
    "label": "Inactive/Canceled",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "canceledColumn",
    },
    "tip": "Applicants inactive or who recjected the offer"
  },
  "enddate": {
    "path": "enddate",
    "label": "Campaign due date",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "periodColumnTz",
    },

  },
  "buildCampaign": {
    "path": "buildCampaign",
    "label": "Actions",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "actionCampaignColumns",
    },

  },
  "id": {
    "path": "id",
    "dataType": "id",
    "label": "id",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },

  },
  "originalApplicationId": {
    "path": "originalApplicationId",
    "dataType": "id",
    "label": "Original",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": false,
    },

  },
  "firstName": {
    "path": "firstName",
    "label": "Primeiro nome",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "First name"
  },
  "lastName": {
    "path": "lastName",
    "label": "Sobrenomes",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },

  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "label",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },

  },
  "email": {
    "path": "email",
    "dataType": "email",
    "label": "e-mail",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },

  },
  "telephoneNumber": {
    "path": "telephoneNumber",
    "dataType": "number",
    "label": "Celular",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },

  },
  "url": {
    "path": "url",
    "label": "URL",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },

  },
  "newApplicationId": {
    "path": "newApplicationId",
    "dataType": "id",
    "label": "Pedido criado",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },

  },
  "approvedAmount": {
    "path": "approvedAmount",
    "dataType": "number",
    "label": "Valor aprovado",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
    },

  },
  "approvedInstallment": {
    "path": "approvedInstallment",
    "dataType": "number",
    "label": "Prestação",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
    },

  },
  "approvedPeriodMonths": {
    "path": "approvedPeriodMonths",
    "label": "Nº prestações",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
    },

  },
  "interestRate": {
    "path": "interestRate",
    "label": "Taxa de Juros",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
    },

  },
  "convertedDate": {
    "path": "convertedDate",
    "dataType": "date",
    "label": "Data",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumnTz",
    },

  },
}


export const campaignAnalytics: Meta = {
  "id": {
    "path": "id",
    "dataType": "id",
    "label": "id",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      // "isKey": true,
      rowSpan: "2"
    },
  },
  "buildStatus": {
    "path": "buildStatus",
    "label": "Build",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "20ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "center",
      rowSpan: "2"
    },
  },
  "campaign": {
    "path": "campaign",
    "label": "Campanha",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "50ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
      rowSpan: "2"
    },
  },
  "subject": {
    "path": "notificationSubject",
    "label": "Assunto Personalizado",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "33ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "center",
      rowSpan: "2"
    },
  },
  "created": {
    "path": "created",
    "label": "Criada em",
    "tableProps": {
      "width": "18ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateTimeColumn",
      "isKey": false,
      rowSpan: "2"
    },
  },
  "buildEnd": {
    "path": "buildEnd",
    "label": "Processada em",
    "tableProps": {
      "width": "18ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateTimeColumn",
      "isKey": false,
      rowSpan: "2"
    },
  },
  "scheduleDate": {
    "path": "scheduleDate",
    "label": "Agendada para",
    "tableProps": {
      "width": "18ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateTimeColumn",
      "isKey": false,
      rowSpan: "2"
    }
  },
  "scheduleSentDate": {
    "path": "scheduleSentDate",
    "label": "Enviada em",
    "tableProps": {
      "width": "18ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateTimeColumn",
      "isKey": false,
      rowSpan: "2"
    }
  },
  "dateFrom": {
    "path": "dateFrom",
    "dataType": "date",
    "label": "1a Application",
    "tableProps": {
      "width": "13ch",
      "dataSort": false,
      "dataAlign": "center",
      rowSpan: "2"
    }
  },
  "dateTo": {
    "path": "dateTo",
    "dataType": "date",
    "label": "Ult. Application",
    "tableProps": {
      "width": "13ch",
      "dataAlign": "center",
      "exportFormat": "dateSummaryColumn",
      rowSpan: "2"
    }
  },
  "isManual": {
    "path": "isManual",
    "dataType": "bool",
    "label": "Manual",
    "tableProps": {
      "width": "10ch",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn",
      rowSpan: "2"
    }
  },
  "type": {
    "path": "type",
    "dataType": "bool",
    "label": "Tipo",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "center",
      rowSpan: "2"
    }
  },
  "notificationEvent": {
    "path": "notificationEvent",
    "dataType": "string",
    "label": "Evento Mercúrio",
    "tableProps": {
      "viewsTable": "",
      "width": "30ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "center",
      rowSpan: "2"
    }
  },
  "total": {
    "path": "total",
    "dataType": "number",
    "label": "Total",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
      rowSpan: "2"
    },
    "tip": "Total approved applicants"
  },
  "active": {
    "path": "active",
    "dataType": "number",
    "label": "Ativos",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      rowSpan: "2"
    },
    "tip": "Non converted applicants"
  },
  "converted": {
    "path": "converted",
    "label": "Convertidos",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "convertedColumn",
      rowSpan: "2"
    },
    "tip": "Applicants who accepts the offer"
  },
  "canceled": {
    "path": "canceled",
    "label": "Cancelados",
    "tableProps": {
      "hidden": true,
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "canceledColumn",
      rowSpan: "2"
    },
    "tip": "Applicants inactive or who recjected the offer"
  },
  "notified": {
    "path": "notified",
    "dataType": "bool",
    "label": "Notificados",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
      rowSpan: "2"
    },
    "tip": "Applicants who was notified via mercurio"
  },
  "bounced": {
    "path": "bounced",
    "dataType": "bool",
    "label": "Bounced",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Bounced",
            dataAlign: "center",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "Qtty",
            dataAlign: "center",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "center",
          }]
      },
      "formatExtraData": "total",
    },
    "tip": "Applicants that delivery channel notify as bounced"
  },
  "unsubscribed": {
    "path": "unsubscribed",
    "dataType": "bool",
    "label": "Unsubscribed",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
      rowSpan: "2"
    },
    "tip": "Applicants that is on unsubscribe list"
  },
  "read": {
    "path": "read",
    "dataType": "number",
    "label": "Read",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Read",
            dataAlign: "center",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "Qtty",
            dataAlign: "center",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "center",
          }]
      },
      "formatExtraData": "total",
    },
    "tip": "Applicants that read email"
  },
  "clickedTotal": {
    "path": "clicked",
    "dataType": "number",
    "label": "Clicked / Notificados",
    "tableProps": {
      "viewsTable": "",
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Clicked / Notificados",
            dataAlign: "center",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "Qtty",
            dataAlign: "center",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "width": "10ch",
            "columnFormat": "pctColumn",
            dataAlign: "center",
          }],
        },
        "formatExtraData": "notified",
    },
    "tip": "Applicants that clicked in email's link"
  },
  "clickedRead": {
    "path": "clicked",
    "dataType": "number",
    "label": "Clicked / Read",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Clicked / Read",
            dataAlign: "center",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "Qtty",
            dataAlign: "center",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "width": "10ch",
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "center",
          }]
      },
      "formatExtraData": "read",
    },
    "tip": "Applicants that clicked in email's link"
  },
  "endDate": {
    "path": "endDate",
    "dataType": "date",
    "label": "Validade",
    "tableProps": {
      rowSpan: "2",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateColumn",
    }
  },
  "isApplicant": {
    "path": "isApplicant",
    "dataType": "bool",
    "label": "Tem lista",
    "tableProps": {
      "width": "10ch",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      rowSpan: "2",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn",
    },
  },
  "gererateToken": {
    "path": "gererateToken",
    "dataType": "bool",
    "label": "Token",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn",
    }
  },
  "generatePreAuth": {
    "path": "generatePreAuth",
    "dataType": "bool",
    "label": "Pre Auth",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn",
    }
  }
}

export const campaignSummary = (filterType): Meta => {

  let filterTypeField

  switch (filterType) {
    case 'event':
      filterTypeField = {
        "event": {
          "path": "_id.event",
          "label": "Evento",
          "tableProps": {
            "viewsTable": "",
            "hidden": false,
            "width": "33ch",
            "dataAlign": "center",
            "filter": { type: 'TextFilter', delay: 500 },
          },
        },
      }
      break

    case 'campaignName':
      filterTypeField = {
        "campaign": {
          "path": "_id.campaign",
          "label": "Campanha",
          "tableProps": {
            "viewsTable": "",
            "hidden": false,
            "width": "33ch",
            "dataAlign": "center",
            "filter": { type: 'TextFilter', delay: 500 },
          },
        },
      }
      break

    default:
      filterTypeField = {}
  }

  return {
    "id": {
      "path": "_id.aggregation",
      "dataType": "id",
      "label": "id",
      "tableProps": {
        "viewsTable": "",
        "hidden": true,
        "width": "12ch",
        "dataSort": true,
        "dataAlign": "center",
        "isKey": true,
      },
    },
    "aggregation": {
      "path": "_id.aggregation",
      "label": "Período",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "15ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    ...filterTypeField,
    "type": {
      "path": "_id.type",
      "label": "Tipo",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "15ch",
        "dataAlign": "center",
      },
    },
    "autoOrManual": {
      "path": "_id.autoOrManual",
      "label": "Tipo Envio",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "15ch",
        "dataAlign": "center",
      },
    },
    "active": {
      "path": "active",
      "label": "Ativos",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "converted": {
      "path": "converted",
      "label": "Convertidos",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "canceled": {
      "path": "canceled",
      "label": "Cancelados",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "notified": {
      "path": "notified",
      "label": "Notificados",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "bounced": {
      "path": "bounced",
      "label": "Retornados",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "unsubscribed": {
      "path": "unsubscribed",
      "label": "Desinscritos",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "read": {
      "path": "read",
      "label": "Lidos",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    "clicked": {
      "path": "clicked",
      "label": "Clicados",
      "tableProps": {
        "viewsTable": "",
        "hidden": false,
        "width": "10ch",
        "dataSort": true,
        "dataAlign": "center",
      },
    },
    // "total": {
    //   "path": "count",
    //   "label": "Total",
    //   "tableProps": {
    //     "viewsTable": "",
    //     "hidden": false,
    //     "width": "10ch",
    //     "dataSort": true,
    //     "dataAlign": "center",
    //   },
    // },
  }
}