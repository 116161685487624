
import React from 'react';
import { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap'
import usePortal from '../../../hooks/usePortal';
import { FzButton } from '../form';

type FzPortalProps = {
  children: ReactNode;
}
const Portal = ({ children }: FzPortalProps) => {
  const modalTarget = usePortal("modal-portal");
  return createPortal(children, modalTarget);

}


type FzModalProps = {
  cancelText?: string;
  children?: ReactNode;
  containerStyle?: Record<string, string | number | boolean>;
  customFooter?: ReactNode;
  handleCancelButton?: () => unknown;
  handleSuccessButton?: () => unknown;
  successButtonDisabled?: boolean;
  handleButtonClick?: () => unknown;
  headerTitle?: string;
  modalSize?: "lg" | "sm";
  showButtonText: string;
  showButtonType?: "text" | "button";
  showButtonFzStyle?: string;
  fzStyle?: string;
  successText?: string;
  buttonCustomStyle?: Record<string, string | number | boolean>;
  buttonDisabled?: boolean;
}

export function FzModal(props: FzModalProps) {
  const [show, setShow] = useState<boolean>(false)

  const handleCancel = () => {
    if (props.handleCancelButton) props.handleCancelButton();
    setShow(false);
  }

  const handleSuccess = () => {
    if (props.handleSuccessButton) props.handleSuccessButton();
    setShow(false);
  }

  const handleClick = () => {
    if (props.handleButtonClick) {
      props.handleButtonClick()
    }
    setShow(true)
  }

  return (
    <>
      {
        props.showButtonType === "text"
          ? (
            <div style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleClick()}>
              {props.showButtonText}
            </div>
          )
          : (
            <FzButton
              fzStyle={props.showButtonFzStyle}
              customStyle={{
                ...props.buttonCustomStyle
              }}
              onClick={handleClick}
              disabled={props.buttonDisabled}
            >
              {props.showButtonText}
            </FzButton>
          )
      }
      <Portal>
        <Modal
          style={props.containerStyle}
          size={props.modalSize || "lg"}
          show={show}
          onHide={() => setShow(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{props.headerTitle}</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ wordBreak: "break-word" }}>
            {props.children}
          </Modal.Body>

          <Modal.Footer>
            {
              props.customFooter
                ? props.customFooter
                : (
                  <>
                    {(props.handleCancelButton || props.cancelText) && (
                      <FzButton
                        fzStyle="discrete"
                        onClick={handleCancel}
                      >
                        {props.cancelText || 'Cancel'}
                      </FzButton>
                    )}

                    {props.handleSuccessButton && (
                      <FzButton
                        fzStyle={props.fzStyle || "regular"}
                        onClick={handleSuccess}
                        disabled={props.successButtonDisabled || false}
                      >
                        {props.successText || 'Ok'}
                      </FzButton>
                    )}
                  </>
                )
            }
          </Modal.Footer>
        </Modal>
      </Portal>
    </>
  )
}
