import { Meta } from "../../../types/metas";

export const hermesUserMeta: Meta = {
  queueSelect: {
    path: "queueSelect",
    label: "Nome da fila",
    dataType: "string",
    inputType: "select",
    inputProps: {
      "options": [],
    },
    isMandatory: (app)=> false,
  }
}