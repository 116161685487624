import { Meta } from "../../types/metas";
import moment from 'moment'

export const panelControl: Meta = {

  "source": {
    "path": "source",
    "label": "Origem",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "application", "description": "Application" },
        { "code": "formalization", "description": "Formalization" }
      ],
    },
    "isMandatory": (application) => false,
  },
  "view": {
    "path": "view",
    "label": "Visualizar por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "analytics", "description": "Analytics" },
        { "code": "summaries", "description": "Summaries" },
      ],
    },
    "isMandatory": (application) => false,
  },
  "channel": {
    "path": "channel",
    "label": "Canal",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "api", "description": "APIs" },
        { "code": "cof", "description": "Manual" },
      ],
    },
    "isMandatory": (application) => false,
  },
  "startDate": {
    "path": "startDate",
    "label": "Desde (0h)",
    "dataType": "moment",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T03") : value,
  },
  "endDate": {
    "path": "endDate",
    "label": "Até",
    "dataType": "moment",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().add(+2, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T03") : value,
  },
  "agregado": {
    "path": "agregado",
    "label": "Agregado por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "day", "description": "Daily" },
        { "code": "week", "description": "Weekly" },
        { "code": "month", "description": "Month" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "suppliers": {
    "path": "suppliers",
    "label": "Parceiro",
    "dataType": "string",
    "inputType": "nav-dropdown",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => true,
  },
}
