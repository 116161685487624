import { Meta } from '../../types/metas';

export const users: Meta = {
  id: {
    path: 'id',
    label: 'Id',
    section: 'users',
    dataType: 'number',
    inputType: 'text',
    tableProps: {
      shortLabel: '#',
      width: '7em',
      dataAlign: 'center',
      isKey: true,
    },
    isMandatory: (value) => false,
    isReadOnly: () => true,
  },
  created: {
    path: 'created',
    label: 'Criado',
    section: 'users',
    dataType: 'date',
    inputType: 'date',
    tableProps: {
      width: '15ch',
      isKey: false,
    },
    isMandatory: (value) => false,
    isReadOnly: () => true,
  },
  name: {
    path: 'name',
    label: 'Nome',
    section: 'users',
    dataType: 'string',
    inputType: 'text',
    inputProps: {
      options: undefined,
    },
    tableProps: {
      width: '15em',
      filter: { type: 'TextFilter', delay: 1000 },
      dataSort: true,
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  email: {
    path: 'email',
    label: 'Email',
    section: 'users',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      width: '25em',
      filter: { type: 'TextFilter', delay: 1000 },
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  telephoneNumber: {
    path: 'telephoneNumber',
    label: 'Telefone',
    section: 'personal-data',
    dataType: 'string',
    inputType: 'text',
    inputProps: {
      autoComplete: 'tel',
      removeMask: false,
    },
    tableProps: {
      hidden: false,
      width: '14ch',
    },
    validationMessage: 'Informe um número com DDD: (xx) xxxxx-xxxx',
    isMandatory: (value) => false,
    normalize: (value) => {
      return value !== undefined ? value.toLowerCase() : value;
    },
  },
  group: {
    path: 'group',
    label: 'Grupo',
    section: 'users',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      width: '8em',
      filter: { type: 'TextFilter', delay: 1000 },
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  queues: {
    path: 'queues',
    label: 'Fila',
    section: 'users',
    dataType: 'string',
    inputType: 'multiselect',
    inputProps: {
      options: [
        { code: 'fup', description: 'FUP' },
        { code: 'fupApp', description: 'FUP Multi-produtos' },
        { code: 'deal', description: 'Deal' },
      ],
    },
    tableProps: {
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  priorityQueue: {
    path: 'queues',
    label: 'Prioridade da fila (Da esquerda para direita)',
    section: 'users',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      isKey: false,
    },
    isMandatory: (value) => false,
    isReadOnly: () => true,
  },
  loginWithPassword: {
    path: 'loginWithPassword',
    label: 'Login com senha',
    section: 'no-section',
    dataType: 'bool',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'true', description: 'Sim' },
        { code: 'false', description: 'Não' },
      ],
    },
    tableProps: {
      width: '6em',
      dataSort: true,
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  active: {
    path: 'active',
    label: 'Ativo',
    section: 'users',
    dataType: 'bool',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'true', description: 'Sim' },
        { code: 'false', description: 'Não' },
      ],
    },
    tableProps: {
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  personalNumber: {
    path: 'personalNumber',
    label: 'CPF',
    section: 'users',
    dataType: 'string',
    inputType: 'masked',
    inputProps: {
      mask: '111.111.111-11',
      removeMask: true,
    },
    tableProps: {
      viewsTable: 'application',
      hidden: false,
      width: '20ch',
      filter: { type: 'TextFilter', delay: 500 },
    },
    validationMessage: 'Numero de CPF inválido',
    pattern: /^\d{11}$/,
    isMandatory: (value) => false,
    normalize: (value) => {
      if (
        /^((\\d{11})|(\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2})|(\\d{9}-\\d{2}))$/.test(
          value
        )
      ) {
        return value.replace(/\\D/g, '');
      } else {
        return value;
      }
    },
    extraValidation: 'personalNumber',
  },
  role: {
    path: 'role',
    label: 'Papel',
    section: 'no-section',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'admin', description: 'Administrador' },
        { code: 'sales', description: 'Assistente de Vendas' },
        { code: 'advisor', description: 'Consultor de vendas' },
        { code: 'customer', description: 'Cliente' },
        { code: 'affiliate', description: 'Afiliado' },
        { code: 'supplier', description: 'Parceiro (Banco)' },
        { code: 'franchisee', description: 'Franqueado (Franchisee)' },
        { code: 'screener', description: 'Avaliador da Triagem (Screener)' },
      ],
    },
    tableProps: {
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  apiKey: {
    path: 'apiKey',
    label: 'Api key',
    section: 'no-section',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      width: '6em',
      dataSort: true,
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  apiSecret: {
    path: 'apiSecret',
    label: 'Api secret',
    section: 'users',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  firstPassword: {
    path: 'firstPassword',
    label: 'Senha',
    section: 'user',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      isKey: false,
    },
    pattern: /\w{6,}/,
    isMandatory: (value) => false,
  },
  secondPassword: {
    path: 'secondPassword',
    label: 'Repetir senha',
    section: 'users',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      isKey: false,
    },
    pattern: /\w{6,}/,
    isMandatory: (value) => false,
  },
  products: {
    path: 'products',
    label: 'Produto',
    section: 'users',
    dataType: 'string',
    inputType: 'multiselect',
    tableProps: {
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      isKey: false,
    },
    isMandatory: (value) => false,
  },
  preferredPartners: {
    path: 'preferredPartners',
    label: 'Parceiro preferencial',
    section: 'users',
    dataType: 'string',
    inputType: 'multiselect',
    tableProps: {
      isKey: false,
    },
    inputProps: {},
    isMandatory: (value) => false,
  },
};
