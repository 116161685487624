import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InputLayoutListContainer from '../../../../Containers/InputLayout/list'
import { GetAllInputLayout } from '../../../../services/api/inputlayout'
import { InputLayoutResponse } from '../../../../services/api/inputlayout/types'

const InputLayoutList = ({ ns }) => {
  let history = useHistory();
  const [data, setData] = useState<InputLayoutResponse[]>([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData()
  }, [])
  

  const getData = async () => {
    try {
      const response = await GetAllInputLayout()
      setData(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleGoToEditInputLayout = (data: InputLayoutResponse): void => {
    history.push(`/input-layout/edit/${data.id}`);
  }

  return (
    <InputLayoutListContainer 
      ns={ns} 
      data={data}
      handleGoToEditInputLayout={handleGoToEditInputLayout}
      loading={loading}
      history={history}
    />
  )
}

export default InputLayoutList
