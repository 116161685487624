import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import '../../fz/style/menu.css';

export class FzMenuAdminMis extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      stats: false,
      route_funnel: false,
      route_teste: false,
      route_selected: false,
    };

    this.goToRoute = this.goToRoute.bind(this);
    this.showOptionsMenu = this.showOptionsMenu.bind(this);
  }

  goToRoute(route: string, name: string) {
    const { history } = this.props;
    this.setState({ route_selected: name }, () => history.push(`/${route}`));
  }

  showOptionsMenu(name: string) {
    if (this.state.show_options !== name) {
      this.setState({ show_options: name });
    } else if (this.state.show_options === name) {
      this.setState({ show_options: undefined });
    }
  }

  render() {
    var isBeta = /((beta)|(^home\.))/i.test(window.location.hostname);

    return (
      <div>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={() => this.showOptionsMenu('stats')}>
            <ListItemText className={'item-menu'} primary="Estatísticas" />
            {this.state.show_options === 'stats' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'stats'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={(e) => this.goToRoute('stats/funnel', 'route_funnel')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_funnel',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Funnel" />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('stats/mkt', 'route_mkt')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_mkt',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Applications"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('stats/formalization', 'route_formalization')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_formalization',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Formalizations"
                />
              </ListItem>

              {/* <ListItem
                button
                onClick={() => this.goToRoute('stats/sales', 'route_sales')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_sales',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="CS" />
              </ListItem> */}

              <ListItem
                button
                onClick={() => this.goToRoute('stats/revenue', 'route_revenue')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_revenue',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Revenue Model"
                />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('stats/form', 'route_form')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_form',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Form" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('stats/messaging', 'route_messaging')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_messaging',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Messaging" />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('affiliate', 'route_affiliate')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_affiliate',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Afiliados" />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('creditRisk', 'route_creditRisk')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_creditRisk',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Risco de Crédito"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('parameters/fzScore', 'route_fzScore')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_fzScore',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Fz Score" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('stats/screening', 'route_screening')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_screening',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Triagem" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('app')}>
            <ListItemText className={'item-menu'} primary="Propostas" />
            {this.state.show_options === 'app' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'app'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>

              <ListItem button onClick={() => this.goToRoute("applications", "route_applications")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "route_applications",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Buscar Propostas" />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('customer', 'route_customer')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_customer',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Buscar Clientes"
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('user')}>
            <ListItemText className={'item-menu'} primary="Usuários" />
            {this.state.show_options === 'user' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'user'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => this.goToRoute('admusers/admin', 'route_admin')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_admin',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Admin" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('admusers/advisor', 'route_advisor')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_advisor',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Consultor de Vendas"
                />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('admusers/sales', 'route_sales')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_sales',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Assistente de Vendas"
                />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('admusers/mis', 'route_mis')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_mis',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="MIS" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('admusers/supplier', 'route_supplier')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_supplier',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Bancos" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('admusers/affiliate', 'route_affiliate')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_affiliate',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Afiliado" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('admusers/franchisee', 'route_franchisee')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_franchisee',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Franqueado"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('admusers/screener', 'route_screener')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_screener',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Triagem" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('parceiros')}>
            <ListItemText className={'item-menu'} primary="Parceiros" />
            {this.state.show_options === 'parceiros' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'parceiros'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>

              <ListItem button onClick={() => this.goToRoute("admsuppliers", "route_admsuppliers")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "route_admsuppliers"
              })}>
                <ListItemText className={"sub-item-menu"} primary="Dados Parceiros" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('integration', 'route_integration')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_integration',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Integrações"
                />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('manager/agents', 'route_agents')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_agents',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Agentes" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('campanhas')}>
            <ListItemText className={'item-menu'} primary="Campanhas" />
            {this.state.show_options === 'campanhas' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'campanhas'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => this.goToRoute('campaigns', 'route_campaigns')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_campaigns',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Disparos" />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('campaigns/prepare', 'route_prepare')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_prepare',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Regras de Filtros"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('new-campaign/', 'route_new-campaign')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_new-campaign',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Manual" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('mensageria')}>
            <ListItemText className={'item-menu'} primary="Mensageria" />
            {this.state.show_options === 'campanhas' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'mensageria'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('admmessages', 'route_admmessages')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_admmessages',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Comunicações"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('emailTemplate', 'route_emailTemplate')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_emailTemplate',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Templates GO"
                />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('emailHistory', 'route_emailHistory')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_emailHistory',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Histórico" />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('url-shortener', 'route_url-shortener')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_url-shortener',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Encurtador de links" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu("dory")} >
            <ListItemText className={"item-menu"} primary="Dory" />
            {this.state.show_options === "dory" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={this.state.show_options === "dory"} timeout="auto" unmountOnExit>

            <List component="div" disablePadding>

              <ListItem button onClick={() => this.goToRoute("upsell/list", "upsell/list")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "upsell/list",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Upsells" />
              </ListItem>
              <ListItem button onClick={() => this.goToRoute("pathname", "pathname")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "pathname",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Pathnames" />
              </ListItem>
              <ListItem button onClick={() => this.goToRoute("affiliates", "affiliates")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "affiliates",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Affiliates" />
              </ListItem>
              <ListItem button hidden={true} onClick={() => this.goToRoute("form", "form")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "form",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Form" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu("contract")} >
            <ListItemText className={"item-menu"} primary="Contrato" />
            {this.state.show_options === "contract" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={this.state.show_options === "contract"} timeout="auto" unmountOnExit>

            <List component="div" disablePadding>

            <ListItem button onClick={() => this.goToRoute("contracts/list", "contracts/list")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "contracts/list",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Contratos" />
              </ListItem>

              <ListItem button onClick={() => this.goToRoute("input-layout/list", "input-layout/list")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "input-layout/list",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Input Layout" />
              </ListItem>
              <ListItem button onClick={() => this.goToRoute("input-layout/upload", "input-layout/upload")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "input-layout/upload",
              })} >
                <ListItemText className={"sub-item-menu"} primary="Upload File" />
              </ListItem>
              <ListItem button onClick={() => this.goToRoute("input-layout/list-files", "input-layout/list-files")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "input-layout/list-files"
              })} >
                <ListItemText className={"sub-item-menu"} primary="Imported File List" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu("it")}>
            <ListItemText className={"item-menu"} primary="IT" />
            {this.state.show_options === "it" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'it'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('manager/servers', 'route_servers')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_servers',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Servidores API"
                />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute('manager/cron', 'route_cron')}
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_cron',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Tarefas agendadas"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('manager/aggregations', 'route_aggregations')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_aggregations',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Agregações"
                />
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  this.goToRoute('manager/reprocessing/search', 'route_reprocessing_list')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_reprocessing_list',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Reprocessamentos"
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('parâmetros')}>
            <ListItemText className={'item-menu'} primary="Parâmetros" />
            {this.state.show_options === 'parâmetros' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'parâmetros'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('parameters/config', 'route_config')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_config',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Config (Servidor)"
                />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute(
                    'parameters/partner-priority',
                    'partner_priority'
                  )
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'partner_priority',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Relevância de parceiro"
                />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute(
                    'parameters/filters-manager',
                    'filters_manager'
                  )
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'filters_manager',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Gerenciador de Filtros"
                />
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute(
                    'ocr/models',
                    'ocr_models'
                  )
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'ocr_models',
                })}
              >
                <ListItemText
                  className={'sub-item-menu'}
                  primary="Regras OCR"
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('hermes')}>
            <ListItemText className={'item-menu'} primary="Hermes" />
            {this.state.show_options === 'hermes' ? (<ExpandLess />) : (<ExpandMore />)}
          </ListItem>

          <Collapse in={this.state.show_options === "hermes"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => this.goToRoute("hermes/queues", "hermes_queues")} className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === "hermes_queues"
                })}>
                <ListItemText className={"sub-item-menu"} primary="Criação de Filas" />
              </ListItem>
              <ListItem button onClick={() => this.goToRoute("hermes/users", "hermes_users")} className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === "hermes_users"
                })}>
                  <ListItemText className={"sub-item-menu"} primary="Consultores" />
              </ListItem>
              <ListItem button onClick={() => this.goToRoute("hermes/reports/tickets", "hermes_reports")} className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === "hermes_reports"
                })}>
                <ListItemText className={"sub-item-menu"} primary="Relatórios" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu("blocklist")}>
            <ListItemText className={"item-menu"} primary="Blocklist" />
            {this.state.show_options === "blocklist" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={this.state.show_options === "blocklist"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => this.goToRoute("blocklist/report", "route_report")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "route_report"
              })}>
                <ListItemText className={"sub-item-menu"} primary="Relatório" />
              </ListItem>

              <ListItem button onClick={() => this.goToRoute("blocklist/search", "route_search")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "route_search"
              })}>
                <ListItemText className={"sub-item-menu"} primary="Buscar Cliente" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('screening')}>
            <ListItemText className={'item-menu'} primary="Triagem" />
            {this.state.show_options === 'pagascreeningmentos' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>

          <Collapse
            in={this.state.show_options === 'screening'}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('screening/rules', 'route_screening')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_screening',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Regras" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => this.goToRoute('about', 'route_about')}
            className={clsx('', {
              ['fz-route-selected']:
                this.state.route_selected === 'route_about',
            })}
          >
            <ListItemText className={'item-menu'} primary="Sobre" />
          </ListItem>
        </List>
      </div>
    );
  }
}
