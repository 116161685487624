import React from 'react';
import PropTypes from 'prop-types';
import { Namespace } from '../scripts/state/redux_ns';
import InputText from './input_text';
import InputNumber from './input_number';
import InputMasked from './input_masked';
import InputBool from './input_bool';
import { FormControl, FormGroup } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import get from 'lodash/get';
import moment from 'moment'
import { FzForm } from '../ui/fz/layout/index'


// Field renders a field specified in the 'path' prop of the object in the 'data'
//
// Field is responsible for getting the current value for the field and setting
// up the onChange function that saves the modified value in the namespace.
//
const Field = ({ type, path, ns, sharedNS, data, label, component, cols, api, noLabel, ...options }) => {

  const changeField = (newValue) => {
    ns.saveChange(path, newValue);
    if (options.ttl === null || options.ttl === undefined) {
      ns.set("dirty", true, -1)
    } else {
      ns.set("dirty", true, options.ttl)
    }
    if (options.notifyChange) {
      options.notifyChange(path, newValue)
    }
  }
  let readOnly = options.readOnly === undefined ? !ns.isEditing() : options.readOnly;
  var value
  if (!readOnly && ns.isEditing()) {
    value = ns.getChanged(path);
    // definicao de componentes, um para cada tipo
  } else {
    value = get(data, path);
  }
  let inputControl;
  if (component !== undefined) {

    inputControl = React.createElement(component, { name: path, path, ns: ns, disabled: readOnly, api: api, value: (value || ''), data, onChange: ((newVal) => changeField(newVal)), ...options })
  } else if (options.mask !== undefined) {
    inputControl = <InputMasked
      type={type}
      readOnly={readOnly}
      name={path}
      value={value || ''}
      onChange={(newVal) => changeField(newVal)}
      {...options}
    />

  } else {
    switch (type) {
      case 'select':
        inputControl = (<span>*** ERROR in field {path}</span>)
        break;
      case 'bool':
        inputControl = <InputBool
          name={path}
          disabled={readOnly}
          value={value}
          onChange={(newVal) => changeField(newVal)}
        />
        break;
      case 'currency':
      case 'number':
        inputControl = <InputNumber
          readOnly={readOnly}
          name={path}
          type={type}
          value={value !== undefined ? Number(value) : 0}
          onChange={(newVal) => changeField(newVal)}
          {...options}
        />
        break
      case 'datetime':
        inputControl = <DateTimePicker
          name={path}
          disabled={readOnly}
          value={(typeof (value) === "string" && value.slice(0, 4) === "0001") ? null : moment(value)}
          dateFormat="D/M/YYYY"
          timeFormat="H:mm"
          utc={true}
          onChange={(newVal) => changeField(newVal)} />
        break;

      default:
        inputControl = <InputText
          readOnly={readOnly}
          name={path}
          value={value || ''}
          onChange={(newVal) => changeField(newVal)}
          type={type}
          {...options}
        />
    }
  }
  let className;
  if (cols === 0) {
    className = "";
  } else if (cols === 1) {
    className = "col-sm-12";
  } else if (cols === 3) {
    className = "col-sm-4";
  } else {
    className = "col-sm-6";
  }
  let validationState
  if (!readOnly && ns.isInvalid(path)) {
    validationState = "error";
  }
  let controlLabel = (<FzForm.Label>{label || path}</FzForm.Label>)
  if (noLabel) {
    controlLabel = null
  }
  return (<FormGroup className={className} validationState={validationState} >
    {controlLabel}
    {inputControl}
    <FormControl.Feedback />
  </FormGroup>)
}


Field.propTypes = {
  path: PropTypes.string.isRequired,
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.object.isRequired,
  cols: PropTypes.number,
  type: PropTypes.string.isRequired
};

export default Field;
