import React, { Component } from 'react';
import { Jumbotron, Button, ButtonGroup } from 'react-bootstrap';
import SupplierDetails from './supplier-details'
import { SupplierFilters } from '../ui/blocks/admin-suppliers/supplier-filters'
import SupplierBranches from './supplier-branches'
import SuppliersUsers from './supplier-users'
import SupplierLogs from './supplier-logs'
import { getUsers, getUserById } from '../api/users';
import { getSupplierById, updateSupplier, disableNewApplications, getAllSuppliers, createSupplier } from '../api/suppliers';
import api from '../api/client-api';
import { FzCardGroup } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';
import moment from 'moment'
import { getAllProducts } from '../api/applications'
import { GetAllFilter, GetAllForm } from '../services/api/dory';

export class EditSupplier extends Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.disableSupplier = this.disableSupplier.bind(this);
    this.getUsersSupplier = this.getUsersSupplier.bind(this)
    this.getCheckedUsers = this.getCheckedUsers.bind(this)
    this.state = { requestState: "idle", requestCheckedUsers: "idle", "productsList": [], formSections: {} };
    this.remvoedUsersAssocietedOtherPartners = this.remvoedUsersAssocietedOtherPartners.bind(this)
    this.getAllAssociatedUsers = this.getAllAssociatedUsers.bind(this)
    this.getAllProductsList = this.getAllProductsList.bind(this)
  }

  async getCheckedUsers(supplier, ns) {
    this.setState({ requestCheckedUsers: "requesting" }, async () => {
      if (supplier && supplier.users && Array.isArray(supplier.users)) {
        await Promise.all(supplier.users.map(async (userId) => {
          const user = await getUserById(userId)
          ns.push("checkedUsers", user);
        }))
        this.setState({ requestCheckedUsers: "idle" })
      }
    })
  }

  async getAllProductsList() {
    try {
      const response = await getAllProducts()
      const products = response.map(({ internalName: code, description }) => ({
        code,
        description,
      }))

      this.setState({ productsList: products })
    } catch (error) {
      let err = error
      console.error(err)
    }
  }

  getForms() {
    GetAllForm()
    .then(forms => {
      let _formSections = []
      forms['data'].forEach(_form => {
        _form['pages'].forEach(_page => {
          _page['sections'].forEach(_section => {
            const code = `${_form['product']}_${_page['page']}_${_section['type']}`
            const description = `${_form['product']} - ${_page['page']} - ${_section['title'] || _section['type']}`
            _formSections.push({ code, description })
          })
        })
      })
      this.setState({ formSections: _formSections })
    })
    .catch((err) => console.warn(err))
  }

  getFilters() {
    GetAllFilter()
    .then(filters => {
      const _filters = filters['data'].map(_filter => {
        return { 
          code: _filter._id,
          description: _filter.name
        }
      })
      this.setState({ filters: _filters })
    })
    .catch((err) => console.warn(err))
  }

  doGetSuppliers() {
    return getAllSuppliers()
      .then((suppliers) => { return suppliers })
      .catch((err) => { console.warn(err); this.props.history.push("/"); return null })
  }

  saveChanges() {
    if (this.props.readOnly) {
      return
    }

    let ns = this.props.ns
    let tmpSupp = ns.getChanged()
    if (tmpSupp) {
      if (tmpSupp.layoutProps && tmpSupp.layoutProps.bullets) {
        delete tmpSupp.layoutProps.bullets;
      }
      // all branches must have channel field set up
      if (tmpSupp.branches) {
        for (let b in tmpSupp.branches) {
          if (tmpSupp.branches[b] && tmpSupp.branches[b].layoutProps && tmpSupp.branches[b].layoutProps.bullets) {
            delete tmpSupp.branches[b].layoutProps.bullets;
          }
          if (tmpSupp.branches.hasOwnProperty(b)) {
            if (tmpSupp.branches[b].channel === undefined || tmpSupp.branches[b].channel.trim().length === 0) {
              alert("Canal da Filial deve ser digitado [" + b + "]");
              return
            }
            if (tmpSupp.branches[b].branchAPICode !== undefined && tmpSupp.branches[b].branchAPICode.split("|").length > 1) {
              var spD = tmpSupp.branches[b].branchAPICode.split("|");
              tmpSupp.branches[b].branchAPICode = spD[0].trim().toLowerCase() + "|" + spD.slice(1).join("|");
            }
          }
        }
      }
      // clean filters with 'empty' keys
      if (tmpSupp.filters) {
        for (let product in tmpSupp.filters) {
          for (let key in tmpSupp.filters[product]) {
            if (key === 'empty' || !tmpSupp.filters[product][key]) {
              delete tmpSupp.filters[product][key]
            }
          }
        }
        for (let product in tmpSupp.filters) {
          if (product === 'empty' || Object.keys(tmpSupp.filters[product]).length === 0) {
            delete tmpSupp.filters[product]
          }
        }
      }
      if (tmpSupp.internalName.length === 0) {
        alert("Nome sistêmico deve ser digitado!");
        return
      }
      // Associated user
      if (tmpSupp.users.length === 0) {
        alert("Você deve ter pelo menos um usuário associado");
        return
      }

      this.doGetSuppliers()
        .then((suppliers) => {
          let sameIName = suppliers.find(x => x.internalName === tmpSupp.internalName)

          if (sameIName && sameIName.id !== tmpSupp.id) {
            alert("Nome sistêmico já em uso! Por favor, cancele e digite outro nome");
            return
          }

          if (tmpSupp.id === "new") {
            createSupplier(tmpSupp)
              .then((supplier) => {
                ns.unset("dirty");
                ns.stopEditing();
                ns.set("supplier", supplier)
                ns.unset("checkedUsers")

                this.props.history.push("/admsuppliers/" + supplier.id)
                window.location.reload();
              })
              .catch((err) => {
                ns.unset("dirty");
                console.error("API ERROR:", err);
                ns.stopEditing();
                ns.unset("supplier")
              });
          } else {
            updateSupplier(tmpSupp)
              .then((supplier) => {
                ns.unset("dirty");
                ns.stopEditing();
                ns.set("supplier", supplier)
                ns.unset("checkedUsers")

                this.props.history.push("/admsuppliers/" + supplier.id)
                window.location.reload();
              })
              .catch((err) => {
                ns.unset("dirty");
                console.error("API ERROR:", err);
                ns.stopEditing();
                ns.unset("supplier")
              });

          }


        })
    }
  }

  disableSupplier(id, disable) {
    disableNewApplications(id, disable)
      .then((supplier) => {
        this.props.ns.set("supplier", supplier); this.props.history.push("/admsuppliers")
      })
  }

  async getUsersSupplier() {
    const { ns } = this.props;

    this.setState({ requestState: "requesting" }, async () => {

      let supplier = ns.getChanged() || ns.get("supplier")
      const resultAllSuppliers = await getAllSuppliers()
      const resultAllUsersRoleSupplier = await getUsers("supplier")

      if (supplier && resultAllSuppliers && resultAllUsersRoleSupplier) {
        const associatedUsersOtherPartners = this.getAllAssociatedUsers(resultAllSuppliers);
        const usersAvailableForMembership = this.remvoedUsersAssocietedOtherPartners(resultAllUsersRoleSupplier, associatedUsersOtherPartners, supplier)

        ns.set("associatedUsersOtherPartners", associatedUsersOtherPartners)
        ns.set("supplierUsers", usersAvailableForMembership)
        ns.set("suppliers", resultAllSuppliers)
        this.setState({ requestState: "idle" });

      } else {
        return null
      }
    })
  }

  remvoedUsersAssocietedOtherPartners(resultAllUsersRoleSupplier, associatedUsersOtherPartners, supplier) {
    const alreadyAssociatedUser = resultAllUsersRoleSupplier.filter((user) => supplier.users.indexOf(user.id) !== -1)
    const usersAvailableForMembership = resultAllUsersRoleSupplier.filter((user) => associatedUsersOtherPartners.indexOf(user.id) === -1)
    return [...alreadyAssociatedUser, ...usersAvailableForMembership];
  }

  getAllAssociatedUsers(resultAllSuppliers) {
    return [...new Set(resultAllSuppliers.reduce((resultSoFar, current) => {
      return [...resultSoFar, ...current.users];
    }, []))];
  }

  async componentDidMount() {
    await this.getAllProductsList()
    this.getForms()
    this.getFilters()

    let url_atual = window.location.href;
    var supp = this.props.ns.getChanged() || this.props.ns.get("supplier")
    if (!supp) {
      if (!url_atual.match(/new/)) {
        getSupplierById(this.props.match.params.supplierId)
          .then((supplier) => {
            this.props.ns.set("supplier", supplier)
          })
          .catch((error) => {
            console.warn(error)
          });
      }
    }

  }



  render() {
    let { ns } = this.props;
    let { requestState, requestCheckedUsers } = this.state;

    let supplier = ns.getChanged() || ns.get("supplier")
    if (supplier === null || supplier === undefined) {
      return null
    }

    let supplierUsers = ns.get("supplierUsers")
    if (requestState === "idle" &&
      supplier &&
      supplier.users &&
      supplierUsers === undefined) {
      this.getUsersSupplier();
    }

    let checkedUsers = ns.get("checkedUsers")
    if (
      requestCheckedUsers === "idle" &&
      supplier &&
      supplier.users &&
      supplier.users.length > 0 &&
      checkedUsers === undefined) {
      this.getCheckedUsers(supplier, ns);
    }
    let justCreatedSupplier = supplier.newApplicationsDisabled && (Math.floor((new Date() - new Date(supplier.created)) / 3600000) <= 5) && supplier.name === ""

    let buttons = [];
    let alert
    let readOnly = !ns.isEditing()
    if (ns.isEditing()) {
      buttons.push(<ButtonGroup key="save-cancel">
        <Button variant="primary" onClick={this.saveChanges}>Gravar</Button>
        <Button variant="default" onClick={() => {
          ns.unset("dirty");
          ns.stopEditing();
          window.location.reload();
        }}>Cancelar</Button>
      </ButtonGroup>);

      if (ns.isInvalid()) {
        alert = <FzAlert fzStyle="alert" title={ns.getInvalidFields().join()}>Corrija os erros nos campos marcados de vermelho!</FzAlert>
      }
    } else if (api.isUserInRole("admin")) {
      buttons.push(<ButtonGroup key="edit">
        <Button variant="primary" onClick={() => ns.startEditing(ns.get("supplier"))}>Editar</Button>
      </ButtonGroup>)
      if (!supplier.newApplicationsDisabled) {
        buttons.push(<ButtonGroup key="close-proposals">
          <Button variant="warning" onClick={() => this.disableSupplier(supplier.id, true)}>Fechar para novas propostas</Button>
        </ButtonGroup>);
      } else if (justCreatedSupplier) {
        // newly created supplier
        ns.startEditing(ns.get("supplier"));
      } else {
        buttons.push(<ButtonGroup key="open-proposals">
          <Button variant="warning" onClick={() => this.disableSupplier(supplier.id, false)}>Habilitar para novas propostas</Button>
        </ButtonGroup>);
      }
    }

    if (supplier.auditLogs && supplier.auditLogs.length > 0) {
      const lastDate = moment(Math.max(...supplier.auditLogs.map(e => new Date(e.ObjectCreated)))).format("DD/MM/yyyy HH:mm");

      buttons.push(<ButtonGroup key="last-update">
        <div style={{ marginLeft: '10px' }}>Última alteração: {lastDate}</div>
      </ButtonGroup>)
    }

    if (this.state.productsList.length > 0) {
      return (
        <Jumbotron>
          <FzCardGroup id="EditSupplier">
            {buttons}
            {alert}
            <SupplierDetails supplier={supplier} productsList={this.state.productsList} formSections={this.state.formSections} filters={this.state.filters} ns={ns} readOnly={readOnly} />
            <SupplierFilters supplier={supplier} productsList={this.state.productsList} ns={ns} readOnly={readOnly} basePath={"filters"} />
            <SupplierBranches supplier={supplier} productsList={this.state.productsList} formSections={this.state.formSections} filters={this.state.filters} ns={ns} readOnly={readOnly} />
            <SuppliersUsers supplierUsers={supplierUsers} userIds={supplier.users} checkedUsers={checkedUsers} ns={ns} readOnly={readOnly} />
            <SupplierLogs supplier={supplier} />
          </FzCardGroup>
        </Jumbotron>
      );
    }
    return null
  }

}
