import { useEffect, useState } from 'react';
import {
  FinanvcRequestInterface,
  FinanvcResponseInterface,
  ShortenerContainer,
  VALIDATE_URL_PATTERN,
} from '../../../biz/metadatas/url-shortener-metadata';
import FieldConnected from '../../../ui/components/form/field-connected';
import { FzButton } from '../../../ui/fz/form';
import { FzCol, FzRow } from '../../../ui/fz/layout';
import { postURLShortener } from '../../../services/api/finanvc';

export const URLShortenerContainer = ({ ns, data }) => {
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [result, setResult] = useState<FinanvcResponseInterface | null>(null);

  useEffect(() => {
    const invalidFields: Array<string> = ns.getInvalidFields('INVALID');
    if (invalidFields.length >= 1) {
      setButtonDisabled(true);
      return;
    }

    const changed: {
      targetUrl: string;
      expireAt: string;
      expiredUrl: string;
    } = ns.getChanged();

    if (
      !changed ||
      !changed.targetUrl ||
      !changed.expireAt ||
      isNaN(Date.parse(changed.expireAt))
    ) {
      setButtonDisabled(true);
      return;
    }

    const expireAt = addTimezoneOffset(changed.expireAt);
    if (Date.parse(expireAt) < Date.now()) {
      setButtonDisabled(true);
      return;
    }

    if (changed.expiredUrl) {
      const isValidExpiredUrl = VALIDATE_URL_PATTERN.test(changed.expiredUrl);
      if (isValidExpiredUrl) {
        ns.setValid('expiredUrl');
      } else {
        ns.setInvalid('expiredUrl');
        setButtonDisabled(true);
        return;
      }
    }

    setButtonDisabled(false);
  }, [ns]);

  async function handleFinanvcRequest() {
    const changed = ns.getChanged();

    const payload: FinanvcRequestInterface = {
      targetUrl: changed.targetUrl,
      event: changed.event,
      expireAt: addTimezoneOffset(changed.expireAt),
      ...(changed.expiredUrl && { expiredUrl: changed.expiredUrl }),
    };

    try {
      const finanvcResponse: { data: FinanvcResponseInterface } =
        await postURLShortener(payload);
      const { hash, shortUrl } = finanvcResponse.data;
      const expireAt = new Date(finanvcResponse.data.expireAt).toLocaleString();

      setResult({ expireAt, hash, shortUrl });
    } catch (error) {
      console.error('error at finanvc request', error);
    }
  }

  function addTimezoneOffset(date: string) {
    const timezoneOffset = 3 * 60 * 60 * 1000;
    const localeDatetime = new Date(date).getTime() + timezoneOffset;
    return new Date(localeDatetime).toISOString();
  }

  return (
    <>
      <FieldConnected meta={ShortenerContainer.TargetUrl} data={data} ns={ns} />
      <FieldConnected meta={ShortenerContainer.Event} data={data} ns={ns} />
      <FieldConnected meta={ShortenerContainer.ExpireAt} data={data} ns={ns} />
      <FieldConnected
        meta={ShortenerContainer.ExpiredUrl}
        data={data}
        ns={ns}
      />

      <div style={{ marginTop: '20px' }}>
        <FzButton
          fzStyle="regular"
          disabled={isButtonDisabled}
          onClick={handleFinanvcRequest}
          name="requestFinanvcLink"
        >
          Gerar URL encurtada
        </FzButton>
      </div>

      {result && (
        <>
          <FzRow>
            <FzCol key={'resultShortUrl'} span={4}>
              <FieldConnected
                data={result}
                ns={ns}
                meta={ShortenerContainer.ResultShortUrl}
              />
            </FzCol>
            <FzCol key={'resultHash'} span={4}>
              <FieldConnected
                data={result}
                ns={ns}
                meta={ShortenerContainer.ResultHash}
              />
            </FzCol>
            <FzCol key={'resultExpireAt'} span={4}>
              <FieldConnected
                data={result}
                ns={ns}
                meta={ShortenerContainer.ResultExpireAt}
              />
            </FzCol>
          </FzRow>
        </>
      )}
    </>
  );
};
