import { confirmationStepsMeta } from "../../../biz/metadatas/confirmation-steps";
import { FzTable } from "../../fz/grid/table";
import { FzCard } from "../../fz/layout/index";

export const ConfirmationSteps = ({ ns, app, bid }) => {
  return app?.supplierData[bid.supplier.internalName]?.confirmationSteps?.length ? (
    <FzCard bsStyle="info">
      <FzCard.Heading>
        <FzCard.Title componentClass="h3">
          Etapas de Confirmação
        </FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <FzTable
          data={app.supplierData[bid.supplier.internalName].confirmationSteps}
          metas={confirmationStepsMeta}
          ns={ns}
          visibleColumns={["value", "type", "completed", "rejected"]}
          context={"confirmationSteps"}
          exportDownload={false}
          clipboard={false}
        />
      </FzCard.Body>
    </FzCard>
  ) : null;
};
