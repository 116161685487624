
import {
  InsertButton,
  InsertModalHeader,
  InsertModalFooter
} from 'react-bootstrap-table';
import { getDomain } from '../../../../../scripts/utils/cache-helpers';

export function createInsertButton(onClick) {
  return (
    <InsertButton
      btnText='Adicionar'
      btnContextual='btn-primary'
      btnGlyphicon='glyphicon-edit'
      onClick={() => onClick()}
    />
  );
}

export function createModalHeader(closeModal) {
  return (
    <InsertModalHeader
      className='my-custom-class'
      title='Inserir nova regra'
      onModalClose={closeModal}
      hideClose={true} // to hide the close button
    />
  );

}

export function createModalFooter(closeModal, save) {
  return (
    <InsertModalFooter
      className='my-custom-class'
      saveBtnText='Inserir'
      closeBtnText='Cancelar'
      closeBtnContextual='btn-secondary'
      saveBtnContextual='btn-primary'
      closeBtnClass='my-close-btn-class'
      saveBtnClass='my-save-btn-class'
      onModalClose={closeModal}
      onSave={save} />
  );
}

export function createOptionsInsertRow(colObject, ns) {
  if (colObject.tableProps.editable && colObject.tableProps.editable.type === "select") {
    let optionsList: { code: string | undefined| null, description: string }[] = [{ code: null, description: 'Selecione' }]
    if (!colObject.tableProps.editable.options && colObject.domainName) {
      optionsList.push(...getDomain(ns, colObject.domainName))
    } if (colObject.tableProps.editable.options) {
      optionsList.push(...colObject.tableProps.editable.options)
    }

    return optionsList.map(it => ({
      value: it.code,
      text: it.description
    }))
  }
  return undefined
}