import React, { Component } from 'react';
import {Button, Jumbotron } from 'react-bootstrap';
import MessagesTable from './messages-table';
import SplitLine from "../components/split-line";
import {getAllRules } from '../api/client-messages';
import { FzCard} from '../ui/fz/layout/index'

export class AdminMessages extends Component {
  constructor(props) {
    super(props);
    this.handlePageSelect = this.handlePageSelect.bind(this);
    //this.doGetSuppliers = this.doGetSuppliers.bind(this);
  }

  handlePageSelect(eventKey) {
    this.props.history.push('/admmessages/' + eventKey)
  }

  updateMessagesList() {
      this.doGetMessages()
      .then(() => alert("Lista Atualizada"))
      .catch(err => {
        console.error(err);
        alert("Falha ao atualizar a lista");
      });
  }

  createMessage() {

    let newPath = this.props.ns.namespace + "/new"
    let newNs = this.props.ns.copy(newPath)
    let newMessageRule = { _id: "new" }
    newNs.set("message", newMessageRule)
    newNs.startEditing(newMessageRule)
    this.props.history.push("/admmessages/new")
  }

  doGetMessages() {
    getAllRules()
      .then((messages) => {
        messages = messages.map(it => {
          if (it.channels && it.channels.length > 0) {
            it = { ...it, templateIds: it.channels.map(channel => channel.templateid).filter(e => e) }
          }
          return it;
        })
        this.props.ns.set("messages", messages);
      })
      .catch((err) => {console.warn(err); this.props.history.push("/")})
  }

  componentDidMount() {
    this.doGetMessages();
  }

  render () {
    const authorized = this.props.api.isUserInRole("mis");

    return(
        <Jumbotron>
          <FzCard>
            <FzCard.Heading>
              <FzCard.Title componentClass="h3">
                <SplitLine>
                  <SplitLine.Left>Regras de Mensagens</SplitLine.Left>
                  <SplitLine.Right>
                    {authorized ? <Button variant="success" onClick={() => this.createMessage()}>Criar Regra de Mensagem</Button> : null}
                  </SplitLine.Right>
                  <SplitLine.Right>

                  </SplitLine.Right>
                </SplitLine>
              </FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
            <MessagesTable ns={this.props.ns} api={this.props.api} data={this.props.ns.get("messages")} history={this.props.history}/>
            </FzCard.Body>
          </FzCard>
        </Jumbotron>
     );
 }
}