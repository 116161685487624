import React from 'react';
import { FunnelFormProgression, FormVersionFieldValidation } from "../../pages/statistics/form-progression";
import * as statsMeta from '../../../biz/metadatas/form-metadata'
import { FieldValidationReport } from '../../pages/statistics/FieldValidationReport';

const FormStats = ({ ns, activeKey,  toggleBlocking, ...props }) => {

  let content
  switch (activeKey) {
    case "form-progression":
      return <FunnelFormProgression ns={ns} metas={statsMeta.formSummary} toggleBlocking={toggleBlocking} />
    case "form-validation":
      // content = <FormVersionFieldValidation ns={ns} metas={statsMeta.formSummary} toggleBlocking={toggleBlocking} />
      content =  <FieldValidationReport  ns={ns} toggleBlocking={toggleBlocking} />
      break;
    default:
      content = null;
      break;
  }

  return (content);
}

export default FormStats;
