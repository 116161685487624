
import React from 'react';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "./style.css";

interface Images {
  url: string;
  title: string;
}

interface FzImageProps {
  url: string;
  handleClose: () => void;
  description: string;
}

interface FzImagesProps {
  handleClose: () => void;
  images: Images[];
}

export function FzImage({ url, handleClose, description }: FzImageProps) {
  return (
    <Lightbox image={url} title={description} onClose={handleClose} />
  );
}

export function FzImages({ handleClose, images }: FzImagesProps) {
  return (
    <Lightbox images={images} onClose={handleClose} />
  );
}