import React from 'react'
import moment from 'moment'

import FieldConnected from '../../../../ui/components/form/field-connected'
import { FzButton } from '../../../../ui/fz/form'
import { FzCard } from '../../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../../ui/fz/grid/table'
import { FzToast } from '../../../../ui/fz/form/notification/toast'
import { FzCol, FzRow } from '../../../../ui/fz/layout';
import { get as getLodash } from 'lodash'
import { 
  analyticsFilter, 
  analyticsColumns, 
} from '../../../../biz/metadatas/stats/user-documents/user-documents'

const Analytics = ({
  data,
  filters,
  handleSearchReport,
  handleTostClose,
  ns,
  role,
  showTost,
  onPageChange,
  options,
  clearFilters,
}) => {
  const btnSearchDisable = (
    moment(filters.from).toISOString() 
    && moment(filters.to).toISOString() 
      ? false 
      : true
  )

  analyticsFilter
    .supplierInternalName
    .inputProps
    .options = getLodash(options, ['suppliers'], [])

  const formItems = Object.keys(analyticsFilter)
  const formItem = item => (
    <FzCol span={2} key={item}>
      <FieldConnected 
        meta={analyticsFilter[item]}
        data={filters}
        ns={ns}
      />
    </FzCol>
  )
  const buildFormItem = formItems.map(formItem)

  return (
    <FzCard>
    <FzCard.Header>
      <FzCard.Title>
        Analítico
      </FzCard.Title>
    </FzCard.Header>
      <div className="fz-panel">
        <div className="fz-panel-border-padding" style={{ display: "block", border: "none" }}>
          <div>
            <small>É necessário preencher pelo menos um dos campos abaixo:</small>
          </div>
          <FzRow>
            {buildFormItem}
            <div  style={{ margin: "25px 10px 0 17px"}}>
              <FzButton 
                fzStyle="discrete" 
                onClick={handleSearchReport}
                disabled={btnSearchDisable}
              >
                Buscar
              </FzButton>
            </div>
            <div  style={{ margin: "25px 10px 0 0"}}>
              <FzButton 
                fzStyle="info" 
                onClick={clearFilters}
              >
                Limpar filtros
              </FzButton>
            </div>
          </FzRow>
        </div>
      </div>

      <FzCard.Body>
        <FzTable
          dataTotalSize={getLodash(data, ['statistics','total'], 0)}
          remote={true}
          onPageChange={onPageChange}
          ns={ns}
          metas={analyticsColumns}
          data={getLodash(data, ['statistics','userDocumentsAnalytics'], 0)}
          exportDownload={role === "admin"}
          context="UserDocumentsAnalytics"
          pagination
        />
      </FzCard.Body>
      <FzToast
        fzStyle="danger"
        close={handleTostClose}
        show={showTost}
        delay={5000}
        headerMessageTost="Error"
        bodyMessageTost="Erro interno, tente novamente!"
        autoHide={true}
      />
    </FzCard>
  )
}

export { Analytics }
