import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import FiltersDetails from './filter-detail';
import { FzTable } from '../ui/fz/grid/table';
import { tableRoleMessaging } from '../biz/metadatas/message-metadata'
import { FzCard } from '../ui/fz/layout/index'
import { FzIcons } from '../ui/fz/form/icon'

const MessageRuleChannelFiltersTable = ({
  ns,
  channel,
  message,
  code,
  readOnly,
  persistChanges,
  ...props
}) => {

  const expandComponent = (ns, channel, row, readOnly) => {
    let index = row['index'];

    let channel_obj = { filters: channel };
    return (
      <FiltersDetails
        ns={ns}
        channel={channel_obj}
        index={index}
        readOnly={readOnly}
        message={message}
        code={code}
        persistChanges={persistChanges}
      />
    );
  };

  var data = [];
  var filters = channel || {};
  for (var itemCode in filters || {}) {
    let x = filters[itemCode];
    x['index'] = itemCode;

    data.push(x);
  }

  return (
    <FzTable
      data={data}
      metas={tableRoleMessaging}
      rowExpand={{
        expandComponent: (row) => expandComponent(ns, channel, row, readOnly)
      }}
      exportDownload={false}
      clipboard={false}
      visibleColumns={["index", "eval"]}
    />
  );
};

class MessageRuleFiltersEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.changeOpenState = this.changeOpenState.bind(this);
  }
  changeOpenState() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let {
      ns,
      index,
      channel,
      code,
      message,
      readOnly,
      persistChanges,
    } = this.props;

    if (!channel.filters) return null;

    let removeFiltersButton = (
      <FzCard>
        <ButtonGroup>
          <Button
            title="Remover Filtro"
            onClick={e => {
              e.stopPropagation();
              delFilter(ns, code, message, index);
            }}
          >
          < FzIcons icon={"minus"} fzStyle={{width: "15px", height: "15px"}} /> Remover Filtro{' '}
          </Button>
        </ButtonGroup>
      </FzCard>
    );
    if (readOnly) {
      removeFiltersButton = null;
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">
            {index !== 'undefined' ? index : 0} -{' '}
          </FzCard.Title>
        </FzCard.Heading>

        <FzCard.Body>
          <FiltersDetails
            ns={ns}
            channel={channel}
            index={index}
            message={message}
            code={code}
            readOnly={readOnly}
            persistChanges={persistChanges}
          />
          {removeFiltersButton}
        </FzCard.Body>
      </FzCard>
    );
  }
}

function delFilter(ns, code, message, index) {
  if (!message.channels[code].filters) return;

  if (index >= message.channels[code].filters.length) return;

  message.channels[code].filters.splice(index, 1);

  ns.set('message', message);
}

const MessageRuleFilters = ({
  ns,
  api,
  channel,
  message,
  code,
  readOnly,
  persistChanges,
  ...props
}) => {
  if (!readOnly && persistChanges === undefined) {
    console.error('Callback not supplied: making component readOnly');
    readOnly = true;
  }

  let Filters;
  if (!channel.filters) {
    Filters = '';
  }
  if (readOnly) {
    Filters = (
      <MessageRuleChannelFiltersTable
        ns={ns}
        channel={channel}
        message={message}
        code={code}
        readOnly={readOnly}
        persistChanges={persistChanges}
      />
    );
  } else {
    Filters = [];
    for (let index in channel.filters) {
      Filters.push(
        <MessageRuleFiltersEdit
          ns={ns}
          index={index}
          channel={channel}
          message={message}
          code={code}
          readOnly={readOnly}
          persistChanges={persistChanges}
          key={`${code}-${index}`}
          props={props}
        />
      );
    }
  }
  return (
    <FzCard>
      <FzCard.Heading>
        <FzCard.Title componentClass="h3">
          Filtro - Condições de Regras (eval)
        </FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>{Filters}</FzCard.Body>
    </FzCard>
  );
};

export { MessageRuleChannelFiltersTable as default, MessageRuleFilters };
