import { Modal } from "react-bootstrap"
import { FzText } from "../../fz/form/old-simple-input"
import { FzButton } from "../../fz/form"

const ModalIMEI = ({ 
  onChange, 
  value, 
  onClose, 
  show, 
  onSave, 
  error,
}) => {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title >
          Atualizar Imei
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FzText
          placeHolder="Digite o imei!"
          value={value}
          name="imei"
          onChange={onChange}
        />
        <span style={{ 
          display: "block", 
          color: "red", 
          fontSize: "0.8em",
        }}>{error}</span>
      </Modal.Body>
      <Modal.Footer>
        <FzButton
          onClick={onClose}
          fzStyle="alert"
        >
          Cancelar
        </FzButton>
        <FzButton
          onClick={onSave}
          fzStyle="info"
        >
          Atualizar
        </FzButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalIMEI