export function getOfferIdsLength(
  auction: Bid[],
  supplierInternalName: String
) {
  const bid = auction.find(
    (bid) => bid.supplier?.internalName === supplierInternalName
  );
  const offerIds = bid?.alternateOffers?.map((offer) => offer.offerId);
  return offerIds?.length || 0;
}
/**
 * Filters out the disabled suppliers and normalize the allowed list.
 *
 * @param {Record<string, SupplierType>} suppliersAll - A object of suppliers keyed by their names.
 * @param {string[]} elegibleSuppliers - An array of accepted supplier names.
 * @returns {OptionsItem[]} An array of filtered and transformed supplier options.
 */
export function filterAllowedSuppliers(
  suppliersAll: Record<string, SupplierType>,
  elegibleSuppliers: string[]
): OptionsItem[] {
  const suppliersInformations: SupplierType[] = elegibleSuppliers.map(
    (supplierName) => suppliersAll[supplierName] || {}
  );

  const allowedSuppliers = suppliersInformations.filter((supplier) => {
    if (!supplier?.inactive && !supplier?.newApplicationsDisabled) {
      return supplier;
    }
  });

  const normalizedSuppliers = Object.values(allowedSuppliers)
    .map(({ internalName: code, name: description }) => ({ code, description }))
    .sort((previousSupplier: OptionsItem, nextSupplier: OptionsItem) =>
      previousSupplier.description.localeCompare(nextSupplier.description, 'pt')
    );

  return normalizedSuppliers;
}

/**
 * @summary Intersects accepted suppliers with allowed branches to determine eligible suppliers for custom simulation.
 *
 * This function takes a list from supplierData (application) and a list of allowed suppliers with their branches (manager)
 * and returns a list of internal names of suppliers that didn't reject the application
 * and have both accepted the application and the branch are allowed to do a new simulation.
 *
 * @param supplierData - Data from application, a object of supplier data with internal names as keys.
 * @param elegibleSuppliers - Data from manager, a list of allowed branches for each supplier internal name.
 * @returns {string[]} A list of internal names of eligible suppliers.
 */
export function intersectSuppliers(
  supplierData: {
    [internalName: string]: {
      accepted: boolean;
      rejected: boolean;
      branchCode: string;
      [key: string]: any;
    };
  },
  elegibleSuppliers: AllowedSimulationSupplier[]
): string[] {
  return Object.entries(supplierData)
    .filter(([internalName, { accepted, rejected, branchCode }]) => {
      const currentSupplier = elegibleSuppliers.find(
        (sup) => sup.supplierInternalName === internalName
      );
      return (
        currentSupplier &&
        accepted &&
        !rejected &&
        currentSupplier.branches.find((branch) => branch === branchCode)
      );
    })
    .map(([internalName, _]) => internalName);
}

export function validAppliedAmount(appliedAmount: any) {
  return isNaN(Number(appliedAmount)) && Number(appliedAmount) > 0;
}

export const installmentsOptions = [
  { code: '6', description: '06 parcelas' },
  { code: '12', description: '12 parcelas' },
  { code: '18', description: '18 parcelas' },
  { code: '24', description: '24 parcelas' },
  { code: '30', description: '30 parcelas' },
  { code: '36', description: '36 parcelas' },
  { code: '48', description: '48 parcelas' },
  { code: '60', description: '60 parcelas' },
];

export const toastTypes: Record<string, ToastType> = {
  'internal-error': {
    delay: 15000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Erro',
    messageTost: 'Erro interno.',
    bodyMessageTost: 'Erro interno.',
  },
  'missing-data': {
    delay: 15000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Erro na solicitação',
    messageTost: 'Preencha os campos obrigatórios.',
  },
  'no-content': {
    delay: 30000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Simulação sem ofertas',
    messageTost: 'O parceiro {supplier} não possui novas ofertas disponíveis.',
  },
  success: {
    delay: 30000,
    autoHide: false,
    fzStyle: 'success',
    headerMessageTost: 'Simulação com ofertas',
    messageTost: 'Há novas ofertas disponíveis para o parceiro {supplier}!',
  },
  requested: {
    delay: 30000,
    autoHide: true,
    fzStyle: 'success',
    headerMessageTost: 'Simulação de ofertas',
    messageTost: 'Simulação solicitada para o parceiro {supplier}.',
  },
  'already-requesting': {
    delay: 10000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Simulação de ofertas',
    messageTost:
      'Já existe uma solicitação para o parceiro {supplier}. Aguarde antes de efetuar a próxima.',
  },
  'no-offer-ids': {
    delay: 10000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Erro na solicitação',
    messageTost:
      'Nenhuma oferta encontrada para o parceiro {supplier}. Tente novamente com outro parceiro.',
  },
  'configuration-error': {
    delay: 15000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Erro na solicitação',
    messageTost:
      'O parceiro {supplier} não está configurado para efetuar novas simulações.',
  },
};

interface Bid {
  supplier?: { internalName: string };
  alternateOffers?: [{ offerId: string }];
}

export interface SupplierType {
  name: string;
  internalName: string;
  inactive: boolean;
  newApplicationsDisabled: boolean;
  allowSimulation: boolean;
  branches: Record<string, Branch>;
  [key: string]: any;
}

interface Branch {
  channel: string;
  allowedProducts: string[];
  [key: string]: any;
}

export interface OptionsItem {
  code: string;
  description: string;
}

export interface ToastType {
  delay: number;
  autoHide: boolean;
  fzStyle: string;
  headerMessageTost: string;
  messageTost?: any;
  bodyMessageTost?: string;
}

export interface CustomSimulationType {
  [supplierName: string]: {
    appliedAmount: number;
    installments?: number;
    offeridslength: number;
    isRequesting: boolean;
    requestedAt: number;
  };
}

export interface AllowedSimulationSupplier {
  supplierInternalName: string;
  branches: string[];
}

export interface CustomSimulationRequest {
  appliedAmount: number;
  loanPeriodInMonths?: number;
}

export type StateType = (arg0: any) => void;