import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createContractPaidByRowId, downloadUnprocessedRows, getFilesRows } from '../../../../services/api/importFile'
import { FzCard } from '../../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../../ui/fz/grid/table'
import SplitLine from '../../../../components/split-line'
import { FzButton } from '../../../fz/form'
import fileDownload from 'js-file-download'
import { FzToast } from '../../../../ui/fz/form/notification/toast';

interface ParsedData {
  Key: string;
  Value: string | number;
}

interface DynamicData {
  created: string;
  fileId: string;
  id: string;
  status: string;
  parsed: ParsedData[];
  [key: string]: any;
}

const markPaid ={
  marcar_pago: {
    path: "marcar_pago",
    label: "Marcar Pago",
    tableProps: {
      hidden: false,
      width: "145px",
      dataSort: true,
      expandable: false,
    },
    isMandatory: () => false
  },
}

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Não foi possível marca pago e gerar contratos!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}


const InputLayoutListFiles = ({ ns }) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([])
  const [toastInfo, setToastInfo] = useState(toastInitialConfig);

  useEffect(() => {
    getData()
  }, [])

  const downloadCSV = async () => {
    const result = await downloadUnprocessedRows(id)
    if(result && result.data){
      fileDownload("\uFEFF" + result.data, "export.csv", 'text/csv');
    }
  }

  const buildMetaByHeaders = (headers) => {
    const buildMeta = headers.map((head, index) => ({
      [head.path]: {
        path: head.path,
        label: head.label,
        tableProps: { isKey: (index === 0) },
      }
    })).reduce((acc, next) => ({ ...acc, ...next }), {})
    return ({
      ...buildMeta,
      ...markPaid,
    })
  }

  const getData = async () => {
    try {
      const response: any = await getFilesRows(id)
      const rows: any = transformDataArray(response.data)

      setData(rows)

      const additionalFields = [
        { label: "status", path: "status" },
        { label: "updated field", path: "updated" },
      ];

      const transformedColumns = response.data[0].parsed.map((item: ParsedData) => {
        return {
          label: `p.${item.Key}`,
          path: `p.${item.Key}`,
        };
      });

      const columnsData: any = additionalFields.concat(transformedColumns);

      const bidFields = [
        { label: "bidId", path: "bidid" },
        { label: "applicationId", path: "applicationid" },
      ]
      const columnsDataAll: any = columnsData.concat(bidFields);

      setColumns(columnsDataAll);
    } catch (error) {
      setLoading(false)
    }
  }

  function transformDataArray(inputArray: DynamicData[]): any[] {
    return inputArray.map((input: DynamicData) => {
      const { created, fileId, id, parsed, ...otherProps } = input;
      const transformed: any = { created, fileId, id, status };

      parsed.forEach((item: ParsedData) => {
        transformed[`p.${item.Key}`] = item.Value;
      });

      for (const key in otherProps) {
        if (Object.prototype.hasOwnProperty.call(otherProps, key)) {
          transformed[key] = otherProps[key];
        }
      }
      return transformed;
    });
  }


  const handleToastClose = () =>
    setToastInfo(toastInitialConfig);

  const handleContractPaid = async (rowId) => {
    try {
      await createContractPaidByRowId(rowId)
      setToastInfo({ 
        ...toastInfo,
        fzStyle: "success",
        show: true,
        headerMessageTost: 'Marcação Finalizada!',
        bodyMessageTost: `Marcação de pago e contrado gerado com sucesso!`,
      });
      setLoading(false)
    } catch (error) {
      setToastInfo({ ...toastInfo, show: true });
      setLoading(false)       
    }
  }
  
  const createPayBtn = (index, row) => {
    return <FzButton
      customStyle={{
        borderColor: '#5923DD',
        color: '#fff',
        background: '#5923DD',
      }}
      size="small"
      fzStyle=""
      disabled={!row.status.includes("A3")}
      onClick={() =>  handleContractPaid(row.id)}
    >
      Marcar Pago
    </FzButton>
}

  return (
    <>
      {columns.length > 0 && (<div style={{ display: "flex", padding: "20px" }}>
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              <SplitLine>
                <SplitLine.Left>
                  Imported File Rows
                </SplitLine.Left>
              </SplitLine>
            </FzCard.Title>
          </FzCard.Header>
          <FzCard.Body>
            <FzButton
              customStyle={{
                borderColor: '#5923DD',
                color: '#fff',
                background: '#5923DD',
              }}
              size="small"
              fzStyle=""
              onClick={() =>  downloadCSV()}
            >
              Download
            </FzButton>
            <FzTable
              data={data}
              metas={buildMetaByHeaders(columns)}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              customColumnFormatters={{
                marcar_pago: (cell, row) => createPayBtn(cell, row),
              }}
            />
          </FzCard.Body>
        </FzCard>
        <FzToast {...toastInfo} close={handleToastClose} />
      </div>)}
    </>
  )
}

export default InputLayoutListFiles
