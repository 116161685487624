import React from 'react';
import PropTypes from 'prop-types';
import { Namespace } from '../scripts/state/redux_ns';
import { FzCard, FzRow, FzCol } from '../ui/fz/layout/index'
import FieldConnected from '../ui/components/form/field-connected';

const ApplicantSummary = ({ ns, persistChanges, data, ...props }) => {

  if (!props.readOnly && persistChanges === undefined) {
    console.error("Callback not supplied: making component readOnly")
    props.readOnly = true;
  }

  const meta = {
    "firstName": {
      "path": "firstName",
      "label": "Nome",
      "dataType": "string",
      "inputType": "text",
      "isMandatory": (application) => false,
      "isReadOnly": () => true
    },
    "lastName": {
      "path": "lastName",
      "label": "Sobrenome",
      "dataType": "string",
      "inputType": "text",
      "isMandatory": (application) => false,
      "isReadOnly": () => true
    },
    "birthDate": {
      "path": "birthDate",
      "label": "Data de Nascimento",
      "dataType": "date",
      "inputType": "date",
      "isMandatory": (application) => false,
      "isReadOnly": () => true
    },
    "personalNumber": {
      "path": "personalNumber",
      "label": "CPF",
      "dataType": "string",
      "inputType": "masked",
      "inputProps": {
        "mask": "111.111.111-11",
        "removeMask": true
      },
      "isMandatory": (application) => false,
      "isReadOnly": () => true
    },
  }

  return (
    <FzCard>
      <FzCard.Body>
        <FzRow>
          <FzCol><FieldConnected meta={meta.firstName} data={data} ns={ns} /></FzCol>
          <FzCol><FieldConnected meta={meta.lastName} data={data} ns={ns} /></FzCol>
        </FzRow>

        <FzRow>
          <FzCol><FieldConnected meta={meta.birthDate} data={data} ns={ns} /></FzCol>
          <FzCol><FieldConnected meta={meta.personalNumber} data={data} ns={ns} /></FzCol>
        </FzRow>
      </FzCard.Body>
    </FzCard>
  );
}

ApplicantSummary.propTypes = {
  persistChanges: PropTypes.func,
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.object.isRequired
};

export default ApplicantSummary;