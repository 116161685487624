
import { Meta, MetaField } from "../../types/metas";
import get from 'lodash/get';

export const supplierMetas: any = {
  getField(path: string): MetaField {
    return get(supplierMetas, path);
  },
  "id": {
    "path": "id",
    "label": "id",
    "section": "no-section",
    "dataType": "number",
    "inputType": "text",
    "inputProps": {
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": "14ch",
      "filter": undefined,
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": undefined,
      "isKey": true,
    },
    "isMandatory": (application) => false,
  },
  "name": {
    "path": "name",
    "label": "Nome",
    "section": "no-section",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": undefined,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "internalName": {
    "path": "internalName",
    "label": "Nome interno",
    "section": "no-section",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": undefined,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "email": {
    "path": "email",
    "label": "Email",
    "section": "no-section",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": undefined,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "websiteUrl": {
    "path": "websiteUrl",
    "label": "URL do Site",
    "section": "no-section",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": true,
      "shortLabel": "websiteUrl",
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "inactive": {
    "path": "inactive",
    "label": "Supplier inativo no sistema",
    "section": "no-section",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": "Status",
      "width": "8em",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "newApplicationsDisabled": {
    "path": "newApplicationsDisabled",
    "label": "Fechar para novas propostas",
    "section": "no-section",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "products": {
    "path": "products",
    "label": "Produtos aceitos pelo parceiro",
    "section": "no-section",
    "dataType": "string",
    "inputType": "multiselect",
    "isMandatory": (value) => false,
    "isReadOnly": () => true,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "maximumDistanceMeters": {
    "path": "maximumDistanceMeters",
    "label": "Distância máxima padrão para a filial",
    "section": "no-section",
    "dataType": "number",
    "inputType": "number",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "bidWeight": {
    "path": "bidWeight",
    "label": "Peso do Bid na Priorização",
    "section": "no-section",
    "dataType": "number",
    "inputType": "number",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "logoImageUrl": {
    "path": "logoImageUrl",
    "label": "URL do Logo (relativa ou absoluta)",
    "section": "no-section",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": "Logo",
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "reserveLead": {
    "path": "reserveLead",
    "label": "Reserve Lead",
    "section": "no-section",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": "true", "description": "Sim" },
        { "code": "false", "description": "Não" }
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "8ch",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "commissionRanges": {
    "path": "commissionRanges",
    "label": "Comissão (%)",
    "section": "no-section",
    "dataType": "string",
    "inputType": "commissionRanges",
    "inputProps": {},
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "8ch",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "locateBranchByGeography": {
    "path": "locateBranchByGeography",
    "label": "Utilizar Google Maps para encontrar Filiais (Loja)",
    "section": "no-section",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "8ch",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "defaultBranch": {
    "path": "defaultBranch",
    "label": "Branch Padrão",
    "section": "no-section",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {},
    "isMandatory": (value) => false,
  },
  "formalization.hasFormalization": {
    "path": "formalization.hasFormalization",
    "label": "Possui formalização Online",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.notifyBranchAcceptedBid": {
    "path": "formalization.notifyBranchAcceptedBid",
    "label": "Notificar loja aceite de oferta?",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.ccbPrint": {
    "path": "formalization.ccbPrint",
    "label": "Imprimir Contrato",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.ccbPages": {
    "path": "formalization.ccbPages",
    "label": "Quantidade de fotos para subir",
    "section": "formalization",
    "dataType": "number",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.ccbInstructions": {
    "path": "formalization.ccbInstructions",
    "label": "Instruções para upload do documento",
    "section": "formalization",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.enableDeleteSentDocuments": {
    "path": "formalization.enableDeleteSentDocuments",
    "label": "Permite exclusão no COF quando enviados",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.onlineValidation": {
    "path": "formalization.onlineValidation",
    "label": "Possui validação Online de documentos",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.onlineValidationEndpoint K0K)": {
    "path": "formalization.onlineValidationEndpoint K0K)",
    "label": "URL do agente de validação Online",
    "section": "formalization",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.ocrValidation": {
    "path": "formalization.ocrValidation",
    "label": "Possui reconhecimento de texto nos documentos? (OCR)",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.ocrValidationDocuments": {
    "path": "formalization.ocrValidationDocuments",
    "label": "Documentos processados pelo OCR",
    "newRow": true,
    "section": "formalization",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "domainName": "formalization-documents",

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.minOCRScore": {
    "path": "formalization.minOCRScore",
    "label": "minOCRScore",
    "section": "ocr",
    "dataType": "number",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.siblingMap": {
    "path": "formalization.siblingMap",
    "label": "siblingMap",
    "section": "ocr",
    "dataType": "string",
    "inputType": "text",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.formalizationDocuments": {
    "path": "formalization.formalizationDocuments",
    "label": "Documentos de formalização padrão",
    "section": "formalization",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "domainName": "formalization-documents",

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.fakeDocuments": {
    "path": "formalization.fakeDocuments",
    "label": "Documentos de formalização FAKE",
    "section": "formalization",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "domainName": "formalization-documents",

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "formalization.onlineValidationDocuments": {
    "path": "formalization.onlineValidationDocuments",
    "label": "Documentos validados Online",
    "section": "formalization",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "domainName": "formalization-documents",

      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "preventDownloadContract": {
    "path": "preventDownloadContract",
    "label": "Impedir download do contrato no site",
    "section": "formalization",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },

    "isMandatory": (value) => false,
  },
  "branchCode": {
    "path": "branchCode",
    "label": "Cód",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5em",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": true,
    },
    "isMandatory": (value) => false,
  },
  "branchNick": {
    "path": "branchNick",
    "label": "Nome",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "10em",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "city": {
    "path": "city",
    "label": "Cidade",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "12em",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "state": {
    "path": "state",
    "label": "UF",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "4em",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "neighbourhood": {
    "path": "neighbourhood",
    "label": "Bairro",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "12em",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "address": {
    "path": "address",
    "label": "Endereço",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "12em",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "streetNumber": {
    "path": "streetNumber",
    "label": "Número",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "10ch",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "complement": {
    "path": "complement",
    "label": "Complemento",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "12em",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "hasBranchAPICode": {
    "path": "hasBranchAPICode",
    "label": "API",
    "inputProps": {

      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "8ch",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": undefined,
      "columnFormat": undefined,
      "isKey": false,
    },
    "isMandatory": (value) => false,
  },
  "acceptedProducts": {
    "path": "acceptedProducts",
    "label": "Produtos aceitos pelo parceiro",
    "section": "only_table",
    "dataType": "string",
    "tableProps": {
      "shortLabel": "Produtos aceitos",
      "width": "9em",
      "columnFormat": "formatedAcceptedProducts",
      "filter": { "type": 'SelectFilter', "options": "buildFilterAcceptedProducts" },
    },
    "isMandatory": (value) => false,
  },
  "layoutProps.tags": {
    "path": "layoutProps.tags",
    "label": "Tags",
    "section": "no-section",
    "dataType": "string",
    "inputType": "dynamic-fields-list",
    "newRow": true,
    "inputProps": {
      
    },
    "isMandatory": (value) => false,
  },
  "layoutProps.bulletsIcons": {
    "path": "layoutProps.bulletsIcons",
    "label": "Elements",
    "section": "no-section",
    "dataType": "string",
    "inputType": "dynamic-fields-list-with-icons",
    "inputProps": {
      
    },
    "isMandatory": (value) => false,
  },
  "confirmationStepConfigs": {
    "path": "confirmationStepConfigs",
    "label": "Passos de Confirmação",
    "section": "no-section",
    "dataType": "string",
    "inputType": "confirmation-steps",
    "newRow": true,
    "inputProps": {},
    "isMandatory": (value) => false,
  }
}

export const supplierBranchesMetas: Meta = {
  ".branchNick": {
    "path": ".branchNick",
    "label": "Nome da Filial",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".channel": {
    "path": ".channel",
    "label": "Canal de integração principal",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": 'email', "description": "Email" },
        { "code": 'api', "description": "API" },
        { "code": 'cof', "description": "COF" },
        { "code": 'external', "description": "External" },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".agentName": {
    "path": ".agentName",
    "label": "Nome do Agente",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".alwaysSendEmail": {
    "path": ".alwaysSendEmail",
    "label": "Sempre Enviar Email",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".managerName": {
    "path": ".managerName",
    "label": "Gerente",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".mobilePhone": {
    "path": ".mobilePhone",
    "label": "Celular",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "mask": "(11) 1 1111 1111",
      "removeMask": true,
    },
    "isMandatory": (application) => false,
  },
  ".telephone": {
    "path": ".telephone",
    "label": "Telefone",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "mask": "(11) 1 1111 1111",
      "removeMask": true,
    },
    "isMandatory": (application) => false,
  },
  ".zipCode": {
    "path": ".zipCode",
    "label": "CEP da filial",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "autoComplete": "postal-code",
      "mask": "#####-###",
      "removeMask": true
    },
    "validationMessage": "Informe o CEP",
    "pattern": /^\d{5}-?\d{3}$/,
    "normalize": (value) => value.replace("-", ""),
    "isMandatory": (application) => false,
  },
  ".address": {
    "path": ".address",
    "label": "Endereço do Trabalho",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".streetNumber": {
    "path": ".streetNumber",
    "label": "Número do endereço",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".complement": {
    "path": ".complement",
    "label": "Complemento",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".neighbourhood": {
    "path": ".neighbourhood",
    "label": "Bairro",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".city": {
    "path": ".city",
    "label": "Cidade",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".state": {
    "path": ".state",
    "label": "Estado",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "domainName": "states",
    },
    "pattern": /^[A-Z]{2}$/,
    "placeholder": undefined,
    "isMandatory": (application) => false,
  },
  ".country": {
    "path": ".country",
    "label": "País",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".validZipCodes": {
    "path": ".validZipCodes",
    "label": "Faixas de CEP para filtragem",
    "dataType": "string",
    "inputType": "textarea",
    "tip": "Lista de CEPs, 5 dígitos, separador por :. Múltiplas faixas separadas por ','. Ex.: 01000:01999,03000:03999",
    "isMandatory": (application) => false,
  },
  ".latitude":{
    "path": ".latitude",
    "label": "Latitude da filial",
    "dataType": "number",
    "isMandatory": (application) => false,
  },
  ".longitude":{
    "path": ".longitude",
    "label": "Longitude da filial",
    "dataType": "number",
    "isMandatory": (application) => false,
  },
  ".validAreaCodes": {
    "path": ".validAreaCodes",
    "label": "DDDs para filtragem",
    "dataType": "string",
    "inputType": "text",
    "tip": "Códigos de área válidos, separados por ,",
    "isMandatory": (application) => false,
  },
  ".maximumDistanceMeters": {
    "path": ".maximumDistanceMeters",
    "label": 'Distância máxima para a filial (m)',
    "dataType": "number",
    "inputType": "number",
    "isMandatory": (application) => false,
  },
  ".commissionRanges": {
    "path": ".commissionRanges",
    "label": "Comissão (%)",
    "dataType": "string",
    "inputType": "commissionRanges",
    "inputProps": {},
    "isMandatory": (application) => false,
  },
  ".emails.All": {
    "path": ".emails.All",
    "label": "Email para demais produtos",
    "dataType": "string",
    "inputType": "text",
    "validationMessage": "Email inválido",
    "pattern": /^.{10,128}$/,
    "placeholder": "parceiro@exemplo.com.br",
    "isMandatory": (application) => false,
    "extraValidation": "email",
  },
  ".emails.CP": {
    "path": ".emails.CP",
    "label": "Email para Credito Pessoal",
    "dataType": "string",
    "inputType": "text",
    "validationMessage": "Email inválido",
    "pattern": /^.{10,128}$/,
    "placeholder": "parceiro@exemplo.com.br",
    "isMandatory": (application) => false,
    "extraValidation": "email",
  },
  ".emails.HE": {
    "path": ".emails.HE",
    "label": "Email para Refinanciamento de Imóvel",
    "dataType": "string",
    "inputType": "text",
    "validationMessage": "Email inválido",
    "pattern": /^.{10,128}$/,
    "placeholder": "parceiro@exemplo.com.br",
    "isMandatory": (application) => false,
    "extraValidation": "email",
  },
  ".emails.Refin": {
    "path": ".emails.Refin",
    "label": "Email para Refinanciamento de Veículos",
    "dataType": "string",
    "inputType": "text",
    "validationMessage": "Email inválido",
    "pattern": /^.{10,128}$/,
    "placeholder": "parceiro@exemplo.com.br",
    "isMandatory": (application) => false,
    "extraValidation": "email",
  },
  ".emails.Fin": {
    "path": ".emails.Fin",
    "label": "Email para Financiamento Veículo",
    "dataType": "string",
    "inputType": "text",
    "validationMessage": "Email inválido",
    "pattern": /^.{10,128}$/,
    "placeholder": "parceiro@exemplo.com.br",
    "isMandatory": (application) => false,
    "extraValidation": "email",
  },
  ".allowedProducts": {
    "path": ".acceptedProducts",
    "label": "Produtos aceitos pela filial",
    "dataType": "string",
    "inputType": "multiselect",
    "tip": "Deixe em branco se aceita todos os produtos do Banco ou liste os produtos, separdos por ','",
    "isMandatory": (application) => false,
  },
  ".branchAPICode": {
    "path": ".branchAPICode",
    "label": "Código da filial na API",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".validationSteps": {
    "path": ".validationSteps",
    "label": "Etapas de validação da formalização",
    "dataType": "string",
    "inputType": 'multiselect',
    "inputProps": {
      "domainName": "validation-steps",
      "options": undefined,
    },
    informationMessage: (value) => ("Ordem de validação: " + value),
    "isMandatory": (application) => false,
  },
  ".layoutProps.tags": {
    "path": "layoutProps.tags",
    "label": "Tags",
    "section": "no-section",
    "dataType": "string",
    "inputType": "dynamic-fields-list",
    "newRow": true,
    "inputProps": {
      
    },
    "isMandatory": (value) => false,
  },
  ".layoutProps.bulletsIcons": {
    "path": "layoutProps.bulletsIcons",
    "label": "Elements",
    "section": "no-section",
    "dataType": "string",
    "inputType": "dynamic-fields-list-with-icons",
    "inputProps": {
      
    },
    "isMandatory": (value) => false,
  },
  ".confirmationStepConfigs": {
    "path": "confirmationStepConfigs",
    "label": "Passos de Confirmação",
    "section": "no-section",
    "dataType": "string",
    "inputType": "confirmation-steps",
    "newRow": true,
    "inputProps": {},
    "isMandatory": (value) => false,
  },
  ".fakeBidLayout": {
    "path": ".fakeBidLayout",
    "label": "Layout de External Partner",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": 'default', "description": "Padrão (Completo)" },
        { "code": 'external-period', "description": "External com Periodo/Parcelas" },
        { "code": 'external-url', "description": "External sem valores (apenas URL)" },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".fakeBid.value": {
    "path": "fakeBid.value",
    "label": "FakeBid - value",
    "dataType": 'number',
    "inputType": 'number',
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": (data, code) => {
      const fakeBidLayout = data.branches[code].fakeBidLayout;
      if(fakeBidLayout === "default") {
        return true;
      }
      return false;
    }
  },
  ".fakeBid.installment": {
    "path": "fakeBid.installment",
    "label": "FakeBid - installment",
    "dataType": 'number',
    "inputType": 'number',
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": (data, code) => {
      const fakeBidLayout = data.branches[code].fakeBidLayout;
      if(fakeBidLayout === "default") {
        return true;
      }
      return false;
    }
  },
  ".fakeBid.interestRate": {
    "path": "fakeBid.interestRate",
    "label": "FakeBid - interestRate",
    "dataType": 'number',
    "inputType": 'number',
    "inputProps": {
      "decimalDigits": 2
    },
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": (data, code) => {
      const fakeBidLayout = data.branches[code].fakeBidLayout;
      if(fakeBidLayout === "external-period" || fakeBidLayout === "default") {
        return true;
      }
      return false;
    }
  },
  ".fakeBid.cet": {
    "path": "fakeBid.cet",
    "label": "FakeBid - cet",
    "dataType": 'number',
    "inputType": 'number',
    "inputProps": {
      "decimalDigits": 2
    },
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": (data, code) => {
      const fakeBidLayout = data.branches[code].fakeBidLayout;
      if(fakeBidLayout === "default") {
        return true;
      }
      return false;
    }
  },
  ".fakeBid.period": {
    "path": "fakeBid.period",
    "label": "FakeBid - period",
    "dataType": 'number',
    "inputType": 'number',
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": (data, code) => {
      const fakeBidLayout = data.branches[code].fakeBidLayout;
      if(fakeBidLayout === "external-period" || fakeBidLayout === "default") {
        return true;
      }
      return false;
    }
  },
  ".fakeBid.url": {
    "path": "fakeBid.url",
    "label": "FakeBid - url",
    "dataType": 'string',
    "inputType": 'text',
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": (data, code) => {
      const fakeBidLayout = data.branches[code].fakeBidLayout;
      if(fakeBidLayout === "external-period" || fakeBidLayout === "external-url" || fakeBidLayout === "default") {
        return true;
      }
      return false;
    }
  },
  ".fakeBid.buttonText": {
    "path": "fakeBid.buttonText",
    "label": "FakeBid - Button Text",
    "dataType": 'string',
    "inputType": 'text',
    "inputProps": {
      "decimalDigits": 2
    },
    "isMandatory": (application) => false,
    "cleanIfHidden": true,
    "isVisible": () => true,
  },
  ".showDraft": {
    "path": ".showDraft",
    "label": "Show Draft",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".allowTelephoneUpdate": {
    "path": ".allowTelephoneUpdate",
    "label": "Habilitar Edição de Telefone",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".bureauDocuments": {
    "path": "bureauDocuments",
    "label": "Documentos Bureau",
    "dataType": "string",
    "inputType": "multiselect",
    "inputProps": {
      "domainName": "formalization-documents",
      "mask": undefined,
      "removeMask": undefined
    },
    "isMandatory": (value) => false,
  },
}
