import React, { useState } from 'react'
import { StatsDetailHeader } from '../../../statistics/stats-components'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzNavSelect } from '../../../ui/fz/nav'
import { deletedReasonsMeta } from './metadata'
import { SummariesCustomerDeletedReportProps } from './types'

const SummariesCustomerDeletedReport = ({
  data,
  role,
  ns,
  handleSearchReport
}: SummariesCustomerDeletedReportProps) => {
  const [selectIn, setSelect] = useState("")

  function handleChangeSelect(period: string) {
    setSelect(period);
    handleSearchReport({ period })
  }

  return (
    <FzCard>
      <FzCard.Body>
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              <StatsDetailHeader
                stats={data}
                title="Razões de exclusão"
                periods={null}
                currPeriod={null}
                onChange={null}
              />
            </FzCard.Title>
          </FzCard.Header>
          <FzNavSelect
            fzStyle={{ marginLeft: 0 }}
            selected={selectIn}
            onChange={handleChangeSelect}
            options={[
              { "code": "day", "description": "Daily" },
              { "code": "week", "description": "Weekly" },
              { "code": "month", "description": "Monthly" },
            ]}
          />
          <div className={'mt-3'}>
            <FzTable
              ns={ns}
              metas={deletedReasonsMeta}
              data={data.statistics}
              exportDownload={role === 'admin'}
              clipboard={false}
              visibleColumns={[
                "_id",
                "period",
                "no-offers",
                "email-spam",
                "dont-want-expose-personal-data",
                "no-more-needed",
                "other-reasons",
                "updated",
              ]}
            />
          </div>
        </FzCard>
      </FzCard.Body>
    </FzCard>
  )
}

export { SummariesCustomerDeletedReport }
