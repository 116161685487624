
import React from 'react'
import moment from 'moment'
import { FormGroup, Button } from 'react-bootstrap'
import { CloseAppReasonStatus } from '../application-components/input-domains'
import ActionChangeProduct from './action-product'
import { APPLICATION_STATE } from '../business/application';
import api from '../api/client-api';
import { sendPreAuthLink } from "../api/auth";
import { EditTelephoneEmail } from './edit-email';
import { StaticDomains } from '../api/client-api';
import { FzCard } from '../ui/fz/layout/index'
import { FzForm } from '../ui/fz/layout/index'
import { FzButton } from '../ui/fz/form';
import { ExcludeCustomers } from '../mis-applications/move-application-status'
import { deleteCustomer } from '../api/users'
import { extendAuctionEndDate } from '../api/applications'
import { Modal } from 'react-bootstrap'
import copy from 'copy-to-clipboard';
import { ViewPayed } from '../ui/blocks/crm/view-payed'
import { DeleteCustomerModal } from '../ui/blocks/application-components/delete-customer'
import {getByID} from '../api/applications';

const VIEW_BUTTONS = 'buttons'
const VIEW_COUDNT_TALK = 'coudnttalk'
const VIEW_CHANGE_PRODUCT = 'product'
const VIEW_CLOSE_APP = 'closeapp'
const VIEW_PAYED = 'payed'
const VIEW_EDIT_USER = 'edituser'
const VIEW_DELETE_CUSTOMER = 'excluid_customer'

const APP_ACTION_REPORT_PAIED = 'report-paid'
const APP_ACTION_PREAUTH_LINK = 'send-preauth-link'
const APP_ACTION_CROSSSELL_TO_REFIN = 'crossseling-to-refin'
const APP_ACTION_EDIT_EMAIL = 'edit-email-telephone'
const APP_ACTION_START_AUCTION = 'start-auction'
const APP_ACTION_CLOSE_APPLICATION = 'close-application'
const VIEW_TEMPLATE_HE = 'template-HE'

class SalesApplicationActions extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      view: VIEW_BUTTONS,
      closeReason: '',
      payedReason: '',
      paidDate: '',
      paidAmount: 0.,
      postPoneDatetime: null,
      suppliers: null,
      showExcludeApp: false,
      templeteHE: false,
      requestState: "idle",
    }
    this.basePath = this.props.basePath || "/sales/out"
    this.sendPreAuthLink = this.sendPreAuthLink.bind(this);
    this.confirmExcludeCustomer = this.confirmExcludeCustomer.bind(this)
    this.closeStateModals = this.closeStateModals.bind(this)
    this.extendAuctionEndDate = this.extendAuctionEndDate.bind(this)
    this.changeViewTemplateHE = this.changeViewTemplateHE.bind(this)
    this.showTemplateHe = this.showTemplateHe.bind(this)
    this.buildTemplateToHe = this.buildTemplateToHe.bind(this)
  }

  async getApplictionById() {
    try {
      const application = await getByID(this.props.app.id)
      this.props.ns.set("application", application);
    } catch (error) {
      console.error(error)
    }
  }

  startAuction = () => {
    if (this.props.validateStartAuction()) {
      api.startAuction(
        this.props.app.id,
        this.props.app.revision,
        (err) => {
          if (!err) {
            let treatment = this.props.ns.get("crm.treatments." + this.props.app.id) ? this.props.ns.get("crm.treatments." + this.props.app.id) + " * Completar Cadastro" : "Completar Cadastro";
            let treatmentArray = this.props.ns.get("crm.treatmentsArray." + this.props.app.id) ? this.props.ns.get("crm.treatmentsArray." + this.props.app.id) : [];
            treatmentArray.push(APP_ACTION_START_AUCTION);

            this.props.ns.set("crm.treatments." + this.props.app.id, treatment);
            this.props.ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);
            this.getApplictionById()
            alert("Cotação iniciada")
          }
          else
          alert("Error ao iniciar cotação!")
        }
      )
    }
  }

  performCrossover = () => {
    api.createChildApplication(
      this.props.app.id,
      this.props.app.trackingData,
      (response) => {
        if (!response.error && response.status < 400) {
          this.props.ns.unset("application");
          const { ns } = this.props;

          let treatment = ns.get("crm.treatments." + this.props.app.id) ? ns.get("crm.treatments." + this.props.app.id) + " * Proposta Refin criada" : "Proposta Refin criada";
          let treatmentArray = ns.get("crm.treatmentsArray." + this.props.app.id) ? ns.get("crm.treatmentsArray." + this.props.app.id) : [];
          treatmentArray.push(APP_ACTION_CROSSSELL_TO_REFIN);

          ns.set("crm.treatments." + this.props.app.id, treatment);
          ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);

          this.props.history.push("/applications/" + response.body.id)
        } else {
          alert("Condição inválida ao criar uma proposta Refin (resposta do servidor): " + response.error)
        }
      }
    )
  }

  confirmEditUser = () => {

    const { ns } = this.props;
    let treatment = ns.get("crm.treatments." + this.props.app.id) ? ns.get("crm.treatments." + this.props.app.id) + " * E-mail/Telefone editado" : "E-mail/Telefone editado";
    let treatmentArray = ns.get("crm.treatmentsArray." + this.props.app.id) ? ns.get("crm.treatmentsArray." + this.props.app.id) : [];
    treatmentArray.push(APP_ACTION_EDIT_EMAIL);

    ns.set("crm.treatments." + this.props.app.id, treatment);
    ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);

    this.props.ns.unset("application")
    alert("E-mail/Telefone editado")
  }

  postponeContact = () => {

    if (this.isviewPostponeValid()) {
      api.appPostpone(
        this.props.app.id,
        this.props.app.revision,
        moment(this.state.postPoneDatetime).format(),
        (err) => {
          if (!err) {
            this.props.ns.unset("application")
            this.props.history.push(this.basePath)
          }
          else
            console.log(err)
        }
      )
    }
  }

  closeApplication = () => {
    if (this.isViewCloseAppValid()) {
      api.closeLead(
        this.props.app,
        this.state.closeReason,
        () => {
          const { ns } = this.props;

          let treatment = ns.get("crm.treatments." + this.props.app.id) ? ns.get("crm.treatments." + this.props.app.id) + " * " + StaticDomains.getCloseReasonDescription(this.state.closeReason) : StaticDomains.getCloseReasonDescription(this.state.closeReason);
          let treatmentArray = ns.get("crm.treatmentsArray." + this.props.app.id) ? ns.get("crm.treatmentsArray." + this.props.app.id) : [];
          treatmentArray.push(APP_ACTION_CLOSE_APPLICATION);

          ns.set("crm.treatments." + this.props.app.id, treatment);
          ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);
          alert("Proposta encerrada")
        },
        (err) => {
          if (err) {
            console.log(err)
          }
        }
      )
      this.props.ns.unset("application")
    }
  }

  changeViewToButtons = () => {
    this.setState({ view: VIEW_BUTTONS })
  }

  changeViewToExcluidCustomer = () => {
    const { app } = this.props


    if (!app) {
      console.warn("Erro ao excluir uma proposta", app)
      return null
    }

    this.setState({ view: VIEW_DELETE_CUSTOMER, showExcludeApp: true })
  }


  changeViewTemplateHE() {
    this.setState({ view: VIEW_TEMPLATE_HE })
  }

  changeViewToEditUserData = () => {
    this.setState({ view: VIEW_EDIT_USER })
  }

  changeViewToCouldnTalk = () => {
    this.setState({ view: VIEW_COUDNT_TALK })
  }

  changeViewChangeProduct = () => {
    this.setState({ view: VIEW_CHANGE_PRODUCT })
  }

  changeViewToCloseApp = () => {
    this.setState({ view: VIEW_CLOSE_APP })
  }

  changeViewToPayed = () => {
    this.setState({ view: VIEW_PAYED })
  }

  handleChangeCloseReason = (value) => {
    this.setState({ closeReason: value })
  }

  handleChangePayedReason = (value) => {
    this.setState({ payedReason: value })
  }

  handleChangePaidDate = (value) => {
    this.setState({ paidDate: value })
  }

  handleChangePaidAmount = (value) => {
    this.setState({ paidAmount: value })
  }

  handleChangePostponeDatetime = (value) => {
    this.setState({ postPoneDatetime: value })
  }

  isViewCloseAppValid = () => {
    return this.state.closeReason != null
      && this.state.closeReason.length >= 1
  }

  sendPreAuthLink() {
    const { ns } = this.props;
    let custId = ns.get("application.customerId") ? ns.get("application.customerId") : this.props.app.customerId;
    
    if (!custId || custId == undefined || custId.length <= 0) {
      custId = this.props.app.customerId;
    }
    
    let appId = ns.get("application.id") ? ns.get("application.id") : this.props.app.id;

    return sendPreAuthLink(custId, appId)
      .then(() => {
        alert("Link enviado para o celular do usuário");
        let treatment = ns.get("crm.treatments." + appId) ? ns.get("crm.treatments." + appId) + " * Link logado enviado" : "Link logado enviado";
        let treatmentArray = ns.get("crm.treatmentsArray." + appId) ? ns.get("crm.treatmentsArray." + appId) : [];
        treatmentArray.push(APP_ACTION_PREAUTH_LINK);

        ns.set("crm.treatments." + appId, treatment);
        ns.set("crm.treatmentsArray." + appId, treatmentArray);
      })
      .catch((e) => alert("ERRO: ", e))
  }

  isViewPayedAppValid = () => {
    return this.state.payedReason != null
      && this.state.payedReason.length >= 1
  }

  isviewPostponeValid = () => {
    var d = new Date(this.state.postPoneDatetime)
    var e = moment(this.state.postPoneDatetime)
    var f = moment(new Date()).add(2, 'days')
    var g = moment(new Date())
    return this.state.postPoneDatetime != null
      && this.state.postPoneDatetime.length > 0
      && !isNaN(d.getTime())
      && e <= f
      && e > g
  }

  handleChangeEmail = (value) => {
    this.setState({ newEmail: value })
  }

  handleChangeTelephone = (value) => {
    this.setState({ newTelephone: value })
  }

  isviewEditUserDataValid = () => {
    return (this.state.newEmail != null
      && this.state.newEmail.length > 0) ||
      (this.state.newTelephone != null
        && this.state.newTelephone.length > 0)
  }

  confirmExcludeCustomer() {
    const customerId = this.props.app.customerId
    deleteCustomer(customerId)
      .then(result => this.props.history.push("/applications/"))
      .catch((err) => console.warn("deu ruim ", err))
  }

  closeStateModals() {
    this.setState({ view: VIEW_BUTTONS, showExcludeApp: false, })
  }

  async extendAuctionEndDate() {
    const { app, ns } = this.props
    if (app.id) {
      try {
        const resultApplication = await extendAuctionEndDate(app.id, app)
        ns.set("application", resultApplication)
      }
      catch (e) {
        console.warn("Erro ao tentar prorrogar auction", e)
      }
    }
  }

  async showTemplateHe() {
    const { ns } = this.props
    await this.buildTemplateToHe(ns);
    const template = ns.get("template")
    copy(template, { format: "text/plain" })
    this.setState({ templeteHE: false, view: VIEW_BUTTONS })
  }


  async buildTemplateToHe(ns) {
    this.setState({ requestState: "requesting" }, async function () {
      const { app } = this.props

      try {
        const resultPropertyType = await api.domains.getDomainValues("property-type")
        const resultLoanReason = await api.domains.getDomainValues("loan-reason")

        const homeSecurityPropertyType = (resultPropertyType.find(it => app.homeSecurity && it.code === this.props.app.homeSecurity.propertyType) || { description: "n/a" }).description;
        const reason = (resultLoanReason.find(it => it.code === app.loanReason) || { description: "n/a" }).description

        const consdicao = { false: "está", true: "não está" }
        const homesecurityHasExistingLoans = consdicao[this.props.app.homeSecurity.hasExistingLoans]
        const user = api.getCurrentUser()

        const data = `Olá! ${app.firstName}, tudo bem? Meu nome é ${user.name}, sou da FinanZero.
          Meu contato com você é referente ao seu pedido de crédito com garantia de imóvel.

          Poderia me confirmar estas informações que você inseriu no site por favor? Assim poderemos fazer a simulação e enviar no seu e-mail: ${this.props.app.email}.
          - O valor do seu empréstimo é de ${(app.appliedAmount || "n/a").toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} em ${this.props.app.loanPeriodInMonths} meses e o seu imóvel vale ${(this.props.app.homeSecurity.value || "n/a").toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, é do tipo ${homeSecurityPropertyType} e ${homesecurityHasExistingLoans} quitado.
          - O motivo do empréstimo é ${reason}, a sua renda mensal é de ${(app.householdMonthlyIncome || "n/a").toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}.
          - O seu imóvel ${app.homeSecurity.propertyRegistered === false ? "não" : ""} está averbado (com matrícula e IPTU).`;

        ns.set("template", data.replace(/^ +/mg, ""))
        this.setState({ requestState: "idle" });
      } catch (error) {
        console.error(error)
        this.setState({ requestState: "idle" });
      }
    })
  }

  render() {
    let isAdvisor = api.isUserInRole("advisor");
    let isSales = api.isUserInRole("sales");

    let template = this.props.ns.get("template")
    let { requestState } = this.state;

    if (!template && requestState !== "requesting" && this.props.app.product === "HE" && this.props.app.homeSecurity) {
      this.buildTemplateToHe(this.props.ns)
      return null
    }

    let appIsClosedOrPaid = this.props.app.applicationState === APPLICATION_STATE.Closed || this.props.app.applicationState === APPLICATION_STATE.Paid
    let appIsExcluded = this.props.app.applicationState === APPLICATION_STATE.Excluded
    const hideButtons = this.props.hideButtons || {}
    if (appIsExcluded) {
      return null
    }

    let btnEditUserData
    if (hideButtons.editPhone) {
      btnEditUserData = <FzButton block fzStyle="regular" disabled={this.props.ns.isEditing()} onClick={this.changeViewToEditUserData} >Editar E-mail/Telefone</FzButton>
    }

    let btnStartAuction
    if (this.props.app.applicationState === APPLICATION_STATE.Lead && (isSales || isAdvisor)) {
      btnStartAuction = <FzButton block fzStyle="regular" disabled={this.props.ns.isEditing()} onClick={this.startAuction} >Iniciar Cotações</FzButton>
    }

    let btnPerformCrossover
    if ((this.props.app.serasaScore === -1 || this.props.app.serasaScore > 600) && (this.props.app.appliedAmount >= 5000) && (this.props.app.ownsVehicle === true) && (isSales || isAdvisor) && this.props.app.product === "CP") {
      btnPerformCrossover = <FzButton block fzStyle="attention" disabled={this.props.ns.isEditing()} onClick={this.performCrossover} >Criar Proposta Refin</FzButton>
    }

    let btnClose
    if ((isSales && this.props.app.applicationState === APPLICATION_STATE.Lead) || (!isSales && !appIsClosedOrPaid)) {
      btnClose = <FzButton block fzStyle="alert" disabled={this.props.ns.isEditing()} onClick={this.changeViewToCloseApp}>Encerrar Pedido</FzButton>
    }
    let btnPayed
    if (!isSales) {
      btnPayed = <FzButton block fzStyle="regular" disabled={this.props.ns.isEditing()} onClick={this.changeViewToPayed} >Pago</FzButton>
    }

    let btnChangeProduct
    if (this.props.app.applicationState === APPLICATION_STATE.Lead) {
      btnChangeProduct = <FzButton block fzStyle="attention" disabled={this.props.ns.isEditing() || appIsClosedOrPaid} onClick={this.changeViewChangeProduct}>Trocar produto</FzButton>
    }

    let btnSendPreAuthLink = <FzButton block fzStyle="regular" disabled={!api.isUserInRole("advisor") || !this.props.app.customerId} onClick={this.sendPreAuthLink}>Enviar link logado</FzButton>

    let btnExtendAuctionEndDate
    if (this.props.app.product === "HE") {
      btnExtendAuctionEndDate = <FzButton block fzStyle="regular" disabled={!api.isUserInRole("advisor")} onClick={this.extendAuctionEndDate}>Prorrogar proposta 20 DIAS</FzButton>
    }

    let btnCopyTemplate
    if (this.props.app.product === "HE" && this.props.app.homeSecurity) {
      btnCopyTemplate = <FzButton block fzStyle="regular" disabled={!(api.isUserInRole("advisor") || api.isUserInRole("admin"))} onClick={this.changeViewTemplateHE}>Resumo proposta HE (copy)</FzButton>
    }

    let btnDelete
    if (api.isUserInRole("admin")) {
      let btnDeleteEnabled = this.props.app.applicationState === APPLICATION_STATE.Paid ? api.isSuperUser() : api.isUserInRole("admin");
      btnDelete = <FzButton block fzStyle="alert" disabled={!btnDeleteEnabled} onClick={this.changeViewToExcluidCustomer}>Excluir Proposta</FzButton>
    }

    var viewDeleteCustomer = (
      <>
        {this.props.app.applicationState == APPLICATION_STATE.Paid && api.isUserInRole('admin') && api.isSuperUser() ? (
          <DeleteCustomerModal
            ns={this.props.ns}
            history={this.props.history}
            closeStateModals={this.closeStateModals}
          />
        ) : (
          <ExcludeCustomers
            show={this.state.showExcludeApp}
            usersToExclude={[this.props.app]}
            closeButton={this.closeStateModals}
            saveButton={this.confirmExcludeCustomer}
          />
        )}
      </>
    );


    var viewTemplateHE = (
      <Modal style={{ marginTop: "50px" }} bsSize="large" show={!this.state.templeteHE} onHide={this.showTemplateHe}>
        <Modal.Header closeButton>
          <Modal.Title> Resumo da proposta de HE
            <Button bsSize="xsmall" onClick={() => {
              this.showTemplateHe()
            }}>
              Copy
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div><pre>
            {template}
          </pre></div>
        </Modal.Body>
      </Modal>
    )


    var viewButtons = (
      <FzCard>
        <FzCard.Body>
          <div className="vertical-button-list">
            {btnSendPreAuthLink}
            {btnEditUserData}
            {btnPerformCrossover}
            {btnStartAuction}
            {btnPayed}
            {btnChangeProduct}
            {btnClose}
            {btnExtendAuctionEndDate}
            {btnCopyTemplate}
            {btnDelete}
          </div>
        </FzCard.Body>
      </FzCard>
    )

    var viewEditUserData = (
      <EditTelephoneEmail

        showTost={this.props.showTost}
        setShowTost={this.props.setShowTost}

        toastType={this.props.toastType}
        setToastType={this.props.setToastType}

        toastMessage={this.props.toastMessage}
        setToastMessage={this.props.setToastMessage}

        app={this.props.app}
        onSave={this.confirmEditUser}
        onCancel={this.changeViewToButtons}
      />
    )

    var viewCloseApp = (
      <FzCard bsStyle="warning">
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Encerrar Pedido</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FormGroup>
            <FzForm.Label>Motivo</FzForm.Label>
            <CloseAppReasonStatus
              name="closeAppReasonStatus"
              disabled={false}
              value={this.state.closeReason}
              onChange={this.handleChangeCloseReason}
            />
          </FormGroup>
          <FormGroup>
            <Button onClick={this.changeViewToButtons}>Voltar</Button>
            <Button
              bsStyle="warning"
              onClick={this.closeApplication}
              disabled={!this.isViewCloseAppValid()}>
              Encerrar Pedido
            </Button>
          </FormGroup>
        </FzCard.Body>
      </FzCard>
    )


    let viewPayed = (<div>{"Carregando ..."}</div>)
    if (this.state.view === 'payed') {
      let newNs = this.props.ns.copy("viewToPayed")
      const dataForViewPayed = newNs.getChanged()

      viewPayed = (<ViewPayed
        dataForViewPayed={dataForViewPayed}
        nsViewPayed={newNs}
        changeViewToButtons={this.changeViewToButtons}
        ns={this.props.ns}
        app={this.props.app}
        APP_ACTION_REPORT_PAIED={APP_ACTION_REPORT_PAIED}
      />)
    }


    var view = null

    switch (this.state.view) {
      case VIEW_BUTTONS:
        view = viewButtons
        break
      case VIEW_EDIT_USER:
        view = viewEditUserData
        break
      case VIEW_CLOSE_APP:
        view = viewCloseApp
        break
      case VIEW_PAYED:
        view = viewPayed
        break
      case VIEW_DELETE_CUSTOMER:
        view = viewDeleteCustomer
        break

      case VIEW_TEMPLATE_HE:
        view = viewTemplateHE
        break

      case VIEW_CHANGE_PRODUCT:
        view = <ActionChangeProduct
          app={this.props.app}
          ns={this.props.ns}
          api={api}
          dismissAction={this.changeViewToButtons}
        />
        break
      default:
        view = viewButtons
        break
    }

    return view
  }
}

export { SalesApplicationActions }
