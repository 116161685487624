import React from 'react';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { formatPeriod } from './dynamic-table/columns-formatters';
import { Link } from 'react-router-dom';
import api from '../api/client-api';
import {
  APPLICATION_STATE,
  getApplicationState,
  statesDescriptions,
} from '../business/application';
import { StaticDomains } from '../api/client-api';
import { formatPersonalNumber } from './formatters';
import { getDomain } from '../scripts/utils/cache-helpers';
import { FzIcons } from '../ui/fz/form/icon';
import get from 'lodash/get';
import { AssistantSharp } from '@material-ui/icons';

export const TableOptions = {
  sizePerPageList: [
    {
      text: '20',
      value: 20,
    },
    {
      text: '50',
      value: 50,
    },
    {
      text: '100',
      value: 100,
    },
  ], // you can change the dropdown list for size per page
  sizePerPage: 50, // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 4, // the pagination bar size.
  prePage: 'Prev', // Previous page button text
  nextPage: 'Next', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: (start, to, total) => {
    return 'Showing ' + start + ' to ' + to + ' of ' + total;
  },
  // hideSizePerPage: true > You can hide the dropdown for sizePerPage
};

export const _getExtraData = (formatExtraData, param, acceptString, defaultValue) => {
  if (
    typeof formatExtraData === 'object' &&
    typeof formatExtraData[param] !== 'undefined'
  ) {
    return formatExtraData[param];
  }
  if (acceptString && typeof formatExtraData === 'string') {
    return formatExtraData;
  }
  return defaultValue;
};

export const TableOptionsPT = {
  sizePerPageList: [
    {
      text: '10',
      value: 10,
    },
    {
      text: '20',
      value: 20,
    },
    {
      text: '50',
      value: 50,
    },
  ], // you can change the dropdown list for size per page
  sizePerPage: 20, // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 4, // the pagination bar size.
  prePage: 'Ant', // Previous page button text
  nextPage: 'Prox', // Next page button text
  firstPage: 'Primeira', // First page button text
  lastPage: 'Última', // Last page button text
  paginationShowsTotal: true, // Accept bool or function
  // hideSizePerPage: true > You can hide the dropdown for sizePerPage
};

export const dateColumn = (cell, row) => {
  if (cell) {
    return moment(cell).format('ll');
  } else {
    return null;
  }
};

export const dateSummaryColumn = (cell, row) => {
  if (cell) {
    return moment(cell).format('DD/MM/YYYY');
  } else {
    return null;
  }
};

export const applicationIdColumn = (cell, row, formatExtraData) => {
  if (api.isUserSupplier()) {
    return cell;
  }
  let appId = cell;
  if (!appId) {
    return null
  }
  let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
  url += appId;
  let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
    </a>
  ) : null;
  return (
    <div>
      {newTab}&nbsp;
      <Link to={url} title={cell}>
        {'...' + cell.slice(-6)}
      </Link>
    </div>
  );
};

export const dateTimeColumn = (cell, row) => {
  if (cell && cell.substring(0, 10) !== '0001-01-01') {
    return moment(cell).format('DD/MM, HH:mm:ss');
  } else {
    return null;
  }
};
export const timeColumn = (cell, row) => {
  if (cell) {
    return moment(cell).format('HH:mm:ss');
  } else {
    return null;
  }
};

export const urlColumn = (cell, row) => {
  if (cell) {
    return (
      <a href={cell} target="_blank" rel="noopener noreferrer">
        {cell}
      </a>
    );
  } else {
    return null;
  }
};

export const customLink = (url, text) => {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  } else {
    return null;
  }
}

export const amountColumn = (cell, row) => {
  if (!cell) {
    return '';
  }
  return <FormattedNumber value={cell} maximumFractionDigits={2} />;
};
export const numberColumn = amountColumn;

export const appliedAmountColumn = (_, row) => {
  if (!row) {
    return '';
  }
  return (
    <FormattedNumber
      value={
        row['appliedAmount'] === 0
          ? row['vehicleSecurity']['value'] -
          row['vehicleSecurity']['downPayment']
          : row['appliedAmount']
      }
      maximumFractionDigits={2}
    />
  );
};
export const cityColumn = (_, row) => {
  return <React.Fragment>{row.city + '/' + row.state}</React.Fragment>;
};
export const customerColumn = (_, row) => {
  return (
    <span>
      <b>
        {row.firstName} {row.lastName}
      </b>
      <br />
      {formatPersonalNumber(row.personalNumber)}
    </span>
  );
};

export const bankColumn = (bank, app, formatExtraData) => {
  const ns = _getExtraData(formatExtraData, 'ns');
  const account =
    getDomain(ns, 'account-type', app.accountType) || app.accountType;
  return (
    <span>
      {account}: {bank} - <b>{getDomain(ns, 'bank-name', bank)}</b>
      <br />
      Agência/Conta: {app.agency} / {app.accountNumber}
    </span>
  );
};

// Format in percent.
//  If formatExtraData is a string, a percentage rate is calculated (cell/row[formatExtraData]).
//  If formatExtraData is an object, {total:"column"} point to total column base value
//  If none is provided, a simple percent format is performed
export const pctColumn = (cell, row, formatExtraData) => {
  if (!cell) {
    return '0%';
  }
  let v = Number(cell);
  const totalCol = _getExtraData(formatExtraData, 'total', true);
  if (totalCol) {
    let t = Number(get(row, totalCol, null));
    if (t > 0) {
      v /= t;
    } else {
      v = 0;
    }
  }

  return (
    <FormattedNumber style={'percent'} maximumFractionDigits={1} value={v} />
  );
};

export const applicationStateColumn = (cell, row) => {
  let st = getApplicationState(cell);

  if ('closedReason' in row && !api.isUserInRole('franchisee')) {
    if (cell === APPLICATION_STATE.Closed || cell === APPLICATION_STATE.Paid) {
      st = (
        <span>
          {st}
          <br />
          {StaticDomains.getCloseReasonDescription(row['closedReason'])}
        </span>
      );
    }
  }
  return st;
};

export const periodColumn = formatPeriod;

export const booleanColumn = (cell, row) => {
  return cell ? 'Sim' : 'Não';
};

export const booleanColumn_en = (cell, row) => {
  return cell ? 'Yes' : 'No';
};

export const booleanColumn_approved_rejected = (cell, row) => {
  return cell ? 'Aprovado' : 'Recusado';
};

export const buildPeriodFilters = statisticsData => {
  let periods = {};
  if (statisticsData) {
    for (var i = 0; i < statisticsData.length; ++i) {
      let p = statisticsData[i].period;
      if (p && !periods[p]) {
        periods[p] = periodColumn(p);
      } else {
        if (statisticsData[i]._id && statisticsData[i]._id.period) {
          p = statisticsData[i]._id.period;
          periods[p] = periodColumn(p);
        }
      }
    }
  }
  return periods;
};

export const buildColumnApplicationState = (data, column) => {
  let filterValues = {};
  if (data) {
    for (var i = 0; i < data.length; ++i) {
      let p = data[i][column];
      if (p && !filterValues[p]) {
        filterValues[p] = statesDescriptions[p];
      }
    }
  }
  return filterValues;
}

export const buildColumnValuesFilter = (data, column) => {
  let filterValues = {};
  if (data) {
    for (var i = 0; i < data.length; ++i) {
      let p = data[i][column];
      if (p && !filterValues[p]) {
        filterValues[p] = p;
      }
    }
  }
  return filterValues;
};

export const buildSupplierFilters = (statisticsData, path = "supplier") => {
  let suppliers = {};
  for (var i = 0; i < statisticsData.length; ++i) {
    let s = get(statisticsData[i], path);
    if (s && !suppliers[s]) {
      suppliers[s] = s;
    }
  }
  return suppliers;
};

export const buildStatusDocumentsFilters = (statisticsData, path = "status") => {
  let documents = {};
  for (var i = 0; i < statisticsData.length; ++i) {
    let s = get(statisticsData[i], path);
    if (s && !documents[s]) {
      documents[s] = s;
    }
  }
  return documents;
};

export const buildEventNamesFilters = statisticsData => {
  return statisticsData.reduce((eventNames, object) => {
    if (!eventNames[object.eventName]) {
      eventNames = { ...eventNames, [object.eventName]: object.eventName }
    }
    return eventNames
  }, {})
};

export const buildProductFilters = statisticsData => {
  let product = {};
  for (var i = 0; i < statisticsData.length; ++i) {
    let s = statisticsData[i].product;
    if (s && !product[s]) {
      product[s] = s;
    }
  }
  return product;
};

export const filters = {
  boolean: { true: 'Sim', false: 'Não' },
  boolean_en: { true: 'Yes', false: 'No' },
  boolean_approved_rejected: { true: 'Aprovado', false: 'Recusado' },
  disabled: { true: 'Desabilitado', false: 'Habilitado' },
  product: {
    CP: 'CP',
    Refin: 'Refin',
    Fin: 'Fin',
    HE: 'HE',
    Cartao: "Cartão",
    Consorcio: "Consórcio"
  },
};

export const arrayJoinColumun = (cell, row) => {
  return cell.join(",")
};
