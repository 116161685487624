import { Modal } from "react-bootstrap"
import { FzButton } from "../../../ui/fz/form"
import { ShowValidationStep } from "../../../ui/blocks/screening/show-validation-step"

const ModalDocumentsPendency = ({ modalOpen, closeModal, validationStepsHistory, ns }) => {
  const historySteps = validationStepsHistory && validationStepsHistory.reduce((acc, cur) => {
    acc = Array.isArray(cur.validationSteps) ? acc.concat(cur.validationSteps) : []
    return acc
  }, []).sort(function (a, b) {
    return +new Date(b.sent) - +new Date(a.sent);
  });

  return (
    <div style={{padding: "20px"}}>
        <Modal size="lg" centered show={modalOpen}>
          <Modal.Body>
            <div>
              Histórico de Avaliações
            </div>
            <div>
              {historySteps && historySteps.map((step, index) => {
                return (
                  <>
                    <ShowValidationStep
                      key={step.name + index}
                      name={step.name}
                      sent={step.sent}
                      outcome={step.outcome}
                      documentsOutcomes={step.documentsOutcomes}
                      ns={ns}
                    />
                  </>
                )
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{"alignContent":"center","textAlign":"center","margin":"auto"}}>
              <FzButton 
                fzStyle='discrete' 
                onClick={closeModal}
              >
                Fechar
              </FzButton>
            </div>
          </Modal.Footer>
        </Modal>
    </div>
  )
}

export { ModalDocumentsPendency }