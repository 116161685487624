import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap'
import useToast from '../../../../hooks/useToast';
import { ToastContext } from "../../../../hooks/ToastProvider";

const toastStyles = {
  'warning': '#B4CC44',
  'danger': '#d9534f',
  'success': '#05BCB4'
};

interface FzToastProps {
  headerMessageTost?: any;
  bodyMessageTost?: any;
  close?: any; 
  show?: any;
  delay?: any; 
  fzStyle?: any; 
  autoHide?:any;
}

export function FzToast({ headerMessageTost, bodyMessageTost, close, show, delay, fzStyle, autoHide }: FzToastProps) {
  // const {body, header, handleClose} = useToast()
  const {
    isOpen: contextIsOpen,
    setIsOpen: contextSetIsOpen,
    header: contextHeader,
    body: contextBody,
    delay: contextDelay,
    level: contextLevel,
    autoHide: contextAutoHide
  } = React.useContext(ToastContext)

  return (
    <Toast
      style={{
        position: 'fixed',
        top: '80px',
        right: 0,
        marginRight: '1rem',
        border: `3px solid ${toastStyles[fzStyle || contextLevel]}`,
        borderRadius: '10px',
        fontSize: '1.2rem',
        padding: '20px',
        zIndex: 100
      }}
      onClose={() => close ? close() : contextSetIsOpen(false)}
      show={close ? show : contextIsOpen}
      delay={delay || contextDelay}
      autohide={autoHide || contextAutoHide}
    >
      <Toast.Header><strong className="mr-auto">{headerMessageTost || contextHeader}</strong></Toast.Header>
      <Toast.Body style={{maxWidth: "100%", whiteSpace: "break-spaces"}}>{bodyMessageTost || contextBody}</Toast.Body>
    </Toast>

  )
}