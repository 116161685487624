import React, { ReactNode, useEffect, useState } from 'react';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzNavSelect } from '../../../ui/fz/nav';
import { Analytics } from './analytics';
import moment from "moment";
import { GetUserDocumentsAnalyticsReport } from '../../../services/api/user-documents';
import { getAllSuppliers } from '../../../api/suppliers';
import { normalizeDate } from '../../../biz/metadatas/stats/user-documents/user-documents';

const initialFilter = {
  applicationId: '',
  supplierInternalName: '',
  from: moment().subtract(10, "days").startOf("day").toISOString(),
  to: moment().endOf("day").toISOString(),
}

function UserDocumentsReport({ 
  ns,
  api,
}) {
  const [typeView, setTypeView] = useState('')
  const [data, setData] = useState({ })
  const [showTost, setShowTost] = useState(false)
  const filters = ns.getChanged()
  const { role = 'no-role' } = api.getCurrentUser()
  const [options, setOptions] = useState<any>({
    suppliers: [],
  });

  useEffect(() => {
    filterForm()
  }, [])

  const handleTostClose = () => setShowTost(false)

  const getAnalyticsData = async (page = 1, pageSize = 50, clearFilter = false) => {
    try {
      const { from, to, supplierInternalName, applicationId } = ns.getChanged() || {}
      const query = clearFilter ? initialFilter : { startDate: normalizeDate(from), endDate: normalizeDate(to), supplierInternalName, applicationId }
      const { data: statistics = [] } = await GetUserDocumentsAnalyticsReport(
        page,
        pageSize,
        query,
      )
      setData({ statistics })
    } catch (error) {
      setShowTost(true)
    }
  }

  function handleSearchReport(filter) {
    if (typeView === "summaries") {
      console.log(filter.period)
    } else {
      return getAnalyticsData(1, 50)
    }
  }

  const handleOnChangePage = async (page = 1, sizePerPage = 50) => {
    try {
      await getAnalyticsData(page, sizePerPage)
    } catch (error) {
      setShowTost(true)
    }
  }

  const filterForm = async () => {
    ns.set("filters-user-documents-analytics", initialFilter);
    ns.startEditing(initialFilter);
    try {
      const suppliersList = await getAllSuppliers()
      const parseRes = (code, description) => (item) => ({ 
        code: item[code], 
        description: item[description],
      })

      setOptions({
        suppliers: suppliersList.map(parseRes("internalName", "name")),
      });
    } catch (error) {
      setShowTost(true)
    }
  }
  const clearFilters = async () => {
    try {
      ns.set("filters-user-documents-analytics", initialFilter);
      ns.startEditing(initialFilter);
      await getAnalyticsData(1, 50, true)
    } catch (error) {
      setShowTost(true);
    }
  }

  const contents = {
    analytics: (
      <Analytics 
        data={data} 
        filters={filters}
        handleSearchReport={handleSearchReport}
        handleTostClose={handleTostClose}
        ns={ns}
        role={role}
        showTost={showTost}
        onPageChange={handleOnChangePage}
        options={options}
        clearFilters={clearFilters}
      />
    ),
    summaries: ( 
      <h1>Em Desenvolvimento</h1>
    ),
  }

  const handlePage = (pageType) => {
    setTypeView(pageType)
    setData({})
  }


  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={handlePage}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[typeView] || 'Aguardando seleção de relatório'}
      </FzCard>
    </>
  )
}

export { UserDocumentsReport }
