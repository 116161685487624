import React from 'react';
import { BidsPanel } from '../ui/blocks/auctions-bids';
//import { BidsPanel } from '../application-components/auction-bids'
import { SalesApplicationActions } from './actions-application';
import { AuditLog } from '../ui/blocks/application-components/audit-log';
import { CustomerAttendance } from '../ui/blocks/application';
import { FzCol, FzRow } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';
import { CustomerAttendanceHermes } from '../Containers/Hermes/attendance/ticketAttendance';

class CustomerValidate extends React.Component {
  render() {
    let messages = [];
    const { app, customer } = this.props;
    if (customer.name !== app.fullName) {
      messages.push(
        'Nome do cliente não é idêntico à proposta: ',
        customer.name,
        ' ==> ',
        app.fullName
      );
    }
    if (customer.telephoneNumber !== app.telephoneNumber) {
      messages.push(
        'Telefone da proposta difere dos dados do cliente: ',
        customer.telephoneNumber,
        ' ==> ',
        app.telephoneNumber
      );
    }
    if (messages.length === 0) {
      return null;
    }
    return <FzAlert fzStyle="attention">{messages.join('\n')}</FzAlert>;
  }
}

class ApplicationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { ns, app, history, customer } = this.props;
    const auditlog = [];

    let actions = (
      <FzRow>
        <FzCol span={6}>
          {this.props.basePath === "/hermes/tickets" ? (
            <CustomerAttendanceHermes
              ns={ns}
              app={app}
              history={history}
              onNeedUpdate={this.props.onNeedUpdate}
              basePath={this.props.basePath}
              ticketProp={this.props.ticket}
            />
          ) : (
            <CustomerAttendance
              ns={ns}
              app={app}
              history={history}
              onApplicationUpdated={this.props.onNeedUpdate}
              basePath={this.props.basePath}
            />
          )}
        </FzCol>
        <FzCol span={6}>
          <SalesApplicationActions
            disabled={this.state.working}
            app={app}
            ns={ns}
            validateStartAuction={this.validateStartAuction}
            hideButtons={{ editPhone: true, couldntTalk: true, postpone: true }}
            history={history}
            basePath={this.props.basePath}
          />
          <AuditLog auditLog={auditlog} ns={ns} />
        </FzCol>
      </FzRow>
    );

    return (
      <div>
        <CustomerValidate app={app} customer={customer} />
        <BidsPanel ns={ns} app={app} history={history} />
        {actions}
      </div>
    );
  }
}

export { CustomerValidate, ApplicationDetail };
