import React from 'react';
import { CustomerAttendance } from './../../ui/blocks/application';
import { FzTable } from '../fz/grid/table';
import { Application } from '../../biz/metadatas/application';
import api from '../../api/client-api';
import { CustomerAttendanceHermes } from '../../Containers/Hermes/attendance/ticketAttendance';

class InactiveApplications extends React.Component {
  constructor(props) {
    super(props);

    this.expandComponent = this.expandComponent.bind(this);
  }

  expandComponent(
    row,
    customer,
    ns,
    basePath,
    history,
    onNeedUpdate
  ) {
    return !api.isUserInRole('admin') ? (
      basePath === "/hermes/tickets" ? (
        <CustomerAttendanceHermes
          ns={ns}
          app={row}
          history={history}
          onNeedUpdate={onNeedUpdate}
          basePath={basePath}
          ticketProp={this.props.ticket}
        />
      ) : (
        <CustomerAttendance
          app={row}
          customer={customer}
          ns={ns}
          basePath={basePath}
          history={history}
          onNeedUpdate={onNeedUpdate}
        />
      )
    ) : null;
  }

  render() {
    const {
      priorityAttendance,
      customer,
      ns,
      basePath,
      history,
      onNeedUpdate,
      applications,
    } = this.props;

    if (!applications) {
      return null;
    }

    const expanding = [];
    if (priorityAttendance) {
      expanding.push(priorityAttendance.id);
    }

    return (
      <div>
        <FzTable
          data={applications}
          ns={ns}
          metas={Application.fields}
          visibleColumns={[
            'id',
            'created',
            'closedDate',
            'applicationState',
            'product',
            'customer',
            'appliedAmount',
          ]}
          rowExpand={{
            expandComponent: (row) =>
              this.expandComponent(
                row,
                customer,
                ns,
                basePath,
                history,
                onNeedUpdate
              ),
            onExpandRow: () => expanding,
          }}
          context={'inactiveApplications'}
        />
      </div>
    );
  }
}

export { InactiveApplications };
