import React from 'react';
import { RevenueModelOverview } from '../../pages/statistics/revenue-model-overview';
import {  RevenueModelPerSupplier } from '../../pages/statistics/revenue-model-supplier';

export const RevenueStats = ({ ns, activeKey, toggleBlocking,  ...props}) => {

  switch(activeKey) {
    case "overview":
      return <RevenueModelOverview ns={ns} toggleBlocking={toggleBlocking} />
    case "partners":
      return <RevenueModelPerSupplier ns={ns} toggleBlocking={toggleBlocking} />
    default:
    return null
  }
}

