import FieldConnected from "../../../../ui/components/form/field-connected";
import { FzButton } from "../../../../ui/fz/form";
import { FzTable } from "../../../../ui/fz/grid/table";
import { FzCard } from "../../../../ui/fz/layout/FzCard";
import { analyticsColumns, analyticsFilter } from "./metadata";
import { AnalyticsReportProps } from "./types";


export function AnalyticsReport({
  ns,
  data,
  handleSearchReport,
  role,
  filters,
}: AnalyticsReportProps) {
  return (
    <>
      <div className={"fz-panel"}>
        <div className={"fz-panel-border-padding"} style={{ display: "block", borderBottom: "3px solid #dedede" }}>
          <div style={{ display: "flex" }}>
            <FieldConnected meta={analyticsFilter.from} data={filters} ns={ns} />
            <FieldConnected meta={analyticsFilter.to} data={filters} ns={ns} />
          </div>
        </div>
        <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: "15px 0px 0 0" }}>
              <FzButton
                fzStyle="discrete"
                onClick={() => handleSearchReport({
                  from: ns.getChanged("from"),
                  to: ns.getChanged("to")
                  })
                }
                className={"btn btn-success"}
              >
                Buscar
              </FzButton>
            </div>
          </div>
        </div>
      </div>
      {(data.result && data.result.length > 0) ?
        <FzCard.Body>
          <FzTable
            ns={ns}
            metas={analyticsColumns}
            data={data.result || []}
            exportDownload={role === "admin"}
            context="ScreeningColumns"
          />
        </FzCard.Body> : <>
          <div className="d-flex align-items-center justify-content-left p-3">
            <h6 className="text-muted">Selecione uma data (De, Até) e clique em buscar!</h6>
          </div>
        </>}


    </>
  )
}