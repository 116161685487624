import React from 'react';
import { FormGroup} from 'react-bootstrap';
import {FormattedTime, FormattedDate, FormattedNumber} from 'react-intl';
import { FzCard } from '../ui/fz/layout/index';
import { FzForm } from '../ui/fz/layout/index';


const SimulationData = ({ns, data, ...props}) => {
    if (data.simulation === undefined || data.simulation === null) {
      return null
    }
    return (
        <FzCard>
            <FzCard.Heading>
                <FzCard.Title componentClass="h3">Dados da Simulação</FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
                <FormGroup className="col-sm-6">
                    <FzForm.Label>Simulado em (por)</FzForm.Label>
                    <FzForm.Static>
                        <FormattedDate month='2-digit' day='2-digit' value={data.simulation.created} />&nbsp;<FormattedTime value={data.simulation.created} />&nbsp;({data.simulation.simulatedBySelf ? "Usuário" : data.simulation.simulatedByName})
                    </FzForm.Static>
                </FormGroup>
                <FormGroup className="col-sm-6">
                    <FzForm.Label>Valor / parcelas simuladas</FzForm.Label>
                    <FzForm.Static>
                        <FormattedNumber value={data.simulation.simulatedAmount} /> / {data.simulation.simulatedPayments}
                    </FzForm.Static>
                </FormGroup>
                <FormGroup className="col-sm-6">
                    <FzForm.Label>Prestação Mínima / Máxima</FzForm.Label>
                    <FzForm.Static>
                        <FormattedNumber value={data.simulation.minimumInstallment} maximumFractionDigits={2} /> / <FormattedNumber value={data.simulation.maximumInstallment} maximumFractionDigits={2} />
                    </FzForm.Static>
                </FormGroup>
                <FormGroup className="col-sm-6">
                    <FzForm.Label>Menor taxa de juros</FzForm.Label>
                    <FzForm.Static>
                        <FormattedNumber value={data.simulation.simulatedInterestRate*100} maximumFractionDigits={3} />%
                        {data.simulation.simulationNotes && data.simulation.simulationNotes.length > 0 ? "(" + data.simulation.simulationNotes + ")" : ""}
                    </FzForm.Static>
                </FormGroup>
            </FzCard.Body>
        </FzCard>
    );
}

export default SimulationData;