import { Modal } from "react-bootstrap"
import { FzText } from "../../fz/form/old-simple-input"
import { FzButton } from "../../fz/form"
import { FzCol, FzRow } from "../../fz/layout"

const ModalElectricBillNumber = ({
  number,
  changeNumber, 
  date, 
  changeDate, 
  onClose, 
  show, 
  onSave, 
  error,
}) => {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title >
          Atualizar Dados de Conta de Luz
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FzRow>
          <FzCol>
            <FzText
              placeHolder="Digite o número de instalação da conta"
              value={number}
              name="electric-bill-number"
              onChange={(changedValue) => changeNumber(changedValue)}
            />
          </FzCol>
          <FzCol>
            <FzText
              placeHolder="Digite a data da última leitura"
              value={date}
              name="electric-bill-date"
              onChange={(changedValue) => changeDate(changedValue)}
            />
          </FzCol>
        </FzRow>
        
        <span style={{ 
          display: "block", 
          color: "red", 
          fontSize: "0.8em",
        }}>{error}</span>
      </Modal.Body>
      <Modal.Footer>
        <FzButton
          onClick={onClose}
          fzStyle="alert"
        >
          Cancelar
        </FzButton>
        <FzButton
          onClick={onSave}
          fzStyle="info"
        >
          Atualizar
        </FzButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalElectricBillNumber