import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';

class InputMasked extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: undefined };
    this.loadCache = this.loadCache.bind(this);
    this.saveCache = this.saveCache.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);

    if (props.type === "number") {
      this.getValue = (inputText) => Number(inputText);
    } else {
      this.getValue = (inputText) => inputText;
    }
  }

  loadCache() {
    this.setState({value: this.props.value.toString()});
  }

  saveCache() {
    if (this.state.value === undefined) {
      return;
    }

    if (this.props.value.toString() !== this.state.value) {
      let content = this.state.value;
      // TODO: support non numeric masks clean-up
      if (this.props.removeMask) {
        content = content.replace(/\D/g,'')
      }
      this.props.onChange(this.getValue(content));
    }
    this.setState({value: undefined});
  }

  saveOnEnter(event) {
    if (event.keyCode === 13) {
      this.saveCache();
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    if (this.props.notifyInputChanges) {
      this.props.notifyInputChanges(event.target.value);
    }
  }

  render() {
    // extract props used here and remove 'onChange' and 'notifyInputChanges' (used by methods above)
    let { type, domId, value, original, onChange, name, notifyInputChanges, mask, ...props } = this.props;

    value = (value === undefined) || (value === null) ? '' : value;
    original = (original === undefined) || (original === null) ? '' : original;

    const content = this.state.value === undefined ? value : this.state.value
    const changedClass = "form-control form-control" + (content !== original ? ' changed' : '');
    delete props.removeMask;
    return(
      <MaskedInput
        id={domId}
        mask={mask}
        placeholderChar=" "
        type="text"
        value={content.toString()}
        //emptyValue=""
        //isRevealingMask={true}
        className={changedClass}
        onChange={this.handleChange}
        onFocus={this.loadCache}
        onBlur={this.saveCache}
        onKeyDown={this.saveOnEnter}
        {...props} />
    );
  }
};

InputMasked.propTypes = {
  type: PropTypes.string.isRequired,
  domId: PropTypes.string,
  // value:    ---- could be string or numeric
  // default:   --- must be the same type of value
  mask: PropTypes.string.isRequired,
  removeMask: PropTypes.bool,
  original: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  notifyInputChanges: PropTypes.func
};

export default InputMasked;