import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { CloseApplications, ExcludeCustomers } from '../../../mis-applications/move-application-status';
import { ApplicationsList } from '../../../application-components/applications-list';
import { performCSVExport } from '../../../mis-applications/csv-exporter';
import { getApplications, getApplicationsCount } from '../../../api/applications';
import { deleteCustomer } from "../../../api/users";
import { ButtonToolbar, Form, FormGroup } from 'react-bootstrap';
import { FzCard, FzCol, FzRow, FzBadge } from '../../fz/layout/index';
import { FzForm } from '../../fz/layout/index';
import { FzButtonGroup, FzButton } from '../../fz/form/button';
import { FzAlert } from '../../fz/form/alert';
import { Application } from '../../../types/application';
import BlockUi from 'react-block-ui';
import moment from 'moment';


const PAGE_SIZE = 10000


export function ApplicationsTable({ api, ns, sharedNS, url }) {

  const [alertMessage, setAlertMessage] = useState("")
  const [blockApplicationsTable, setBlockApplicationsTable] = useState(false)
  const [filterSpinner, setFilterSpinner] = useState(false)
  const [showCloseApp, setShowCloseApp] = useState(false)
  const [showExcludeApp, setShowExcludeApp] = useState(false)
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const [selectedApplications, setSelectedApplications] = useState<Array<string>>([])
  const [applicationsCount, setApplicationsCount] = useState(0)
  const [outputFormat, setOutputFormat] = useState("full-cp")
  const [exportDisabled, setExportDisabled] = useState(true)
  const [urlChecked, setUrlChecked] = useState(false)


  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [ns])


  useEffect(() => {
    ns.startEditing({
      createdAfter: moment().utc().set({day: -7, hour: 0, minute: 0, second: 0, millisecond: 0}).local(),
      createdBefore: moment().utc().set({hour: 23, minute: 59, second: 59, millisecond: 0}).local()
    })
    getQueryStringFromUrl()
  }, [])


  function setUrlQueryString() {
    let changes = { ...ns.getChanged(), ...ns.get("filters") }

    if (changes) {
      if (changes.createdAfter instanceof moment) {
        changes.createdAfter = changes.createdAfter._d
      }
      if (changes.createdBefore instanceof moment) {
        changes.createdBefore = changes.createdBefore._d
      }
      if (changes.paidAfter instanceof moment) {
        changes.paidAfter = changes.paidAfter._d
      }
      if (changes.paidBefore instanceof moment) {
        changes.paidBefore = changes.paidBefore._d
      }
      const query = qs.stringify(changes, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      if(window.location.href !== newurl) {
        history.pushState({ path: newurl }, '', newurl);
      }
    }
  }


  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const listOfQueryParams = Object.keys(params)
    let checkbox: [] | any
    if (listOfQueryParams.length > 0) {
      for (let i = 0; i < listOfQueryParams.length; i++) {
        if (listOfQueryParams[i] === "applicationStates[" + [i] + "]") {
          checkbox = params[listOfQueryParams[i]]
        }
        if (checkbox) {
          ns.saveChange(checkbox, params[listOfQueryParams[i]])
        }
        else {
          ns.saveChange(listOfQueryParams[i], params[listOfQueryParams[i]])
        }
      }
    }
    setUrlChecked(true)
  }

  function apiError(errorCode: number) {
    if (errorCode === 403) {
      api.clearAuth()
    } else {
      console.warn("ApiError ****")
      setAlertMessage("API Error: " + errorCode)
      setBlockApplicationsTable(false)
      setFilterSpinner(false)
    }
  }


  function closeStateModals() {
    setShowCloseApp(false)
    setShowExcludeApp(false)
  }


  function excludeUsers(userIds) {
    if (userIds.length === 0) {
      setAlertMessage("Nenhuma proposta selecionada. Por favor selecione algumas propostas")
    }
    else {
      setShowExcludeApp(true)
      setSelectedUserIds(userIds)
      setAlertMessage("");
    }
  }


  function closeSelectedApplications() {
    let appIDs = ns.get("selected", [])
    if (appIDs && appIDs.length > 0) {
      setShowCloseApp(true)
      setSelectedApplications(appIDs)
      setAlertMessage("")
    }
    else {
      setAlertMessage("Nenhuma proposta selecionada. Por favor selecione algumas propostas")
    }
  }


  function confirmExcludeUsers(callback, errorCallback) {
    let userIds = selectedUserIds
    let exclusions = userIds.map(it => deleteCustomer(it))
    Promise.all(exclusions)
      .then(callback)
      .then(clearFilters)
      .catch(errorCallback)
  }


  function clearFilters() {
    ns.stopEditing()
    ns.unset("filters")
    ns.unset("applications")
    ns.startEditing()
    setApplicationsCount(0)
  }


  function confirmCloseApplications(reason) {
    setShowCloseApp(false)
    var apps: [] = ns.get("applications", [])
    var closeApps: Application[] = []
    let app: Application | any
    for (app of apps) {
      if (selectedApplications.indexOf(app.id) >= 0) {
        closeApps.push(app)
      }
    }
    api.closeLeads(closeApps, reason, () => applyFilter());
  }


  function applyFilter() {
    let filters = ns.getChanged() || ns.get("filters")
    ns.unset("applications")
    setAlertMessage("")
    
    if (filters) {
      getApplicationsCount(filters)
        .then(jsonData => {
          setApplicationsCount(jsonData.total)
          setExportDisabled(true)

          if (jsonData.total > 200000) {
            setAlertMessage("Quantidade de registros excedida (> 200 000). Por favor, restrinja sua pesquisa")
          }
          else {
            loadApplications()
            if (jsonData.total === 0) {
              setExportDisabled(true)
            }
            else {
              setExportDisabled(false)
            }
          }

          ns.set("filters", filters)
        }).catch(apiError);
    }
    else {
      alert("Preencha pelo menos um filtro")
    }
  }


  function loadApplications() {
    let filters = ns.getChanged() || ns.get("filters")
    setFilterSpinner(true)
    getApplications(filters, 1, PAGE_SIZE)
      .then(function (applications) {
        ns.set("applications", applications);
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        ns.unset("selected");
        setFilterSpinner(false);
      })
  }


  function applicationsExportRecursiveLegacy(filter, page, resultSet, outputFormat) {
    resultSet = resultSet || []

    api.getApplicationsAuctionBreakdownPage(filter, page, PAGE_SIZE, function (applications) {
      
      Array.prototype.push.apply(resultSet, applications);

      if (applications.length === PAGE_SIZE) {
        applicationsExportRecursiveLegacy(filter, ++page, resultSet, outputFormat);
      } else {
        if (resultSet && resultSet.length > 0) {
          performCSVExport(resultSet, outputFormat);
          setBlockApplicationsTable(false)
        }
      }

    }, apiError);
  }


  function applicationsExportRecursive(filter, page, resultSet, outputFormat) {
    resultSet = resultSet || []

    getApplications(filter, page, PAGE_SIZE)
      .then(applications => {
        Array.prototype.push.apply(resultSet, applications);

        if (applications.length === PAGE_SIZE) {
          applicationsExportRecursive(filter, ++page, resultSet, outputFormat)
        } else {
          if (resultSet && resultSet.length > 0) {
            performCSVExport(resultSet, outputFormat);
            setBlockApplicationsTable(false)
          }
        }
        
      })
  }


  function exportApplications(outputFormat) {
    const filter = ns.getChanged() || ns.get("filters", []);
    setBlockApplicationsTable(true)
    const reportsBuiltOnFrontend = ['analytics-marketing', "financial-paid", "deleted-reason"]

    if (reportsBuiltOnFrontend.includes(outputFormat)) {
      applicationsExportRecursive(filter, 1, null, outputFormat);
    }
    else {
      applicationsExportRecursiveLegacy(filter, 1, null, outputFormat);
    }
  }


  const filterButtons = [
    {
      fzStyle: "regular",
      description: "Filtrar",
      onClick: () => applyFilter()
    },
    {
      fzStyle: "regular",
      description: "Limpar Filtros",
      onClick: () => clearFilters()
    },
  ]

  const actionButtons = [
    {
      fzStyle: "regular",
      description: "Fechar propostas",
      onClick: () => closeSelectedApplications()
    },
    {
      fzStyle: "alert",
      description: "Excluir",
      onClick: () => {
        const appIds = ns.get("selected", []);
        const applications = appIds.map(appId => ns.get("applications", []).find(it => it.id === appId))
        if (applications.filter(it => it.applicationState === 28672).length > 0) {
          return window.alert("Existem applications já excluídas na seleção\nRemova-as e tente novamente")
        }
        const userIds = applications.map(it => it.customerId)
        excludeUsers(userIds)
      },
      eachDisabled: () => ns.get("selected", []).length === 0 && api.isUserInRole("admin")
    },
  ]

  if (alertMessage.length > 0) {
    <FzAlert fzStyle="attention">
      <p>{alertMessage}</p>
    </FzAlert>
  }

  let applications = ns.get("applications") || []
  const user = api.getCurrentUser()
  const role = user?.role || 'no-role' 

  return (
    <BlockUi tag="div" blocking={blockApplicationsTable}>
      <FzCard>
        <FzCard.Body>
          <Form inline>
            <FzRow>
              <FzCol span={3}>
                <ButtonToolbar>
                  <FzButtonGroup buttons={filterButtons} isLoading={filterSpinner} />
                  <FzBadge fzStyle="regular">
                    Total encontrado {(applicationsCount > 0 ? applicationsCount : "")}
                  </FzBadge>
                </ButtonToolbar>
              </FzCol>
              <FzCol span={4}>
                <ButtonToolbar>
                  <FzButtonGroup
                    buttons={role !== 'admin' ? actionButtons.filter(btn => btn.description !== 'Excluir') : actionButtons}
                    disabled={ns.get("selected", []).length === 0}
                  />
                  <div style={{ margin: "0 0.5rem" }}>
                    <FzBadge fzStyle="attention">
                      Total selecionado {(ns.get("selected", []).length > 0 ? ns.get("selected", []).length : "")}
                    </FzBadge>
                  </div>
                </ButtonToolbar>
              </FzCol>
              <FzCol span={5}>
                <FormGroup >
                  <FzForm.Label> Formato: </FzForm.Label>
                  <FzForm.Control
                    style={{ height: "2.2rem", margin: "3px" }}
                    name="outputFormat"
                    as="select"
                    placeholder="select"
                    value={outputFormat}
                    disabled={exportDisabled}
                    onChange={(e) => setOutputFormat(e.target.value)} >
                    <option value='full-cp'> Completo CP </option>
                    <option value='full-consorcio'> Completo Consórcio </option>
                    <option value='full-he'> Completo HE </option>
                    <option value='full-fin'> Completo (Re)Fin </option>
                    <option value='card'> Cartão </option>
                    <option value='summaries-marketing'> Summaries Marketing </option>
                    <option value='analytics-marketing'> Analytics Marketing </option>
                    <option value='financial-paid'> Financial report (Paid) </option>
                    <option value='deleted-reason'> Deleted Reasons </option>
                  </FzForm.Control>
                  <FzButton
                    fzStyle="regular"
                    size="sm"
                    disabled={exportDisabled}
                    onClick={() => exportApplications(outputFormat)}
                  >
                    Download all filtered data as CSV
                  </FzButton>
                </FormGroup>
              </FzCol>
            </FzRow>
            <FzRow bsClass="alert-line">
              <FzCol span={10} smOffset={1}>
                {alert}
              </FzCol>
            </FzRow>
          </Form>
        </FzCard.Body>
        <FzCard>
          <FzCard.Body>
            <ApplicationsList
              sharedNS={sharedNS}
              ns={ns}
              multipleSelect detailsBaseRoute={'/applications/'}
              count={applicationsCount}
              data={applications}
              history={history}
            />
          </FzCard.Body>
        </FzCard>
        <CloseApplications
          show={showCloseApp}
          applicationsToClose={ns.get("selected", [])}
          closeButton={closeStateModals}
          saveButton={confirmCloseApplications}
        />
        <ExcludeCustomers
          api={api}
          show={showExcludeApp}
          usersToExclude={selectedUserIds}
          closeButton={closeStateModals}
          saveButton={confirmExcludeUsers}
        />
      </FzCard>
    </BlockUi>
  )
}