export const firstBy = (function () {
  function baseCase(f) {
    f.thenBy = thenBy;
    return f;
  }

  function thenBy(y, x) {
    x = this;
    return baseCase(function (a, b) {
      return x(a, b) || y(a, b);
    });
  }
  return baseCase;
})();