import { Meta } from "../../../types/metas";


export const QueueMeta: Meta = {

  id: {
    path: 'id',
    label: '',
    dataType: 'string',
    tableProps: {
      width: '20em',
      dataSort: true,
      isKey: false,
      dataAlign: 'left',
    },
  },

  queueName: {
    path: 'queueName',
    label: 'Nome da fila',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      width: 'auto',
      dataSort: true,
      dataAlign: 'left',
      isKey: true,
      filter: {
        type: 'TextFilter',
        delay: 500,
      }
    },
    placeholder: "Digite a fila",
    isMandatory: () => true,
  },

  delay: {
    path: 'delay',
    label: 'Delay de entrada do cliente',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      columnFormat: "formatMinutesSeconds",
      width: 'auto',
      dataSort: true,
      dataAlign: 'left',
      isKey: false,
    },
    placeholder: "Digite o tempo",
    isMandatory: () => true,
  },

  cadencia: {
    path: 'cadencia',
    label: 'Cadência',
    dataType: 'date',
    tableProps: {
      width: 'auto',
      dataSort: true,
      isKey: false,
      dataAlign: 'left',
    },
  },

  detais: {
    path: 'details',
    label: 'Detalhes',
    dataType: 'string',
    tableProps: {
      width: 'auto',
      dataSort: true,
      isKey: false,
      dataAlign: 'left',
    },
  },

  priority: {
    path: 'priority',
    label: 'Prioridade da fila',
    dataType: 'string',
    tableProps: {
      width: '220px',
      dataSort: true,
      isKey: false,
      dataAlign: 'left',
      filter: {
        type: 'TextFilter',
        delay: 500,
      },
    },
  },

  linked: {
    path: 'linked',
    label: 'Vinculada ao consultor',
    dataType: 'string',
    tableProps: {
      width: 'auto',
      dataSort: true,
      isKey: false,
      dataAlign: 'left',
      filter: {
        type: 'SelectFilter',
        options: 'filters.boolean'
      },
    },
  },
};