import { getAllSuppliers } from "../../api/suppliers"
import { Application } from "../../biz/metadatas/application"
import { createExportBasedOnMetas } from "./csv-exporter-utils/exporting-utils"

const agentsToIncludeOnExport = [
  "cbss",
  "simplic",
  "supersim",
  "rebel",
  "portocred",
  "noverde",
  "newback",
  "moneyman",
  "geru",
  "emprestimosim",
  "alfa",
  "bv",
  "piki",
  "lendico",
  "focus",
  "iti", 
  "clickcash"
]

async function getCsvAnalyticsMarketingReport(jsonList) {
  const allSuppliers: $TsFixMe = await getAllSuppliers()
  const filteredSuppliers = allSuppliers.filter(it => agentsToIncludeOnExport.indexOf(it.internalName) !== -1)
  const metaDynamic = filteredSuppliers.reduce((accum, elem) => {
    return {
      ...accum,
      [elem.internalName]: [
        "auction.bids.supplier.internalName",
        "supplierData.reserved",
        "supplierData.sent",
        "auction.bids.approvedAmount",
        "auction.bids.approvedPeriodMonths",
        "auction.bids.monthlyInstallment",
        "auction.bids.interestRate",
        "auction.bids.accepted",
        "auction.bids.refused",
        "auction.bids.rejected"
      ].map(field => Application.getField(field))
    }
  }, {})

  const metas = { ...Application.fields, ...metaDynamic }
  const columnsPaths = [
    "id",
    "created",
    "updated",
    "trackingData.utm_source",
    "personalNumber",
    "product",
    "applicationState",
    "closedReason",
    "scores.score",
  ].concat(Object.keys(metaDynamic))

  const csv = createExportBasedOnMetas(metas, columnsPaths, jsonList)
  return csv
}

export { getCsvAnalyticsMarketingReport }
