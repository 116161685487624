import React, { Component } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { UserDetails } from './user-detail'
import { GetPassword } from './get-password'
import { createUser, getUserById, updateUser, activatePassword, deactivatePassword } from '../../../api/users';
import { users } from '../../../biz/metadatas/users'
import { FzCard, FzJumbotron } from '../../fz/layout/index'
import { FzButtonGroup, FzButton } from '../../fz/form/button'
import { getAllProducts } from '../../../api/applications';
import { getAllSuppliers } from '../../../api/suppliers';

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      askPassword: false,
      allProductsList: [],
      suppliers: [],
    }
  }


  async componentDidMount() {
    this.loadAllProducts();
    await this.loadAllSuppliers();
  }

  async loadAllProducts() {
    const productsResp = await getAllProducts();
    this.setState({
      allProductsList: productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    });
  }

  async loadAllSuppliers() {
    const suppliers = await getAllSuppliers()
    this.setState({ suppliers })
  }


  render() {

    let { ns } = this.props
    let { userId } = this.props.match.params
    const persistChanges = () => {
      let tmpUser = ns.getChanged()

      //removing branches from non selected partners
      const selectedPartners = tmpUser.preferredPartners
      for (let b in tmpUser.branches) {
        if (selectedPartners.indexOf(b) === -1 || tmpUser.branches[b] == "undefined") {
          delete tmpUser.branches[b]
        }
      }

      if (tmpUser) {
        if (tmpUser.id === "new") {
          delete tmpUser.id
          createUser(tmpUser)
            .then((user) => {
              ns.unset("dirty");
              ns.set("user", user)
              ns.stopEditing(user);
            })
            .catch((err) => {
              ns.unset("dirty");
              console.error("API ERROR:", err);
              ns.stopEditing();
              ns.unset("user")
            })
        } else {
          updateUser(tmpUser.id, tmpUser)
            .then((user) => {
              ns.unset("dirty");
              ns.set("user", user)
              ns.stopEditing(user);
            })
            .catch((err) => {
              ns.unset("dirty");
              console.error("API ERROR:", err);
              ns.stopEditing();
              ns.unset("user")
            })
        }
      }
    }
    const cancelEditPassword = () => {
      let l = ns.namespace
      l = l.substring(l.lastIndexOf(+ users.role + '/'))
      deactivatePassword(userId)
        .then((user) => {
          ns.set("user", user)
          ns.stopEditing()
          this.props.history.push(l)
        })
    }

    const doActivatePassword = () => {
      let passwd = { firstPassword: "", secondPassword: "" }
      this.props.ns.set("password", passwd)
      ns.startEditing(passwd)

      this.setState({ askPassword: true })
      activatePassword(userId)
        .then((user) => {
          ns.set("user", user)
        })
    }

    const doDeactivatePassword = () => {
      deactivatePassword(userId)
        .then((user) => {
          ns.set("user", user)
        })
    }

    const savePassword = () => {
      let passwd = ns.getChanged()
      if (!passwd.firstPassword || passwd.firstPassword.length < 6) {
        this.setState({ errorMessage: "Digite uma senha de 6 caracteres no mínimo" })
      } else if (passwd.firstPassword !== passwd.secondPassword) {
        this.setState({ errorMessage: "Digite as duas senhas iguais" })
      } else {
        activatePassword(userId, passwd.firstPassword)
          .then((user) => {
            ns.set("user", user)
            ns.unset("dirty");
            ns.stopEditing();
            this.setState({ errorMessage: undefined, askPassword: false })
          })
      }
    }
    const goParent = () => {
      let p = ns.namespace
      p = p.substring(0, p.lastIndexOf('/'))
      ns.unset("dirty");
      ns.stopEditing();
      this.props.history.push(p)
      ns.unset("users");
    }

    let user = ns.getChanged() || ns.get("user")

    if (!user) {
      getUserById(userId).then((user) => ns.set("user", user))
      return null
    }
    let buttons

    const metas = {
      "id": users.id,
      "created": users.created,
      "name": users.name,
      "email": users.email,
      "telephoneNumber": users.telephoneNumber,
      "group": users.group,
      "queues": users.queues,
      "priorityQueue": users.priorityQueue,
      "loginWithPassword": users.loginWithPassword,
      "active": users.active,
      "personalNumber": users.personalNumber,
      "role": users.role,
      "apiKey": users.apiKey,
      "apiSecret": users.apiSecret,
      "products": {
        ...users.products,
        "inputProps": {
          "options": (this.state.allProductsList.length > 0 ? this.state.allProductsList : [])
        },
      },
      "preferredPartners": users.preferredPartners,
    }
    let editArea = <UserDetails
      suppliers={this.state.suppliers}
      products={this.state.allProductsList || []}
      ns={ns}
      data={user}
      persistChanges={persistChanges}
      metas={metas}
      btn={buttons}
    />
    if (ns.isEditing() && !this.state.askPassword) {
      let btn = [
        {
          fzStyle: "regular",
          description: "Gravar",
          onClick: () => { persistChanges(); goParent(); },
        },
        {
          fzStyle: "alert",
          description: "Cancelar",
          onClick: goParent,
        },
      ];

      buttons = (<FzButtonGroup buttons={btn} />);
    } else if (this.state.askPassword) {
      let btn = [
        {
          fzStyle: "discrete",
          description: "Gravar senha",
          onClick: savePassword,
        },
        {
          fzStyle: "alert",
          description: "Cancelar",
          onClick: cancelEditPassword,
        },
      ];

      buttons = (<FzButtonGroup buttons={btn} />);
      editArea = <GetPassword ns={ns} metas={users} errorMessage={this.state.errorMessage} user={ns.get("user")} />
    } else {

      let passwdBtn = user.loginWithPassword ?
        <FzButton fzStyle="default" onClick={doDeactivatePassword} > Desativar Senha </FzButton> :
        <FzButton fzStyle="regular" onClick={doActivatePassword} > Ativar Senha </FzButton>

      buttons = (
        <ButtonGroup>
          <FzButton fzStyle="default" onClick={() => ns.startEditing(user)} >Editar</FzButton>
          {passwdBtn}
        </ButtonGroup>
      )
    }

    return (
      <FzJumbotron>
        <FzCard>
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">Editar usuário</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            {buttons}
            {editArea}
          </FzCard.Body>
        </FzCard>
      </FzJumbotron>
    )
  }
}
export { EditUser };
