import React, { Component } from 'react';
import { funnel, pivotMeta } from '../../../../../biz/metadatas/funnel'
import { FzPivotTable } from '../../../../fz/grid/pivot-table'
import { FzCard } from '../../../../fz/layout/index';

class OperationalPartners extends Component {

  render() {
    const { ns, data, product, pivotColumnName, filterValue } = this.props;

    if (!pivotColumnName) {
      return null
    }

    return (
      <div>
        <FzCard>
          <FzCard.Body>
            <FzCard>
              <FzCard.Body>
                <FzPivotTable
                  data={data.statistics || []}
                  ns={ns}
                  metas={funnel}
                  pivotMetas={pivotMeta}
                  context={"funnelOpePartners_" + product}
                  filterColumnName={pivotColumnName === "supplier" ? "period" : "supplier"}
                  filterValue={filterValue}
                  exportDownload
                  clipboard
                  pivotColumnName={pivotColumnName}
                />
              </FzCard.Body>
            </FzCard>
          </FzCard.Body>
        </FzCard>
      </div>
    )
  }
}

export { OperationalPartners }