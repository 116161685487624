import get from 'lodash/get'

import fileDownload from 'js-file-download'
import hooks from './column-formatter'

const exportHeader = (columnsMeta) => {
  let str = ""
  for (let m of columnsMeta) {
    str += (m.nameExportCsv || m.label || "") + "\t"
  }
  return str + "\n"

}
const exportOneLine = (columnsMeta, row) => {
  let str = ""
  
  for (let m of columnsMeta) {
    let f = get(row, m.field)
    if (f !== undefined) {
      str += m.exportFormat(f) + "\t"
    } else {
      str += "\t"
    }
  }

  return str
}



export const fzTableDoExport = (data, metas, columnsPaths, asDownload, context) => {
  const columns = buildExportMeta(metas, columnsPaths)
  let csvContent = exportHeader(columns)

  for (let row of data) {
    let s = exportOneLine(columns, row)
    if (s.length) {
      csvContent += s + "\n"
    }
  }

  if (asDownload) {
    fileDownload("\uFEFF" + csvContent, "export-" + context + ".csv", 'text/csv');
  } else {
    return csvContent
  }
}


export const doExport = (data, metas, columnsPaths, dateFilter, asDownload, context) => {

  const columns = buildExportMeta(metas, columnsPaths)
  let csvContent = ""

  data = data.filter((it) => it.period === dateFilter)
  columns.forEach((columnIt) => {
    return csvContent += columnIt.label + "\t" + data.map((it) => {
      if (columnIt.exportFormat) {
        return columnIt.exportFormat(get(it, columnIt.field))
      } else {
        return get(it, columnIt.field, 0)
      }
    }).join("\t") + "\n"
  })

  if (asDownload) {
    fileDownload("\uFEFF" + csvContent, "export-" + context + ".csv", 'text/csv');
  } else {
    return csvContent
  }
}

const buildExportMeta = (metas, columnsPaths) => {
  let colsMeta = []
  for (let c of columnsPaths) {
    let m = metas[c]
    if (!m)
      continue;
    let formatter = hooks[m.tableProps.exportFormat]
    if (!formatter) {
      formatter = function (f) { return String(f) }
    }

    const labelText = (m.nameExportCsv || m.tableProps.headerText || m.label)

    colsMeta.push({ field: m.path, label: labelText, exportFormat: formatter })
  }
  return colsMeta
}