import React, { useState } from 'react';
import { FzCard } from '../../../../../fz/layout/FzCard';
import { FzNavSelect } from '../../../../../fz/nav';
import { ScreeningDocumentsOverwriteAnalytics } from '../../overwrite-documents-analytics';
import { ScreeningOverwriteDocumentsSummaries } from '../../overwrite-documents-summaries';

function ScreeningDocumentsOverwrite({ toggleBlocking, ns }) {

  const [typeView, setTypeView] = useState('')

  const contents = {
    analytics: <ScreeningDocumentsOverwriteAnalytics ns={ns} toggleBlocking={toggleBlocking} />,
    summaries: <ScreeningOverwriteDocumentsSummaries ns={ns} toggleBlocking={toggleBlocking} />
  }

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={(value) => {
            setTypeView(value)
          }}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[typeView]}
      </FzCard>
    </>
  )
}

export { ScreeningDocumentsOverwrite }