export const statsMessagingFinanvc = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": "2",
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "period",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
      "isKey": null,
      "exportFormat": "periodColumn",
      "rowSpan": "2",
      "sortFunc": null
    },
    "tip": null
  },
  "event": {
    "path": "event",
    "dataType": "text",
    "label": "event",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": "2",
      "sortFunc": null
    },
    "tip": null
  },
  "creations": {
    "path": "creations",
    "dataType": "number",
    "label": "creations",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "8ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": "2",
      "sortFunc": null
    },
    "tip": null
  },
  "reads": {
    "path": "reads",
    "dataType": "data-percent",
    "label": "reads",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "isKey": null,
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "reads",
            dataAlign: "left",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      },
      "exportFormat": null,
      "formatExtraData": "creations",
      "sortFunc": null
    },
    "tip": null
  },
  "channels.email": {
    "path": "channels.email",
    "dataType": "data-percent",
    "label": "email",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "email",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      },
      "isKey": null,
      "exportFormat": null,
      "formatExtraData": "reads",
      "sortFunc": null
    },
    "tip": null
  },
  "channels.sms": {
    "path": "channels.sms",
    "dataType": "data-percent",
    "label": "SMS",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "SMS",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      },
      "isKey": null,
      "exportFormat": null,
      "formatExtraData": "reads",
      "sortFunc": null
    },
    "tip": null
  },
  "channels.web": {
    "path": "channels.web",
    "dataType": "data-percent",
    "label": "browser",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "isKey": null,
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "browser",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      },
      "exportFormat": null,
      "formatExtraData": "reads",
      "sortFunc": null
    },
    "tip": null
  }
}

