import api from '../../../api/client-api';
import { useState, useEffect } from 'react'
import { FzCol, FzRow } from '../../fz/layout';
import { FzCard } from '../../fz/layout/FzCard';
import { FzButton } from '../../fz/form';
import { uploadDocument } from '../../../api/applications';
import ModalElectricBillNumber from '../formalization/modalElectricBillNumber';
import isValidElectricBillNumber from '../../../utils/electricBillNumberValidation';

interface DocumentTextProps {
  url: String,
  documentType: String,
  bidId?: String,
  applicationId?: String,
  ns: any
}

export function DocumentText({ url, documentType, ns, bidId, applicationId }: DocumentTextProps) {
  const [documentContent, setDocumentContent] = useState<String>("")

  useEffect(() => {
    if (documentContent === "") {
      getDocumentContent()
    }
  }, [])

  async function getDocumentContent() {
    if (api.baseUrl && typeof api.baseUrl === 'string' && url.indexOf(api.baseUrl) > -1) {
      url = url.replace(api.baseUrl, '')
    }
    const data = await api.Get(url);
    setDocumentContent(data.content);
  }

  switch (documentType) {
    case 'address-eletricbill-number':
      return eletricBillNumber({info: documentContent, bidId, applicationId, setDocumentContent})

    default:
      return <>{documentContent}</>
  }
}

function eletricBillNumber({info, bidId, applicationId, setDocumentContent}) {

  const [electricBillNumber, setElectricBillNumber] = useState("")
  const [electricBillNumberDate, setElectricBillNumberDate] = useState("")
  const [electricBillNumberError, setElectricBillNumberError] = useState("")
  const [showModalElectricBillNumber, setShowModalElectricBillNumber] = useState(false)

  useEffect(() => {
    let [meterReadingDate, installationCode] = info.split('|')

    if (meterReadingDate && !isNaN(Date.parse(meterReadingDate))) {
      meterReadingDate = new Date(meterReadingDate).toISOString().replace(/T.*/, '').split('-').reverse().join('/')
    }

    setElectricBillNumber(installationCode)
    setElectricBillNumberDate(meterReadingDate)
  }, [info])

  async function handleUpdateElectricBillNumber() {
    const { isValid, message } = isValidElectricBillNumber({number: electricBillNumber, date: electricBillNumberDate})
    if (!isValid) {
      return setElectricBillNumberError(message)
    }

    try {
      const formattedDate = electricBillNumberDate.split("/").reverse().join("-")
      const payload = {
        content: `${formattedDate}|${electricBillNumber}`,
        documentType: "address-eletricbill-number",
        suppressNotification: true
      }
      
      await uploadDocument(applicationId, bidId, payload)

      setElectricBillNumber("")
      setElectricBillNumberDate("")
      setElectricBillNumberError("")
      setShowModalElectricBillNumber(false)

      setDocumentContent(payload.content)
    } catch (error) {
      console.error('handleUpdateElectricBillNumber', {error})
    }    
  }

  return <>
    <FzCard fzStyle={{ border: 'none' }}>
      <FzCard.Body fzStyle={{ margin: '10px 30px 20px 30px' }}>
        <FzRow key={'eletricBillNumberContent'} fzStyle={{ display: 'flex', justifyContent: 'center' }}>
          <FzCol key={'meterReadingDate'} span={4}>
            <strong>Data de emissão:</strong> {electricBillNumberDate}
          </FzCol>
          <FzCol key={'installationCode'} span={4}>
            <strong>Número de Instalação:</strong> {electricBillNumber}
          </FzCol>
          {(applicationId && bidId) && (
            <FzCol key={'updateElectricBillNumberButton'} span={4}>
              <FzButton onClick={() => setShowModalElectricBillNumber(true)}>Atualizar número</FzButton>
            </FzCol>
          )}
        </FzRow>

        <ModalElectricBillNumber
          number={electricBillNumber}
          changeNumber={setElectricBillNumber}
          date={electricBillNumberDate}
          changeDate={setElectricBillNumberDate}
          show={showModalElectricBillNumber}
          onClose={() => {
            setElectricBillNumber("")
            setElectricBillNumberDate("")
            setElectricBillNumberError("")
            setShowModalElectricBillNumber(false)
          }}
          onSave={handleUpdateElectricBillNumber}
          error={electricBillNumberError}
        />
      </FzCard.Body>
    </FzCard>
  </>
}