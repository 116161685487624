import React, { Fragment, useState } from 'react'
import { Jumbotron, Button, ButtonGroup } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import { clone } from "lodash";
import FieldConnected from '../../../ui/components/form/field-connected';
import { FzCol, FzRow, FzSplitLine } from '../../../ui/fz/layout';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzCardGroup } from '../../../ui/fz/layout/';
import { InputLayoutForm } from '../../../biz/metadatas/InputLayout';
import { UpsellBanner } from '../../../services/api/dory/type';
import { validateAppField } from "../../../biz/validate-application";
import { FzToast } from '../../../ui/fz/form/notification/toast';
import { FzButton } from '../../../ui/fz/form/button';

const AddInputLayout = ({
  ns,
  handleSubmit,
  goBack,
  toastInfo,
  handleToastClose,
  loading,
  suppliersOptions,
  mapFieldOptions,
}) => {
  const [metaDataForm, setMetaDataForm] = useState<any>(InputLayoutForm)
  const [fieldConnectedItens, setFieldConnectedItens] = useState<any[]>([])
  const [fieldCount, setFieldCount] = useState<any>(0)
  
  metaDataForm
    .supplierInternalName
    .inputProps
    .options = suppliersOptions
  
  metaDataForm
    .mapField_0
    .inputProps
    .options = mapFieldOptions

  const formItems = Object.keys(InputLayoutForm);
  const RenderFormItem = () => {
    return formItems.map((item, index) => {
      const fieldColumn = item.includes("_0")
      const spanSize = (
        fieldColumn ? (
          item.includes('field_0')
            ? 3
            : 2
        ) : item.includes("referenceNumberDays") ? 12 : 6
      )
      const meta = metaDataForm[item]

      return (
        <FzCol span={spanSize} key={index}>
          <FieldConnected
            meta={meta}
            ns={ns}
          />
        </FzCol>
      )
    });
  }

  const formFieldIsEmpty = async data => {
    const formElmentItens = Object.keys(metaDataForm);
    const checkIsEmptyFields = await Promise.all(
      formElmentItens.map(item => {
        return validateAppField({
          value: data[item],
          data,
          ns,
          metaField: metaDataForm[item],
        })
      })
    )

    const invalidFields = checkIsEmptyFields
      .filter(({ valid }) => !valid)
      .map(({ path }) => path)

    ns.set("INVALID", invalidFields)

    return invalidFields
  }

  const handleSubmitAndCheckForm = () => {
    const values: UpsellBanner = ns.getChanged();
    formFieldIsEmpty(values)
      .then(fields => handleSubmit(values, fields))

  }

  const handleRemoveItem = (indexField) => (nsFieldKey) => {
    setFieldConnectedItens(fieldConnectedItens.filter((_, index) => index !== indexField))
    const nsData = ns.getChanged()
    delete nsData[nsFieldKey]
    ns.set("inputLayoutForm", nsData);
  }

  const handleAddFieldConnected = () => {
    const increment = fieldCount + 1
    const field = `field_${increment}`
    const type = `type_${increment}`
    const format = `format_${increment}`
    const size = `size_${increment}`
    const mapField = `mapField_${increment}`

    const metaClone = {
      [field]: clone(InputLayoutForm.field_0),
      [type]: clone(InputLayoutForm.type_0),
      [format]: clone(InputLayoutForm.format_0),
      [size]: clone(InputLayoutForm.size_0),
      [mapField]: clone(InputLayoutForm.mapField_0),

    }

    metaClone[field].path = field
    metaClone[type].path = type
    metaClone[format].path = format
    metaClone[size].path = size
    metaClone[mapField].path = mapField


    setFieldCount(increment)
    setMetaDataForm({ ...metaDataForm, ...metaClone })

    const component = ({ handleRemoveItem, meta, ns }) => (
      <Fragment key={`${field}-${type}-${format}-${size}-${mapField}`}>
        <FzCol span={3}>
          <FieldConnected
            meta={meta[field]}
            ns={ns}
          />
        </FzCol>
        <FzCol span={2}>
          <FieldConnected
            meta={meta[type]}
            ns={ns}
          />
        </FzCol>
        <FzCol span={2}>
          <FieldConnected
            meta={meta[format]}
            ns={ns}
          />
        </FzCol>
        <FzCol span={2}>
          <FieldConnected
            meta={meta[size]}
            ns={ns}
          />
        </FzCol>
        <FzCol span={2}>
          <FieldConnected
            meta={meta[mapField]}
            ns={ns}
          />
        </FzCol>
        <FzCol span={1}>
          <div style={{ margin: "25px 0 0 10px" }}>
            <FzButton
              fzStyle={"alert"}
              onClick={() => handleRemoveItem(`${field}-${type}-${format}-${size}-${mapField}`)}
            >-</FzButton>
          </div>
        </FzCol>
      </Fragment>
    )

    setFieldConnectedItens([...fieldConnectedItens, component])
  }

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <Jumbotron>
        <ButtonGroup>
          <Button
            variant="primary"
            onClick={handleSubmitAndCheckForm}
          >
            Gravar
          </Button>
          <Button
            variant="default"
            onClick={goBack}
          >
            Cancelar
          </Button>
        </ButtonGroup>
        <FzCardGroup bsPrefix="inputlayout-form">
          <FzCard>
            <FzCard.Header>
              <FzSplitLine>
                <FzSplitLine.Left>
                  <FzCard.Title>Criar novo Input Layout</FzCard.Title>
                </FzSplitLine.Left>
              </FzSplitLine>
            </FzCard.Header>

            <FzCard.Body>
              <FzRow>
                {RenderFormItem()}
                <FzCol span={1} />
                {fieldConnectedItens.map((ComponentItem, index) =>
                  <ComponentItem
                    key={index}
                    meta={metaDataForm}
                    handleRemoveItem={handleRemoveItem(index)}
                    ns={ns}
                  />
                )}
                <FzCol span={6}>
                  <Button onClick={handleAddFieldConnected}>Adicionar</Button>
                </FzCol>
              </FzRow>
            </FzCard.Body>
          </FzCard>
        </FzCardGroup>
        <FzToast {...toastInfo} close={handleToastClose} />
      </Jumbotron>
    </BlockUi>
  )
}

export default AddInputLayout;
