
import { MetaField } from '../../../types/metas';
import get from 'lodash/get';
import moment from 'moment';

export interface IBlockListRequest {
  type: string;
  value: string;
  doctype: string;
  fullblock: boolean;
}

export interface IBlockListResponse {
  type: string;
  value: string;
  doctype: string;
	created?: Date;
	updated?: Date;
	onblocklistuntil?: Date;
	onblocklist: boolean;
	daysleftonblocklist: number;
	entrycount: number;
}

export const MetaBlocklist: any = {
  getField(path: string): MetaField {
    return get(MetaBlocklist, path);
  },
  type: {
    path: 'type',
    label: 'Tipo de Documento',
    nameExportCsv: 'type',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'email', description: 'Email' },
        { code: 'telephone', description: 'Telefone' },
        { code: 'personalnumber', description: 'CPF' },
        { code: 'rg', description: 'RG' },
        { code: 'driver', description: 'CNH' },
        { code: 'professionalcouncil', description: 'Carteira de Conselho Profissional' },
        { code: 'rne', description: 'RNE' },
        { code: 'passport', description: 'Passaporte' },
        { code: 'id', description: 'Outro documento de identidade' },
      ], 
    },
    tableProps: {
      width: '6ch',
      dataSort: true,
      dataAlign: 'center',
      isKey: true,
      filter: {
        type: 'TextFilter',
        delay: 500,
      },
      editable: {
        type: "select",
        options: [
          { description: 'Email', code: 'email' },
          { description: 'Telefone', code: 'telephone' },
          { description: 'CPF', code: 'personalnumber' },
          { description: 'RG', code: 'rg' },
          { description: 'CNH', code: 'driver' },
          { description: 'Carteira de Conselho Profissional', code: 'professionalcouncil' },
          { description: 'RNE', code: 'rne' },
          { description: 'Passaporte', code: 'passport' },
          { description: 'Outro documento de identidade', code: 'id' },
        ],
        validatorName: 'fzTableEditSelectValidator',
      }
    },
    placeholder: "Selecione o tipo de documento...",
    isMandatory: () => true,
  },

  value: {
    path: 'value',
    label: 'Info do Documento',
    nameExportCsv: 'value',
    dataType: 'string',
    inputType: 'text',
    tableProps: {
      filter: {
        type: 'TextFilter',
        delay: 500,
      },
      width: '10ch',
      onlyModal: true,
      dataSort: false,
      dataAlign: 'center',
      isKey: false,
      editable: {
        type: 'text',
        validatorName: 'fzTableEditTextValidator'
      }
    },
    placeholder: "",
    isMandatory: () => true,
  },

  docType: {
    path: 'doctype',
    label: 'Subtipo de documento',
    nameExportCsv: 'docType',
    dataType: 'string',
    domainName: 'formalization-documents',
    tableProps: {
      width: '10ch',
      dataSort: true,
      hidden: true,
      isKey: false,
      dataAlign: 'center',
      filter: {
        type: 'TextFilter',
        delay: 500,
      },
    },
  },

  created: {
    path: 'created',
    label: 'Data de Entrada na Blocklist',
    dataType: 'date',
    tableProps: {
      width: '10ch',
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
      columnFormat: 'fullDateTimeColumn',
    },
  },

  daysinblocklist: {
    path: 'daysinblocklist',
    label: 'Dias para estar na Blocklist',
    dataType: 'number',
    tableProps: {
      width: '10ch',
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
    },
  },

  onblocklistuntil: {
    path: 'onblocklistuntil',
    label: 'Data a sair da Blocklist',
    dataType: 'date',
    tableProps: {
      width: '10ch',
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
      columnFormat: 'fullDateTimeColumn',
    },
  },

  outcount: {
    path: 'outcount',
    label: 'Contagem de dias (saída BL)',
    dataType: 'number',
    tableProps: {
      width: '10ch',
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
    },
  },

  entrycount: {
    path: 'entrycount',
    label: 'Passagens pela Blocklist',
    dataType: 'number',
    tableProps: {
      width: '10ch',
      dataSort: true,
      isKey: false,
      dataAlign: 'center',
    },
  },

  onblocklist: {
    path: 'onblocklist',
    label: 'Ativo na Blocklist',
    dataType: 'boolean',
    tableProps: {
      width: '6ch',
      dataSort: true,
      dataAlign: 'center',
      isKey: false,
      columnFormat: 'booleanColumn',
      filter: {
        type: 'SelectFilter',
        options: 'filters.boolean',
      },
      editable: {
        type: 'select',
        options: [
          { description: 'Sim', code: true },
          { description: 'Não', code: false },
        ],
      },
    },
  },

  fullblock: {
    path: 'fullblock',
    label: 'Tipo de bloqueio',
    dataType: 'boolean',
    tableProps: {
      width: '6ch',
      dataSort: true,
      dataAlign: 'center',
      hidden: true,
      isKey: false,
      columnFormat: 'booleanColumn',
      filter: {
        type: 'SelectFilter',
        options: 'filters.boolean',
      },
      editable: {
        type: 'select',
        options: [
          { description: 'Sim', code: true },
          { description: 'Não', code: false },
        ],
        validatorName: 'fzTableEditSelectValidator',
      },
    },
  },

  startDate: {
    path: 'startDate',
    label: 'De:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      maxValue: moment()
        .add('year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      minValue: moment()
        .add(-70, 'year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    },
    isMandatory: () => false,
  },
  endDate: {
    path: 'endDate',
    label: 'Até:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      maxValue: moment()
        .add('year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      minValue: moment()
        .add(-70, 'year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    },
    isMandatory: () => false,
  },
};
