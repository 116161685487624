import { currentApi } from "../../../api/axios-client-api";
import qs from 'qs'

const api = currentApi

export const GetUserDocumentsAnalyticsReport = async (page = 1, pageSize = 50, query = {}): Promise<any> => {
  let queryParse = qs.stringify({ ...query, page, pageSize })
  const serviceUrl = `user-documents/analytics?${queryParse}`;
  return api.Get(serviceUrl);
}
