import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import '../../fz/style/menu.css'


export class FzMenuAdvisorSales extends React.Component<any, any>   {

  constructor(props) {
    super(props)

    this.state = {
      stats: false,
      route_funnel: false,
      route_teste: false,
      route_selected: false
    }

    this.goToRoute = this.goToRoute.bind(this)
    this.showOptionsMenu = this.showOptionsMenu.bind(this)
  }

  goToRoute(route, name) {
    const { history } = this.props
    this.setState({ route_selected: name }, () => history.push(`/${route}`))
  }

  showOptionsMenu(name) {
    if (this.state.show_options !== name) {
      this.setState({ show_options: name })
    } else if (this.state.show_options === name) {
      this.setState({ show_options: undefined })
    }

  };

  render() {

    return (
      <div>

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
        
          <ListItem onClick={() => this.goToRoute("applications", "route_applications")} className={clsx("", {
            ["fz-route-selected"]: this.state.route_selected === "route_applications",
          })} >
            <ListItemText className={"item-menu"} primary="Buscar Propostas" />
          </ListItem>

          <ListItem button onClick={() => this.showOptionsMenu("stats")}>
            <ListItemText className={"item-menu"} primary="Estatísticas" />
            {this.state.show_options === "stats" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={this.state.show_options === "stats"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <ListItem button onClick={(e) => this.goToRoute("stats/funnel", "route_funnel")}
                className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === "route_funnel",
                })}>
                <ListItemText className={"sub-item-menu"} primary="Funnel" />
              </ListItem>

              <ListItem
                button
                onClick={() => this.goToRoute("stats/mkt", "route_mkt")}
                className={clsx("fz-list-item", { ["fz-route-selected"]: this.state.route_selected === "route_mkt" })}>
                <ListItemText className={"sub-item-menu"} primary="Applications" />
              </ListItem>

              {/* <ListItem button onClick={() => this.goToRoute("stats/sales", "route_sales")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "route_sales",
              })}>
                <ListItemText className={"sub-item-menu"} primary="CS" />
              </ListItem> */}

            </List>
          </Collapse>

          <ListItem onClick={() => this.goToRoute("admsuppliers", "route_admsuppliers")} className={clsx("", {
            ["fz-route-selected"]: this.state.route_selected === "route_admsuppliers"
          })}>
            <ListItemText className={"item-menu"} primary="Dados Parceiros" />
          </ListItem>

          <ListItem button onClick={() => this.showOptionsMenu('hermes')}>
            <ListItemText className={'item-menu'} primary="Hermes" />
            {this.state.show_options === 'hermes' ? (<ExpandLess />) : (<ExpandMore />)}
          </ListItem>

          <Collapse in={this.state.show_options === "hermes"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => this.goToRoute("hermes/tickets", "hermes_tickets")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "hermes_tickets"
              })}>
                <ListItemText className={"sub-item-menu"} primary="Ativo (Tickets)" />
              </ListItem>
              {/* <ListItem button onClick={() => this.goToRoute("stats/sales", "hermes_reports")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "hermes_reports"
              })}>
                <ListItemText className={"sub-item-menu"} primary="Relatórios" />
              </ListItem> */}
              <ListItem button onClick={() => this.goToRoute("sales/in", "route_receptive")} className={clsx("", {
                ["fz-route-selected"]: this.state.route_selected === "route_receptive"
              })}>
                <ListItemText className="sub-item-menu" primary="Receptivo" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu("blocklist")}>
            <ListItemText className={"item-menu"} primary="Blocklist" />
            {this.state.show_options === "blocklist" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={this.state.show_options === "blocklist"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => this.goToRoute("blocklist/search", "route_search")} className={clsx("fz-list-item", {
                ["fz-route-selected"]: this.state.route_selected === "route_search"
              })}>
                <ListItemText className={"sub-item-menu"} primary="Buscar Cliente" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => this.goToRoute("about", "route_about")} className={clsx("", {
            ["fz-route-selected"]: this.state.route_selected === "route_about"
          })}>
            <ListItemText className={"item-menu"} primary="Sobre" />
          </ListItem>
        </List>
      </div>
    )
  }
}
