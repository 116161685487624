import React from 'react';
import ApplicantSummary from '../../../application-components/applicant-summary'
import { PartnerDataPanel } from '../../../application-components/auction-info'
import { getApplicationsForAnalytics, getByID } from '../../../api/applications';
import { FzTable } from '../../fz/grid/table'
import { integrationAnalytic } from '../../../biz/metadatas/integration-metadata'
import moment from 'moment'
import { FzCard } from '../../fz/layout/index';
import { FzAlert } from '../../fz/form/alert';

const IntegrationDetails = ({ app, ns, supplier, isSupplier, ...props }) => {
  if (!app) {
    return null
  }
  return (<div>
    <PartnerDataPanel app={app} ns={ns} activeSupplier={supplier} isSupplier={isSupplier} showBadges={false} />
    <ApplicantSummary data={app} ns={ns} readOnly={true} />
  </div>)
}

const expandComponent = (integration, ns, supplier, isSupplier) => {
  return (
    <div>
      <IntegrationDetails app={ns.get(integration.id)} ns={ns} supplier={supplier} isSupplier={isSupplier} />
    </div>
  )
}

export class IntegrationAnalytics extends React.Component {

  constructor(props) {
    super(props);
    this.state = { requested: false }
    this.loadRowData = this.loadRowData.bind(this)
    this.getStatistics = this.getStatistics.bind(this)
  }


  loadRowData(rowKey, isExpand) {
    const { ns } = this.props


    if (isExpand && !ns.get(rowKey)) {
      getByID(rowKey).then((app) => {
        ns.set(rowKey, app)
      })
    }
  }

  customBadgesName(badgeDomain) {
    const data = {
      "phone-as-warranty": "EPGC"
    };
    return data[badgeDomain] || badgeDomain;
  }

  getStatistics(start, end) {
    const { supplier, channel, filters, ns } = this.props;
    this.setState({ requested: true }, async () => {
      this.props.toggleBlocking(async () => {

        var vailableDates = [];
        for (var date = moment(filters.startDate); date <= moment(filters.endDate); date.add(1, "days")) {
          const helpDate = moment(date)
          const periods = { startDate: moment(date).toISOString(), endDate: helpDate.add(1, "days").toISOString() }
          vailableDates.push(periods)
        }


        if (vailableDates.length > 33) {
          ns.saveChange("startDate", null);
          ns.saveChange("endDate", null);
          alert("Restringir a busca para até 30 dias")
          this.setState({ requested: false }, () => {
            this.props.toggleBlocking()
          })
          return null
        }

        const resultData = await Promise.all(vailableDates.map(async (it) => {
          try {
            return await getApplicationsForAnalytics(channel, supplier, it)
          } catch (error) {
            console.error(error)
            return { date: it.startDate, result: false }
          }
        }))

        let resultFlatData = resultData.filter(it => Array.isArray(it)).flat()
        resultFlatData = resultFlatData.map(data => {
          return {
            ...data,
            "supplierName": this.props.supplier,
            "badges": data.badges && Array.isArray(data.badges) && data.badges.length > 0 ? data.badges.map(domain => this.customBadgesName(domain)).join(", ") : ""
          }
        })

        const unavailableDates = resultData.filter(it => !Array.isArray(it))

        const resultDataSort = resultFlatData.sort(function (a, b) {
          if (a.created < b.created) {
            return 1;
          }
          if (a.created > b.created) {
            return -1;
          }
          return 0;
        });

        ns.set("analytics" + channel + start + "--" + end + supplier, resultDataSort)
        this.setState({ requested: false, errosDates: unavailableDates.length > 0 }, () => {
          this.props.toggleBlocking()
        });
      })
    })
  }

  customFormatterVehicleType(vehicleType) {
    const vehicleTypes = {
      "car": "Carro",
      "motorcycle": "Moto",
      "truck": "Caminhão",
    }

    return vehicleTypes[vehicleType] || vehicleType
  };

  render() {
    const { ns, supplier, history, filters, header, channel } = this.props;

    if (!supplier || (!filters.startDate || !filters.endDate)) {
      return null
    }

    let start
    let end

    if (filters.startDate instanceof moment) {
      start = filters.startDate._isValid ? filters.startDate.toISOString().split("T")[0] : null
    } else {
      start = filters.startDate.split("T")[0]
    }

    if (filters.endDate instanceof moment) {
      end = filters.endDate._isValid ? filters.endDate.toISOString().split("T")[0] : null
    } else {
      end = filters.endDate.split("T")[0]
    }

    let currentSupplier = ns.get("currentSupplier")
    let isSupplier = (currentSupplier === supplier)

    let data = ns.get("analytics" + channel + start + "--" + end + supplier)

    if (
      data === undefined &&
      !!filters.startDate &&
      !!filters.endDate &&
      !this.state.requested
    ) {
      this.getStatistics(start, end)
    }


    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h2">{header}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <div>
            {this.state.errosDates ? (<FzAlert fzStyle="attention">{
              "Atenção! Algumas datas não foram processadas"}</FzAlert>) : null}
          </div>
          <FzTable
            exportDownload={true}
            pagination
            ns={ns}
            context={"integrationAnalytic"}
            data={data}
            customColumnFormatters={{ vehicleType: (value) => this.customFormatterVehicleType(value) }}
            rowExpand={{
              expandComponent: (row) => expandComponent(row, ns, supplier, isSupplier),
              onExpandRow: (rowKey, isExpand) => this.loadRowData(rowKey, isExpand)
            }}
            history={history}
            metas={integrationAnalytic}
            visibleColumns={["id", "created", "supplierApplicationId", "applicationState", "branchCode", "apiAccepted", "approved", "rejected", "supplierName",
              "userAccepted", "badges", "appliedAmount", "approvedAmount", "paid", "annualInterestRate", "interestRate", "timeToBid", "rejectedReason", "notaCliente"]}
          />
        </FzCard.Body>
      </FzCard>
    );
  }
}

