import { FzAlert } from '../../../../fz/form/alert'
import { FzTable } from "../../../../fz/grid/table"
import { getAgentQueuesStats } from '../../../../../api/integrations'
import { agentQueuesSummary } from './queuesSummary-meta'

export function QueuesSummary (props) {
  
  const agent = props.agent
  const stats = props.ns.get(props.name)
  if (!stats) {
    getAgentQueuesStats(agent).then((s) => props.ns.set(props.name, s)).catch(() => props.ns.set(props.name, []));
    return (<FzAlert fzStyle="attention">Carregando mensagens do servidor...</FzAlert>);
  }

  return (
    <FzTable
      ns={props.ns}
      data={stats}
      metas={agentQueuesSummary}
      visibleColumns={["key", "queue", "qtty", "activeNow", "canceled", "timedOut", "attemptsAvg"]}
    />

  )
  
}