import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { overwriteDocumentsMeta } from '../../../../biz/metadatas/stats/screening/overwrite-documents';
import { FzSplitLine } from '../../../fz/layout/index';
import { FzCard } from '../../../fz/layout/FzCard';
import { FzNavSelect } from '../../../fz/nav';
import { StatsDetailHeader } from '../../../../statistics/stats-components';
import { FzTable } from '../../../fz/grid/table';
import { setTTL } from '../../../../business/misc-functions';
import { getOverwriteDocuments } from '../../../../services/api/screening';

interface filtersType {
  period: string,
}

function ScreeningOverwriteDocumentsSummaries({ ns, toggleBlocking }) {
  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)


  const filtersObj: filtersType = { period: "day" }
  const [filters, setFilters] = useState(filtersObj)

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getReport()
  }, [filters.period])

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])


  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getReport() {
    setRequestState("requesting")
    const DocResults = await getOverwriteDocuments(getFilters());
    ns.set("overwriteDocuments", DocResults.data.statistics, setTTL(5))
    ns.set("overwriteDocumentsResponse", DocResults)
    setRequestState("idle")
  }

  let dataResult = ns.get('overwriteDocuments')

  return (
    <FzCard>
      <FzCard.Body>
        <FzCard>
          <FzCard.Header >
            <FzCard.Title>
              <FzSplitLine>
                <FzSplitLine.Left />
                <FzSplitLine.Right>
                  <FzNavSelect
                    fzStyle={{ marginRight: 0 }}
                    value={filters.period}
                    options={[
                      { "code": "day", "description": "Daily" },
                      { "code": "week", "description": "Weekly" },
                      { "code": "month", "description": "Monthly" },
                    ]}
                    onChange={(value) => {
                      setFilters({ ...filters, period: value })
                    }}
                  />
                </FzSplitLine.Right>
              </FzSplitLine>
              <div className="mt-3">
                <StatsDetailHeader
                  title="Documentos alterados"
                  stats={ns.get("overwriteDocumentsResponse")}
                  periods={null}
                  currPeriod={null}
                  onChange={null}
                />
              </div>
            </FzCard.Title>
          </FzCard.Header>
        </FzCard>
        <div className={'mt-3'}>
          <FzTable
            data={dataResult}
            metas={overwriteDocumentsMeta}
            context={'overwriteDocuments'}
            pagination
            ns={ns}
          />
        </div>
      </FzCard.Body>
    </FzCard >
  )
}

export { ScreeningOverwriteDocumentsSummaries }