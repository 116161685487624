import { Meta } from "../../types/metas";

export const PathnameForm = {
  "$group": "us",
  "$name": "Pathname",
  "$span": 12,
  "$namePath": "path",
  "_id": {
    "span": 6,
    'path': "_id",
    'dataType': 'string',
    'label': "id",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': () => true,
    'isVisible': () => false,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "path": {
    path: 'path',
    dataType: 'text',
    label: 'Path',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  "elements.$": {
    "$path": "elements",
    "$name": "Elements",
    "$namePath": "elements.$.key",
    "elements.$.filterName": {
      path: 'elements.$.filterName',
      dataType: 'text',
      label: 'Filtro',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'select',
      section: 'pathnameForm',
      inputProps: {
        domainName: "filters",
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: undefined,
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => false,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "elements.$.key": {
      path: 'elements.$.key',
      dataType: 'text',
      label: 'Chave',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'text',
      section: 'pathnameForm',
      inputProps: {
        domainName: undefined,
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: 100,
        minSize: 1,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: "Chave obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "elements.$.value": {
      span: 6,
      path: 'elements.$.value',
      dataType: 'text',
      label: 'Valor',
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'text',
      section: 'pathnameForm',
      inputProps: {
        domainName: "upsell-banners",
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: 100,
        minSize: 1,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: ["elements.$.filterInsideScope"],
      validationMessage: "Chave obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "elements.$.filterInsideScope": {
      path: 'elements.$.filterInsideScope',
      dataType: 'bool',
      label: 'Filtrar somente id na url',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      section: 'pathnameForm',
      "inputType": "select",
      "inputProps": {
        "options": [
          { "code": true, "description": "Sim" },
          { "code": false, "description": "Não" },
        ],
        domainName: undefined,
        filteredFrom: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: 100,
        minSize: 1,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: "Chave obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "elements.$.applyToAllApplications": {
      path: 'elements.$.applyToAllApplications',
      dataType: 'bool',
      label: 'Aplicar filtro a todas as applications do usuário',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      section: 'pathnameForm',
      "inputType": "select",
      "inputProps": {
        "options": [
          { "code": true, "description": "Sim" },
          { "code": false, "description": "Não" },
        ],
        domainName: undefined,
        filteredFrom: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: 100,
        minSize: 1,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: "Chave obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
  },
  "components.$": {
    "$path": "components",
    "$name": "Components",
    "$namePath": "components.$.title",
    "components.$.filterName": {
      path: 'components.$.filterName',
      dataType: 'text',
      label: 'Filtro',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'select',
      section: 'pathnameForm',
      inputProps: {
        domainName: "filters",
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: undefined,
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => false,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "components.$.component": {
      path: 'components.$.component',
      dataType: 'text',
      label: 'Componente',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'select',
      section: 'pathnameForm',
      inputProps: {
        domainName: "components",
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: "Componente obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "components.$.variant": {
      path: 'components.$.variant',
      dataType: 'text',
      label: 'Variante',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'select',
      section: 'pathnameForm',
      inputProps: {
        domainName: "componentVariants",
        filteredFrom: "components.$.component",
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: undefined,
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "components.$.title": {
      path: 'components.$.title',
      dataType: 'text',
      label: 'Título',
      span: 6,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'text',
      section: 'pathnameForm',
      inputProps: {
        domainName: undefined,
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: 100,
        minSize: 1,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: "Título obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => false,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "components.$.subtitle": {
      span: 6,
      path: 'components.$.subtitle',
      dataType: 'text',
      label: 'Subtítulo',
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'text',
      section: 'pathnameForm',
      inputProps: {
        domainName: "upsell-banners",
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: 100,
        minSize: 1,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: "Chave obrigatório",
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      product: undefined,
      isMandatory: value => false,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
  }
}

export const PathnameColumns: Meta = {
  "_id": {
    "path": "_id",
    "label": "Id",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "path": {
    "path": "path",
    "label": "Path",
    "tableProps": {
      "isKey": false,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "createdOn": {
    "path": "createdOn",
    "label": "Criado em",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": () => false,
  },
  "updatedOn": {
    "path": "updatedOn",
    "label": "Atualizado em",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": () => false,
  },
  "linkPath": {
    "path": "linkPath",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
