import React, { Component } from 'react';
import { agentQueueAnalytic } from "../../../biz/metadatas/integration-metadata"
import { getApplicationTraces, getAgentActiveQueueEntries, getAgentTimeOutQueueEntries, getAgentCanceledQueueEntries } from '../../../api/integrations';
import { Button } from 'react-bootstrap'
import { FzTable } from "../../fz/grid/table"
import { TraceModal, QueueDetails } from '../integration-queues'
import get from 'lodash/get'
import api from '../../../api/client-api';
import { FzCard } from '../../fz/layout/index';
import { FzAlert } from '../../fz/form/alert';

export class ListQueue extends Component {
  constructor(props) {
    super(props)
    this.actionsColumn = this.actionsColumn.bind(this)
    this.showTrace = this.showTrace.bind(this)
    this.state = { showTraces: false }
    this.expandComponent = this.expandComponent.bind(this)
  }
  showTrace(appId, agent, queue, doc) {
    this.setState({ showTraces: true, appId: appId, queue: queue })
    getApplicationTraces(appId, agent, queue, doc).then((trace) => this.setState({ trace: trace }))
  }

  actionsColumn(cell, row) {
    if (api.isITUser()) {
      let buttons = []
      buttons.push(
        <Button onClick={(e) => { e.stopPropagation(); this.showTrace(row.applicationId, this.props.agent, this.props.queue, get(row, this.props.extraField ? this.props.extraField.field : "__")) }} bsSize="xsmall">trace</Button>
      )
      return <div>{buttons}</div>
    }
    return ""
  }

  expandComponent(row) {
    return (< QueueDetails queue={row} />)
  }

  render() {
    let { agent, queue, queryType, ns } = this.props
    let queueEntries = this.props.ns.get(queue)
    if (!queueEntries) {
      if (queryType === "openQueues") {
        getAgentActiveQueueEntries(agent, queue).then((qe) => this.props.ns.set(queue, qe)).catch(() => this.props.ns.set(queue, []))
      } else if (queryType === "timedOut") {
        getAgentTimeOutQueueEntries(agent, queue).then((qe) => this.props.ns.set(queue, qe)).catch(() => this.props.ns.set(queue, []))
      }
      else if (queryType === "canceled") {
        getAgentCanceledQueueEntries(agent, queue).then((qe) => this.props.ns.set(queue, qe)).catch(() => this.props.ns.set(queue, []))
      }
      else if (queryType === "summary24h") {
        getAgentCanceledQueueEntries(agent, queue).then((qe) => this.props.ns.set(queue, qe)).catch(() => this.props.ns.set(queue, []))
      }
      return (<FzAlert fzStyle="attention">Carregando mensagens do servidor...</FzAlert>);
    }
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">{queue}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <TraceModal show={this.state.showTraces} traceContent={this.state.trace} onHide={() => this.setState({ showTraces: false })} />
          <FzTable
            data={queueEntries}
            metas={agentQueueAnalytic}
            ns={ns}
            context="agentQueues"
            rowExpand={{ expandComponent: (row) => this.expandComponent(row) }}
            customColumnFormatters={{ "trace": (_, row) => this.actionsColumn(_, row) }}
            visibleColumns={["_id", "queue", "created", "updated", "active", "locked", "canceled", "attempts", "maxAttempts", "trace"]}
            pagination
            exportCSV
          />
        </FzCard.Body>
      </FzCard>
    )

  }
}