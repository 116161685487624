import React, { Component } from 'react';
import SplitLine from "../../../components/split-line";
import { Link } from "react-router-dom";
import { FzCard, FzJumbotron } from '../../fz/layout/index';
import { getAllTasks } from '../../../api/integrations';
import { FzTable } from "../../fz/grid/table";
import { cronTasks } from "../../../biz/metadatas/crontask"
import { FzIcons } from '../../fz/form/icon';
import { FzButton } from '../../fz/form';
import api from '../../../api/client-api'
import { toggleDisableTask, deleteTask } from "../../../api/integrations";
import { Modal } from 'react-bootstrap'

export class CronConsole extends Component {

  constructor(props) {
    super(props);
    this.state = { requestState: "idle" };
    this.getTasks = this.getTasks.bind(this);
    this.expandComponent = this.expandComponent.bind(this)
    this.logsColumn = this.logsColumn.bind(this)
    this.taskColumn = this.taskColumn.bind(this)
    this.scheduleColumn = this.scheduleColumn.bind(this)
    this.doNormalizaed = this.doNormalizaed.bind(this)
    this.switchStatus = this.switchStatus.bind(this)
    this.actionButton = this.actionButton.bind(this)
    this.deleteTask = this.deleteTask.bind(this)
    this.onRowSelect = this.onRowSelect.bind(this)
    this.createTask = this.createTask.bind(this)
  }

  expandComponent(task) {
    return (
      <FzCard>
        <FzCard.Body>
          Body:
          <pre>{JSON.stringify(task.action.body, " ", 1)}</pre>
        </FzCard.Body>
      </FzCard>
    )
  }

  getTasks() {
    const { ns } = this.props;
    this.setState({ requestState: "requesting" }, () => {
      getAllTasks()
        .then((data) => {
          const dataNormalized = this.doNormalizaed(data);
          ns.set("tasks", dataNormalized, -1);
          this.setState({ requestState: "idle" });
        })
        .catch((err) => {
          console.warn("API ERROR", err);
        });
    });
  }

  componentDidMount() {
    this.getTasks()
  }

  logsColumn(cell, row) {
    if (cell === true) {
      return <strong>desabilitado</strong>;
    }

    const baseURL = 'https://sa-east-1.console.aws.amazon.com/cloudwatch/home';

    let logGroupEnv;
    if (window.finanzero.isBeta) logGroupEnv = 'beta';
    else if (window.finanzero.isProd) logGroupEnv = 'prod';
    else logGroupEnv = 'dev';

    const queryStringParams = {
      region: 'sa-east-1',
      group: `manager-${logGroupEnv}`,
    };

    const anchor = `logsV2:log-groups/log-group/manager-${logGroupEnv}/log-events`;
    const filter = `$3FfilterPattern$3D$257B$2524.fz_metric$253D$2522CRON_EXECUTED$2522+$2526$2526+$2524.metric_val$253D$2522${row.name}$2522$257D`;
    const period = '$26start$3D-86400000';
    const queryString = new URLSearchParams(queryStringParams).toString();

    const encodedURL = encodeURI(
      `${baseURL}?${queryString}#${anchor}${filter}${period}`
    ).toString();

    return (
      <a href={encodedURL} target="_blank" rel="noopener noreferrer">
        logs cloudwatch
      </a>
    );
  }

  taskColumn(cell, row) {
    return <Link to={"/manager/cron/" + cell} title={"Editar " + cell}>
      {cell}
    </Link>
  }

  createTask() {

    let newPath = this.props.ns.namespace + "/new"
    let newNs = this.props.ns.copy(newPath)
    const data = { "_id": "new" }
    newNs.startEditing(data)
    this.props.history.push(newPath)
    this.props.ns.unset("users")

  }

  scheduleColumn(cell) {
    const keys = ['min', 'hour', 'day', 'month', 'weekDay'];
    if (cell && keys.every((key) => cell[key] !== undefined)) {
      return <pre>{keys.map((key) => cell[key]).join(' ')}</pre>;
    }
  }

  async deleteTask() {
    const { ns } = this.props;
    const taskName = ns.get("selected")[0]
    await deleteTask(taskName)
    await this.getTasks()
    this.setState({ showModal: false });
  }

  doNormalizaed(tasks) {
    tasks = tasks.map(it => {
      return { switchStatus: it.disabled, ...it };
    });
    return tasks;
  }
  switchStatus(cell, row) {
    let isNotItUser = !api.isITUser();

    return (
      <>
        {cell === true ? (
          <>
            <FzButton
              fzStyle="attention"
              disabled={isNotItUser}
              onClick={() => this.actionButton(row)}
            >
              <FzIcons
                icon={'play-circle'}
                fzStyle={{ width: '15px', height: '15px' }}
              />
            </FzButton>
            <FzButton
              fzStyle="alert"
              onClick={() => this.setState({ showModal: true })}
            >
              <FzIcons
                icon={'trash'}
                fzStyle={{ width: '15px', height: '15px' }}
              />
            </FzButton>

            <Modal
              size="sm"
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
              backdrop={false}
              style={{ top: '50px', transform: 'none', marginTop: '0' }}
            >
              <Modal.Body>
                <div>Tem certeza que deseja excluir essa Task? </div>
              </Modal.Body>
              <Modal.Footer>
                <div
                  style={{
                    alignContent: 'center',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  <FzButton
                    fzStyle="discrete"
                    onClick={() => this.setState({ showModal: false })}
                  >
                    Cancelar
                  </FzButton>
                </div>
                <div style={{ margin: 'auto' }}>
                  <FzButton fzStyle="alert" onClick={this.deleteTask}>
                    Excluir
                  </FzButton>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <FzButton
            fzStyle="primary"
            bsSize="small"
            disabled={isNotItUser}
            onClick={() => this.actionButton(row)}
          >
            <FzIcons
              icon={'pause-circle'}
              fzStyle={{ width: '15px', height: '15px' }}
            />
          </FzButton>
        )}
      </>
    );
  }

  async actionButton(row) {

    try {
      await toggleDisableTask(row.name)
      await this.getTasks()
    }
    catch (e) {
      console.err("Erro ao tentar toggleDisableTask  e getTasks", e)
    }
  }

  onRowSelect({ name }, isSelected) {
    const { ns } = this.props;

    if (isSelected) {
      ns.push("selected", name);
    } else {
      ns.pull("selected", name);
    }
  }

  render() {

    let { ns } = this.props;
    let tasks = ns.get("tasks");

    const selectRowProp = {
      multiple: false,
      clickToExpand: true,
      onSelect: this.onRowSelect,
    };

    return (<FzJumbotron>
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">
            <SplitLine>
              <SplitLine.Left>Tarefas agendadas</SplitLine.Left>
              <SplitLine.Right>
                <span hidden={this.state.requestState !== "requesting"}>Atualizando</span>
                <FzButton onClick={() => this.getTasks()}>< FzIcons icon={"repeat"} fzStyle={{ width: "15px", height: "15px" }} /></FzButton>
                <FzButton onClick={() => this.createTask()}>Criar</FzButton>
              </SplitLine.Right>
            </SplitLine>
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            ns={ns}
            data={tasks}
            visibleColumns={["switchStatus", "name", "disabled", "schedule.schedule", "action.target", "action.queueName", "action.path","description"]}
            rowDisabled={(row) => row.disabled}
            customColumnFormatters={{
              "switchStatus": this.switchStatus,
              "schedule": this.scheduleColumn,
              "name": this.taskColumn,
              "disabled": this.logsColumn
            }}
            pagination
            rowSelection={selectRowProp}
            metas={cronTasks}
            clipboard={false}
            exportDownload={false}
          />
        </FzCard.Body>
      </FzCard>
    </FzJumbotron>
    )
  }


}
