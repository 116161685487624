import { getAverageResponseTime } from '../../../../../api/integrations';
import { FzTable } from "../../../../fz/grid/table"
import { FzAlert } from '../../../../fz/form/alert';
import { responseTimeSummaryMeta } from './responseTimeSumary-meta';

export function ResponseTimeSummary(props) {

  let agent = props.agent
  let stats = props.ns.get(props.name)

  if (!stats) {
    getAverageResponseTime(agent).then((s) => props.ns.set(props.name, s)).catch(() => props.ns.set(props.name, []));
    return (<FzAlert fzStyle="attention">Carregando mensagens do servidor...</FzAlert>);
  }

  return (
    <FzTable
      data={stats}
      metas={responseTimeSummaryMeta}
      ns={props.ns}
      visibleColumns={["key", "queue", "period", "qtty", "timeMin", "timeMax", "timeAvg"]}
    />

  )

}