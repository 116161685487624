import { useCallback } from 'react';
import './style.css';

import {
  Query,
  Builder,
  Utils as QbUtils,
} from '@react-awesome-query-builder/mui';
import '@react-awesome-query-builder/mui/css/styles.css';

const QueryBuilder = ({ config, state, setState, ns, isQueue, count }) => {
  const onChange = useCallback((immutableTree, config) => {
    const jsonTree = QbUtils.getTree(immutableTree);    
    setState(() => ({
      tree: immutableTree,
      config: config,
    }));
    count += 1
    if(isQueue && jsonTree.children1.length === 0 && count > 2) {
      ns.saveChange('currentQueue.jsonLogic', {});
    }
  }, []);

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container">
        <div className="qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  return (
    <Query
      {...config}
      value={state.tree}
      onChange={onChange}
      renderBuilder={renderBuilder}
    />
  );
};
export default QueryBuilder;
