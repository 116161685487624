
import { Meta } from "../../types/metas"

export const salesSummaries = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",

      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
      "isKey": null,
      "exportFormat": false,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "queue": {
    "path": "queue",
    "dataType": "text",
    "label": "Motivo da Fila",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "20em",
      "filter": { "type": 'SelectFilter', "options": "salesQueueDictionary" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getSalesQueue",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "agent": {
    "path": "agent",
    "dataType": "text",
    "label": "Agente",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "15em",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "product": {
    "path": "product",
    "dataType": "text",
    "label": "Product",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "6em",
      "filter": { type: 'SelectFilter', options: "filters.product", condition: 'eq' },
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "treatment": {
    "path": "treatment",
    "dataType": "domain",
    "label": "Treatments",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "15em",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "getTreatments",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "outcome": {
    "path": "outcome",
    "dataType": "domain",
    "label": "Resultado",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "15em",
      "filter": { "type": 'SelectFilter', "options": "buildFilterOutcome" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getOutcome",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "quantity": {
    "path": "quantity",
    "dataType": "number",
    "label": "Quantidade",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "10em",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "startedBy": {
    "path": "startedBy",
    "dataType": "text",
    "label": "Iniciado por",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "averageAmount": {
    "path": "averageAmount",
    "dataType": "currency",
    "label": "Valor médio",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "serasaScore": {
    "path": "serasaScore",
    "dataType": "number",
    "label": "Score médio",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "salesPriority": {
    "path": "salesPriority",
    "dataType": "number",
    "label": "Prioridade média",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "acceptedBy": {
    "path": "acceptedBy",
    "dataType": "text",
    "label": "Aceito por",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "averageApplied": {
    "path": "averageApplied",
    "dataType": "dataType",
    "label": "Average applied amount",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "averageAccepted": {
    "path": "averageAccepted",
    "dataType": "dataType",
    "label": "Average accepted amount",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },

}

export const salesCallPerAgent: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
    'isMandatory': (value) => false,
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    'isMandatory': (value) => false,
  },
  "queue": {
    "path": "queue",
    "dataType": "string",
    "label": "Motivo da Fila",
    "tableProps": {
      "viewsTable": "",
      "width": "20em",
      "filter": { "type": 'SelectFilter', "options": "salesQueueDictionary" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getSalesQueue",
    },
    'isMandatory': (value) => false,
  },
  "agent": {
    "path": "agent",
    "dataType": "string",
    "label": "Agente",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "center",
    },
    'isMandatory': (value) => false,
  },
  "outcome": {
    "path": "outcome",
    "dataType": "string",
    "label": "Resultado",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": { "type": 'SelectFilter', "options": "buildFilterOutcome" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getOutcome",
    },
    'isMandatory': (value) => false,
  },
  "quantity": {
    "path": "quantity",
    "dataType": "number",
    "label": "Quantidade",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
}

export const salesCallPerTreatments: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
    'isMandatory': (value) => false,
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    'isMandatory': (value) => false,
  },
  "agent": {
    "path": "agent",
    "dataType": "string",
    "label": "Agente",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "center",
    },
    'isMandatory': (value) => false,
  },
  "quantity": {
    "path": "quantity",
    "dataType": "number",
    "label": "Quantidade",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "product": {
    "path": "product",
    "dataType": "string",
    "label": "Product",
    "tableProps": {
      "viewsTable": "",
      "width": "6em",
      "filter": { type: 'SelectFilter', options: "filters.product", condition: 'eq' },
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "treatment": {
    "path": "treatment",
    "dataType": "string",
    "label": "Treatments",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "getTreatments",
    },
    'isMandatory': (value) => false,
  },
}

export const salesProductChanges: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
    'isMandatory': (value) => false,
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    'isMandatory': (value) => false,
  },
  "quantity": {
    "path": "quantity",
    "dataType": "number",
    "label": "Quantidade",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "oldProduct": {
    "path": "oldProduct",
    "dataType": "string",
    "label": "Produto Original",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "filters.product", condition: 'eq' },
      "dataSort": true,
      "dataAlign": "center",
    },
    'isMandatory': (value) => false,
  },
  "newCP": {
    "path": "newCP",
    "dataType": "number",
    "label": "Trocados por CP",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "newRefin": {
    "path": "newRefin",
    "dataType": "number",
    "label": "Trocados por Refin",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "newFin": {
    "path": "newFin",
    "dataType": "number",
    "label": "Trocados por Fin",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "newHE": {
    "path": "newHE",
    "dataType": "number",
    "label": "Trocados por HE",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
}

export const productivitySummary: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
    'isMandatory': (value) => false,
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    'isMandatory': (value) => false,
  },
  "agent": {
    "path": "agent",
    "dataType": "string",
    "label": "Agente",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "center",
    },
    'isMandatory': (value) => false,
  },
  "count_opportunities": {
    "path": "count_opportunities",
    "dataType": "number",
    "label": "Oportunidades",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "count_uploads": {
    "path": "count_uploads",
    "dataType": "number",
    "label": "Número de uploads feito",
    "tableProps": {
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "count_paid": {
    "path": "count_paid",
    "dataType": "number",
    "label": "Pagos Total",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
  "count_uploads_paid": {
    "path": "count_uploads_paid",
    "dataType": "number",
    "label": "Pagos com suporte de upload",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "dataSort": true,
      "dataAlign": "right",
    },
    'isMandatory': (value) => false,
  },
}