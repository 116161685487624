import { Meta } from "../../types/metas";
import moment from 'moment'

const normalizeDate = (value) => (
  typeof (value) === "object" && value !== null 
    ? value.toISOString() 
    : value
)

export const emailHistories: Meta = {
  "from": {
    "path": "from",
    "label": "Desde (0h)",
    "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: normalizeDate
  },
  "to": {
    "path": "to",
    "label": "Até",
    "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "inputProps": {
      "maxValue": moment().add(+2, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: normalizeDate
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "inputType": "text",
    "dataType": "string",
    "isMandatory": () => false,
    "isReadOnly": () => false,
  },
  "email": {
    "path": "email",
    "label": "E-mail",
    "inputType": "text",
    "dataType": "string",
    "isMandatory": () => false,
    "isReadOnly": () => false,
  },
  "applicationId": {
    "path": "applicationId",
    "label": "applicationId",
    "inputType": "text",
    "dataType": "id",
    "isMandatory": () => false,
    "isReadOnly": () => false,
  },
}

export const emailHistoriesColumns: Meta = {
  "eventName": {
    "path": "eventName",
    "label": "Status",
    "tableProps": {
      "hidden": false,
      "filter": { 
        "type": "SelectFilter", 
        "options": "buildEventNamesFilters"
      },
      "width": "4rem",
      "dataSort": true,
      "isKey": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "eventTimestamp": {
    "path": "eventTimestamp",
    "label": "Data e horário",
    "tableProps": {
      "columnFormat": "formatDateWithTime",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "event": {
    "path": "event",
    "label": "Nome do evento",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "template": {
    "path": "template",
    "label": "Template Sendgrid",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "supplier": {
    "path": "supplier",
    "label": "Parceiro",
    "tableProps": {
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
