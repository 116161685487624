import { Meta, MetaField } from '../../types/metas';

const fzscoresMeta : Meta = {
  ...["0 .. 100", "100 .. 200", "200 .. 300", "300 .. 400", "400 .. 500", "500 .. 600", "600 .. 700", "700 .. 800", "800 .. 900", "900 .. 1000"].reduce((acc, item) => ({
    ...acc,
    [item]: {
      "path": "_id.score",
      "dataType": "string",
      "label": item,
      isMandatory: () => false,
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": null,
        "width": "12ch",
        "dataSort": true,
        "dataAlign": "center",
        "columnFormat": null,
        "isKey": false,
        "exportFormat": null,
      },
    }
  }), {})
};

export const fzscore_meta : any = {
  "supplier": {
    "path": "supplier",
    "dataType": "string",
    "label": "ID",
    isMandatory: () => true,
    "tableProps": {
      "viewsTable": undefined,
      "hidden": true,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "count-bids": {
    "path": "count-bids",
    "dataType": "number",
    "label": "Quantidade de bids",
    isMandatory: () => true,
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": false,
    }
  },
  ...(fzscoresMeta), //TODO: Verificar tipo de fzscoresMeta
};
