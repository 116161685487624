import React from 'react'
import api from '../../api/client-api';
import { acceptBid, updateAcceptedBid } from '../../api/applications';
import { FzTable } from '../fz/grid/table';
import { alternateOffersMeta } from '../../biz/metadatas/alternate-offers'
import { FzButton } from '../fz/form';
import { Modal } from 'react-bootstrap';
import { FzAlert } from '../fz/form/alert';
import { AcceptBidModal } from '../../Containers/Applications/AuctionsBids';
import { FzToast } from '../fz/form/notification/toast';
const AUCTION_ACTION_ACCEPT_BID = 'accept-bid';


export class AlternateOffers extends React.Component {

  constructor(props) {
    super(props)
    this.state = { confirmationStepModal: null, acceptError: { show: false, msg: "" }}
    this.acceptOffer = this.acceptOffer.bind(this)
    this.bidAction = this.bidAction.bind(this)
  }

  acceptOffer(offerId) {
    const { bid, ns, app } = this.props
    let changeBid = Boolean(bid.accepted);

    let accepted = bid.accepted ?
      updateAcceptedBid(app.id, bid.id, { offerId }, changeBid) :
      acceptBid(app.id, bid.id, { offerId }, changeBid);

    this.setState({confirmationStepModal: null})
    accepted.then((newapp) => {
      const customerApplications = ns.get("customer.applications")
      const index = customerApplications.findIndex((it) => it.id === app.id)

      ns.set('application', newapp);
      ns.set(`customer.applications[${index}]`, newapp)

      let treatment = ns.get("crm.treatments." + app.id) ? ns.get("crm.treatments." + app.id) + " * Bid aceito" : "Bid aceito";
      let treatmentArray = ns.get("crm.treatmentsArray." + app.id) ? ns.get("crm.treatmentsArray." + app.id) : [];
      treatmentArray.push(AUCTION_ACTION_ACCEPT_BID);

      ns.set("crm.treatments." + app.id, treatment);
      ns.set("crm.treatmentsArray." + app.id, treatmentArray);
    })
      .catch((error) => {
        this.setState({acceptError: {
          show: true, 
          msg: error?.includes("application has incomplete confirmationSteps") ? 
            "Application possui confirmtionSteps pendentes de preenchimento" : 
            "Erro ao salvar application"
        }})
        console.warn("Error in acceptBid", error) 
      })
  }

  bidAction(offerId, offer, formatExtraData, idx) {
    const { bid, ns, app } = this.props

    if (!ns.isEditing() && api.isUserInRole("advisor") && !bid.expired) {
      if (!bid.accepted || bid.offerId !== offerId) {
        return (
        <FzButton
          fzStyle="regular"
          size="sm"
          onClick={() => {
            if(app.supplierData[bid.supplier.internalName].confirmationSteps.length && app.supplierData[bid.supplier.internalName].confirmationSteps.find(cs => !cs.completed)) { 
              this.setState({confirmationStepModal: offerId});
            } else {
              this.acceptOffer(offerId)
            }
          }
          }>
          Aceitar esta oferta
        </FzButton>)
      } else {
        return (<FzButton
          fzStyle="regular"
          disabled={true}
          size="sm">
          Oferta aceita
        </FzButton>)
      }
    }
    return null
  }

  render() {
    const { ns, bid } = this.props

    let alternateOffers = bid.alternateOffers

    return (
      <>
        <FzTable
          data={alternateOffers}
          exportDownload={false}
          clipboard={false}
          metas={alternateOffersMeta}
          ns={ns}
          visibleColumns={[
            "approvedAmount",
            "monthlyInstallment",
            "approvedPeriodMonths",
            "interestRate",
            "totalAnnualInterestRate",
            "paymentMethod",
            "offerId",
          ]}
          customColumnFormatters={{
            offerId: (cell, row, formatExtraData, _) => this.bidAction(cell, row, formatExtraData, _)
          }}
        />
        <AcceptBidModal onHide={() => this.setState({confirmationStepModal: null})} acceptBid={this.acceptOffer} confirmationStepModal={this.state?.confirmationStepModal} />
        {this.state.acceptError?.show && <FzToast
          fzStyle={"danger"}
          show={true}
          delay={5000}
          headerMessageTost={'Alerta'}
          bodyMessageTost={this.state.acceptError.msg}
          autoHide={true}
          close={() => this.setState({acceptError: {show: false, msg: ""}})}
        />}
      </>
    )
  }
}