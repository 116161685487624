import { FzToast } from '../../ui/fz/form/notification/toast';
import { FzTable } from '../../ui/fz/grid/table';

export const BlocklistSearchContainers = ({ data, ns, meta, toast }) => {
  return (
    <>
      {data && data.length > 0 && (
        <FzTable
          data={data}
          ns={ns}
          metas={meta}
          exportDownload={false}
          clipboard={false}
        />
      )}

      <FzToast {...toast} />
    </>
  );
};