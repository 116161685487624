import React, { ReactNode } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import get from 'lodash/get';
import { Namespace } from '../../../../scripts/state/redux_ns';
import { FzTableCustomToolBar, getVisibleColumns } from '../toolbar-custom';
import hooks from '../../../fz/formatter/column-formatter';
import { getDomain } from '../../../../scripts/utils/cache-helpers';
import { FzTableOptionsType, FzTableProps } from './types';
import { RenderFormBuild } from './EditCell';
import {
  createInsertButton,
  createModalFooter,
  createOptionsInsertRow,
} from './InserModal/customModal';
import { FzIcons } from '../../form/icon';
import './style.css';
import {
  InsertModalHeader,
} from 'react-bootstrap-table';

export function FzTableEdit({
  ns,
  data = [],
  rowExpand,
  context,
  instance,
  rowSelection,
  rowDisabled,
  trClassFunction,
  metas,
  customColumnFormatters,
  visibleColumns,
  exportDownload,
  clipboard,
  selectColumns,
  hasSubsetVisibleColumns,
  search = true,
  cellEditConfig,
  importCsv,
  onInsertRow,
  titleModal,
  insertModalBody
}: FzTableProps) {
  let newInstance;
  if (instance) {
    newInstance = context + '_' + instance;
  }
  const columnsPaths = getVisibleColumns(
    visibleColumns,
    metas,
    ns,
    context,
    newInstance,
    hasSubsetVisibleColumns
  );
  const forceDataToNotBeUndefined = data || []; //TODO - Search for a better strategy to ensure that the data is not undefined

  let expandComponent;
  let expandableRow;
  let onExpandRow;

  let cellEdit = {
    mode: 'click',
    blurToSave: false,
    beforeSaveCell: cellEditConfig.onBeforeSaveCell,
    afterSaveCell: cellEditConfig.onAfterSaveCell,
  };

  if (rowExpand) {
    expandComponent = rowExpand.expandComponent;

    // If expandableRow is ommited, defaults to true
    expandableRow = rowExpand.expandableRow || (() => true);

    if (rowExpand.onExpandRow) {
      onExpandRow = rowExpand.onExpandRow;
    }
  }

  let selectRow;
  if (rowSelection) {
    selectRow = {
      mode: rowSelection.multiple ? 'checkbox' : 'radio',
      clickToExpand: true,
      clickToSelect: true,
      onSelect: rowSelection.onSelect,
      onSelectAll: rowSelection.onSelectAll,
      selected: rowSelection.selectedKeys,
    };
  }

  function createSpanColumns(
    columns: $TsFixMe[],
    columnNumber: number,
    colObject: $TsFixMe
  ) {
    const extraColumns = colObject.tableProps.spanColumns.columns.map(
      (column, index) => {
        const path = column.path ? column.path : colObject.path;
        const formatExtraData = column.formatExtraData
          ? column.formatExtraData
          : colObject.tableProps.formatExtraData;

        return (
          <TableHeaderColumn
            key={columnNumber + index + 1}
            dataAlign={column.dataAlign || 'center'}
            title={column.label}
            dataField={path}
            dataFormat={(cell, row, _, idx) => {
              const hook = hooks[column.columnFormat];
              cell = get(row, path);
              return hook(cell, row, formatExtraData, idx);
            }}
            formatExtraData={formatExtraData}
            row={column.row}
            width={column.width}
            colSpan={column.colSpan}
          >
            {column.label}
          </TableHeaderColumn>
        );
      }
    );
    columns = [...columns, ...extraColumns];
    columnNumber += colObject.tableProps.spanColumns.columns.length;
    return { columns, columnNumber };
  }

  function createColumns(
    colObject: $TsFixMe,
    ns: Namespace,
    data: any,
    customColumnFormatters: $TsFixMe,
    columnNumber: number,
    columns: ReactNode[],
    hasSpanColumns: boolean,
    rowSpan?: string
  ) {
    const dataFormat = (cell, row, _, idx) => {
      cell = get(row, colObject.path, cell);
      if (customColumnFormatters && customColumnFormatters[colObject.path]) {
        cell = customColumnFormatters[colObject.path](
          cell,
          row,
          colObject.tableProps.formatExtraData,
          idx,
          ns
        );
      } else if (hooks[colObject.tableProps.columnFormat]) {
        cell = hooks[colObject.tableProps.columnFormat](
          cell,
          row,
          colObject.tableProps.formatExtraData,
          idx,
          ns
        );
      }

      return (
        <>
          {colObject.tableProps.editable && !colObject.tableProps.isKey && !colObject.tableProps.onlyModal && (
            <FzIcons
              icon={'pencil'}
              fzStyle={{ width: '13px', height: '13px', margin: '5px' }}
            />
          )}
          {cell}
        </>
      );
    };

    const createOptionsEditCell = (colObject) => {
      if (
        colObject.tableProps.editable &&
        colObject.tableProps.editable.type === 'select'
      ) {
        if (!colObject.tableProps.editable.options && colObject.domainName) {
          return getDomain(ns, colObject.domainName);
        }
        if (colObject.tableProps.editable.options) {
          return colObject.tableProps.editable.options;
        }
      }
      return undefined;
    };

    const options = createOptionsEditCell(colObject);
    const optionsInsertRow = createOptionsInsertRow(colObject, ns);

    const col = (
      <TableHeaderColumn
        isKey={colObject.tableProps.isKey}
        key={columnNumber}
        rowSpan={colObject.tableProps.rowSpan}
        dataField={colObject._extendedPath || colObject.path}
        width={colObject.tableProps.width}
        dataFormat={dataFormat}
        hidden={colObject.tableProps.hidden}
        dataAlign={colObject.tableProps.dataAlign}
        dataSort={colObject.tableProps.dataSort}
        sortFunc={hooks[colObject.tableProps.sortFunction]}
        headerText={colObject.tableProps.headerText}
        filter={createFilters(colObject, ns, data)}
        expandable={colObject.tableProps.expandable}
        editable={isEditable()}
        customEditor={{
          getElement: (onUpdate, tablePropsRow) =>
            renderFunction(onUpdate, {
              tablePropsRow,
              typeField: colObject.tableProps.editable.type,
              options,
            }),
        }}
      >
        {colObject.tableProps.shortLabel || colObject.label}
      </TableHeaderColumn>
    );
    columns.push(col);

    function isEditable() {
      return colObject.tableProps.editable
        ? {
            type: colObject.tableProps.editable.type,
            options: { values: optionsInsertRow },
            validator: get(hooks, colObject.tableProps.editable.validatorName),
          }
        : false;
    }
  }

  function createFilters(colObject: $TsFixMe, ns: Namespace, data: any) {
    let columnFilter = {
      ...colObject.tableProps.filter,
    };

    if (colObject.tableProps.filter) {
      if (colObject.tableProps.filter.type === 'SelectDomain') {
        columnFilter = {
          type: 'SelectFilter',
          condition: 'eq',
          options: getDomain(ns, colObject.domainName).reduce(
            (resultSoFar, dom) => {
              return { ...resultSoFar, [dom.code]: dom.description };
            },
            {}
          ),
        };
      } else {
        const filterObject = get(hooks, colObject.tableProps.filter.options);
        columnFilter.options =
          typeof filterObject === 'function'
            ? filterObject(data, colObject.path)
            : filterObject;
      }
    }
    return columnFilter;
  }

  function extendedPathName(path) {
    if (path && path.indexOf('.') >= 0) {
      return '_' + path.replace(/\./g, '_');
    }
    return null;
  }

  function buildVisibleColumns(
    data,
    metas,
    columnsPaths,
    customColumnFormatters,
    ns
  ) {
    
    if (!metas) {
      console.warn('Invalid metadata in buildVisibleColumns');
      return null;
    }
    
    let hasSpanColumns = false;
    for (let path of columnsPaths) {
      let colObject = get(metas, path);
      if (colObject && colObject.tableProps.spanColumns) {
        hasSpanColumns = true;
        break;
      }
    }

    // Build Columns
    let columns: ReactNode[] = [];
    let columnNumber: number = 0;

    //creating fake_column for cancel edit click event
    let fakeColumn = {
      "path": "fake_key",
      "label": "FakeKey",
      "nameExportCsv": "fake_key",
      "dataType": "string",
      "tableProps": {
        "filter": {
            "type": "TextFilter",
            "delay": 500
        },
        "width": "10ch",
        "dataSort": false,
        "dataAlign": "center",
        "isKey": false,
        "hidden": true,
        "columnFormat": "fakeColumn",
      },
      "_extendedPath": null
    }
    createColumns(
      fakeColumn,
      ns,
      data,
      customColumnFormatters,
      columnNumber,
      columns,
      hasSpanColumns
    )
    columnNumber += 1
    
    for (let path of columnsPaths) {
      let colObject = get(metas, path);
      if (!colObject) {
        console.warn('FzTable: Invalid key', path);
        continue;
      }

      colObject._extendedPath = extendedPathName(colObject.path);
      if (colObject._extendedPath) {
        data = data.map((app) => {
          app[colObject._extendedPath] = get(app, colObject.path);
          return app;
        });
      }

      if (columnsPaths.indexOf(path) >= 0) {
        if (colObject.tableProps.spanColumns) {
          ({ columns, columnNumber } = createSpanColumns(
            columns,
            columnNumber,
            colObject
          ));
        } else {
          createColumns(
            colObject,
            ns,
            data,
            customColumnFormatters,
            columnNumber,
            columns,
            hasSpanColumns
          );
        }
      }
      columnNumber += 1;
    }
    return columns;
  }

  function createModalHeader(closeModal) {
    return (
      <InsertModalHeader
        className='my-custom-class'
        title={titleModal}
        onModalClose={closeModal}
        hideClose={true} // to hide the close button
      />
    );
  }


  function buildTableOptions(onExpandRow, onInsertRow) {
    const tableSettingDefault: FzTableOptionsType = {
      insertBtn: createInsertButton,
      insertModalHeader: createModalHeader,
      insertModalFooter: createModalFooter,
      onAddRow: onInsertRow,
      sizePerPageList: [
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      sizePerPage: 50,
      pageStartIndex: 1,
      paginationSize: 4,
      prePage: 'Anterior',
      nextPage: 'Próxima',
      firstPage: 'Primeira',
      lastPage: 'Última',
      paginationShowsTotal: (start, to, total) =>
        `De ${start} até ${to} em ${total} `,
      clearSearch: true,
      noDataText: 'Nenhum dado carregado',
      ...(onExpandRow ? { onExpand: onExpandRow } : {}),
      expandBy: 'column',
    };

    if (insertModalBody) {
      return {
        insertModalBody: insertModalBody,
        ...tableSettingDefault,
      };
    }

    return {
      ...tableSettingDefault,
    };
  }

  const handleTrClass = (row, idx) => {
    if (rowDisabled && rowDisabled(row, idx)) {
      return 'fz-row-disabled';
    }

    if (trClassFunction) {
      return trClassFunction(row, idx);
    }

    return undefined;
  };

  const renderFunction = (onUpdate, props) => {
    return <RenderFormBuild onUpdate={onUpdate} {...props} />;
  };

  return (
    <>
      <FzTableCustomToolBar
        context={context}
        instance={instance}
        data={forceDataToNotBeUndefined}
        ns={ns}
        metas={metas}
        columnsPaths={columnsPaths}
        exportDownload={exportDownload}
        importCsv={importCsv}
        clipboard={clipboard}
        selectColumns={selectColumns}
      />
      <BootstrapTable
        data={forceDataToNotBeUndefined}
        options={buildTableOptions(onExpandRow, onInsertRow)}
        trClassName={handleTrClass}
        striped
        hover
        pagination={true}
        selectRow={selectRow}
        expandableRow={expandableRow}
        expandComponent={expandComponent}
        search={search}
        cellEdit={cellEdit}
        insertRow
      >
        {buildVisibleColumns(
          forceDataToNotBeUndefined,
          metas,
          columnsPaths,
          customColumnFormatters,
          ns
        )}
      </BootstrapTable>
    </>
  );
}
