import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function FzWYSIWYG(props) {
  const { readOnly, onChange } = props;
  const [value, setValue] = useState(props.value);
  
  const modules = {
    toolbar: [['bold', 'link']]
  };
  
  const formats = [
    'bold',
    'link',
  ];

  useEffect(()=>{
    setValue(props.value);
  },[props.value]);

  useEffect(()=>{
    onChange(value);
  },[value]);

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={setValue}
      modules={modules}
      formats={formats}
      readOnly={readOnly}
    />
  )
}