import React, { useState, useEffect } from 'react'
import get from 'lodash/get';
import { FzRow, FzCol, FzSplitLine } from '../../../ui/fz/layout';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzTable } from '../../../ui/fz/grid/table';
import { FzNavSelect } from '../../../ui/fz/nav'
import { StatsDetailHeader } from '../../../statistics/stats-components';
import { summaryMetaBoaVista } from './metadata';
import { buildPeriodFilters } from '../../../components/table-standard';

const Summaries = ({
  ns,
  data,
  handleSearchReport
}) => {

  const [selectedPeriod, setSelectedPeriod] = useState("")
  const [selectIn, setSelect] = useState("")
  const [exibitionSelect, setExibitionSelect] = useState("Daily")
  const [summariesResult, setSummariesResult] = useState([])

  useEffect(() => {
    getData(data)
  }, [selectedPeriod])

  function handleFilterDates() {
    const resultBvReports = data.statistics.filter(registro => registro.period === selectedPeriod)[0];
    return get(resultBvReports, ["data"], []);
  }

  const handleChangeSelect = (period) => {
    setSelect(period);
    switch (period) {
      case "day":
        setExibitionSelect("Daily")
        break;
      case "week":
        setExibitionSelect("Weekly")
        break;
      case "month":
        setExibitionSelect("Monthly")
        break;
    }
    setSelectedPeriod("")
    handleSearchReport({ period })
  }

  function getData(data) {
    if (data && selectedPeriod) {
      setSummariesResult(handleFilterDates())
    }
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <FzCard>
        <FzCard.Title>
          <FzCard.Header className='w-100'>
            <FzSplitLine>
              <FzSplitLine.Left>
              </FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzNavSelect
                  selected={selectIn}
                  onChange={handleChangeSelect}
                  options={[
                    { "code": "day", "description": "Daily" },
                    { "code": "week", "description": "Weekly" },
                    { "code": "month", "description": "Monthly" },
                  ]}
                />
              </FzSplitLine.Right>
            </FzSplitLine>
            <FzRow>
              <FzCol span={12}>
                <StatsDetailHeader
                  title={`Summaries Boa Vista ${exibitionSelect}`}
                  stats={data}
                  periods={buildPeriodFilters(get(data, ['statistics'], []))}
                  currPeriod={selectedPeriod}
                  onChange={(period) => setSelectedPeriod(period)}
                />
              </FzCol>
            </FzRow>
          </FzCard.Header>
        </FzCard.Title>
        <FzCard.Body>
          <div className={'mt-3'}>
            <FzTable
              data={summariesResult}
              metas={summaryMetaBoaVista}
              ns={ns}
              visibleColumns={["description", "totalGlobalMatch"]}
              exportDownload={false}
              clipboard={false}
              context={'BoaVista'}
              selectColumns={false}
              hasSubsetVisibleColumns={true}
            />
          </div>
        </FzCard.Body>
      </FzCard>

    </div>
  )
}

export { Summaries }

