import React from 'react'
import moment from 'moment'

import FieldConnected from '../../../ui/components/form/field-connected'
import { FzButton } from '../../../ui/fz/form'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzIcons } from '../../../ui/fz/form/icon'
import { FzToast } from '../../../ui/fz/form/notification/toast'
import { getDocumentUrl } from '../../../api/formalization'

import { 
  analyticsFilter, 
  analyticsColumns, 
  analyticsPassagersColumns,
} from '../../../biz/metadatas/stats/formalization/unico'

const Analytics = ({
  data,
  filters,
  handleSearchReport,
  handleTostClose,
  ns,
  role,
  showTost,
}) => {
  const btnSearchDisable = (
    moment(filters.from).toISOString() 
    && moment(filters.to).toISOString() 
      ? false 
      : true
  )
  return (
    <>
      <div className="fz-panel">
        <div 
          className="fz-panel-border-padding" 
          style={{ display: "block", borderBottom: "3px solid #dedede" }}
        >
          <div style={{ display: "flex" }}>
            <FieldConnected meta={analyticsFilter.from} data={filters} ns={ns} />
            <FieldConnected meta={analyticsFilter.to} data={filters} ns={ns} />
          </div>
        </div>
        <div 
          className="fz-panel-border-padding d-flex"
          style={{ 
            display: "block", 
            border: "none", 
            borderRight: "3px solid #dedede", 
            borderBottom: "3px solid #dedede",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: "15px 0px 0 0" }}>
              <FzButton
                fzStyle="discrete"
                onClick={handleSearchReport}
                className="btn btn-success"
                disabled={btnSearchDisable}
              >
                Buscar
              </FzButton>
            </div>
          </div>
        </div>
      </div>
  
      <FzCard.Body>
        <FzTable
          ns={ns}
          metas={analyticsColumns}
          data={data && data.statistics}
          exportDownload={role === "admin"}
          context="AnalyticsUnico"
          rowExpand={{
            expandComponent: row => (
              <FzTable
                clipboard={false}
                exportDownload={false}
                ns={ns}
                customColumnFormatters={{
                  userDocumentId: (cell) => (
                    row && <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getDocumentUrl(cell, "png")}
                    >
                      <FzIcons 
                        icon="eye-solid"
                        fzStyle={{ width: "15px", height: "15px" }}
                      />
                    </a>
                  )
                }}
                metas={analyticsPassagersColumns}
                data={row && row.passages}
              />
            )
          }}
        />
      </FzCard.Body>
      <FzToast
        fzStyle="danger"
        close={handleTostClose}
        show={showTost}
        delay={5000}
        headerMessageTost="Error"
        bodyMessageTost="Erro interno, tente novamente!"
        autoHide={true}
      />
    </>
  )
}

export { Analytics }
