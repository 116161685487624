import React from 'react';
import { FzCard } from '../fz/layout/index';
import { FzTable } from '../fz/grid/table';

import { FzButton } from '../fz/form/button';
import FieldConnected from '../components/form/field-connected';
import BlockUi from 'react-block-ui';
import { getCreditRisk } from '../../api/applications';
import { creditRisk, creditRiskTable } from '../../biz/metadatas/credit-risk'

const PAGE_SIZE = 10000

export class CreditRisk extends React.Component<any, any>{
  constructor(props) {
    super(props)

    this.state = {
      requested: false,
      blockStatsConsole: false,
    }

    this.getStatistics = this.getStatistics.bind(this)

  }


  getCreditRiskRecursive(filter, page, resultSet, callback) {
    resultSet = resultSet || []
    getCreditRisk(filter, page, PAGE_SIZE)
      .then((resultCreditRisk) => {
        Array.prototype.push.apply(resultSet, resultCreditRisk);
        if (resultCreditRisk.length >= PAGE_SIZE) {
          this.getCreditRiskRecursive(filter, ++page, resultSet, callback)
        } else {
          callback(resultSet);
        }
      })
      .catch((err) => {
        this.setState({ blockStatsConsole: false })
        console.error("API ERROR", err)
      })
  }


  getStatistics() {
    const { ns } = this.props

    this.setState({ requested: "requesting", blockStatsConsole: true }, () => {
      const filter: any = {
        "createdAfter": ns.getChanged("createdAfter"),
        "createdBefore": ns.getChanged("createdBefore"),
        "product": ns.getChanged("product")
      }

      this.getCreditRiskRecursive(filter, 1, null, (resultCreditRisk) => {
        if (resultCreditRisk && resultCreditRisk.length > 0) {
          ns.set("creditrisk", resultCreditRisk)
          this.setState({ blockStatsConsole: false })
        }
      })
    })
  }


  componentDidMount() {
    const { ns } = this.props
    ns.startEditing()
  }


  render() {
    const { ns } = this.props

    const { requestState } = this.state

    const data = ns.get("creditrisk")
    if (!data && requestState === "requesting") {
      return null
    }



    return (

      <BlockUi tag="div" blocking={this.state.blockStatsConsole} >
        <FzCard>

          <FzCard.Body>
            <div className={"fz-panel"}>

              <div style={{ "padding": "10px"}} className={"fz-panel-border-padding"}>
                < FieldConnected data={data} meta={creditRisk.createdAfter} ns={ns} />
              </div>

              <div style={{ "padding": "10px" }} className={"fz-panel-border-padding"}>
                < FieldConnected data={data} meta={creditRisk.createdBefore} ns={ns} />
              </div>

              <div style={{ "padding": "10px" }} className={"fz-panel-border-padding"} >
                < FieldConnected data={data} meta={creditRisk.product} ns={ns} />
              </div>

              <div style={{ "padding": "10px" }} >
                <FzButton
                  onClick={this.getStatistics}
                  tip={"buscar"}
                  disabled={false}
                  className={null}
                  block={true}
                  fzStyle={"regular"}
                >{"Buscar"}</FzButton>
              </div>
            </div>
          </FzCard.Body>
          <FzCard.Body>
            <FzTable
              data={data}
              metas={creditRiskTable}
              context={"creditRisk"}
              ns={ns}
              pagination
              visibleColumns={
                ["_id",
                  "created",
                  "hashuser",
                  "newuser",
                  "product",
                  "appswithsamepersonalnumemail",
                  "changeaddress",
                  "changeemail",
                  "dayssincefirstrejection",
                  "dayssincelastrejection",
                  "maxappliedamount ",
                  "maxmonthlyincome",
                  "minappliedamount",
                  "minmonthlyincome",
                  "dayssincefirstapplication",
                  "dayssincefirstpaid",
                  "dayssincelastapplication",
                  "dayssincelastpaid",
                  "sumpaidamount",
                  "sumpaidinstallments",
                  "threemonthrestrictions",
                  "sixmonthsrestrictions",
                  "yearrestrictions",
                  "totalapps",
                  "totalfiltered",
                  "totalrefused",
                  "totalrejected",
                  "totaltimeout",
                  "totalwithbid",
                  "totalpaid",
                ]}
            />

          </FzCard.Body>
        </FzCard>
      </BlockUi>
    )
  }
}