import React, { useState } from 'react'
import moment from 'moment'
import { omit } from 'lodash'

import { History as EmailHistoryContainer } from '../../../../Containers/Messages/History'
import api from '../../../../api/client-api'
import { getUsersByFilter } from '../../../../api/auth'
import { getAnalyticsEmailByUser, getByID as getApplicationById } from '../../../../api/applications'

const visibleColumns = [
  "eventName",
  "eventTimestamp",
  'event',
  "template",
  "supplier",
]

const EmailHistory = ({
  ns
}) => {
  const [isChecked, setIsChecked] = useState('')
  const [data, setData] = useState({ statistics: [] })
  
  const createDate = (value, event = "increment") => {
    let date = moment().subtract(value, 'days')

    if(event === "increment") {
      date = moment().add(1, 'days')
    }

    return date.toISOString()
  }

  const getCustomerId = async (value) => {
    let customer: {
      customerId?: string,
      id?: string,
    } = {}

    if(value.hasOwnProperty('applicationId')) {
      customer = await getApplicationById(value.applicationId)
      return customer.customerId
    }
    
    customer = await getUsersByFilter({ 
      ...(value.hasOwnProperty('email') ? { email: value.email } : {}),
      ...(
        value.hasOwnProperty('personalNumber') 
          ? { personalNumber: value.personalNumber.replace(/[^\d]+/g, '') } 
          : {}
      ),
    })

    return customer && customer[0].id
  }

  const handleSearch = async () => {
    try {
      const filters = ns.getChanged() || {}
      const query = omit(filters, ['personalNumber', 'email', 'applicationId'])
      const customerId = await getCustomerId(filters)
      const statistics = (
        customerId 
        && await getAnalyticsEmailByUser(customerId, query)
      )
      setData({ statistics })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckBox = ({ target }) => {
    const { value } = target
    setIsChecked(value)

    if(value === "24h") {
      ns.saveChange("from", moment().startOf("day").toISOString())
      return ns.saveChange("to", moment().endOf("day").toISOString())
    }

    const start = createDate(value, 'decrement')
    const end = createDate(value, 'increment')

    ns.saveChange("from", start)
    return ns.saveChange("to", end)
  }

  const { role = 'no-role' } = api.getCurrentUser()
  const filters = ns.getShared("filters") || ns.getChanged()

  return (
    <EmailHistoryContainer 
      ns={ns}
      data={data}
      visibleColumns={visibleColumns}
      handleCheckBox={handleCheckBox}
      isChecked={isChecked}
      role={role}
      handleSearch={handleSearch}
      filters={filters}
    />
  )
}

export { EmailHistory }
