import React, { Component } from 'react';
import { FzCard, FzRow, FzCol } from '../ui/fz/layout/index';
import { supplierMetas } from '../biz/metadatas/supplier'
import FieldConnected from '../ui/components/form/field-connected'
import api from '../api/client-api'
import { extractMetasStructureToFlat } from '../biz/metadatas/index';
import { filterDomainDescription } from '../utils/filterDomainDescription';

class SupplierDetails extends Component {

  constructor(props) {
    super(props)
    this.state = { domain_formalization_documents: [], "bid-refused-reason": [], allProductsList: [] }
  }

  componentDidMount() {
    this.setState({
      allProductsList: this.props.productsList
    });

    api.domains
      .getDomainValues('formalization-documents')
      .then((domainValues) => {
        this.setState({
          domain_formalization_documents: filterDomainDescription(domainValues)
        });
      })
      .catch((err) => console.warn(err));

    api.domains.getDomainValues("bid-refused-reason")
      .then((domainValues) => this.setState({ "bid-refused-reason": domainValues }))
      .catch((err) => console.warn(err))
  }

  genereteFieldsConnected(metas, data, ns) {
    let supBranches = data.branches;
    let products = []
    const branchOptions = []
    for (let b in supBranches) {
      branchOptions.push({ code: b, description: b })
      if (supBranches.hasOwnProperty(b)) {
        if (!supBranches[b].allowedProducts) continue;
        products.push(...supBranches[b].allowedProducts)
      }
    }

    const fieldElms = metas.map((field) => {
      const meta = supplierMetas.getField(field.path)

      if (field.path === "products") {
        meta.inputProps = { options: this.state.allProductsList }
        let newData = { "products": [...new Set(products)] }
        return <FieldConnected key={field} meta={meta} data={newData} ns={ns} />;
      }

      if (field.path === "defaultBranch") {
        meta.inputProps = {
          options: branchOptions
        }
      }

      if (field.inputType == "dynamic-fields-list-with-icons") {
        meta.inputProps["domain"] = this.state["bid-refused-reason"]
      }

      if (field.inputType == "confirmation-steps") {
        meta.inputProps["formSections"] = this.props['formSections']
        meta.inputProps["filters"] = this.props['filters']
      }

      return <FieldConnected key={field} meta={meta} data={data} ns={ns} />;
    });

    var rows = this.createColumns(fieldElms)

    var fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>;
    });

    return fieldsContent
  }


  createColumns(fieldElms) {
    let rows = [];
    let lastRow = [];

    for (let iteratorFieldElms = 0; iteratorFieldElms < fieldElms.length; iteratorFieldElms++) {
      if (fieldElms[iteratorFieldElms].props.meta.newRow) {
        rows.push(lastRow);
        lastRow = [];
      }
      lastRow.push(<FzCol key={iteratorFieldElms}>{fieldElms[iteratorFieldElms]}</FzCol>);
    }
    rows.push(lastRow)
    return rows
  }


  buildSection(title, allSections, section) {
    return (
      <FzCard >
        <FzCard.Heading>
          <FzCard.Title>{title}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {allSections[section]}
        </FzCard.Body>
      </FzCard>
    )
  }

  getMinScoreDocuments(supplier, supplierMetas, ns) {
    return supplier && supplier.formalization && supplier.formalization.ocrValidationDocuments ?

      supplier.formalization.ocrValidationDocuments.map((doc, k) => {

        const meta = {
          ...supplierMetas.getField("formalization.minOCRScore"),
          path: `formalization.minOCRScore.${doc}`,
          label: this.state.domain_formalization_documents.length > 0 ? this.state.domain_formalization_documents.filter(it => it.code === doc)[0].description : "Carregando ...."
        }

        return (<FzRow key={`${doc}-${k}`}>
          <FieldConnected key={k} meta={meta} data={supplier} ns={ns} />
        </FzRow>);
      }) : [];
  }

  getSiblingMap(supplier, supplierMetas, ns) {
    return supplier && supplier.formalization && supplier.formalization.ocrValidationDocuments ?

      supplier.formalization.ocrValidationDocuments.map((doc, k) => {

        const meta = {
          ...supplierMetas.getField("formalization.siblingMap"),
          path: `formalization.siblingMap.${doc}`,
          label: this.state.domain_formalization_documents.length > 0 ? this.state.domain_formalization_documents.filter(it => it.code === doc)[0].description : "Carregando ...."
        }

        return (<FzRow key={`${doc}-${k}`}>
          <FieldConnected key={k} meta={meta} data={supplier} ns={ns} />
        </FzRow>);
      }) : [];
  }

  render() {
    const { ns, supplier } = this.props

    const metas = extractMetasStructureToFlat(supplierMetas)
    var allSections = ["no-section", "formalization"].reduce((resultSoFar, it) => {
      const metasFiltered = metas.filter(m => m.section === it)

      resultSoFar[it] = this.genereteFieldsConnected(metasFiltered, supplier, ns)

      return resultSoFar
    }, {})

    const ocrScoresFields = this.getMinScoreDocuments(supplier, supplierMetas, ns)
    const ocrSiblingMap = this.getSiblingMap(supplier, supplierMetas, ns)

    const ocrScores = (<FzCol span={6}>
      <FzRow>
        <FzCol span={12}><label className="control-label">Score mínimo por tipo de documento (ex.: 0,7654321):</label></FzCol>
      </FzRow>
      {ocrScoresFields}
    </FzCol>)

    const ocrSiblings = (<FzCol span={6}>
      <FzRow>
        <FzCol span={12}><label className="control-label">Documento dependente para validação de score do OCR:</label></FzCol>
      </FzRow>
      {ocrSiblingMap}
    </FzCol>)

    return (
      <FzCard bsStyle="primary">
        <FzCard.Heading>
          <FzCard.Title>Editar dados do Parceiro</FzCard.Title>
        </FzCard.Heading>

        <FzCard.Body>
          {this.buildSection("", allSections, "no-section")}
          {this.buildSection("", allSections, "formalization")}
          {supplier.formalization.ocrValidation && supplier.formalization.ocrValidationDocuments && supplier.formalization.ocrValidationDocuments.length > 0 ? ocrScores : null}
          {supplier.formalization.ocrValidation && supplier.formalization.ocrValidationDocuments && supplier.formalization.ocrValidationDocuments.length > 0 ? ocrSiblings : null}
        </FzCard.Body>
      </FzCard>
    );
  }
}

export default SupplierDetails;