import { Meta } from "../../types/metas"

export const managerScreeningMeta: Meta = {
  "supplier": {
    "path": "supplier",
    "label": "Parceiro",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      "isKey": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
    "isMandatory": () => false
  },
  "bid.approvedAmount": {
    "path": "bid.approvedAmount",
    "label": "Aprovado",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "bid.monthlyInstallment": {
    "path": "bid.monthlyInstallment",
    "label": "Prestação",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "bid.approvedPeriodMonths": {
    "path": "bid.approvedPeriodMonths",
    "label": "Parcelas",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "bid.interestRate": {
    "path": "bid.interestRate",
    "label": "Juros a.m",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "created": {
    "path": "created",
    "label": "Entrada na fila da triagem",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "screeningId": {
    "path": "screeningId",
    "label": "",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
