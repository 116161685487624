import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FzAccordion, FzCard } from '../../fz/layout/index';
import { FzButton } from '../../fz/form';
import { routes, ScreeningDetails, ScreeningDocumentStatesMeta, screeningRoot } from './screening-index'
import { FzToast } from '../../fz/form/notification/toast'
import { finishScreening, getFormalizationDocumentUrl, postponeAnalisys } from '../../../services/api/screening';
interface FinalAnalysisProps {
  ns: any,
  continuing?: boolean
}

interface DomainType {
  code: string,
  description: string
}

interface FormalizationDocumentsType extends Array<DomainType> { }

export function FinalAnalysis({ ns, continuing }: FinalAnalysisProps) {
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, seColorTost] = useState('')

  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false)
  const [postponeModalOpen, setPostponeModalOpen] = useState(false);

  const screening_response = ns.getShared("SCREENING.screening_response", {})
  const documents = ns.getShared("SCREENING.screening_response.documents", null)
  const domains: FormalizationDocumentsType = ns.getShared("SCREENING.domains.formalization_documents", [])
  const applicationId = ns.getShared("SCREENING.screening_response.applicationId", null)
  const bidId = ns.getShared("SCREENING.screening_response.bidId", null)
  const customerFields = ns.getShared("SCREENING.screening_response.customerFields", [])
  const fullName =
   customerFields.find(it => it.path === 'fullName')?.value ?? 'Não encontrado'

  function handleClose() {
    setShow(false)
  }

  if (!documents || !domains) {
    return <div>
      Carregando...
    </div>
  }

  function showAllAccordionTabs() {
    const getCollapsedAccordionSections = document.getElementsByClassName(
      "collapse"
    )
    for (var i = 0; i < getCollapsedAccordionSections.length; i++) {
      getCollapsedAccordionSections[i].classList.add("show")
    }

    setIsExpanded(true)
  }

  function hideAllAccordionTabs() {
    const getCollapsedAccordionSections = document.getElementsByClassName(
      "collapse"
    )
    for (var i = 0; i < getCollapsedAccordionSections.length; i++) {
      getCollapsedAccordionSections[i].classList.remove("show")
    }

    setIsExpanded(false)
  }

  const finish = async (history) => {
    try {
      const data = continuing ? {isContinueAnalysis: true} : {}
      await finishScreening(data)
      ns.clear()
      history.push(`/screening/queue`)
    } catch (err) {
      let error: $TsFixMe = err
      if (error.response.status === 400) {
        setHeaderMessageTost('Screening error')
        setBodyMessageTost('Ops, essa proposta foi excluída ou cancelada. Vamos encontrar uma proposta ativa para você')
        seColorTost('danger')
        setShow(true)
        setTimeout(function () {
          if (history.location.pathname !== "/screening/queue") {
            history.push("/screening/queue")
          }
        }, 3000);
      } else if (error.response.status === 404) {
        setHeaderMessageTost('Screening error')
        setBodyMessageTost('Está análise não esta mais disponível, pegue o próximo novamente')
        seColorTost('danger')
        setShow(true)
        setTimeout(function () {
          if (history.location.pathname !== "/screening/queue") {
            history.push("/screening/queue")
          }
        }, 3000);
      } else {
        setHeaderMessageTost('Screening error')
        setBodyMessageTost(error.response.data.message)
        seColorTost('danger')
        setShow(true)
      }
    }
  }

  /**
 * Open postpone modal
 */
  function handlePostponeModalOpen() {
    setPostponeModalOpen(true);
  }

  /**
   * Close postpone modal
   */
  function handlePostponeModalClose() {
    setPostponeModalOpen(false);
  }

  /**
   * Postpone screening
   */
  async function handlePostpone() {
    try {
      const response = await postponeAnalisys();

      if (response.status === 200) {
        ns.clear()
        history.push(`/screening/queue`);
        return;
      }
    } catch (err) {
      console.error({ err });
    }

  }

  const options = documents.reduce((acc, current) => {
    let description = domains.find(doc => doc.code === current.documentType)?.description
    return [...acc, { code: current.documentType, description }]
  }, [])

  return (
    <div>
      <header style={{ display: "flex", justifyContent: "space-between" }} >
        <div>
          <div>Usuários : {fullName}</div>
          <ScreeningDetails className="mb-3" screeningResponse={screening_response} />
        </div>
        <div>
          <FzButton
            className="mr-2"
            fzStyle='primary'
            onClick={handlePostponeModalOpen}
          >
            Pendência Interna
          </FzButton>

          <FzButton
            fzStyle='regular'
            onClick={() => {
              finish(history)
            }}
          >
            Finalizar Análise
          </FzButton>
        </div>
      </header>

      <div className='mt-2' style={{ display: 'flex' }} >
        <FzButton className='mr-3' size='sm' fzStyle='outline-secondary' disabled={isExpanded} block onClick={() => showAllAccordionTabs()}>Expandir todos os documentos</FzButton>
        <FzButton className='mt-0' size='sm' fzStyle='outline-secondary' disabled={!isExpanded} block onClick={() => hideAllAccordionTabs()}>Minimizar todos os documentos</FzButton>
      </div>

      {documents.map((doc) => (

        <div className="mt-3" style={{ border: "1px solid lightgrey", borderRadius: "5px", width: "100%" }} key={doc.documentType}>
          <FzAccordion>
            <FzCard.Heading fzStyle={{ padding: 0 }}>
              <FzAccordion.Toggle FzElementType='h4' fzStyle={{ cursor: 'pointer', padding: '10px' }} eventKey={doc.documentType} >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <strong >
                    {
                      (domains.find(it => it.code === doc.documentType)?.description || '')
                    }
                  </strong>
                  <div style={{ textTransform: "capitalize" }} className="text-secondary" >
                    {ScreeningDocumentStatesMeta[doc.state] ? ScreeningDocumentStatesMeta[doc.state].label : "Status não definido"}
                  </div>
                </div>
              </FzAccordion.Toggle>
            </FzCard.Heading>
            <FzAccordion.Collapse className={``} eventKey={doc.documentType} >
              <div className="p-3" style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <img
                    style={{ height: '300px' }}
                    src={getFormalizationDocumentUrl(applicationId, bidId, doc.documentType, 'png', doc.userDocumentId)}
                    alt={doc.documentType}
                  />
                </div>
                <div className="ml-3" style={{ minWidth: "150px" }}>
                  <FzButton
                    id={doc.documentType}
                    disabled={doc.state === 'pending' && doc.pendedRules.indexOf('doc-is-blank') !== -1 || (doc.lastState === 'validated' && doc.isValidated)}
                    block
                    onClick={() => {
                      history.push(screeningRoot + routes.INDIVIDUAL_ANALYSIS + "/" + doc.documentType)
                    }}>Corrigir</FzButton>
                </div>
              </div>
            </FzAccordion.Collapse>
          </FzAccordion>
        </div>
      ))}

      <Modal
        size="lg"
        centered
        show={postponeModalOpen}
        onHide={handlePostponeModalClose}
      >
        <Modal.Header>
          <Modal.Title >
            Desviar para pendência interna ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            A proposta ficará na fila interna por até 1 hora. Após isso, voltará para a fila inicial.
            <br />
            Deseja prosseguir ?
          </div>
        </Modal.Body>
        <Modal.Footer>

          <FzButton
            fzStyle='success'
            onClick={handlePostpone}
          >
            Sim
          </FzButton>

          <FzButton
            fzStyle='alert'
            onClick={handlePostponeModalClose}
          >
            Não
          </FzButton>
        </Modal.Footer>
      </Modal>

      <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
      />
    </div>
  )
}