import React from 'react';
import { FzBadge, FzCard, FzSplitLine } from '../../fz/layout/index'
import { FzCheckbox } from '../../fz/form/old-simple-input'
import { getAllProducts } from '../../../api/applications';

export class FilterProductsSupplierPage extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = { allProductsList: [] }
    this.localOnChange = this.localOnChange.bind(this)
  }

  componentDidMount() {
    this.loadAllProducts();
  }

  localOnChange(value) {
    this.props.onChange(value)
  }

  async loadAllProducts() {
    const productsResp = await getAllProducts();
    this.setState({
      allProductsList: productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    });
  }


  render() {

    const { page } = this.props
    return (
      <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title>Filtrar</FzCard.Title>
        </FzCard.Heading>

        <FzCard.Body>
          <FzSplitLine>
            <FzSplitLine.Left >
              {page === "biddable" ? (
                <div>
                  <FzBadge>{`Total de Leads: ${this.props.total}`} </FzBadge>
                  <button style={{ backgroundColor: "transparent", marginLeft: "5px", border: "1px solid #dedede" }} onClick={this.props.updatedLeads}>Atualizar</button>
                </div>) : null}
            </FzSplitLine.Left >
            <FzSplitLine.Right>
              <FzCheckbox checked={this.props.checked === "*"} onChange={() => this.localOnChange("*")} inline >{"Todos"}</FzCheckbox>
              {this.state.allProductsList.map(it => {
                return <FzCheckbox checked={this.props.checked === it.code} onChange={() => this.localOnChange(it.code)} inline >{it.description}</FzCheckbox>
              })}
            </FzSplitLine.Right>
          </FzSplitLine>
        </FzCard.Body>
      </FzCard>
    )
  }
}

