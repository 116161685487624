import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getAllProducts, updateProduct } from "../../../../../api/applications";
import { getAllSuppliers } from "../../../../../api/suppliers";
import { FzButton } from "../../../../fz/form";
import { FzToast } from "../../../../fz/form/notification/toast";
import { RenderProducts } from "./RenderProducts";


export function PartnerPriority({ ns }) {
  const [metafields, setMetaFields] = useState(null);
  const [products, setProducts] = useState(null);
  const [suppliers, setSuppliers] = useState(null);
  const [editButtons, setEditButtons] = useState({});
  const [toastConfig, setToastConfig] = useState({
    showToast: false,
    fzStyle: "danger",
    headerMessageToast: "Error",
    bodyMessageToast: "Erro interno, tente novamente!"
  });
  const [activeElement, setActiveElement] = useState(null)

  function handleSetIsEditing(productIsEditing, productName) {
    editButtons[productName] = productIsEditing;
    setEditButtons({ ...editButtons });
  }
  function getOptionsByProductsAndSuppliers(products, suppliers) {
    products.sort(
      function (productA, productB) {
        let a = productA.internalName.toUpperCase(),
          b = productB.internalName.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      }
    );

    suppliers.sort(
      function (productA, productB) {
        let a = productA.internalName.toUpperCase(),
          b = productB.internalName.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      }
    );

    return products.map(product => {
      return {
        code: product.internalName,
        description: product.name,
        suppliers: suppliers.map(supplier => {
          const branches = supplier.branches ? Object.keys(supplier.branches).map(branchCode => {
            const branch = supplier.branches[branchCode]
            return branch.allowedProducts.indexOf(product.internalName) > -1 &&
              !(product.offersPriority && !product.offersPriority.find(p => p.supplierInternalName == supplier.internalName && p.branchCode == branchCode)) &&
            {
              code: branchCode,
              description: branch.branchNick
            }
          }).filter(e => e) : [];
          branches.sort(
            function (productA, productB) {
              let a = productA.description.toUpperCase(),
                b = productB.description.toUpperCase();
              return a == b ? 0 : a > b ? 1 : -1;
            }
          );
          return branches.length > 0 ? {
            code: supplier.internalName,
            description: supplier.name,
            branches,
          } : undefined;
        }).filter(e => e)
      }
    })
  }

  useEffect(() => {

    getAllProducts().then((products) => {
      const stateEdit = products.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.internalName]: {
            isEditing: false,
            disabled: true
          }
        };
      }, {});
      setEditButtons(stateEdit);
      getAllSuppliers().then((suppliers) => {
        const options = getOptionsByProductsAndSuppliers(products, suppliers);
        setProducts(products);
        setMetaFields(options);
        setSuppliers(suppliers)
      })
    })
  }, []);

  function onChange(newProducts, productIsEditing, productName) {
    const options = getOptionsByProductsAndSuppliers(newProducts, suppliers);
    editButtons[productName] = productIsEditing;
    setEditButtons({ ...editButtons });
    setProducts(newProducts);
    setMetaFields(options);
  }

  function onSubmit(existError, product, code) {
    if (!existError) {
      product.offersPriorities.map((offer) => {
        offer.branchCode = (offer.branchCode == "undefined") ? "" : offer.branchCode
      });
      updateProduct(product.internalName, product).then(() => {
        setToastConfig({
          showToast: true,
          fzStyle: "success",
          headerMessageToast: "Relevância de Parceiros",
          bodyMessageToast: "Dados atualizados!"
        });
        handleSetIsEditing({ isEditing: false, disabled: existError }, code);
      }).catch(() => {
        setToastConfig({
          showToast: true,
          fzStyle: "danger",
          headerMessageToast: "Erro inesperado",
          bodyMessageToast: "Não foi possível salvar os campos!"
        });
      });
      return;
    }

    setToastConfig({
      showToast: true,
      fzStyle: "danger",
      headerMessageToast: "Erro",
      bodyMessageToast: "Na aba de Lista de Parceiros, preencha os campos “Parceiro” e “Grau de relevância” de 1 a 99 para avançar."
    });
  }

  function ButtonEditing(props) {
    return <>
      <FzButton
      >
        Editar
      </FzButton>
      <FzButton
      >
        Salvar
      </FzButton>
    </>
  }


  function Products() {
    return (metafields) ? metafields.map((meta, key) => {
      const filterByProduct = products.find(product => product.internalName == meta.code);
      return <RenderProducts
        index={key}
        allProducts={products}
        ButtonEditing={ButtonEditing}
        isEditing={editButtons[meta.code]}
        handleSetIsEditing={handleSetIsEditing}
        key={key}
        meta={meta}
        ns={ns}
        onChange={onChange}
        onSubmit={onSubmit}
        product={filterByProduct}
        setProducts={setProducts}
        setToastConfig={setToastConfig}
        activeElement={activeElement}
        setActiveElement={setActiveElement}
      />
    }) : <></>;
  }

  function handleToastClose() {
    toastConfig.showToast = false;
    setToastConfig({ ...toastConfig });
  }


  return (
    <Card>
      <Card.Header>
        <Card.Title className="m-0">
          <b>Relevância</b> de parceiro
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <br />
        <section>
          <Products />
        </section>
      </Card.Body>
      <FzToast
        fzStyle={toastConfig.fzStyle}
        close={handleToastClose}
        show={toastConfig.showToast}
        delay={5000}
        headerMessageTost={toastConfig.headerMessageToast}
        bodyMessageTost={toastConfig.bodyMessageToast}
        autoHide={true}
      />
    </Card>
  )
}