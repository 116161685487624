import React, { Component } from 'react';
import { FzCard, FzCol, FzRow } from "../../fz/layout/index"
import FieldConnected from '../../components/form/field-connected'

class TaskDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { allSuppliers: [] }

    this.buildSection = this.buildSection.bind(this)
    this.createColumns = this.createColumns.bind(this)
    this.buildSectionsFiltersApplications = this.buildSectionsFiltersApplications.bind(this)
    this.flatMetas = this.flatMetas.bind(this)
  }


  flatMetas(metasFields) {
    const fields = Object.keys(metasFields);
    let section = "";
    const metas =  fields.reduce((prev, f) => {
      section = metasFields[f].section && section !== metasFields[f].section ? metasFields[f].section : section;
      return [...prev, { ...metasFields[f], section }];
    }, []);

    return metas
  }


  buildSectionsFiltersApplications(metas, data, ns) {

    const fieldElms = metas.map((field) => {
      return <FieldConnected key={field} meta={field} data={data} ns={ns} />;
    });

    var rows = this.createColumns(fieldElms, fieldElms.length)


    var fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>;
    });

    return fieldsContent
  }


  createColumns(fieldElms, n) {
    let rows = [];
    let lastRow = [];

    for (let iteratorFieldElms = 0; iteratorFieldElms < fieldElms.length; iteratorFieldElms++) {
      lastRow.push(<FzCol key={iteratorFieldElms}>{fieldElms[iteratorFieldElms]}</FzCol>);
      if (lastRow.length > n - 1) {
        rows.push(lastRow);
        lastRow = [];
      }
    }
    return rows
  }

  buildSection(allSections, section) {
    return (
      <div >
          {allSections[section]}
      </div>
    )
  }


  render() {
    const { ns, data, metas } = this.props


    const fields = this.flatMetas(metas)
    var allSections = ["Task", "schedule", "action", "body"].reduce((resultSoFar, it) => {
      const metasFiltered = fields.filter(m => m.section === it)
      resultSoFar[it] = this.buildSectionsFiltersApplications(metasFiltered, data, ns)

      return resultSoFar
    }, {})

    return (
      <FzCard>
        <FzCard.Body>
          {this.buildSection(allSections, "Task")}
          {this.buildSection(allSections, "schedule")}
          {this.buildSection(allSections, "action")}
          {this.buildSection(allSections, "body")}
        </FzCard.Body>
      </FzCard>
    );
  }


}

export { TaskDetails };