const clone = require('lodash/cloneDeep')
const MIN_INTERVAL = 10000;

var lastSaved = new Date();

export const saveStateToLocalStorage = (applicationState) => {
  const newSavedDate = new Date()
  if ((newSavedDate - lastSaved) < MIN_INTERVAL) {
    return
  } else {
    lastSaved = newSavedDate
  }
  let filteredState = clone({
    COF: applicationState.namespaces.COF,
    '/franchisee/form': applicationState.namespaces['/franchisee/form']
  })


  if (filteredState.COF) {
    delete filteredState.COF.ORIGINAL
    delete filteredState.COF.CHANGED
    delete filteredState.COF.CACHE
    delete filteredState.COF.IS_EDITING
    delete filteredState.COF.dirty
  }

  let state = {
    redux: filteredState,
    saved_date: new Date()
  };
  localStorage._data = JSON.stringify(state);
};

export const readFromLocalStorage = () => {
  try {
    let data = JSON.parse(localStorage._data);
    return {namespaces: data.redux};
  } catch (e) {
    console.warn("Cannot read application state");
    localStorage.removeItem("_data");
  }
};

