
import React from 'react';
import { FzCard } from '../../fz/layout/index'
import FieldConnected from "../../components/form/field-connected"
import { FzButton } from '../../fz/form/button'
import { reportAPaid } from '../../../biz/metadatas/payment'
import { paidLead } from '../../../api/applications'
import { getSuppliers } from '../../../scripts/utils/cache-helpers';
import cloneDeep from "lodash/cloneDeep";

export class ViewPayed extends React.Component<any, any>  {
  constructor(props) {
    super(props)

    this.state = {}
    this.reportPayed = this.reportPayed.bind(this)
    this.buildListOfSuppliers = this.buildListOfSuppliers.bind(this)
  }

  async componentDidMount() {
    await this.buildListOfSuppliers();
  }


  reportPayed = (nsViewPayed) => {

    const { ns, APP_ACTION_REPORT_PAIED } = this.props

    const { payedAppReasonStatus, paidDate, paidAmount } = nsViewPayed.getChanged()
    let remark = "", comissionCode = ""

    if (!payedAppReasonStatus || payedAppReasonStatus === null) {
      return null
    }

    paidLead(this.props.app, payedAppReasonStatus, paidDate, paidAmount, remark, comissionCode)
      .then(result => {
        let treatment = this.props.ns.get("crm.treatments." + this.props.app.id) ? this.props.ns.get("crm.treatments." + this.props.app.id) + " * Reportado como pago" : "Reportado como pago";
        let treatmentArray = this.props.ns.get("crm.treatmentsArray." + this.props.app.id) ? this.props.ns.get("crm.treatmentsArray." + this.props.app.id) : [];
        treatmentArray.push(APP_ACTION_REPORT_PAIED);
        this.props.ns.set("crm.treatments." + this.props.app.id, treatment);
        this.props.ns.set("crm.treatmentsArray." + this.props.app.id, treatmentArray);
      })
      .catch(err => {
        console.log(err)
      })

    this.props.ns.unset("application")
  }

  buildListOfSuppliers = async () => {
    const allSuppliers = await getSuppliers(this.props.ns);
    const listOfSuppliers = Object.keys(allSuppliers).map(it => {
      return { code: allSuppliers[it].internalName, description: allSuppliers[it].name, auxiliaryForOrder: allSuppliers[it].internalName.toLowerCase() };
    }).sort(function (a, b) {
      if (a.auxiliaryForOrder > b.auxiliaryForOrder) {
        return 1;
      }
      if (a.auxiliaryForOrder < b.auxiliaryForOrder) {
        return -1;
      }
      return 0;
    });
    this.setState({ "listOfSuppliers": listOfSuppliers })
  }


  render() {
    const { dataForViewPayed, nsViewPayed, changeViewToButtons, ns } = this.props


    let payedCustomField
    if (this.state.listOfSuppliers !== undefined) {
      payedCustomField = cloneDeep(reportAPaid.payedAppReasonStatus)
      payedCustomField.inputProps.options = this.state.listOfSuppliers
    }

    return (
      <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title >Pago</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {payedCustomField && this.state.listOfSuppliers ? (<FieldConnected meta={payedCustomField} data={dataForViewPayed} ns={nsViewPayed} />) : null}
          <FieldConnected meta={reportAPaid.paidDate} data={dataForViewPayed} ns={nsViewPayed} />
          <FieldConnected meta={reportAPaid.paidAmount} data={dataForViewPayed} ns={nsViewPayed} />
          <br />
          <br />
          <div>
            <FzButton block onClick={() => changeViewToButtons()}>Voltar</FzButton>
            <FzButton
              block
              fzStyle="attention"
              onClick={() => this.reportPayed(nsViewPayed)}>
              Registrar
            </FzButton>
          </div>
        </FzCard.Body>
      </FzCard>
    );
  }
}