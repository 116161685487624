import { Meta } from "../../../../types/metas";

export const metaSummariesReport: Meta = {
  label: {
    path: 'label',
    label: 'Campo alterado',
    dataType: "string",
    tableProps: {
      rowSpan: "2",
      isKey: true,
      width: "35ch",
    }
  },
  templete: {
    path: 'templete',
    label: 'templete',
    dataType: "string",
    tableProps: {
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "templete",
            dataAlign: "left",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            dataAlign: "left",
            "columnFormat": "pctColumn",
          },
        ]
      },
      "formatExtraData": "total_templete",
    },
  },
  totalField: {
    path: 'totalField',
    label: 'Total',
    dataType: 'string',
    tableProps: {
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Total",
            dataAlign: "left",
            "width": "16ch",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            dataAlign: "left",
            "columnFormat": "pctColumn",
          },
        ]
      },
      "formatExtraData": "total",
    },
  }
}