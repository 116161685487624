import React, { useEffect } from 'react'
// import * as pdfobject from 'pdfobject'
import { PDFObject } from 'react-pdfobject'

const options = {
  height: '600px',
  pdfOpenParams: {
    view: 'full',
    toolbar: 0,
  },
}

type PdfViewProps = { 
  url: string,
  optionsProps?: any,
  documentType: string
}

const PdfView = ({
  documentType,
  url,
  optionsProps = {}
}: PdfViewProps) => {
  const selector = `my-pdf-${documentType}`
  function getImgOrPdfComponent(pdfUrl) {
    const pdfParts = pdfUrl.split('fmt=')
    const replacedFileFormat = 'fmt=pdf'+pdfParts[1].substring(3)
    return pdfParts[0]+replacedFileFormat
  }
  return ( 
    <PDFObject url={getImgOrPdfComponent(url)} containerId={selector} {...{...options, ...optionsProps}} />
  )
}

export default PdfView
