import React from 'react'
import { FzCard, FzCol, FzRow } from '../../fz/layout/index'
import { supplierBranchesMetas } from '../../../biz/metadatas/supplier'
import FieldConnected from '../../components/form/field-connected'

interface BranchDetailsProps {
  code: String;
  supplier: $TsFixMe;
  ns: $TsFixMe;
  productsList: object;
  formSections: $TsFixMe;
  filters: $TsFixMe;
}

const BranchDetails = ({ 
  code,
  ns,
  supplier,
  productsList,
  formSections,
  filters
}: BranchDetailsProps
) => {

  const fieldElms = Object.keys(supplierBranchesMetas).map(fieldName => {
    const pathAllowed = `branches.${code}.allowedProducts`
    const path = `branches.${code}${fieldName}`
    let inputProps: any = (
      path === pathAllowed 
      ? { inputProps: { options: productsList } } 
      : {}
    )

    if (fieldName == ".confirmationStepConfigs") {
      inputProps["inputProps"] = { formSections, filters }
    }
      
    const metaField = {
      ...supplierBranchesMetas[fieldName],
      ...inputProps,
      path,
    }

    return (
      <FieldConnected 
        ns={ns} 
        data={supplier} 
        meta={metaField} 
        code={code}
      />
    )
  })

  const createColumnsAndRows = (data) => {
    let rows: $TsFixMe = []
    let lastRow: $TsFixMe = []

    data.map((element, index) => {
      if(element.props.meta.newRow) {
        rows.push(lastRow)
        lastRow = []
      }

      let hidden = false;

      if(typeof element.props.meta.isVisible === "function") {
        hidden = !element.props.meta.isVisible(element.props.data, code);
      }

      if (element && element.props && element.props.meta && element.props.meta.path && element.props.meta.path.indexOf("fakeBid.value") != -1) {
        return lastRow.push(<FzCol hidden={hidden} span={6} key={index}>{element}</FzCol>)
      } else {
        return lastRow.push(<FzCol hidden={hidden} span={6} key={index}>{element}</FzCol>)
      }
    });
    rows.push(lastRow)
    
    return rows.map((rowContent, index) => (
      <FzRow key={index}>{rowContent}</FzRow>
    ))
  }

  const fieldsContent: $TsFixMe = createColumnsAndRows(fieldElms)

  return (
    <FzCard onClick={event => event && event.stopPropagation()}>
      <FzCard.Body>
        <div>{fieldsContent}</div>
      </FzCard.Body>
    </FzCard>
  )
}


export default BranchDetails
