import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Namespace } from '../../../scripts/state/redux_ns';
import { FormalizationDocumentsList } from '../formalization/formalization-documents-list'
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import { FzTable } from '../../fz/grid/table'
import { getFormalizationsForAnalytics } from '../../../api/applications';
import api from '../../../api/client-api'
import { formalizationAnalytic } from '../../../biz/metadatas/formalization-metadata'
import moment from 'moment'
import { FzCard } from '../../fz/layout/index';
import { getSuppliers, getSuppliersList } from '../../../scripts/utils/cache-helpers';
import { getAllSuppliers } from '../../../api/suppliers';
import { filterDomainDescription } from '../../../utils/filterDomainDescription';



const expandComponent = (formalization, ns, api, documentDomains, allSuppliers) => {
  return (<FormalizationDocumentsList
    ns={ns}
    formalization={formalization}
    api={api}
    readOnly={true}
    documentDomains={documentDomains}
    allSuppliers={allSuppliers}
  />)
}

/*
  IntegrationFormalizations
*/

export class IntegrationFormalizations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      period: "10days",
      requested: false,
      blockStatsConsole: false,
      documentDomains: [],
      allSuppliers: {}
    }
    this.formatCreated = this.formatCreated.bind(this)
  }

  async componentDidMount() {
    let documentDomains = await api.domains.getDomainValues("formalization-documents")

    documentDomains = filterDomainDescription(documentDomains)

    const allSuppliers = await getAllSuppliers()

    let tmp = {};
    for (let i = 0; i < allSuppliers.length; i++) {
      tmp[allSuppliers[i].internalName] = allSuppliers[i];
    }

    this.setState({ documentDomains, allSuppliers: tmp })
  }


  getStatistics(desde, ate) {
    const { ns, supplier, filters } = this.props

    this.setState({ requested: "requesting", blockStatsConsole: true }, async () => {
      const limit = 1000
      let skip = 0
      let keepLoading = true
      const fullLoadedResult = new Array
      while (keepLoading) {
        let resultFomalization = await getFormalizationsForAnalytics(supplier, filters, skip, limit)
        if (Array.isArray(resultFomalization) && resultFomalization.length <= limit) {
          fullLoadedResult.push(...resultFomalization)
          skip += limit

          ns.set("formalization" + desde + "--" + ate + supplier, fullLoadedResult)
          this.setState({ requested: false, blockStatsConsole: false })
          if (resultFomalization.length !== limit) {
            keepLoading = false
          }
        } else {
          keepLoading = false
        }
      }
    })
  }


  formatCreated(cell) {
    if (!cell) {
      return ""
    }
    return moment(cell.split("T")[0]).format("DD/MMM/YY")
  }

  render() {
    const { ns, filters, supplier } = this.props

    if (!supplier || (!filters.startDate || !filters.endDate)) {
      return null
    }

    var desde = filters.startDate instanceof moment ? filters.startDate.toISOString().split("T")[0] : filters.startDate.split("T")[0]
    var ate = filters.endDate instanceof moment ? filters.endDate.toISOString().split("T")[0] : filters.endDate.split("T")[0]

    const data = ns.get("formalization" + desde + "--" + ate + supplier)

    if (data === undefined && !this.state.requested) {
      this.getStatistics(desde, ate)
    }

    return (
      <FzCard>
        <FzCard.Body>
          <FzTable
            exportDownload
            pagination
            data={data || []}
            context={"formalizationListColumns"}
            ns={ns}
            rowExpand={{ expandComponent: (row) => expandComponent(row, ns, api, this.state.documentDomains, this.state.allSuppliers) }}
            customColumnFormatters={{
              created: (cell, row,) => this.formatCreated(cell, row),
            }}
            metas={formalizationAnalytic}
            // visibleColumns={["applicationId", "id", "created", "personalNumber", "supplierApplicationId", "formalizationState", "countPending", "countSent", "branchCode"]}
          />
        </FzCard.Body>
      </FzCard>
    );
  }
}

IntegrationFormalizations.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  sharedNS: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};
