import React from 'react'
import { FzButton } from '../../../ui/fz/form'
import { internalPendencyMeta } from '../../../biz/metadatas/screening/internal-pendency'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzToast } from '../../../ui/fz/form/notification/toast'

const InternalPendencyContainer = ({
  ns,
  data,
  handleUpdateList,
  handleGoToScreening,
  visibleColumns,
  handleTostClose,
  showTost,
  toastType,
  toastMessage
}) => {

  const goToScreening = (screeningId) => {
    return (
      <FzButton
        customStyle={{
          borderColor: '#5923DD',
          width: '100%',
          color: '#fff',
          background: '#5923DD'
        }}
        size="small"
        fzStyle=""
        onClick={() => handleGoToScreening(screeningId)}
      >
        Abrir proposta
      </FzButton>
    )
  }

  return (
    <>
      <FzButton onClick={handleUpdateList} fzStyle="success" customStyle={{marginBottom: '20px'}}>
        Atualizar
      </FzButton>
      <FzTable
        ns={ns}
        metas={internalPendencyMeta}
        data={data}
        exportDownload={false}
        clipboard={false}
        visibleColumns={visibleColumns}
        customColumnFormatters={{ id: (cell) => goToScreening(cell) }}
      />
      <FzToast
        fzStyle={toastType}
        close={handleTostClose}
        show={showTost}
        delay={5000}
        headerMessageTost="Screening"
        bodyMessageTost={toastMessage}
        autoHide={true}
      />
    </>
  )
}

export { InternalPendencyContainer }