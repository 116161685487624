import { useEffect, useState } from "react"
import { FzButton } from "../button";
import FieldsListWithIcons from "./Fields";
import { Nav, Navbar } from "react-bootstrap";

export default function FzDynamicFieldsListWithIcons(props) {
  const { readOnly, value, onChange, domain } = props;
  const [fieldsArray, setFieldsArray] = useState([]);
  const [activeElement, setActiveElement] = useState("bullet")
  
  useEffect(() => {
    setFieldsArray(value ? value : []);
  }, [value]);

  function addNewField() {
    setFieldsArray([...fieldsArray, { icon: '', text: '', type: activeElement }])
  }

  function saveChanges() {
    if (!readOnly) {
      onChange(fieldsArray)
    }
  }

  function saveOnEnter(event) {
    if (event.keyCode === 13) {
      saveChanges()
    }
  }

  function changeField(index, value, type) {
    let newArray = [...fieldsArray];
    newArray[index][type] = value;
    setFieldsArray(newArray);
    onChange(newArray);
  }


  function removeField(index) {
    let newArray = [...fieldsArray]
    newArray.splice(index, 1)
    setFieldsArray(newArray)
    onChange(newArray)
  }

  function FieldsList() {
    if (fieldsArray.length > 0) {
      return fieldsArray.map((item, index) => {
        if (item.type == activeElement) {
          return (
            <div key={index}>
              <FieldsListWithIcons
                index={index}
                element={item}
                readOnly={readOnly}
                saveChanges={saveChanges}
                saveOnEnter={saveOnEnter}
                changeField={changeField}
                removeField={removeField}
                domains={domain}
              />

            </div>
          )
        }
      })
    }
    return <></>
  }

  return (
    <div>
      <div className='form-group' style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '15px', paddingTop: 0 }}>
        <Navbar className="pl-0">
          <Nav variant="tabs" activeKey={activeElement} onSelect={(e) => setActiveElement(e)} >
            <Nav.Link eventKey="bullet" >Bullets</Nav.Link>
            <Nav.Link eventKey="confirmation" >Confirmation</Nav.Link>
          </Nav>
        </Navbar>
        <FieldsList />
        <hr />
        <FzButton onClick={addNewField} disabled={readOnly} fzStyle="discrete">
          <i className="fa-solid fa-plus"></i> Adicionar Novo
        </FzButton>
      </div>
    </div>
  )
}