import React, { useEffect, useState, Fragment } from 'react'
import {  useParams } from 'react-router-dom';
import { Edit } from '../form/form-generator';

import { List } from '../form/form-generator';
import { getCurrentUser } from '../../../api/auth';
import { FzToast } from '../../fz/form/notification/toast';




const toastInitialConfig = {
  show: false,
  color: '',
  title: '',
  message: 'sdf',
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: false,
}

export const toastError = (message) => {
  return {
    ...toastInitialConfig,
    show: true,
    fzStyle: 'danger',
    title: 'Erro',
    bodyMessageTost: message,
  }
}
export const toastInfo = (message) => {
  return {
    ...toastInitialConfig,
    show: true,
    fzStyle: 'success',
    title: 'Informação',
    bodyMessageTost: message,
  }
}

const EntityPack = ({ ns, meta, linkPath, metaList, title, entity, onCreate, handleUpdate, actionCreate, actionGet, actionGetList, actionUpdate }) => {
  const { id } = useParams<{ id: string }>();
  const user = getCurrentUser()
  const [component, setComponent] = useState(!id || id == "" ? "list" : "edit");
  const [toast, setToast] = useState({
    show: false,
    color: '',
    title: '',
    message: '',
    headerMessageTost: 'Error',
    bodyMessageTost: 'Erro interno, tente novamente!',
    fzStyle: 'danger',
    delay: 5000,
    autoHide: false,
  });
  const handleToastClose = () => setToast(toastInitialConfig);

  useEffect(() => {
    if (!id || id == "") {
      setComponent("list")
    } else {
      setComponent("edit")
    }
  }, [])

  
  // function isErrorWithMessage(error: unknown): boolean {
  //   if (
  //     typeof error === 'object' &&
  //     error !== null &&
  //     'message' in error &&
  //     'statusCode' in error) {
  //       var err = error as ErrorWithMessage
  //       return typeof err.message === 'string' &&
  //       typeof err.statusCode === 'number'
  //     }
  //     return false
  // }

  // function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  //   if (isErrorWithMessage(maybeError)) return maybeError as ErrorWithMessage
  
  //   try {
  //     return { message: JSON.stringify(maybeError), statusCode: 900 }
  //   } catch {
  //     // fallback in case there's an error stringifying the maybeError
  //     // like with circular references for example.
  //     return { message: String(maybeError), statusCode: 901 }
  //   }
  // }

  // function getErrorMessageAndStatus(error: unknown) {
  //   return {statusCode: toErrorWithMessage(error).statusCode, message: toErrorWithMessage(error).message}
  // }


  async function requestHandler(func: Function, ignoreSuccessToast: boolean, ...args) {
    try {
      const res = await func(...args)
      if (!ignoreSuccessToast) {
        setToast(toastInfo(`Chamada executada com sucesso! ${title}`))
      }
      return res
    } catch (err) {
        let error: $TsFixMe = err
        setToast(toastError(`Erro ${error.statusCode || "no-status"} ao salvar o ${title}. ${error.message}`))
        console.error("Error on requestHandler", {err})
    }
  }
  function getComponent(which) {
    return which == "edit" ? <Edit
    user={user}
    meta={meta}
    title={title}
    entity={entity}
    ns={ns}
    onCreate={(...args) => requestHandler(onCreate, true, ...args)}
    handleUpdate={(...args) => requestHandler(handleUpdate, false, ...args)}
    actionCreate={(...args) => requestHandler(actionCreate, false, ...args)}
    actionUpdate={(...args) => requestHandler(actionUpdate, false, ...args)}
    actionGet={(...args) => requestHandler(actionGet, true, ...args)}
    setToast={setToast}
    id={id}
  /> : <List
    linkPath={linkPath}
    metas={metaList}
    actionGet={actionGetList}
    entity={entity}
    title={title}
    ns={ns}
    />
  } 
  

  return <>
    {getComponent(component)}
    <FzToast {...toast} close={handleToastClose} />
  </>
}

export default EntityPack;
