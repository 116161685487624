import React from "react";
import PropTypes from "prop-types";
import {  Button } from "react-bootstrap";
import { Namespace } from '../scripts/state/redux_ns';
import messagesMetadata from "../biz/metadatas/message-metadata";
import { FzTable } from "../ui/fz/grid/table";
import { FzBadge } from "../ui/fz/layout/index";
import { FzIcons } from "../ui/fz/form/icon";

export default function MessagesTable({ ns, data, role, api, ...props }) {
  const ShowMessage = row => {
    ns.copy("/admmessages/" + row._id).set("message", row);
    props.history.push("/admmessages/" + row._id);
  };

  function idColumn(cell, row) {
    const inactive = !!row["inactive"];
    const closed = !!row["newApplicationsDisabled"];
    return (
      <div>
        <Button
          title={row["id"]}
          onClick={() => ShowMessage(row)}
          bsSize="xsmall"
        >
          < FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Ver detalhes
        </Button>
        {inactive ? <FzBadge>Desativado</FzBadge> : null}
        {closed && !inactive ? <FzBadge variant="warning">Fechado</FzBadge> : null}
      </div>
    );
  }

  return (
    <>
      <FzTable
        data={data}
        metas={messagesMetadata}
        ns={ns}
        customColumnFormatters={{ _id: (cell, row) => idColumn(cell, row) }}
        visibleColumns={["event", "updated", "description", "_id", "templateIds"]}
        pagination
        search
      />
    </>
  );
}

MessagesTable.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  api: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object)
};
