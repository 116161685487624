
const getAllColumns = (metas, currentLevel = "") => {
  let paths = []
  if (metas.__allColumns) {
    return metas.__allColumns
  }
  for (let k in metas) {
    if (metas.hasOwnProperty(k)) {
      if (metas[k] && metas[k].tableProps) {
        if (metas[k].tableProps) {
          paths.push(currentLevel + k)
        }
      } else if (typeof metas[k] == "object" && metas[k]) {
        paths.concat(getAllColumns(metas[k]), currentLevel + k + ".")
      }
    }
  }
  return paths
}

export { getAllColumns }