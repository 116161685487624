import React, { useEffect, useState } from 'react';
import { Badge, CardGroup } from 'react-bootstrap';
import { FzCard, FzCol } from '../ui/fz/layout/index';
import { FzForm } from '../ui/fz/layout/index';
import { FzTable } from '../ui/fz/grid/table';
import { FzButton } from '../ui/fz/form';
import { campaignSummary, campaignAnalytics } from '../biz/metadatas/campaign';
import fmt from "../ui/fz/formatter/column-formatter"
import { getCurrentUser } from '../api/auth';
import { getAllRules } from '../api/client-messages';
import { dateSummaryColumn } from '../components/table-standard';
import FieldConnected from '../ui/components/form/field-connected';
import { panelControlCampaigns } from '../biz/metadatas/campaign';
import {
  activateCampaignById,
  notifyCampaignById,
  getCampaigns,
  getCampaignsSummary,
  updateCampaignStatistics,
  notifyCampaignUnread,
  stopBuildCampaign
} from '../api/campaigns';
import qs from 'qs';
import moment from 'moment';

const expandComponent = (campaign, ns) => {
  let filters
  if (campaign.filterConditions) {
    filters = (
      <div><pre>
        {JSON.stringify(campaign.filterConditions, null, 2)}
      </pre></div>);
  }
  const buildCampaign = () => {
    ns.set("_busy", true);
    return activateCampaignById(campaign.id, true)
      .then((result) => {
        window.alert(`${result.active} clientes ativados`)
        ns.unset("_busy");
        ns.unset("campaigns");
      })
      .catch((err) => {
        console.warn('API ERROR ', err);
        window.alert(`Erro: ${err}\nAguarde um pouco e tecle no botão \"Refresh\" do menu para confirmar se a ação foi executada`)
      });
  }

  const stopBuild = async () => {
    ns.set("_busy", true);
    try{
      await stopBuildCampaign(campaign.campaign);
      window.alert(`Você interrompeu o build da campanha: ${campaign.campaign}`)
      ns.unset("_busy");
      ns.unset("campaigns");
    } catch(error){
      console.warn('API ERROR ', error);
      window.alert(`Erro: Ao interromper o build da campanha: ${campaign.campaign}`)
    }
  }

  let buttonBuild
  
  if(campaign.isApplicant){
    buttonBuild = (
      <FzButton
        block
        fzStyle="regular"
        disabled={campaign.total <= (campaign.active + campaign.converted)}
        onClick={buildCampaign}
      >
        Ativar Campanha
      </FzButton>
    )
  }

  const notifyCampaign = () => {
    ns.set("_busy", true);
    return notifyCampaignById(campaign.id)
      .then((result) => {
        window.alert("Notificação iniciada!")
        ns.unset("_busy");
        ns.unset("campaigns");
      })
      .catch((err) => {
        console.warn('API ERROR ', err);
        window.alert("Erro executando o comando!\nAguarde um pouco e tecle no botão \"Refresh\" do menu para confirmar se a ação foi executada")
      });
  }

  const notifyUnread = () => {
    if (window.confirm("Esta rotina enviará OUTRO email para todos que não estão marcados como lidos.\nConfirma?")) {
      ns.set("_busy", true);
      return notifyCampaignUnread(campaign.id)
        .then((result) => {
          window.alert("Reenviando notificações para os clientes")
          ns.unset("campaigns");
          ns.unset("_busy");
        })
        .catch((err) => {
          console.warn('API ERROR ', err);
          window.alert("Erro executando o comando!\nAguarde um pouco e tecle no botão \"Refresh\" do menu para confirmar se a ação foi executada")
        });
    }
  }

  const updateStatistics = () => {
    ns.set("_busy", true);
    return updateCampaignStatistics(campaign.id)
      .then((updated) => {
        window.alert(`Campanha atualizada`)
        ns.unset("_busy");
        const campaigns = ns.get("campaigns", [])
        for (let i = 0; i < campaigns.length; ++i) {
          if (campaigns[i].id === updated.id) {
            campaigns[i] = updated
            ns.set("campaigns", campaigns);
            return true
          }
        }
        // just in case, if campaign not found
        ns.unset("campaigns");
      })
      .catch((err) => {
        console.warn('API ERROR ', err);
        window.alert("Erro executando o comando!\nAguarde um pouco e tecle no botão \"Refresh\" do menu para confirmar se a ação foi executada")
      });
  }

  return (
    <>
      <CardGroup>
        <FzCol span={3}>
          <FzCard>
            <FzCard.Body>
              <FzForm.Label>Ações:</FzForm.Label>
              <div className="vertical-button-list">
                {
                  !campaign.buildResult && (
                    <FzButton
                      block
                      fzStyle="regular"
                      onClick={stopBuild}
                    >
                      Cancelar Build de Campanha
                    </FzButton>
                  )
                }
                {buttonBuild}
                <FzButton
                  block
                  fzStyle="regular"
                  disabled={new Date(campaign.endDate) < new Date() || campaign.total === campaign.notified || (campaign.active > 0 && campaign.active === campaign.notified)}
                  onClick={notifyCampaign}
                >Mandar Notificações
                </FzButton>
                <FzButton
                  block
                  fzStyle="regular"
                  disabled={new Date(campaign.endDate) < new Date() || campaign.active === 0 || campaign.read === campaign.notified}
                  onClick={notifyUnread}
                >Reenviar Notificações para Não Lidos
                </FzButton>
                <FzButton
                  block
                  fzStyle="attention"
                  disabled={campaign.total === 0}
                  onClick={updateStatistics}
                >Atualizar Estatísticas
                </FzButton>
              </div>
            </FzCard.Body>
          </FzCard>
        </FzCol>
        <FzCol span={6}>
          <FzCard>
            <FzCard.Body>
              <FzForm.Label>Filtros</FzForm.Label>
              {filters}
            </FzCard.Body>
          </FzCard>
        </FzCol>
      </CardGroup>
    </>
  )
}

const CampaignsAnalyticsTable = (props) => {
  const countColumn = (target, cell, row) => {
    if (cell > 0) {
      return row.isManual === true ? (
        <a href={'/campaigns/' + row.campaign + '/' + target}>
          {fmt.numberColumn(cell, row)}
        </a>
      ) : (
        fmt.numberColumn(cell, row)
      );
    } else {
      return null;
    }
  };

  const getDateRange = (targetField, row) => {
    if (row && row.filterConditions && row.filterConditions.targets) {
      const targetList = row.filterConditions.targets;
      const createField = targetList.filter(
        (target) => target.field === 'created' && target.type === 'range'
      );

      if (createField.length > 0) {
        if (createField[0][targetField] !== undefined) {
          const formatDateInfo = dateSummaryColumn(
            createField[0][targetField],
            row
          );

          // creating row property to exportToCSV
          row[targetField] = formatDateInfo;

          return formatDateInfo;
        }
      }
    }
  };

  const { data, ns } = props;

  const user = getCurrentUser()

  return (
    <FzTable
      data={data}
      metas={campaignAnalytics}
      context={'campaignAnalytics'}
      exportDownload={user.role !== 'advisor'}
      clipboard={user.role !== 'advisor'}
      pagination={true}
      customColumnFormatters={{
        active: (cell, row) => countColumn("active", cell, row),
        converted: (cell, row) => countColumn("converted", cell, row),
        canceled: (cell, row) => countColumn("canceled", cell, row),
        dateFrom: (cell, row) => getDateRange("dateFrom", row),
        dateTo: (cell, row) => getDateRange("dateTo", row),
        buildStatus: (cell, row) => {
          if (row?.buildResult === "Build interrupted") {
            return (
              <>
                <Badge style={{ backgroundColor: 'red', color: '#fff', padding: 10 }}>Build interrompido</Badge>
              </>
            )
          }else if (row.buildStart === undefined && row.buildEnd === undefined && row.scheduleDate !== undefined) {
            return (
              <>
                <Badge style={{ backgroundColor: '#666', color: '#fff', padding: 10 }}>Não iniciado</Badge>
              </>
            )
          } else if (row.buildStart !== undefined && row.buildEnd === undefined) {
            return (
              <>
                <Badge style={{ backgroundColor: 'orange', color: '#000', padding: 10 }}>Build em progresso</Badge>
              </>
            )
          } else if (row.buildStart !== undefined && row.buildEnd !== undefined && row.created === row.scheduleDate && row.scheduleSentDate === undefined) {
            return (
              <>
                <Badge style={{ backgroundColor: '#045cb0', color: '#fff', padding: 10 }}>Build Finalizado</Badge>
              </>
            )
          } else if (row.buildStart !== undefined && row.buildEnd !== undefined && row.created !== row.scheduleDate && row.scheduleSentDate === undefined) {
            return (
              <>
                <Badge style={{ backgroundColor: '#05bcb4', color: '#fff', padding: 10 }}>Campanha agendada</Badge>
              </>
            )
          } else if (row.buildStart !== undefined && row.buildEnd !== undefined && row.scheduleSentDate !== undefined) {
            return (
              <>
                <Badge style={{ backgroundColor: 'green', color: '#fff', padding: 10 }}>Campanha enviada</Badge>
              </>
            )
          } else {
            return "-"
          }
        },
      }}
      visibleColumns={
        [
          "id",
          "buildStatus",
          "campaign",
          "created",
          "buildEnd",
          "scheduleDate",
          "scheduleSentDate",
          "dateFrom",
          "dateTo",
          "isManual",
          "type",
          "notificationEvent",
          "total",
          "active",
          "converted",
          "canceled",
          "notified",
          "bounced",
          "unsubscribed",
          "read",
          "clickedTotal",
          "clickedRead",
          "endDate",
          "isApplicant",
          "gererateToken",
          "generatePreAuth",
        ]
      }
      rowExpand={{
        expandableRow: (row) => user.role !== 'advisor',
        expandComponent: (row) => expandComponent(row, ns),
      }}
      ns={ns}
    />
  );
};

const CampaignsSummaryTable = (props) => {

  const { data, filterType, ns } = props;
  const user = getCurrentUser()

  return (
    <FzTable
      data={data}
      metas={campaignSummary(filterType)}
      context={'campaignSummary'}
      exportDownload={user.role !== 'advisor'}
      clipboard={user.role !== 'advisor'}
      pagination={true}
      visibleColumns={
        [
          "id",
          "aggregation",
          "campaign",
          "event",
          "type",
          "autoOrManual",
          "total",
          "active",
          "converted",
          "canceled",
          "notified",
          "bounced",
          "unsubscribed",
          "read",
          "clicked",
        ]
      }
      ns={ns}
    />
  );
}

const CampaignsOverview = (props) => {
  const { ns, api } = props
  const [dataAnalytics, setDataAnalytics] = useState([]);
  const [dataSummary, setDataSummary] = useState([]);
  const [campaignRules, setCampaignRules] = useState([]);
  const [eventRules, setEventRules] = useState([]);
  const [urlChecked, setUrlChecked] = useState(false)
  const [queryParams, setQueryParams] = useState([]);

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
    ns.getChanged();
  }, [ns])

  useEffect(() => {
    getStatisticsCampaignRules()
    ns.startEditing()
    loadDefaultValues()
    getQueryStringFromUrl()
  }, [])

  useEffect(() => {
    Object.keys(queryParams).forEach(el => {
      ns.saveChange(el, queryParams[el]);
    });
  }, [queryParams])

  function loadDefaultValues() {
    ns.saveChange(panelControlCampaigns["typeView"]["path"], "analytics")
    ns.saveChange(panelControlCampaigns["startDate"]["path"], moment())
    ns.saveChange(panelControlCampaigns["endDate"]["path"], moment().add(1, 'days'))
  }

  function setUrlQueryString() {
    let changes = ns.getChanged()
    if (changes) {
      if (changes.startDate instanceof moment) {
        changes.startDate = changes.startDate.utc().format("DD/MM/YYYY");
      }

      if (changes.endDate instanceof moment) {
        changes.endDate = changes.endDate.utc().format("DD/MM/YYYY");
      }
    }

    const query = qs.stringify(changes, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let entries = urlSearchParams.entries();

    let result = {};

    for (let entry of entries) {
      var key = entry[0];
      var val = entry[1];

      result[key] = val;
    }

    setQueryParams(result);
    setUrlChecked(true)
  }

  function cleanFilters(key) {
    ns.clear()
    const newFilter = { typeView: key }
    setDataAnalytics([])
    setDataSummary([])
    setQueryParams(newFilter)
    ns.saveChange(newFilter)
  }

  function toggleBlocking(callback = function () { }) {
    setBlockStatsConsole(!blockStatsConsole)
    callback()
  }

  const getStatisticsCampaignAnalytics = async (filterType, filterTypeSelected, startDate, endDate) => {
    try {
      const campaigns = await getCampaigns(filterType, filterTypeSelected, startDate, endDate)
      setDataAnalytics(campaigns)
    } catch (error) {
      console.error('Ocorreu um erro durante a busca de campanhas (Analytics)', error)
      setDataAnalytics([])
    }
  }

  const getStatisticsCampaignSummaries = async (filterType, aggregation, isManual, type) => {
    const campaigns = await getCampaignsSummary(filterType, aggregation, isManual, type)
    setDataSummary(campaigns)
  }

  const getStatisticsCampaignRules = async () => {
    const campaignRules = await api.Get(`/rules`);
    const campaignNameData = campaignRules.filter(el => !el.campaignid).map(el => ({
      code: el.name,
      description: el.name,
    }))

    const eventRules = await getAllRules()
    const eventNameData = eventRules.map(el => ({
      code: el.event,
      description: el.event,
    }))

    setCampaignRules(campaignNameData)
    setEventRules(eventNameData)
  }

  const orchestration = () => {
    const aggregation = ns.getChanged('agregado')
    const startDate = ns.getChanged('startDate')
    const endDate = ns.getChanged('endDate')
    const isManual = ns.getChanged('isManual')
    const filterTypeSummary = ns.getChanged('filterTypeSummary')
    const filterTypeAnalytics = ns.getChanged('filterTypeAnalytics')
    const filterTypeSelected = ns.getChanged('filterTypeSelected')
    const type = ns.getChanged('type')

    if (ns.getChanged("typeView") === "summaries") {
      getStatisticsCampaignSummaries(filterTypeSummary, aggregation, isManual, type)
    } else if (ns.getChanged("typeView") === "analytics") {
      getStatisticsCampaignAnalytics(filterTypeAnalytics, filterTypeSelected, startDate, endDate)
    }
  }

  return (
    <FzCard>
      <FzCard.Heading>
        <FzCard.Title componentClass="h2">Campanhas</FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <div className={"fz-panel"}>

          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["typeView"]} ns={ns} extraChangeEffect={cleanFilters} />
          </div>

          {(ns.getChanged("typeView") !== undefined && ns.getChanged("typeView") === "summaries") && (
            <>
              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["filterTypeSummary"]} ns={ns} />
              </div>

              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["agregado"]} ns={ns} />
              </div>

              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["isManual"]} ns={ns} />
              </div>

              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["type"]} ns={ns} />
              </div>
            </>
          )}

          {(ns.getChanged("typeView") !== undefined && ns.getChanged("typeView") === "analytics") && (
            <>
              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["filterTypeAnalytics"]} ns={ns} />
              </div>

              {ns.getChanged("filterTypeAnalytics") !== undefined && (
                <>
                  {ns.getChanged("filterTypeAnalytics") === "event" && (
                    <div className={"fz-panel-border-padding"}>
                      <FieldConnected
                        data={ns.getChanged()}
                        meta={panelControlCampaigns["filterTypeSelected"](ns.getChanged('filterTypeAnalytics'), eventRules)}
                        ns={ns} />
                    </div>
                  )}
                  {ns.getChanged("filterTypeAnalytics") === "campaignName" && (
                    <div className={"fz-panel-border-padding"}>
                      <FieldConnected
                        data={ns.getChanged()}
                        meta={panelControlCampaigns["filterTypeSelected"](ns.getChanged('filterTypeAnalytics'), campaignRules)}
                        ns={ns} />
                    </div>
                  )}
                </>
              )}

              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["startDate"]} ns={ns} />
              </div>

              <div className={"fz-panel-border-padding"}>
                <FieldConnected data={ns.getChanged()} meta={panelControlCampaigns["endDate"]} ns={ns} />
              </div>
            </>
          )}

          <div className={"fz-panel-border-padding"}>
            <FzButton
              onClick={orchestration}
              tip={"buscar"}
              disabled={false}
              className={null}
              block={true}
              fzStyle={"regular"}
            >{"Buscar"}</FzButton>
          </div>
        </div>

        <hr />

        {(ns.getChanged("typeView") !== undefined && ns.getChanged("typeView") === "analytics") && (
          <CampaignsAnalyticsTable data={dataAnalytics} ns={ns} />
        )}

        {(ns.getChanged("typeView") !== undefined && ns.getChanged("typeView") === "summaries") && (
          <CampaignsSummaryTable filterType={ns.getChanged('filterTypeSummary')} data={dataSummary} ns={ns} />
        )}

      </FzCard.Body>
    </FzCard>
  );
}

export default CampaignsOverview;
