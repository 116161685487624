import React, { Component } from 'react';
import { TaskDetails } from './task-details'
import { getTask, replaceTask, createTask } from '../../../api/integrations';
import { cronTasks } from '../../../biz/metadatas/crontask'
import { FzCard, FzJumbotron } from '../../fz/layout/index'
import { FzButtonGroup } from '../../fz/form/button'
import { validateAppField } from "../../../biz/validate-application";

class CronTask extends Component {
  constructor(props) {
    super(props)
    this.state = { askPassword: false }

    this.persistChanges = this.persistChanges.bind(this)
    this.goParent = this.goParent.bind(this)
  }

  formFieldIsEmpty = async data => {
    const { ns } = this.props;
    const formElmentItens = Object.keys(cronTasks);
    const checkIsEmptyFields = await Promise.all(
      formElmentItens.map(item => {
        return validateAppField({
          value: data[item],
          data,
          ns,
          metaField: cronTasks[item],
        })
      })
    )

    const invalidFields = checkIsEmptyFields
      .filter(({ valid }) => !valid)
      .map(({ path }) => path)

    ns.set("INVALID", invalidFields)

    return invalidFields
  }

  persistChanges() {
    const that = this
    const { ns } = that.props

    let tmpTask = ns.getChanged()
    if (tmpTask) {
      if (tmpTask._id === "new") {
        delete tmpTask._id
        createTask(tmpTask)
          .then((task) => {
            ns.unset("dirty");
            ns.set("task", task)
            ns.stopEditing(task);
          })
          .catch((err) => {
            ns.unset("dirty");
            console.error("API ERROR:", err);
            ns.stopEditing();
            ns.unset("task")
          })
      } else {
        replaceTask(tmpTask.name, tmpTask)
          .then((task) => {
            ns.unset("dirty");
            ns.set("task", task)
            ns.stopEditing(task);
          })
          .catch((err) => {
            ns.unset("dirty");
            console.error("API ERROR:", err);
            ns.stopEditing();
            ns.unset("task")
          })
      }
    }
    this.goParent()
  }

  goParent() {
    const that = this
    let { ns } = that.props
    ns.unset("dirty");
    ns.stopEditing();
    ns.unset("task")
    this.props.history.push("/manager/cron")
  }

  loadTask() {
    const { ns, match } = this.props;
    const { taskName } = match.params;

    if (taskName === "new") {
      const data = { "_id": "new" };
      ns.startEditing(data);
      ns.set("task", data);
    } else {
      getTask(taskName).then((task) => {
        ns.set("task", task);
        ns.startEditing(task); 
      });
    }
  }

  componentDidMount() {
    this.loadTask();
  }

  render() {

    let { ns } = this.props
    let task = ns.getChanged() || ns.get("task")

    let buttons
    let editArea = <TaskDetails ns={ns} data={task} persistChanges={this.persistChanges} metas={cronTasks} />
    if (ns.isEditing()) {
      let btn = [
        {
          fzStyle: "regular",
          description: "Gravar",
          onClick: async () => {
            const invalidFields = await this.formFieldIsEmpty(task);
            if (invalidFields.length === 0) {
              this.persistChanges(); 
            }}
        },
        {
          fzStyle: "alert",
          description: "Cancelar",
          onClick: this.goParent,
        },
      ];

      buttons = (<FzButtonGroup buttons={btn} />);
    } else {
      let btn = [
        {
          description: "Editar",
          onClick: () => ns.startEditing(task),
        }
      ];
      buttons = (<FzButtonGroup buttons={btn} />);
    }

    return (
      <FzJumbotron>
        <FzCard>
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">Editar Tarefa Agendada</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            {buttons}
            {editArea}
          </FzCard.Body>
        </FzCard>
      </FzJumbotron>
    )
  }
}
export { CronTask };
