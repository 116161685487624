import { Meta } from '../../types/metas';

export const ModalDeleteUserSU: Meta = {
  cardId: {
    path: 'cardId',
    label: 'Card ID',
    dataType: 'string',
    inputType: 'text',
    inputProps: {},
    placeholder: 'HD-1234',
    validationMessage: 'Card ID inválido',
    pattern: /^[a-zA-Z]{2,}\d{1,}$|^[a-zA-Z]{2,}-\d{1,}$/,
    normalize: (value: string) => {
      return normalizeCardId(value);
    },
    isReadOnly: () => false,
    isMandatory: () => true,
  },
};

export function normalizeCardId(value: string) {
  if (
    /^[a-zA-Z]{2,}\d{1,}$|^[a-zA-Z]{2,}-\d{1,}$/.test(value) &&
    value.indexOf('-') === -1
  ) {
    return (
      value.substring(0, value.search(/\d/)) +
      '-' +
      value.substring(value.search(/\d/))
    ).toUpperCase();
  } else {
    return value.toUpperCase();
  }
}

export function isCardIdValid(value: string) {
  const pattern = /^[a-zA-Z]{2,}\d{1,}$|^[a-zA-Z]{2,}-\d{1,}$/
  return pattern.test(value)
}

export type ExcludeApplicationsResponse = {
  userId: string;
  applicationsCount: number;
  campaignsCount: number;
  error?: string;
};

export type CounterType = {
  usersCount: number;
  applicationsCount: number;
  campaignsCount: number;
  errorsCount: number;
}

export type StateType = (arg0: any) => void;