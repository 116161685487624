import {getCustomer} from "../api/crm";

export const setCustomerDataToNs = (custumerId, ns) => {
  return getCustomer(custumerId)
    .then(data => {
      ns.set("customer", data);
      data.applications.forEach(app => {
        ns.set("crm.treatments." + app.id, "");
        ns.set("crm.treatmentsArray." + app.id, []);
      }) 
    
      return data
    })
    .catch((err) => {
      console.warn("API ERROR", err);
    });
}
