import { isArray } from 'highcharts'
import moment from 'moment'

const arrayToString = (template: string, array: Array<Record<string, any>>, keyToReturn: string) => {
  const match = template.match("\{(.*)\}")
  
  if (match && isArray(array)) {
    const conditionSplit = match[1].split(":")
    const condition = {
      key: conditionSplit[0],
      value: conditionSplit[1]
    }
    
    const matchElement = array.find(element => element[condition.key] === condition.value)

    if (matchElement) {
      if (keyToReturn.split(".").length > 1) {
        let returnValue

        keyToReturn.split(".").forEach(value => {
          returnValue = returnValue ? returnValue[value] : matchElement[value]
        })

        return returnValue
      } else {
        if (typeof matchElement[keyToReturn] === 'object') {
          return JSON.stringify(matchElement[keyToReturn]) 
        } else {
          return matchElement[keyToReturn]
        }
      }
    }
  }

  return ""
}

const numberToString = (value: number): string => {
  return `="${value}"`;
}
const moneyToStringWithComma = (value: number): string => {
  var tmp = String(value)
  return tmp.replace(".", ",");
}
const booleanToStringPT = (value: boolean): string => {
  return value ? "Sim" : "Não"
}
const translateWorkStatus = (workNameFromAPI: string): string => {
  const allWorkStatus = {
    'private-employee': 'Empregado privado',
    'public-employee': 'Empregado público',
    'independent-contractor': 'Autônomo',
    'business-owner': 'Empresário',
    'liberal-professional': 'Liberal',
    'retired': 'Aposentado-Pensionista',
    'unemployed': 'Desempregado',
  }
  return allWorkStatus[workNameFromAPI] ? allWorkStatus[workNameFromAPI] : ''
}
const applicationStateToString = (stateNumber: number): string => {
  const allApplicationStates = {
    1: 'Em Cadastro',
    2: 'Cadastrada',
    4: 'Em análise',
    8: 'Proposta Enviada',
    16: 'Encerrada',
    32: 'Proposta aceita',
    64: 'Em follow-up',
    128: 'Arquivada',
  }

  return allApplicationStates[stateNumber] ? 
    allApplicationStates[stateNumber] : `? (${stateNumber})`
}
const educationToStringPT = (name: string): string => {
  const allEducationsName = {
    "illiterate": 'Sem instrução formal',
    "primary": 'Primeiro grau completo',
    "secondary": 'Segundo grau completo',
    "college": 'Superior completo',
    "postgraduate": 'Pós graduação',
  }

  return allEducationsName[name] ? allEducationsName[name] : ''
}

const housingToStringPT = (name: string): string => {
  const allHousingInEnglish = {
    "owned": 'Própria',
    "mortgage": 'Própria (financiada)',
    "rented": 'Alugada',
    "family": 'De familiares',
    "others": 'Outro tipo',
  }

  return allHousingInEnglish[name] ? allHousingInEnglish[name] : ''
}

const parseAuditEvent = (value) => {
  if (value.length > 0) {
    var dateFirst = new Date(), idFirst = 0, i = 0;
    for (i = 0; i < value.length; i++) {
      if (moment(value[i].eventdate).isBefore(dateFirst)) {
        dateFirst = value[i].eventdate;
        idFirst = i;
      }
    }
    var dateLast = dateFirst, idLast = idFirst;
    for (i = 0; i < value.length; i++) {
      if (moment(value[i].eventdate).isAfter(dateLast)) {
        dateLast = value[i].eventdate;
        idLast = i;
      }
    }
    return value[idFirst].oldvalue + "/" + value[idLast].newvalue;
  }
  return '/';
}

export { 
  arrayToString,
  numberToString,
  moneyToStringWithComma,
  booleanToStringPT,
  translateWorkStatus,
  applicationStateToString,
  educationToStringPT,
  housingToStringPT,
  parseAuditEvent,
}
