import { jsonToCsv } from './csv-exporter-utils/exporting-utils';
import { 
  applicationStateToString,
  booleanToStringPT,
  educationToStringPT,
  housingToStringPT,
  moneyToStringWithComma,
  numberToString,
  parseAuditEvent,
  translateWorkStatus
} from './csv-exporter-utils/provide-marketing-csv-utils'

const exportingFields = [
  { key: '_id', name: 'Id' },
  { key: 'applicationstate', name: 'Status da proposta', format: applicationStateToString },
  { key: 'closedreason', name: 'Motivo do fechamento' },
  { key: 'product', name: 'Produto' },
  { key: 'email', name: 'E-mail' },
  { key: 'firstname', name: 'Nome' },
  { key: 'lastname', name: 'Sobrenome' },
  { key: 'gender', name: 'Gênero' },
  { key: 'telephonenumber', name: 'Celular', format: numberToString },
  { key: 'zipcode', name: 'CEP', format: numberToString },
  { key: 'created', name: 'Criada' },
  { key: 'personalnumber', name: 'CPF', format: numberToString },
  { key: 'birthdate', name: 'Data nascimento' },
  { key: 'workstatus', name: 'Ocupação', format: translateWorkStatus },
  { key: 'appliedamount', name: 'Valor emprestimo', format: moneyToStringWithComma },
  { key: 'monthlyincome', name: 'Renda mensal', format: moneyToStringWithComma },
  { key: 'education', name: 'Grau de instrução', format: educationToStringPT },
  { key: 'housing', name: 'Tipo de residência', format: housingToStringPT },
  { key: 'bankname', name: 'Nome do banco' },
  { key: 'hascreditrestrictions', name: 'Restrições de crédito?', format: booleanToStringPT },
  { key: 'serasascore', name: 'Serasa Score', format: moneyToStringWithComma },
  { key: 'auction.startdate', name: 'Inicio do leilão' },
  { key: 'auction.startedbyname', name: 'Iniciado por' },
  { key: 'auditevents', name: "Produto Original/Produto Atual", format: parseAuditEvent },
  { key: 'trackingdata.utm_campaign', name: 'Campanha UTM' },
  { key: 'trackingdata.utm_content', name: 'Conteudo UTM' },
  { key: 'trackingdata.utm_medium', name: 'Medio UTM' },
  { key: 'trackingdata.utm_source', name: 'Fonte UTM' },
  { key: 'trackingdata.referrer', name: 'Referência UTM' },
  { key: 'trackingdata.deviceId', name: 'Dispositivo Id' },
  { key: 'trackingdata.clientId', name: 'Google Client Id', format: numberToString },
  { key: 'trackingdata.isMobile', name: 'Dispositivo Mobile?' },
  { key: 'trackingdata.browser', name: 'Browser' },
  { key: 'trackingdata.browserVersion', name: 'Versão do Browser' },
  { key: 'trackingdata.os', name: 'OS' },
  { key: 'trackingdata.osVersion', name: 'Versão do OS' },
  { key: 'trackingdata.creationPage', name: 'página de criação' },
  { key: 'age', name: 'Idade' },
]

function getCsvSummariesMarketingReport(jsonList) {
  return jsonToCsv(jsonList, exportingFields);
}

export { getCsvSummariesMarketingReport }