
const filterActiveAuctionApplications = (apps, revert) => {
  if (!apps) return apps;

  const filtered = apps.filter((a) => {
    //Application.onLoad(a)
    if ([4, 8, 32, 64].indexOf(a.applicationState) >= 0) {
      let x = new Date(a.auction.endDate)
      return !!revert ? false : (x > (new Date()))
    }
    return !!revert
  })
  return filtered
}

const filterLeadApplications = (apps) => {
  if (!apps) return apps;

  const filtered = apps.filter((a) => {
    //Application.onLoad(a)
    return (a.applicationState === 2)
  })
  return filtered
}

const filterInactiveAuctionApplications = (apps) => {
  const filtered = apps.filter((a) => {
    //Application.onLoad(a)
    if ([4, 8, 32, 64].indexOf(a.applicationState) >= 0) {
      let x = new Date(a.auction.endDate)
      return (x < (new Date()))
    }
    return (a.applicationState !== 2)
  })
  return filtered
}


export { filterActiveAuctionApplications, filterInactiveAuctionApplications, filterLeadApplications }