import { URLShortenerContainer } from '../../../Containers/Messages/URLShortener';
import { FzCard } from '../../fz/layout';

export function URLShortener({ ns }) {
  const data = ns.getChanged();

  return (
    <>
      <FzCard.Body fzStyle={{ padding: "1.5rem" }}>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title>Encurtador de links</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body fzStyle={{ padding: "1.5rem" }} >
            <URLShortenerContainer
              data={data}
              ns={ns}
            />
          </FzCard.Body>
        </FzCard>
      </FzCard.Body >
    </>)
}