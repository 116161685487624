import { Application } from '../../../biz/metadatas/application';
import { FzBadge, FzCard, FzCol, FzRow } from '../../../ui/fz/layout/index';
import { formatDateTime, formatPersonalNumber } from '../../../components/formatters';
import FieldConnected from '../../../ui/components/form/field-connected';
import cloneDeep from 'lodash/cloneDeep';

export const TicketDetails = ({ customer, ns }) => {
  const meta = cloneDeep(Application.fields['telephoneNumber']);
  meta.inputProps.whatsappOptin =
    customer.whatsappOptin === undefined || customer.whatsappOptin === true
      ? false
      : true;

  let acceptWhatsappContact;
  if (customer.whatsappOptin === undefined) {
    acceptWhatsappContact = (
      <FzBadge> Cliente não informou preferência de contato </FzBadge>
    );
  } else if (customer.whatsappOptin === true) {
    acceptWhatsappContact = <FzBadge> Aceita contato por WhatsApp </FzBadge>;
  } else {
    acceptWhatsappContact = <FzBadge> Recusa contato por WhatsApp </FzBadge>;
  }
  let inactiveAlert;
  if (!customer.active) {
    inactiveAlert = <FzBadge fzStyle="alert">Cliente Inativo </FzBadge>;
  }

  return (
    <FzCard>
      <FzCard.Heading fzStyle="">
        <FzCard.Title componentClass="h3">
          Cliente: {customer.name} {inactiveAlert}
        </FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <FzRow>
          <FzCol span={6}>
            <strong>Email</strong>
            <div>{customer.email}</div>
          </FzCol>
          <FzCol span={6}>
            <strong>CPF</strong>
            <div>{formatPersonalNumber(customer.personalNumber)}</div>
          </FzCol>
        </FzRow>
        <FzRow>
          <FzCol span={6}>
            <strong>Cliente desde</strong>
            <div>{formatDateTime(customer.created)}</div>
          </FzCol>
          <FzCol span={6}>
            <div>
              <FieldConnected
                meta={meta}
                data={{ telephoneNumber: customer.telephoneNumber }}
                ns={ns}
              />
              { acceptWhatsappContact }
            </div>
          </FzCol>
        </FzRow>
      </FzCard.Body>
    </FzCard>
  );
};
