
import moment from 'moment'
import { Meta } from '../../../../types/metas'

export const normalizeDate = (value) => {
  return  (
    typeof (value) !== "string"
    && typeof (value) !== "number"
    && typeof (value) !== "function" 
    && value !== null 
    && value.toISOString
      ? value.toISOString() 
      : value
  )
}

export const analyticsFilter = {
  "from": {
    "path": "from",
    "label": "De",
    "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "section": "filters-user-documents-analytics",
    "isMandatory": () => false,
    "normalize": normalizeDate
  },
  "to": {
    "path": "to",
    "label": "Até",
    "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "section": "filters-user-documents-analytics",
    "isMandatory": () => false,
    "normalize": normalizeDate
  },
  "applicationId": {
    "path": "applicationId",
    "label": "Application Id",
    "inputType": "text",
    "inputProps": {},
    "isMandatory": () => false,
    "isReadOnly": () => false,
    "section": "filters-user-documents-analytics",
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "inputType": "select",
    "inputProps": {
      "options": [],
    },
    "isMandatory": () => false,
    "isReadOnly": () => false,
    "section": "filters-user-documents-analytics",
  },
}

export const analyticsColumns: Meta = {
  "applicationId": {
    "path": "applicationId",
    "label": "AppId",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "applicationId",
      "shortLabel": undefined,
      "width": "4rem",
      "dataSort": true,
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataAlign": "left",
      "columnFormat": "applicationIdColumn",
    },
    "isMandatory": (application) => false,
  },
  "created": {
    "path": "created",
    "label": "Data e Hora",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Data_Hora",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "formatDateWithTime",
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Documento",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Documento",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "documentSubtype": {
    "path": "documentSubtype",
    "label": "Tipo de Documento",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Documento",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "supplierInternalname": {
    "path": "supplierInternalname",
    "label": "Parceiro",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Parceiro",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
    "isMandatory": () => false
  },
  "branch": {
    "path": "branch",
    "label": "Branch",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Documento",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "tries": {
    "path": "tries",
    "label": "Tentativas",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Documento",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "ocrScore": {
    "path": "ocrScore",
    "label": "Ocr score",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Ocrscore",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "minScore": {
    "path": "minScore",
    "label": "Score Min",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Ocrscore",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "status": {
    "path": "status",
    "label": "Status",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "booleanColumn_approved_rejected",
      "filter": { "type": 'SelectFilter', "options": "filters.boolean_approved_rejected" },
    },
    "isMandatory": () => false
  },
  "reason": {
    "path": "reason",
    "label": "Motivo",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "Ocrscore",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
}
