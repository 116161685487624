import React from 'react';
import { FzCard, FzCol, FzRow, FzSplitLine } from '../fz/layout/index'
import { editBid } from "../../biz/metadatas/stats-bids"
import FieldConnected from '../components/form/field-connected';
import cloneDeep from "lodash/cloneDeep";
import { FzButton } from '../fz/form';
import { updateBid } from '../../api/applications';

export class EditBid extends React.Component<any, any>  {

  constructor(props) {
    super(props)

    this.startEditingBid = this.startEditingBid.bind(this)
    this.state = { editando: false }
    this.createColumns = this.createColumns.bind(this)
    this.cancelBidUpdate = this.cancelBidUpdate.bind(this)
    this.update = this.update.bind(this)
  }

  createColumns(fieldElms: any[], n: number) {
    let lastRow: any[] = [];
    let rows: any[] = [];

    for (let iteratorFieldElms = 0; iteratorFieldElms < fieldElms.length; iteratorFieldElms++) {
      lastRow.push(
        <FzCol key={iteratorFieldElms} >{fieldElms[iteratorFieldElms]}</FzCol>)
      if (lastRow.length > n - 1) {
        rows.push(lastRow);
        lastRow = [];
      }
    }

    return rows
  }

  startEditingBid(newNs, bid, ns) {

    const cloneBid = cloneDeep(bid)
    cloneBid.alternateOffers = []
    this.setState({ editando: true }, () => {
      newNs = ns.copy("editingBid")
      newNs.startEditing(cloneBid)
    })

    return newNs
  }


  cancelBidUpdate(newNs) {
    this.setState({ editando: false }, () => {
      newNs.stopEditing()
    })
  }

  async update(newNs, app, bid, ns) {
    const data = newNs.getChanged()
    const applicationId = app.id
    const bidId = bid.id

    const resultApplication = await updateBid(applicationId, bidId, data)
    this.setState({ editando: false }, () => {
      newNs.stopEditing()
      if (resultApplication && resultApplication.id) {
        ns.set("application", resultApplication)
      }
    })
  }


  render() {
    let { ns, bid, app } = this.props


    let dataBid
    let newNs

    if (this.state.editando) {
      dataBid = cloneDeep(bid)
      newNs = ns.copy("editingBid")
    }



    const fieldElms = Object.keys(editBid).map((meta) => {
      if (this.state.editando) {
        return <FieldConnected data={dataBid} meta={editBid[meta]} ns={newNs}></FieldConnected>
      } else {
        return <FieldConnected data={bid} meta={editBid[meta]} ns={ns}></FieldConnected>
      }
    })


    var rows = this.createColumns(fieldElms, fieldElms.length)

    var fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>;
    });


    return (
      <FzCard onClick={(e) => { e.stopPropagation() }}>

        <FzCard.Body>
          {fieldsContent}
          <FzSplitLine>
            <FzSplitLine.Left></FzSplitLine.Left>
            <FzSplitLine.Right>
              {!this.state.editando ? (
                <FzButton fzStyle="attention" onClick={() => this.startEditingBid(newNs, bid, ns)}>Editar</FzButton>
              ) : (
                  <>
                    <FzButton fzStyle="alert" onClick={() => this.cancelBidUpdate(newNs)}>Cancelar</FzButton>
                    <FzButton fzStyle="regular" onClick={() => this.update(newNs, app, bid, ns)}>Atualizar</FzButton>
                  </>
                )}
            </FzSplitLine.Right>
          </FzSplitLine>

        </FzCard.Body>

      </FzCard>
    )
  }
}

export default EditBid;
