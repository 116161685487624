import React, { useState, useEffect, ReactNode } from 'react';
import { Button, ButtonGroup, Nav, Navbar } from 'react-bootstrap';
import SystemInfo from '../application-components/system-info';
import { AuditLog } from '../ui/blocks/application-components/audit-log';
import { get as getLodash } from 'lodash'
import { FzToast } from '../ui/fz/form/notification/toast';

import { SalesApplicationActions } from './actions-application';
import { validateAppField, validateApplication } from '../biz/validate-application';
import { AuctionInfo } from '../application-components/auction-info';
import SimulationData from '../application-components/simulation-data';
import { APPLICATION_STATE } from '../business/application'
import { getByID, validatePolicy, updateApplication, updateCarPlate, getAllProducts, getBySupplier } from '../api/applications';
import { getSuppliers } from '../scripts/utils/cache-helpers'
import { ApplicationSection } from '../ui/blocks/application-section'
import { CustomerAttendance } from '../ui/blocks/application'
import { PaidApplication } from '../ui/blocks/paid-application'
import { Score } from '../ui/blocks/score'
import { Application, flatApplication } from '../biz/metadatas/application';
import { FzCardGroup, FzCol, FzRow, FzJumbotron } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';
import { useParams, useHistory } from "react-router-dom";
import { CustomerAttendanceHermes } from '../Containers/Hermes/attendance/ticketAttendance';
import { GetAllForm } from '../services/api/dory';

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Veículo antigo ou não aceito por nenhum dos nossos parceiros.',
  bodyMessageTost: 'Para continuar, caso o cliente tenha outro veículo, altere a placa.',
  fzStyle: 'danger',
  delay: 8000,
  autoHide: true,
}

const toastLicensePlateMessage = {
  headerMessageTost: 'Error placa do veículo.',
  bodyMessageTost: 'Para continuar, é necessário preencher a placa.',
}

export let loanInfoMeta = {
  "appliedAmount": {
    "path": "appliedAmount",
    "label": "Valor Solicitado",
    "isMandatory": (application) => true,
  },
  "loanPeriodInMonths": {
    "path": "loanPeriodInMonths",
    "label": "Número de prestações",
    "isMandatory": (application) => true,
  },
  "loanReason": {
    "path": "loanReason",
    "label": "Motivo do empréstimo",
    "isMandatory": (application) => true,
  },
}

type OptionsType = {
  code: string;
  description: string;
}

type ValidateApplicationType = {
  path: string;
  valid: boolean;
}

export function SalesEditApplication({ ns, api, displayCustomerAttendance, onNeedUpdate }) {
  const [supplierInternalName, setSupplierInternalName] = useState('application')
  const [ticketApp, setTicketApp] = useState(null);
  const [category, setCategory] = useState();
  const [forms, setForms] = useState<null|any[]>(null);
  const [editableFields, setEditableFields] = useState<null|any>(null);
  const [onDisable, setOnDisable] = useState(false);
  const [editingConfirmationSteps, setEditingConfirmationSteps] = useState(false);

  const [working, setWorking] = useState(false)
  const [allProductsList, setAllProductsList] = useState<OptionsType[]>([])
  const { applicationId } = useParams<{ applicationId: string }>();
  const [licensePlateError, setLicensePlateError] = useState(false)
  let history = useHistory();
  const hasSupplierSelected = supplierInternalName !== 'application';

  useEffect(() => {
    const ticket = ns.getShared("ticket")  
    if (ticket && ticket.applicationId === applicationId) {
      setTicketApp(ticket)
    }
    const category = ns.getShared('crm.category') 
    setCategory(category)
    getSuppliers(ns)
    loadAllProducts();
    loadAllForms();
  }, [])

  async function handleApplicationBySupplier(supplierInternalName) {
    const applicationBySupplier = await getBySupplier(applicationId, supplierInternalName)
    ns.set(`${supplierInternalName}.application`, applicationBySupplier)
    setSupplierInternalName(supplierInternalName)
  }

  async function loadAllProducts() {
    const productsResp = await getAllProducts();
    const productList: OptionsType[] = productsResp.map(it => ({
      code: it.internalName,
      description: it.description
    }))

    setAllProductsList(productList)
  }

  async function loadAllForms() {
    const formsResponse = await GetAllForm();

    if(formsResponse?.data) {
      setForms(formsResponse.data)
    }
  }

  async function validate(data) {
    let results: ValidateApplicationType[] = await validateApplication(data, ns, undefined)
    results = results.filter(it => it !== null)
    results = hasVehicleSecurityVehicleTypeEqualTruck(data, results);
    results.forEach((it) => !it.valid && ns.setInvalid(it.path))
    return results.filter((it) => !it.valid).length === 0
  }

  function hasVehicleSecurityVehicleTypeEqualTruck(data, results) {
    if (data.vehicleSecurity &&
      data.vehicleSecurity.vehicleType &&
      data.vehicleSecurity.vehicleType === 'truck') {
      results.find(it => it.path === "vehicleSecurity.brand").valid = true;
      // results.find(it => it.path === "vehicleSecurity.model").valid = true;
    }
    return results;
  }

  function validateStartAuction() {
    if (ns.isEditing()) {
      return false;
    }
    let app = ns.get("application");
    if (!validate(app)) {
      ns.startEditing(app);
      return false;
    }
    return true;
  }

  function isEditingCarPlate(app) {
    const bids = getLodash(app, ['auction', "bids"], [])
    const hasAcceptBid = bids.filter(bid => bid?.accepted)?.length > 0

    if(!hasAcceptBid && app.product === 'Refin' && [APPLICATION_STATE.HasBids, APPLICATION_STATE.Accept, APPLICATION_STATE.FollowUp].includes(app.applicationState)) {
      return true
    }
    
    return false
  }

  function persistChanges(final) {
    let tmpApp = ns.getChanged()
    const currentApp = ns.get("application")

    if(currentApp && currentApp.product === "Refin" && tmpApp.vehicleSecurity && !tmpApp.vehicleSecurity.registrationNumber) {
      return setLicensePlateError(true)
    }

    setOnDisable(true)

    if (tmpApp && !ns.getShared("vehicleSecurityModelfullError", false)) {
      setWorking(true)
      updateApplication(tmpApp)
        .then(
          (application) => {
            ns.unset("dirty");
            ns.set("application", application)
            if (final) {
              ns.stopEditing(application);
              if (Object.keys(application.supplierData).length === 0) {
                validatePolicy(application.id, application.revision)
                  .then((newApp) => {
                    ns.set("application", newApp)
                    setWorking(false)
                  })
              } else {
                setWorking(false)
              }
              ns.stopEditing(application);
            } else {
              setWorking(false)
              ns.startEditing(application);
            }
            setOnDisable(false)
            setLicensePlateError(false)
            setEditingConfirmationSteps(false)
          })
          .catch(
            (err) => {
              ns.unset("dirty");
              console.error("API ERROR:", err);
              ns.stopEditing();
              ns.unset("application")
              setWorking(false)
              setOnDisable(false)
              setLicensePlateError(false)
              setEditingConfirmationSteps(false)
          })
    } else {
      setWorking(false)
      setOnDisable(false)
      ns.stopEditing()
      setLicensePlateError(false)
    }
  }

  function startEditing(app, fields) {
    ns.startEditing(app, fields)
  }

//   async function formFieldIsEmpty(data) {
//     const formElementItems = Object.keys(Application.fields); 
//     const checkIsEmptyFields = await Promise.all(
//       formElementItems.map(item => {
//         return validateAppField({
//           value: data[item],
//           data,
//           ns,
//           metaField: Application.fields[item],
//         })
//       })
//     )

//     const invalidFields = checkIsEmptyFields
//         .filter(({ valid }) => !valid)
//         .map(({ path }) => path);

//     ns.set("INVALID", invalidFields);

//     return invalidFields;
// }

  async function saveChanges() {
    if (!editingConfirmationSteps && isEditingCarPlate(app) && !ns.getShared("vehicleSecurityModelfullError", false)) {
      setOnDisable(true)
      const appId = app.id
      const bid = app.auction.bids.find(bid => bid.supplier.internalName === 'omni')
      const carPlateData = ns.getChanged().vehicleSecurity.registrationNumber
      const payload = {
        fieldsOverrides: {
          registrationNumber: carPlateData
        }
      }
      if (bid && carPlateData && carPlateData !== "" && carPlateData !== undefined) {
        if(!carPlateData) {
          return setLicensePlateError(true)
        }
        setWorking(true)
        updateCarPlate(appId, bid.id, payload).then((result) => {
          ns.stopEditing()

        }).catch((err) => {
          ns.unset("dirty");
          console.error("API ERROR:", err);
          ns.unset("application")

        }).finally(() => {
          ns.stopEditing();
          setWorking(false)
        })
      } else {
        ns.stopEditing()
      }
      return false
    }
    // const invalidFields = await formFieldIsEmpty(ns.getChanged());
    // if (invalidFields.length === 0) {
        persistChanges(true);
  
  }

  function changeFieldsWhenVehicleTypeIsTruck(vehicleSecurityData) {
    // const metaFieldModel = vehicleSecurityData.find(metaField => metaField.path === 'vehicleSecurity.model');
    // metaFieldModel.inputType = 'text';
    // metaFieldModel.hookOnSave = undefined;
    // metaFieldModel.extraValidation = undefined;
    // metaFieldModel.pattern = undefined;

    const metaFieldBrand = vehicleSecurityData.find(metaField => metaField.path === "vehicleSecurity.brand");
    metaFieldBrand.inputType = 'text';
    metaFieldBrand.hookOnSave = undefined;
    metaFieldBrand.extraValidation = undefined;
    metaFieldBrand.pattern = undefined;

    const metaFieldValue = vehicleSecurityData.find(metaField => metaField.path === "vehicleSecurity.value");
    metaFieldValue.isReadOnly = undefined;
    metaFieldValue.extraValidation = undefined;
  }

  function createViewBySupplier(): ReactNode {
    let viewBySupplier = [{
      code: 'application',
      description: 'Proposta Original'
    }];
    if (app && app.auction && app.auction.bids) {
      const supplierOptions = app.auction.bids.filter(bid => bid.fieldsOverrides)
        .map(bidFiltered => {
          return {
            code: bidFiltered.supplier.internalName,
            description: bidFiltered.supplier.name
          };
        });
      viewBySupplier.push(...supplierOptions);
    }

    let navbar = <Navbar className="report-navbar">
      <Nav className="adjusted-nav" variant="tabs" onSelect={handleApplicationBySupplier} activeKey={supplierInternalName}>
        {viewBySupplier.map(it => {
          return <Nav.Link eventKey={it.code} title={it.code}> {it.description}</Nav.Link>;
        })}
      </Nav>
    </Navbar>;
    return navbar;
  }



  let alert: ReactNode
  let app = supplierInternalName !== "application" ? ns.get(`${supplierInternalName}.application`) : ns.getChanged() || ns.get("application");

  useEffect(() => {
    if(app && forms) {
      const fields = (app?.supplierData && Object.keys(app.supplierData).map(supplierInternalName => {
        if(app.supplierData[supplierInternalName]?.confirmationSteps.length) {
          let productForm = forms && forms.find(form => form.product === app.product);
          return app.supplierData[supplierInternalName].confirmationSteps.map(confirmationStep => {
            if(confirmationStep.type === "form" && !confirmationStep?.completed) {
              const formProps = confirmationStep?.value?.split("_");
              return productForm && (productForm?.pages[formProps[1] - 1]?.sections.find(section => section.type === formProps[2]))?.fields
            }
          })
        }
      })).flat(2).filter(field => field)

      setEditableFields([...Array.from(fields)].filter(field => field !== "bankName"))
    }
  }, [app, forms])

  
  var auditlog = ns.get("auditlog")
  const metas: any = flatApplication(Application.fields)

  const identifier = metas.filter(m => m.section === "identifier")
  const personalData = metas.filter(m => m.section === "personal-data")
  const operationDetails = metas.filter(m => m.section === "operation-details")
  const residenceData = metas.filter(m => m.section === "residence-data")
  const workData = metas.filter(m => m.section === "work-information")
  const vehicleSecurityData = metas.filter(m => m.section === "vehicle-security-data")
  const homeSecurityData = metas.filter(m => m.section === "home-security-data")
  const bankInformation = metas.filter(m => m.section === "bank-data")
  const score = metas.filter(m => m.section === "score")

  let metaProduct = operationDetails.filter(it => it.path === 'product')[0]
  metaProduct.inputProps = { options: allProductsList }

  useEffect(() => {
    if (!app) {
      getByID(applicationId)
      .then((application) => {
          if (application.vehicleSecurity && application.vehicleSecurity.modelFull.length === 0) {
            application = {
              ...application,
              vehicleSecurity: {
                ...application.vehicleSecurity,
                modelFull: application.vehicleSecurity.model,
              }
            };
          }
          ns.set("application", application);
          // get the auditlogs for this application

          api.appGetAuditLog(applicationId,
            (status, al) => {
              if (status === 200) {
                auditlog = al;
                ns.set("auditlog", al);
              }
            });
        })
        .catch((error) => {
          console.warn("ERROR getting application", applicationId, error)
          history.push("/sales")
        });
    } else {
      if (!app.customerId) {
        alert = <FzAlert fzStyle="alert">Cliente removido </FzAlert>
      }
    }
  }, [])


  if (app) {
    if (app.accountType === null && app.bankName === "000") {
      bankInformation.map((metaField) => {
        if (metaField.path === "accountType") {
          metaField.placeholder = "Cliente não tem conta"
        }
        return metaField
      })
    }
  }

  if (app) {
    if (app.vehicleSecurity &&
      app.vehicleSecurity.vehicleType &&
      app.vehicleSecurity.vehicleType === 'truck') {
      app.vehicleSecurity.modelFull = (
        app.vehicleSecurity.modelFull && app.vehicleSecurity.modelFull.length > 0
          ? app.vehicleSecurity.modelFull
          : app.vehicleSecurity.model
      )
      changeFieldsWhenVehicleTypeIsTruck(vehicleSecurityData);
    }
  }

  var buttons: ReactNode
  let workingElement: ReactNode

  if (working) {
    workingElement = <FzAlert fzStyle="attention">Validando os dados no servidor!</FzAlert>
  }
  if (!app) {
    return null;
  }

  if (ns.isEditing()) {
    buttons = (<ButtonGroup>
      <Button variant="primary" onClick={saveChanges} disabled={onDisable}>Gravar</Button>
      <Button variant="default" onClick={() => { ns.unset("dirty"); ns.stopEditing(); }} disabled={onDisable}>Cancelar</Button>
    </ButtonGroup>
    );
    if (ns.isInvalid()) {
      alert = <FzAlert fzStyle="alert" title={ns.getInvalidFields().join()}><strong>Corrija os campos marcados de vermelho!</strong></FzAlert>
    }
  } else {
    let reloadButton = <Button variant="default"
      onClick={() => {
        getByID(app.id).then((newApp) => {
          app = newApp;
          ns.set("application", newApp)
          ns.unset("formalization")
        });
      }
      }
    >Atualizar tela</Button>
    if (app.applicationState <= APPLICATION_STATE.Lead) {
      buttons = (
        <FzCol span={6} className="FzCol-right">
          <ButtonGroup>
            {reloadButton}
          </ButtonGroup>
        </FzCol>
      );
    } else if (isEditingCarPlate(app) && !editingConfirmationSteps) {
      buttons = (<>
        <FzCol span={6}>
          <ButtonGroup>
            <Button variant="primary" disabled={working} onClick={() => startEditing(app, ['vehicleSecurity.registrationNumber', 'vehicleSecurity.model'])}>Editar placa do Veículo</Button>
          </ButtonGroup>
        </FzCol>
        <FzCol span={6} className="FzCol-right">
          <ButtonGroup>
            {reloadButton}
          </ButtonGroup>
        </FzCol>
      </>);
    } else {
      buttons = (
        <ButtonGroup>
          {reloadButton}
        </ButtonGroup>
      );
    }
  }

  let security;
  if (app.product === "Refin") {
    security =
      <ApplicationSection ns={ns} data={app} metas={vehicleSecurityData} title="Detalhes do Veiculo" />
  } else if (app.product === "HE") {
    security = <ApplicationSection ns={ns} data={app} metas={homeSecurityData} title="Detalhes do Imóvel" />
  }

  const metasPersonalData = identifier.concat(personalData)
  const objectProduct = allProductsList.filter(it => it.code === app.product)

  let navbar = createViewBySupplier();

  let bid
  if (supplierInternalName !== 'application' && app && app.auction) {
    bid = app.auction.bids.find(bid => bid.supplier.internalName === supplierInternalName)
  }

  const handleToastClose = () => {
    ns.setShared("vehicleSecurityModelfullError", false)
    setLicensePlateError(false)
  }

  return (
    <FzJumbotron>
      <FzCardGroup id="EditApplication">
        <FzRow>
          {buttons && buttons}
          {!ns.isEditing() && (app.applicationState <= APPLICATION_STATE.Lead || app.applicationState <= APPLICATION_STATE.Accept && editableFields?.length) ? (
            <FzCol span={6}>
              <ButtonGroup>
                <Button
                  variant="primary"
                  disabled={working}
                  onClick={() => {
                    if (app.applicationState <= APPLICATION_STATE.Lead) {
                      startEditing(app, null);
                    } else {
                      setEditingConfirmationSteps(true);
                      startEditing(app, editableFields);
                    }
                  }}
                >
                  Editar
                </Button>
              </ButtonGroup>
            </FzCol>
          ) : null}
        </FzRow>
        {workingElement}
        {alert}
        <FzRow>
          <FzCol span={8}>

            {navbar}
            <div>
              {hasSupplierSelected &&
                bid &&
                bid.fieldsOverridesLastUpdated &&
                bid.fieldsOverridesUserName ?
                `Última alteração: ${new Intl.DateTimeFormat('pt-BR', {
                  dateStyle: 'short',
                  timeStyle: 'short'
                }).format(new Date(bid.fieldsOverridesLastUpdated))} - ${bid.fieldsOverridesUserName}` : null}
            </div>

            <ApplicationSection ns={ns} data={app} metas={metasPersonalData} title="Dados Pessoais" supplierInternalName={hasSupplierSelected ? supplierInternalName : null} />
            <AuctionInfo ns={ns} app={app} api={api} showBidsPanel={true} showPartnersData={true} history={history} />
            <ApplicationSection ns={ns} data={app} metas={operationDetails} title={`Detalhes da Operação :   ${objectProduct[0] ? objectProduct[0].description : "Carregando ..."}`} supplierInternalName={hasSupplierSelected} />
            <SimulationData ns={ns} data={app} />
            {security} 
            <ApplicationSection ns={ns} data={app} metas={residenceData} title="Dados da Residência" supplierInternalName={hasSupplierSelected ? supplierInternalName : null} />
            <ApplicationSection ns={ns} data={app} metas={workData} title="Dados de Trabalho" supplierInternalName={hasSupplierSelected ? supplierInternalName : null} />
            <ApplicationSection ns={ns} data={app} metas={bankInformation} title="Dados Bancários" supplierInternalName={hasSupplierSelected ? supplierInternalName : null} />
            <ApplicationSection ns={ns} data={app} metas={score} title="Score" supplierInternalName={hasSupplierSelected ? supplierInternalName : null} />

          </FzCol>
          <FzCol span={4}>
            <SystemInfo application={app} api={api} />
            <PaidApplication application={app} history={history} />
            <Score application={app} />

            <SalesApplicationActions
              disabled={working}
              app={app} api={api}
              ns={ns}
              hideButtons={{
                editPhone: true,
                couldntTalk: true,
                postpone: true
              }}
              validateStartAuction={
                validateStartAuction
              }
              history={history}
            />
            <CustomerAttendanceHermes
              ns={ns}
              app={app}
              history={history}
              onNeedUpdate={onNeedUpdate}
              basePath={category === "activeHermes" ? "/hermes/tickets" : category === "receptive" ? "/sales/in" : "/sales/manual"}
              category={category}
              ticketProp={ticketApp}
            />
            <CustomerAttendance 
              ns={ns} 
              app={app} 
              history={history} 
              onApplicationUpdated={onNeedUpdate} 
              readOnly={displayCustomerAttendance} 
              category={category}
            />
            <AuditLog auditLog={auditlog} />
          </FzCol>
        </FzRow>
      </FzCardGroup>
      <FzToast 
        {...toastInitialConfig}
        {...(licensePlateError ? toastLicensePlateMessage : {})}
        close={handleToastClose} 
        show={ns.getShared("vehicleSecurityModelfullError", false) || licensePlateError}
      />
    </FzJumbotron>
  );
}
