import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import '../../fz/style/menu.css'
import { routes as SCREENING_ROUTES, routesMeta as SCREENING_META } from '../../pages/screening/screening-index';


export class FzMenuScreener extends React.Component<any, any>   {

  constructor(props) {
    super(props)

    this.state = {
      screening: false,
      route_funnel: false,
      route_teste: false,
      route_selected: false
    }

    this.goToRoute = this.goToRoute.bind(this)
    this.showOptionsMenu = this.showOptionsMenu.bind(this)
  }

  goToRoute(route, name) {
    const { history } = this.props
    this.setState({ route_selected: name }, () => history.push(`/${route}`))

  }

  showOptionsMenu(name) {
    if (this.state.show_options !== name) {
      this.setState({ show_options: name })
    } else if (this.state.show_options === name) {
      this.setState({ show_options: undefined })
    }

  };

  render() {

    return (
      <div>

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem button onClick={() => this.showOptionsMenu("screening")}>
            <ListItemText className={"item-menu"} primary="Triagem" />
            {this.state.show_options === "screening" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={this.state.show_options === "screening"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <ListItem button onClick={(e) => this.goToRoute("screening/" + SCREENING_ROUTES.QUEUE, SCREENING_ROUTES.QUEUE)}
                className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === SCREENING_ROUTES.QUEUE,
                })}>
                <ListItemText className={"sub-item-menu"} primary={SCREENING_META[SCREENING_ROUTES.QUEUE].menuItemName} />
              </ListItem>

              <ListItem button onClick={(e) => this.goToRoute("screening/" + SCREENING_ROUTES.CUSTOMER, SCREENING_ROUTES.CUSTOMER)}
                className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === SCREENING_ROUTES.CUSTOMER,
                })}>
                <ListItemText className={"sub-item-menu"} primary={SCREENING_META[SCREENING_ROUTES.CUSTOMER].menuItemName} />
              </ListItem>

              <ListItem button onClick={(e) => this.goToRoute("screening/" + SCREENING_ROUTES.INTERNAL_PENDENCY, SCREENING_ROUTES.INTERNAL_PENDENCY)}
                className={clsx("fz-list-item", {
                  ["fz-route-selected"]: this.state.route_selected === SCREENING_ROUTES.INTERNAL_PENDENCY,
                })}>
                <ListItemText className={"sub-item-menu"} primary={SCREENING_META[SCREENING_ROUTES.INTERNAL_PENDENCY].menuItemName} />
              </ListItem>

            </List>
          </Collapse>

          <ListItem button onClick={() => this.showOptionsMenu('stats')}>
            <ListItemText className={'item-menu'} primary="Estatísticas" />
            {this.state.show_options === 'stats' ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          
          <Collapse
            in={this.state.show_options === 'stats'}
            timeout="auto"
            unmountOnExit
          >

            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() =>
                  this.goToRoute('stats/screening/screening?period=day', 'route_screening')
                }
                className={clsx('fz-list-item', {
                  ['fz-route-selected']:
                    this.state.route_selected === 'route_screening',
                })}
              >
                <ListItemText className={'sub-item-menu'} primary="Triagem" />
              </ListItem>
            </List>

          </Collapse>

          <ListItem button onClick={() => this.goToRoute("about", "route_about")} className={clsx("", {
            ["fz-route-selected"]: this.state.route_selected === "route_about"
          })}>
            <ListItemText className={"item-menu"} primary="Sobre" />
          </ListItem>
        </List>
      </div>


    )
  }



}
