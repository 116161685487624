import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Application } from '../../../biz/metadatas/application'
import { FzCard } from '../../fz/layout/index'
import { FzTable } from '../../fz/grid/table';
import { FzIcons } from '../../fz/form/icon';
import { getApplicationsWithBids } from '../../../api/suppliers';
import { FilterProductsSupplierPage } from '../../blocks/supplier/product-filters';

export class ApplicationsWithBids extends Component {

  constructor(props) {
    super(props)

    this.showDetails = this.showDetails.bind(this)
    this.idColumn = this.idColumn.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { checked: "*" }

  }

  handleChange(value) {
    this.setState({ checked: value, product: value }, () => this.getData())
  }

  showDetails(application) {
    const { history } = this.props
    history.push("/suppliers/" + application.id);
  }

  idColumn(cell, row){
    return (<Button title={row['id']} bsSize="xsmall" onClick={() => this.showDetails(row)}>< FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Ver
    </Button>);
  }

  getData() {
    const { ns } = this.props

    let product = this.state.product || this.state.checked
    return getApplicationsWithBids(1, product)
      .then((resultData) => {
        ns.set("applications-bids", resultData)
      })
      .catch(err => {
        console.error(err)
      })
  }


  componentDidMount() {
    this.getData()
  }

  render() {

    const { ns } = this.props
    const applications_bids = ns.get("applications-bids")


    return (
      <FzCard>
        <FilterProductsSupplierPage checked={this.state.checked} onChange={this.handleChange} />

        <FzCard.Heading>
          <FzCard.Title>Propostas Aceitas</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={this.props.applications || applications_bids}
            ns={this.props.ns}
            metas={Application.fields}
            visibleColumns={["id",  "product", "customer", "personalNumber", "city", "appliedAmount", "auction.endDate"]}
            clipboard={false}
            exportDownload={false}
            customColumnFormatters={{ id: (cell, bid, formatExtraData, idx) => this.idColumn(cell, bid, formatExtraData, idx) }}
          />
        </FzCard.Body>
      </FzCard>
    );
  }
}
