import React from 'react'
import { ReactElement, ReactNode, useState } from 'react';
import copy from "copy-to-clipboard";
import { Badge, Modal } from 'react-bootstrap';

import api from '../../../api/client-api'
import { FzButton } from "../../../ui/fz/form";
import { FzIcons } from "../../../ui/fz/form/icon";
import { FzBadge, FzCol, FzRow } from "../../../ui/fz/layout";
import { FzTable } from '../../../ui/fz/grid/table';
import { bidsAnalytic } from '../../../biz/metadatas/stats-bids';
import { FzAlert } from '../../../ui/fz/form/alert';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzModal } from '../../../ui/fz/fz-modal';
import { amountColumn, pctColumn } from '../../../components/table-standard';
import { acceptBid, reSendEmailBid } from '../../../api/applications';
import { RefuseBid, BidDetails } from '../../../ui/blocks/auctions-bids';
import { firstBy } from './helper'
import { Namespace } from '../../../scripts/state/redux_ns';
import { Application } from '../../../types/application';
import { Bids } from '../../../types/Bids';
import { FzToast } from '../../../ui/fz/form/notification/toast';
import { FzSelect } from '../../../ui/fz/form/old-simple-input';
import { CustomSimulationModal } from '../../../ui/blocks/application-components/custom-simulation';

const AUCTION_ACTION_RESEND_EMAIL = 'resend-email';
const AUCTION_ACTION_ACCEPT_BID = 'accept-bid';

const SORT_DIRECTION = {
  ASC: true,
  DESC: false
}

interface BidsPanelProps {
  ns: Namespace,
  application: Application
}

interface sortOffersByResponse {
  orderedBids: Bids[]
}

export function AcceptBidModal({ onHide, confirmationStepModal, acceptBid }) {
  return (
    <>
      {confirmationStepModal !== null ? (
        <Modal
          style={{ marginTop: '50px' }}
          bsSize="large"
          show={confirmationStepModal !== null}
          onHide={onHide}
          dialogClassName="modal-form"
          keyboard={true}
        >
          <Modal.Body>
            <FzAlert fzStyle="attention">
              <strong>Atenção:</strong> essa proposta possui etapas de
              confirmação pendentes. Por favor, só aceite se todos os dados
              estiverem preenchidos
            </FzAlert>
          </Modal.Body>
          <Modal.Footer>
            <FzButton fzStyle="alert" onClick={onHide}>
              Cancelar
            </FzButton>
            <FzButton
              fzStyle="regular"
              onClick={() => acceptBid(confirmationStepModal)}
            >
              Confirmar Aceite
            </FzButton>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}

export function BidsPanel({ application, ns }: BidsPanelProps): ReactElement {

  const [showRefuseBidModal, setShowRefuseBidModal] = useState(false)
  const [confirmationStepModal, setConfirmationStepModal] = useState<null|Bids>(null)
  const [acceptError, setAcceptError] = useState<{show: boolean; msg: string}>({show: false, msg: ""})
  const [typeView, setTypeView] = useState("cof")

  const [primarySorter, setPrimarySorter] = useState({
    code: "monthlyInstallment",
    direction: SORT_DIRECTION.ASC
  })

  const [secondarySorter, setSecondarySorter] = useState({
    code: "approvedPeriodMonths",
    direction: SORT_DIRECTION.DESC
  })

  const [tertiarySorter, setTertiarySorter] = useState({
    code: "created",
    direction: SORT_DIRECTION.ASC
  })

  const [sorters, setSorters] = useState([
    { description: "Selecione", code: "none", direction: SORT_DIRECTION.ASC },
    { description: "Maior Valor", code: "approvedAmount", direction: SORT_DIRECTION.DESC },
    { description: "Menor Parcela", code: "monthlyInstallment", direction: SORT_DIRECTION.ASC },
    { description: "Menor Juros", code: "interestRate", direction: SORT_DIRECTION.ASC },
    { description: "Maior Prazo", code: "approvedPeriodMonths", direction: SORT_DIRECTION.DESC },
    { description: "Limpar Ordenação", code: "clear", direction: SORT_DIRECTION.ASC }
  ])




  function setSortState(name) {
    const sorter = sorters.find(sorter => sorter.code === name)
    if (!sorter) {
      return
    }
    const order = { code: sorter.code, direction: sorter.direction }
    setPrimarySorter(order)
  }

  function bidStatus(cell, bid, formatExtraData, idx, application) {


    if (!bid) {
      return <div>******* ERROR ********</div>
    }

    let bidStatusBar: ReactNode[] = [];
    let supplierRejected = application.supplierData && application.supplierData[bid.supplier.internalName] && application.supplierData[bid.supplier.internalName].rejected;

    if (application.paid && application.paid.supplierInternalName === bid.supplier.internalName) {
      bidStatusBar.push(<FzBadge key={0} fzStyle="success" >Pago</FzBadge>);
      return <div>{bidStatusBar}</div>;
    }

    if (bid.accepted) {
      bidStatusBar.push(<FzBadge className='mr-1' key={1} fzStyle="success" >Aceito</FzBadge>)
    }

    if (supplierRejected) {
      bidStatusBar.push(<FzBadge className='mr-1' key={2} fzStyle="alert" >Cancelado</FzBadge>);
      return <div>{bidStatusBar}</div>;
    }

    if (bid.refused) {
      bidStatusBar.push(<FzBadge className='mr-1' key={3} fzStyle="attention" >Recusado</FzBadge>)
    }

    if (bid.expired) {
      bidStatusBar.push(<FzBadge className='mr-1' key={4} fzStyle="alert" >Expirado</FzBadge>)
    }

    if (bid.canUpdateBid) {
      bidStatusBar.push(<FzBadge className='mr-1' key={4} fzStyle="success" >Editar oferta</FzBadge>)
    }

    if (bid.confirmationUrl && bid.accepted) {
      // Important! This compenent (CopyToClipboard) requires One (and only one) child to properlly work
      bidStatusBar.push(
        <FzButton
          className='mr-1'
          tip='Copiar link'
          size="sm"
          fzStyle='discrete'
          onClick={() => copy(bid.confirmationUrl, { format: "text/plain" })}
        >
          <FzIcons icon={"copy"} fzStyle={{ width: "15px", height: "15px" }} />
        </FzButton>
      )
    }

    if (bid.accepted) {
      bidStatusBar.push(
        <FzButton
          className='mr-1'
          tip='Reenviar Email'
          key={bid.id}
          size="sm"
          fzStyle='discrete'
          onClick={() => fnResendEmail(bid)}
        >
          <FzIcons icon={"envelope"} fzStyle={{ width: "15px", height: "15px" }} />
        </FzButton>
      )
    }

    if (api.isUserInRole("advisor")) {
      if (!bid.rejected && !bid.accepted && (!bid.alternateOffers || bid.alternateOffers.length === 0)) {
        bidStatusBar.push(
          <FzButton
            key={5}
            fzStyle="success"
            size="sm"
            disabled={ns.isEditing()}
            onClick={() => {
              if(application.supplierData[bid.supplier.internalName].confirmationSteps.length && application.supplierData[bid.supplier.internalName].confirmationSteps.find(cs => !cs.completed)) { 
                setConfirmationStepModal(bid);
              } else {
                fnAcceptBid(bid)
              }
            }}>
            Aceitar esta oferta
          </FzButton>
        )
      }

      if (bid.alternateOffers && bid.alternateOffers.length > 0) {
        bidStatusBar.push(
          <FzBadge key={6} fzStyle="success" >
            <Badge>{bid.alternateOffers.length}</Badge> ofertas alternativas
          </FzBadge>
        )
      }
    }

    if(application?.supplierData[bid.supplier.internalName]?.confirmationSteps?.length && application.supplierData[bid.supplier.internalName].confirmationSteps.find(cs => !cs.completed) ) {
      const incompleteSteps = application.supplierData[bid.supplier.internalName].confirmationSteps.filter(cs => !cs.completed).length;
      bidStatusBar.push(
        <FzBadge key={6} fzStyle="alert" >
          <Badge>{incompleteSteps }</Badge> confirmationsStep{incompleteSteps > 1 ? "s" : ""} incompleto{incompleteSteps > 1 ? "s" : ""}
        </FzBadge>
      )
    }

    if (api.isUserInRole("advisor") && !bid.accepted) {
      bidStatusBar.push(
        <FzButton
          key={7}
          fzStyle="attention"
          size="sm"
          disabled={ns.isEditing()}
          onClick={() => handleShowRefuseBidModal(bid)}>
          Não interessado
        </FzButton>
      );
      bidStatusBar.push(
        <RefuseBid
          key={8}
          show={showRefuseBidModal}
          closeButton={handleCloseRefuseBidModal}
          ns={ns}
          app={application}
          bid={bid} />
      )
    }

    return (<div style={{display: "flex", flexWrap: "wrap", gap: "4px"}}> {bidStatusBar} </div>)
  }

  function fnAcceptBid(bid) {
    setConfirmationStepModal(null)
    acceptBid(application.id, bid.id)
      .then((newapp) => {
        ns.set('application', newapp)

        let treatment = ns.get("crm.treatments." + application.id) ? ns.get("crm.treatments." + application.id) + " * Bid aceito" : "Bid aceito";
        let treatmentArray = ns.get("crm.treatmentsArray." + application.id) ? ns.get("crm.treatmentsArray." + application.id) : [];
        treatmentArray.push(AUCTION_ACTION_ACCEPT_BID);
        ns.set("crm.treatments." + application.id, treatment);
        ns.set("crm.treatmentsArray." + application.id, treatmentArray);
      })
      .catch((error) => {
        setAcceptError({
          show: true, 
          msg: error?.includes("application has incomplete confirmationSteps") ? 
            "Application possui confirmtionSteps pendentes de preenchimento" : 
            "Erro ao salvar application"
        })
        console.warn("Error in acceptBid", error) 
      })
  }

  function handleId(cell, bid, formatExtraData, idx, application) {
    return (

      <ModalDetailsBid bid={bid} />

    )
  }

  function fnResendEmail(bid) {
    reSendEmailBid(application.id, bid.id)
      .then((newapp) => {
        let treatment = ns.get("crm.treatments." + application.id) ? ns.get("crm.treatments." + application.id) + " * E-mail de aceite reenviado" : "E-mail de aceite reenviado";
        let treatmentArray = ns.get("crm.treatmentsArray." + application.id) ? ns.get("crm.treatmentsArray." + application.id) : [];
        treatmentArray.push(AUCTION_ACTION_RESEND_EMAIL);

        ns.set("crm.treatments." + application.id, treatment);
        ns.set("crm.treatmentsArray." + application.id, treatmentArray);

      })
      .catch((error) => {
        console.warn("Error in reSendEmailBid", error)
      })
  }

  function handleCloseRefuseBidModal() {
    setShowRefuseBidModal(false)
  }

  function handleShowRefuseBidModal(bid) {
    ns.set('bid', bid);
    setShowRefuseBidModal(true)
  }

  function expandComponent(ns, application, bid) {
    return (< BidDetails ns={ns} app={application} bid={bid} />)
  }

  function _parseCreatedDate(field, a, b) {
    if (field.code === 'created') {
      a[field.code] = new Date(a[field.code]).getTime();
      b[field.code] = new Date(b[field.code]).getTime();
    }
  }

  function floatAcceptedToTop(a, b) {
    if (a.accepted && !b.accepted) return -1;
    if (!a.accepted && b.accepted) return 1;
    if (a.accepted && b.accepted) {
      if (a.formalization && !b.formalization) return -1;
      if (!a.formalization && b.formalization) return 1;
      const aFormalization = a.formalization && a.formalization.formalizationState || 0;
      const bFormalization = b.formalization && b.formalization.formalizationState || 0;
      return bFormalization - aFormalization;
    }
  }

  function floatHighlightToTop(a, b) {
    if (a.highlighted && !b.highlighted) return -1;
    if (!a.highlighted && b.highlighted) return 1;
    if (a.highlighted && b.highlighted) {
      if (a.formalization && !b.formalization) return -1;
      if (!a.formalization && b.formalization) return 1;
      const aFormalization = a.formalization && a.formalization.formalizationState || 0;
      const bFormalization = b.formalization && b.formalization.formalizationState || 0;
      return bFormalization - aFormalization;
    }
  }

  function addLabels(bids) {
    const allAltOffers: any = [].concat(...bids.map((bid) => {
      bid.alternateOffers.forEach(altOffer => {
        altOffer.labels = [];
        altOffer.id = bid.id;
        altOffer.supplier = bid.supplier;
      });
      return bid.alternateOffers;
    }));

    const pushLabel = (offer, label) => {
      if (offer) {
        offer.labels.push(label);
      }
    };

    pushLabel(allAltOffers.filter(it => !it.refused).find(it => it.supplier.internalName === 'cbss'), {
      text: 'mais rápida',
      place: "floating",
      value: 1.5,
    });

    pushLabel(allAltOffers.filter(it => !it.refused)
      .sort((a, b) => a.totalAnnualInterestRate - b.totalAnnualInterestRate)
      .sort((a, b) => b.approvedAmount - a.approvedAmount)[0], {
      text: 'maior valor aprovado',
      place: 'appliedAmount',
      value: 1,
    });

    pushLabel(allAltOffers.filter(it => !it.refused)
      .sort((a, b) => a.totalAnnualInterestRate - b.totalAnnualInterestRate)
      .sort((a, b) => b.approvedPeriodMonths - a.approvedPeriodMonths)[0], {
      text: 'pague tranquilo',
      place: 'installment',
      value: 1,
    });

    pushLabel(allAltOffers.filter(it => !it.refused)
      .sort((a, b) => a.totalAnnualInterestRate - b.totalAnnualInterestRate)
      .sort((a, b) => a.monthlyInstallment - b.monthlyInstallment)[0], {
      text: 'menor parcela',
      place: 'installment',
      value: 1,
    });

    return bids;
  }

  function sortOffersBy(
    bidList,
    primaryField,
    secondaryField,
    tertiaryField): sortOffersByResponse {

    let sortedBidList = bidList;
    if (primaryField.code != "none") {

      const _sortingFn = firstBy(
        (a, b) => {
          _parseCreatedDate(primaryField, a, b);
          return primaryField.direction ? Math.sign(a[primaryField.code] - b[primaryField.code]) : Math.sign(b[primaryField.code] - a[primaryField.code])
        }
      ).thenBy(
        (a, b) => {
          if (secondaryField.code === primaryField.code) return 0;

          _parseCreatedDate(secondaryField, a, b);
          return secondaryField.direction ? Math.sign(a[secondaryField.code] - b[secondaryField.code]) : Math.sign(b[secondaryField.code] - a[secondaryField.code]);
        }
      ).thenBy(
        (a, b) => {
          if (tertiaryField.code === primaryField.code || tertiaryField.code === secondaryField.code) return 0;

          _parseCreatedDate(tertiaryField, a, b);
          return tertiaryField.direction ? Math.sign(a[tertiaryField.code] - b[tertiaryField.code]) : Math.sign(b[tertiaryField.code] - a[tertiaryField.code]);
        }
      );

      const sortAlternateOffers = bidList.map(bid => {
        const { accepted, refused, rejected, expired, alternateOffers } = bid;
        const preservedProps = { accepted, refused, rejected, expired, alternateOffers };

        if (accepted) return { ...bid };

        bid.alternateOffers.sort(_sortingFn);

        return { ...bid, ...bid.alternateOffers[0], ...preservedProps }        
      });

      const sortedOfferCards = sortAlternateOffers.sort(_sortingFn);

      sortedBidList = sortedOfferCards;
    }

    const acceptedToTop = sortedBidList.sort((a, b) => floatAcceptedToTop(a, b));
    const orderedBids = acceptedToTop.sort((a, b) => floatHighlightToTop(a, b));

    return orderedBids
  }

  function handleChangeType(event) {

    if (!event) {
      return (
        <FzToast
          fzStyle={"danger"}
          show={true}
          delay={5000}
          headerMessageTost={'Alerta'}
          bodyMessageTost={'Erro ao trocar de visualização'}
          autoHide={true}
        />
      )
    }
    const page = event.target.value === "cof" ? "parati" : "cof"
    setTypeView(page)
  }

  let bids: Bids[] = []
  if (application && application.auction && application.auction.bids) {
    bids = application.auction.bids
  }

  let columnVisible = application.product === "HE" ?
    ["id", "supplier.name", "approvedAmount", "monthlyInstallment", "firstInstallment", "lastInstallment", "totalValueInstallments", "approvedPeriodMonths", "paymentMethod", "accepted"] :
    ["id", "supplier.name", "approvedAmount", "monthlyInstallment", "approvedPeriodMonths", "interestRate", "paymentMethod", "accepted"]

  const orderedBids = sortOffersBy(
    bids.filter(bid => bid.rejected  !== true && bid.alternateOffers),
    primarySorter,
    secondarySorter,
    tertiarySorter
  )

  const bidsWithLabels = addLabels(orderedBids)

  const dataForTypeViewParati = bidsWithLabels
    .map(({ alternateOffers, supplier }) => alternateOffers.map((alt, index) => ({ ...alt, supplier, countalternateOffers: alternateOffers[index] === 0 })))
    .reduce((currentValue, prevValue) => ([...currentValue, ...prevValue]), [])
    .reduce((acc, current, index) => {

      const supplier = current.supplier.internalName
      const count = acc.findIndex((element) => element.supplier.internalName === supplier ) 
      
      if (current.labels.length > 0 || (acc.length > 0 && current.labels.length === 0  && count === -1)) {
        return [...acc, current]
      }

      return [...acc]
    }, [])

  const pages = {
    "parati": (
      <FzTable
        ns={ns}
        data={dataForTypeViewParati}
        metas={bidsAnalytic}
        visibleColumns={["id",
          "supplier.name",
          "approvedAmount",
          "monthlyInstallment",
          "approvedPeriodMonths",
          "interestRate",
          "paymentMethod",
        ]}
        customColumnFormatters={{
          id: (cell, bid, formatExtraData, idx) => handleId(cell, bid, formatExtraData, idx, application),
        }}
        exportDownload={false}
        clipboard={false}
      />
    ),
    "cof": (
      <FzTable
        ns={ns}
        data={application.auction ? application.auction?.bids : []}
        metas={bidsAnalytic}
        visibleColumns={columnVisible}
        rowExpand={{
          expandComponent: (bid) => expandComponent(ns, application, bid),
          expandableRow: () => !api.isUserInRole("franchisee")
        }}
        customColumnFormatters={{
          accepted: (cell, bid, formatExtraData, idx) => bidStatus(cell, bid, formatExtraData, idx, application),
          id: (cell, bid, formatExtraData, idx) => handleId(cell, bid, formatExtraData, idx, application),
        }}
        exportDownload={false}
        clipboard={false}
      />
    )
  }


  return (
    <div>
      <FzCard>
        <FzCard.Header>
          <FzCard.Title as="h3">Propostas</FzCard.Title>
        </FzCard.Header>
        <FzCard.Body>

          <FzRow>
            <FzCol>
              <FzButton
                fzStyle="outline-secondary"
                value={typeView}
                onClick={handleChangeType}
              >
                {`Visualizar ${typeView === "cof" ? "Parati" : "COF"}`}
              </FzButton>
              <CustomSimulationModal ns={ns} application={application}/>
            </FzCol >
            <FzCol>
              {typeView === "parati" && (
                <FzSelect
                  name="Reasons"
                  readOnly={false}
                  value={null}
                  onChange={setSortState}
                  options={[
                    { description: "Maior Valor", code: "approvedAmount", direction: SORT_DIRECTION.DESC },
                    { description: "Menor Parcela", code: "monthlyInstallment", direction: SORT_DIRECTION.ASC },
                    { description: "Menor Juros", code: "interestRate", direction: SORT_DIRECTION.ASC },
                    { description: "Maior Prazo", code: "approvedPeriodMonths", direction: SORT_DIRECTION.DESC },
                    { description: "Limpar Ordenação", code: "clear", direction: SORT_DIRECTION.ASC }
                  ]}
                />)}
            </FzCol >
          </FzRow >

          {bids.length > 0 ? (
            pages[typeView]
          ) : (
            <FzAlert fzStyle="attention">Nenhuma oferta ainda ...</FzAlert>
          )}
        </FzCard.Body>
      </FzCard>
      <AcceptBidModal confirmationStepModal={confirmationStepModal} onHide={() => setConfirmationStepModal(null)} acceptBid={fnAcceptBid}/>
      {acceptError?.show && <FzToast
        fzStyle={"danger"}
        show={true}
        delay={5000}
        headerMessageTost={'Alerta'}
        bodyMessageTost={acceptError.msg}
        autoHide={true}
        close={() => setAcceptError({show: false, msg: ""})}
      />}
    </div >
  )
}


function ModalDetailsBid({ bid }): ReactElement {
  return (
    <FzModal
      showButtonText='Ver Detalhes'
      headerTitle={`Detalhes da oferta ${bid && bid.supplier.name}`}
    >
      <>
        <FzRow>
          <FzCol span={4}>
            <strong>Total Parcelas</strong>
            <div>{amountColumn(bid.totalValueInstallments)}</div>
          </FzCol>

          <FzCol span={4}>
            <strong>1ª Parcela</strong>
            <div>{amountColumn(bid.firstInstallment)}</div>
          </FzCol>

          <FzCol span={4}>
            <strong>Últ. Parcela</strong>
            <div>{amountColumn(bid.lastInstallment)}</div>
          </FzCol>
        </FzRow>

        <FzRow>
          <FzCol>
            <strong>Juros</strong>
            <div>{pctColumn(bid.interestRate)}</div>
          </FzCol>
          <FzCol>

            <strong>C.E.T</strong>
            <div>{pctColumn(bid.totalAnnualInterestRate)}</div>
          </FzCol>
        </FzRow>

        <FzRow>
          <FzCol>
            <strong>Loja</strong>
            <div>{bid.supplier.branchCode}</div>
          </FzCol>
        </FzRow>
      </>
    </FzModal>
  )
}