import React from "react";
import { FzToast } from "../ui/fz/form/notification/toast";

export const ToastContext = React.createContext<any>(null);

export const ToastProvider = ({ children }) => {

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [header, setHeader] = React.useState<string>('defaultHeader')
  const [body, setBody] = React.useState<string>('defaultBody')
  const [level, setLevel] = React.useState<'warning' | 'danger' | 'success'>()
  const [delay, setDelay] = React.useState<number>(5000)
  const [autoHide, setAutoHide] = React.useState<string>('true')

  return <ToastContext.Provider value={{
    isOpen, setIsOpen,
    header, setHeader,
    body, setBody,
    level, setLevel,
    delay, setDelay,
    autoHide, setAutoHide
  }}>
    {children}
    <FzToast />
  </ToastContext.Provider>
}