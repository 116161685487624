import React, { useEffect, useState } from 'react'
import api from '../../../api/client-api'
import { FzCard, FzAccordion } from '../../fz/layout/index'
import { FzTable } from '../../fz/grid/table'
import { FzButton } from '../../fz/form'
import { Modal } from 'react-bootstrap'
import { filterDomainDescription } from '../../../utils/filterDomainDescription'

const meta = {
  "documentType": {
    "path": "documentType",
    "label": "Tipo de documento",
    "tableProps": {
      "width": "1rem",
      "dataSort": true,
      "isKey": true,
    },
    "isMandatory": (application) => false,
  },
  "state": {
    "path": "state",
    "label": "Status",
    "tableProps": {
      "width": "1rem",
    },
    "isMandatory": (application) => false,
  },
  "pendencies": {
    "path": "pendencies",
    "label": "Pendencias",
    "tableProps": {
      "width": "0.5rem",
    },
    "isMandatory": (application) => false,
  }
}

const stateDescriptions = {
  'not-initialized': 'NÃO INICIADO',
  'rejected': 'REJEITADO',
  'validated': 'VALIDADO',
  'pending': 'PENDENCIADO',
}

export const outcomesDescriptions = {
  "success": "APROVADO",
  "docs-pending": "PENDENCIADO",
  "rejected": "REJEITADO"
}

export const nameDescriptions = {
  "screening": "Triagem",
  "integration": "Integração",
  "telephone-validation": "Validação de Telefone",
  "unico": "Único",
  "hs": "Antifraude | HS",
  "partner-telephone-validation": "Validação de Telefone - Parceiro",
}

interface ValidationStepType {
  name: string;
  sent: string;
  outcome: string;
  documentsOutcomes: documentsOutcomesType[];
}

interface documentsOutcomesType {
  documentType: string;
  pendencies: string[];
  state: string;
}

interface ShowValidationStepProps {
  name: string;
  sent: string;
  outcome: string;
  documentsOutcomes: documentsOutcomesType[];
  ns: any;
}



export function ShowValidationStep({ name, sent, outcome, documentsOutcomes, ns }: ShowValidationStepProps) {
  const [documentDomains, setDocumentDomains] = useState([])
  const [openModalPendencies, setOpenModalPendencies] = useState(false)
  const [pendencies, setPendencies] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadDomains()
  }, [])

  async function loadDomains() {
    let documentDomains = await api.domains.getDomainValues("formalization-documents")

    documentDomains = filterDomainDescription(documentDomains)

    setDocumentDomains(documentDomains)
    setIsLoading(false)
  }

  function handleShowPendencies(cell, bid, formatExtraData, idx) {
    if (!cell || !Array.isArray(cell) || !bid) {
      return null
    }
    return (
      bid !== 'validated' && cell.filter(it => it).length > 0 ? (
        <FzButton
          size='sm'
          onClick={() => {
            setOpenModalPendencies(true)
            setPendencies(cell)
          }}
          tip='ver motivos de pendencias'
        >
          Ver
        </FzButton>
      ) : null
    )
  }

  function handleSetModal() {
    setOpenModalPendencies(!openModalPendencies)
  }

  function getDocsDescriptions(cell, row, formatExtraData, idx) {
    return (docsDescriptions[cell] || cell)
  }

  function getStateDescriptions(cell, row, formatExtraData, idx) {
    return (outcomesDescriptions[cell] || cell)
  }

  let docsDescriptions = documentDomains.reduce((resultSoFar, data: { code: string, description: string }) => {
    return resultSoFar = { ...resultSoFar, [data.code]: data.description }
  }, {})

  const visibleColumnsForEachResult = outcome !== 'success' ?
    ["documentType", "state", "pendencies"] :
    ["documentType", "state"]

  const rulesTranslation = ns.getShared("CACHE.rules-translation")

  return (
    <div className='p-3'>
      <FzAccordion>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzAccordion.Toggle FzElementType={"h5"} fzStyle={{ cursor: 'pointer' }} eventKey={name}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <strong className="mr-3" >
                  {name ? nameDescriptions[name] : "Nome não definido"}
                </strong>

                {sent && (
                  <div className="text-secondary mr-3">
                    Data de envio: {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(sent))}
                  </div>)
                }

                <div style={{ textTransform: "capitalize" }} className="text-secondary mr-3" >
                  {outcome ? outcomesDescriptions[outcome] : " NÃO INICIADO"}
                </div>
              </div>

            </FzAccordion.Toggle>
          </FzCard.Heading>

          <FzAccordion.Collapse eventKey={name} className=''>
            <FzCard.Body>
              <FzTable
                ns={ns}
                data={documentsOutcomes}
                metas={meta}
                visibleColumns={visibleColumnsForEachResult}
                customColumnFormatters={{
                  documentType: (cell, row, formatExtraData, idx) => getDocsDescriptions(cell, row, formatExtraData, idx),
                  state: (cell, row, formatExtraData, idx) => getStateDescriptions(cell, row, formatExtraData, idx),
                  pendencies: (cell, bid, formatExtraData, idx) => handleShowPendencies(cell, bid, formatExtraData, idx),
                }}
                exportDownload={false}
                clipboard={false}
              />
            </FzCard.Body>
          </FzAccordion.Collapse>
        </FzCard>
      </FzAccordion>

      <Modal
        size="lg"
        centered
        show={openModalPendencies}
        onHide={() => handleSetModal()}
      >
        <Modal.Header>
          <Modal.Title>Motivo(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && pendencies && pendencies.map(it => {
           const pendencyText = name === "screening" && rulesTranslation ? rulesTranslation[it] || it + " (rule not found)" : it
            return (
              <div>
                {pendencyText}
              </div>
            )
          })}
        </Modal.Body>
      </Modal>

    </div>
  )
}