import api from '../api/client-api';

// A função espera um número no formato 119XXXXXXXX C
export const dialTelephone = async (number) => {
  const user = api.getCurrentUser();

  const { telephoneNumber, name } = user;

  const messageErrorRamal = `Erro ao ligar para o usuário. \n
  Solicitação realizada pelo usuário ${name}, ramal ${telephoneNumber} para o telefone ${number}. \n
  Verifique se seu número é um ramal válido.`;

  const messageErrorUserNumber = `Erro ao ligar para o usuário. \n
  Solicitação realizada pelo usuário ${name}, ramal ${telephoneNumber} para o telefone ${number}. \n
  Verifique se o número ao qual está tentanto ligar possui um formato válido. \n
  O formato esperado é xx9XXXXXXXX`;

  const messageErrorDefault = `Erro ao ligar para o usuário.\n
  Ocorreu um erro inesperado, provavelmente não conseguimos identificar todos os dados, tais quais:\n Usuário: ${name}\n Ramal: ${telephoneNumber}\n Telefone: ${number} `

  switch (true) {
    case (telephoneNumber && telephoneNumber.length < 4) ||
      telephoneNumber.length > 4:
      alert(messageErrorRamal);
      break;
    case number && number.length < 9:
      alert(messageErrorUserNumber);
      break;
    case number.length > 11:
      alert(messageErrorUserNumber);
      break;
    case telephoneNumber && number.length > 9 && telephoneNumber.length === 4:
      if (number.substring(0, 2) === '11') {
        number = number.substring(2); // Remove sempre o código de área para SP
      } else {
        number = `0${number}`; // Concatena o 0 na frente de todo número fora do estado, mantendo o DDD
      }

      const timeStamp = new Date().getTime();
      const url = `https://telefone.finanzero.com.br/f0dialer/call.php?exten=${telephoneNumber}&number=${number}&${timeStamp}`;
      const img = document.createElement('img');
      img.id = timeStamp;
      img.src = url;

      window.setTimeout(() => {
        console.log({ origin: telephoneNumber, destiny: number, url });
      }, 2000);
      break;
    default:
      alert(messageErrorDefault);
      break;
  }
};
