import React from 'react';
import { ToggleButtonGroup } from "react-bootstrap";
import { FzToggleButtonGroupProps } from "./types";

export function FzToggleButtonGroup({
  children,
  size = "sm",
  type = "checkbox",
  value,
  onChange,
  name
}: FzToggleButtonGroupProps) {

  return (
    <ToggleButtonGroup
      name={name}
      size={size}
      type={type}
      value={value}
      onChange={onChange}
    >
      {children}
    </ToggleButtonGroup>
  )
}