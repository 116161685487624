import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import UsersTable from './users-table';
import { getUsers } from '../../../api/users';
import { FzCard, FzSplitLine } from '../../fz/layout/index'

class UsersConsole extends Component {
  constructor(props) {
    super(props);
    this.handlePageSelect = this.handlePageSelect.bind(this)
    this.getUsersByRole = this.getUsersByRole.bind(this)
    this.newUser = this.newUser.bind(this)
    this.getRoleDescription = this.getRoleDescription.bind(this)
    this.getRoleFromUrl = this.getRoleFromUrl.bind(this)
    this.state = {}
  }

  getRoleFromUrl () {
    const match = this.props.match
    let role = match.params && match.params.role ? match.params.role : ""
    return role
  }


  getUsersByRole() {
    const role = this.getRoleFromUrl();
    if (role) {
      getUsers(role)
        .then((users) => {
          this.props.ns.set("users", users);
        })
        .catch((err) => this.props.history.push("/"));
    }
  }

  getRoleDescription() {

    const role = this.getRoleFromUrl();

    const mapRole = {
      admin: "Administrador",
      advisor: "Consultor de Vendas",
      sales: "Assistente de Vendas",
      mis: "Mis",
      supplier: "Bancos",
      affiliate: "Afiliado",
      franchisee: "Franqueado",
      screener: "Avaliador da Triagem"
    };

    return mapRole[role] || role
  }

  handlePageSelect(eventKey) {
    this.props.history.push('/admusers/' + eventKey)
  }

  newUser() {
    const role = window.location.href.match(/[a-z]{1,}$/);
    let newPath = this.props.ns.namespace + "/new"
    let newNs = this.props.ns.copy(newPath)
    let newUser = { id: "new", role: role[0] }
    newNs.set("user", newUser)
    newNs.startEditing(newUser)
    this.props.history.push(newPath)
    this.props.ns.unset("users")
  }

  componentDidMount() {
    if (!this.props.ns.get("users")) {
      this.getUsersByRole();
    }
  }


  render() {
    return (
      <FzCard>
        <FzCard.Heading>
          <FzSplitLine>
            <FzSplitLine.Left>
              <FzCard.Title componentClass="h3">Usuários&nbsp;<b>{this.getRoleDescription()}</b></FzCard.Title>
            </FzSplitLine.Left>

            <FzSplitLine.Right>
              <Button bsStyle="success" onClick={this.newUser}>Novo</Button>
            </FzSplitLine.Right>
          </FzSplitLine>
        </FzCard.Heading>
        <FzCard.Body className="show-grid">
          <UsersTable ns={this.props.ns} api={this.props.api} data={this.props.ns.get("users")} role={this.state.role} history={this.props.history} />
        </FzCard.Body>
      </FzCard>
    );
  }
}

export { UsersConsole }