import moment from 'moment';
import { Meta } from '../../../../types/metas';

export const screeningAnalyticsMeta: Meta = {
  "applicationId": {
    "path": "applicationId",
    "label": "Proposta",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "applicationId",
      "shortLabel": undefined,
      "width": "16ch",
      "dataSort": true,
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },

  "created": {
    "path": "created",
    "label": "Entrada na Fila",
    "dataType": "date",
    "tableProps": {
      "viewsTable": "created",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "periodWithTimeColumnTz"
    },
    "isMandatory": (application) => false,
  },

  "analysesStartDate": {
    "path": "analysesStartDate",
    "label": "Inicio da Análise",
    "dataType": "date",
    "tableProps": {
      "viewsTable": "analysesStartDate",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "periodWithTimeColumnTz"
    },
    "isMandatory": (application) => false,
  },

  "state": {
    "path": "state",
    "label": "Status da Análise",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "state",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { type: 'TextFilter', delay: 500 },
    },
    "isMandatory": (application) => false,
  },

  "analysesEndDate": {
    "path": "analysesEndDate",
    "label": "Saida da Fila",
    "dataType": "date",
    "tableProps": {
      "viewsTable": "analysesEndDate",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "periodWithTimeColumnTz"
    },
    "isMandatory": (application) => false,
  },

  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "supplierInternalName",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { type: 'TextFilter', delay: 500 },
    },
    "isMandatory": (application) => false,
  },

  "outcome": {
    "path": "outcome",
    "label": "Resultado da Análise",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "outcome",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { type: 'TextFilter', delay: 500 },
    },
    "isMandatory": (application) => false,
  },

  "screenedCount": {
    "path": "screenedCount",
    "label": "Passagens",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "screenedCount",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },

  "queueTime": {
    "path": "queueTime",
    "label": "Tempo de fila(Em segundos)",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "queueTime",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      'columnFormat': 'formatMinutesSeconds'
    },
    "isMandatory": (application) => false,
  },

  "analysisTime": {
    "path": "analysisTime",
    "label": "Tempo de análise(Em segundos)",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "analysisTime",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      'columnFormat': 'formatMinutesSeconds'
    },
    "isMandatory": (application) => false,
  },

  "completionTime": {
    "path": "completionTime",
    "label": "Tempo total(Em segundos)",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "completionTime",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      'columnFormat': 'formatMinutesSeconds'
    },
    "isMandatory": (application) => false,
  },
  "screenerName": {
    "path": "screenerName",
    "label": "Nome do screener",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "screenerName",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
};


export const panelControlScreeningAnalytics: Meta = {
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "dataType": "string",
    "inputType": "nav-dropdown",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => true,
  },
  "createdAfter": {
    "path": "createdAfter",
    "label": "De:",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
  "createdBefore": {
    "path": "createdBefore",
    "label": "Até:",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
};