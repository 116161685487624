export const APPLICATION_STATE = {
  Init: 1,
  Incomplete: 2,
  Sent: 4,
  Signing: 8,
  Signed: 16,
  Paid: 32,
  Canceled: 0x8000
}

export const statesFormalizationDescriptions = {
  1: "Não iniciado",
  2: "Parcial",
  4: "Enviado para análise",
  6: "Parcialmente recusado",
  8: "Aguardando assinatura",
  16: "Finalizado (assinado)",
  32: "Pago",
  0x8000: "Cancelado"
}


export const getFormalizationStateDescription = (state) => {
  return statesFormalizationDescriptions[state] || 'n/a';
}
