import '@react-awesome-query-builder/mui/css/styles.css';
import { Utils as QbUtils } from '@react-awesome-query-builder/mui';
import QueryBuilder from '../../../ui/blocks/parameters-service/partner/filtersManager/query-builder';
import { MuiConfig } from '@react-awesome-query-builder/mui';
import { FzCard } from '../../../ui/fz/layout';
import { useEffect, useState } from 'react';
import { getDomains } from '../../../api/domains';
import { segmentationsHermesMeta } from './metaQueryBuilder';
import { getFieldsQueryBuilder, translationsConfig } from '../../../scripts/utils/jsonLogic-helpers';


export const FilterQueues = ({ ns, data, count }) => {
  const [config, setConfig] = useState({
    ...MuiConfig,
    ...translationsConfig,
  });
  const [state, setState] = useState({
    tree: QbUtils.checkTree(
      QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }),
      config
    ),
    config: config,
  });

  useEffect(() => {
    async function setConfigs() {
      const domains = await getDomains()
      const metaWithScores = addScoresToMeta(domains["available-scores"], segmentationsHermesMeta)
      setConfig({
        ...config,
        fields: { ...getFieldsQueryBuilder(domains, metaWithScores) },
      });
    }

    setConfigs()
  }, []);

  const addScoresToMeta = (scoresList, meta) => {
    for(let score of scoresList) {
      meta[score.code] = {
        "path": score.code,
        "label": score.description,
        "section": "score",
        "dataType": "number",
        "inputType": "number",
        "product": ["CP", "HE", "Refin"],
        isMandatory: (application) => true,
      }
    }

    return meta
  }

  useEffect(() => {
    if (data && data?.currentQueue?.jsonLogic) {
      const jsonTree = QbUtils.loadFromJsonLogic(
        data.currentQueue.jsonLogic,
        config
      );

      if (jsonTree) {
        setState({
          tree: QbUtils.checkTree(jsonTree, config),
          config: config,
        });
      }
    }
  }, [config]);

  useEffect(() => {
    const jsonLogic = QbUtils.jsonLogicFormat(state.tree, config);    
    if (jsonLogic && jsonLogic.logic) {
      ns.saveChange('currentQueue.jsonLogic', jsonLogic.logic);
    }
  }, [state]);

  return (
    <>
      <FzCard>
        <FzCard.Heading fzStyle={{ border: 'none' }}>
          <FzCard.Title componentClass="h3"> Segmentação </FzCard.Title>
        </FzCard.Heading>
        <div
          style={{
            background: '#FCF8E8',
            margin: '2px',
            padding: '1rem',
            border: 'solid 1px lightgrey',
            borderRadius: '2px',
          }}
        >
          <strong>Negócio / Cliente</strong>
          <QueryBuilder
            count={count}
            ns={ns}
            isQueue
            config={config} 
            state={state} 
            setState={setState} 
          />
        </div>
      </FzCard>
    </>
  );
};
