import { states } from "../../../biz/metadatas/application";
import { Meta } from "../../../types/metas";

export const segmentationsHermesMeta: Meta = {
  "civilStatus": {
    "path": "civilStatus",
    "label": "Estado civil",
    "section": "personal-data",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "civil-status",
      "options": [
        {
          "code": "single",
          "description": "Solteiro(a)"
        },
        {
          "code": "married",
          "description": "Casado(a)",
        },
        {
          "code": "divorced",
          "description": "Divorciado(a)",
        },
        {
          "code": "widowed",
          "description": "Viúvo(a)",
        }
      ],
      "autoComplete": "honorific-prefix",
    },
    "validationMessage": "Informe o estado civil",
    "pattern": /^.{2,}$/,
    isMandatory: (application) => true
  },
  "education": {
    "path": "education",
    "label": "Grau de instrução",
    "section": "personal-data",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "education",
    },
    "validationMessage": "Informe grau de instrução",
    "pattern": /^.{2,60}$/,
    isMandatory: (application) => true,
  },
  "newUser": {
    "path": "newUser",
    "label": "Cliente Novo",
    "section": "personal-data",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
    },
    "validationMessage": "Informe se o cliente é novo ou retornante.",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "workStatus": {
    "path": "workStatus",
    "label": "Ocupação",
    "section": "work-information",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "work-status",
      "options": [
        {
          "code": "public-employee",
          "description": "Funcionário Público"
        },
        {
          "code": "private-employee",
          "description": "Funcionário do Setor Privado"
        },
        {
          "code": "independent-contractor",
          "description": "Autônomo"
        },
        {
          "code": "business-owner",
          "description": "Empresário ou PJ"
        },
        {
          "code": "liberal-professional",
          "description": "Profissional Liberal"
        },
        {
          "code": "retired",
          "description": "Aposentado ou Pensionista"
        },
        {
          "code": "unemployed",
          "description": "Desempregado"
        }
      ],
    },
    "validationMessage": "Selecione a ocupação principal",
    "pattern": /^.{2,30}$/,
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "age": {
    "path": "age",
    "label": "Idade",
    "dataType": "number",
    "inputType": "number",
    "filterType": "range",
    "validationMessage": "Informe a idade",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "state": {
    "path": "state",
    "label": "Estado",
    "section": "residence-data",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": states
    },
    "validationMessage": "Informe o estado de residência",
    "pattern": /^[A-Z]{2}$/,
    "placeholder": "Estado?",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => ["CP", "Refin"].indexOf(application.product) > -1,
  },
  "incomeProofDocument": {
    "path": "incomeProofDocument",
    "label": "Comprovante de renda",
    "section": "work-information",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "income-proof-document",
      "filteredFrom": "workStatus",
    },
    "validationMessage": "Informe o comprovante de renda",
    "pattern": /^.{3,60}$/,
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "incomePaymentMethod": {
    "path": "incomePaymentMethod",
    "label": "Método de pagamento da renda",
    "section": "work-information",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "income-payment-method",
    },
    "validationMessage": "Informe como recebe o benefício",
    "pattern": /^.{4,14}$/,
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "benefitType": {
    "path": "benefitType",
    "label": "Tipo de benefício",
    "section": "work-information",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "benefit-type",
    },
    "validationMessage": "Informe o tipo de benefício",
    "product": ["CP"],
    isMandatory: (application) => false,
  },
  "monthlyIncome": {
    "path": "monthlyIncome",
    "label": "Renda mensal",
    "section": "work-information",
    "dataType": "number",
    "inputType": "number",
    "filterType": "range",
    "validationMessage": "A renda mínima aceita é um salário mínimo e maxima de R$60 mil",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
    "extraValidation": "monthlyIncome",
  },
  "householdMonthlyIncome": {
    "path": "householdMonthlyIncome",
    "label": "Renda total famíliar",
    "section": "work-information",
    "dataType": "number",
    "inputType": "number",
    "filterType": "range",
    "validationMessage": "A renda mensal total da família deve ser de no mínimo R$ 3.000,00",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
    "extraValidation": "householdMonthlyIncome",
  },
  "bankName": {
    "path": "bankName",
    "label": "Banco",
    "section": "bank-data",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "bank-name",
    },
    "relatedFields": ["accountType", "bankName"],
    "validationMessage": "Informe o nome do banco",
    "pattern": /^[0-9]{3}$/,
    "tip": "A conta precisa estar no nome do solicitante, não são aceitas contas de outras pessoas.",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
    "extraValidation": "bankName",
  },
  "accountType": {
    "path": "accountType",
    "label": "Tipo de conta bancária",
    "section": "bank-data",
    "dataType": "string",
    "inputType": "select",
    "filterType": "domain",
    "inputProps": {
      "domainName": "account-type",
    },
    "validationMessage": "Escolha um tipo de conta",
    "pattern": /^\w+$/,
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => application.product === 'CP',
    "normalize": (value) => value === "undefined" ? null : value,
  },
  "netWorth": {
    "path": "netWorth",
    "label": "Valor aproximado do patrimônio",
    "section": "work-information",
    "dataType": "number",
    "inputType": "select",
    "inputProps": {
      "options": [
        {
          "code": "1",
          "description": "Não possuo patrimônio"
        },
        {
          "code": "10000",
          "description": "Até R$ 10 mil"
        },
        {
          "code": "50000",
          "description": "Entre R$ 10 mil e R$ 50 mil"
        },
        {
          "code": "300000",
          "description": "Entre R$ 50 mil e R$ 300 mil"
        },
        {
          "code": "500000",
          "description": "Acima de R$ 300 mil"
        }
      ],
    },
    "validationMessage": "Informe o valor aproximado de bens",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "hasCreditRestrictions": {
    "path": "hasCreditRestrictions",
    "label": "Restrições de crédito",
    "section": "bank-data",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": true, "description": "Sim" },
        { "code": false, "description": "Não" },
      ],
    },
    "validationMessage": "Informe se há restrições de credito",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
  "approvedAmount": {
    "path": "auction.bids",
    "label": "Valor total aprovado",
    "section": "bid",
    "dataType": "number",
    "inputType": "number",
    "inputProps": {
    },
    "tableProps": {
      "hidden": false,
      "shortLabel": "approvedAmount",
      "exportFormat": "formatCurrency",
      "formatExtraData": "approvedAmount"
    },
    "product": ["CP", "HE", "Refin"],
  },
  "personalNumberDigit": {
    "path": "personalNumberDigit",
    "label": "CPF 7º Dígito",
    "dataType": "number",
    "inputType": "number",
    "filterType": "range",
    "validationMessage": "Informe o sétimo dígito do CPF",
    "product": ["CP", "HE", "Refin"],
    isMandatory: (application) => true,
  },
}
