export const CustomRelativeDateTime = {
  args: {
    dim: {
      label: "Dimension",
      type: "select",
      defaultValue: "day",
      valueSources: ["value"],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          second: "second",
          minute: "minute",
          hour: "hour",
          day: "day",
          month: "month",
        },
      },
      spelEscapeForFormat: false,
    },
  },
};