import React from "react";
import PropTypes from 'prop-types';
import {getUserById} from '../../../api/users'
import api from '../../../api/client-api';

class UserName extends React.Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {userId:null, userName: null}
  }
  render() {
    if (!this.props.userId || !this.props.customerId) {
      return <React.Fragment>n/a</React.Fragment>
    }
    if (this.state.userId !== this.props.userId) {
      Promise.resolve().then(() => {
        if (this.props.userId === this.props.customerId) {
          this.setState({userId:this.props.userId, userName: "Usuário"})
        } else if (api.getCurrentUser().id === this.props.userId) {
          this.setState({userId:this.props.userId, userName: "Eu mesmo"})
        } else {
          getUserById(this.props.userId).then(u => {
            this.setState({userId:this.props.userId, userName: u.name})
          })
        }
      })
      return null
    }
    return <React.Fragment>{this.state.userName}</React.Fragment>
  }
}
export {UserName}