import React, { useState } from 'react';
import { FzButton } from '../../../fz/form';
import { DocumentsType } from '../../../../services/api/screening/types'
import { FullScreenView } from './fullscreen-view';
interface DocumentImageProps {
  url: string;
  alt: string;
  doc?: DocumentsType;
  style?: any;
  btnStyle?: any;
  mimeType: string;
}

export function DocumentImage({
  url,
  alt,
  doc,
  btnStyle,
  mimeType,
}: DocumentImageProps) {
  const [fullscreen, setFullscreen] = useState(false);

  function handleFullScreen() {
    setFullscreen(!fullscreen);
  }

  return (
    <>
      {fullscreen 
      ? (
          <FullScreenView 
            url={url} 
            mimeType={mimeType} 
            handleClose={handleFullScreen} 
            description={alt}
          />
        ) 
      : <img
          width='100%'
          height='100%'
          alt={alt}
          src={url}
        />
      }
      {
        doc && mimeType !== 'application/pdf' && (
          <div style={btnStyle ? btnStyle : { display: 'flex', width: '77%' }}>
            <FzButton
              fzStyle="secondary"
              className="mt-0"
              id={doc.documentType}
              block
              onClick={handleFullScreen}
            >
              Visualizar
          </FzButton>
          </div>
        )
      }
    </>
  );

}


