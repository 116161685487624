import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FzImage, FzImages } from '../../../fz/form/view-image/image'

export function FullScreenView (props) {
  const [imgViewerWhite, setImgViewerWhite] = useState(true)

  function handleChangeBackground() {
    setImgViewerWhite(!imgViewerWhite)
  }

  return ( 
    <div className={'img-view-container ' + (imgViewerWhite ? 'white': 'black')}>
      {(
        props.images 
          ? <FzImages 
              images={props.images} 
              handleClose={props.handleClose}
            /> 
          : <FzImage 
              url={props.url} 
              handleClose={props.handleClose} 
              description={props.description}
            />
      )}
      
      <div className="img-view-bg-button custom-control custom-switch">
        <label 
          htmlFor="change-bg" 
          style={{marginRight: '10px'}}
        >
          Visualizar fundo
        </label>
        <Form>
          <Form.Check 
            checked={imgViewerWhite} 
            type="switch" 
            id="change-bg"
            onChange={() => handleChangeBackground()} 
          />
        </Form>
      </div>
    </div>
  )
}
