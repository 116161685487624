import React from 'react';
import api from '../../../api/client-api';
import { ApplicationFilter } from './application-filter';
import { ApplicationsTable } from './applications-table';
import { FzCard } from '../../fz/layout/index';


export default function ApplicationListView ({ ns, sharedNS, history }) {
  return (
    <FzCard.Body>
      <ApplicationFilter ns={ ns } sharedNS={ sharedNS } />
      <ApplicationsTable api={ api } ns={ ns } sharedNS={ sharedNS } url={ history } />
    </FzCard.Body>
  )
}