
import moment from 'moment'
import { Meta, MetaField } from '../../../../types/metas'

const normalizeDate = (value) => (
  typeof (value) === "object" && value !== null 
    ? value.toISOString() 
    : value
)

export const analyticsFilter: Meta = {
  "from": {
    "path": "from",
    "label": "De",
    "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: normalizeDate
  },
  "to": {
    "path": "to",
    "label": "Até",
    "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: normalizeDate
  }
}

export const analyticsColumns: Meta = {
  "applicationId": {
    "path": "applicationId",
    "label": "Proposta",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "applicationId",
      "shortLabel": undefined,
      "width": "4rem",
      "dataSort": true,
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataAlign": "left",
      "columnFormat": "applicationIdColumn",
    },
    "isMandatory": (application) => false,
  },

  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "personalNumber",
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "created": {
    "path": "created",
    "label": "Entrada na Único",
    "dataType": "string",
    "tableProps": {
      "columnFormat": "formatDateWithTimeAndWeekDay",
      "hidden": false,
      "width": "3.5rem",
      "dataSort": true,
      'expandable': false,
     
    },
    "isMandatory": () => false
  },
  "isApproved": {
    "path": "isApproved",
    "label": "Selfie Aprovada pela Único",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "booleanColumn",
      "filter": { "type": 'SelectFilter', "options": "filters.boolean" },
    },
    "isMandatory": () => false
  },
  "score": {
    "path": "score",
    "label": "Score da Único",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "requestUnico": {
    "path": "requestUnico",
    "label": "Total de envios",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "2.5rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "timeSpanInMiliseconds": {
    "path": "timeSpanInMiliseconds",
    "label": "Tempo de validação",
    "dataType": "string",
    "tableProps": {
      "columnFormat": "formatMillisecondsToDate",
      "hidden": false,
      "width": "3.2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "supplierName": {
    "path": "supplierName",
    "label": "Parceiro",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "5rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
    "isMandatory": () => false
  },
}

export const analyticsPassagersColumns: Meta = {
  "lastResponse": {
    "path": "lastResponse",
    "label": "Data",
    "dataType": "string",
    "tableProps": {
      "columnFormat": "formatDateWithTimeAndWeekDay",
      "hidden": false,
      "width": "3.5rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "score": {
    "path": "score",
    "label": "Score",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "userDocumentId": {
    "path": "userDocumentId",
    "label": "Selfie",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "selfieId",
      "shortLabel": undefined,
      "width": "4rem",
      "dataSort": true,
      "isKey": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "timeSpanInMiliseconds": {
    "path": "timeSpanInMiliseconds",
    "label": "Tempo de validação",
    "dataType": "string",
    "tableProps": {
      "columnFormat": "formatMillisecondsToDate",
      "hidden": false,
      "width": "3.2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "errorDescription": {
    "path": "errorDescription",
    "label": "Resposta",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "5rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}

export const defaulColumnSummaries: Meta = {
  "description": {
    "path": "description",
    "label": "",
    "dataType": "string",
    "tableProps": {
      "hidden": false,
      "width": "3.5rem",
      "dataSort": true,
      'expandable': false,
      'isKey': true,
    },
    "isMandatory": () => false
  },
}

const buildMetaItem = (
  valuePath: string,
  label: string,
  isKey: boolean = false
): MetaField => ({
  "path": valuePath,
  "label": label ? label : valuePath,
  "tableProps": {
    "hidden": false,
    "width": "5rem",
    "dataSort": true,
    'expandable': false,
    'isKey': isKey,
  },
  "isMandatory": () => false
})

const tableOrder = (values: string[]) => ([
  'description',
  ...values,
  'total',
])

export const builtMetaSummaries = (values = [], translateHeader) => {
  let meta = {}
  if(values.length > 0) {
    const filterItem = item => item !== 'description' && item !== 'total'
    const headers = tableOrder(
      Object.keys(values[0])
        .filter(filterItem)
        .sort((a, b) => (a > b ? 1 : -1))
    )
    
    headers.forEach(item => {
      const label = translateHeader[item] && translateHeader[item].name ? translateHeader[item].name : ''
      const isKey = item === 'description' ? true : false
      meta[item] = buildMetaItem(item, label, isKey)
    })
  }
  
  return meta
}
