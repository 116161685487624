import { Meta } from "../../types/metas";

export default {
  _id: {
    path: '_id',
    dataType: 'number',
    label: '',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '8ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'center',
      columnFormat: undefined,
      isKey: true,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    tip: undefined,
  },
  event: {
    path: 'event',
    dataType: 'text',
    label: 'Nome do evento',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  description: {
    path: 'description',
    dataType: 'text',
    label: 'Descrição do evento',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '30ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  long_url: {
    path: 'long_url',
    dataType: 'text',
    label: 'Link Longo',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: "Para utilizar multiplas URLs utilize | para separa-las",
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  expired_url: {
    path: 'expired_url',
    dataType: 'text',
    label: 'Link Expirado',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  created: {
    path: 'created',
    dataType: 'text',
    label: 'Data de criação',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: value => true,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  updated: {
    path: 'updated',
    dataType: 'text',
    label: 'Data de atualização',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'center',
      columnFormat: "dateColumn",
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: value => true,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  adm_eval: {
    path: 'adm_eval',
    dataType: 'text',
    label: 'Regra de alto nível (As regras abaixo apenas serão verificadas caso essa seja válida)',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: undefined,
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'message',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  }
};

export const tableRoleMessaging = {
  "index": {
    "path": "index",
    "dataType": "dataType",
    "label": "Id",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "3em",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "type": {
    "path": "type",
    "dataType": "dataType",
    "label": "Tipo",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "3em",
      "filter": { type: "TextFilter", delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "description": {
    "path": "description",
    "dataType": "dataType",
    "label": "Descrição",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "7em",
      "filter": { type: "TextFilter", delay: 1000 },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "templateid": {
    "path": "templateid",
    "dataType": "dataType",
    "label": "Template Id",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "9em",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "template": {
    "path": "template",
    "dataType": "dataType",
    "label": "Mensagem",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12em",
      "filter": { type: "TextFilter", delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "provider": {
    "path": "provider",
    "dataType": "dataType",
    "label": "Provedor",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "4em",
      "filter": { type: "TextFilter", delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "senderemail": {
    "path": "senderemail",
    "dataType": "dataType",
    "label": "E-mail",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "8em",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "sendername": {
    "path": "sendername",
    "dataType": "dataType",
    "label": "Emissor",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "4em",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "eval": {
    "path": "eval",
    "dataType": "dataType",
    "label": "Expressão para Mercurio Evaluation",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}

export const communication_test: Meta = {
  "ApplicationId": {
    "path": "ApplicationId",
    "label": "ID application",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  "Category": {
    "path": "Category",
    "label": "Category",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  "ContextParams.product": {
    "path": "ContextParams.product",
    "label": "Product",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": "no-security", "description": "Credito Pessoal" },
        { "code": "home", "description": "Refinanciamento de Imóvel" },
        { "code": "car", "description": "Refinanciamento de Veículos" }
      ],
    },
    "isMandatory": (application) => false,
  },
  "ContextParams.applicationQuality": {
    "path": "ContextParams.applicationQuality",
    "isMandatory": (application) => false,
  },
  "ContextParams.bidQuality": {
    "path": "ContextParams.bidQuality",
    "isMandatory": (application) => false,
  },
  "ContextParams.token": {
    "path": "ContextParams.token",
    "isMandatory": (application) => false,
  },
  "ContextParams.userQuality": {
    "path": "ContextParams.userQuality",
    "isMandatory": (application) => false,
  },
  "CustomerNotificationId": {
    "path": "CustomerNotificationId",
    "label": "Customer Notification ID ",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  "BidId": {
    "path": "BidId",
    "label": "Bid ID",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  "Event": {
    "path": "Event",
    "label": "Event",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
    "isReadOnly": () => true
  },
  "Sent": {
    "path": "Sent",
    "label": "Sent",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
    "isReadOnly": () => true
  },
  "UserId": {
    "path": "UserId",
    "label": "User Id",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
    "isReadOnly": () => true
  },
  "TestTelephone": {
    "path": "TestTelephone",
    "label": "Test Telephone",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "autoComplete": "tel",
      "mask": "(11) 1 1111 1111",
      "removeMask": true,
    },
    "isMandatory": (application) => false,
  },
  "TestEmail": {
    "path": "TestEmail",
    "label": "Test Email",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  "Test": {
    "path": "Test",
    "isMandatory": (application) => false,
  },
  "Iteration": {
    "path": "Iteration",
    "label": "Iteration",
    "dataType": "number",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
}

export const channelDetails: Meta = {
  ".type": {
    "path": ".type",
    "label": "Tipo",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": [
        { code: 'E', description: 'Email' },
        { code: 'S', description: 'SMS' },
        { code: 'B', description: 'Browser Push Notification' },
        { code: 'A', description: 'Mobile Push Notification' },
        { code: 'W', description: 'WhatsApp Notification' },
        { code: 'D', description: 'WhatsApp Direct Message' },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".description": {
    "path": ".description",
    "label": "Descrição",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".provider": {
    "path": ".provider",
    "label": "Provedor",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": [
        { code: 'sendgrid', description: 'SendGrid' },
        { code: 'zenvia', description: 'Zenvia' },
        { code: 'webpush', description: 'WebPush' },
        { code: 'salesforce', description: 'Salesforce' },
      ],
    },
    "isMandatory": (application) => false,
  },
  ".sendername": {
    "path": ".sendername",
    "label": "Nome Emissor",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  }
}

export const channelDetailsE = {
  ...channelDetails,
  ".templateid": {
    "path": ".templateid",
    "label": "Template Id (E-mail)",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".longUrl": {
    "path": ".longUrl",
    "label": "URL Longo",
    "tip": "Para utilizar multiplas URLs utilize | para separa-las",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".senderemail": {
    "path": ".senderemail",
    "label": "E-mail do Emissor (E-mail)",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".sendername": {
    "path": ".sendername",
    "label": "Nome Emissor",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
  ".category": {
    "path": ".category",
    "label": "Categoria (E-mail)",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
    "pattern": /^\w{1,255}$/,
    "validationMessage": "Este campo aceita letras, números e underscore e até 32 caracteres"
  },
  ".subject": {
    "path": ".subject",
    "label": "Assunto do e-mail",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": (application) => false,
  },
}

export const channelDetailsB = {
  ...channelDetails,
  ".template": {
    "path": ".template",
    "label": "Mensagem Push",
    "dataType": "string",
    "inputType": "textarea-options",
    "inputProps": {
      options: undefined,
      emoticons: false,
    },
    "isMandatory": (application) => false,
  },
}

export const channelDetailsS = {
  ...channelDetails,
  ".template": {
    "path": ".template",
    "label": "Mensagem SMS",
    "dataType": "string",
    "inputType": "textarea-options",
    "inputProps": {
      options: undefined,
      emoticons: false,
    },
    "isMandatory": (application) => false,
  },
}

export const channelDetailsW = {
  ...channelDetails,
  ".template": {
    "path": ".template",
    "label": "Template WhatsApp Notification",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": [
        { code: "f21df2c2-8186-470a-8b25-aac026fa6282", description: "Fup-CS Team" },
        { code: "ef9127e2-4420-4c58-afe3-ac8c706c0a40", description: "Fup-CS Team2" },
        { code: "92a9a6eb-af1f-4d74-8fe4-ebc724b4bb93", description: "cp_doc_pendenciado_sem_var" },
        { code: "a23c4375-ef19-4a6c-8b19-6b87edc4aa83", description: "cp_doc_sem_envio_msg1" },
        { code: "dc2993a3-43bd-41bc-8006-7d35e255c41f", description: "cp_doc_parcial_msg2" },
        { code: "ac0283cc-2133-4e29-9eaa-c1fbd7adfe59", description: "cp_doc_parcial_msg1" },
        { code: "2cf1d280-8998-45c8-a0b5-e0d0f1631b2e", description: "cp_doc_pendenciado_msg2" },
        { code: "d269a4c8-8c51-46cf-a56b-1d649e324f8c", description: "cp_assinar_contrato_msg1 - V2" }
      ]
    },
    "isMandatory": (application) => false,
  },
}

export const channelDetailsD = {
  ...channelDetails,
  ".template": {
    "path": ".template",
    "label": "Mensagem WhatsApp",
    "dataType": "string",
    "inputType": "textarea-options",
    "inputProps": {
      options: undefined,
      emoticons: true,
    },
    "isMandatory": (application) => false,
  },
}