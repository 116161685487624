import React, { ReactNode } from 'react';
import { FzCard } from '../../ui/fz/layout/FzCard';
import { FzNavSelect } from '../../ui/fz/nav';
import { Analytics } from './analytics';
import { Summaries } from './summaries';

function UnicoReports({ 
  data,
  filters,
  handlePage,
  handleSearchReport,
  handleTostClose,
  ns,
  role,
  showTost,
  typeView,
  suppliers,
}) {

  const contents = {
    analytics: (
      <Analytics 
        data={data} 
        filters={filters}
        handleSearchReport={handleSearchReport}
        handleTostClose={handleTostClose}
        ns={ns}
        role={role}
        showTost={showTost}
      />
    ),
    summaries: ( 
      <Summaries 
        data={data}
        handleTostClose={handleTostClose}
        ns={ns}
        handleSearchReport={handleSearchReport}
        showTost={showTost}
        suppliers={suppliers}
      />
    ),
  }

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={handlePage}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[typeView] || 'Aguardando seleção de relatório'}
      </FzCard>
    </>
  )
}

export { UnicoReports }
