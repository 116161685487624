import React, { useState, useEffect } from 'react';
import clone from "lodash/cloneDeep";
import moment from 'moment';
import { Link } from 'react-router-dom';

import { screeningCausesOfPendencyMeta, panelControlCausesOfPendency } from '../../../../biz/metadatas/stats/screening/causes-of-pendency';
import { FzCard, FzCol, FzRow } from '../../../fz/layout/index';
import { FzButton } from '../../../fz/form/button';
import { FzTable } from '../../../fz/grid/table';
import FieldConnected from '../../../components/form/field-connected';
import { getAllSuppliers } from '../../../../api/suppliers';
import { _getExtraData } from '../../../../components/table-standard';
import { FzIcons } from '../../../fz/form/icon';
import qs from 'qs';
import { getReportCausesOfPendency } from '../../../../services/api/screening';


export function ScreeningCausesOfPendency(props) {

  const [requestState, setRequestState] = useState("idle")
  const [suppliersList, setSuppliersList] = useState([])
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters]: any = useState({
    createdAfter: null,
    createdBefore: null,
    supplierInternalName: null
  })
  const { ns } = props

  useEffect(() => {
    if (urlChecked) {
      getStatistics()
    }
  }, [filters])


  useEffect(() => {
    ns.startEditing({
      createdAfter: moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0 }).local(),
      createdBefore: moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).local()
    })
    buildSuppliersList()
    getQueryStringFromUrl()
  }, [])

  function setUrlQueryString() {
    if (ns.getChanged()) {
      var lastChanged = ns.getChanged()
      setFilters(lastChanged)

      var url = {
        createdAfter: lastChanged.createdAfter instanceof moment ? lastChanged.createdAfter._d : "",
        createdBefore: lastChanged.createdBefore instanceof moment ? lastChanged.createdBefore._d : "",
        supplierInternalName: lastChanged.supplierInternalName,
      }

      const query = qs.stringify(url, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;

      history.pushState({ path: newurl }, '', newurl);
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());

    if (params.createdAfter || params.createdBefore || params.supplierInternalName) {

      if (params.createdAfter instanceof moment) {
        params.createdAfter = params.createdAfter._d
      }
      if (params.createdBefore instanceof moment) {
        params.createdBefore = params.createdBefore._d
      }

      ns.startEditing(params)
      setFilters(params)
    } else {
      var dateI = moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0, }).local()
      var dateB = moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0, }).local()
      ns.startEditing({
        createdAfter: dateI,
        createdBefore: dateB
      })
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  function buildSuppliersList() {
    getAllSuppliers()
      .then((suppliers) => {
        const suppliersList = suppliers.map(x => ({
          code: x.internalName,
          description: x.name
        }))
        setSuppliersList(suppliersList)
      })
      .catch((err) => {
        console.warn("API ERROR", err);
      })
      .finally(() => setRequestState("idle"))
  }

  async function getStatistics() {
    setRequestState("requesting")

    const limit = 100
    let skip = 0
    let keepLoading = true
    const fullLoadedResult = new Array

    if (filters) {
      ns.saveChange(filters)
    }

    while (keepLoading) {
      let { data }: any = await getReportCausesOfPendency(ns.getChanged(), limit, skip)
      let analyticsResult = data
      if (Array.isArray(analyticsResult) && analyticsResult.length <= limit) {
        fullLoadedResult.push(...analyticsResult)
        skip += limit
        ns.set("screeningAnalytics", fullLoadedResult)
        setRequestState("idle")
        if (analyticsResult.length !== limit) {
          keepLoading = false
        }
      } else {
        keepLoading = false
      }
    }
  }

  function idColumn(cell, row, formatExtraData, idx, ns) {
    let applicationId = cell;
    let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
    url += applicationId;
    let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a>
    ) : null;
    return (
      <div>
        {newTab}&nbsp;
        <Link to={url} title={cell}>
          {'...' + cell.slice(-6)}
        </Link>
      </div>
    );
  }

  function showList(cell, row, formatExtraData, idx, ns) {
    var rows: any = []
    for (var iteratorFieldElms = 0; iteratorFieldElms < cell.length; iteratorFieldElms++) {
      if (iteratorFieldElms % 2 === 0) {
        var lastRow: any = []
        rows.push(lastRow)
      }
      lastRow.push(<FzCol key={iteratorFieldElms} >{cell[iteratorFieldElms]}</FzCol>)
    }

    var fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>
    })

    return (
      <>
        {fieldsContent}
      </>
    )
  }

  let data = ns.getShared("filters") || ns.getChanged()
  const analyticsDataResult = ns.get('screeningAnalytics', [])

  let customField
  if (suppliersList) {
    customField = clone(panelControlCausesOfPendency["supplierInternalName"])
    customField.inputProps.options = [...suppliersList]
  }


  return (
    <FzCard>
      <FzCard>
        <div className={"fz-panel"} >
          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={data} meta={panelControlCausesOfPendency["createdAfter"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={data} meta={panelControlCausesOfPendency["createdBefore"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"}>
            {suppliersList && <FieldConnected data={data} meta={customField} ns={ns} />}
          </div>

          <div className={"fz-panel-border-padding"}>
            <FzButton
              onClick={
                setUrlQueryString
              }
              tip={"buscar"}
              disabled={false}
              className={null}
              block={true}
              fzStyle={"regular"}
            >
              Buscar
            </FzButton>
          </div>
        </div>
      </FzCard>

      <FzCard.Body>
        <FzTable
          data={analyticsDataResult}
          metas={screeningCausesOfPendencyMeta}
          context={"screeningAnalytics"}
          ns={ns}
          pagination
          customColumnFormatters={{
            applicationId: (cell, row, formatExtraData, idx, ns) => idColumn(cell, row, formatExtraData, idx, ns),
            reasonPending: (cell, row, formatExtraData, idx, ns) => showList(cell, row, formatExtraData, idx, ns),
          }}
          visibleColumns={[
            "applicationId",
            "personalNumber",
            "analysesEndDate",
            "documentType",
            "reasonPending",
            "supplierName",
            "screener",
            "screenedCount",
            "ocrScore",
            "status",
          ]}
        />
      </FzCard.Body>
    </FzCard >
  )
}