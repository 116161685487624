import {APPLICATION_STATE} from './application';

export function validApplicationSupplier(application, supplier) {
  if (application.applicationState === APPLICATION_STATE.Started || 
          application.applicationState === APPLICATION_STATE.HasBids || 
          application.applicationState === APPLICATION_STATE.FollowUp ||
          application.applicationState === APPLICATION_STATE.Accept) {
    return true;
  }
  return false;
}
