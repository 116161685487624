export const compareSupplier = (s1,s2) => {
  if (s1.name > s2.name) {
    return 1
  } else if (s1.name < s2.name) {
    return -1
  }
  return  0
}

export const setTTL = (min) => {
  const ttl = new Date()
  ttl.setMinutes(new Date().getMinutes() + min)
  return ttl
}