export const parameters = {
  "domainId": {
    "path": "domainId",
    "label": "Ver",
    "section": undefined,
    "dataType": undefined,
    "inputType": undefined,
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": "10ch",
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": false,
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    "isMandatory": undefined,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
  "domainName": {
    "path": "domainName",
    "label": "Dominios",
    "section": undefined,
    "dataType": undefined,
    "inputType": undefined,
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": undefined,
      "minValue": undefined,
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": undefined,
      "width": undefined,
      "filter": undefined,
      "dataSort": undefined,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": true,
    },
    "relatedFields": undefined,
    "validationMessage": undefined,
    "pattern": undefined,
    "placeholder": undefined,
    "tip": undefined,
    "product": undefined,
    "isMandatory": undefined,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined,
  },
}

export const domainsParameters = {
  "crm.queues.deal": {
    "inactive.seconds": {
      "path": "inactive.seconds",
      "label": "Margem de contato (Segundos)",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": "EM (Segundos)",
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "retrospect.days": {
      "path": "retrospect.days",
      "label": "Range de fila",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
  },
  "lending.auction.duration": {
    "extend_end_date": {
      "path": "extend_end_date",
      "label": "Numero de dias para extender proposta de (HE)",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "CP": {
      "path": "CP",
      "label": "CP",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "Cartao": {
      "path": "Cartao",
      "label": "Cartão",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "HE": {
      "path": "HE",
      "label": "HE",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "Consorcio": {
      "path": "Consorcio",
      "label": "Consórcio",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "Fin": {
      "path": "Fin",
      "label": "Fin",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
    "Refin": {
      "path": "Refin",
      "label": "Refin",
      "section": "",
      "dataType": "number",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined
      },
      "tableProps": {
        "viewsTable": undefined,
        "hidden": false,
        "shortLabel": undefined,
        "width": undefined,
        "filter": undefined,
        "dataSort": false,
        "dataAlign": undefined,
        "columnFormat": undefined,
        "isKey": false,
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": undefined,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined
    },
  },
  "domainDefault": {
    "template": {
      "path": "template",
      "label": "template",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "key": {
      "path": "key",
      "label": "Key",
      "section": undefined,
      "dataType": "string",
      "inputType": "string",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "value": {
      "path": "value",
      "label": "Value",
      "section": undefined,
      "dataType": "string",
      "inputType": "string",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
  }

}


export const paramFzScore = {
  "id": {
    "path": "id",
    "dataType": "id",
    "label": "id",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "shortLabel": null,
      "width": null,
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "field": {
    "path": "field",
    "dataType": "string",
    "label": "Campo",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "2ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "product": {
    "path": "product",
    "dataType": "string",
    "label": "Produto",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "2ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "operation": {
    "path": "operation",
    "dataType": "string",
    "label": "operation",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "2ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "weight": {
    "path": "weight",
    "dataType": "number",
    "label": "Weight",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "2ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "options": {
    "path": "options",
    "dataType": "dataType",
    "label": "Options",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "22ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}


export const teste = {
  "education": {
    "college": {
      "path": "college",
      "label": "college",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "illiterate": {
      "path": "illiterate",
      "label": "illiterate",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "postgraduate": {
      "path": "postgraduate",
      "label": "postgraduate",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "primary": {
      "path": "primary",
      "label": "primary",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "secondary": {
      "path": "secondary",
      "label": "secondary",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": undefined,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
    "weight": {
      "path": "weight",
      "label": "weight",
      "section": undefined,
      "dataType": "string",
      "inputType": "text",
      "inputProps": {
        "domainName": undefined,
        "filteredFrom": undefined,
        "options": undefined,
        "maxValue": undefined,
        "minValue": undefined,
        "maxSize": undefined,
        "minSize": undefined,
        "autoComplete": undefined,
        "mask": undefined,
        "removeMask": undefined,
      },
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "7ch",
        "filter": null,
        "dataSort": true,
        "dataAlign": "left",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "relatedFields": undefined,
      "validationMessage": undefined,
      "pattern": undefined,
      "placeholder": undefined,
      "tip": null,
      "product": undefined,
      "isMandatory": (application) => false,
      "isReadOnly": true,
      "isVisible": undefined,
      "normalize": undefined,
      "extraValidation": undefined,
      "hookOnSave": undefined,
    },
  }
}