import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import "../../fz/style/module/main-layout.css"

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
);

export function FzAppBarDesktop({
  open,
  user,
  handleDrawerOpen,
  isLogin,
}) {

  const classes = useStyles();
  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      style={{ backgroundColor: "#5923DD" }}
    >
      <Toolbar>
        {user && !isLogin && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6">
          FinanZero
          </Typography>
      </Toolbar>
    </AppBar>
  );
}
