
import React from 'react'
import { FzAccordion, FzCard } from '../../fz/layout/index'
import BranchDetails from './branch-detail'
import { SupplierFilters } from './supplier-filters';

interface branch {
  branchNick: string;
}

interface SupplierType {
  branches: {
    [key: string]: branch
  }
}

interface SupplierBranchEditProps {
  ns: any;
  supplier: SupplierType;
  code: string;
  readOnly: boolean;
  productsList: object;
  formSections: any;
  filters: any;
}

export function SupplierBranchEdit({ ns, supplier, code, readOnly, productsList, formSections, filters }: SupplierBranchEditProps) {
  if (!supplier.branches[code]) return null

  return (
    <FzAccordion>
      <FzAccordion.Toggle FzElementType={'h2'} eventKey={code} >
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title >{code} - {supplier.branches[code].branchNick}</FzCard.Title>
        </FzCard.Heading>
      </FzAccordion.Toggle>
      <FzAccordion.Collapse eventKey={code} className=''>
        <FzCard>
          <FzCard.Body>
            <BranchDetails ns={ns} productsList={productsList} formSections={formSections} filters={filters} supplier={supplier} code={code} />
            <SupplierFilters supplier={supplier} productsList={productsList} ns={ns} readOnly={readOnly} basePath={"branches." + code + ".filters"} branchInFilter={true} />
          </FzCard.Body>
        </FzCard>
      </FzAccordion.Collapse>
    </FzAccordion>
  )
}
