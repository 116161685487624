import React, { useState, useEffect } from 'react';
import clone from 'lodash/cloneDeep';
import { FzCard, FzCol, FzRow } from '../../fz/layout/index';
import FieldConnected from '../../components/form/field-connected';
import { FzButton } from '../../fz/form';
import { FzField } from './ui/fz/form';

interface supplierType {
  branches: {};
  internalName: string;
  name: string;
}

let branches = {};
export function UserDetails(props) {
  const [allSuppliers, setAllSuppliers] = useState<supplierType[]>([]);
  const [allProductsList, setAllProductsList] = useState([]);
  const { ns, data, metas, suppliers, products } = props;

  useEffect(() => {
    setAllSuppliers(suppliers);
  }, [suppliers]);

  useEffect(() => {
    setAllProductsList(products);
  }, [products]);

  useEffect(() => {
    loadBranches();
  }, [allSuppliers]);

  function loadBranches() {
    if (allSuppliers) {
      allSuppliers.map((it) => {
        if (it.branches) {
          branches[it.internalName] = Object.keys(it.branches).map(
            (branchKey) => {
              return {
                code: branchKey,
                description: it.branches[branchKey].branchNick,
              };
            }
          );
        }
      });
    }
  }

  function insertBranchOnMeta() {
    let branchMetaModel = {
      path: 'branch',
      label: 'Branch de parceiro',
      section: 'users',
      dataType: 'string',
      inputType: 'select',
      inputProps: {
        options: [],
      },
      tableProps: {
        isKey: false,
      },
      isMandatory: (value) => false,
    };
    let selectedPartners = [];
    if (data.preferredPartners) {
      selectedPartners = data.preferredPartners;
    }

    for (let i = 0; i < selectedPartners.length; i++) {
      branchMetaModel.path = 'branches.' + selectedPartners[i];
      branchMetaModel.label = `Branch - ${selectedPartners[i]}`;
      branchMetaModel.inputProps.options = branches[selectedPartners[i]];
      metas[selectedPartners[i]] = clone(branchMetaModel);
    }
  }

  let url_atual = window.location.href;
  insertBranchOnMeta();

  const fieldElms = Object.keys(metas).map((field) => {
    let meta = clone(metas[field]);

    if (field === 'preferredPartners') {
      if (allSuppliers) {
        meta.inputProps.options = allSuppliers.map((it) => {
          return {
            code: it.internalName,
            description: it.name,
          };
        });
      }
    }

    if (field === 'products') {
      meta.inputProps = {
        options: allProductsList,
      };
    }

    if (
      url_atual.match(/affiliate/) &&
      field === 'name' &&
      !url_atual.match(/new/)
    ) {
      meta.isReadOnly = () => true;
      meta.label = 'Nome - (Este campo não pode ser alterado)';
    }
    const { v4: uuidv4 } = require('uuid');
    const crypto = require('crypto');
    if (field === 'apiKey' && ns.isEditing()) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <FieldConnected key={field} meta={meta} data={data} ns={ns} />
          </div>
          <div style={{ width: 'auto', marginTop: '25px' }}>
            <FzButton
              fzStyle="success"
              onClick={() => {
                let user = ns.getChanged();
                if (user) {
                  user.apiKey = uuidv4();
                  ns.set('user', user);
                }
              }}
            >
              Gerar
            </FzButton>
          </div>
        </div>
      );
    } else if (field === 'apiSecret' && ns.isEditing()) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <FieldConnected key={field} meta={meta} data={data} ns={ns} />
          </div>
          <div style={{ width: 'auto', marginTop: '25px' }}>
            <FzButton
              fzStyle="success"
              onClick={() => {
                let user = ns.getChanged();
                if (user) {
                  user.apiSecret = crypto.randomBytes(6).toString('hex');
                  ns.set('user', user);
                }
              }}
            >
              Gerar
            </FzButton>
          </div>
        </div>
      );
    } else {
      return <FieldConnected key={field} meta={meta} data={data} ns={ns} />;
    }
  });

  var rows: any = [];
  for (
    var iteratorFieldElms = 0;
    iteratorFieldElms < fieldElms.length;
    iteratorFieldElms++
  ) {
    if (iteratorFieldElms % 2 === 0) {
      var lastRow: any = [];
      rows.push(lastRow);
    }
    lastRow.push(
      <FzCol key={iteratorFieldElms}>{fieldElms[iteratorFieldElms]}</FzCol>
    );
  }

  var fieldsContent = rows.map((rowContent, index) => {
    return <FzRow key={index}>{rowContent}</FzRow>;
  });

  return (
    <FzCard>
      <FzCard.Body>{fieldsContent}</FzCard.Body>
    </FzCard>
  );
}
