import React from 'react'
import BlockUi from 'react-block-ui'
import { FzCard } from '../../ui/fz/layout/FzCard'
import { FzTable } from '../../ui/fz/grid/table'

const buildMetaByHeaders = (headers) => {
  return headers.map((head, index) => ({ 
    [head.path]: { 
      path: head.path, 
      label: head.label, 
      tableProps: { isKey: (index === 0)},
    }
  })).reduce((acc, next) => ({...acc, ...next}), {})
}

const TestingFile = ({
  loading,
  ns,
  metaAndData,
}) => {
  return ( 
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <div style={{ display: "flex" }} className="mt-2">
        <FzCard>
            <h4 style={{ padding: "25px 20px 0 25px"}}>
              Abaixo o resultado das 10 primeiras linhas do seu arquivo.
            </h4>
          <FzCard.Body>
            <FzTable
              customHeader='success'
              data={metaAndData.data}
              metas={buildMetaByHeaders(metaAndData.headers)}
              ns={ns}
              exportDownload={false}
              clipboard={false}
            />
          </FzCard.Body>
        </FzCard>
      </div>
    </BlockUi>
  )
}

export default TestingFile
