import { getByID } from '../../../api/applications';
import { useEffect, useRef, useState } from 'react';
import { FzSelect } from '../../fz/form/old-simple-input';
import { FzModal } from '../../fz/fz-modal';
import { getSuppliers } from '../../../scripts/utils/cache-helpers';
import {
  postCustomSimulation,
  getAllowedCustomSimulationSuppliers,
} from '../../../api/integrations';
import { FzToast } from '../../fz/form/notification/toast';
import { FzCol, FzForm, FzRow } from '../../fz/layout';
import { FzField } from '../../fz/form';
import {
  CustomSimulationRequest,
  CustomSimulationType,
  OptionsItem,
  StateType,
  SupplierType,
  ToastType,
  filterAllowedSuppliers,
  getOfferIdsLength,
  installmentsOptions,
  intersectSuppliers,
  toastTypes,
  validAppliedAmount,
} from './custom-simulation-helpers';

const TIME_UNTIL_POLLING = 60000;

export function CustomSimulationModal({ ns, application }) {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastInfo, setToastInfo] = useState<ToastType>(
    toastTypes['internal-error']
  );

  const [allowedSuppliers, setAllowedSuppliers] = useState<OptionsItem[]>([]);
  const [suppliersAll, setSuppliersAll] = useState<
    Record<string, SupplierType>
  >({});
  const [supplierName, setSupplierName] = useState<string>();
  const [appliedAmount, setAppliedAmount] = useState<number>();
  const [loanPeriodInMonths, setLoanPeriodInMonths] = useState<number>();

  const [disableSuccessButton, setDisableSuccessButton] =
    useState<boolean>(true);

  const customSimulations = useRef<CustomSimulationType>({});
  const appid = application.id || ns.get('application.id');
  const appliedFromApplication = Number(ns.get('application.appliedAmount'));

  useEffect(() => {
    handleSuppliersAll();
    if (appliedFromApplication && !isNaN(appliedFromApplication)) {
      setAppliedAmount(appliedFromApplication);
    }
  }, []);

  useEffect(() => {
    if (
      (!allowedSuppliers || allowedSuppliers.length === 0) &&
      suppliersAll &&
      Object.keys(suppliersAll).length > 0
    ) {
      handleAllowedSuppliers();
    }
  }, [suppliersAll]);

  useEffect(() => {
    if (!suppliersAll || Object.keys(suppliersAll).length === 0) {
      handleSuppliersAll();
    }
  }, [ns]);

  useEffect(() => {
    if (
      supplierName &&
      supplierName !== '' &&
      appliedAmount &&
      !isNaN(Number(appliedAmount))
    ) {
      setDisableSuccessButton(false);
    } else {
      setDisableSuccessButton(true);
    }
  }, [supplierName, appliedAmount]);

  function handleSuppliersAll() {
    const suppliers: Record<string, SupplierType> = getSuppliers(ns);
    setSuppliersAll(suppliers);
  }

  async function handleAllowedSuppliers() {
    const allowedBranches = await getAllowedCustomSimulationSuppliers();

    const acceptedAppSuppliers = intersectSuppliers(
      application?.supplierData,
      allowedBranches
    );

    const supplierList = filterAllowedSuppliers(
      suppliersAll,
      acceptedAppSuppliers
    );

    setAllowedSuppliers(supplierList);
  }

  async function sendCustomSimulation() {
    try {
      if (!supplierName || !appliedAmount || !application) {
        handleToast(setShowToast, setToastInfo, 'missing-data');
        return;
      }
      if (customSimulations?.current[supplierName]?.isRequesting) {
        handleToast(
          setShowToast,
          setToastInfo,
          'already-requesting',
          supplierName
        );
        return;
      }
      const ids = getOfferIdsLength(application.auction?.bids, supplierName);
      if (!ids) {
        handleToast(setShowToast, setToastInfo, 'no-offer-ids', supplierName);
        return;
      }

      const payload: CustomSimulationRequest = {
        appliedAmount,
        ...(loanPeriodInMonths && { loanPeriodInMonths }),
      };

      await postCustomSimulation(appid, supplierName, payload);

      customSimulations.current[supplierName] = {
        offeridslength: ids,
        isRequesting: true,
        appliedAmount: appliedAmount,
        installments: loanPeriodInMonths,
        requestedAt: Date.now(),
      };

      handleToast(setShowToast, setToastInfo, 'requested', supplierName);

      setSupplierName('');
      if (appliedFromApplication && !isNaN(appliedFromApplication)) {
        setAppliedAmount(appliedFromApplication);
      }
      setLoanPeriodInMonths(0);
      setDisableSuccessButton(true);

      getAppAfterInterval(supplierName);
      return;
    } catch (error) {
      console.error('error at customSimulation', error);
      if (
        error ===
        '404 - : {"message":"Cannot find supplier in sqs configuration"}'
      ) {
        handleToast(
          setShowToast,
          setToastInfo,
          'configuration-error',
          supplierName
        );
      } else {
        handleToast(setShowToast, setToastInfo, 'internal-error');
      }
      return;
    }
  }

  async function getAppAfterInterval(supplierName: string) {
    const polling = setTimeout(async () => {
      const app = await getByID(appid);
      const ids = getOfferIdsLength(app?.auction?.bids, supplierName);
      if (!ids) {
        handleToast(setShowToast, setToastInfo, 'no-offer-ids', supplierName);
        return;
      }
      const simulation = customSimulations.current[supplierName];
      simulation.isRequesting = false;

      if (ids > simulation.offeridslength) {
        ns.set('application', app);
        handleToast(setShowToast, setToastInfo, 'success', supplierName);
        return;
      } else {
        handleToast(setShowToast, setToastInfo, 'no-content', supplierName);
        return;
      }
    }, TIME_UNTIL_POLLING);
    return () => clearTimeout(polling);
  }

  const label = 'Nova simulação';

  return (
    <>
      <FzModal
        headerTitle={label}
        key={label}
        showButtonText={label}
        showButtonFzStyle="regular"
        buttonDisabled={
          [1, 2, 16, 128, 28672].includes(application.applicationState) ||
          !allowedSuppliers ||
          allowedSuppliers.length === 0
        }
        cancelText="Cancelar"
        handleCancelButton={() => {}}
        successText="Simular"
        handleSuccessButton={sendCustomSimulation}
        successButtonDisabled={disableSuccessButton}
      >
        <FzRow>
          <FzCol span={4}>
            <FzForm.Label>Parceiro *</FzForm.Label>
            <FzSelect
              name="supplierName"
              readOnly={false}
              value={supplierName}
              onChange={(value: string) => setSupplierName(value)}
              fzStyle={{ marginBottom: '7px' }}
              options={allowedSuppliers}
            />
          </FzCol>
          <FzCol span={4}>
            <FzField
              name="appliedAmount"
              dataType="number"
              onChange={(value: string) => setAppliedAmount(Number(value))}
              inputType="text"
              readOnly={false}
              value={`R$ ${String(appliedAmount).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                '.'
              )}`}
              label="Valor pedido"
              invalid={validAppliedAmount(appliedAmount)}
              mandatory={true}
              doNormalize={(value: string) => value.replaceAll(/[^0-9]/g, '')}
            />
          </FzCol>
          <FzCol span={4}>
            <FzForm.Label>Número de parcelas</FzForm.Label>
            <FzSelect
              name="installments"
              readOnly={false}
              value={loanPeriodInMonths}
              onChange={(value: string) => setLoanPeriodInMonths(Number(value))}
              options={installmentsOptions}
            />
          </FzCol>
        </FzRow>
      </FzModal>
      <FzToast
        {...toastInfo}
        close={() => setShowToast(false)}
        show={showToast}
      />
    </>
  );
}

function handleToast(
  setShowToast: StateType,
  setToastInfo: StateType,
  type: string,
  supplier: string = ''
) {
  const toast = toastTypes[type];
  let bodyMessageTost = toast.messageTost;
  if (String(bodyMessageTost).indexOf('{') > -1 && supplier !== '') {
    bodyMessageTost = bodyMessageTost.replace(/{.[^\/]*}/, supplier);
  }
  setToastInfo({ bodyMessageTost, ...toast });
  setShowToast(true);
}
