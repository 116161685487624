import React from 'react';
import { FormalizationDocsStats } from '../../pages/statistics/formalizationDocsStats'
import { UnicoReports } from '../../../ui/pages/statistics/UnicoReports'
import { HSReports } from '../../../ui/pages/statistics/HSReports'
import * as statsMeta from '../../../biz/metadatas/formalization-metadata'
import { BoaVistaReports } from '../../pages/statistics/boaVistaReports';

const FormalizationsStats = ({ ns, activeKey, toggleBlocking, ...props }) => {

  switch (activeKey) {
    case "documents":
      return <FormalizationDocsStats ns={ns} metas={statsMeta.formalizationSummary} toggleBlocking={toggleBlocking} />
    case "unico":
      return <UnicoReports ns={ns} toggleBlocking={toggleBlocking} />
    case "hs":
      return <HSReports ns={ns} toggleBlocking={toggleBlocking} />
    case "boaVista":
      return <BoaVistaReports ns={ns} toggleBlocking={toggleBlocking} />
    default:
      return null

  }
}

export default FormalizationsStats