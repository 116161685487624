import React, { Component } from 'react'
import { FormGroup, Button } from 'react-bootstrap'
import { changeProduct, getAllProducts } from '../api/applications';
import { FzCard } from '../ui/fz/layout/index'
import { FzForm } from '../ui/fz/layout/index'
import { FzAlert } from '../ui/fz/form/alert'
import { FzSelect } from '../ui/fz/form/old-simple-input'
import { ProductName } from '../application-components/input-domains';

const APP_ACTION_PRODUCT_CHANGE = 'product-change';

export default class ActionChangeProduct extends Component {

  constructor(props) {
    super(props)
    this.state = {
      allProductsOptions: [], newProduct: '',
      alert: {
        show: false,
        message: ""
      }
    }
  }

  componentDidMount() {
    this.loadAllProducts();
  }

  async loadAllProducts() {
    const productsResp = await getAllProducts();
    this.setState({
      allProductsOptions: productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    });
  }


  doChangeProduct = () => {

    changeProduct(this.props.app.id, this.props.app.product, this.state.newProduct, this.props.app.revision).then((app) => {
      this.props.ns.set("application", app)
      this.props.ns.startEditing(app)
      this.props.dismissAction()

      let treatment = this.props.ns.get("crm.treatments." + app.id) ? this.props.ns.get("crm.treatments." + app.id) + " * Produto modificado" : "Produto modificado";
      let treatmentArray = this.props.ns.get("crm.treatmentsArray." + app.id) ? this.props.ns.get("crm.treatmentsArray." + app.id) : [];
      treatmentArray.push(APP_ACTION_PRODUCT_CHANGE);

      this.props.ns.set("crm.treatments." + app.id, treatment);
      this.props.ns.set("crm.treatmentsArray." + app.id, treatmentArray);

    }).catch(err => {
      if (typeof err === "string" && err.startsWith("409")) {
        this.setState({
          alert: {
            show: true,
            message: "Troca de produto não permitida! Já existe uma proposta ativa ou criada a menos de 10 dias para o produto solicitado."
          }
        })
      }
      console.error({ err })
    })

  }
  render() {
    let { app, dismissAction } = this.props
    let warning
    if (Object.keys(app.supplierData).length > 0) {
      warning = <FzAlert fzStyle="alert">Atenção! Esse cliente já foi enviado para um parceiro.</FzAlert>
    } else if (this.state.alert.show && this.state.alert.message.length > 0) {
      warning = <FzAlert fzStyle="alert">{this.state.alert.message}</FzAlert>
    }

    return (
      <FzCard bsStyle="warning">
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Trocar produto</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FormGroup>
            <FzForm.Label>Produto Atual</FzForm.Label>
            <FzForm.Static>
              <ProductName product={app.product} />
            </FzForm.Static>

            <FzForm.Label>Produto</FzForm.Label>
            {
              this.state.allProductsOptions.length > 0 &&
              <FzSelect
                value={this.state.newProduct}
                name="product"
                onChange={(newVal) => this.setState({ newProduct: newVal })}
                options={this.state.allProductsOptions}
              />
              }
          </FormGroup>
          <FormGroup>
            {warning}
            <Button onClick={dismissAction}>Voltar</Button>
            <Button
              disabled={!app || !app.product || !this.state.newProduct || this.state.newProduct === app.product}
              bsStyle="warning"
              onClick={this.doChangeProduct}
            >
              Trocar
            </Button>
          </FormGroup>
        </FzCard.Body>
      </FzCard>
    )
  }
}
