import React, { useEffect } from 'react';
import { ShowValidationStep } from './show-validation-step';
import { FzCard } from '../../fz/layout/index';
import { FzAccordion } from '../../fz/layout/FzAccordion';
import { getScreeningRulesTranslation } from '../../../scripts/utils/cache-helpers';

export const outcomesDescriptions = {
  success: 'APROVADO',
  'docs-pending': 'PENDENCIADO',
  rejected: 'REJEITADO',
};

export const nameDescriptions = {
  screening: 'Triagem',
  integration: 'Integração',
  'telephone-validation': 'Validação de Telefone',
  unico: 'Único',
  hs: 'Antifraude | HS',
  'partner-telephone-validation': 'Validação de Telefone - Parceiro',
};

interface ValidationStepType {
  name: string;
  sent: string;
  outcome: string;
  documentsOutcomes: documentsOutcomesType[];
}

interface ValidationStepsHistoryType {
  created: string;
  validationSteps: ValidationStepType[];
}

interface documentsOutcomesType {
  documentType: string;
  pendencies: string[];
  state: string;
}

interface AnalysisPanelProps {
  validationStepsHistory: ValidationStepsHistoryType[];
  validationSteps: ValidationStepType[];
  ns: any;
}

export function AnalysisPanel({
  validationSteps,
  validationStepsHistory,
  ns,
}: AnalysisPanelProps) {
  useEffect(() => {
    getScreeningRulesTranslation(ns);
  }, []);

  const currenSteps = validationSteps.filter((step) => step.sent !== null);

  const historySteps =
    validationStepsHistory &&
    validationStepsHistory
      .reduce((acc: ValidationStepType[], cur: ValidationStepsHistoryType) => {
        acc = Array.isArray(cur.validationSteps)
          ? acc.concat(cur.validationSteps)
          : [];
        return acc;
      }, [])
      .sort(function (a, b) {
        return +new Date(b.sent) - +new Date(a.sent);
      });

  return (
    <FzCard>
      <FzCard.Heading fzStyle={{}}>
        <FzCard.Title>Mesa de Analise</FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <>
          {currenSteps.map((step, index) => {
            return (
              <ShowValidationStep
                key={step.name + index}
                name={step.name}
                sent={step.sent}
                outcome={step.outcome}
                documentsOutcomes={step.documentsOutcomes}
                ns={ns}
              />
            );
          })}
          <FzAccordion>
            <FzAccordion.Toggle FzElementType="h5" eventKey="1">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <strong className="mr-3" role="button">
                  Historico de Avaliações
                </strong>
              </div>
            </FzAccordion.Toggle>
            <FzAccordion.Collapse eventKey="1">
              <FzCard>
                <div>
                  {historySteps &&
                    historySteps.map((step, index) =>
                      step.sent ? (
                        <ShowValidationStep
                          key={step.name + index}
                          name={step.name}
                          sent={step.sent}
                          outcome={step.outcome}
                          documentsOutcomes={step.documentsOutcomes}
                          ns={ns}
                        />
                      ) : null
                    )}
                </div>
              </FzCard>
            </FzAccordion.Collapse>
          </FzAccordion>
        </>
      </FzCard.Body>
    </FzCard>
  );
}
