import React, { useState, useEffect } from 'react';

import { applicationFilters } from '../../../biz/metadatas/application-filter';
import { flatApplicationAllPosition } from '../../../biz/metadatas/application';
import { getAllSuppliers } from '../../../api/suppliers';
import { getAllProducts } from '../../../api/applications';

import { FzCard, FzCol, FzRow } from '../../fz/layout/index';
import { FzAccordion } from '../../fz/layout/FzAccordion';
import FieldConnected from '../../components/form/field-connected'
import '../../fz/style/blocks/application-filter.css';


export function ApplicationFilter({ ns, sharedNS }) {
  const [resquestState, setResquestState] = useState("idle");
  const [allProductsList, setAllProductsList] = useState([{}]);
  const [allSuppliers, setAllSuppliers] = useState([{}])

  useEffect(() => {
    loadAllProducts()
    buildSuppliersList()
  }, []);


  function buildSuppliersList() {
    setResquestState("requesting")
    getAllSuppliers()
      .then((suppliers:any) => {
        let suppliersList = [{ code: "", description: "Selecione o parceiro ..." }]
        for (var supplier of suppliers) {
          suppliersList.push({ code: supplier.internalName, description: supplier.name })
        }
        setAllSuppliers(suppliersList)
      })
      .catch((err) => {
        console.warn("API ERROR", err);
      })
      .finally(() => setResquestState("idle"));
  }


  async function loadAllProducts() {
    const productsResp = await getAllProducts();
    setAllProductsList(
      productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    )
  }


  function buildSectionsFiltersApplications(metas, data, ns) {
    const fieldElms = metas.map((field) => {
      return <FieldConnected key={field.path} meta={field} data={data} ns={ns} />;
    });
    
    var rows = createColumns(fieldElms, fieldElms.length)
    var fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>;
    });

    return fieldsContent
  }


  function createColumns(fieldElms: any[], n: number) {
    let lastRow: any[] = [];
    let rows: any[] = [];

    for (let iteratorFieldElms: number = 0; iteratorFieldElms < fieldElms.length; iteratorFieldElms++) {
      lastRow.push(
        <FzCol key={iteratorFieldElms}>
          {fieldElms[iteratorFieldElms]}
        </FzCol>
      );
      if (lastRow.length > n - 1) {
        rows.push(lastRow);
        lastRow = [];
      }
    }
    return rows
  }


  function buildSection(title, allSections, section) {
    return (
      <div>
        <FzAccordion>
          <FzCard>
            <FzAccordion.Toggle FzElementType='h5' eventKey={section}>
              <FzCard.Heading fzStyle={{}}>
                <FzCard.Title> {title} </FzCard.Title>
              </FzCard.Heading>
            </FzAccordion.Toggle>
            <FzAccordion.Collapse eventKey={section}>
              <FzCard.Body>
                {allSections[section]}
              </FzCard.Body>
            </FzAccordion.Collapse>
          </FzCard>
        </FzAccordion>
      </div>
    )
  }


  if (!allProductsList && resquestState === "requesting") {
    return null
  }

  let data = ns.getShared("filters") || ns.getChanged()


  const metas: string[] | any = flatApplicationAllPosition(applicationFilters)
  var allSections = [
    "filter-application",
    "filter-mkt",
    "filter-geo-local",
  ].reduce((resultSoFar, it) => {
    const metasFiltered = metas.filter(m => m.section === it)
    if ("filter-application" === it) {
      let foundMetaProduct = metasFiltered.find(it => it.path === "product");
      foundMetaProduct.inputProps = allProductsList.length > 0 ?
        {
          options: allProductsList
        } :
        {
          options: []
        }
    }
    resultSoFar[it] = buildSectionsFiltersApplications(metasFiltered, data, ns)
    return resultSoFar
  }, {})

  let paidSupplierCustomizedMeta = metas.find(it => it.path === 'paidSupplier')
  if (allSuppliers) {
    paidSupplierCustomizedMeta.inputProps.options = allSuppliers
  }


  return (
    <>
      <FzAccordion defaultActiveKey='0'>
        <FzCard>
          <FzAccordion.Toggle FzElementType='h5' eventKey="0">
            <FzCard.Heading fzStyle={{}}>
              <FzCard.Title> Filtros </FzCard.Title>
            </FzCard.Heading>
          </FzAccordion.Toggle>
          <FzAccordion.Collapse eventKey="0">
            <FzCard.Body>
              <div>
                {allSections["filter-application"]}
                {buildSection("UTM", allSections, "filter-mkt")}
                {buildSection("Geolocalização", allSections, "filter-geo-local")}
                {
                  <div>
                    <FzAccordion>
                      <FzCard>
                        <FzAccordion.Toggle FzElementType='h5' eventKey={"filter-paid"}>
                          <FzCard.Heading fzStyle={{}}>
                            <FzCard.Title> Pagos </FzCard.Title>
                          </FzCard.Heading>
                        </FzAccordion.Toggle>
                        <FzAccordion.Collapse eventKey={"filter-paid"}>
                          <FzCard.Body>
                            <FzRow>
                              <FzCol>
                                <div className='paidAfter'>
                                  <FieldConnected meta={applicationFilters['paidAfter']} data={data} ns={ns} />
                                </div>
                              </FzCol>
                              <FzCol>
                                <div className='paidBefore'>
                                  <FieldConnected meta={applicationFilters['paidBefore']} data={data} ns={ns} />
                                </div>
                              </FzCol>
                              <FzCol>
                                {
                                  <FieldConnected
                                    meta={paidSupplierCustomizedMeta}
                                    data={data}
                                    ns={ns}
                                  />
                                }
                              </FzCol>
                            </FzRow>
                          </FzCard.Body>
                        </FzAccordion.Collapse>
                      </FzCard>
                    </FzAccordion>
                  </div>
                }
              </div>
            </FzCard.Body>
          </FzAccordion.Collapse>
        </FzCard>
      </FzAccordion>
    </>
  )
}