
export const integrationAnalytic = {
  "id": {
    "path": "id",
    "dataType": "id",
    "label": "App Id",
    "tableProps": {
      "viewsTable": "",
      "width": "14ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "applicationIdColumn",
      "isKey": true,
    },
  },
  "created": {
    "path": "created",
    "dataType": "date",
    "label": "Created Date-BR",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "12ch",
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "periodWithTimeColumnTz",
      "exportFormat": "formatPeriodForExport",
    },
    "tip": "Created Date-BR",
  },
  "auctionStartDate": {
    "path": "auctionStartDate",
    "dataType": "date",
    "label": "Auction Date-BR",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumnTz",
      "exportFormat": "formatPeriodForExport",
    },
    "tip": "Auction Date-BR",
  },
  "personalNumber": {
    "path": "personalNumber",
    "dataType": "number",
    "label": "CPF",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": false,
      "width": "15ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
    "tip": "User personal id"
  },
  "supplierApplicationId": {
    "path": "supplierApplicationId",
    "dataType": "id",
    "label": "Supplier's id",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": false,
      "width": "24ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
    "tip": "Supplier's internal application number"
  },
  "supplierName": {
    "path": "supplierName",
    "dataType": "text",
    "label": "Supplier's name",
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "width": "10ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
    },
    "tip": "Supplier's internal name"
  },
  "applicationState": {
    "path": "applicationState",
    "dataType": "domain",
    "label": "Current Status",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": {
        "type": "SelectFilter",
        "options": "statesDescriptions",
        "condition": "eq"
      },
      "dataSort": true,
      "columnFormat": "applicationStateColumn",
    },
  },
  "RGDocumentType": {
    "path": "RGDocumentType",
    "dataType": "text",
    "label": "ID Type",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "filter": {
        "type": "SelectFilter",
        "options": "RGDocumentType",
        "condition": "eq"
      },
      "dataSort": true,
      "columnFormat": "getRGDocumentType",
    },
  },
  "IncomeProofDocument": {
    "path": "IncomeProofDocument",
    "dataType": "domain",
    "label": "Income Proof Type",
    "tableProps": {
      "viewsTable": "",
      "width": "10em",
      "filter": {
        "type": "SelectFilter",
        "options": "IncomeProofDocumentType",
        "condition": "eq"
      },
      "dataSort": true,
      "columnFormat": "getIncomeProofDocument",
    },
  },
  "vehicleType": {
    "path": "vehicleType",
    "dataType": "text",
    "label": "Tipo de veículo",
    "tableProps": {
      "viewsTable": "",
      "width": "20ch",
      "filter": {
        "type": "SelectFilter",
        "options": "vehicleType",
        "condition": "eq"
      },
    },
    "tip": "vehicleType"
  },
  "branchCode": {
    "path": "branchCode",
    "dataType": "text",
    "label": "Branch",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "reserved": {
    "path": "reserved",
    "dataType": "number",
    "label": "Reserved",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "Sent to supplier pre analysis (P1)"
  },
  "sent": {
    "path": "sent",
    "dataType": "number",
    "label": "Sent",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "booleanColumn_en",
      "formatExtraData": false,
    },
    "tip": "Complete lead sent to partner (P2)"
  },
  "apiAccepted": {
    "path": "apiAccepted",
    "dataType": "bool",
    "label": "Selected",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "Supplier enabled application to proceed (P1)"
  },
  "approved": {
    "path": "approved",
    "dataType": "bool",
    "label": "Pre-approved",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "Supplier pre-approved the application"
  },
  "rejected": {
    "path": "rejected",
    "dataType": "bool",
    "label": "Rejected",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "Rejected by supplier"
  },
  "expired": {
    "path": "expired",
    "dataType": "bool",
    "label": "Expired",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "Expired offer"
  },
  "userAccepted": {
    "path": "userAccepted",
    "label": "User Accepted",
    "dataType": "bool",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "defaultValue": "autoFilterUserAccepted()",
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "User accepted this offer"
  },
  "badges": {
    "path": "badges",
    "label": "Badges",
    "dataType": "text",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "appliedAmount": {
    "path": "appliedAmount",
    "label": "Applied",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataAlign": "right",
      "columnFormat": "amountColumn",
    },
    "tip": "Applied amount"
  },
  "approvedAmount": {
    "path": "approvedAmount",
    "dataType": "number",
    "label": "Approved amount",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
    },
    "tip": "Approved amount"
  },
  "paid": {
    "path": "paid",
    "label": "Bid Paid",
    "dataType": "bool",
    "tableProps": {
      "viewsTable": "",
      "width": "5em",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
    },
    "tip": "Bid Paid"
  },
  "notaCliente": {
    "path": "notaCliente",
    "dataType": "number",
    "label": "Nota Cliente",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "amountColumn",
    },
    "tip": "Nota Cliente"
  },
  "monthlyInstallment": {
    "path": "monthlyInstallment",
    "dataType": "number",
    "label": "Installment",
    "tableProps": {
      "viewsTable": "",
      "width": "9ch",
      "dataAlign": "center",
      "columnFormat": "amountColumn",
    },
    "tip": "Monthly Installment"
  },
  "annualInterestRate": {
    "path": "annualInterestRate",
    "dataType": "number",
    "label": "CET",
    "tableProps": {
      "viewsTable": "",
      "width": "9ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "pctColumn",
    },
    "tip": "Annual Interest Rate (includes fees)"
  },
  "interestRate": {
    "path": "interestRate",
    "dataType": "number",
    "label": "Taxa",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "pctColumn",
    },
    "tip": "Annual Interest Rate"
  },
  "timeToBid": {
    "path": "timeToBid",
    "dataType": "number",
    "label": "Time to bid",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "msColumn",
    },
    "tip": "Time to Bid in seconds"
  },
  "rejectedReason": {
    "path": "rejectedReason",
    "dataType": "string",
    "label": "Reject reason",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "API message on rejecting application"
  },
  "bidRefusedReason": {
    "path": "bidRefusedReason",
    "dataType": "string",
    "label": "Bid Refused reason",
    "tableProps": {
      "viewsTable": "",
      "width": "15em",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Bid refused reason"
  },
}

export const integrationSummary = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
      rowSpan: "2"
    },
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      rowSpan: "2",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    "tip": "Period",
  },
  "reserved": {
    "path": "reserved",
    "dataType": "number",
    "label": "Res.",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Sent to reserve API (if exists)",
    },
    "tip": "Reserved applications"
  },
  "reservedAmount": {
    "path": "reservedAmount",
    "dataType": "number",
    "label": "$ Res.",
    "tableProps": {
      rowSpan: "2",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Amount of $ sent to reserve API",
    },
    "tip": "Reserved amount"
  },
  "apiAccepted": {
    "path": "apiAccepted",
    "dataType": "number",
    "label": "Accepted",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Accepted by partner API",
    },
    "tip": "Accepted by partner API"
  },
  "sent": {
    "path": "sent",
    "label": "Sent",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "headerText": "Complete lead sent to partner (P2)",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Sent",
            "width": "12ch",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "12ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "12ch",
          }
        ]
      },
      "formatExtraData": "reserved",
    },
    "tip": "Complete lead sent to partner (P2)"
  },
  "sentAmount": {
    "path": "sentAmount",
    "dataType": "number",
    "label": "Sent Amount",
    "tableProps": {
      rowSpan: "2",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Total amount of leads sent to partner (P2)",
    },
  },
  "rejected": {
    "path": "rejected",
    "dataType": "number",
    "label": "Rejected",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Rejected by partner",
    },
  },
  "approved": {
    "path": "approved",
    "dataType": "number",
    "label": "Pre-approved",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "headerText": "Number of applications approved",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Pre-approved",
            dataAlign: "left",
            "width": "10ch",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "10ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "10ch",
          }]
      },
      "formatExtraData": "sent",
    },
  },
  "limitBids": {
    "path": "limitBids",
    "dataType": "number",
    "label": "Bids up to limit",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Bids received customer is still on screen (up to 50s)",
    }
  },
  "fastBids": {
    "path": "fastBids",
    "dataType": "number",
    "label": "Fast bids",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Bids received fast (up to 30s)",
    }
  },
  "expired": {
    "path": "expired",
    "dataType": "number",
    "label": "Expired",
    "tableProps": {
      rowSpan: "2",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Number of bids expired",
    },
  },
  "approvedAmount": {
    "path": "approvedAmount",
    "dataType": "number",
    "label": "Approved amount",
    "tableProps": {
      rowSpan: "2",
      "width": "14ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Sum of approved amount",
    },
    "tip": "Approved amount"
  },
  "interestRate": {
    "path": "interestRate",
    "dataType": "number",
    "label": "Interest %",
    "tableProps": {
      rowSpan: "2",
      "width": "8ch",
      "dataAlign": "center",
      "columnFormat": "pctColumn",
      "headerText": "Average interest rate of all bids in period",
    },
    "tip": "AVG interest"
  },
  "timeToBid": {
    "path": "timeToBid",
    "dataType": "number",
    "label": "Time to bid",
    "tableProps": {
      rowSpan: "2",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Average time to Bid in seconds",
    },
    "tip": "Time to Bid in seconds"
  },
  "userAccepted": {
    "path": "userAccepted",
    "dataType": "number",
    "label": "User accepted",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "headerText": "Bids accepted by users",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "User accepted",
            dataAlign: "left",
            "width": "10ch",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "10ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "10ch",
          }]
      },
      "formatExtraData": "approved",
    },
    "tip": "Bids accepted by users"
  },
  "acceptedAmount": {
    "path": "acceptedAmount",
    "dataType": "number",
    "label": "Accepted amount",
    "tableProps": {
      rowSpan: "2",
      "width": "14ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Sum of amount accepted by users",
    },
    "tip": "Sum of amount accepted by users"
  },
  "paidAmount": {
    "path": "paidAmount",
    "dataType": "number",
    "label": "Paid Amount",
    "tableProps": {
      rowSpan: "2",
      "width": "14ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "headerText": "Total amount paid to users",
    },
    "tip": "Total amount paid to users"
  },
  "paid": {
    "path": "paid",
    "dataType": "number",
    "label": "Paid",
    "tableProps": {
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Paid",
            dataAlign: "left",
            "width": "8ch",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            "width": "8ch",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "8ch",
          }]
      },
      "headerText": "Number of Bids paid to users (% over accepted bids)",
      "formatExtraData": "userAccepted",
      "tip": "Number of Bids paid to users (% over accepted bids)"
    },
  }
}

export const agentQueueAnalytic = {
  "_id": {
    "path": "_id",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "shortLabel": false,
      "width": "26ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "applicationId": {
    "path": "applicationId",
    "dataType": "id",
    "label": "App Id",
    "tableProps": {
      "viewsTable": "",
      "width": "14ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "applicationIdColumn",
      "isKey": false,
    },
  },
  "queue": {
    "path": "queue",
    "dataType": "text",
    "label": "Fila",
    "tableProps": {
      "viewsTable": "",
      "width": "25ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "",
      "exportFormat": undefined,
    },
  },
  "created": {
    "path": "created",
    "dataType": "date",
    "label": "Criado",
    "tableProps": {
      "viewsTable": "",
      "width": "15ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "dateTimeColumn",
      "exportFormat": undefined,
    },
  },
  "updated": {
    "path": "updated",
    "dataType": "date",
    "label": "Atualizado",
    "tableProps": {
      "viewsTable": "",
      "width": "15ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "dateTimeColumn",
      "exportFormat": undefined,
    },
  },
  "active": {
    "path": "active",
    "dataType": "text",
    "label": "Ativo",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
      "exportFormat": undefined,
    },
  },
  "locked": {
    "path": "locked",
    "dataType": "bool",
    "label": "Locked",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
      "exportFormat": undefined,
    },
  },
  "canceled": {
    "path": "canceled",
    "dataType": "bool",
    "label": "Canceled",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
      "exportFormat": undefined,
    },
  },
  "attempts": {
    "path": "attempts",
    "dataType": "number",
    "label": "Attempts",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "exportFormat": undefined,
    },
  },
  "maxAttempts": {
    "path": "maxAttempts",
    "dataType": "number",
    "label": "Max Attempts",
    "tableProps": {
      "viewsTable": "",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "exportFormat": undefined,
    },
  },
  "trace": {
    "path": "trace",
    "dataType": "object",
    "label": "Trace",
    "tableProps": {
      "viewsTable": "",
      "width": "11ch",
      "dataSort": true,
      "dataAlign": "right",
      "exportFormat": undefined,
    },
  },
  "reprocessing": {
    "path": "reprocessing",
    "dataType": "object",
    "label": "Reprocessing",
    "tableProps": {
      "viewsTable": "",
      "width": "18ch",
      "dataSort": true,
      "dataAlign": "right",
      "exportFormat": undefined,
    },
  },
};
