
import { FzCard, FzSplitLine} from '../../../fz/layout/index'
import { FzTable } from '../../../fz/grid/table';
import { FzButton } from '../../../fz/form/button';
import { users } from '../../../../biz/metadatas/users'
import { useState, useEffect } from 'react';
import { getUsers } from '../../../../api/users';

const HermesUsersList = ({ns, history}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    getUsers('advisor')
        .then((users) => {
          ns.set("users", users);
          setData(users)
        })
        .catch((err) => history.push("/"));
  }, [])

  function navigateToHermesUser (user) {
    let newPath = ns.namespace + "/" + user.id
    history.push(newPath)
  }

  return (
    <div style={{padding: "20px"}}>
      <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzSplitLine>
              <FzSplitLine.Left>
                <FzCard.Title componentClass="h3">Usuários&nbsp;<b>Consultor de Vendas</b></FzCard.Title>
              </FzSplitLine.Left>
            </FzSplitLine>
          </FzCard.Heading>
          <FzCard.Body>
          <FzTable
                data={data || []}
                metas={users}
                ns={ns}
                customColumnFormatters={{
                  id: (cell, row) => {
                    return (
                      <FzButton bsSize="xsmall" onClick={() => navigateToHermesUser(row)}>Ver</FzButton>
                    )
                  }
                }}
                visibleColumns={["id", "created", "name", "email", "telephoneNumber", "group", "products"]}
                exportDownload={false}
                clipboard={false}
              />
          </FzCard.Body>
        </FzCard>
    </div>
  )
}

export { HermesUsersList }