import React, { Component } from "react";
import {
  Jumbotron,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import MessageRuleDetails from "./message-details";
import MessageRuleChannels from "./message-channels";
/*import { MessageFilters } from './message-filters'*/
import { getRuleById, updateRule, getAllRules, createMessageRule } from "../api/client-messages";

import api from "../api/client-api";
import { FzCardGroup } from "../ui/fz/layout/index";
import { FzAlert } from "../ui/fz/form/alert";

export class EditMessageRule extends Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.getRuleById = this.getRuleById.bind(this);
    this.startEditing = this.startEditing.bind(this);
  }

  doGetMessages() {
    return getAllRules()
      .then(messages => {
        return messages;
      })
      .catch(err => {
        console.warn(err);
        this.props.history.push("/");
        return null;
      });
  }


  getRuleById() {

    const { ns } = this.props

    getRuleById(this.props.match.params.ruleId)
      .then(message => {
        ns.unset("dirty");
        ns.set("message", message);
      })
      .catch(error => console.warn(error));

  }



  saveChanges() {
    if (this.props.readOnly) {
      return;
    }

    const ns = this.props.ns;
    const tmpRule = ns.getChanged();
    const invalidFields = ns.getInvalidFields()

    if (invalidFields.length > 0) {
      alert("Corrija os erros nos campos marcados de vermelho!");
      return;
    }

    if (!tmpRule) {
      alert("Erro! Valores não especificados!");
      return;
    }


    if (!tmpRule.event) {
      alert("Evento deve ser especificado!");
      return;
    }

    if (tmpRule.channels) {
      for (let i = 0; i < tmpRule.channels.length; i++) {
        if (
          !tmpRule.channels[i].description ||
          tmpRule.channels[i].description.length === 0
        ) {
          alert("Descrição deve ser especificada! Id: " + i + " - ");
          return;
        }
        if (
          !tmpRule.channels[i].type ||
          tmpRule.channels[i].type.length === 0
        ) {
          alert(
            "Tipo deve ser selecionado! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }
        if (
          !tmpRule.channels[i].provider ||
          tmpRule.channels[i].provider.length === 0
        ) {
          alert(
            "Provedor deve ser selecionado! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }
        if (
          tmpRule.channels[i].type === "S" &&
          tmpRule.channels[i].provider !== "zenvia"
        ) {
          alert(
            "Tipo e provedor não compatíveis! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }
        if (
          tmpRule.channels[i].type === "E" &&
          !["sendgrid","salesforce"].includes(tmpRule.channels[i].provider)
        ) {
          alert(
            "Tipo e provedor não compatíveis! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }
        if (
          tmpRule.channels[i].type === "B" &&
          tmpRule.channels[i].provider !== "webpush"
        ) {
          alert(
            "Tipo e provedor não compatíveis! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }

        if (
          (tmpRule.channels[i].type === "S" ||
            tmpRule.channels[i].type === "B") &&
          (!tmpRule.channels[i].template ||
            tmpRule.channels[i].template.trim() === "")
        ) {
          alert(
            "Mensagem deve ser especificada! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }
        if (
          tmpRule.channels[i].type === "E" &&
          (!tmpRule.channels[i].templateid ||
            tmpRule.channels[i].templateid.trim() === "")
        ) {
          alert(
            "Template Id deve ser especificado! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }
        if (
          tmpRule.channels[i].type === "E" &&
          (!tmpRule.channels[i].senderemail ||
            tmpRule.channels[i].senderemail.trim() === "")
        ) {
          alert(
            "Email do Emissor deve ser especificado para Mensagem tipo E-mail! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }

        if (
          !tmpRule.channels[i].sendername ||
          tmpRule.channels[i].sendername.length === 0
        ) {
          alert(
            "Nome Emissor deve ser digitdo! Id: " +
            i +
            " - " +
            tmpRule.channels[i].description
          );
          return;
        }

        // filter validation
        if (tmpRule.channels[i].filters) {
          for (let i2 = 0; i2 < tmpRule.channels[i].filters.length; i2++) {
            if (
              !tmpRule.channels[i].filters[i2].eval ||
              tmpRule.channels[i].filters[i2].eval === 0
            ) {
              alert(
                "Expressão Condicional de rega deve ser especificada! Id: " +
                i +
                " - " +
                tmpRule.channels[i].description +
                " - Condição Id: " +
                i2
              );
              return;
            }
          }
        }
      }
    }

    if (tmpRule._id !== "new") {
      updateRule(tmpRule)
        .then(message => {
          ns.set("message", message);
          ns.unset("dirty");
          ns.stopEditing();

        })
        .catch(err => {
          ns.unset("dirty");
          console.error("API ERROR:", err);
          ns.stopEditing();
          ns.unset("message");
        });
    } else {


      const newMessgaRule = {
        ...tmpRule,
        _id: undefined
      }

      createMessageRule(newMessgaRule)
        .then(newMessgaRule => {
          this.props.ns.unset("message")
          ns.set("message", newMessgaRule);
          ns.stopEditing()

        })
        .catch(err => {
          console.error(err)
          alert("Erro ao criar Regra de Mensagem: " + err)
        })

    }

  }

  startEditing() {
    const { ns } = this.props
    const user = api.getCurrentUser()
    const base = ns.get("message")
    const data = {
      ...base,
      Iteration: 1,
      Event: base.event,
      UserId: user.id,
      Sent: base.created,
      TestTelephone: user.telephonenumber,
      TestEmail: user.email,
    }
    ns.startEditing(data)
  }


  render() {
    let { ns } = this.props;
    const message = ns.getChanged() || ns.get("message");
    if (message === null || message === undefined) {
      this.getRuleById()
      return null
    }


    let buttons;
    let alert;
    let readOnly = !ns.isEditing();
    if (ns.isEditing()) {
      buttons = (
        <ButtonGroup>
          <Button variant="primary" onClick={this.saveChanges}>
            Gravar
          </Button>
          <Button
            variant="default"
            onClick={() => {
              ns.unset("dirty");
              ns.stopEditing();
              this.props.history.push("/admmessages")
            }}
          >
            Cancelar
          </Button>
        </ButtonGroup>
      );

      if (ns.isInvalid()) {
        alert = (
          <FzAlert fzStyle="alert" title={ns.getInvalidFields().join()}>
            Corrija os erros nos campos marcados de vermelho!
          </FzAlert>
        );
      }
    } else if (api.isUserInRole("admin")) {

      buttons = (
        <ButtonGroup>
          <Button
            variant="primary"
            onClick={this.startEditing}
          >
            Editar
            </Button>
        </ButtonGroup>
      );
    }
    return (
      <Jumbotron>
        <FzCardGroup id="EditMessage" bsPrefix='card-group' >
          {buttons}
          {alert}
          <MessageRuleDetails
            message={message}
            ns={ns}
            readOnly={readOnly}
            persistChanges={this.saveChanges}
          />
          <MessageRuleChannels
            message={message}
            ns={ns}
            readOnly={readOnly}
            persistChanges={this.saveChanges}
          />
        </FzCardGroup>
      </Jumbotron>
    );
  }
}
