
export const funnelSummary = {
  "product": {
    "path": "product",
    "dataType": "string",
    "label": "STEPS",
    "highlight": true,
    "dictionary": {
      "CP": "Crédito pessoal",
      "Refin": "Refinanciamento",
      "Fin": "Financiamento",
      "HE": "Home Equity"
    },
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": false,
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "string",
    "label": "Period",
    "highlight": true,
    "defaultSort": -1,
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count": {
    "path": "count",
    "dataType": "number",
    "label": "Leads",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": true,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "appliedAmount": {
    "path": "appliedAmount",
    "dataType": "currency",
    "label": "Applied Amount",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "formatCurrency",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": false,
      "sortFunc": null
    },
    "tip": null
  },
  "filteredApply": {
    "path": "filteredApply",
    "dataType": "number",
    "label": "Filtered Apply",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "gaveupApply": {
    "path": "gaveupApply",
    "dataType": "number",
    "label": "Gaveup Apply",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "timeoutApply": {
    "path": "timeoutApply",
    "dataType": "number",
    "label": "Timeout Apply",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "filteredPolicy": {
    "path": "filteredPolicy",
    "dataType": "number",
    "label": "Filtered Policy",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "gaveupPolicy": {
    "path": "gaveupPolicy",
    "dataType": "number",
    "label": "Gaveup Policy",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "timeoutPolicy": {
    "path": "timeoutPolicy",
    "dataType": "number",
    "label": "Timeout Policy",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "completed": {
    "path": "completed",
    "dataType": "number",
    "label": "Has Auction",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "hasBids": {
    "path": "hasBids",
    "dataType": "number",
    "label": "Received Bids (1 at least)",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsCount": {
    "path": "bidsCount",
    "dataType": "number",
    "label": "Quantity of Bids",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "approvedAmount": {
    "path": "approvedAmount",
    "dataType": "number",
    "label": "Total Approved Amount",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "formatCurrency",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "appliedApproved": {
    "path": "appliedApproved",
    "dataType": "number",
    "label": "Appplied Amount in Approved",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "formatCurrency",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "accepted": {
    "path": "accepted",
    "dataType": "number",
    "label": "Quantity of Accepted Bids",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "coLeadslumnFormat": null,
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "refused": {
    "path": "refused",
    "dataType": "number",
    "label": "Quantity of Refused Bids",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "paidout": {
    "path": "paidout",
    "dataType": "number",
    "label": "Paid",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "paidAmount": {
    "path": "paidAmount",
    "dataType": "number",
    "label": "Paid Amount",
    "highlight": false,
    "dictionary": false,
    "tableProps": {
      "viewsTable": "",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "formatExtraData": null,
      "isKey": false,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}

export const funnel = {
  "supplier": {
    "path": "supplier",
    "dataType": "string",
    "label": "Supplier",
    "highlight": true,
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "string",
    "label": "Period",
    "highlight": true,
    defaultSort: -1,
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count": {
    "path": "count",
    "dataType": "number",
    "label": "Leads",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "filtered": {
    "path": "filtered",
    "dataType": "number",
    "label": "Filtered policy",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "reserved": {
    "path": "reserved",
    "dataType": "number",
    "label": "Reserved",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "rejectedPre": {
    "path": "rejectedPre",
    "dataType": "number",
    "label": "Rejected Pre",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "sent": {
    "path": "sent",
    "dataType": "number",
    "label": "Sent P2",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "rejectedApi": {
    "path": "rejectedApi",
    "dataType": "number",
    "label": "Rejected API",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsCount": {
    "path": "bidsCount",
    "dataType": "number",
    "label": "Sent Bids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsAccepted": {
    "path": "bidsAccepted",
    "dataType": "number",
    "label": "Accepted Bids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsRefused": {
    "path": "bidsRefused",
    "dataType": "number",
    "label": "Refused Bids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsCanceled": {
    "path": "bidsCanceled",
    "dataType": "number",
    "label": "Canceled Bids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsUnique": {
    "path": "bidsUnique",
    "dataType": "number",
    "label": "Bids Unique",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "bidsAcceptedOthers": {
    "path": "bidsAcceptedOthers",
    "dataType": "number",
    "label": "Bids Accepted Other",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "paidBySupplier": {
    "path": "paidBySupplier",
    "dataType": "dataType",
    "label": "Paid (Supplier)",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "paidByOther": {
    "path": "paidByOther",
    "dataType": "dataType",
    "label": "Paid (Others)",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": "formatCurrency",
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}

export const pivotMeta = {
  "key": {
    "path": "key",
    "dataType": "number",
    "label": "#",
    "tableProps": {
      "width": "3ch",
      "hidden": true,
      "isKey": true,
      "dataSort": true,
      "dataAlign": "center",
    }
  },
  "pivot": {
    "path": "pivot",
    "dataType": "string",
    "label": "Indicador",
    "tableProps": {
      "hidden": null,
      "shortLabel": null,
      "width": "35ch",
      "dataAlign": "left"
    }
  },
  "template": {
    "path": "template",
    "dataType": "number",
    "label": "template",
    "tableProps": {
      "width": "10ch",
      "filter": null,
      "hidden": true,
      "dataAlign": "right",
      "columnFormat": "totalAmountColumn",
      "exportFormat": "formatCurrency",
    }
  }
}


export const pivotMeta_TESTE = {
  "key": {
    "path": "key",
    "dataType": "number",
    "label": "#",
    "tableProps": {
      "width": "3ch",
      "hidden": true,
      "isKey": true,
      "dataSort": true,
      "dataAlign": "center",
    }
  },
  "pivot": {
    "path": "pivot",
    "dataType": "string",
    "label": "Indicador",
    "tableProps": {
      "hidden": null,
      "shortLabel": null,
      "width": "35ch",
      "dataAlign": "left"
    }
  },
  "template": {
    "path": "template",
    "dataType": "number",
    "label": "template",
    "tableProps": {
      "width": "10ch",
      "filter": null,
      "hidden": true,
      "dataAlign": "right",
      "columnFormat": "teste_P",
      "exportFormat": "formatCurrency",
    }
  }
}