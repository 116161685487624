import { useEffect, useState } from "react";
import { FzButton } from "./button";
import { FzField } from "./field";

interface IField {
  filter: string;
  type: string;
  value: string;
}

interface IOptions {
  code: string;
  description: string;
}

interface FzConfirmationStepsProps {
  readOnly: boolean;
  value: Array<IField>;
  onChange: (newVal: IField[]) => void;
  formSections: Array<IOptions>;
  filters: Array<IOptions>;
}

interface IMetaField {
  name: string;
  dataType: string;
  label: string;
  inputType: string;
  inputProps: {
    options: Array<IOptions>;
  };
  fzStyle: string;
  placeHolder: string;
  col: string;
}

const FzConfirmationSteps = (props: FzConfirmationStepsProps) => {
  const { readOnly, value, onChange, formSections, filters } = props;
  const [fieldsArray, setFieldsArray] = useState<Array<IField>>([]);

  const metaFieldsConfirmationSteps: Array<IMetaField> = [{
      name: "filter",
      dataType: "string",
      label: "Nome do Filtro:",
      inputType: "select",
      inputProps: {
        options: filters
      },
      fzStyle: "small",
      placeHolder: "Selecione o filtro a ser utilizado",
      col: "12"
    }, {
      name: "type",
      dataType: "string",
      label: "Tipo de Confirmação:",
      inputType: "select",
      inputProps: {
        options: [
          { 
            code: "form", 
            description: "Formulário"
          }, { 
            code: "integration", 
            description: "Integração"
          }
      ]},
      fzStyle: "small",
      placeHolder: "Selecione o tipo do Confirmação",
      col: "12"
    }
  ]

  const metaFieldForm: IMetaField = {
    name: "value",
    dataType: "string",
    label: "Seção:",
    inputType: "select",
    inputProps: {
      options: formSections
    },
    fzStyle: "small",
    placeHolder: "Selecione a seção do formulário",
    col: "12"
  }

  const metaFieldIntegration: IMetaField = {
    name: "value",
    dataType: "string",
    label: "Trigger:",
    inputType: "text",
    inputProps: {
      options: []
    },
    fzStyle: "small",
    placeHolder: "Digite o nome do 'Message Trigger'",
    col: "12"
  }

  useEffect(() => {
    setFieldsArray(value ? value : []);
  }, [value]);

  function addNewField() {
    setFieldsArray([...fieldsArray, { filter: '', type: '', value: '' }])
  }

  function changeField(fieldIndex: number, fieldName: string, value: string) {
    if (!readOnly) {
      let newArray = [...fieldsArray];
      newArray[fieldIndex][fieldName] = value;
      setFieldsArray(newArray);
      onChange(newArray)
    }
  }

  function removeField(index: number) {
    let newArray = [...fieldsArray]
    newArray.splice(index, 1)
    setFieldsArray(newArray)
    onChange(newArray)
  }

  const mountMetaFields = (field: IField, fieldIndex) => {
    let _metaFields: Array<IMetaField> = [...metaFieldsConfirmationSteps]
    if ( field['type'] === 'form' ) _metaFields.push(metaFieldForm)
    if ( field['type'] === 'integration' ) _metaFields.push(metaFieldIntegration)

    return _metaFields.map((metaField, metaIndex) => {
      return (
        <FzField
          key={metaIndex}
          readOnly={readOnly}
          onChange={(e) => changeField(fieldIndex, metaField.name, e)}
          value={field[metaField['name']]}
          {...metaField}
        />
      )
    })
  }

  const mountFieldList = () => {
    return fieldsArray.map((field, fieldIndex) => {
      return (
        <div key={fieldIndex}>
          <div  style={{border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '15px'}}>
            {mountMetaFields(field, fieldIndex)}
            <div className="col-md-12">
              <FzButton
                fzStyle="alert"
                disabled={readOnly}
                onClick={() => removeField(fieldIndex)}
                className={'w-100 d-flex align-items-center justify-content-center'}
                customStyle={{ marginLeft: '0px', marginBottom: '0.5rem', height: "80%" }}>
                Deletar item
              </FzButton>
            </div>
          </div>
          <hr/>
        </div>
      )
    })
  }

  return (
    <div>
      <div className='form-group' style={{border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '15px'}}>
        {mountFieldList()}
        <FzButton onClick={addNewField} disabled={readOnly} fzStyle="discrete">
          <i className="fa-solid fa-plus"></i> Adicionar Novo
        </FzButton>
      </div>
    </div>
  )
}

export default FzConfirmationSteps