import { Meta } from "../../types/metas"

const actionsOptions = [
  { code: "new", description: "Create" },
  { code: "put", description: "Update Field" },
  { code: "save", description: "Update Application" },
  { code: "validatePolicies", description: "ValidatePolicies" },
  { code: "completed", description: "Completed" },
]
const CustomizedComponentsOptions = [
  { code: "DocumentSection", description: "DocumentSection" },
  { code: "AddressSection", description: "AddressSection" },
  { code: "BankSection", description: "BankSection" },
  { code: "OptInSection", description: "OptInSection" },
  { code: "VehicleSection", description: "VehicleSection" },
]
const HiddenSectionOptions = [
  { code: "isHiddenWhenBankIsNone", description: "Quando preencher: Não tenho conta em banco" },
  { code: "isHidden", description: "Sempre" },
  { code: "isHiddenTypeDory", description: "Baseado em filtro da dory (baseado no type da section)" },
]
const extraValidationsOptions = [
  { code: "validateAccount", description: "validateAccount" },
]

export const FormForm = {
  "$group": "us",
  "$name": "Formulário",
  "$span": 12,
  "$namePath": "product",
  "product": {
    path: 'product',
    dataType: 'string',
    label: 'Produto',
    "span": 12,
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    section: 'formsForm',
    "inputType": "select",
    // "filterType": "domain",
    "inputProps": {
      "domainName": "products",
      filteredFrom: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    // product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  "title": {
    path: 'title',
    dataType: 'text',
    label: 'Título (cabeçalho da primeira página)',
    "span": 12,
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'formsForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    // product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  "disclaimer": {
    path: 'disclaimer',
    dataType: 'text',
    label: 'Disclaimer',
    "span": 12,
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'WYSIWYG',
    section: 'formsForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  "description": {
    path: 'description',
    dataType: 'text',
    label: 'Descrição',
    "span": 12,
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'formsForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    // product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  "bulletsIcons": {
    path: 'bulletsIcons',
    label: 'Bullets',
    "span": 12,
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    "dataType": "string",
    "inputType": "dynamic-fields-list-with-icons",
    section: 'formsForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    // product: undefined,
    isMandatory: value => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  "action": {
    path: 'action',
    dataType: 'text',
    label: 'Função de atualização de campo a campo',
    "span": 12,
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'select',
    section: 'formsForm',
    inputProps: {
      domainName: "form-actions",
      filteredFrom: undefined,
      options: actionsOptions,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: "Função de atualização campo a campo deve ser Update Field",
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    // product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: "actionValidation",
    hookOnSave: undefined,
  },
  "pages.$": {
    "$name": "Página",
    "$span": 6,
    "$namePath": "pages.$.page",
    "$nameIsIndex": true,
    "pages.$.action": {
      path: 'pages.$.action',
      dataType: 'text',
      label: 'Função de fim de página',
      "span": 12,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'select',
      section: 'formsForm',
      inputProps: {
        domainName: "form-actions",
        filteredFrom: undefined,
        options: actionsOptions,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: undefined,
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      // product: undefined,
      isMandatory: value => true,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: "actionValidation",
      hookOnSave: undefined,
    },
    "pages.$.page": {
      "path": "pages.$.page",
      "label": "Número da página",
      "section": "formsForm",
      "dataType": "number",
      "inputType": "number",
      "span": 12,
      "inputProps": {
        // "domainName": 'NumberOfDoors',
      },
      "tableProps": {
        // "viewsTable": "application",
        "hidden": false,
        "shortLabel": undefined,
        // "width": "12ch",
        "filter": undefined,
        // "dataSort": true,
        "dataAlign": "right",
        // "columnFormat": "maxInstallmentColumn",
        // "exportFormat": "formatCurrency"
      },
      "isMandatory": ()=>true,
      "isReadOnly": ()=>true
    },
    "pages.$.title": {
      path: 'pages.$.title',
      dataType: 'text',
      label: 'Título da página',
      "span": 12,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'text',
      section: 'formsForm',
      inputProps: {
        domainName: undefined,
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: undefined,
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      // product: undefined,
      isMandatory: value => false,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "pages.$.buttonText": {
      path: 'pages.$.buttonText',
      dataType: 'text',
      label: 'Texto do botão (Salvar/Avançar)',
      "span": 12,
      tableProps: {
        viewsTable: '',
        hidden: undefined,
        shortLabel: undefined,
        width: '12ch',
        filter: { type: 'TextFilter', delay: 500 },
        dataSort: true,
        dataAlign: 'left',
        columnFormat: undefined,
        isKey: undefined,
        exportFormat: undefined,
        rowSpan: undefined,
        sortFunc: undefined,
      },
      inputType: 'text',
      section: 'formsForm',
      inputProps: {
        domainName: undefined,
        filteredFrom: undefined,
        options: undefined,
        maxValue: undefined,
        minValue: undefined,
        maxSize: undefined,
        minSize: undefined,
        autoComplete: undefined,
        mask: undefined,
        removeMask: undefined,
      },
      relatedFields: undefined,
      validationMessage: undefined,
      pattern: undefined,
      placeholder: undefined,
      tip: undefined,
      // product: undefined,
      isMandatory: value => false,
      isReadOnly: undefined,
      isVisible: undefined,
      normalize: undefined,
      extraValidation: undefined,
      hookOnSave: undefined,
    },
    "pages.$.sections.$": {
      "$name": "Seção",
      "$span": 6,
      "$namePath": "pages.$.sections.$.title",
      "pages.$.sections.$.title": {
        path: 'pages.$.sections.$.title',
        dataType: 'text',
        label: 'Título da seção',
        "span": 12,
        tableProps: {
          viewsTable: '',
          hidden: undefined,
          shortLabel: undefined,
          width: '12ch',
          filter: { type: 'TextFilter', delay: 500 },
          dataSort: true,
          dataAlign: 'left',
          columnFormat: undefined,
          isKey: undefined,
          exportFormat: undefined,
          rowSpan: undefined,
          sortFunc: undefined,
        },
        inputType: 'text',
        section: 'formsForm',
        inputProps: {
          domainName: undefined,
          filteredFrom: undefined,
          options: undefined,
          maxValue: undefined,
          minValue: undefined,
          maxSize: undefined,
          minSize: undefined,
          autoComplete: undefined,
          mask: undefined,
          removeMask: undefined,
        },
        relatedFields: undefined,
        validationMessage: undefined,
        pattern: undefined,
        placeholder: undefined,
        tip: undefined,
        // product: undefined,
        isMandatory: value => false,
        isReadOnly: undefined,
        isVisible: undefined,
        normalize: undefined,
        extraValidation: undefined,
        hookOnSave: undefined,
      },
      "pages.$.sections.$.subTitle": {
        path: 'pages.$.sections.$.subTitle',
        dataType: 'text',
        label: 'Subtítulo da seção',
        "span": 12,
        tableProps: {
          viewsTable: '',
          hidden: undefined,
          shortLabel: undefined,
          width: '12ch',
          filter: { type: 'TextFilter', delay: 500 },
          dataSort: true,
          dataAlign: 'left',
          columnFormat: undefined,
          isKey: undefined,
          exportFormat: undefined,
          rowSpan: undefined,
          sortFunc: undefined,
        },
        inputType: 'text',
        section: 'formsForm',
        inputProps: {
          domainName: undefined,
          filteredFrom: undefined,
          options: undefined,
          maxValue: undefined,
          minValue: undefined,
          maxSize: undefined,
          minSize: undefined,
          autoComplete: undefined,
          mask: undefined,
          removeMask: undefined,
        },
        relatedFields: undefined,
        validationMessage: undefined,
        pattern: undefined,
        placeholder: undefined,
        tip: undefined,
        // product: undefined,
        isMandatory: value => false,
        isReadOnly: undefined,
        isVisible: undefined,
        normalize: undefined,
        extraValidation: undefined,
        hookOnSave: undefined,
      },
      "pages.$.sections.$.type": {
        path: 'pages.$.sections.$.type',
        dataType: 'text',
        label: 'Tipo de seção (usada para validação)',
        "span": 12,
        tableProps: {
          viewsTable: '',
          hidden: undefined,
          shortLabel: undefined,
          width: '12ch',
          filter: { type: 'TextFilter', delay: 500 },
          dataSort: true,
          dataAlign: 'left',
          columnFormat: undefined,
          isKey: undefined,
          exportFormat: undefined,
          rowSpan: undefined,
          sortFunc: undefined,
        },
        inputType: 'text',
        section: 'formsForm',
        inputProps: {
          domainName: undefined,
          filteredFrom: undefined,
          options: undefined,
          maxValue: undefined,
          minValue: undefined,
          maxSize: undefined,
          minSize: undefined,
          autoComplete: undefined,
          mask: undefined,
          removeMask: undefined,
        },
        relatedFields: undefined,
        validationMessage: undefined,
        pattern: undefined,
        placeholder: undefined,
        tip: undefined,
        // product: undefined,
        isMandatory: value => true,
        isReadOnly: undefined,
        isVisible: undefined,
        normalize: undefined,
        extraValidation: undefined,
        hookOnSave: undefined,
      },
      "pages.$.sections.$.component": {
        path: 'pages.$.sections.$.component',
        dataType: 'text',
        label: 'Componente Customizado',
        "span": 12,
        tableProps: {
          viewsTable: '',
          hidden: undefined,
          shortLabel: undefined,
          width: '12ch',
          filter: { type: 'TextFilter', delay: 500 },
          dataSort: true,
          dataAlign: 'left',
          columnFormat: undefined,
          isKey: undefined,
          exportFormat: undefined,
          rowSpan: undefined,
          sortFunc: undefined,
        },
        inputType: 'select',
        section: 'formsForm',
        inputProps: {
          // domainName: "form-actions",
          filteredFrom: undefined,
          options: CustomizedComponentsOptions,
          maxValue: undefined,
          minValue: undefined,
          maxSize: undefined,
          minSize: undefined,
          autoComplete: undefined,
          mask: undefined,
          removeMask: undefined,
        },
        relatedFields: undefined,
        validationMessage: undefined,
        pattern: undefined,
        placeholder: undefined,
        tip: undefined,
        // product: undefined,
        isMandatory: value => false,
        isReadOnly: undefined,
        isVisible: undefined,
        normalize: undefined,
        extraValidation: undefined,
        hookOnSave: undefined,
      },
      "pages.$.sections.$.extraValidation": {
        path: 'pages.$.sections.$.extraValidation',
        dataType: 'text',
        label: 'Validação extra da seção',
        "span": 12,
        tableProps: {
          viewsTable: '',
          hidden: undefined,
          shortLabel: undefined,
          width: '12ch',
          filter: { type: 'TextFilter', delay: 500 },
          dataSort: true,
          dataAlign: 'left',
          columnFormat: undefined,
          isKey: undefined,
          exportFormat: undefined,
          rowSpan: undefined,
          sortFunc: undefined,
        },
        inputType: 'select',
        section: 'formsForm',
        inputProps: {
          // domainName: "form-actions",
          filteredFrom: undefined,
          options: extraValidationsOptions,
          maxValue: undefined,
          minValue: undefined,
          maxSize: undefined,
          minSize: undefined,
          autoComplete: undefined,
          mask: undefined,
          removeMask: undefined,
        },
        relatedFields: undefined,
        validationMessage: undefined,
        pattern: undefined,
        placeholder: undefined,
        tip: undefined,
        // product: undefined,
        isMandatory: value => false,
        isReadOnly: undefined,
        isVisible: undefined,
        normalize: undefined,
        extraValidation: undefined,
        hookOnSave: undefined,
      },
      "pages.$.sections.$.hidden": {
        path: 'pages.$.sections.$.hidden',
        dataType: 'text',
        label: 'Seção é oculta quando',
        "span": 12,
        tableProps: {
          viewsTable: '',
          hidden: undefined,
          shortLabel: undefined,
          width: '12ch',
          filter: { type: 'TextFilter', delay: 500 },
          dataSort: true,
          dataAlign: 'left',
          columnFormat: undefined,
          isKey: undefined,
          exportFormat: undefined,
          rowSpan: undefined,
          sortFunc: undefined,
        },
        inputType: 'select',
        section: 'formsForm',
        inputProps: {
          // domainName: "form-actions",
          filteredFrom: undefined,
          options: HiddenSectionOptions,
          maxValue: undefined,
          minValue: undefined,
          maxSize: undefined,
          minSize: undefined,
          autoComplete: undefined,
          mask: undefined,
          removeMask: undefined,
        },
        relatedFields: undefined,
        validationMessage: undefined,
        pattern: undefined,
        placeholder: undefined,
        tip: undefined,
        // product: undefined,
        isMandatory: value => false,
        isReadOnly: undefined,
        isVisible: undefined,
        normalize: undefined,
        extraValidation: undefined,
        hookOnSave: undefined,
      },
      "pages.$.sections.$.fields": {
        "path": "pages.$.sections.$.fields",
        "label": "Campos da application",
        "section": "formsForm",
        "dataType": "string",
        "inputType": "multiselect-autocomplete",
        "inputProps": {
          "domainName": "parati.fields",
          "mask": undefined,
          "removeMask": undefined
        },
        "isMandatory": (value) => false,
      },
    }
  }
}
export const FormColumns: Meta = {
  "product": {
    "path": "product",
    "label": "Product",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "created": {
    "path": "created",
    "label": "Criado em",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": () => false,
  },
  "updated": {
    "path": "updated",
    "label": "Atualizado em",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": () => false,
  },
  "linkPath": {
    "path": "linkPath",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },

}
