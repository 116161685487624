import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import '../../fz/style/menu.css'


export class FzMenuAffiliate extends React.Component<any, any>   {

  constructor(props) {
    super(props)

    this.state = { route_selected: false }
    this.goToRoute = this.goToRoute.bind(this)
  }

  goToRoute(route, name) {
    const { history } = this.props
    this.setState({ route_selected: name }, () => history.push(`/${route}`))
  }

  render() {

    return (
      <div>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem button onClick={() => this.goToRoute("affiliate", "route_affiliate")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_affiliate",
          })}>
            <ListItemText className={"item-menu"} primary="Estatísticas" />
          </ListItem>

          <ListItem button onClick={() => this.goToRoute("about", "route_about")} className={clsx("", {
            ["fz-route-selected"]: this.state.route_selected === "route_about"
          })}>
            <ListItemText className={"item-menu"} primary="Sobre" />
          </ListItem>
        </List>
      </div>
    )
  }
}
