import { clientService } from "../../../api/axios-client-api";
import { ParamsType, ResponseAxiosForAnalytics } from "./types";
import qs from 'qs'
const apiV3 = clientService.api.v3


export const getDeletedCutomersReport = async (
  filter: ParamsType
): Promise<ResponseAxiosForAnalytics> => {
  return apiV3.Get(`audit/deleted-customers?${qs.stringify(filter)}`);
}
