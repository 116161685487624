
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

type FzOption = {

  chart: {
    type: string
  },
  title: {
    text: string
  },
  xAxis: {
    categories: [string]
  },
  yAxis: [{
    min: number,
    title: {
      text: string,
    }
  }, {
    title: {
      text: string,
    },
    opposite: boolean,
  }],
  legend: {
    shadow: boolean,
  },
  tooltip: {
    shared: boolean,
  },
  plotOptions: {
    column: {
      grouping: boolean,
      shadow: boolean,
      borderWidth: number,
    }
  },
  series: [{
    name: string,
    color: string,
    data: [number],
    pointPadding: number,
    pointPlacement: number,
  }, {
    name: string,
    color: string,
    data: [number],
    pointPadding: number,
    pointPlacement: number,
  }, {
    name: string,
    color: string,
    data: [number],
    tooltip: {
      valuePrefix: string,
      valueSuffix: string,
    },
    pointPadding: number,
    pointPlacement: number,
    yAxis: number
  }, {
    name: string,
    color: string,
    data: [number],
    tooltip: {
      valuePrefix: string,
      valueSuffix: string,
    },
    pointPadding: number,
    pointPlacement: number,
    yAxis: number
  }, {
    name: string,
    color: string,
    data: [number],
    tooltip: {
      valuePrefix: string,
      valueSuffix: string,
    },
    pointPadding: number,
    pointPlacement: number,
    yAxis: number
  }]

}


const metaBarOverlap = {
  chart: {
    type: 'column'
  },
  title: {
    text: 'Funnel Operational Partners'
  },
  xAxis: {
    categories: []
  },
  yAxis: [{
    min: 0,
    title: {
      text: 'Valores'
    }
  }, {
    opposite: true
  }],
  legend: {
    shadow: false
  },
  tooltip: {
    shared: true
  },
  plotOptions: {
    column: {
      grouping: false,
      shadow: false,
      borderWidth: 0
    }
  },
  series: [
    {
      name: 'Eligible',
      color: 'rgb(5 188 180)',
      data: [150, 73, 20],
      tooltip: {
        valueSuffix: ' %'
      },
      pointPadding: 0.3,
      pointPlacement: 0.0,
    }, {
      name: 'Bidded',
      color: 'rgb(252 196 67)',
      data: [140, 90, 40],
      tooltip: {
        valueSuffix: ' %'
      },
      pointPadding: 0.3,
      pointPlacement: 0.0,
    }, {
      name: 'Accepted',
      color: 'rgb(179 206 71)',
      data: [183.6, 178.8, 198.5],
      tooltip: {
        valueSuffix: ' %'
      },
      pointPadding: 0.3,
      pointPlacement: 0.0,
    }, {
      name: 'Paid (Supplier)',
      color: 'rgb(88 34 221)',
      data: [203.6, 198.8, 208.5],
      tooltip: {
        valueSuffix: ' %'
      },
      pointPadding: 0.3,
      pointPlacement: 0.0,
    }, {
      name: 'Paid (Others)',
      color: 'rgba( 0 109 167)',
      data: [203.6, 198.8, 208.5],
      tooltip: {
        valueSuffix: ' %'
      },
      pointPadding: 0.3,
      pointPlacement: 0.0,
    }
  ]
}


type FzBarProps = {
  options: FzOption,
}


class FzOverlaidBar extends React.Component<FzBarProps, any> {

  render() {
    const { options } = this.props

    return (
      <div style={{width:"100%"}}>
        {options ? (<HighchartsReact
          highcharts={Highcharts}
          options={options} />) : null}
      </div>

    )
  }

}
export { metaBarOverlap, FzOverlaidBar }
