
import { clientService } from "../../../api/axios-client-api";
import {
  responseAxiosForSummary,
  responseAxiosForAnalytics
} from "./type";

const hsApi = clientService.hsApi.v1
const misApi = clientService.misApi.v1

export const getAnalyticsHSReports = async (
  from: string,
  to: string): Promise<responseAxiosForAnalytics> => {
  return hsApi.Get('reports/analytics',{from,to});
}

export const getValidatorsHSReports = async ( period: string ): Promise<responseAxiosForSummary> => {
  return await misApi.Get(`validators/hs/${period}`)
}
