import { result } from 'lodash'
import resizeImage from 'resize-image'

interface FileI {
  content: string;
  fileName: string;
  mimeType: string; 
}

const removeMimeType = (fileType) => (
  fileType.replace(/^data:(image|application)\/(jpeg|png|pdf);base64,/, "")
)

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

const imageToBase64 = async file => {
  const content = await toBase64(file)
  return new Promise((resolve, reject) => {
    const image = new Image()
    const srcImage: any = content
    image.src = srcImage
    
    image.onload = () => {
      const dMin = 1000
      let factor = 1
      const naturalHeight = result(image, ['naturalHeight'], 100)
      const naturalWidth = result(image, ['naturalWidth'], 100)

      const shorterSide = (
        naturalWidth < naturalHeight 
          ? naturalWidth 
          : naturalHeight
      )

      if (shorterSide > dMin) {
        factor = shorterSide / dMin
      }

      resolve(removeMimeType(resizeImage.resize(
        image,
        naturalWidth / factor, 
        naturalHeight / factor, 
        "jpeg"
      )))
    }
    image.onerror = error => reject(error)
  })
}

const formatterFile = async (event) => {
    const file = result(event, ['target', 'files', 0], null)
    const fileType = result(file, ['type'], null)
    const fileName = result(file, ['name'], null)
    let content: any
    let data: FileI = {
      content: '',
      fileName,
      mimeType: fileType
    }
    
    if (["image/png", "image/jpeg"].indexOf(fileType) >= 0) {
      content = await imageToBase64(file)
      return ({
        ...data,
        content,
      })
    }

    content = await toBase64(file)
    return ({
      ...data,
      content: removeMimeType(content)
    })
  }

export {
  toBase64,
  formatterFile,
  imageToBase64,
  removeMimeType,
}

