import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { getCallsPerAgent } from "../../../api/crm";
import { setTTL } from "../../../business/misc-functions";
import { FzNavSelect } from "../../fz/nav";
import { FzRow, FzCol, FzSplitLine } from "../../fz/layout/index";
import { FzCard } from "../../fz/layout/FzCard";
import FieldConnected from '../../components/form/field-connected';
import { FzButton } from '../../fz/form';
import { users } from "../../../biz/metadatas/users";
import { getUsers } from "../../../api/users";
import clone from 'lodash/clone'
import { FzPieChart } from '../../fz/charts/pie'
import { FzBarChart } from '../../fz/charts/bar'
import api from '../../../api/client-api'
import { StatsDetailHeader } from '../../../statistics/stats-components';

interface CrmContactsPerAgentChartsProps {
  ns: any;
  toggleBlocking: (
    callback?: any
  ) => void;
}

export function CrmContactsPerAgentCharts({ ns, toggleBlocking }: CrmContactsPerAgentChartsProps) {

  const [requestState, setRequestState] = useState("idle")
  const [dataPiePageBar, setDataPiePageBar] = useState<any>([])
  const [dataPiePage, setDataPiePage] = useState<any>([])
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({ period: "day" })

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
    ns.startEditing();
    loadUser()
  }, [])

  async function loadUser() {
    const currentUser = api.getCurrentUser()
    let users
    if (currentUser.role === "advisor") {
      users = [currentUser]
    } else {
      users = await getUsers("advisor");
    }
    ns.set("users", users)
  }

  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  async function getStatistics() {
    try {
      setRequestState("requesting")
      toggleBlocking();
      const contactsAgentResults = await getCallsPerAgent(getFilters());
      ns.set("contactsAgent", contactsAgentResults, setTTL(5));
      toggleBlocking();
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      toggleBlocking()
    }
  }


  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
    getStatistics()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  function validateDataAndAdvisor(rawData) {
    const user = ns.getChanged("user");
    const users = ns.get("users");

    const selectedUser = users.find(it => it.id === user);

    const data = rawData.statistics
    const listOperatorsNames = [selectedUser.name]

    return { listOperatorsNames, data };
  }

  function buildDataBar(rawData) {

    const { listOperatorsNames, data } = validateDataAndAdvisor(rawData);
    const deduplicatedListQueue: string[] = Array.from(new Set(data.map(it => it.queue)))

    const processedData = listOperatorsNames.map(agentName => {

      const operatorPre_processing = deduplicatedListQueue.reduce((acumulador: any, current) => {

        let finishContact = 0;
        let closeNoContact = 0;
        let started = 0;
        let locked = 0;

        for (let i = 0; i < data.length; i++) {
          if (data[i].queue === current && data[i].outcome === "finish-contact") {
            finishContact = finishContact + data[i].quantity;
          }
          if (data[i].queue === current && data[i].outcome === "close-no-contact") {
            closeNoContact = closeNoContact + data[i].quantity;
          }
          if (data[i].queue === current && data[i].outcome === "started") {
            started = started + data[i].quantity;
          }
          if (data[i].queue === current && data[i].outcome === "locked") {
            locked = locked + + data[i].quantity;
          }
        }

        let customData = {
          "name": current,
          'Atendido com sucesso': finishContact,
          'Não consegui falar': closeNoContact,
          'Sem tratativa': started,
          'Atendido pelo time': locked,
          "Total": finishContact + closeNoContact + started + locked
        }

        return [
          ...acumulador,
          customData
        ]
      }, [])

      return {
        agentName,
        data: operatorPre_processing
      }
    })

    setDataPiePageBar(processedData)
    return processedData
  }

  function buildDataPie(rawData) {
    const { listOperatorsNames, data } = validateDataAndAdvisor(rawData);

    const processedData = listOperatorsNames.map(agentName => {
      const finishContact = data.filter((it) => agentName === it.agent && it.outcome === "finish-contact")
        .reduce((acumulador, current) => {
          return acumulador + current.quantity
        }, 0)
      const closeNoContact = data.filter((it) => agentName === it.agent && it.outcome === "close-no-contact")
        .reduce((acumulador, current) => {
          return acumulador + current.quantity
        }, 0)

      const started = data.filter((it) => agentName === it.agent && it.outcome === "started")
        .reduce((acumulador, current) => {
          return acumulador + current.quantity
        }, 0)
      const locked = data.filter((it) => agentName === it.agent && it.outcome === "locked")
        .reduce((acumulador, current) => {
          return acumulador + current.quantity
        }, 0)

      return {
        agentName,
        data: [
          { name: 'Atendido com sucesso', value: finishContact },
          { name: 'Não consegui falar', value: closeNoContact },
          { name: 'Sem tratativa', value: started },
          { name: 'Atendido pelo time', value: locked },
        ]
      }
    })

    setDataPiePage(processedData)
    return processedData
  }

  const userMeta = clone(users.name);
  userMeta.inputProps.options = (ns.get("users") || []).map(it => ({

    code: it.id,
    description: it.email
  }));


  let data = ns.get("contactsAgent");
  if (!data && requestState === "requesting") {
    return null
  }

  const barChart = dataPiePageBar.map(it => {
    return <FzBarChart agent={it.agentName} data={it.data} />
  })

  const pieChart = dataPiePage.map(it => {
    return <FzPieChart agent={it.agentName} data={it.data} />
  })

  return (
    <div>
      <FzCard>
        <FzCard.Header>
          <FzCard.Title>
            <FzSplitLine>
              <FzSplitLine.Left></FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzNavSelect
                  value={filters.period}
                  options={[
                    { "code": "day", "description": "Daily" },
                    { "code": "week", "description": "Weekly" },
                    { "code": "month", "description": "Monthly" },
                  ]}
                  onChange={(value) => {
                    setFilters({ ...filters, period: value })
                    getStatistics()
                  }}
                />
              </FzSplitLine.Right>
            </FzSplitLine>

            <FzSplitLine>


              <FzSplitLine.Left>
                <FieldConnected ns={ns} meta={{ ...userMeta, path: "user", inputType: "select" }} data={data} />
              </FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzButton
                  disabled={!data || !ns.getChanged("user")}
                  fzStyle="regular" onClick={(e) => {
                    buildDataBar(data)
                    buildDataPie(data)
                  }}>Buscar</FzButton>
              </FzSplitLine.Right>
            </FzSplitLine>

            <div className="mt-3">
              <StatsDetailHeader
                title={'Crm Contacts Per Agent Charts'}
                periods={undefined}
                currPeriod={undefined}
                onChange={undefined}
                stats={data}
              />
            </div>
          </FzCard.Title>
        </FzCard.Header>

      </FzCard>
      <FzRow>
        <FzCol span={6}>
          {barChart}
        </FzCol>
        <FzCol span={6}>
          {pieChart}
        </FzCol>
      </FzRow>
    </div>
  );

}

