import { Product } from "../../../../../../biz/metadatas/product"
import { FieldMetaPartner } from "../field-meta-partner"

export function ProductParameters(props) {
  const metaFields = Product.getFieldBySection("parameters-product")
  return metaFields.map((meta, index)=> {
    let inputProps = {
      ...props,
      meta
    }
    return <FieldMetaPartner key={index} {...inputProps}/>
  })
}