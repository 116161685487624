import React, { useState, ReactNode } from 'react';
import { FzCard } from '../../ui/fz/layout/FzCard';
import { FzNavSelect } from '../../ui/fz/nav';
import { Analytics } from './analytics';
import { Summaries } from './summaries';
import { HSReportsContainerProps } from './types';

function HSReportsContainer({ 
  handlePage, 
  typeView, 
  ns,
  role,
  filters,
  data,
  handleSearchReport,
  handlePeriodSummary,
}: HSReportsContainerProps) {

  const contents = {
    analytics: (
      <Analytics 
        filters={filters}  
        role={role} 
        ns={ns} 
        data={data[typeView]} 
        handleSearchReport={handleSearchReport} 
      />
    ),
    summaries: ( 
      <Summaries
        ns={ns} 
        data={data[typeView]} 
        handleSearchReport={handleSearchReport} 
        handlePeriodSummary={handlePeriodSummary}
      />
    ),
  }

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={handlePage}
        />
      </div>
      <br></br>
      <FzCard fzStyle={{ margin: 2, padding: 4 }}>
        { contents[typeView] || 'Aguardando a seleção do tipo de relatório' }
      </FzCard>
    </>
  )
}

export { HSReportsContainer }
