export const revenue = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "50",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "leads": {
    "path": "leads",
    "dataType": "number",
    "label": "Leads",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "auctions": {
    "path": "auctions",
    "dataType": "number",
    "label": "Send to bank",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "receivedOneBid": {
    "path": "receivedOneBid",
    "dataType": "number",
    "label": "Received at least 1 bid",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "acceptedOneBid": {
    "path": "acceptedOneBid",
    "dataType": "number",
    "label": "Accepted at least 1 bid",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "paid": {
    "path": "paid",
    "dataType": "number",
    "label": "Paids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "supplier": {
    "path": "supplier",
    "dataType": "text",
    "label": "Supplier",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "nbAcceptedBids": {
    "path": "nbAcceptedBids",
    "dataType": "dataType",
    "label": "Accepted Bids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "totalAcceptedAmount": {
    "path": "totalAcceptedAmount",
    "dataType": "dataType",
    "label": "Total Accepted Amount",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "nbPaid": {
    "path": "nbPaid",
    "dataType": "dataType",
    "label": "Paid Bids",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "totalPaidAmount": {
    "path": "totalPaidAmount",
    "dataType": "number",
    "label": "Total Paid Amount",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}
