import { clientService } from "../../../api/axios-client-api"
import { UpsellBanner, Pathname, Filter, Form } from "./type"
import qs from 'qs'

const doryApi = clientService.doryApi.v1

export const GetAllUpsellBanners = async (query = {}): Promise<any> => {
  const serviceUrl = `upsell?${qs.stringify(query)}`;
  return doryApi.Get(serviceUrl);
}

export const GetIdUpsellBanner = async (id: string): Promise<any> => {
  const serviceUrl = `upsell/${id}`;
  return doryApi.Get(serviceUrl);
}

export const PostIdUpsellBanner = async (data: UpsellBanner): Promise<any> => {
  const serviceUrl = 'upsell';
  return doryApi.Post(serviceUrl, data);
}

export const PutUpsellBanner = async (id: string, data: UpsellBanner): Promise<any> => {
  const serviceUrl = `upsell/${id}`;
  return doryApi.Put(serviceUrl, data);
}

export const DeleteUpsellBanner = async (id: string): Promise<any> => {
  const serviceUrl = `upsell/${id}`;
  return doryApi.Delete(serviceUrl);
}

export const GetAllPathname = async (): Promise<any> => {
  const serviceUrl = 'paths';
  return doryApi.Get(serviceUrl);
}

export const GetIdPathname = async (id: string): Promise<any> => {
  const serviceUrl = `paths/${id}`;
  return doryApi.Get(serviceUrl);
}

export const PostPathname = async (data: Pathname): Promise<any> => {
  const serviceUrl = 'paths';
  return doryApi.Post(serviceUrl, data);
}

export const PutPathname = async (id: string, data: Pathname): Promise<any> => {
  const serviceUrl = `paths/${id}`;
  return doryApi.Put(serviceUrl, data);
}

export const DeletePathname = async (id: string): Promise<any> => {
  const serviceUrl = `paths/${id}`;
  return doryApi.Delete(serviceUrl);
}

export const GetAllForm = async (): Promise<any> => {
  const serviceUrl = 'forms';
  return doryApi.Get(serviceUrl);
}

export const GetIdForm = async (id: string): Promise<any> => {
  const serviceUrl = `forms/${id}`;
  return doryApi.Get(serviceUrl);
}

export const PostForm = async (data: Form): Promise<any> => {
  const serviceUrl = 'forms';
  return doryApi.Post(serviceUrl, data);
}

export const PutForm = async (id: string, data: Form): Promise<any> => {
  const serviceUrl = `forms/${id}`;
  return doryApi.Put(serviceUrl, data);
}

export const DeleteForm = async (id: string): Promise<any> => {
  const serviceUrl = `forms/${id}`;
  return doryApi.Delete(serviceUrl);
}

export const GetAllFilter = async (): Promise<any> => {
  const serviceUrl = 'filters';
  return doryApi.Get(serviceUrl);
}

export const GetIdFilter = async (id: string): Promise<any> => {
  const serviceUrl = `filters/${id}`;
  return doryApi.Get(serviceUrl);
}

export const PostFilter = async (data: Filter): Promise<any> => {
  const serviceUrl = 'filters';
  return doryApi.Post(serviceUrl, data);
}

export const PutFilter = async (id: string, data: Filter): Promise<any> => {
  const serviceUrl = `filters/${id}`;
  return doryApi.Put(serviceUrl, data);
}

export const DeleteFilter = async (id: string): Promise<any> => {
  const serviceUrl = `filters/${id}`;
  return doryApi.Delete(serviceUrl);
}
