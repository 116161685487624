import React from 'react';
import {FormattedRelative, FormattedNumber} from 'react-intl';
import {ProductName} from '../application-components/input-domains';
import { FzCard, FzCol, FzRow } from '../ui/fz/layout/index';
import { FzForm } from '../ui/fz/layout/index';

const ApplicationSummary = ({api,  application }) => {
    if (application === undefined) {
        return null;
    }
    let amount = application.appliedAmount;
    if (amount === 0) {
        if (application.vehicleSecurity) {
            amount = application.vehicleSecurity.value - application.vehicleSecurity.downPayment;
        }
    }
    return(
        <FzCard>
            <FzCard.Heading>
                <FzCard.Title componentClass="h3">Detalhes do Pedido</FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
                <FzRow>
                    <FzCol span={6}>
                        <FzForm.Label>Valor</FzForm.Label>
                            <h4><FormattedNumber value={amount} currency="BRL" /></h4>
                    </FzCol>
                    <FzCol span={6}>
                        <FzForm.Label>Produto</FzForm.Label>
                        <ProductName product={application.product} />
                    </FzCol>
                </FzRow>
                <FzForm.Label>Finaliza propostas em</FzForm.Label>
                <FzForm.Static>
                    <FormattedRelative value={application.auction.endDate} />
                </FzForm.Static>
            </FzCard.Body>
        </FzCard>
    );
}

export default ApplicationSummary;
