import React, { ReactNode, ReactElement } from 'react';
import { Card, CardProps } from 'react-bootstrap';
import CardGroup from 'react-bootstrap/CardGroup'

interface FzCardProps extends CardProps {
  children: ReactNode;
  fzStyle?: any;
}
interface FzCardGroupProps {
  children: ReactNode;
}
interface FzPropsCard extends CardProps {
  as?: any;
  bsPrefix?: string;
  fzStyle?: any;
  className?: string;
  children?: ReactNode;
}

export function FzCard({ children, fzStyle }: FzCardProps) {
  return (
    <Card style={fzStyle} >
      {children}
    </Card>
  )
}

export function FzCardGroup({ children }: FzCardGroupProps) {
  return (
    <CardGroup >
      {children}
    </CardGroup>
  )
}


FzCard.Header = ({ as, bsPrefix, children, fzStyle, className }: FzPropsCard) => {
  return (
    <Card.Header style={fzStyle} as={as} bsprefix={bsPrefix} className={className}>
      {children}
    </Card.Header>
  )
};

FzCard.Body = ({ as, bsPrefix, children, fzStyle, className }: FzPropsCard) => {
  return (
    <Card.Body style={fzStyle} as={as} bsprefix={bsPrefix} className={className} >
      {children}
    </Card.Body>
  );
};

FzCard.Footer = ({ as, bsPrefix, children, fzStyle, className }: FzPropsCard) => {
  return (
    <Card.Footer style={fzStyle} as={as} bsprefix={bsPrefix} className={className}>
      {children}
    </Card.Footer>
  )
};

FzCard.Title = ({ as, bsPrefix, children, fzStyle, className }: FzPropsCard) => {
  return (
    <Card.Title style={fzStyle} as={as} bsprefix={bsPrefix} className={className}>
      {children}
    </Card.Title>
  );
};

FzCard.Text = ({ as, bsPrefix, children, fzStyle, className }: FzPropsCard) => {
  return (
    <Card.Text style={fzStyle} as={as} bsprefix={bsPrefix} className={className}>
      {children}
    </Card.Text>
  );
};

FzCard.Subtitle = ({ as, bsPrefix, children, fzStyle, className }: FzPropsCard) => {
  return (
    <Card.Subtitle style={fzStyle} as={as} bsprefix={bsPrefix} className={className}>
      {children}
    </Card.Subtitle>
  );
};


