import moment from "moment";
import { Meta } from "../../../../types/metas";


export const analyticsColumns: Meta = {

  "applicationId": {
    "path": "applicationId",
    "label": "Proposta",
    "dataType": "number",
    "tableProps": {
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "8rem",
      "dataSort": false,
      'expandable': false,
      "columnFormat": "applicationIdColumn",
    },
    "isMandatory": () => false
  },
  "updated": {
    "path": "updated",
    "label": "Data de Alteração",
    "tableProps": {
      "width": "8.5rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "periodWithTimeColumn"
    },
    "isMandatory": () => false
  },
  "fields": {
    "path": "fields",
    "label": "Campo alterado",
    "tableProps": {
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "12.5rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "breakSpaces"
    },
    "isMandatory": () => false
  },
  "old": {
    "path": "old",
    "label": "Antes",
    "tableProps": {
      "width": "12.5rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "breakSpaces"
    },
    "isMandatory": () => false
  },
  "new": {
    "path": "new",
    "label": "Depois",
    "tableProps": {
      "width": "12.5rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "breakSpaces"
    },
    "isMandatory": () => false
  },
  "screenerName": {
    "path": "screenerName",
    "label": "Screener",
    "tableProps": {
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "tableProps": {
      "width": "12rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
    "isMandatory": () => false
  },
  "screenedCount": {
    "path": "screenedCount",
    "label": "Nª de passagens",
    "tableProps": {
      "width": "8rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "applicationState": {
    "path": "applicationState",
    "label": "Status da proposta",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "applicationState",
      "filter": {
        type: 'SelectFilter',
        options: "buildColumnApplicationState"
      }
    },
    "isMandatory": () => false
  },
  "approvedAmount": {
    "path": "approvedAmount",
    "label": "Valor do Bid",
    "dataType": "number",
    "inputType": "number",
    "tableProps": {
      "width": "6.5rem",
      "columnFormat": "amountColumn",

    },
    "isMandatory": () => false
  },
}


function normalizeDate(value) {
  return typeof (value) === "object" && value !== null
    ? value.toISOString()
    : value
}


export const analyticsFilter: Meta = {
  "from": {
    "path": "from",
    "label": "De",
    // "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "isMandatory": (application) => false,
    normalize: normalizeDate
  },
  "to": {
    "path": "to",
    "label": "Até",
    // "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "isMandatory": (application) => false,
    normalize: normalizeDate
  }
}


export const testeColumns: Meta = {
  "id": {
    "path": "id",
    "label": "id",
    "tableProps": {
      "isKey": true,
      "hidden": true,
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "status": {
    "path": "status",
    "label": "Status do documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "reasons": {
    "path": "reasons",
    "label": "Motivo de pend ou recusa",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  }
}
