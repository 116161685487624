import moment from "moment";
import { Meta } from "../../../types/metas";


export const analyticsColumns: Meta = {

  "applicationId": {
    "path": "applicationId",
    "label": "App Id",
    "tableProps": {
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "tableProps": {
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "tableProps": {
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "created": {
    "path": "created",
    "label": "Entrada na HS",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "periodWithTimeColumn"
    },
    "isMandatory": () => false
  },
  "updated": {
    "path": "updated",
    "label": "Saida da HS",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "periodWithTimeColumn"
    },
    "isMandatory": () => false
  },
  "lastPassageTimeSpanInMiliseconds": {
    "path": "lastPassageTimeSpanInMiliseconds",
    "label": "Tempo de validação da HS",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      'columnFormat': 'formatMinutesSeconds'
    },
    "isMandatory": () => false
  },
  "amountOfPassages": {
    "path": "amountOfPassages",
    "label": "Passagens pela HS",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "telephoneNumber": {
    "path": "telephoneNumber",
    "label": "Telefone",
    "tableProps": {
      "width": "9rem",
      "hidden": true,
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "docType": {
    "path": "docType",
    "label": "Tipo de Documento",
    "tableProps": {
      "width": "9rem",
      "hidden": true,
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "docTypeValue": {
    "path": "docTypeValue",
    "label": "Valor de Documento",
    "tableProps": {
      "width": "9rem",
      "hidden": true,
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  }, 
  "email": {
    "path": "email",
    "label": "Email",
    "tableProps": {
      "width": "9rem",
      "hidden": true,
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "status": {
    "path": "status",
    "label": "Status",
    "tableProps": {
      "width": "9rem",
      "hidden": true,
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },      
}


const normalizeDate = (value) => (
  typeof (value) === "object" && value !== null
    ? value.toISOString()
    : value
)

export const analyticsFilter: Meta = {
  from: {
    path: 'from',
    label: 'De:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      maxValue: moment()
        .add('year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      minValue: moment()
        .add(-70, 'year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    },
    isMandatory: () => false,
  },
  to: {
    path: 'to',
    label: 'Até:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      maxValue: moment()
        .add('year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      minValue: moment()
        .add(-70, 'year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    },
    isMandatory: () => false,
  }
}


export const testeColumns: Meta = {
  "id": {
    "path": "id",
    "label": "id",
    "tableProps": {
      "isKey": true,
      "hidden": true,
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "status": {
    "path": "status",
    "label": "Status do documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "reasons": {
    "path": "reasons",
    "label": "Motivo de pend ou recusa",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  }
}

