import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import api from '../../api/client-api';
import copy from 'copy-to-clipboard';
import {
  getSQSTriggers,
  getApplicationTraces,
  makeReprocessing,
  getQueue,
  integrationAgentCallback,
  postIntegrationAgentCallback,
} from '../../api/integrations';
import get from 'lodash/get';
import _ from 'lodash';
import { FzTable } from '../fz/grid/table';
import { FzButton } from '../fz/form';
import { FzIcons } from '../fz/form/icon';
import { agentQueueAnalytic } from '../../biz/metadatas/integration-metadata';
import { FzCard, FzBadge } from '../fz/layout/index';
import { triggers_colors } from '../fz/formatter/column-formatter';
import '../fz/style/legend.css';
import ReactJson from 'react-json-view';

export class QueueDetails extends React.Component {
  render() {
    const { queue } = this.props;

    return (
      <FzCard>
        <FzCard.Body>
          {queue ? (
            <ReactJson
              displayDataTypes={false}
              src={queue}
              style={{ overflowWrap: 'anywhere' }}
            />
          ) : (
            'Nenhum objeto encontrado'
          )}
        </FzCard.Body>
      </FzCard>
    );
  }
}

export class TraceModal extends React.Component {
  render() {
    let traceContent;
    if (this.props.traceContent) {
      if (typeof this.props.traceContent === 'object') {
        traceContent = this.props.traceContent;
      }
    }

    return (
      <Modal
        style={{ marginTop: '50px' }}
        bsSize="large"
        size="lg"
        show={this.props.show}
        onHide={() => (this.props.onHide ? this.props.onHide() : undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Trace Detalhado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {traceContent ? (
            <ReactJson
              shouldCollapse={({ name }) =>
                name === 'data_received' || name === 'data_sent'
              }
              displayDataTypes={false}
              src={this.props.traceContent}
              style={{ overflowWrap: "anywhere" }}
            />
          ) : (
            'Nenhum trace foi gerado para essa fila'
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export class ReprocessingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userCode: '', confirmation: false, detalhes: false };
    this.detailQueue = this.detailQueue.bind(this);
    this.reprocessingAction = this.reprocessingAction.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }

  detailQueue() {
    if (this.state.detalhes === false) {
      return {
        _id: this.props.queueContent[0]._id,
        queue: this.props.queueContent[0].queue,
        active: this.props.queueContent[0].active,
        readyTime: this.props.queueContent[0].readyTime,
        created: this.props.queueContent[0].created,
        updated: this.props.queueContent[0].updated,
        applicationId: this.props.queueContent[0].applicationId,
        attempts: this.props.queueContent[0].attempts,
        maxAttempts: this.props.queueContent[0].maxAttempts,
        locked: this.props.queueContent[0].locked,
        timedOut: this.props.queueContent[0].timedOut,
        canceled: this.props.queueContent[0].canceled,
        reprocessed: this.props.queueContent[0].reprocessed
          ? this.props.queueContent[0].reprocessed
          : '',
      };
    } else {
      return this.props.queueContent[0];
    }
  }

  async reprocessingAction() {
    try {
      await makeReprocessing(
        this.props.repId,
        this.props.repAgent,
        this.props.active
      );
      this.setState({ confirmation: true });
    } catch (error) {
      console.warn('API ERROR', error);
    }
  }

  showDetails() {
    this.setState({ detalhes: !this.state.detalhes });
  }

  render() {
    let queueContent = '';
    const buttonText = this.state.detalhes ? 'Menos Detalhes' : 'Mais Detalhes';
    if (this.props.queueContent) {
      queueContent = JSON.stringify(this.detailQueue(), null, 2);
    } else {
      queueContent = 'Nenhum queue registrado';
    }
    let buttonAction = this.props.active ? (
      <FzButton
        fzStyle="alert"
        style={{ display: 'block' }}
        onClick={() => this.reprocessingAction()}
      >
        Parar Reprocessamento
      </FzButton>
    ) : (
      <FzButton
        fzStyle="regular"
        style={{ display: 'block' }}
        onClick={() => this.reprocessingAction()}
      >
        Confirmar Reprocessamento
      </FzButton>
    );
    let actionText = this.props.active
      ? 'Deseja parar o reprocessamento da fila abaixo?'
      : 'Deseja realizar o reprocessamento da fila abaixo?';
    let responseText = this.props.active
      ? 'Reprocessamento interrompido!'
      : 'Reprocessamento realizado!';
    return (
      <Modal
        style={{ marginTop: '50px' }}
        bsSize="large"
        show={this.props.show}
        onHide={() => {
          this.props.onHide ? this.props.onHide() : undefined;
          this.setState({ confirmation: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reprocess Queue</Modal.Title>
        </Modal.Header>
        {!this.state.confirmation && (
          <Modal.Body>
            <div>
              <pre>{actionText}</pre>
            </div>
            {buttonAction}
            <div>
              <pre>{queueContent}</pre>
            </div>
            <Button
              variant="primary"
              bsSize="small"
              style={{ display: 'block' }}
              onClick={() => this.showDetails()}
            >
              {buttonText}
            </Button>
          </Modal.Body>
        )}
        {this.state.confirmation && (
          <Modal.Body>
            <div>
              <pre>{responseText}</pre>
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

class LegendTable extends React.Component {
  constructor(props) {
    super(props);
  }
  renderTableData(sqsArrays) {
    return sqsArrays.map((sqsTrigger, rowIndex) => (
      <tr key={rowIndex}>
        {sqsTrigger.map((trigger, columnIndex) => (
          <td
            key={columnIndex}
            style={{ backgroundColor: triggers_colors[trigger] }}
          >
            {trigger}
          </td>
        ))}
      </tr>
    ));
  }

  splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  MakeDivisble(arr, n) {
    if (arr.length % n === 0) {
      return arr;
    } else {
      while (true) {
        arr = arr.concat('none');
        if (arr.length % n === 0) {
          return arr;
        }
      }
    }
  }

  render() {
    let agentName = _.capitalize(this.props.agentName);
    let divisibleArrays = this.MakeDivisble(
      this.props.sqsTriggers,
      this.props.nRows
    );
    let sqsArrays = this.splitArrayIntoChunksOfLen(
      divisibleArrays,
      this.props.nRows
    );

    return (
      <div>
        <table className="legend-table">
          <tr id="legend-header">
            <th colSpan={this.props.nRows.toString()}>
              Legenda (SQS Triggers): {agentName}
            </th>
          </tr>
          {this.renderTableData(sqsArrays)}
        </table>
      </div>
    );
  }
}

export class QueueList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTrace: false,
      showLegend: false,
      showReprocessing: false,
      sqstriggers: [],
    };
    this.activeCol = this.activeCol.bind(this);
    this.attemptsColumn = this.attemptsColumn.bind(this);
    this.actionsColumn = this.actionsColumn.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sqstriggers === this.state.sqstriggers) {
      this.setState({ showLegend: false, sqstriggers: [] });
    }
  }

  activeCol(cell, row) {
    if (row.canceled) {
      return <FzBadge fzStyle="alert">canceled</FzBadge>;
    }
    return (
      <FzBadge fzStyle={cell ? 'success' : 'default'}>
        {cell.toString()}
      </FzBadge>
    );
  }

  attemptsColumn(cell, row) {
    let text = cell + ' / ' + row.maxAttempts;
    if (cell === row.maxAttempts) {
      return <FzBadge fzStyle="attention">{text}</FzBadge>;
    }
    return text;
  }

  actionsColumn(cell, row, type) {
    const { agentName } = this.props;
    if (api.isITUser()) {
      let buttons = [];
      let k = 0;
      if (type == 'trace') {
        buttons.push(
          <Button
            key={k++}
            onClick={(e) => {
              e.stopPropagation();
              this.showTrace(
                row.applicationId,
                agentName,
                cell,
                get(row, '__subQueue')
              );
            }}
            bsSize="xsmall"
          >
            Trace
          </Button>
        );
      } else if (type == 'reprocessing' && api.isSuperUser()) {
        if (get(row, 'active') == false) {
          buttons.push(
            <FzButton
              fzStyle="regular"
              onClick={(e) => {
                e.stopPropagation();
                this.showReprocessing(get(row, '_id'), agentName, false);
              }}
            >
              <FzIcons
                icon={'play-circle'}
                fzStyle={{ width: '15px', height: '15px' }}
              />
              Reprocess
            </FzButton>
          );
        } else {
          buttons.push(
            <FzButton
              fzStyle="alert"
              onClick={(e) => {
                e.stopPropagation();
                this.showReprocessing(get(row, '_id'), agentName, true);
              }}
            >
              <FzIcons
                icon={'pause-circle'}
                fzStyle={{ width: '15px', height: '15px' }}
              />
              Reprocess
            </FzButton>
          );
        }
      }
      return <div>{buttons}</div>;
    }
    return '';
  }

  showTrace(appId, agent, queue, doc) {
    this.setState({ showTrace: true, appId: appId, queue: queue });
    getApplicationTraces(appId, agent, queue, doc).then((trace) =>
      this.setState({ trace: trace })
    );
  }

  /**
   * A updated version of `queue_colors` from `column-formatter.js`
   * @param {string} messageTrigger 
   * @param {string} label 
   * @returns {string}
   */
  getColorizedQueueLabel (messageTrigger, label) {
    const color = triggers_colors[messageTrigger] || triggers_colors["none"];
    const coloredLabel = `<span style="color: ${color}; font-weight:bold">${label}</span>`;
    return coloredLabel;
  };  

  showReprocessing(id, agent, active) {
    this.setState({
      reprocessingId: id,
      reprocessingAgent: agent,
      showReprocessing: true,
      active: active,
    });
    getQueue(id, agent).then((queue) => this.setState({ queue: queue }));
  }

  showColoredQueues() {
    const { agentName } = this.props;
    if (this.state.sqstriggers.length === 0) {
      getSQSTriggers(agentName).then((sqstriggers) => {
        this.setState({ sqstriggers: sqstriggers, showLegend: true });
      });
    } else {
      this.setState({ showLegend: true });
    }
  }

  expandComponent(row) {
    return <QueueDetails queue={row} />;
  }

  render() {
    let { queueData, agentName } = this.props;

    if (!queueData || queueData.length === 0) {
      return null;
    }
    const data = queueData.map((it) => ({ ...it, trace: it.queue }));

    const getAdditionalStatus = (appId, supplier) => {
      integrationAgentCallback(appId, supplier, 'manualgetstatus');
    };

    let buttonGetStatus = null;
    if (
      agentName === 'cbss-v3' &&
      queueData &&
      queueData.length &&
      queueData[0].applicationId
    ) {
      buttonGetStatus = (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            getAdditionalStatus(queueData[0].applicationId, 'cbss');
          }}
          bsSize="small"
        >
          Get current Status
        </Button>
      );
    }
    if (
      agentName === 'portocred' &&
      queueData &&
      queueData.length &&
      queueData[0].applicationId
    ) {
      buttonGetStatus = (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            postIntegrationAgentCallback(
              queueData[0].applicationId,
              'portocred',
              'renew_offers',
              {}
            );
          }}
          bsSize="small"
        >
          Renew Offers
        </Button>
      );
    }

    return (
      <div className="inner-table" style={{ maxWidth: 1010 }}>
        <Button
          onClick={() => {
            this.state.showLegend === false
              ? this.showColoredQueues()
              : this.setState({ showLegend: false });
          }}
          bsSize="small"
        >
          Legenda (SQS Triggers)
        </Button>
        {this.state.showLegend && (
          <LegendTable
            nRows={3}
            agentName={this.props.agentName}
            sqsTriggers={this.state.sqstriggers}
          />
        )}
        <TraceModal
          show={this.state.showTrace}
          traceContent={this.state.trace}
          onHide={() => this.setState({ showTrace: false })}
        />
        <ReprocessingModal
          show={this.state.showReprocessing}
          queueContent={this.state.queue}
          repId={this.state.reprocessingId}
          repAgent={this.state.reprocessingAgent}
          active={this.state.active}
          onHide={() => this.setState({ showReprocessing: false })}
        />
        <FzTable
          data={data}
          metas={agentQueueAnalytic}
          customColumnFormatters={{
            queue: (cell, row) => {
              let queueLabel = row.queue;
              const customQueueInfo =
                row.sqsMessage.ReceivedData?.customQueueInfo ||
                row.sqsMessage.extraData?.customQueueInfo ||
                row.extraData?.customQueueInfo;

              if (customQueueInfo?.subTitle) {
                queueLabel += ` - ${customQueueInfo.subTitle}`;
              }
              if (this.state.showLegend) {
                queueLabel = this.getColorizedQueueLabel(
                  row.sqsMessage.MessageTrigger,
                  queueLabel
                );
              }
              return queueLabel;
            },
            subQueue: (cell) => (cell === 'none' ? '' : cell),
            active: (cell, row) => this.activeCol(cell, row),
            attempts: (cell, row) => this.attemptsColumn(cell, row),
            trace: (cell, row) => this.actionsColumn(cell, row, 'trace'),
            reprocessing: (cell, row) =>
              this.actionsColumn(cell, row, 'reprocessing'),
          }}
          rowExpand={{
            expandComponent: (row) => this.expandComponent(row),
          }}
          exportDownload={false}
          clipboard={false}
          configSort={{
            orderBy: 'created',
            sortOrder: 'desc',
          }}
          visibleColumns={[
            '_id',
            'queue',
            'created',
            'updated',
            'active',
            'locked',
            'canceled',
            'attempts',
            'maxAttempts',
            'trace',
            'reprocessing',
          ]}
        />
        {buttonGetStatus}
      </div>
    );
  }
}
