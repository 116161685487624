import React from 'react';
import { formatPersonalNumber, formatDateTime } from '../../../components/formatters';
import { FzCard, FzCol, FzRow, FzBadge } from "../../fz/layout/index";
import FieldConnected from '../../components/form/field-connected';
import { Application } from '../../../biz/metadatas/application';
import cloneDeep from 'lodash/cloneDeep';

class CustomerDetails extends React.Component {

  render() {
    const { customer } = this.props;

    const meta = cloneDeep(Application.fields["telephoneNumber"])
    meta.inputProps.whatsappOptin = customer.whatsappOptin === undefined || customer.whatsappOptin === true ? false : true

    let acceptWhatsappContact
    if (customer.whatsappOptin === undefined) {
      acceptWhatsappContact = (< FzBadge> Cliente não informou preferência de contato</FzBadge >)
    } else if (customer.whatsappOptin === true) {
      acceptWhatsappContact =  (< FzBadge > Aceita contato por WhatsApp</FzBadge >)
    } else {
      acceptWhatsappContact =  (< FzBadge > Recusa contato por WhatsApp</FzBadge >)
    }
    let inactiveAlert = null
    if (!customer.active) {
      inactiveAlert=  <FzBadge fzStyle="alert">Cliente Inativo</FzBadge>
    }
    return (
      <FzCard>
        <FzCard.Heading>
    <FzCard.Title componentClass="h3">Cliente: {customer.name} {inactiveAlert}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzRow>
            <FzCol span={6}>
              <strong>Email</strong>
              <div>
                {customer.email}
              </div>
            </FzCol>
            <FzCol span={6}>
              <strong>CPF</strong>
              <div>
                {formatPersonalNumber(customer.personalNumber)}
              </div>
            </FzCol>
          </FzRow>
          <FzRow>
            <FzCol span={6}>
              <strong>Cliente desde</strong>
              <div>
                {formatDateTime(customer.created)}
              </div>
            </FzCol>
            <FzCol span={6}>
              <div>
                <FieldConnected
                  meta={meta}
                  data={{ "telephoneNumber": customer.telephoneNumber }}
                  ns={this.props.ns}
                />
                {acceptWhatsappContact}
              </div>
            </FzCol>
          </FzRow>
        </FzCard.Body>
      </FzCard>
    );
  }
}

export { CustomerDetails }

