export default  class ClientDomains {
  constructor(baseApi) {
    this.api = baseApi
    this.cachedDomains = {}
    this.api.Get('/domains/')
              .then((domainValues) => this.cachedDomains = domainValues)
              .catch((err) => console.warn(err))
  }

  getDomainValues(domainName) {
    if (!domainName || domainName.length < 5) {
      console.warn("getDomainValues requres a domain name")
      return null;
    }
    if (this.cachedDomains[domainName]) {
      return new Promise((fulfill) => {
        fulfill(this.cachedDomains[domainName])
      })
    }
    return this.api.Get('/domains/'+domainName);
  }

  getVehicleBrands(year) {
    return this.api.Get("/domains/vehicle/brands/" + year);
  }

  getVehicleModels(year, brand) {
    return this.api.Get("/domains/vehicle/models/" + year + "/" + brand);
  }
}