import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzTableEdit } from '../../../ui/fz/grid/fz-table-edit/FzTableEdit';
import { FzToast } from '../../../ui/fz/form/notification/toast';

const Rules = ({
  data,
  ns,
  handleUpdateRule,
  handleInsert,
  handleUploadFile,
  meta,
  toast,
}) => {
  return (
    <div style={{ margin: '1rem 1rem' }}>
      <FzCard>
        <FzCard.Header fzStyle={{ display: 'flex', justifyContent: 'center' }}>
          <FzCard.Title> Regras de Triagem </FzCard.Title>
        </FzCard.Header>
        <FzCard.Body>
          <FzTableEdit
            data={data}
            metas={meta}
            ns={ns}
            context="RulesScreening"
            cellEditConfig={{
              onAfterSaveCell: handleUpdateRule,
              onBeforeSaveCell: () => null,
            }}
            selectColumns={true}
            onInsertRow={handleInsert}
            importCsv={{ onUploadFile: handleUploadFile }}
            titleModal="Inserir nova regra"
          />
        </FzCard.Body>
      </FzCard>
      <FzToast {...toast} />
    </div>
  );
};

export { Rules };
