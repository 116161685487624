import { filterActiveAuctionApplications, filterInactiveAuctionApplications, filterLeadApplications } from '../../../biz/application';
import { ActiveApplications } from '../../../ui/blocks/active-applications';
import { CustomerLeads } from '../../../ui/blocks/crm/customer-leads';
import { InactiveApplications } from '../../../ui/blocks/inactive-applications';
import { FzBadge, FzCard } from '../../../ui/fz/layout/index';
import { getDomain } from '../../../scripts/utils/cache-helpers';

export const TicketApplications = ({ customer, applications, ticket, ns, history, basePath, onNeedUpdate, priorityAttendance }) => {
  let leadPanel;
  let activePanel;
  let inactivePanel;

  const lead = filterLeadApplications(applications) || [];
  const active = filterActiveAuctionApplications(applications) || [];
  const inactive = filterInactiveAuctionApplications(applications) || [];

  function getSupplierName(supplierInternalName: string): string {
    const bid = ticket.application.auction.bids.find(bid => bid.supplier.internalName === supplierInternalName)
    return bid.supplier.name
  }

  function getEventsDomain(event: string): string {
    const events = getDomain(ns, "events-hermes")
    return events?.length > 0 ? events.find(ev => ev.code === event).description : event
  }

  if (lead.length > 0) {
    leadPanel = (
      <FzCard bsStyle="danger">
        <FzCard.Heading fzStyle="">
          <FzCard.Title componentClass="h3">Propostas Incompletas</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <CustomerLeads
            priorityAttendance={priorityAttendance}
            customer={customer}
            ns={ns}
            applications={lead}
            history={history}
          />
        </FzCard.Body>
      </FzCard>
    );
  }

  if (active.length > 0) {
    activePanel = (
      <FzCard>
        {ticket && (
          <FzCard.Heading fzStyle="">
            <FzCard.Title>
              <span>
                Fila:{" "}
                <FzBadge fzStyle="success">{ticket.queueName}</FzBadge>
              </span>
              <span style={{ marginLeft: '15px' }}>
                Evento: {" "}
                <FzBadge fzStyle="success">{getEventsDomain(ticket.event)}</FzBadge>
              </span>
              <span style={{ marginLeft: '15px' }}>
                Parceiro:{" "}
                <FzBadge fzStyle="success">{getSupplierName(ticket.supplierInternalName)}</FzBadge>
              </span>
              <span style={{ marginLeft: '15px' }}>
                Cadência:{" "}
                <FzBadge fzStyle="success">{ticket.cadencia}</FzBadge>
              </span>
            </FzCard.Title>
          </FzCard.Heading>
        )}
        <FzCard.Heading fzStyle="">
          <FzCard.Title componentClass="h3">Propostas Ativas</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <ActiveApplications
            priorityAttendance={priorityAttendance}
            customer={customer}
            applications={active}
            ns={ns}
            history={history}
            basePath={basePath}
            onNeedUpdate={onNeedUpdate}
            ticket={ticket}
          />
        </FzCard.Body>
      </FzCard>
    );
  }

  if (inactive.length > 0) {
    inactivePanel = (
      <FzCard bsStyle="warning">
        <FzCard.Heading fzStyle="">
          <FzCard.Title componentClass="h3">Propostas Anteriores</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <InactiveApplications
            priorityAttendance={priorityAttendance}
            applications={inactive}
            ns={ns}
            history={history}
            basePath={basePath}
            ticket={ticket}
          />
        </FzCard.Body>
      </FzCard>
    );
  }

  return (
    <div>
      {leadPanel}
      {activePanel}
      {inactivePanel}
    </div>
  );
};
