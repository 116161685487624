import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { FzForm } from '../ui/fz/layout/index';

// InputText emits a simple <input /> field.
// It calls onChange with the data typed by the user without any parsing.
// It accepts input type as a prop and uses type="text" as default.
//
// InputText loads the value on local state when it gets focus and save it when it looses
// focus (on when <enter> key is pressed). The changes typed by the user are saved on the
// local state to avoid calling onChange at every keypress.
//
// It is used for editing string fields and also is rendered by other components that set a
// different type prop (like password) and by components that edit its value in a input text,
// but parse it before calling onChange (like, integer, float, money, etc).
//
class InputText extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: undefined };
    this.loadCache = this.loadCache.bind(this);
    this.saveCache = this.saveCache.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  loadCache() {
    this.setState({value: this.props.value});
  }

  saveCache() {
    if (this.state.value === undefined) {
      return;
    }

    if (this.props.value !== this.state.value) {
      let content = this.state.value;
      if (this.props.mask !== undefined) {
        content = content.replace(/\D/g,'')
      }
      this.props.onChange(content);
    }
    this.setState({value: undefined});
  }

  saveOnEnter(event) {
    if (event.keyCode === 13) {
      this.saveCache();
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    if (this.props.notifyInputChanges) {
      this.props.notifyInputChanges(event.target.value);
    }
  }

  render() {
    // extract props used here and remove 'onChange' and 'notifyInputChanges' (used by methods above)
    // eslint-disable-next-line
    let { type, domId, value, original, onChange, name, notifyInputChanges, mask, ...props } = this.props;

    if (type === undefined) {
      type = 'text'
    }
    if (props.multiline) {
      delete props.multiline;
      props.componentClass="textarea"
    }

    value = (value === undefined) || (value === null) ? '' : value;
    original = (original === undefined) || (original === null) ? '' : original;

    const content = this.state.value === undefined ? value : this.state.value
    const changedClass = "form-control" + (content !== original ? ' changed' : '');

    if (mask === undefined) {
        return (
          <FzForm.Control
            id={domId}
            type={type}
            value={content}
            name={name}
            className={changedClass}
            onChange={this.handleChange}
            onFocus={this.loadCache}
            onBlur={this.saveCache}
            onKeyDown={this.saveOnEnter}
            {...props} />
        );
    } else {
        return(
          <MaskedInput
            id={domId}
            mask={mask}
            placeholderChar=" "
            type={type}
            value={content}
            className={changedClass}
            onChange={this.handleChange}
            onFocus={this.loadCache}
            onBlur={this.saveCache}
            onKeyDown={this.saveOnEnter}
            {...props} />
        );
    }

  }
};

InputText.propTypes = {
  type: PropTypes.string,
  domId: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  original: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  notifyInputChanges: PropTypes.func
};

export default InputText;

