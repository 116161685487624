import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from "lodash";
import AddInputLayoutContainer from '../../../../Containers/InputLayout/Add'
import { createInputLayout } from '../../../../services/api/inputlayout'
import { getAllSuppliers } from '../../../../api/suppliers';
import { formatToFloat } from '../../../../utils/formatter';
import api from '../../../../api/client-api';

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}

const AddInputLayout = ({ ns }) => {
  let history = useHistory();
  const [toastInfo, setToastInfo] = useState(toastInitialConfig);
  const [loading, setLoading] = useState(false);
  const [suppliersOptions, setSuppliersOptions] = useState([]);
  const [mapFieldOptions, setMapFieldOptions] = useState([]);

  useEffect(() => {
    buildform()
    loadSuppliers()
    GetMapfieldsOnDomain()
    return function willMount() {
      ns.clear();
    }
  }, [])

  const GetMapfieldsOnDomain = async () => {
    try {
      const result = await api.domains.getDomainValues("mapFields-gringotts")
      setMapFieldOptions(result)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const handleToastClose = () =>
    setToastInfo(toastInitialConfig);

  const buildInputLayoutPayload = values => {
    let payload: any = {
      name: get(values, "name", ""),
      encoding: get(values, "encoding", ""),
      separator: get(values, "separator", ""),
      numberOfHeaderLines: get(values, "numberOfHeaderLines", ""),
      supplierInternalName: get(values, "supplierInternalName", ""),
      value: formatToFloat(get(values, "value", "")),
      installmentAmount: formatToFloat(get(values, "installmentAmount", "")),
      installment: get(values, "installment", ""),
      interestRate: formatToFloat(get(values, "interestRate", "")),
      comission: formatToFloat(get(values, "comission", "")),
      referenceNumberDays: get(values, "referenceNumberDays", ""),
      columns: []
    }

    const keyValues = Object.keys(values)
    const types = keyValues.filter(item => item.includes("type")).map(field => {
      const prefixId = field.replace(/type_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    const formats = keyValues.filter(item => item.includes("format")).map(field => {
      const prefixId = field.replace(/format_|size_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    const sizes = keyValues.filter(item => item.includes("size")).map(field => {
      const prefixId = field.replace(/size_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: Number(values[field])
      })
    })

    const mapFields = keyValues.filter(item => item.includes("mapField")).map(field => {
      const prefixId = field.replace(/mapField_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    const fields = keyValues.filter(item => item.includes("field")).map(field => {
      const prefixId = field.replace(/field_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })
  
    payload.columns = types.map(type => {
      const size = sizes.find(size => size.id === type.id)
      const format = formats.find(format => format.id === type.id)
      const mapField = mapFields.find(mapField => mapField.id === type.id)
      const field = fields.find(field => field.id === type.id)
      if (size && format) {
        return ({
          ...field,
          ...type,
          ...size,
          ...mapField,
          ...format
        })
      }
    }).map(element => ({
      field: element && element.field,
      type: element && element.type, 
      size: element && element.size,
      format: element && element.format,
      mapField: element && element.mapField,
    }))
    return payload
  }

  const loadSuppliers = async () => {
    setLoading(true)
    const suppliersList = await getAllSuppliers()
    const parseSupplier = ({ internalName, name }) => ({ 
      code: internalName, 
      description: name,
    })   
    setSuppliersOptions(suppliersList.map(parseSupplier));
    setLoading(false)
  }
  
  const handleSubmit = async (values, fieldsInvalid) => {
    try {
      if (fieldsInvalid <= 0) {
        setLoading(true)
        await createInputLayout(buildInputLayoutPayload(values));
        ns.unset("inputLayoutForm");
        ns.stopEditing();
        setLoading(false)
        goToInputLayoutList();
      }
    } catch (error) {
      setLoading(false)
      setToastInfo({ ...toastInfo, show: true });
    }
  }

  const goToInputLayoutList = () => {
    ns.stopEditing();
    history.push("/input-layout/list");
  }

  const buildform = () => {
    ns.set("inputLayoutForm", {});
    ns.startEditing({});
  }

  return (
    <AddInputLayoutContainer
      ns={ns} 
      suppliersOptions={suppliersOptions}
      handleSubmit={handleSubmit} 
      goBack={goToInputLayoutList}
      toastInfo={toastInfo}
      handleToastClose={handleToastClose}
      loading={loading}
      mapFieldOptions={mapFieldOptions}
    />
  )
}

export default AddInputLayout;
