import { Card } from 'react-bootstrap';
import { FzCard } from '../../../../../ui/fz/layout/index';
import { FzButton, FzField } from '../../../../../ui/fz/form';
import { useState, useEffect } from 'react';
import { BasicFuncs, Utils as QbUtils } from '@react-awesome-query-builder/mui';
import { MuiConfig } from '@react-awesome-query-builder/mui';
import '@react-awesome-query-builder/mui/css/styles.css';
import { Application } from '../../../../../biz/metadatas/application';
import SplitLine from '../../../../../components/split-line';
import './style.css';
import {
  GetAllFilter,
  PostFilter,
  PutFilter,
} from '../../../../../services/api/dory';
import { getDomains } from '../../../../../api/domains';
import QueryBuilder from './query-builder';
import { FzToast } from '../../../../../ui/fz/form/notification/toast';
import { bidsCustomQueryFields, customQueryFields } from './customQueryFields';
import { getAllProducts } from '../../../../../api/applications';
import { getAllSuppliers } from '../../../../../api/suppliers';
import merge from "lodash/merge"
import { CustomRelativeDateTime } from './customFunctions';

const InitialConfig = MuiConfig;

const filterFieldType = (fieldType) => {
  switch (fieldType) {
    case 'masked':
      return 'text';
    case 'currency':
      return 'number';
    case 'maskedPhone':
      return 'number';
    case 'bool':
      return 'boolean';

    default:
      return fieldType;
  }
};

const getFields = (domains, products, suppliers) => {
  let fields = {};

  Object.keys(Application.fields).forEach((fieldPath) => {
    const field = Application.fields[fieldPath];

    if (field && field.label) {
      let queryField = {
        label: fieldPath,
        type: filterFieldType(field.inputType),
      };

      if (field.filterType === 'domain') {
        if (domains[field.inputProps.domainName]) {
          queryField.fieldSettings = {
            listValues: domains[field.inputProps.domainName].map((option) => ({
              value: option.code,
              title: option.description,
            })),
          };
        }
      } else {
        if (field.inputProps?.options && field.inputProps.options.length > 0) {
          queryField.valueSources = ['value'];

          queryField.fieldSettings = {
            listValues: field.inputProps.options.map((option) => ({
              value: option.code,
              title: option.description,
            })),
          };
        }
      }

      if (fieldPath === 'product') {
        queryField.fieldSettings = {
          listValues: products
            .map((product) => {
              const { internalName, description } = product;
              if (internalName && description) {
                return {
                  value: internalName,
                  title: description,
                };
              }
            })
            .filter((notUndefined) => notUndefined !== undefined),
        };
      }

      fields[fieldPath] = queryField;
    }
  });

  const bidsFields = bidsCustomQueryFields(suppliers)

  return { ...fields, ...customQueryFields, ...bidsFields };
};

const translateOperators = (label) => {
  switch (label) {
    case 'All':
      return 'Todos';
    case 'Between':
      return 'Entre';
    case 'Ends with':
      return 'Termina com';
    case '==':
      return 'Igual a';
    case '>':
      return 'Maior que';
    case '>=':
      return 'Maior ou igual que';
    case 'Is empty':
      return 'Vazio';
    case 'Is not empty':
      return 'Não é vazio';
    case 'Is not null':
      return 'Não é nulo';
    case 'Is null':
      return 'Nulo';
    case '<':
      return 'Menor que';
    case '<=':
      return 'Menor ou igual a';
    case 'Contains':
      return 'Contém';
    case 'Equals':
      return 'Igual (Vários)';
    case 'Not contains':
      return 'Não contém';
    case 'Not equals':
      return 'Diferente (Vários)';
    case 'None':
      return 'Nenhum';
    case 'Not between':
      return 'Não está entre';
    case '!=':
      return 'Diferente';
    case 'Not contains':
      return 'Não contém';
    case 'Proximity search':
      return 'Busca por proximidade';
    case 'Any in':
      return 'Qualquer';
    case '==':
      return 'Igual';
    case 'Not in':
      return 'Não é (vários)';
    case 'Some':
      return 'Algum';
    case 'Starts with':
      return 'Começa com';
    default:
      return label;
  }
};

const operatorsTranslation = {};

Object.keys(InitialConfig.operators).forEach((operator) => {
  operatorsTranslation[operator] = {
    label: translateOperators(InitialConfig.operators[operator].label),
  };
});

const translationsConfig = {
  conjunctions: {
    AND: {
      label: 'E',
    },
    OR: {
      label: 'OU',
    },
  },
  operators: operatorsTranslation,
};

const funcs = {
  funcs: {
    RELATIVE_DATETIME: merge(BasicFuncs.RELATIVE_DATETIME, CustomRelativeDateTime),
    NOW: BasicFuncs.NOW,
  }
};

const queryValue = { id: QbUtils.uuid(), type: 'group' };

export function FiltersManager({
  ns,
}) {
  const [filters, setFilters] = useState([]);
  const [activeFilterIndex, setActiveFilterIndex] = useState(undefined);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [toastData, setToastData] = useState({
    show: false,
    color: '',
    title: '',
    message: '',
  });

  const [config, setConfig] = useState(merge(InitialConfig, translationsConfig, funcs));

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  });

  useEffect(() => {
    getDomains().then((domains) => {
      getAllProducts().then((products) => {
        getAllSuppliers().then((suppliers) => {
          setConfig({ ...config, fields: { ...getFields(domains, products, suppliers) } });
        })
      });
    });

    GetAllFilter()
      .then((res) => {
        const filters = res.data.map((filter) => ({
          id: filter._id,
          name: filter.name,
          filter: filter.filter,
          query: filter.query,
        }));
        setFilters(filters);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const createFilter = () => {
    setFilters([
      {
        id: '',
        name: '',
        filter: '',
        query: '',
      },
      ...filters,
    ]);
    setInputValue('');
    setState({
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      config: config,
    });
    setActiveFilterIndex(0);
  };

  const updateFiltersList = (payload) => {
    setFilters(payload);
    setActiveFilterIndex(undefined);
    setInputValue('');
    setState({
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
      config: config,
    });
    setSaveStatus('');
    setToastData({
      show: true,
      color: 'success',
      title: 'Salvo',
      message: 'Filtro salvo.',
    });
  };

  const saveFilter = async (index, filter) => {
    const mongoQuery = QbUtils.mongodbFormat(state.tree, config);
    const jsonLogic = QbUtils.jsonLogicFormat(state.tree, config);
    if (mongoQuery && jsonLogic) {
      if (inputValue !== '') {
        try {
          setSaveStatus('saving');
          const payload = filters;
          payload[index] = {
            name: inputValue,
            filter: JSON.stringify(mongoQuery),
            query: JSON.stringify(jsonLogic),
          };
          if (filter.id !== '') {
            PutFilter(filter.id, payload[index])
              .then(() => {
                payload[index] = { ...payload[index], id: filter.id };
                updateFiltersList(payload);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            PostFilter(payload[index])
              .then((res) => {
                payload[index] = { ...payload[index], id: res.data._id };
                updateFiltersList(payload);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (error) {
          setToastData({
            show: true,
            color: 'danger',
            title: 'Erro',
            message: 'Erro ao salvar o filtro, tente novamente mais tarde.',
          });
        }
      } else {
        setToastData({
          show: true,
          color: 'danger',
          title: 'Erro',
          message: 'Digite um título para o filtro.',
        });
      }
    } else {
      setToastData({
        show: true,
        color: 'danger',
        title: 'Erro',
        message: 'Adicione pelo menos uma regra.',
      });
    }
  };

  const editFilter = (filter, index) => {
    const jsonLogic = JSON.parse(filter.query);
    const jsonTree = QbUtils.loadFromJsonLogic(jsonLogic.logic, config);
    setActiveFilterIndex(index);
    setInputValue(filter.name);
    setState({
      tree: QbUtils.checkTree(jsonTree, config),
      config: config,
    });
  };

  // const deleteFilter = async (removeIndex, id) => {
  //   setSaveStatus('deleting');
  //   if (id) {
  //     await DeleteFilter(id);
  //   }
  //   setFilters(filters.filter((_, index) => index !== removeIndex));
  //   setActiveFilterIndex(undefined);
  //   setInputValue('');
  //   setState({
  //     tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
  //     config: config,
  //   });
  //   setSaveStatus('');
  //   setToastData({
  //     show: true,
  //     color: 'success',
  //     title: 'Excluido',
  //     message: 'Filtro excluido.',
  //   });
  // };

  return (
    <Card>
      <Card.Body>
        <section>
          <FzCard>
            <FzCard.Heading>
              <FzCard.Title componentClass="h3">
                <SplitLine>
                  <SplitLine.Left>Gerenciador de Filtros</SplitLine.Left>
                  <SplitLine.Right>
                    <FzButton
                      disabled={isLoading}
                      fzStyle="regular"
                      onClick={() => createFilter()}
                    >
                      Criar Filtro
                    </FzButton>
                  </SplitLine.Right>
                </SplitLine>
              </FzCard.Title>
            </FzCard.Heading>

            <FzCard.Body>
              {filters && filters.length ? (
                filters.map((filter, index) => (
                  <div key={index} className="filterWrapper">
                    <div className="filterHeader">
                      {index !== activeFilterIndex && <p>{filter.name}</p>}
                      {index === activeFilterIndex ? (
                        <>
                          <FzButton
                            fzStyle="regular"
                            onClick={() => {
                              saveFilter(index, filter);
                            }}
                            disabled={saveStatus !== ''}
                          >
                            {saveStatus === 'saving' ? 'Salvando' : 'Salvar'}
                          </FzButton>
                          {/* <FzButton
                            fzStyle="alert"
                            onClick={() => deleteFilter(index, filter.id)}
                            disabled={saveStatus != ''}
                          >
                            {saveStatus === 'deleting'
                              ? 'Excluindo'
                              : 'Excluir'}
                          </FzButton> */}
                        </>
                      ) : (
                        <FzButton
                          fzStyle="regular"
                          onClick={() => {
                            editFilter(filter, index);
                          }}
                        >
                          Editar
                        </FzButton>
                      )}
                    </div>
                    {index === activeFilterIndex && (
                      <div className="filterContent">
                        <FzField
                          readOnly={saveStatus !== ''}
                          name={filter.name}
                          dataType="string"
                          inputType="text"
                          label="Nome do filtro"
                          placeHolder="Digite o nome do filtro"
                          mandatory={true}
                          onChange={setInputValue}
                          value={inputValue}
                        />
                        {!isLoading && (
                          <QueryBuilder
                            count={0}
                            ns={ns}
                            isQueue={false}
                            config={config}
                            state={state}
                            setState={setState}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="emptyMessage">
                  {isLoading ? 'Carregando...' : 'Nenhum filtro encontrado'}
                </p>
              )}
            </FzCard.Body>
          </FzCard>
          <FzToast
            fzStyle={toastData.color}
            close={() => {
              setToastData({
                ...toastData,
                show: false,
              });
            }}
            show={toastData.show}
            delay={5000}
            headerMessageTost={toastData.title}
            bodyMessageTost={toastData.message}
            autoHide={true}
          />
        </section>
      </Card.Body>
    </Card>
  );
}
