import React from 'react'
import FieldConnected from '../../../ui/components/form/field-connected'
import { FzButton } from '../../../ui/fz/form'
import { FzTable } from '../../../ui/fz/grid/table'
import { analyticsFilter, analyticsColumns } from './metadata'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzToast } from '../../../ui/fz/form/notification/toast'
import { rowType } from './type'

export const _getExtraData = (formatExtraData, param, acceptString, defaultValue) => {
  if (
    typeof formatExtraData === 'object' &&
    typeof formatExtraData[param] !== 'undefined'
  ) {
    return formatExtraData[param];
  }
  if (acceptString && typeof formatExtraData === 'string') {
    return formatExtraData;
  }
  return defaultValue;
};

const Analytics = ({
  ns,
  data,
  handleSearchReport,
  role,
  filters,
  showToast,
  handleToastClose,
  documentDomains
}) => {
  
  function getDescriptionDoc(cell: string, row: rowType, formatExtraData: any, idx: number) {
    return documentDomains.find( it => it.code === cell)?.description ?? "Não encontrado";
  }
  return (
    <>
      <div className={"fz-panel"}>
        <div className={"fz-panel-border-padding"} style={{ display: "block", borderBottom: "3px solid #dedede" }}>
          <div style={{ display: "flex" }}>
            <FieldConnected meta={analyticsFilter.from} data={filters} ns={ns} />
            <FieldConnected meta={analyticsFilter.to} data={filters} ns={ns} />
          </div>
        </div>
        <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>
          <FieldConnected meta={analyticsFilter.documentType} data={filters} ns={ns} />
        </div>
        <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>
          <FieldConnected meta={analyticsFilter.personalNumber} data={filters} ns={ns} />

        </div>
        <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: "15px 0px 0 0" }}>
              <FzButton
                fzStyle="discrete"
                onClick={() => {
                  const from = ns.getChanged("from")
                  const to = ns.getChanged("to")
                  const documentType = ns.getChanged("documentType")
                  const personalNumber = ns.getChanged("personalNumber")

                  handleSearchReport({
                    from,
                    to,
                    documentType,
                    personalNumber
                  })
                }}
                className={"btn btn-success"}
              >
                Buscar
              </FzButton>
            </div>
          </div>
        </div>
      </div>

      <FzCard.Body>
        <FzTable
          ns={ns}
          metas={analyticsColumns}
          data={data.statistics}
          exportDownload={role === "admin"}
          customColumnFormatters={{
            documentType: (cell, row, formatExtraData, idx) => getDescriptionDoc(cell, row, formatExtraData, idx)
          }}
        />
      </FzCard.Body>
      <FzToast
        fzStyle="danger"
        close={handleToastClose}
        show={showToast}
        delay={5000}
        headerMessageTost="Error"
        bodyMessageTost="Erro interno, tente novamente!"
        autoHide={true}
      />
    </>
  )
}

export { Analytics }