import React from 'react';
import PropTypes from 'prop-types';
import { Namespace } from '../scripts/state/redux_ns';
import { urlColumn } from '../components/table-standard';
import { FzTable } from '../ui/fz/grid/table';
import { supplierMetas } from '../biz/metadatas/supplier'
import { buildImgUrl } from '../api/suppliers';
import { FzBadge } from '../ui/fz/layout/index';
import { FzIcons } from '../ui/fz/form/icon';
import { FzButton } from '../ui/fz/form';

const SuppliersTable = ({ ns, data, role, ...props }) => {

  const ShowSupplier = (row) => {
    ns.copy("/admsuppliers/" + row.id).set("supplier", row)
    props.history.push("/admsuppliers/" + row.id)
  }

  const idColumn = (cell, row) => {
    return (<div>
      <FzButton fzStyle="discrete" title={row['id']} onClick={() => ShowSupplier(row)} bsSize="sm">
        <FzIcons icon={"pencil"} fzStyle={{ width: "13px", height: "13px", margin: "5px" }} /> 
          Ver
      </FzButton>
    </div>
    );
  }

  const status = (cell, row) => {
    let inactive = !!row["inactive"]
    let closed = !!row["newApplicationsDisabled"]
    return (<div>
      {!closed && !inactive ? <FzBadge fzStyle="success"> Aberto </FzBadge> : null}
      {inactive ? <FzBadge fzStyle="alert"> Desativado </FzBadge> : null}
      {closed && !inactive ? <FzBadge fzStyle="attention"> Fechado </FzBadge> : null}
    </div>
    );
  }

  const logoColumn = (cell, row) => {
    if (row.disabled) {
      return null
    }
    return <img src={buildImgUrl(cell)} alt={cell} height="25px" />;
  }
    

  for (var idx in data) {
    let supplier = data[idx];
    let products = "";
    var supBranches = supplier.branches;
    for (var b in supBranches) {
      if (supBranches.hasOwnProperty(b)) {
        var productsBr = supBranches[b].acceptedProducts.split(",");
        for (var i in productsBr) {
          let prod = productsBr[i];
          if (products.toLowerCase().indexOf(prod.trim().toLowerCase()) < 0) {
            products += (products.length === 0 ? "" : ", ") + prod;
          }
        }
      }
    }
    data[idx].acceptedProducts = products.split(",");
  }

  const productsColumn = (cell, row) => {
    if (row.disabled) {
      return null
    }

    const products = cell.map((it, index) => (
      <div key={index} style={{ marginRight: '2px' }}>
        {it !== undefined && it !== "" ?
          <FzBadge fzStyle="gray"> {it} </FzBadge> :
          null}
      </div>
    ))

    return (
      <div style={{ display: 'flex' }} >
        {products}
      </div>)
  }

  return (
    <FzTable
      data={data || []}
      metas={supplierMetas}
      ns={ns}
      visibleColumns={["id", "inactive", "name", "internalName", "acceptedProducts", "email", "websiteUrl", "logoImageUrl"]}
      customColumnFormatters={{
        id: (cell, row, ) => idColumn(cell, row),
        inactive: (cell, row, ) => status(cell, row),
        acceptedProducts: (cell, row, ) => productsColumn(cell, row),
        websiteUrl: (cell, row, ) => urlColumn(cell, row),
        logoImageUrl: (cell, row, ) => logoColumn(cell, row)
      }}
      exportDownload={false}
      clipboard={false}
    />
  );
}

SuppliersTable.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default SuppliersTable;