import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { FzCol, FzRow, FzForm } from '../ui/fz/layout/index';
import 'react-block-ui/style.css';

const periodName = { month: "Monthly", week: "Weekly", day: "Daily", total: "Total" }
const statisticsType = {
  qualified: "Qualified Leads",
  completed: "Completed Applications",
  sent: "Sent Applications",
  contactsAgentSummary: "Contacts per Agent",
  contactsProductSummary: "Contacts per Product",
  revenueoverview: "Revenue Model Overview",
  revenuepartners: "Revenue Model per Partners",
  paid: "Paid Applications",
  formalizationdocs: "Formalization Documents"
}

export const StatsDetailHeader = ({ stats, title, periods, currPeriod, onChange }) => {
  if (!stats) {
    return null;
  }

  if (!title) {
    title = statisticsType[stats.statisticsType] || stats.statisticsType
  }
  title += stats.consolidationPeriod ? " - " + periodName[stats.consolidationPeriod] : ''
  let generatedInfo
  if (typeof (stats) === "string") {
    title = stats
  } else {
    stats.generatedAt = stats.generatedAt || stats.created
    if (stats.generatedAt) {
      stats.queryEndDate = stats.queryEndDate || new Date()
      generatedInfo = <div className="query-details">
        Included interval: <FormattedDate timeZone="UTC" value={stats.queryStartDate} /> to <FormattedDate timeZone="UTC" value={stats.queryEndDate} />. Data generated at <FormattedDate value={stats.generatedAt} /> / <FormattedTime value={stats.generatedAt} />. </div>
    }
  }
  let periodSelect = null
  if (periods) {
    let options = null
    options = []
    for (var p in periods) {
      options.push(<option key={p} value={p}>{periods[p]}</option>)
    }

    periodSelect = <FzForm.Control
      as="select"
      name="periodList"
      placeholder="Select the period to display..."
      value={currPeriod}
      onChange={(e) => onChange(e.target.value)}>
      <option value="">Select the period to display...</option>
      {options}
    </FzForm.Control>
  }
  let header = <h4>{title}</h4>
  return (
    <FzRow>
      <FzCol span={4}>
        {header}
      </FzCol>
      <FzCol span={6}>
        {generatedInfo}
      </FzCol>
      <FzCol span={2}>
        {periodSelect}
      </FzCol>
    </FzRow>
  );
}