import React, { Component } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { dateTimeColumn } from '../../../components/table-standard';
import UploadDocument from '../../../application-components/upload-document';
import api from '../../../api/client-api';
import { deleteDocument, uploadDocument } from '../../../api/applications';
import {
  getDocumentUrl,
  getFormalizationDocumentUrl
} from '../../../api/formalization';
import { FzTable } from '../../fz/grid/table';
import { formalizationAnalyticInside } from '../../../biz/metadatas/formalization-metadata'
import { FzIcons } from '../../fz/form/icon';
import { FzButton } from '../../fz/form';
import { formatterFile } from '../../../api/formatter-file'
import isValidIMEI from "../../../utils/imeiValidation";
import ModalIMEI from './modalIMEI';
import ModalElectricBillNumber from '../formalization/modalElectricBillNumber';
import isValidElectricBillNumber from '../../../utils/electricBillNumberValidation';

const FORMALIZATION_ACTION_UP_DOC = 'upload-document';
const FORMALIZATION_ACTION_DEL_DOC = 'delete-document';
const CANCELED_STATUS = 32768;
const PAID_STATUS = 32;


class DocumentHistory extends React.Component {

  render() {
    const { doc } = this.props

    return (<Table responsive className="inner-table">
      <thead>
        <tr>
          <th>Do Cliente</th>
          <th>P/ Banco</th>
          <th>Rejeitado</th>
          <th>Nº Formalização</th>
        </tr>
      </thead>
      <tbody>
        {doc.history.map((h, k) => {
          return (
            <tr key={doc.documentType + "-history-" + k.toString()}>
              <td>{dateTimeColumn(h.uploaded)}</td>
              <td>{dateTimeColumn(h.sentDate)}</td>
              <td>{dateTimeColumn(h.historyCreated)}</td>
              <td>{h.partnerDocumentId}</td>
              <td>{h.userDocumentId && <a href={getDocumentUrl(h.userDocumentId, "png")}>< FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} /></a>}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
    )
  }
}

class FormalizationDocumentsList extends Component {
  constructor(props) {
    super(props)

    this.statusColumn = this.statusColumn.bind(this)
    this.doDeleteDocument = this.doDeleteDocument.bind(this)
    this.viewDoc = this.viewDoc.bind(this)
    this.expandComponent = this.expandComponent.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.handleChangeImei = this.handleChangeImei.bind(this)
    this.handleUpdateImei = this.handleUpdateImei.bind(this)
    this.handleChangeElectricNumber = this.handleChangeElectricNumber.bind(this)
    this.handleChangeElectricNumberDate = this.handleChangeElectricNumberDate.bind(this)
    this.handleUpdateElectricBillNumber = this.handleUpdateElectricBillNumber.bind(this)
    this.state = { 
      openModalDeleteConfirm: false,
      imei: "",
      imeiError: "",
      electricBillNumber: "",
      electricBillNumberDate: "",
      electricBillNumberError: "",
      showModalElectricBillNumber: false,
    }

  }

  handleSetModal(value, applicationId, bidId, doc, documentType) {
    this.setState({
      openModalDeleteConfirm: value,
      currentApplicationId: applicationId,
      currentBidId: bidId,
      currentDoc: doc,
      currentDocumentType: documentType
    })
  }

  statusColumn(_, doc) {
    let actions = []
    if (doc.needUpload) {
      actions.push(< FzIcons key="arrow-up" icon={"long-arrow-up"} fzStyle={{ width: "15px", height: "15px" }} />);
    }
    if (doc.history) {
      actions.push(< FzIcons key="time" icon={"history"} fzStyle={{ width: "15px", height: "15px" }} />);
    }
    return (<span>
      {actions.map((a) => a)}
    </span>)
  };

  doDeleteDocument(formalizationId, bidId, doc) {
    let { ns, applicationId } = this.props;
    if (doc && formalizationId) {
      this.setState({ openModalDeleteConfirm: false }, () => {
        deleteDocument(formalizationId, bidId, doc)
          .then((msg) => {
            if (msg) {
              let treatment = ns.get("crm.treatments." + applicationId) ? ns.get("crm.treatments." + applicationId) + " * Remoçao de Documento - " + doc.documentType : "Remoção de Documento - " + doc.documentType;
              let treatmentArray = ns.get("crm.treatmentsArray." + applicationId) ? ns.get("crm.treatmentsArray." + applicationId) : [];
              treatmentArray.push(FORMALIZATION_ACTION_DEL_DOC);

              ns.set("crm.treatments." + applicationId, treatment);
              ns.set("crm.treatmentsArray." + applicationId, treatmentArray);

              this.props.formalization.Documents[doc.documentType].ableToDelete = false;
              this.props.formalization.Documents[doc.documentType].needUpload = true;
              this.props.ns.unset("formalization")
            }
          }).catch((err) => {
            console.warn("API ERROR", err)
          })

      })
    } else {
      alert("Deleção de documento interrompida. Motivo: proposta não está com as condições necessárias.")
    }
  }

  async handleUploadFile(file, documentType) {
    const { formalization, bidId, applicationId, ns } = this.props;
    const { currentDoc } = this.state;
    const data = await formatterFile(file);
    const { needUpload, ableToDelete } = formalization.Documents[documentType];
    const suppressNotification = ableToDelete && !needUpload;
    uploadDocument(applicationId, bidId, { ...data, documentType, suppressNotification }).then((res) => {
      var docsDescriptions = {
        "income": "renda",
        "rg-back": "RG-Verso",
        "rg-front": "RG-Frente",
        "address": "Comprovante de Endereço",
        "ctps": "Carteira de Trabalho e Previdência Social"
      }

      if (res) {
        documentType = docsDescriptions[currentDoc] || documentType

        let treatment = ns.get("crm.treatments." + applicationId) ? ns.get("crm.treatments." + applicationId) + " * Upload de Documento - " + documentType : "Upload de Documento - " + documentType;
        let treatmentArray = ns.get("crm.treatmentsArray." + applicationId) ? ns.get("crm.treatmentsArray." + applicationId) : [];
        treatmentArray.push(FORMALIZATION_ACTION_UP_DOC);

        ns.set("crm.treatments." + applicationId, treatment);
        ns.set("crm.treatmentsArray." + applicationId, treatmentArray);

        alert("Documento enviado.")
        ns.unset(formalization.id)
      } else {
        alert("Ocorreu algum erro.")
      }
    }).catch((err) => {
      console.warn("API ERROR", err)
    })
  }

  viewDoc(cell, doc) {
    let { formalization, bidId, applicationId, readOnly } = this.props;

    let uploadDoc = null
    if (
      !readOnly
      && formalization.formalizationState !== PAID_STATUS
      && formalization.formalizationState !== CANCELED_STATUS
      && doc.documentType !== "telephone-validation"
      && doc.documentType !== "imei"
      && !doc.documentType.includes('selfie')
    ) {
      uploadDoc = (
        <UploadDocument
          handleUploadFile={file => this.handleUploadFile(file, cell)}
        />
      )

    }

    if (doc.created && doc.documentType !== "telephone-validation" && doc.documentType !== "imei" && doc.documentType !== "address-eletricbill-number") {
      return (
        <span>
          <a target="_blank" href={getFormalizationDocumentUrl(applicationId, bidId, doc.documentType, 'png')} rel="noopener noreferrer">
            {formalization.formalizationState === 32 || formalization.formalizationState === 32768 ?
              < FzIcons key="arrow-up" icon={"long-arrow-up"} fzStyle={{ width: "15px", height: "15px" }} /> :
              < FzIcons key="eye-solid" icon={"eye-solid"} fzStyle={{ width: "15px", height: "15px" }} />}
          </a>
          <div className={(doc.ableToDelete ? "visible" : "hidden")} style={{ display: "inline-block" }}>


            {doc.ableToDelete ? <button style={{ border: 0 }} onClick={() => this.handleSetModal(true, applicationId, bidId, doc, cell)}>
              < FzIcons icon={"trash"} fzStyle={{ width: "15px", height: "15px" }} />
            </button> : null}
          </div>
          {uploadDoc}
        </span>
      )
    } else if (
      !readOnly
      && formalization.formalizationState !== PAID_STATUS
      && formalization.formalizationState !== CANCELED_STATUS
      && doc.created && doc.documentType === "imei") {
      return (
        <button style={{ border: 'none' }} onClick={() => this.setState({ showModalImei: true }) }>
          <FzIcons 
            key="arrow-up" 
            icon={"long-arrow-up"} 
            fzStyle={{ width: "15px", height: "15px" }}
          />
        </button>)
    } else if (
      !readOnly
      && formalization.formalizationState !== PAID_STATUS
      && formalization.formalizationState !== CANCELED_STATUS
      && doc.created && doc.documentType === "address-eletricbill-number") {
      return (
        <button style={{ border: 'none' }} onClick={() => this.setState({ showModalElectricBillNumber: true }) }>
          <FzIcons 
            key="arrow-up" 
            icon={"long-arrow-up"} 
            fzStyle={{ width: "15px", height: "15px" }}
          />
        </button>)
    } else {
      return (
        <div>{uploadDoc}</div>
      )
    }
  }

  expandComponent(row) {
    return (< DocumentHistory doc={row} />)
  }

  handleChangeImei(value) {
    this.setState({ imei: value })
  }

  handleChangeElectricNumber(value) {
    this.setState({ electricBillNumber: value })
  }

  handleChangeElectricNumberDate(value) {
    this.setState({ electricBillNumberDate: value })
  }

  async handleUpdateImei() {
    let { bidId, applicationId } = this.props;
    const { isValid, message } = isValidIMEI(this.state.imei)
    if (!isValid) {
      return this.setState({ imeiError: message })
    }

    try {
      const payload = {
        content: this.state.imei,
        documentType: "imei"
      }
      const res = await uploadDocument(applicationId, bidId, payload)
      this.setState({ imei: '', showModalImei: false, imeiError: message })
    } catch (error) {
      this.setState({ imei: '', showModalImei: false, imeiError: message })
    } 
  }

  async handleUpdateElectricBillNumber() {
    const { bidId, applicationId } = this.props;
    
    const { isValid, message } = isValidElectricBillNumber({number: this.state.electricBillNumber, date: this.state.electricBillNumberDate})
    if (!isValid) {
      return this.setState({electricBillNumberError: message})
    }

    try {
      const formattedDate = this.state.electricBillNumberDate.split("/").reverse().join("-")
      const payload = {
        content: `${formattedDate}|${this.state.electricBillNumber}`,
        documentType: "address-eletricbill-number",
        suppressNotification: true
      }
      
      await uploadDocument(applicationId, bidId, payload)

      this.setState({
        electricBillNumber: "",
        electricBillNumberDate: "",
        electricBillNumberError: "",
        showModalElectricBillNumber: false,
      })
    } catch (error) {
      console.error('handleUpdateElectricBillNumber', {error})
    }    
  }

  render() {
    let { formalization, ns, supplierIN, allSuppliers } = this.props;

    // if (!getSuppliers(ns)) {
    //   return null
    // }

    // const suppliers = getSuppliers(ns) || allSuppliers
    const supplier = supplierIN ? allSuppliers[supplierIN] : null

    let application = {}
    if (ns.get("application")) {
      application = ns.get("application")
    } else if (ns.get("customer.applications")) {
      application = ns.get("customer.applications").find(it => it.id === formalization.applicationId)
    }

    const rgDocumentType = this.props.api.domains.cachedDomains["rg-document-type"] || [];
    const incomePaymentMethod = this.props.api.domains.cachedDomains["income-proof-document"] || [];
    const defaultDescription = { description: application.RGDocType || "Documento" };
    const defaultDescriptionIncome = { description: application.incomeProofDocument || "Comprovante de Renda" };
    const identificationDoc = (rgDocumentType.find(it => it.code === application.RGDocType) || defaultDescription).description;
    const incomePaymentMethodDescription = (incomePaymentMethod.find(it => it.code === application.incomeProofDocument) || defaultDescriptionIncome).description;

    let docsDescriptions = this.props.documentDomains.reduce(
      (resultSoFar, data) => {
        let description = data.description
          .replace("RG", identificationDoc)
          .replace("C. Renda", incomePaymentMethodDescription);
        let subTypeFromFormalization =
          formalization?.Documents[data.code]?.documentSubType;
          if (subTypeFromFormalization && subTypeFromFormalization !== data.code) {
          const documentTypeFromDomain =
            rgDocumentType.find(
              (docType) => docType.code === subTypeFromFormalization
            ) ||
            incomePaymentMethod.find(
              (income) => income.code === subTypeFromFormalization
            );
          if (documentTypeFromDomain) {
            description = data.description
              .replace('RG', documentTypeFromDomain.description)
              .replace('C. Renda', documentTypeFromDomain.description);
          }
        }
        return (resultSoFar = { ...resultSoFar, [data.code]: description });
      },
      {}
    );

    const getDocsDescriptions = (cell) => {
      return (docsDescriptions[cell] || cell)
    }


    let docs = [];
    let validStates = [2, 6]
    if (supplier && supplier.formalization.enableDeleteSentDocuments) {
      validStates.push(4)
    }
    const fstate = validStates.indexOf(formalization.formalizationState)
    docs = Object.keys(formalization.Documents).reduce((result, d) => {
      if (!formalization.Documents[d].partnerGenerated) {
        if (!formalization.Documents[d].needUpload && fstate > -1) {
          formalization.Documents[d].ableToDelete = true
        } else {
          formalization.Documents[d].ableToDelete = false
        }
        if (!formalization.Documents[d].partnerDocumentId && formalization.stageData && formalization.stageData.missingDocuments && formalization.stageData.missingDocuments[d]) {
          formalization.Documents[d].partnerDocumentId = formalization.stageData.missingDocuments[d]
        }
        result.push(formalization.Documents[d])
      }
      return result
    }, [])

    const visibleColumns = api.isUserInRole("admin") ?
      ["documentType", "_created", "needUpload", "sentDate", "partnerDocumentId", "actions", "tags", "sizeMB"] :
      ["documentType", "_created", "needUpload", "sentDate", "partnerDocumentId", "actions"]
    let rowExpand
    if (formalization.formalizationState !== PAID_STATUS && formalization.formalizationState !== CANCELED_STATUS) {
      rowExpand = {
        expandComponent: (row, formalization) => this.expandComponent(row, formalization),
        expandableRow: (doc) => doc.history && doc.history.length > 0
      }
    }
    return (
      <div>
        <FzTable
          data={docs}
          metas={formalizationAnalyticInside}
          ns={ns}
          visibleColumns={visibleColumns}
          context={"formalizationAnalyticInside"}
          customColumnFormatters={{
            documentType: (cell, row, formatExtraData, idx) => getDocsDescriptions(cell, row, formatExtraData, idx),
            actions: (cell, row, formatExtraData, idx) => this.viewDoc(row.documentType, row, formatExtraData, idx, docsDescriptions),
            needUpload: (cell, row, formatExtraData, idx) => this.statusColumn(cell, row, formatExtraData, idx)
          }}
          rowExpand={rowExpand}
          exportDownload={false}
          clipboard={false}
        />



        <Modal
          size="lg"
          centered
          show={this.state.openModalDeleteConfirm}
          onHide={() => this.handleSetModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title >
              Deletar Documento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {"Tem certeza que deseja deletar o documento: " + (docsDescriptions[this.state.currentDocumentType] || this.state.currentDocumentType)}
          </Modal.Body>
          <Modal.Footer>
            <FzButton
              onClick={() => this.doDeleteDocument(
                this.state.currentApplicationId,
                this.state.currentBidId,
                this.state.currentDoc
              )
              }
              fzStyle="alert">Deletar documento</FzButton>
          </Modal.Footer>
        </Modal>
        <ModalIMEI
          onChange={this.handleChangeImei}
          value={this.state.imei}
          show={this.state.showModalImei}
          onClose={() => this.setState({ showModalImei: false, imei: '', imeiError: null })}
          onSave={this.handleUpdateImei}
          error={this.state.imeiError}
        />
        <ModalElectricBillNumber
          number={this.state.electricBillNumber}
          changeNumber={this.handleChangeElectricNumber}
          date={this.state.electricBillNumberDate}
          changeDate={this.handleChangeElectricNumberDate}
          show={this.state.showModalElectricBillNumber}
          onClose={() => {
            this.setState({
              electricBillNumber: "",
              electricBillNumberDate: "",
              electricBillNumberError: "",
              showModalElectricBillNumber: false,
            })
          }}
          onSave={this.handleUpdateElectricBillNumber}
          error={this.state.electricBillNumberError}
        />
      </div>
    )
  }
}

export { FormalizationDocumentsList }