import React, { Component } from 'react';
import { Jumbotron, } from 'react-bootstrap';
import SuppliersTable from './suppliers-table';
import SplitLine from "../components/split-line";
import { updateSupplierCache } from '../api/integrations';
import { getAllSuppliers } from '../api/suppliers';
import { FzCard } from '../ui/fz/layout/index'
import { FzButton } from '../ui/fz/form';

export class AdminSuppliers extends Component {
  constructor(props) {
    super(props);
    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.doGetSuppliers = this.doGetSuppliers.bind(this);
  }

  handlePageSelect(eventKey) {
    this.props.history.push('/admsuppliers/' + eventKey)
  }

  updateSuppliersList() {
    updateSupplierCache()
      .then(this.doGetSuppliers)
      .then(() => alert("Lista Atualizada"))
      .catch(err => {
        console.error(err);
        alert("Falha ao atualizar a lista");
      });
  }

  createSupplier() {
    let newPath = this.props.ns.namespace + "/new"
    let newNs = this.props.ns.copy(newPath)
    let newSupplier = {
      commissionRanges: null,
      formalization: {},
      id: "new",
      inactive: false,
      internalName: "",
      logoImageUrl: "",
      name: "",
      users: [],
    }

    newNs.set("supplier", newSupplier)
    newNs.startEditing(newSupplier)

    this.props.history.push(newPath)
  }


  doGetSuppliers() {
    getAllSuppliers()
      .then((suppliers) => { this.props.ns.set("suppliers", suppliers) })
      .catch((err) => { console.warn(err); this.props.history.push("/") })
  }

  componentDidMount() {
    this.doGetSuppliers();
  }

  render() {
    const sudo = this.props.api.isSuperUser();

    return (
      <Jumbotron>
        <FzCard>
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">
              <SplitLine>
                <SplitLine.Left>Parceiros</SplitLine.Left>
                <SplitLine.Right>
                  {sudo ? 
                    <FzButton 
                      fzStyle="regular" 
                      onClick={() => this.createSupplier()}
                    >
                      Criar Parceiro
                    </FzButton> 
                  : null}
                </SplitLine.Right>
                <SplitLine.Right>
                  {sudo ? 
                    <FzButton 
                      fzStyle="primary" 
                      onClick={() => this.updateSuppliersList()}
                    >
                      Atualizar
                    </FzButton> 
                  : null}
                </SplitLine.Right>
              </SplitLine>
            </FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <SuppliersTable ns={this.props.ns} api={this.props.api} data={this.props.ns.get("suppliers")} history={this.props.history} />
          </FzCard.Body>
        </FzCard>
      </Jumbotron>
    );
  }
}