import React from 'react';
import { FormGroup, Button } from 'react-bootstrap'
import api from '../api/client-api';
import { FzCard } from '../ui/fz/layout/index';
import { FzForm } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';
import { FzMasked, FzText } from '../ui/fz/form/old-simple-input';
import { ToastContext } from "../hooks/ToastProvider"

class EditTelephoneEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newEmail: "",
      newTelephone: "",
      user: props.user,
      disableSubmit: true,
      errorPhone: "bg-white",
      errorEmail: "bg-white",
      formErrors: [],
      touchedForm: false
    }
    this.lastVerifyUserData = this.lastVerifyUserData.bind(this)
    this.handleVerifyUserEmail = this.handleVerifyUserEmail.bind(this);
    this.handleVerifyUserPhoneNumber = this.handleVerifyUserPhoneNumber.bind(this);
  }

  componentDidMount() {
    console.log(this.props.showTost, this.props.toastType, this.props.toastMessage)
    if (!this.state.user && this.props.app) {
      api.appGetUserData(this.props.app.id, (status, usr) => {
        if (status === 200 && usr) {
          this.setState({ user: usr })
        }
      })
    }
  }

  handleVerifyUserEmail(newEmail, type, setHeader, setBody, setIsOpen, setLevel) {
    const parse_email = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$/i;
    const verifyEmail = parse_email.test(newEmail);

    if (verifyEmail) {
      return this.setState({
        disableSubmit: false,
        newEmail,
        formErrors: this.state.formErrors.filter(item => item !== type),
      })
    }

    setIsOpen(true);
    setHeader("Ops...");
    setBody("Por favor digite um e-mail válido");
    setLevel("danger");
    return this.setState({
      disableSubmit: true,
      newEmail,
      formErrors: [...this.state.formErrors, type],
    })
  }

  handleVerifyUserPhoneNumber(newTelephone, type, setHeader, setBody, setIsOpen, setLevel) {
    const phoneNumberReplace = newTelephone.replace(/[^0-9]/g, '');
    if (phoneNumberReplace.length === 11) {
      return this.setState({
        disableSubmit: false,
        errorPhone: "bg-white text-black",
        newTelephone,
        formErrors: this.state.formErrors.filter(item => item !== type),
      })
    }
    setIsOpen(true);
    setHeader("Ops...");
    setBody("Por favor digite um telefone celular válido");
    setLevel("danger");

    return this.setState({
      disableSubmit: true,
      errorPhone: "border-danger",
      newTelephone,
      formErrors: [...this.state.formErrors, type],
    })
  }

  lastVerifyUserData(setHeader, setBody, setIsOpen, setLevel) {
    const { newEmail, newTelephone, user, formErrors } = this.state
    const email = formErrors.find(el => el === "email") ? "" : newEmail
    const phoneNumber = formErrors.find(el => el === "phoneNumber") ? "" : newTelephone
    api.appUpdateUserData(
      user.id,
      email,
      phoneNumber,
      user.updated,
      (response, data) => {
        try {
          let headerToast;
          let message;
          let levelToast;
          if (response === 200) {
            headerToast = "Tudo certo!";
            message = "Alteração realizada com sucesso.";
            levelToast = "success";
          }
          else {
            headerToast = "Ops..."
            message = data.error || "Erro ao salvar"
            levelToast = "danger";
          }
          setIsOpen(true);
          setHeader(headerToast);
          setBody(message);
          setLevel(levelToast);
        }
        catch (err) {
          setIsOpen(true);
          setHeader("Ops...");
          setBody(err);
          setLevel("danger");
        }
      });
  }


  handleVerifyData(newValueEdit, type, setHeader, setBody, setIsOpen, setLevel) {
    this.setState({ touchedForm: true })
    if (type === "phoneNumber") {
      return this.handleVerifyUserPhoneNumber(newValueEdit, type, setHeader, setBody, setIsOpen, setLevel);
    }

    return this.handleVerifyUserEmail(newValueEdit, type, setHeader, setBody, setIsOpen, setLevel);
  }

  render() {
    const { onCancel } = this.props
    if (!this.state.user) {
      return <FzAlert fzStyle="attention">Carrregando usuário ...</FzAlert>
    }
    return (
      <ToastContext.Consumer>
        {(({
          setIsOpen,
          setHeader,
          setBody,
          setLevel
        }) =>
          <FzCard bsStyle="warning">
            <FzCard.Heading>
              <FzCard.Title componentClass="h3">Editar E-mail/Telefone</FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
              <FormGroup>
                <FzForm.Label>E-mail</FzForm.Label>
                <FzText
                  name="newEmail"
                  value={this.state.newEmail}
                  style={this.state.errorEmail}
                  onChange={(value) => { this.handleVerifyData(value, "email", setHeader, setBody, setIsOpen, setLevel) }}
                />

                <FzForm.Label>Telefone</FzForm.Label>
                <FzMasked
                  name="newTelephone"
                  value={this.state.newTelephone}
                  mask={"(11) 1 1111 1111"}
                  removeMask={true}
                  className={"form-control " + this.state.errorPhone}
                  readOnly={false}
                  onChange={(value) => { this.handleVerifyData(value, "phoneNumber", setHeader, setBody, setIsOpen, setLevel) }}

                />
              </FormGroup>
              <FormGroup>
                <p><small><font color="red">CUIDADO: Esta mudança irá refletir em todas as propostas deste cliente, inclusive as não encerradas. </font></small></p>
                <p><small>Toda modificação é logada para efeitos de audição. Pode-se alterar apenas um dos campos (e-mail/telefone) ou ambos.</small></p>
                <p><small>Quaisquer dúvidas, favor consultar a página: <a href="https://sites.google.com/finanzero.com.br/intranet/cof" target="_blank" rel="noopener noreferrer">https://sites.google.com/finanzero.com.br/intranet/cof</a>.</small></p>
              </FormGroup>
              <FormGroup>
                <Button style={{ width: "47.5%", marginRight: "5%" }} onClick={onCancel}>Voltar</Button>
                <Button style={{ width: "47.5%" }}
                  bsStyle="warning"
                  onClick={() => {
                    this.lastVerifyUserData(setHeader, setBody, setIsOpen, setLevel)
                  }}
                  disabled={!this.state.touchedForm}>
                  Confirmar
                </Button>
              </FormGroup>
            </FzCard.Body>
          </FzCard>
        )}

      </ToastContext.Consumer>
    );
  }
}

export { EditTelephoneEmail }
