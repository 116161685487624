import { Meta } from '../../types/metas';

export const agentMeta: Meta = {
  agent: {
    path: 'agent.agent',
    label: 'Integração',
    tableProps: {
      width: '30ch',
      isKey: true,
      "dataSort": true,
      filter: { type: 'TextFilter', delay: 500 },
    },
  },
  packageVersion: {
    path: 'configuration.runtime.version',
    label: 'Versão',
    tableProps: {
      width: '10ch',
      isKey: false,
      "dataSort": true,
      filter: { type: 'TextFilter', delay: 500 },
    },
  },
  coreVersion: {
    path: 'configuration.runtime.dependencies',
    label: 'Core',
    tableProps: {
      width: '10ch',
      isKey: false,
      "dataSort": true,
      filter: { type: 'TextFilter', delay: 500 },
    },
  },
  nodeVersion: {
    path: 'container.Config.Env.NODE_VERSION',
    label: 'Node',
    tableProps: {
      width: '10ch',
      isKey: false,
      "dataSort": true,
      filter: { type: 'TextFilter', delay: 500 },
    },
  },
  isMock: {
    path: 'container.Args',
    label: 'Mock?',
    tip: "Demonstra se o agente está atualmente rodando em versão Mock (utilizando `integration.mock`)",
    tableProps: {
      width: '10ch',
      isKey: false,
      "dataSort": true,
      filter: { type: 'TextFilter', delay: 500 },
    },
  },
  status: {
    path: 'agent.status',
    label: 'Container Status',
    tableProps: {
      width: '30ch',
      isKey: false,
    },
  },
  configuration: {
    path: 'configuration',
    label: 'Configurações',
    tableProps: {
      width: '20ch',
      isKey: false,
    },
  },
  actions: {
    path: 'actions',
    label: 'Ações',
    tableProps: {
      width: '10ch',
      isKey: false,
    },
  },
};
