import React, { useState, useEffect } from 'react';
import { PrioritizedBidsLayout } from '../../prioritized-bids-layout'
import qs from 'qs';
import moment from 'moment';
import { getPrioritizedBidsReport } from '../../../../../../services/api/screening';

function PrioritizedBids(props) {

  const [requestState, setRequestState] = useState("idle")
  const [data, setData] = useState(null)
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters]: any = useState({
    createdAfter: null,
    createdBefore: null,
  })
  const { ns } = props

  useEffect(() => {
    if (urlChecked) {
      getStatistics()
    }
  }, [filters])

  useEffect(() => {
    ns.startEditing({
      createdAfter: moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0 }).local(),
      createdBefore: moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).local()
    })
    getQueryStringFromUrl()
  }, [])

  function setUrlQueryString() {
    if (ns.getChanged()) {
      var lastChanged = ns.getChanged()
      
      var url = {
        createdAfter: lastChanged.createdAfter instanceof moment ? lastChanged.createdAfter._d : "",
        createdBefore: lastChanged.createdBefore instanceof moment ? lastChanged.createdBefore._d : "",
      }
      setFilters(url)
      
      const query = qs.stringify(url, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;

      history.pushState({ path: newurl }, '', newurl);
    }
  }

  function getFilters() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());

    if (params.createdAfter || params.createdBefore || params.supplierInternalName) {

      if (params.createdAfter instanceof moment) {
        params.createdAfter = params.createdAfter._d
      }
      if (params.createdBefore instanceof moment) {
        params.createdBefore = params.createdBefore._d
      }

      ns.startEditing(params)
      setFilters(params)
    } else {
      var dateI = moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0, }).local()
      var dateB = moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0, }).local()
      ns.startEditing({
        createdAfter: dateI,
        createdBefore: dateB
      })
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getStatistics() {
    setRequestState("requesting")

    if (filters) {
      ns.saveChange(filters)
    }

    try {
      let analyticsResult: any = await await getPrioritizedBidsReport(getFilters())
      setData(analyticsResult.data)
      setRequestState("idle")
    } catch (err) {
      let error: $TsFixMe = err
      console.log(error.response)
    }
  }

  return (
    <PrioritizedBidsLayout 
      data={data}
      setUrlQueryString={setUrlQueryString}
      ns={ns}
    />
  )
}

export { PrioritizedBids }
