import { useState, useEffect } from 'react';
import ReactJson from 'react-json-view';
import clone from "lodash/cloneDeep";
import qs from 'qs';

import { FzButton } from '../../fz/form/button';
import { FzTable } from '../../fz/grid/table';
import FieldConnected from '../../components/form/field-connected';
import { _getExtraData } from '../../../components/table-standard';
import { FzCard, FzBadge } from '../../fz/layout/index';

import api from '../../../api/client-api';
import { getAllSuppliers } from '../../../api/suppliers';
import { getQueueSearch, getAllQueuesAgent, getApplicationTraces } from '../../../api/integrations'

import { agentQueueAnalytic } from '../../../biz/metadatas/integration-metadata';
import { ReprocessMultipleModal } from '../../blocks/aggregations/ReprocessMultipleQueues';
import { FiltersType, QueueAggregation, QueueEntryType, QueueTraceType } from '../../../biz/metadatas/aggregate-queues-metadata';
import { getUrlParams, normalizeSuppliersList, parseParams } from './utils';
import { ItemListType } from '../../../biz/metadatas/bifrost/reprocessing-metadata';
import { TraceModal } from '../../blocks/integration-queues';

export function AggregationSearch(props) {
  const [suppliersList, setSuppliersList] = useState<ItemListType[]>([])
  const [queuesList, setQueuesList] = useState<string[]>([])
  const [urlChecked, setUrlChecked] = useState<boolean>(false)
  const [disableSearchButton, setDisableSearchButton] = useState<boolean>(true)
  const [disableQueuesButton, setDisableQueuesButton] = useState<boolean>(true)
  const [disableReprocessingButton, setDisableReprocessingButton] = useState<boolean>(true)
  const [showReprocessing, setShowReprocessing] = useState<boolean>(false)
  const [oldSupplier, setOldSupplier] = useState<string>("")
  const [filters, setFilters] = useState<FiltersType>({
    createdAfter: null,
    createdBefore: null,
    supplierInternalName: null,
    includeQueuesInternalName: null,
    excludeQueuesInternalName: null,
    active: null,
    canceled: null,
    timeout: null,
    limit: null
  })
  const [showTraceModal, setShowTraceModal] = useState<boolean>(false)
  const [queueTrace, setQueueTrace] = useState<Array<QueueTraceType>>([])
  const [disableTraceButton, setDisableTraceButton] = useState<boolean>(true)
  
  const { ns } = props
  let data = ns.getShared("filters") || ns.getChanged()
  let customField = clone(QueueAggregation["supplierInternalName"])
  let customField2 = clone(QueueAggregation["includeQueuesInternalName"])
  let customField3 = clone(QueueAggregation["excludeQueuesInternalName"])
  let readOnly = true
  if (suppliersList) {
    customField.inputProps.options = [...suppliersList]
    customField.inputProps.disableSaveOnEnter = true;
    customField.inputProps.lookupIncludingCode = true;
    customField.inputType = "auto-complete";
  }
  if (queuesList) {
    customField2.inputProps.options = [...queuesList]
    customField3.inputProps.options = [...queuesList]
    readOnly = false
  }

  agentQueueAnalytic.queue.tableProps.columnFormat = ""

  useEffect(() => {
    if (urlChecked && !!filters.supplierInternalName && !!filters.includeQueuesInternalName) {
      getQueues()
    }
  }, [filters])

  useEffect(() => {
    if (api.isITUser() && api.isSuperUser()) {
      setShowReprocessing(true)
    }
  }, [showReprocessing])
  
  useEffect(() => {
    if (api.isITUser() && api.isSuperUser()) {
      setDisableTraceButton(false)
    }
  }, [disableTraceButton])

  useEffect(() => {
    let data = ns.getChanged();
    if (data && data.createdAfter && data.createdBefore && data.supplierInternalName) {
      setDisableQueuesButton(false)
    } else {
      setDisableQueuesButton(true)
    }
    if (data && data.createdAfter && data.createdBefore && data.supplierInternalName && data.includeQueuesInternalName && Object.keys(data.includeQueuesInternalName).length > 0) {
      setDisableSearchButton(false)
    } else {
      setDisableSearchButton(true)
    }
    if (ns.get("queueSimple") && ns.get("queueSimple").length > 0) {
      setDisableReprocessingButton(false)
    } else {
      setDisableReprocessingButton(true)
    }
    if (data.supplierInternalName !== oldSupplier) {
      setQueuesList([])
      setDisableSearchButton(true)
    }
    if (data && data.queueInternalName) {
      ns.unset("CHANGED.queueInternalName")
    }
  }, [ns])

  useEffect(() => {
    ns.startEditing({
      createdAfter: new Date(new Date().setHours(-(24 * 7), 0, 0, 0) - (new Date().getTimezoneOffset() * 60 * 1000)),
      createdBefore: new Date(new Date().setHours(23, 59, 59, 0) - (new Date().getTimezoneOffset() * 60 * 1000))
    });
    ns.unset("CHANGED.queueInternalName")
    ns.unset("CHANGED.active")
    ns.unset("CHANGED.canceled")
    ns.unset("CHANGED.timeout")
    ns.unset("CHANGED.limit")
    ns.unset("queueSimple")
    buildSuppliersList()
    getQueryStringFromUrl()
  }, [])

  function setUrlQueryString() {
    if (ns.getChanged()) {
      const lastChanged = ns.getChanged()
      setFilters(lastChanged)

      const url = getUrlParams(lastChanged, true)

      const query = qs.stringify(url, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;

      history.pushState({ path: newurl }, '', newurl);
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let params: any = Object.fromEntries(urlSearchParams.entries());
    parseParams(params);

    ns.startEditing(params)
    setFilters(params)

    setUrlQueryString()
    setUrlChecked(true)
  }

  async function buildSuppliersList() {
    try {
      const nsSuppliers = ns.get("suppliersList");
      if (nsSuppliers && nsSuppliers.length > 0) {
        setSuppliersList(nsSuppliers);
      } else {
        const suppliers = await getAllSuppliers();
        const normalizedSuppliersList = normalizeSuppliersList(suppliers);
        ns.set("suppliersList", normalizedSuppliersList)
        setSuppliersList(normalizedSuppliersList);
      }
    } catch (error) {
      console.warn("API ERROR", error);
    }
  }


  async function buildQueuesList(params) {
    try {
      parseParams(params)
      setUrlQueryString()

      const queuesListResponse = await getAllQueuesAgent(params)
      const queueItem = (queue: any) => ({ code: queue, description: queue })
      const queuesNormalize = queuesListResponse.map(queueItem)
      setQueuesList(queuesNormalize)
      setOldSupplier(params.supplierInternalName)
      ns.unset("CHANGED.includeQueuesInternalName")
      ns.unset("CHANGED.excludeQueuesInternalName")
      ns.unset("CHANGED.active")
      ns.unset("CHANGED.canceled")
      ns.unset("CHANGED.timeout")
      ns.unset("CHANGED.limit")
    } catch (error) {
      console.warn("API ERROR", error);
    }
  }

  async function getQueues() {
    try {
      if (api.isITUser() || api.isUserSupplier()) {
        if (filters) {
          ns.saveChange(filters)
        }
        const info = ns.getChanged();
        let params = {}
        Object.keys(info).forEach(key => { if (!!info[key]) params[key] = info[key] })

        params = {
          ...params,
          ...(info.createdAfter && { createdAfter: new Date(info.createdAfter).toJSON() }),
          ...(info.createdBefore && { createdBefore: new Date(info.createdBefore).toJSON() }),
        }
        const result = await getQueueSearch(params)
        ns.set("queueSimple", result)
      }
    } catch (error) {
      alert("Manager Error.")
      console.warn("MANAGER ERROR", error);
    }
  }

  function activeCol(cell, row) {
    if (row.canceled) {
      return (<FzBadge fzStyle="alert" >canceled</FzBadge>)
    }
    return (<FzBadge fzStyle={cell ? "success" : "default"} >{cell.toString()}</FzBadge>)
  }

  function attemptsColumn(cell, row) {
    let text = cell + " / " + row.maxAttempts
    if (cell === row.maxAttempts) {
      return <FzBadge fzStyle="attention" >{text}</FzBadge>
    }
    return text
  }

  function traceColumn(row: QueueEntryType) {
    const data = ns.getChanged()
    const appId = row.applicationId;
    const supplier = data.supplierInternalName || row.sqsMessage.SupplierName;
    const queueName = row.queue;
    return (
      <FzButton
        onClick={() => {
          setShowTraceModal(true)
          retrieveTrace(appId, supplier, queueName);
        }}
        disabled={disableTraceButton}
      >
        Trace
      </FzButton>
    );
  }
  
  async function retrieveTrace(appId: string, supplier: string, queue: string) {
    const trace = await getApplicationTraces(appId, supplier, queue)
    setQueueTrace(trace)
  }
  
  function expandComponent(row: object) {
    return (
      <FzCard>
        <FzCard.Body>
          {
            row ?
              <ReactJson
                collapsed={2}
                displayDataTypes={false}
                src={row}
                style={{ overflowWrap: "anywhere" }} /> : "Nenhum objeto encontrado"
          }
        </FzCard.Body>
      </FzCard>
    )
  }

  const centeredComponentsStyle = {
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <FzCard>
      <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title> Busca Avançada <FzButton onClick={() => window.open('https://sites.google.com/finanzero.com.br/intranet/cof/agentes?authuser=0')} bsSize="xsmall">Ajuda</FzButton></FzCard.Title>
        </FzCard.Heading>
        <div className={"fz-panel"} >
          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FieldConnected data={data} meta={QueueAggregation["createdAfter"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FieldConnected data={data} meta={QueueAggregation["createdBefore"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            {suppliersList && <FieldConnected data={data} meta={customField} ns={ns} />}
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FzButton
              disabled={disableQueuesButton}
              onClick={() => buildQueuesList(ns.getChanged())}
              bsSize="xsmall">
              Carregar Filas
            </FzButton>
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FieldConnected data={data} meta={QueueAggregation["active"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FieldConnected data={data} meta={QueueAggregation["canceled"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FieldConnected data={data} meta={QueueAggregation["timeout"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FieldConnected data={data} meta={QueueAggregation["limit"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            <FzButton
              onClick={setUrlQueryString}
              tip={"buscar"}
              disabled={disableSearchButton}
              className={null}
              block={true}
              fzStyle={"primary"}
            >
              Buscar
            </FzButton>
          </div>

          {showReprocessing &&
            <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
              <ReprocessMultipleModal ns={ns} data={data} user={api.getCurrentUser()} disabled={disableReprocessingButton} />
            </div>}

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            {queuesList.length === 0 ? "Filas Não Carregadas" : <FieldConnected data={data} readOnly={readOnly} customClass={'col-lg-4 col-md-12'} meta={customField2} ns={ns} />}
          </div>

          <div className={"fz-panel-border-padding"} style={centeredComponentsStyle}>
            {queuesList.length === 0 ? "Filas Não Carregadas" : <FieldConnected data={data} readOnly={readOnly} customClass={'col-lg-4 col-md-12'} meta={customField3} ns={ns} />}
          </div>

        </div>
      </FzCard>

      <FzCard.Body>
        {ns.get("queueSimple") && (
          <FzTable
            ns={ns}
            data={ns.get("queueSimple")}
            metas={agentQueueAnalytic}
            pagination
            customColumnFormatters={{
              subQueue: (cell: string) => cell === "none" ? "" : cell,
              active: (cell: any, row: QueueEntryType) => activeCol(cell, row),
              attempts: (cell: any, row: QueueEntryType) => attemptsColumn(cell, row),
              trace: (_:any, row: QueueEntryType) => traceColumn(row)
            }}
            rowExpand={{
              expandComponent: (row) => expandComponent(row)
            }}
            exportDownload={false}
            clipboard={false}
            visibleColumns={["_id", "applicationId", "queue", "created", "updated", "active", "canceled", "attempts", "trace"]}
          />)}
      </FzCard.Body>
      {showTraceModal && <TraceModal show={showTraceModal} traceContent={queueTrace} onHide={() => setShowTraceModal(false)} />}
    </FzCard >

  )
}
