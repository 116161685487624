import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import Field from '../../../components/field';
import { GenericBankFiltersSelection } from '../../../application-components/input-domains';
import cloneDeep from 'lodash/cloneDeep';
import { FzCard, FzCol, FzRow, FzForm } from '../../fz/layout/index'
import { FzIcons } from '../../fz/form/icon';
import { FzAlert } from '../../fz/form/alert';
import get from 'lodash/get';
import { FzSelect } from '../../fz/form/old-simple-input';
import { getAllProducts } from '../../../api/applications';


export class SupplierFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = { allProductsOptions: [] }
    this.removeField = this.removeField.bind(this)
    this.selectField = this.selectField.bind(this)
    this.selectProduct = this.selectProduct.bind(this)
    this.switchObjectKey = this.switchObjectKey.bind(this)
  }

  componentDidMount() {
    this.setState({
      allProductsOptions: this.props.productsList
    });
  }

  switchObjectKey(filterCopy, oldVal, newVal) {
    let keys = Object.keys(filterCopy)
    let index = keys.indexOf(oldVal)
    keys[index] = newVal
    filterCopy[newVal] = filterCopy[oldVal]
    let newFilter = keys.reduce((resultSoFar, key) => {
      resultSoFar[key] = filterCopy[key]
      return resultSoFar
    }, {})
    return newFilter
  }

  removeField(product, key) {
    const { supplier, ns, basePath } = this.props
    let filters = get(supplier, basePath)
    if (filters) {
      if (key in filters[product]) {
        let newFilter = cloneDeep(filters[product])
        delete newFilter[key]
        ns.saveChange(basePath + "." + product, newFilter)
      }
    }
  }

  removeProduct(product) {
    const { supplier, ns, basePath } = this.props
    let filters = get(supplier, basePath)
    if (filters) {
      let newFilter = cloneDeep(filters)
      delete newFilter[product]
      ns.saveChange(basePath, newFilter)
    }
  }

  selectField(product, oldVal, newVal) {
    let { supplier, ns, basePath } = this.props
    let filters = get(supplier, basePath)
    if (filters) {
      if (filters[product][newVal]) {
        this.setState({ warning: "This field already exists" })
        return
      } else {
        let filterCopy = cloneDeep(filters[product])
        let newFilter = this.switchObjectKey(filterCopy, oldVal, newVal)
        ns.saveChange(basePath + "." + product, newFilter)
      }
    }
    this.setState({ warning: null })
  }

  selectProduct(oldProd, newProd) {
    let { supplier, ns, basePath } = this.props
    let filters = get(supplier, basePath)
    if (filters) {
      if (filters[newProd]) {
        this.setState({ warning: "This product already exists" })
        return
      } else {
        let filterCopy = cloneDeep(filters)
        let newFilter = this.switchObjectKey(filterCopy, oldProd, newProd)
        ns.saveChange(basePath, newFilter)
      }
    }
    this.setState({ warning: null })
  }

  addField(product) {
    let { supplier, ns, basePath } = this.props
    let filters = get(supplier, basePath, undefined)
    if (filters) {
      let newFilter = cloneDeep(filters[product])
      newFilter['empty'] = undefined
      ns.saveChange(basePath + "." + product, newFilter)
    }
    this.setState({ warning: null })
  }

  addProduct() {
    let { supplier, ns, basePath } = this.props
    let filters = get(supplier, basePath, {})
    let newFilter = cloneDeep(filters)
    newFilter['empty'] = {}
    ns.saveChange(basePath, newFilter)
    this.setState({ warning: null })
  }

  render() {
    let { supplier, ns, readOnly, basePath, branchInFilter } = this.props

    let panelsEdit = []
    let filters = get(supplier, basePath)
    if (filters) {
      for (let product in filters) {
        let fields = []
        for (let key in filters[product]) {
          let path = basePath + "." + product + "." + key
          let deleteFieldButton = (<Button title="Delete Field" onClick={() => this.removeField(product, key)}>< FzIcons icon={"minus"} fzStyle={{ width: "15px", height: "15px" }} /></Button>)
          if (readOnly) {
            deleteFieldButton = null
          }
          fields.push(<FzRow key={key}>
            <FzCol span={3}>
              <GenericBankFiltersSelection
                name={path}
                ns={ns}
                disabled={readOnly}
                value={key}
                onChange={(newVal) => this.selectField(product, key, newVal)} />
            </FzCol>
            <FzCol span={8}>
              <Field
                type="string"
                path={path}
                ns={ns}
                data={supplier}
                noLabel={true}
                cols={1}
              />
            </FzCol>
            <FzCol span={1}>
              {deleteFieldButton}
            </FzCol>
          </FzRow>)
          if (key === "apiImplemented") {
            fields.push(<FzRow key={key + "1"}>
              <FzCol span={12}><a target="_blank" rel="noopener noreferrer" href="https://github.com/finanzero/finanzero.api/blob/master/src/app/services/lending/banks_filters.go">Clique aqui para ver o código fonte</a></FzCol>
            </FzRow>)
          }
        }
        let deleteProductButton = (<Button title="Delete Product Filter" onClick={() => this.removeProduct(product)}>< FzIcons icon={"minus"} fzStyle={{ width: "15px", height: "15px" }} /></Button>)
        let addFilterButton = (<ButtonGroup>
          <Button title="Add Field" onClick={() => this.addField(product)}>
            < FzIcons icon={"plus"} fzStyle={{ width: "15px", height: "15px" }} />
          </Button>
        </ButtonGroup>)
        if (readOnly) {
          deleteProductButton = null
          addFilterButton = null
        }

        let header = (
          <FzForm inline>
            <FzSelect
              readOnly={readOnly}
              value={product}
              name={"filters." + product}
              onChange={(newProd) => this.selectProduct(product, newProd)}
              options={this.state.allProductsOptions}
            />
            {deleteProductButton}
          </FzForm>
        )
        panelsEdit.push(<FzCard key={product}>
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">{header}</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            {addFilterButton}
            <FzCard>
              <FzCard.Body>
                {fields}
              </FzCard.Body>
            </FzCard>
          </FzCard.Body>
        </FzCard>)
      }
    }
    let addProductButton = (<ButtonGroup>
      <Button title="Add Product Filter" onClick={() => this.addProduct()}>< FzIcons icon={"plus"} fzStyle={{ width: "15px", height: "15px" }} /> </Button>
    </ButtonGroup>)
    if (readOnly) {
      addProductButton = null
    }
    let warningMessage = null
    if (this.state.warning) {
      warningMessage = (<FzAlert fzStyle="attention">{this.state.warning}</FzAlert>)
    }

    return (
      <FzCard onClick={(e) => { e.stopPropagation() }} >
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">{"Filters " + (branchInFilter ? "(Utilizar apenas para escolher Filiais (Loja))" : "")}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body >
          <FzRow>
            <FzCol span={12}>
              <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/finanzero.com.br/intranet/parceiros/gerenciamento-de-parceiros">Clique aqui para ver a documentação</a>
            </FzCol>
          </FzRow>
          {warningMessage}
          {addProductButton}
          {panelsEdit}
        </FzCard.Body>
      </FzCard>
    )
  }
}