
import clone from "lodash/cloneDeep"

import { metaFunnel } from '../charts/funnel'
import { metaLine } from '../charts/line'
import { metaBarOverlap } from '../charts/overlaid-bar'
import moment from 'moment';


const STEPS_OF_FUNNEL = ["count", "completed", "hasBids", "paidout"]
const HELP_LEGEND = {
  "count": "Leads",
  "completed": "Has Auction",
  "hasBids": "Received Bids(1 at least)",
  "paidout": "Paid",
}

export const buildDataForLine = (rawData, datas, ns, currPeriod, currentSelected) => {

  let pointStart = Object.keys(datas).reverse()[0]
  const filteredDate = rawData.filter(it => it.product === currPeriod)

  let dataProcess = ["count", "completed", "hasBids", "paidout"].reduce((acumulador, currentName) => {
    let allRegistrys = filteredDate.map(registry => {
      return registry[currentName]
    })
    return [...acumulador, { name: HELP_LEGEND[currentName], data: allRegistrys.reverse() }]
  }, [])


  let newOptions = clone(metaLine.options);
  newOptions.title.text = 'Funnel Operational Overview-' + currPeriod;
  newOptions.series = dataProcess


  switch (currentSelected) {
    case "day":
      newOptions.plotOptions.series.pointInterval = 24 * 3600 * 1000
      break;
    case "week":
      newOptions.plotOptions.series.pointInterval = 24 * 3600 * 1000 * 7
      break;
    case "month":
      newOptions.plotOptions.series.pointIntervalUnit = "month"
      break;
    default:
      return
  }

  newOptions.plotOptions.series.pointStart = moment.utc(pointStart)

  ns.saveChange("dataLineChart", newOptions);
}

export const buildDataForFunnel = (rawData, ns, selectedView, percentageView, currPeriod) => {

  if (!selectedView || !currPeriod) {
    return null
  }

  let filterValues = selectedView === "product" ? ns.getChanged("filter.product") : ns.getChanged("filter.periods")
  const keysOfSelectedView = Object.keys(filterValues)

  const data = keysOfSelectedView.reduce((acumulador, current) => {

    const changeKeys = {
      "period": "product",
      "product": "period"
    }

    let processedData
    if (percentageView) {
      processedData = percentageNumbers(rawData, changeKeys, current, currPeriod, selectedView)
    } else {
      processedData = absoluteNumbers(rawData, changeKeys, current, currPeriod, selectedView)
    }

    let newOptions = clone(metaFunnel.options);
    if (processedData !== null && processedData !== undefined) {
      newOptions.title.text = 'Funnel Operational Overview-' + current;
      newOptions.series[0].data = processedData[0] || [[]];
    }
    return {
      ...acumulador,
      [current]: newOptions
    }

  }, {})

  ns.saveChange("dataFunnelChart", data);



}

export const buildDataForOverlaidBar = (rawData, partners, date, ns) => {

  if (!partners || !date || !ns) {
    return null
  }

  const listOfPartners = Object.keys(partners)
  const valueOfStep1 = getValuesInCategory(listOfPartners, rawData, date, "Eligible", "sent", "count")
  const valueOfStep2 = getValuesInCategory(listOfPartners, rawData, date, "Bidded", "bidsCount", "sent")
  const valueOfStep3 = getValuesInCategory(listOfPartners, rawData, date, "Accepted", "bidsAccepted", "bidsCount")
  const valueOfStep4 = getValuesInCategory(listOfPartners, rawData, date, 'Paid (self)', "paidBySupplier", "bidsAccepted")
  const valueOfStep5 = getValuesInCategory(listOfPartners, rawData, date, "Paid (Others)", "paidByOther", "bidsAccepted")

  let meta = clone(metaBarOverlap);
  meta.xAxis.categories = listOfPartners
  meta.series[0].data = valueOfStep1.data
  meta.series[1].data = valueOfStep2.data
  meta.series[2].data = valueOfStep3.data
  meta.series[3].data = valueOfStep4.data
  meta.series[4].data = valueOfStep5.data

  ns.set("dataOverlaidBarChart", meta)

}

const percentageNumbers = (rawData, changeKeys, current, currPeriod, selectedView) => {
  return rawData
    .filter(it => it[changeKeys[selectedView]] === currPeriod && it[selectedView] === current)
    .map(it => {
      var dataInSteps = STEPS_OF_FUNNEL.map(step => {

        let value = (it[step], 100)
        if (step !== "count") {
          value = +(it[step] / it["count"] * 100).toFixed(2)
        }
        return [HELP_LEGEND[step], value];
      });
      return [...dataInSteps];
    });
}

const absoluteNumbers = (rawData, changeKeys, current, currPeriod, selectedView) => {

  if (!rawData || !currPeriod) {
    return null
  }

  const data = rawData
    .filter(it => it[changeKeys[selectedView]] === currPeriod && it[selectedView] === current)
    .map(it => {
      var dataInSteps = STEPS_OF_FUNNEL.map(step => {
        return [HELP_LEGEND[step], it[step]];
      });
      return [...dataInSteps];
    });

  return data
}

const getValuesInCategory = (listOfPartners, rawData, date, category, operador1, operador2) => {

  const value = listOfPartners.map(parceiro => {
    const data = rawData.find(elemt => elemt.supplier === parceiro && elemt.period === date);
    if (!data) {
      return null;
    }
    return +(data[operador1] / data[operador2] * 100).toFixed(2) || 0;
  })


  return {
    category,
    data: value
  }
}
