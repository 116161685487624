import React, { Component } from 'react';
import { PlaceBid, RejectCustomer, ShowBid } from './bid';
import ApplicationSummary from './application-summary';
import { AuctionInfo } from '../application-components/auction-info';
import { getCurrentBid } from '../business/application'
import { validApplicationSupplier } from '../business/application-state';
import { ApplicationSection } from '../ui/blocks/application-section'
import { Application, flatApplication } from '../biz/metadatas/application';
import { FzCard, FzCardGroup, FzCol, FzRow, FzJumbotron } from '../ui/fz/layout/index';
import { getAllProducts, getByID } from '../api/applications';
import {  suppliersMe } from '../api/suppliers';
import { FzButton } from '../ui/fz/form';

class BidApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtons: true,
      showReject: false,
      showBid: false
    };
    this.placeBid = this.placeBid.bind(this);
    this.rejectCustomer = this.rejectCustomer.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.bidPlaced = this.bidPlaced.bind(this);
  }
  placeBid() {
    this.setState({ showButtons: false, showBid: true })
  }
  rejectCustomer() {
    this.setState({ showButtons: false, showReject: true })
  }
  cancelEdit() {
    this.setState({ showButtons: true, showReject: false, showBid: false })
  }
  bidPlaced() {
    this
      .props
      .closeButton(true);
  }
  render() {
    const actionsButtons = (
      <FzCard>
        <FzCard.Body>
          <div className="vertical-button-list">
            <FzButton fzStyle="regular" onClick={this.placeBid} block>Fazer Oferta</FzButton>
            <FzButton fzStyle="attention" onClick={this.rejectCustomer} block>Rejeitar Cliente</FzButton>
          </div>
        </FzCard.Body>
      </FzCard>
    )
    const rejectPanel = (<RejectCustomer
      fzApi={this.props.api}
      application={this.props.application}
      supplier={this.props.supplier}
      cancelEdit={this.cancelEdit}
      confirmBid={this.bidPlaced} />)
    const placeBidPanel = (<PlaceBid
      fzApi={this.props.api}
      application={this.props.application}
      supplier={this.props.supplier}
      cancelEdit={this.cancelEdit}
      confirmBid={this.bidPlaced} />)

    var rightPanel;
    let currentBid = getCurrentBid(this.props.application, this.props.supplier.id);
    if (currentBid) {
      rightPanel = (<ShowBid
        bid={currentBid}
        application={this.props.application} />);
    } else if (this.state.showButtons) {
      rightPanel = actionsButtons;
    } else if (this.state.showReject) {
      rightPanel = rejectPanel;
    } else { // show BidsPanel
      rightPanel = placeBidPanel;
    }
    return (rightPanel);
  }
}

class SupplierApplication extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      working: false , 
      allProductsList: [], 
    }
  }

  componentDidMount() {
    this.getApplication();
    this.loadCurrentSupplier();
    this.loadAllProducts();
  }


  async loadAllProducts() {
    const productsResp = await getAllProducts();
    this.setState({
      allProductsList: productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    });
  }

  async loadCurrentSupplier() {
    localStorage.getItem("currentUser")
    const supplier = JSON.parse(localStorage.getItem("currentUser"))
    const supplierResponse = await suppliersMe(supplier)
    this.setState({ supplier: supplierResponse });
  }

  getApplication() {
    const { ns }  = this.props
    getByID(this.props.match.params.applicationId)
      .then((application) => {
        ns.set("application", application);
      })
      .catch((error) => {
        console.error(error);
        this.props.history.push("/suppliers");
      })
  }

  render() {
    let ns = this.props.ns;
    var app = ns.getChanged() || ns.get("application")
    const metas = flatApplication(Application.fields)

    const identifier = metas.filter(m => m.section === "identifier")
    const personalData = metas.filter(m => m.section === "personal-data")
    const operationDetails = metas.filter(m => m.section === "operation-details")
    const residenceData = metas.filter(m => m.section === "residence-data")
    const workData = metas.filter(m => m.section === "work-information")
    const vehicleSecurityData = metas.filter(m => m.section === "vehicle-security-data")
    const homeSecurityData = metas.filter(m => m.section === "home-security-data")
    const bankInformation = metas.filter(m => m.section === "bank-data")
    const score = metas.filter(m => m.section === "score")


    let metaProduct =  operationDetails.filter(it =>  it.path === 'product' )[0]
    metaProduct.inputProps =  { options: this.state.allProductsList }


    if(!app){
      this.getApplication()
      return null;
    }


    if(!this.state.supplier){
      console.warn("No supplier associated with user")
      this.loadCurrentSupplier()
      return null;
    }
  
    if (!validApplicationSupplier(app, this.state.supplier)) {
      this.props.history.push("/suppliers")
    }

    let buttons = null
    let security = null

    if (app.product === "Refin") {
      security = <ApplicationSection ns={ns} data={app} metas={vehicleSecurityData} title="Detalhes do Veiculo" />
    } else if (app.product === "HE") {
      security = <ApplicationSection ns={ns} data={app} metas={homeSecurityData} title="Detalhes do Imóvel" />
    }

    const metasPersonalData = identifier.concat(personalData)

    return (
      <FzJumbotron>
        <FzCardGroup id="SupplierApplication">
          {buttons}
          <FzRow>
            <FzCol span={8}>
              <ApplicationSection ns={ns} data={app} metas={metasPersonalData} title="Dados Pessoais" />
              <AuctionInfo ns={ns} app={app} api={this.props.api} showBidsPanel={false} showPartnersData={false} history={this.props.history} />
              <ApplicationSection ns={ns} data={app} metas={operationDetails} title="Detalhes da Operação" />
              <ApplicationSection ns={ns} data={app} metas={residenceData} title="Dados da Residência" />
              <ApplicationSection ns={ns} data={app} metas={workData} title="Dados de Trabalho" />
              <ApplicationSection ns={ns} data={app} metas={bankInformation} title="Dados Bancários" />
              <ApplicationSection ns={ns} data={app} metas={score} title="Score" />
              {security}
            </FzCol>
            <FzCol span={4}>
              <ApplicationSummary application={app} api={this.props.api} />
              <BidApplication
                closeButton={() => this.props.history.push("/suppliers/biddable")}
                application={app}
                supplier={this.state.supplier}
                />

            </FzCol>
          </FzRow>
        </FzCardGroup>
      </FzJumbotron>
    );
  }
}

export { SupplierApplication };