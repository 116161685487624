import { useEffect, useState } from 'react'
import { get } from 'lodash'
import Papa from 'papaparse'

import {
  getAllSreeningRules,
  insertScreeningRules,
  insertImportScreeningRules,
  updateScreeningRules,
} from '../../../../services/api/screening'
import { RulesContainer } from '../../../../Containers/Screening/Rules'
import { IScreeningRules } from '../../../../services/api/screening/types'
import { RulesCustom } from '../../../../biz/metadatas/rules-screening'
import { getSuppliersList } from '../../../../scripts/utils/cache-helpers'

const toastDefaultConfig = {
  fzStyle: "danger",
  headerMessageTost: "Error",
  bodyMessageTost: "Erro interno, tente novamente!",
  show: false,
  delay: 1000,
  autoHide: false,
  close: () => null
}

const toastMessages = {
  insert: {
    error: {
      fzStyle: "danger",
      headerMessageTost: "Erro ao inserir",
      bodyMessageTost: "Preencha os campos obrigatórios para inserir uma linha.",
    },
    success: {
      fzStyle: "success",
      headerMessageTost: "Regras de Triagem",
      bodyMessageTost: "Uma nova linha foi inserida.",
    },
  },
  get: {
    error: {
      fzStyle: "danger",
      headerMessageTost: "Erro: Regras de Triagem",
      bodyMessageTost: "Não foi possível buscar regras de triagem",
    },
  },
  import: {
    error: {
      fzStyle: "danger",
      headerMessageTost: "Erro: Regras de Triagem",
      bodyMessageTost: "Não foi possível importar todas as regras de triagem",
    },
  },
  update: {
    error: {
      fzStyle: "danger",
      headerMessageTost: "Erro ao atualizar",
      bodyMessageTost: "Não foi possível atualizar esse campo, tente novamente",
    },
    success: {
      fzStyle: "success",
      headerMessageTost: "Regra atualizada com sucesso",
      bodyMessageTost: "Está regra está atualizada a partir de agora",
    },
  }
}

export function ScreeningRules({ ns }) {
  const [toast, setToast] = useState(toastDefaultConfig)
  const [data, setData] = useState<IScreeningRules[]>([])

  useEffect(() => {
    getRules()
    getSupplierOptions()
  }, [])

  const handleToast = (type) => {
    setToast({
      ...get(toastMessages, type, {}),
      show: true,
      delay: 5000,
      autoHide: true,
      close: () => setToast(toastDefaultConfig)
    })
  }

  const handleUpdateRule = async (rule, fieldName, value): Promise<void> => {
    try {
      const payload: IScreeningRules = {
        ...rule,
        [fieldName]: value,
      }
      await updateScreeningRules(payload)
      handleToast('update.success')
    } catch (error) {
      handleToast('update.error')
    }
  }

  const handleInsert = (newRule: IScreeningRules): void => {
    if (newRule.supplierInternalName === "Selecione") {
      newRule.supplierInternalName = ''
    }
    insertScreeningRules(newRule)
      .then(() => handleToast('insert.success'))
      .catch(() => handleToast('insert.error'))
  }

  const handleUploadFile = async (_, event): Promise<void> => {
    const file = event.target.files[0]
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          try {
            if (results.data) {
              await Promise.all(
                results.data.map(async (item) => {
                return await insertImportScreeningRules(item)
              }))
            }
          } catch (error) {
            handleToast('insert.error')
          }
        },
      })
    } catch (error) {
      console.error(error, 'insert.error')
    }
  }

  const getRules = async (): Promise<void> => {
    try {
      const response = await getAllSreeningRules()
      setData(response.data)
    } catch (error) {
      handleToast('get.error')
    }
  }

  const getSupplierOptions = async (): Promise<void> => {
    try {
      const options = await getSuppliersList(ns)
      if (
        RulesCustom.supplierInternalName
        && RulesCustom.supplierInternalName.tableProps
        && RulesCustom.supplierInternalName.tableProps.editable
      ) {
        RulesCustom.supplierInternalName.tableProps.editable.options = options
      }

    } catch (error) {
      console.error(error)
    }
  }


  return (
    <RulesContainer
      data={data}
      handleUpdateRule={handleUpdateRule}
      handleInsert={handleInsert}
      handleUploadFile={handleUploadFile}
      meta={RulesCustom}
      ns={ns}
      toast={toast}
    />
  )
}
