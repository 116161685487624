import api from './client-api'

export const getAllSuppliers = async () => {
  let serviceURL = '/suppliers/all';
  const supplier = await api.Get(serviceURL);
  supplier.sort((a,b)=> a.name.localeCompare(b.name))
  return supplier;
}


export const getSupplierById = (id) => {
  return api.Get('/suppliers/' + id);
}

export const createSupplier = (data) => {
  return api.Post("/suppliers/", data)
}

export const updateSupplier = (supplier) => {
  return api.Put("/suppliers/" + supplier.id, supplier);
}

export const disableNewApplications = (id, newState) => {
  let data = { currentState: !newState, newState: newState }
  return api.Post("/suppliers/" + id + "/disablenew", data);
}

export const rejectCustomer = (id, supplierInternalName, reason) => {
  var json = { Reason: reason };
  var serviceUrl = '/applications/' + id + '/supplierdata/' + supplierInternalName + '/reject';
  return api.Post(serviceUrl, json)
}

export const buildImgUrl = (image) => {
  return api.baseStaticFilesUrl + image
}

export const getPayoutInfo = (periodo) => {
  return api.Get('/suppliers/me/applications/bids/payout')
}

export const postPayoutInfo = (paymentPayload) => {
  return api.Post('/suppliers/me/applications/bids/payout', paymentPayload)
}

export const getPayouts = (id) => {
  return api.Get('/suppliers/me/applications/bids/payout/' + id)
}


export const getApplicationsWithAcceptedBids = (page, product) => {
  let serviceUrl = '/suppliers/me/applications/bids/accepted?pageSize=' + 200 + '&page=' + page;
  if (product !== null && product !== "*") {
    serviceUrl += "&product=" + product
  }
  return api.Get(serviceUrl)
}

export const getApplicationsWithBids = (page, product) => {
  let serviceUrl = '/suppliers/me/applications/bids?pageSize=' + 200 + '&page=' + page;
  if (product !== null && product !== "*") {
    serviceUrl += "&product=" + product
  }
  return api.Get(serviceUrl)
}

export const getBiddableCurrentSupplier = (page, product, branch, sent) => {
  let serviceUrl = '/suppliers/me/applications/open?pageSize=4000&page=' + page;
  if (product !== null && product !== "*") {
    serviceUrl += "&product=" + product
  }

  if (branch !== null && branch !== "") {
    serviceUrl += "&branch=" + branch
  }

  if (sent) {
    serviceUrl += "&sent=true"
  } else {
    serviceUrl += "&notSent=true"
  }

  return api.Get(serviceUrl)
}

export const supplierSend = (applicationId, supplierInternalName, branchCode, channel) => {
  let data = { branchcode: branchCode, channel: channel }
  return api.Post("/applications/" + applicationId + "/supplierdata/" + supplierInternalName + "/send", data)
}

export const suppliersMe = (supplierUser) => {
  return api.Get("/suppliers/me", supplierUser)
}


export const addBid = (id, bid) => {
  return api.Post('/applications/' + id + '/bids', bid)
}