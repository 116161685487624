import React from 'react'
import { Button } from 'react-bootstrap'
import { FzCard, FzSplitLine } from '../fz/layout/index'
import {  getParameterNames } from '../../api/parameters-service'
import { FzTable } from '../fz/grid/table';
import { parameters } from '../../biz/metadatas/parameters'
import {  FzButton } from '../fz/form';
import { FzIcons } from '../fz/form/icon';

export class SystemParams extends React.Component {


  constructor(props) {
    super(props)

    this.showDomain = this.showDomain.bind(this)
    this.idColumn = this.idColumn.bind(this)

    this.state = { requestState: "idle" };
    this.refreshDomains = this.refreshDomains.bind(this)

  }

  showDomain(cell) {
    this.props.history.push("/parameters/config/" + cell)
  }

  idColumn(cell, row) {
    return (<div>
      <Button title={cell} onClick={() => this.showDomain(cell)} bsSize="xsmall">
        < FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Ver
          </Button>
    </div>
    );
  }


  refreshDomains() {
    const { ns } = this.props
    this.setState({ requestState: "requesting" }, function () {
      getParameterNames()
        .then(json => {
          var data = json
          .map(it => {
            return { domainId: it, domainName: it }
          })
          ns.set('parameters', data)
        })
    })

  }

  componentDidMount(){
    this.refreshDomains()
  }

  render() {

    const { ns } = this.props;

    let data = ns.get("parameters");
    let { requestState } = this.state;

    if (!data) {
      if (requestState === "idle") {
        this.refreshDomains();
      }
      return null;
    }

    if (!data && requestState === "requesting") {
      return null
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzSplitLine>
            <FzSplitLine.Left>Parameters System</FzSplitLine.Left>
            <FzSplitLine.Right>
              {<FzButton fzStyle="attention" onClick={() => this.refreshDomains()}>Refresh</FzButton> }
            </FzSplitLine.Right>
          </FzSplitLine>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={data}
            metas={parameters}
            ns={ns}
            exportDownload={false}
            clipboard={false}
            visibleColumns={["domainId", "domainName"]}
            customColumnFormatters={
              { domainId: (cell, row) => this.idColumn(cell, row) }
            }
          />
        </FzCard.Body>
      </FzCard>
    )
  }
}
