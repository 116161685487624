import { Application } from "../../biz/metadatas/application"
import { createExportBasedOnMetas } from "./csv-exporter-utils/exporting-utils"

const columnsToExport = [
  "id",
  "fullName",
  "personalNumber",
  "paid.paidDate",
  "paid.supplierInternalName",
  "product",
  "paid.paidAmount",
  "paid.installments"
]

function getCsvFinancialPaidReport(jsonList) {
  return createExportBasedOnMetas(Application.fields, columnsToExport, jsonList)
}

export { getCsvFinancialPaidReport }
