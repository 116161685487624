import React, { useState, useEffect } from 'react';
import qs from 'qs';

import { getCsTreatmentsSummary } from "../../../api/crm";
import { setTTL } from "../../../business/misc-functions";
import { FzTable } from "../../fz/grid/table";
import { FzCard } from "../../fz/layout/FzCard";
import { FzNavSelect } from "../../fz/nav";
import { FzSplitLine } from '../../fz/layout';
import { StatsDetailHeader } from '../../../statistics/stats-components';

interface CsTreatmentsProps {
  ns: any;
  metas: {};
  toggleBlocking: (
    callback?: any
  ) => void;
}

export function CsTreatments({ ns, toggleBlocking, metas }: CsTreatmentsProps) {

  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({ period: "day" })


  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])

  async function getStatistics() {
    try {
      setRequestState("requesting")
      toggleBlocking();
      const csTreatmentsResults = await getCsTreatmentsSummary(getFilters());
      ns.set("csTreatments", csTreatmentsResults, setTTL(5))
      toggleBlocking();
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      toggleBlocking()
    }
  }

  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
    getStatistics()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  let data = ns.get("csTreatments", {});
  if (!data && requestState === "requesting") {
    return null
  }

  return (
    <FzCard>
      <FzCard.Header>
        <FzCard.Title>
          <FzSplitLine>
            <FzSplitLine.Left></FzSplitLine.Left>
            <FzSplitLine.Right>
              <FzNavSelect
                value={filters.period}
                options={[
                  { "code": "day", "description": "Daily" },
                  { "code": "week", "description": "Weekly" },
                  { "code": "month", "description": "Monthly" },
                ]}
                onChange={(value) => {
                  setFilters({ ...filters, period: value })
                }}
              />
            </FzSplitLine.Right>
          </FzSplitLine>
          <div className="mt-3">
            <StatsDetailHeader
              title={'Cs Treatments'}
              periods={undefined}
              currPeriod={undefined}
              onChange={undefined}
              stats={data}
            />
          </div>
        </FzCard.Title>
      </FzCard.Header >
      <FzCard.Body>
        <FzTable
          exportDownload
          pagination
          ns={ns}
          context={"statsSalesTreatments"}
          data={data.statistics}
          metas={metas}
          visibleColumns={["key", "period", "agent", "product", "treatment", "quantity"]}
          clipboard={true}
        />
      </FzCard.Body>
    </FzCard >
  );
}