import React, { Component } from 'react';
import { FzJumbotron, FzRow, FzCol, FzCard, FzCardGroup } from '../../fz/layout/index';
import { FzButton } from '../../fz/form';
import isEmpty from 'lodash/isEmpty';
import { getUsersByFilter } from '../../../api/auth';
import api from '../../../api/client-api';
import { Application } from '../../../biz/metadatas/application';
import FieldConnected from '../../components/form/field-connected';
import { setCustomerDataToNs } from '../../../biz/user';
import clone from "lodash/cloneDeep"
import { FzAlert } from '../../fz/form/alert';
import { ClientsTable } from '../../blocks/crm/clientsTable'

export class FindCustomer extends Component {
  constructor(props) {
    super(props);
    this.doFindCustomer = this.doFindCustomer.bind(this);
    this.state = { errorMessage: null };
    this.apiError = this.apiError.bind(this);
    this.sendOnEnter = this.sendOnEnter.bind(this);
    this.startService = this.startService.bind(this);
    this.props.ns.setShared('crm.category', this.props.category);
    this.basePath =
      this.props.category === 'manual-ative' ? '/sales/manual' : '/sales/in';
  }

  componentDidMount() {
    const data = this.props.ns.get('application', {});
    if (!this.props.ns.isEditing()) {
      this.props.ns.startEditing(data);
    }
  }

  getCategory() {
    return this.props.ns.getShared('crm.category') || this.props.category;
  }

  startService(userId) {
    const { ns } = this.props;
    setCustomerDataToNs(userId, ns)
      .then(cd => {
        this.props.history.push('/customer/' + cd.customer.id);
      })
      .catch(this.apiError);
  }

  sendOnEnter(event) {
    if (event.keyCode === 13) {
      this.doFindCustomer();
    }
  }

  apiError(err) {
    console.warn(err);
    this.setState({ errorMessage: 'Nenhum cliente encontrado' });
  }

  doFindCustomer() {
    const { ns } = this.props;
    const _filter = ns.getChanged();
    if (isEmpty(_filter)) {
      this.setState({
        errorMessage: 'Preencha um ou mais valores para procurar uma proposta.',
      });
    } else {
      getUsersByFilter(_filter)
        .then(usrs => {
          if (usrs.length > 1) {
            this.props.ns.set('clients', usrs);
          } else {
            if (api.isUserSales() && !usrs[0].active) {
              this.setState({ errorMessage: "Cliente inativo" })
            } else {
              this.startService(usrs[0].id)
            }
          }
        })
        .catch(this.apiError);
    }
  }

  render() {
    const { data, ns } = this.props;
    let errorMessage;

    if (this.state.errorMessage) {
      errorMessage = <FzAlert fzStyle="alert">{this.state.errorMessage}</FzAlert>;
    }

    let clientsTable;
    let clients = this.props.ns.get('clients');
    if (clients && clients.length > 0) {
      clientsTable = (
        <ClientsTable clients={clients} startService={this.startService} />
      );
    }

    const fieldElms = ['email', 'telephoneNumber', 'personalNumber'].map(
      field => {
        const meta = clone(Application.getField(field));

        if (field === "telephoneNumber") {
          meta.inputType = "masked"
        }

        return (
          <FieldConnected
            key={field}
            meta={meta}
            data={data}
            ns={ns}
          />
        );
      }
    );

    let lastRow = [];
    let rows = [];
    for (
      let iteratorFieldElms = 0;
      iteratorFieldElms < fieldElms.length;
      iteratorFieldElms++
    ) {
      lastRow.push(
        <FzCol span={4} key={iteratorFieldElms}>
          {fieldElms[iteratorFieldElms]}
        </FzCol>
      );

      if (lastRow.length > 2) {
        rows.push(lastRow);
        lastRow = [];
      }
    }

    let fieldsContent = rows.map((rowContent, index) => {
      return <FzRow key={index}>{rowContent}</FzRow>;
    });

    return (
      <FzJumbotron>
        <FzCardGroup id="1">
          <FzCard>
            <FzCard.Heading>
              <FzCard.Title componentClass="h3">
                Iniciar atendimento
              </FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
              {fieldsContent}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <FzButton onClick={this.doFindCustomer} fzStyle="discrete">
                  Buscar Cliente
                </FzButton>
              </div>
            </FzCard.Body>
          </FzCard>
          {errorMessage}
          {clientsTable}
        </FzCardGroup>
      </FzJumbotron>
    );
  }
}
