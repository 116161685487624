import React from 'react';
import { FzCard } from '../../fz/layout/index';
import { panelControlAffiliate } from '../../../biz/metadatas/affiliate'
import { getUsers } from '../../../api/users';
import api from '../../../api/client-api'
import { FzButton } from '../../fz/form';
import FieldConnected from '../../components/form/field-connected';
import clone from "lodash/cloneDeep"
import { getAffiliateOverview, getReportAffiliateAnalyticsHistory, getReportAffiliateSummariesHistory, getAffiliatesCampaignOverview } from '../../../api/affiliate'
import { AffiliateOverview } from '../../pages/affiliate/affiliate-summaries-application';
import { AffiliatesCampaignOverview } from '../../pages/affiliate/affiliate-summaries-campaign-application';
import { AffiliateAnalytics } from '../../pages/affiliate/affiliate-analytics-api';
import { AffiliateSummariesApi } from '../../pages/affiliate/affiliate-summaries-api'
import { getApplicationsCount, getApplications } from '../../../api/applications';
import { ApplicationsList } from '../../../application-components/applications-list';
import finanZero_relatorio_construcao from "../../../images/FinanZero_relatorio_construcao.png";

export class AffiliateConsole extends React.Component<any, any>  {
  constructor(props) {
    super(props)

    this.state = {}
    this.orchestration = this.orchestration.bind(this)
    this.getStatisticsApplicationCampaign = this.getStatisticsApplicationCampaign.bind(this)
    this.getStatisticsApplicationAnalytics = this.getStatisticsApplicationAnalytics.bind(this)
    this.getStatisticsApplicationSummaries = this.getStatisticsApplicationSummaries.bind(this)
    this.getStatisticsApiAnalytics = this.getStatisticsApiAnalytics.bind(this)
    this.getStatisticsApiSummaries = this.getStatisticsApiSummaries.bind(this)
  }


  getStatisticsApplicationCampaign() {
    const { ns } = this.props

    if (ns.getChanged("affiliate") === undefined || ns.getChanged("agregado") === undefined) {
      ns.getChanged("affiliate") === undefined ? (alert("Selecione afiliado")) : (alert("Selecione periodo "))
      return null
    }

    getAffiliatesCampaignOverview(ns.getChanged("affiliate"), ns.getChanged("agregado"))
      .then((data) => {
        ns.set("affiliatesCampaignOverview", data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  getStatisticsApplicationSummaries() {
    const { ns } = this.props

    if (ns.getChanged("affiliate") === undefined || ns.getChanged("agregado") === undefined) {
      ns.getChanged("affiliate") === undefined ? (alert("Selecione afiliado")) : (alert("Selecione periodo "))
      return null
    }
    getAffiliateOverview(ns.getChanged("affiliate"), ns.getChanged("agregado"))
      .then((data) => {
        ns.set("affiliateOverview", data)
      })
      .catch((err) => {
        console.error(err)
      })

  }

  getStatisticsApplicationAnalytics() {
    const { ns } = this.props
    const user = api.getCurrentUser()


    const createdAfter = ns.getChanged("startDate")
    const createdBefore = ns.getChanged("endDate")

    const filter = {
      "createdAfter": ns.getChanged("startDate"),
      "createdBefore": ns.getChanged("endDate"),
      "affiliate": ns.getChanged("affiliate")
    }


    if (user.role === "admin") {
      getApplicationsCount(filter)
        .then((resultCount) => {
          if (resultCount.total > 200000) {
            console.error("Quantidade de registros excedida (> 200 000). Por favor, restrinja sua pesquisa")
            return null
          } else {
            getApplications(filter, 1, null)
              .then((resultApplication) => {
                resultApplication
                this.props.ns.set("applications", resultApplication);
              })
              .catch((err) => {
                console.error(err)
              })
          }
        })
        .catch((err) => {
          console.error(err)
        });
    }
  }

  getStatisticsApiAnalytics() {
    const { ns } = this.props

    const affiliateName = ns.getChanged("affiliate")
    const affiliate = this.state.allInfoAffiliates.find(it => {
      return it.name === affiliateName
    })

    const startDate = ns.getChanged("startDate")
    const endDate = ns.getChanged("endDate")

    if (affiliate && affiliate.id) {
      getReportAffiliateAnalyticsHistory(affiliate.id, startDate, endDate)
        .then((data) => {
          ns.set("affiliateHistory", data)
        }).catch((err) => {
          console.error(err)
        })
    }
  }

  getStatisticsApiSummaries() {
    const { ns } = this.props

    if (ns.getChanged("affiliate") === undefined || ns.getChanged("agregado") === undefined) {
      ns.getChanged("affiliate") === undefined ? (alert("Selecione afiliado")) : (alert("Selecione periodo "))
      return null
    }

    getReportAffiliateSummariesHistory(ns.getChanged("affiliate"), ns.getChanged("agregado"))
      .then((data) => {
        ns.set("affiliateSummariesHistory", data)
      }).catch((err) => {
        console.error(err)
      })

  }

  componentDidMount() {
    const { ns } = this.props
    ns.startEditing()
    this.getAffiliates()
  }

  getAffiliates() {
    const user = api.getCurrentUser()

    if (user.role === "admin") {
      getUsers("affiliate")
        .then((allAfiliates) => {
          const listOfAfiliates = allAfiliates.reduce((resultSoFar, affiliateCurrent) => {
            let data = { code: affiliateCurrent.name, description: affiliateCurrent.name }
            return [...resultSoFar, data]
          }, [])

          const listOfAfiliatesForReportAnalytics = allAfiliates.reduce((resultSoFar, affiliateCurrent) => {
            return [...resultSoFar, affiliateCurrent]
          }, [])

          this.setState({ "affiliates": listOfAfiliates, "allInfoAffiliates": listOfAfiliatesForReportAnalytics })
        })
        .catch((err) => { console.log(err) })
    } else if (user.role === "affiliate") {
      const anAffiliate = [{ code: user.name, description: user.name }]
      this.setState({ "affiliates": anAffiliate, "allInfoAffiliates": [user] })
    }
  }

  orchestration() {
    const { ns } = this.props

    if (ns.getChanged("source") === "application") {

      if (ns.getChanged("typeView") === "summaries") {
        this.getStatisticsApplicationSummaries()
      }
      if (ns.getChanged("typeView") === "analytics") {
        this.getStatisticsApplicationAnalytics()
      }
      if (ns.getChanged("typeView") === "campaign") {
        this.getStatisticsApplicationCampaign()
      }
    }

    if (ns.getChanged("source") === "api") {

      if (ns.getChanged("typeView") === "analytics") {
        this.getStatisticsApiAnalytics()
      }
      if (ns.getChanged("typeView") === "summaries") {
        this.getStatisticsApiSummaries()
      }
    }
  }

  getCustomFieldView(panelControlAffiliate, ns) {
    let customFieldTypeView = clone(panelControlAffiliate["typeView"])

    const user = api.getCurrentUser()

    if (user.role === "affiliate") {

      if (ns.getChanged("source") === "application") {
        customFieldTypeView.inputProps.options = [
          { "code": "summaries", "description": "Summaries" },
          { "code": "campaign", "description": "Campaign" }
        ]
      } else {
        customFieldTypeView.inputProps.options = [
          { code: "analytics", description: "Analytics" },
          { code: "summaries", description: "Summaries" },
        ]
      }

    } else {

      if (ns.getChanged("source") === "application") {
        customFieldTypeView.inputProps.options = [
          { code: "analytics", description: "Analytics" },
          { code: "summaries", description: "Summaries" },
          { code: "campaign", description: "Campaign" }
        ]
      } else {
        customFieldTypeView.inputProps.options = [
          { code: "analytics", description: "Analytics" },
          { code: "summaries", description: "Summaries" },
        ]
      }

    }
    return customFieldTypeView
  }

  render() {
    const { ns, props } = this.props

    const user = api.getCurrentUser()
    const resultDataApiAnalytics = ns.get("affiliateHistory")
    const resultDataApiSummaries = ns.get("affiliateSummariesHistory")

    const resultDataApplicationsSummaries = ns.get("affiliateOverview")
    const resultDataApplicationsSummariesCampaign = ns.get("affiliatesCampaignOverview")
    const resultDataApplicationsAnalytics = ns.get('applications')

    const data = ns.getChanged()

    var customField

    if (this.state.affiliates) {
      customField = clone(panelControlAffiliate["affiliate"])
      customField.inputProps.options = [...this.state.affiliates]
    }

    if (!this.state.affiliates) {
      return null
    }

    let customFieldTypeView = this.getCustomFieldView(panelControlAffiliate, ns)

    return (

      <FzCard>

        <FzCard.Body>

          <FzCard>
            <div className={"fz-panel"} >

              <div className={"fz-panel-border-padding"}>
                < FieldConnected data={data} meta={panelControlAffiliate["source"]} ns={ns} />
              </div>

              <div className={"fz-panel-border-padding"} >
                < FieldConnected data={data} meta={customFieldTypeView} ns={ns} />
              </div>

              {
                ns.getChanged("typeView") !== undefined && (ns.getChanged("typeView") === "summaries" || ns.getChanged("typeView") === "campaign") ? (
                  <div className={"fz-panel-border-padding"}>
                    < FieldConnected data={data} meta={panelControlAffiliate["agregado"]} ns={ns} />
                  </div>
                ) : null
              }

              {
                ns.getChanged("typeView") !== undefined && ns.getChanged("typeView") === "analytics" ? (
                  <>
                    <div className={"fz-panel-border-padding"}>
                      < FieldConnected data={data} meta={panelControlAffiliate["startDate"]} ns={ns} />
                    </div>
                    <div className={"fz-panel-border-padding"}>
                      < FieldConnected data={data} meta={panelControlAffiliate["endDate"]} ns={ns} />
                    </div>
                  </>
                ) : null
              }
              <div className={"fz-panel-border-padding"}>
                {this.state.affiliates !== undefined ? (
                  < FieldConnected data={data} meta={customField} ns={ns} />) : null}
              </div>

              <div className={"fz-panel-border-padding"}>
                <FzButton
                  onClick={this.orchestration}
                  tip={"buscar"}
                  disabled={false}
                  className={null}
                  block={true}
                  fzStyle={"regular"}
                >{"Buscar"}</FzButton>
              </div>
            </div>
          </FzCard>

          <FzCard>

            <FzCard.Body>
              {ns.getChanged("typeView") !== undefined ? (<div>

                {ns.getChanged("source") === "application" && ns.getChanged("typeView") === "summaries" ? (

                  <FzCard.Body>
                    <AffiliateOverview ns={ns} data={resultDataApplicationsSummaries} />
                  </FzCard.Body>) : null}

                {ns.getChanged("source") === "application" && ns.getChanged("typeView") === "campaign" ? (

                  <FzCard.Body >
                    <AffiliatesCampaignOverview ns={ns} data={resultDataApplicationsSummariesCampaign} />
                  </FzCard.Body>) : null}

                {user.role === "affiliate" ?
                  (
                    ns.getChanged("source") === "application" && ns.getChanged("typeView") === "analytics" ? (

                      <FzCard.Body>
                        <div
                          style={
                            { display: "flex", justifyContent: "center", maxWidth: "100%", maxHeight: "70%" }}>
                          <img src={finanZero_relatorio_construcao} alt="FinanZero logo" />
                        </div>
                      </FzCard.Body>) : null
                  )

                  :

                  (
                    ns.getChanged("source") === "application" && ns.getChanged("typeView") === "analytics" ? (

                      <FzCard.Body>
                        <ApplicationsList
                          sharedNS={this.props.sharedNS}
                          ns={this.props.ns}
                          multipleSelect
                          detailsBaseRoute={'/applications/'}
                          count={this.state.applicationsCount}
                          data={resultDataApplicationsAnalytics}
                          history={this.props.history}
                        />
                      </FzCard.Body>) : null
                  )

                }

                {ns.getChanged("source") === "api" && ns.getChanged("typeView") === "summaries" ? (
                  <div>

                    <AffiliateSummariesApi
                      data={resultDataApiSummaries}
                    />


                  </div>) : null}

                {ns.getChanged("source") === "api" && ns.getChanged("typeView") === "analytics" ? (
                  <div>
                    <AffiliateAnalytics history={this.props.history} ns={ns} data={resultDataApiAnalytics} />
                  </div>) : null}


              </div>) : null}

            </FzCard.Body>

          </FzCard>

        </FzCard.Body>
      </FzCard >

    )
  }
}

