import React from 'react';
import { FzCard, FzCol, FzRow } from '../fz/layout/index'
import { FzForm } from '../fz/layout/index'
import { formatPeriod } from '../fz/formatter/data-formatter'

class Score extends React.Component {

  render() {
    const { application } = this.props
    const scores = application.scores || []


    const listScores = scores.map((it, index) => {
      return (
        <FzRow key={`${it.created}-${index}`}>
          <FzCol span="4">
            <FzForm.Label>Provider</FzForm.Label>
            <p>{it.provider}</p>
          </FzCol>
          <FzCol span="4">
            <FzForm.Label>Score</FzForm.Label>
            <p>{it.score}</p>
          </FzCol>
          <FzCol span="4">
            <FzForm.Label>Date</FzForm.Label>
            <p>{formatPeriod(true, it.created)}</p>
          </FzCol>
        </FzRow>
      )
    })

    return (
      <div>
        <FzCard>
          <FzCard.Heading>
            Detalhes do Score
          </FzCard.Heading>
          <FzCard.Body>
            {listScores}
          </FzCard.Body>
        </FzCard>
      </div>
    )
  }
}

export { Score }
