import { Meta, MetaField } from "../../types/metas";


export const bidsAnalytic: Meta = {
  "id": {
    "path": "id",
    "label": "id",
    "tableProps": {
      "hidden": false,
      "width": "9rem",
      "dataSort": true,
      "isKey": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "supplier.name": {
    "path": "supplier.name",
    "label": "Parceiro",
    "tableProps": {
      "width": "10rem",
      "dataSort": true,
      "formatExtraData": "supplier.name",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "approvedAmount": {
    "path": "approvedAmount",
    "label": "Aprovado",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "monthlyInstallment": {
    "path": "monthlyInstallment",
    "label": "Prestação",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "firstInstallment": {
    "path": "firstInstallment",
    "label": "1ª Parcela",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "lastInstallment": {
    "path": "lastInstallment",
    "label": "Últ. Parcela",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "totalValueInstallments": {
    "path": "totalValueInstallments",
    "label": "Total Parcelas",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "approvedPeriodMonths": {
    "path": "approvedPeriodMonths",
    "label": "Meses",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "paymentMethod": {
    "path": "paymentMethod",
    "label": "Forma de pagamento",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getDescriptionPaymentMethod",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "interestRate": {
    "path": "interestRate",
    "label": "Juros a.m",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "pctColumn",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "totalAnnualInterestRate": {
    "path": "totalAnnualInterestRate",
    "label": "C.E.T. anual",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "pctColumn",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "supplier.branchCode": {
    "path": "supplier.branchCode",
    "label": "Loja",
    "tableProps": {
      "width": "7rem",
      "dataSort": true,
      "dataAlign": "center",
      "formatExtraData": "supplier.branchCode",
      'expandable': true,
    },
    "isMandatory": () => false
  },
  "accepted": {
    "path": "accepted",
    "label": "Situação",
    "tableProps": {
      "width": "25rem",
      "dataSort": true,
      "dataAlign": "left",
      "formatExtraData": "app",
      'expandable': true,
    },
    "isMandatory": () => false
  },
}

export const editBid: Meta = {
  "approvedAmount": {
    "path": "approvedAmount",
    "label": "Aprovado",
    "inputType": "number",
    "dataType": "number",
    "tableProps": {
      "isKey": true
    },

    "isMandatory": () => false
  },
  "monthlyInstallment": {
    "path": "monthlyInstallment",
    "label": "Prestação",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "firstInstallment": {
    "path": "firstInstallment",
    "label": "1ª Parcela",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "lastInstallment": {
    "path": "lastInstallment",
    "label": "Últ. Parcela",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "totalValueInstallments": {
    "path": "totalValueInstallments",
    "label": "Total Parcelas",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "approvedPeriodMonths": {
    "path": "approvedPeriodMonths",
    "label": "Meses",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "interestRate": {
    "path": "interestRate",
    "label": "Juros a.m",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "totalAnnualInterestRate": {
    "path": "totalAnnualInterestRate",
    "label": "C.E.T. anual",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "annualInterestRate": {
    "path": "annualInterestRate",
    "label": "Taxa de juros anual",
    "inputType": "text",
    "dataType": "number",
    "isMandatory": () => false
  },
  "totalInterestRate": {
    "path": "totalInterestRate",
    "label": "Taxa de juros total",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "amortizationTable": {
    "path": "amortizationTable",
    "label": "Tabela",
    "inputType": "text",
    "dataType": "number",
    "tableProps": {},
    "isMandatory": () => false
  },
  "indexRate": {
    "path": "indexRate",
    "label": "Indexador",
    "inputType": "text",
    "dataType": "number",
    "isMandatory": () => false
  },
  "insurance": {
    "path": "insurance",
    "label": "Seguro",
    "dataType": "bool",
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": "true", "description": "Sim" },
        { "code": "false", "description": "Não" }
      ],
    },
    "isMandatory": () => false
  },
  "firstInstallmentDate": {
    "path": "firstInstallmentDate",
    "label": "Data da primeira parcela",
    "inputType": "date",
    "dataType": "string",
    "isMandatory": () => false
  },
}
