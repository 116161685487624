import { get, remove, set } from 'js-cookie'

function getGAuthToken() {
    // if gapi lib is not loaded, we will relay on cookie so we don't get redirected to /login at page load ;)
    if (!window.gapi || !window.gapi.auth2 || !window.gapi.auth2.getAuthInstance) {
        return localStorage.getItem("fz-ga-token")
    }
    if (window.gapi && window.gapi.auth2 && window.gapi.auth2.getAuthInstance) {
        const gauth = window.gapi.auth2.getAuthInstance()
        // if user has a valid Google session
        if (gauth.isSignedIn.get()) {
            if (localStorage.getItem("fz-ga-token")) {
                return localStorage.getItem("fz-ga-token")
                // if we didn't found any, we retrieve a new one from gapi lib
            } else {
                const token = gauth.currentUser.get().getAuthResponse().id_token
                const expires = new Date();
                // fz-ga-token have 4 hours of ttl
                expires.setHours(expires.getHours() + 4);
                setCookie("fz-ga-token", token, expires)
                localStorage.setItem("fz-ga-token", token)
                return token
            }
            // if user is not logged in with google, we remove cookie
        } else {
            removeCookie("fz-ga-token")
            localStorage.removeItem("fz-ga-token")
            return ""
        }
    }
    return ""
}

function getCookie(name) {
    if(!name) return get() ?? null;
    
    return get(name) ?? null;
}

function setCookie(name, val, expires) {
    set(name, val, { expires })
}

function removeCookie(name) {
    remove(name)
}

function clearAuth() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("fz-ga-token")
    localStorage.removeItem("fz-cof-token")

    Object.keys(getCookie()).forEach((cookie) => removeCookie(cookie))
}

function getLogoutURL(location) {
  const loginUrl = '/login';
  if (location?.pathname === loginUrl || location?.pathname === '/logout') {
    return loginUrl + location.search;
  }
  if (location?.search?.indexOf('?nextPathname=') > -1) {
    return loginUrl + location.search;
  }
  return `/login?nextPathname=${location?.pathname || '/'}${location?.search || ''}`;
}

export { getCookie, setCookie, removeCookie, clearAuth, getGAuthToken, getLogoutURL };