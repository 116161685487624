import React, { useState, useEffect }  from "react";
import { getFormBusinessStats, getGeneralProgressionDS } from "../../../api/stats";
import { setTTL } from "../../../business/misc-functions";
import { StatsDetailHeader } from "../../../statistics/stats-components";
import { FzTable } from "../../fz/grid/table";
import { FzCard } from "../../fz/layout/index";
import { FzNavSelect } from "../../fz/nav";

export function FunnelFormProgression(props) {

  const { ns, metas } = props
  const [requestState, setRequestState] = useState('idle')
  const [showDataStudio, setShowDataStudio] = useState(false)
  const [currentPeriodo, setCurrentPeriodo] = useState("day")
  const [dataStudioValue, setDataStudioValue] = useState(null)

  let data = ns.get("form-progression", {})
  if (!data && requestState === "requesting") {
    return null
  }

  useEffect(() => {
    getGeneralProgressionDS().then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get FORM-PROGRESSION Data Studio iframe", err)
    })
  }, [])

  async function getStatistics(period) {
    setRequestState("requesting");
    props.toggleBlocking();
    try {
      let res = await getFormBusinessStats(period);
      ns.set("form-progression", res, setTTL(5));
      setRequestState("idle");
      props.toggleBlocking();
    } catch (err) {
      console.warn("API ERROR", err);
      props.toggleBlocking();
    }
  }

  function handleSelect(periodo) {
    setCurrentPeriodo(periodo)
    getStatistics(periodo) 
  }

  function handleShowDataStudio() {
    setShowDataStudio(!showDataStudio)
  }

  return (
    <>
    {!showDataStudio && dataStudioValue ? <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>Data Studio View</button> : <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>COF View</button>}
    {showDataStudio && dataStudioValue ? <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} /> :
    <FzCard>
      <FzCard.Heading>
        <FzCard.Title>
          <StatsDetailHeader stats={data} title="General Form Progression" />
        </FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <FzNavSelect
          selected={currentPeriodo}
          onChange={handleSelect}
          options={[
            { "code": "day", "description": "Daily" },
            { "code": "week", "description": "Weekly" },
            { "code": "month", "description": "Monthly" },
          ]}
        />
        <FzCard.Body>
          <FzTable
            data={data.statistics}
            pagination
            metas={metas}
            ns={ns}
            visibleColumns={["key", "period", "product", "total", "closedFirst", "closedValidation", "closedThrough", "abandoned", "finished"]}
          />
        </FzCard.Body>
      </FzCard.Body>
    </FzCard>}
  </>
  )
}
