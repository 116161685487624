export function compareConsultants(queues, users){
  let queuesComplete = queues.filter(queue => queue.excluded === false);
  users.map((user)=>{
    user["queues"].map((queueId) => {
      let queueIndex = queuesComplete.findIndex((q => q["_id"] == queueId));
      if (queueIndex !== -1) {
        if (typeof queuesComplete[queueIndex]["linkedUsers"] == "undefined" || typeof queuesComplete[queueIndex]["linked"] == "undefined") {
          queuesComplete[queueIndex]["linked"] = true 
          queuesComplete[queueIndex]["linkedUsers"] = user.userName
        } else {
          queuesComplete[queueIndex]["linkedUsers"] += ", " + user.userName 
        }
      }
      return queueId
    })
    return user
  }) 
  queuesComplete = queuesComplete.map((q)=>{
    q["linked"] ? q["linked"] = true : q["linked"] = false
    q["linkedUsers"] ? q["linkedUsers"] = q["linkedUsers"] : q["linkedUsers"] = "Não Encontrado"
    return q
  })
  return queuesComplete
}