import api, { managerApi } from './client-api';

// End point to send message through go server
export const postIntegrationAgentCallback = (
  applicationId,
  agent,
  message,
  data
) => {
  let path =
    '/integrations/callbacks/agent/' +
    agent +
    '/applications/' +
    applicationId +
    '/message/' +
    message;
  return api.Post(path, data);
};

export const getApplicationQueues = (applicationId, agent, active) => {
  let path =
    '/integrations/application/' + applicationId + '/' + agent + '/queues';
  if (active !== undefined && active !== null) {
    path += '/' + active.toString();
  }
  return managerApi.Get(path);
};

export const getApplicationTraces = (
  applicationId,
  agent,
  queue,
  doc,
  active
) => {
  let path =
    '/integrations/application/' +
    applicationId +
    '/' +
    agent +
    '/traces/' +
    queue;
  if (doc !== undefined && doc !== null) {
    path += '/' + doc;
  }
  if (active !== undefined && active !== null) {
    path += '/' + active.toString();
  }
  return managerApi.Get(path);
};

export const makeReprocessing = (id, agent, active) => {
  let path = '/integrations/queues/reprocessing/' + agent + '/' + active;
  let data = { ids: id };
  return managerApi.Post(path, data);
};

export const postReprocessMultipleQueues = (payload) => {
  const path = '/reprocessing/';
  return managerApi.Post(path, payload);
};

export const getAllReprocessing = (filters) => {
  let path = '/reprocessing/all?';
  path += managerApi._buildQueryString(filters);
  return managerApi.Get(path);
};

export const getReprocessing = (id) => {
  let path = '/reprocessing/' + id;
  return managerApi.Get(path);
};

export const getQueueSearch = (filters) => {
  let path = '/integrations/searchqueue?';
  path += managerApi._buildQueryString(filters);
  return managerApi.Get(path);
};

export const getAllQueuesAgent = (filters) => {
  let path = '/integrations/allqueuesagent?';
  path += managerApi._buildQueryString(filters);
  return managerApi.Get(path);
};

export const getQueue = (id, agent) => {
  let path = '/integrations/queues/confirmation/' + id + '/' + agent;
  return managerApi.Get(path);
};

export const getSQSTriggers = (agent) => {
  let path = '/integrations/sqstriggers/' + agent;
  return managerApi.Get(path);
};

export const getAgents = (integrations) => {
  integrations = integrations ? integrations : 'agents';
  let path = `/integrations/${integrations}`;
  return managerApi.Get(path);
};

export const stopAgent = (id, integrations) => {
  integrations = integrations ? integrations : 'agents';
  let path = `/integrations/${integrations}/${id}/stop`;
  return managerApi.Post(path);
};

export const startAgent = (id, integrations) => {
  integrations = integrations ? integrations : 'agents';
  let path = `/integrations/${integrations}/${id}/start`;
  return managerApi.Post(path);
};

export const integrationAgentCallback = (applicationId, agent, message) => {
  let path =
    '/integrations/agent/cbss/applications/' +
    applicationId +
    '/message/' +
    message;
  return managerApi.Get(path);
};

export const getAgentQueues = (agent) => {
  let path = '/integrations/queues/' + agent;
  return managerApi.Get(path);
};

export const getAgentQueuesStats = (agent) => {
  let path = '/integrations/queues/' + agent + '/summary/all';
  return managerApi.Get(path);
};

export const getAverageResponseTime = (agent) => {
  let path = '/integrations/queues/' + agent + '/summary/avgresponsetime';
  return managerApi.Get(path);
};

export const getAgentTimedOutStats = (agent) => {
  let path = '/integrations/queues/' + agent + '/summary/timedout?days=7';
  return managerApi.Get(path);
};

export const getAgentCanceledStats = (agent) => {
  let path = '/integrations/queues/' + agent + '/summary/canceled?days=7';
  return managerApi.Get(path);
};

export const getAgentActiveQueueEntries = (agent, queue) => {
  let path = '/integrations/queues/' + agent + '/' + queue + '/active';
  return managerApi.Get(path);
};

export const getAgentRecentQueueEntries = (agent, queue) => {
  let path = '/integrations/queues/' + agent + '/' + queue + '/recent';
  return managerApi.Get(path);
};

export const getAgentTimeOutQueueEntries = (agent, queue) => {
  let path = '/integrations/queues/' + agent + '/' + queue + '/timedout?days=7';
  return managerApi.Get(path);
};

export const getAgentCanceledQueueEntries = (agent, queue) => {
  let path = '/integrations/queues/' + agent + '/' + queue + '/canceled?days=7';
  return managerApi.Get(path);
};

export const updateSupplierCache = () => {
  let path = '/go-apis/suppliers/updatecache';
  return managerApi.Post(path);
};

export const getAllTasks = () => {
  return managerApi.Get('/cron/tasks');
};

export const getTask = (taskName) => {
  return managerApi.Get('/cron/tasks/' + taskName);
};

export const patchTask = (taskName, patch) => {
  return managerApi.Patch('/cron/tasks/' + taskName, patch);
};

export const replaceTask = (taskName, taskDefinition) => {
  return managerApi.Put('/cron/tasks/' + taskName, taskDefinition);
};

export const createTask = (taskDefinition) => {
  return managerApi.Post('/cron/tasks', taskDefinition);
};

export const toggleDisableTask = (taskName) => {
  return managerApi.Post('/cron/tasks/' + taskName + '/toggle-disabled');
};

export const deleteTask = (taskName) => {
  return managerApi.Delete('/cron/tasks/' + taskName);
};

export const getGoServersMemory = () => {
  return managerApi.Get('/go-apis/debug/memory');
};

/**
 * @typedef {import('../ui/blocks/application-components/custom-simulation-helpers').AllowedSimulationSupplier} AllowedSimulationSupplier
 * @typedef {import('../ui/blocks/application-components/custom-simulation-helpers').CustomSimulationRequest} CustomSimulationRequest
 */

/** @returns {Promise<AllowedSimulationSupplier[]>} */
export const getAllowedCustomSimulationSuppliers = () => {
  return managerApi.Get('/integrations/customsimulation/suppliers');
};
/**
 * @param {string} appId
 * @param {string} supplier
 * @param {CustomSimulationRequest} data
 * @returns {Promise<any>}
 */
export const postCustomSimulation = (appId, supplier, data) => {
  const path = `integrations/customsimulation/application/${appId}/supplier/${supplier}`;
  return managerApi.Post(path, data);
};
