import moment from 'moment';
import { Meta } from '../../../../types/metas';


export const screeningCausesOfPendencyMeta: Meta = {
  "applicationId": {
    "path": "applicationId",
    "label": "Proposta",
    "dataType": "string",
    "tableProps": {
      "width": "9ch",
      "dataSort": true,
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "supplierName": {
    "path": "supplierName",
    "label": "Parceiro",
    "tableProps": {      
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { "type": 'TextFilter', "delay": 500 },
    },
    "isMandatory": (application) => false,
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "personalNumber",
      "width": "9ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { "type": 'TextFilter', "delay": 500 },
    },
    "isMandatory": (application) => false,
  },
  "analysesEndDate": {
    "path": "analysesEndDate",
    "label": "Saída da fila",
    "dataType": "date",
    "tableProps": {
      "width": "11ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "periodWithTimeColumnTz"
    },
    "isMandatory": (application) => false,
  },
  "screener": {
    "path": "screener",
    "label": "Analista",
    "tableProps": {
      "width": "9ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "reasonPending": {
    "path": "reasonPending",
    "label": "Motivo da Pendência",
    "tableProps": {
      "width": "50ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "documentType": {
    "path": "documentType",
    "label": "Tipo de Documento",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "getDescriptionsByDomains",
      "formatExtraData": "formalization-documents",
    },
    "isMandatory": (application) => false,
  },
  "screenedCount": {
    "path": "screenedCount",
    "label": "Passagens",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "screenedCount",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "ocrScore": {
    "path": "ocrScore",
    "label": "OCR Score",
    "dataType": "number",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "status": {
    "path": "status",
    "label": "Status Document",
    "tableProps": {
      "filter": { type: 'SelectFilter', options: "buildStatusDocumentsFilters" },
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
};

export const panelControlCausesOfPendency: Meta = {
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "dataType": "string",
    "inputType": "nav-dropdown",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => true,
  },

  "createdAfter": {
    "path": "createdAfter",
    "label": "De:",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },

  "createdBefore": {
    "path": "createdBefore",
    "label": "Até:",
    "dataType": 'dateTime',
    "inputType": "dateTime",
    "inputProps": {
      "domainName": undefined,
      "filteredFrom": undefined,
      "options": undefined,
      "maxValue": moment().add("year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "minValue": moment().add(-70, "year").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      "maxSize": undefined,
      "minSize": undefined,
      "autoComplete": undefined,
      "mask": undefined,
      "removeMask": undefined
    },
    isMandatory: (application) => false,
    "isReadOnly": undefined,
    "isVisible": undefined,
    "normalize": undefined,
    "extraValidation": undefined,
    "hookOnSave": undefined
  },
};

