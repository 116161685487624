import React, { useEffect, useState } from 'react'
import { FzCard } from '../ui/fz/layout/index'
import api from '../api/client-api'

export default function SupplierLogs(props) {
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (api.isITUser()) {
      setIsAdmin(true)
    }
  }, [])

  if (props.supplier.auditLogs && props.supplier.auditLogs.length > 0 && isAdmin) {
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Histórico de alterações</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <pre style={{ maxHeight: '600px', overflow: 'scroll' }}>
            {JSON.stringify(props.supplier.auditLogs, null, 2)}
          </pre>
        </FzCard.Body>
      </FzCard>
    )
  }
  return null
}