import moment from "moment";
import { useEffect, useState } from "react";
import { getDeletedReason } from "../../../../api/stats";
import { DeletedCustomerContainer } from "../../../../Containers/CustomerReports/index"
import { getDeletedCutomersReport } from "../../../../services/api/audits";
import { ParamsType, ResultType } from "./types";

export function CustomerDeletedReports({ ns }) {

  const [typeView, setTypeView] = useState('')
  const [currentPeriod, setCurrentPeriod]: any = useState(null)
  const [data, setData] = useState<ResultType>({ statistics: [] })
  const [msgToast, setMsgToast] = useState('')
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    ns.startEditing({
      "createdFrom": moment().subtract(10, "days").startOf("day").toISOString(),
      "createdTo": moment().endOf("day").toISOString()
    });
  }, []);

  async function getAnalyticsData(filter: ParamsType) {
    try {
      if (!filter.createdFrom || !filter.createdTo) {
        setShowToast(true)
        setMsgToast("Por favor, informe o período para a pesquisa.")
        return
      }
      const createdFromInMilliseconds: number = new Date(filter.createdFrom).getTime();
      const limitDate = createdFromInMilliseconds + (33 * 24 * 60 * 60 * 1000);
      if (limitDate < new Date(filter.createdTo).getTime()) {
        setShowToast(true)
        setMsgToast("Por favor, restrinja sua pesquisa para até 33 dias.")
      } else {
        const result = await getDeletedCutomersReport({
          createdFrom: filter.createdFrom,
          createdTo: filter.createdTo,
          email: filter.email,
          personalNumber: filter.personalNumber,
        })

        if (result.data.customers) {
          ns.set("deleted-customers-reports", result.data.customers);
          setData({ statistics: result.data.customers });
        } else if (result.data.msg) {
          setShowToast(true)
          setMsgToast(result.data.msg)
        }
      }
    } catch (error) {
      let err: $TsFixMe = error
      console.error(err)
      if (err.response && err.response.data && err.response.data.error){
        setShowToast(true)
        setMsgToast(err.response && err.response.data && err.response.data.error)
      }
    }
  }

  async function getSummaraiesData(filter: ParamsType) {
    try {
      const response = await getDeletedReason(filter.period)
      setData(response)
    } catch (error) {
      let err: $TsFixMe = error
      console.error(err)
    }
  }

  function handleSearchReport(filter: ParamsType) {
    if (typeView === "summaries") {
      getSummaraiesData(filter)
    } else {
      getAnalyticsData(filter)
    }
  }

  const handlePage = (pageType: string): void => {
    setData({ statistics: [] })
    setTypeView(pageType)
  }

  const handlePeriod = async (periodo) => {
    setCurrentPeriod(periodo)
  }

  const filters = ns.getChanged()

  const handleToastClose = () => setShowToast(false)

  return (
    <div>
      <DeletedCustomerContainer
        data={data}
        typeView={typeView}
        ns={ns}
        filters={filters}
        handleSearchReport={handleSearchReport}
        handlePage={handlePage}
        currentPeriod={currentPeriod}
        bodyMessageTost={msgToast}
        showToast={showToast}
        handleTostClose={handleToastClose}
      />
   </div>
  )
}