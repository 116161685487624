import { msgApi } from '../api/client-api'
import {getCookie, getGAuthToken} from './helpers';
import clone from "lodash/cloneDeep"

export const getAllRules = () => {
    return msgApi.Get('/rules');
}

export const getRuleById = (id) => {
    return msgApi.Get('/rules/'+id).then(data => {
        if (Array.isArray(data)) {
            data = data[0]
        }
        return data
    });
}

export const updateRule = (rule) => {
    var payload = clone(rule);
    let id = rule._id;
    payload._id = undefined; // set it up for a post update request
    return msgApi.Put("/rules/" + id, JSON.parse (JSON.stringify (payload)))
}

export const createMessageRule = (messageRule) => {
    var header = new Headers()
    header.append('Content-Type', 'application/json');
    header.append('Authorization', getCookie("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

  return msgApi.Post('/rules', messageRule);
}

export const sendNotificationsTest = (payload) => {
  return msgApi.Post('/sendnotifications', payload)
}

export const getContextVariable = () => {
  return msgApi.Get('/delivery-params');
}