import { useEffect, useState } from 'react';
import {
  ReprocessingResponseType,
  ReprocessingSearchMeta,
  ReprocessingSearchQueryMeta,
} from '../../biz/metadatas/bifrost/reprocessing-metadata';
import FieldConnected from '../../ui/components/form/field-connected';
import { FzCard, FzCol, FzRow } from '../../ui/fz/layout';

export const ReprocessingViewContainer = ({ ns }) => {
  const [individualReprocessing, setIndividualReprocessing] =
    useState<ReprocessingResponseType>();

  useEffect(() => {
    const data = ns.get('reprocessing');
    setIndividualReprocessing(data);
  }, [ns.get("reprocessing")]);

  return (
    <>
      <FzCard>
        <FzCard.Body>
          <FzRow>
            <FzCol key={'id'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchMeta['id']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
            <FzCol key={'created'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchMeta['created']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
          </FzRow>
          <FzRow>
            <FzCol key={'userName'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchMeta['userName']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
            <FzCol key={'executed'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchMeta['executed']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
          </FzRow>
          <FzRow>
            <FzCol key={'cardId'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchMeta['cardId']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
            <FzCol key={'funnelStage'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchMeta['funnelStage']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
          </FzRow>
          <FzRow>
            <FzCol key={'dateFrom'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchQueryMeta['dateFrom']}
                data={individualReprocessing && individualReprocessing.query}
                ns={ns}
              />
            </FzCol>
            <FzCol key={'dateTo'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchQueryMeta['dateTo']}
                data={individualReprocessing && individualReprocessing.query}
                ns={ns}
              />
            </FzCol>
          </FzRow>
          <FzRow>
            <FzCol key={'supplierInternalName'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchQueryMeta['supplierInternalName']}
                data={individualReprocessing && individualReprocessing.query}
                ns={ns}
              />
            </FzCol>
          </FzRow>
          <FzRow>
            <FzCol key={'cancelled'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchQueryMeta['cancelled']}
                data={individualReprocessing && individualReprocessing.query}
                ns={ns}
              />
            </FzCol>
            <FzCol key={'timeouted'} span={6}>
              <FieldConnected
                meta={ReprocessingSearchQueryMeta['timeouted']}
                data={individualReprocessing && individualReprocessing.query}
                ns={ns}
              />
            </FzCol>
          </FzRow>
          {individualReprocessing && individualReprocessing.query.queue && (
            <FzRow>
              <FzCol key={'queue'} span={6}>
                <FieldConnected
                  meta={ReprocessingSearchQueryMeta['queue']}
                  data={individualReprocessing && individualReprocessing.query}
                  ns={ns}
                />
              </FzCol>
            </FzRow>
          )}
          {individualReprocessing &&
            individualReprocessing.query.includedQueues && (
              <FzRow>
                <FzCol key={'includedQueues'} span={6}>
                  <FieldConnected
                    meta={ReprocessingSearchQueryMeta['includedQueues']}
                    data={
                      individualReprocessing && individualReprocessing.query
                    }
                    ns={ns}
                  />
                </FzCol>
                <FzCol key={'excludedQueues'} span={6}>
                  <FieldConnected
                    meta={ReprocessingSearchQueryMeta['excludedQueues']}
                    data={
                      individualReprocessing && individualReprocessing.query
                    }
                    ns={ns}
                  />
                </FzCol>
              </FzRow>
            )}
          {individualReprocessing?.applications && (
            <FzRow>
              <FzCol key={'applications'} span={12}>
                <FieldConnected
                  meta={ReprocessingSearchMeta['applications']}
                  data={individualReprocessing}
                  ns={ns}
                />
              </FzCol>
            </FzRow>
          )}
          <FzRow>
            <FzCol key={'queuesId'} span={12}>
              <FieldConnected
                meta={ReprocessingSearchMeta['queuesId']}
                data={individualReprocessing}
                ns={ns}
              />
            </FzCol>
          </FzRow>
        </FzCard.Body>
      </FzCard>
    </>
  );
};
