import { Meta } from "../../../../types/metas";

export const prioritizedBidsMeta: Meta = {
  'applicationId': {
    "path": "applicationId",
    "label": "Proposta",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      'isKey': true,
      "dataSort": true,
      "dataAlign": "center",
      "filter": { type: 'TextFilter', delay: 500 },
    },
  },
  'personalNumber': {
    "path": "personalNumber",
    "label": "CPF",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": { type: 'TextFilter', delay: 500 },
    },
  },
  'fullName': {
    "path": "fullName",
    "label": "Cliente",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  'supplierInternalName': {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
  },
  'prioritized.date': {
    "path": "prioritized.date",
    "label": "Data e horário da priorização",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "fullDateTimeColumn",
    },
  },
  "prioritized.screenerName": {
    "path": "prioritized.screenerName",
    "label": "Analista",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
  },
}