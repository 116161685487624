import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { supplierMetas } from '../biz/metadatas/supplier'
import { FzTable } from '../ui/fz/grid/table';
import { FzCard, FzBadge } from '../ui/fz/layout/index'
import { FzIcons } from '../ui/fz/form/icon';
import { SupplierFilters } from '../ui/blocks/admin-suppliers/supplier-filters';
import BranchDetails from '../ui/blocks/admin-suppliers/branch-detail'
import { SupplierBranchEdit } from '../ui/blocks/admin-suppliers/supplierBranchEdit'


import { FzText } from '../ui/fz/form/old-simple-input';

export function SupplierBranchesTable(props) {
  
  const { ns, supplier, readOnly, persistChanges } = props

  function expandComponent(ns, supplier, branch, readOnly, persistChanges) {
    let code = branch["branchCode"]
    return (
      <>
        <BranchDetails ns={ns} productsList={props.productsList} formSections={props.formSections} filters={props.filters} supplier={supplier} code={code} />
        <SupplierFilters supplier={supplier} productsList={props.productsList} ns={ns} readOnly={readOnly} persistChanges={persistChanges} basePath={"branches." + code + ".filters"} branchInFilter={true} />
      </>
    )
  }

  var data : $TsFixMe = []
  var branches = supplier.branches || {}
  for (var branchCode in (branches || {})) {
    if (branches.hasOwnProperty(branchCode)) {
      let x : $TsFixMe = branches[branchCode]
      x["branchCode"] = branchCode
      if (!branches[branchCode]["branchAPICode"]) {
        x["hasBranchAPICode"] = false
      } else {
        x["hasBranchAPICode"] = true
      }
      data.push(x)
    }
  }

  return (
    <FzTable
      data={data || []}
      ns={ns}
      metas={supplierMetas}
      visibleColumns={["branchCode", "branchNick", "city", "state", "neighbourhood",
        "address", "streetNumber", "complement", "hasBranchAPICode"]}
      exportDownload={false}
      clipboard={false}
      rowExpand={{
        expandComponent: (row) => expandComponent(ns, supplier, row, readOnly, persistChanges),
      }}
    />
  )

}

function changeNewBranchCodeField(newV, ns) {
  ns.set("newBranchCode", newV)
}

function addBranch(ns, supplier) {
  let branchC = ns.get("newBranchCode");
  if (!branchC) {
    alert("Código da nova filial deve ser digitado!");
    return
  }
  if (!supplier.branches)
    supplier.branches = {}
  if (supplier.branches[branchC]) {
    alert("Código da nova filial já existe para este parceiro!");
    return
  }
  supplier.branches[branchC] = {};
  //supplier.branches = JSON.parse('{"'+branchC+'": {}}');
  ns.set("supplier", supplier)
}

const SupplierBranches = ({ ns, supplier, readOnly, ...props }) => {

  let addBranchButton : $TsFixMe = (<FzCard><ButtonGroup>
    <Button title="Add Branch" onClick={() => addBranch(ns, supplier)}>< FzIcons icon={"plus"} fzStyle={{ width: "15px", height: "15px" }} /> </Button>
  </ButtonGroup>
    <FzCard.Body>
      <FzBadge>Código da nova filial--</FzBadge>
      {/* <InputText
        readOnly={false}
        name={"branchCode"}
        value={''}
        onChange={(newVal) => changeNewBranchCodeField(newVal, ns)}
        label="Código Filial"
      /> */}

      <FzText
        value={''}
        name={"branchCode"}
        onChange={(newVal) => changeNewBranchCodeField(newVal, ns)}
      />
    </FzCard.Body></FzCard>
  )
  if (readOnly) {
    addBranchButton = null
  }
  let Branches
  if (!supplier.branches) {
    Branches = ""
  }
  if (readOnly) {
    Branches = (<SupplierBranchesTable ns={ns} productsList={props.productsList} formSections={props.formSections} filters={props.filters} supplier={supplier} readOnly={readOnly} />)
  } else {
    Branches = []
    for (let code in supplier.branches) {
      Branches.push(
        <SupplierBranchEdit ns={ns} productsList={props.productsList} formSections={props.formSections} filters={props.filters} code={code} supplier={supplier} readOnly={readOnly} key={code} />
      )
    }
  }
  return (
    <FzCard>
      <FzCard.Heading fzStyle={{}}>
        <FzCard.Title componentClass="h3">Filiais (Loja)</FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        {addBranchButton}
        {Branches}
      </FzCard.Body>
    </FzCard>
  )
}

export default SupplierBranches;