import { Meta } from "../../../types/metas"


export const OCRModelForm = {
  "$group": "us",
  "$name": "OCRModel",
  "$span": 12,
  "$namePath": "id",
  "id": {
    "span": 6,
    'path': "id",
    'dataType': 'string',
    'label': "id",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': () => true,
    'isVisible': () => false,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "created": {
    "span": 6,
    'path': "created",
    'dataType': 'string',
    'label': "created",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': () => true,
    'isVisible': () => false,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "updated": {
    "span": 6,
    'path': "updated",
    'dataType': 'string',
    'label': "updated",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': () => true,
    'isVisible': () => false,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "createdBy": {
    "span": 6,
    'path': "createdBy",
    'dataType': 'string',
    'label': "createdBy",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': () => true,
    'isVisible': () => false,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "updatedBy": {
    "span": 6,
    'path': "updatedBy",
    'dataType': 'string',
    'label': "updatedBy",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': () => true,
    'isVisible': () => false,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "active": {
    "span": 6,
    'path': "active",
    'dataType': 'bool',
    'label': "active",
    'inputType': 'select',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': [
        { 'code': 'true', 'description': 'Sim' },
        { 'code': 'false', 'description': 'Não' },
      ],
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "documentType": {
    "span": 6,
    'path': "documentType",
    'dataType': 'string',
    'label': "documentType",
    'inputType': 'select',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => true,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': 'formalization-documents',
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "documentSubType": {
    "span": 6,
    'path': "documentSubType",
    'label': "documentSubType",
    'dataType': 'string',
    'inputType': 'select',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': 'document-subtypes',
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "documentSibling": {
    "span": 6,
    'path': "documentSibling",
    'label': "Documento irmão (analisado em conjunto)",
    'dataType': 'string',
    'inputType': 'select',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': 'formalization-documents',
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "supplierInternalName": {
    "span": 6,
    'path': "supplierInternalName",
    'dataType': 'string',
    'inputType': 'select',
    'label': "supplierInternalName",
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => true,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': "suppliers",
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "branch": {
    "span": 6,
    'path': "branch",
    'dataType': 'string',
    'label': "branch",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => false,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "minScore": {
    "span": 6,
    'path': "minScore",
    'dataType': 'number',
    'label': "minScore",
    'inputType': 'number',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => true,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
      'decimalDigits': 2
    }
  },
  "reason": {
    "span": 6,
    'path': "reason",
    'dataType': 'string',
    'label': "reason",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': /[a-z-]{3,50}/,
    'placeholder': "Nome sistêmico da regra. ex.: simplic-api-income-hol-validade",
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => true,
    'isReadOnly': (ns) => !ns.isEditing() || ns.getChanged("id"),
    'isVisible': undefined,
    'normalize': (val) => `${val}`.toLowerCase(),
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "message": {
    "span": 6,
    'path': "message",
    'dataType': 'string',
    'label': "message",
    'inputType': 'text',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => true,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
  "documentTags": {
    "span": 12,
    'path': "documentTags",
    'dataType': 'object[string]',
    'label': "documentTags",
    'inputType': 'objectString',
    'section': 'OCRModel',
    'relatedFields': undefined,
    'validationMessage': undefined,
    'pattern': undefined,
    'placeholder': undefined,
    'tip': undefined,
    'product': undefined,
    'isMandatory': value => true,
    'isReadOnly': undefined,
    'isVisible': undefined,
    'normalize': undefined,
    'extraValidation': undefined,
    'hookOnSave': undefined,
    'inputProps': {
      'domainName': undefined,
      'filteredFrom': undefined,
      'options': undefined,
      'maxValue': undefined,
      'minValue': undefined,
      'maxSize': undefined,
      'minSize': undefined,
      'autoComplete': undefined,
      'mask': undefined,
      'removeMask': undefined,
    }
  },
}

export const OCRModelColumns: Meta = {
  "id": {
    "path": "id",
    "label": "Id",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "created": {
    "path": "created",
    "label": "Criado em",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": () => false,
  },
  "updated": {
    "path": "updated",
    "label": "Atualizado em",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": () => false,
  },
  "active": {
    "path": "active",
    "label": "Active",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "DocumentType",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentSibling": {
    "path": "documentSibling",
    "label": "DocumentSibling",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentSubType": {
    "path": "documentSubType",
    "label": "DocumentSubType",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "SupplierInternalName",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "branch": {
    "path": "branch",
    "label": "Branch",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "message": {
    "path": "message",
    "label": "Message",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "reason": {
    "path": "reason",
    "label": "Reason",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "minScore": {
    "path": "minScore",
    "label": "MinScore",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentTags": {
    "path": "documentTags",
    "label": "DocumentTags",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "linkPath": {
    "path": "linkPath",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },

}
