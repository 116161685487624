import moment from 'moment'

import { Meta } from "../../types/metas";


export const creditRisk: Meta = {
  "createdAfter": {
    "path": "createdAfter",
    "label": "Data Inicial",
    "dataType": "moment",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T00") : value,
  },
  "createdBefore": {
    "path": "createdBefore",
    "label": "Data Final",
    "dataType": "moment",
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().add(+1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => false,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T00") : value,
  },
  "product": {
    "path": "product",
    "label": "Produto",
    "dataType": 'string',
    "inputType": "select",
    "inputProps": {
      "options": [
        { "code": "CP", "description": "Credito Pessoal" },
        { "code": "HE", "description": "Refinanciamento de Imóvel" },
        { "code": "Refin", "description": "Refinanciamento de Veículos" },
        { "code": "Fin", "description": "Financiamento de Veículos" },
        { "code": "Cartao", "description": "Cartão" }
      ],
    },
    isMandatory: (application) => true,
    "normalize": (value) => value === undefined || value === "undefined" ? null: value
  },
}

export const creditRiskTable: Meta = {

  "_id": {
    "path": "_id",
    "label": "_id",
    "dataType": "number",
    'tableProps': {
      "isKey": true,
      "width": "14ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "applicationIdColumn",
    },
    "isMandatory": (application) => false,
  },
  "created": {
    "path": "created",
    "label": "Criado",
    "dataType": "string",
    'tableProps': {
      "width": "15ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodWithTimeColumnTz"
    },
    "isMandatory": (application) => false,
  },
  "hashuser": {
    "path": "hashuser",
    "label": "IDúnico",
    "dataType": "number",
    'tableProps': {
      "headerText": "IDunico",
      "width": "30ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "newuser": {
    "path": "newuser",
    "label": "Novo usuário",
    "dataType": "bool",
    'tableProps': {
      "headerText": "Novo usuario",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
  "product": {
    "path": "product",
    "label": "Produto",
    "dataType": "string",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.product"
      },
    },
    "isMandatory": (application) => false,
  },
  "appswithsamepersonalnumemail": {
    "path": "appswithsamepersonalnumemail",
    "label": "CPFs unicos por email",
    "dataType": "bool",
    'tableProps': {
      "columnFormat": "booleanColumn_en",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "hidden": true,
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
  "changeaddress": {
    "path": "changeaddress",
    "label": "Mudou de endereço",
    "dataType": "bool",
    'tableProps': {
      "headerText": "Mudou de endereco",
      "columnFormat": "booleanColumn_en",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
  "changeemail": {
    "path": "changeemail",
    "label": "Mudou de email",
    "dataType": "bool",
    'tableProps': {
      "columnFormat": "booleanColumn_en",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
  "dayssincefirstrejection": {
    "path": "dayssincefirstrejection",
    "label": "Dias desde a primeira rejeição",
    "dataType": "number",
    'tableProps': {
      "headerText": "Dias desde a primeira rejeicao",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "dayssincelastrejection": {
    "path": "dayssincelastrejection",
    "label": "Dias desde a última rejeição",
    "dataType": "number",
    'tableProps': {
      "headerText": "Dias desde a ultima rejeicao",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "maxappliedamount": {
    "path": "maxappliedamount",
    "label": "Valor máximo pedido",
    "dataType": "number",
    'tableProps': {
      "headerText": "Valor maximo pedido",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "maxmonthlyincome": {
    "path": "maxmonthlyincome",
    "label": "Renda máxima",
    "dataType": "number",
    'tableProps': {
      "headerText": "Renda maxima",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "minappliedamount": {
    "path": "minappliedamount",
    "label": "Valor mínimo pedido",
    "dataType": "number",
    'tableProps': {
      "headerText": "Valor minimo pedido",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "minmonthlyincome": {
    "path": "minmonthlyincome",
    "label": "Renda mínima",
    "dataType": "number",
    'tableProps': {
      "headerText": "Renda minima",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "dayssincefirstapplication": {
    "path": "dayssincefirstapplication",
    "label": "Dias desde a primeira aplicação",
    "dataType": "number",
    'tableProps': {
      "headerText": "Dias desde a primeira aplicacao",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "dayssincefirstpaid": {
    "path": "dayssincefirstpaid",
    "label": "Dias desde o primeiro pago",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "dayssincelastapplication": {
    "path": "dayssincelastapplication",
    "label": "Dias desde a última aplicação",
    "dataType": "number",
    'tableProps': {
      "headerText": "Dias desde a última aplicacao",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "dayssincelastpaid": {
    "path": "dayssincelastpaid",
    "label": "Dias desde o último pago",
    "dataType": "number",
    'tableProps': {
      "headerText": "Dias desde o ultimo pago",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "sixmonthsrestrictions": {
    "path": "sixmonthsrestrictions",
    "label": "Restrições de seis meses",
    "dataType": "bool",
    'tableProps': {
      "headerText": "Restricoes de seis meses",
      "columnFormat": "booleanColumn_en",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
  "sumpaidamount": {
    "path": "sumpaidamount",
    "label": "Soma de valores pagos",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "sumpaidinstallments": {
    "path": "sumpaidinstallments",
    "label": "Soma das parcelas pagas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "threemonthrestrictions": {
    "path": "threemonthrestrictions",
    "label": "Restrições de três meses",
    "dataType": "bool",
    'tableProps': {
      "headerText": "Restricoes de tres meses",
      "columnFormat": "booleanColumn_en",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
  "totalapps": {
    "path": "totalapps",
    "label": "Total de propostas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "totalfiltered": {
    "path": "totalfiltered",
    "label": "Total Filtradas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "totalpaid": {
    "path": "totalpaid",
    "label": "Total Pagas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "totalrefused": {
    "path": "totalrefused",
    "label": "Total Recusadas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "totalrejected": {
    "path": "totalrejected",
    "label": "Total Rejeitadas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "totaltimeout": {
    "path": "totaltimeout",
    "label": "Total com timeout",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "totalwithbid": {
    "path": "totalwithbid",
    "label": "Total com ofertas",
    "dataType": "number",
    'tableProps': {
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "yearrestrictions": {
    "path": "yearrestrictions",
    "label": "Restrições em um ano",
    "dataType": "bool",
    'tableProps': {
      "headerText": "Restricoes em um ano",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "booleanColumn_en",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean_en"
      },
    },
    "isMandatory": (application) => false,
  },
}
