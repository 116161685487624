import React, { useEffect, useState, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { InputLayoutForm } from '../../../../biz/metadatas/InputLayout';
import { clone, get } from "lodash";
import { FzButton } from '../../../../ui/fz/form/button';
import FieldConnected from '../../../../ui/components/form/field-connected';
import { FzCol } from '../../../../ui/fz/layout';
import { formatToFloat, formatCurrency } from '../../../../utils/formatter';

import EditInputLayoutContainer from '../../../../Containers/InputLayout/Edit'
import { PutInputLayout, GetIdInputLayout } from '../../../../services/api/inputlayout'
import { getAllSuppliers } from '../../../../api/suppliers';
import api from '../../../../api/client-api';

const toastInitialConfig = {
  show: false,
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  fzStyle: 'danger',
  delay: 5000,
  autoHide: true,
}

const EditInputLayout = ({ ns }) => {
  const { inputLayoutId } = useParams<{ inputLayoutId: string }>();
  let history = useHistory();
  const [metaDataForm, setMetaDataForm] = useState<any>(InputLayoutForm)
  const [toastInfo, setToastInfo] = useState(toastInitialConfig);
  const [loading, setLoading] = useState(true);
  const [fieldConnectedItens, setFieldConnectedItens] = useState<any[]>([])
  const [fieldCount, setFieldCount] = useState<any>(0)
  const [suppliersOptions, setSuppliersOptions] = useState([]);
  const [mapFieldOptions, setMapFieldOptions] = useState([]);

  useEffect(() => {
    buildform()
    loadSuppliers()
    GetMapfieldsOnDomain()
    return function willMount() {
      ns.clear();
    }
  }, [])

  const handleToastClose = () =>
    setToastInfo(toastInitialConfig);


  const GetMapfieldsOnDomain = async () => {
    try {
      const result = await api.domains.getDomainValues("mapFields-gringotts")
      setMapFieldOptions(result)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const buildPathnamePayload = values => {
    let payload: any = {
      name: get(values, "name", ""),
      supplierInternalName: get(values, "supplierInternalName", ""),
      encoding: get(values, "encoding", ""),
      separator: get(values, "separator", ""),
      numberOfHeaderLines: get(values, "numberOfHeaderLines", ""),
      value: formatToFloat(get(values, "value", "")),
      installmentAmount: formatToFloat(get(values, "installmentAmount", "")),
      installment: get(values, "installment", ""),
      interestRate: formatToFloat(get(values, "interestRate", "")),
      comission: formatToFloat(get(values, "comission", "")),
      referenceNumberDays: get(values, "referenceNumberDays", ""),
      columns: []
    }

    const keyValues = Object.keys(values)
    const types = keyValues.filter(item => item.includes("type")).map(field => {
      const prefixId = field.replace(/type_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    const formats = keyValues.filter(item => item.includes("format")).map(field => {
      const prefixId = field.replace(/format_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    const sizes = keyValues.filter(item => item.includes("size")).map(field => {
      const prefixId = field.replace(/size_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: Number(values[field])
      })
    })

    const mapFields = keyValues.filter(item => item.includes("mapField")).map(field => {
      const prefixId = field.replace(/mapField_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    const fields = keyValues.filter(item => item.includes("field")).map(field => {
      const prefixId = field.replace(/field_/g, "")
      const keyField = field.replace(/\d+/g, "").replace("_", "")
      return ({
        id: Number(prefixId),
        [keyField]: values[field]
      })
    })

    payload.columns = types.map(type => {
      const size = sizes.find(size => size.id === type.id)
      const format = formats.find(format => format.id === type.id)
      const mapField = mapFields.find(mapField => mapField.id === type.id)
      const field = fields.find(field => field.id === type.id)

      if (size && format) {
        return ({
          ...field,
          ...type,
          ...size,
          ...format,
          ...mapField,
        })
      }
    }).map(element => ({
      field: element && element.field,
      type: element && element.type,
      size: element && element.size,
      format: element && element.format,
      mapField: element && element.mapField,
    }))
    return payload
  }

  const handleSubmit = async (values, fieldsInvalid) => {
    try {
      if (fieldsInvalid <= 0) {
        setLoading(true)
        await PutInputLayout(inputLayoutId, buildPathnamePayload(values));
        goToInputLayoutList();
        ns.unset("inputLayoutForm");
        setLoading(false)
        ns.stopEditing();
      }
    } catch (error) {
      setToastInfo({ ...toastInfo, show: true });
    }
  }

  const goToInputLayoutList = () => {
    ns.stopEditing();
    history.push("/input-layout/list");
  }

  const buildFormDataEditNs = values => {
    let payload = {
      name: get(values, "name", ""),
      encoding: get(values, "encoding", ""),
      separator: get(values, "separator", ""),
      numberOfHeaderLines: get(values, "numberOfHeaderLines", ""),
      supplierInternalName: get(values, "supplierInternalName", ""),
      value: formatCurrency(get(values, "value", "")),
      installmentAmount: formatCurrency(get(values, "installmentAmount", "")),
      installment: get(values, "installment", ""),
      interestRate: formatCurrency(get(values, "interestRate", "")),
      comission: formatCurrency(get(values, "comission", "")),
      referenceNumberDays: get(values, "referenceNumberDays", ""),
      columns: []
    }

    const columns = get(values, "columns", [])
      .map((element, index) => {
        const keys = Object.keys(element)

        return keys.reduce((acc, next) => ({ ...acc, [`${next}_${index}`]: element[next] }), {})
      })

    payload = {
      ...payload,
      ...columns.reduce((acc, next) => ({ ...acc, ...next }), {})
    }

    const elementsMeta = columns
      .filter(item => {
        let conditionFilter = true
        for (let key in item) {
          if (key.includes("field_0")) {
            conditionFilter = false
          }
        }
        return conditionFilter
      })
      .map(item => {
        const fields = Object.keys(item)
          .map(field => {
            return ({ 
              [field]: {
                ...clone(InputLayoutForm[field.replace(/\d+/g, "").replace("_", "_0")]),
                path: field,
              },
            })
          })
          return fields.reduce((acc, next) => ({...acc, ...next}), {})
        })
    setFieldCount(elementsMeta.length)

    setMetaDataForm({
      ...metaDataForm,
      ...elementsMeta.reduce((acc, next) => acc = ({ ...acc, ...next }), {}),
    })

    setFieldConnectedItens([
      ...fieldConnectedItens,
      ...elementsMeta.map((_, index) => component(index + 1)),
    ])

    return payload
  }

  const buildform = async () => {
    try {
      const { data = {} } = await GetIdInputLayout(inputLayoutId)
      const parseData = buildFormDataEditNs(data)
      ns.set("inputLayoutForm", parseData);
      ns.startEditing(parseData);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setToastInfo({ ...toastInfo, show: true });
    }
  }

  const handleRemoveItem = (indexField) => (nsFieldKey) => {
    const metaDataFormRemoved = metaDataForm
    delete metaDataFormRemoved[`field_${nsFieldKey}`]
    delete metaDataFormRemoved[`type_${nsFieldKey}`]
    delete metaDataFormRemoved[`format_${nsFieldKey}`]
    delete metaDataFormRemoved[`size_${nsFieldKey}`]
    delete metaDataFormRemoved[`mapField_${nsFieldKey}`]

    setMetaDataForm(metaDataFormRemoved)
    setFieldConnectedItens(fieldConnectedItens.filter((_, index) => index !== indexField))
    const nsData = ns.getChanged()
    delete nsData[nsFieldKey]
    delete nsData[`field_${nsFieldKey}`]
    delete nsData[`type_${nsFieldKey}`]
    delete nsData[`format_${nsFieldKey}`]
    delete nsData[`size_${nsFieldKey}`]
    delete nsData[`mapField_${nsFieldKey}`]
    ns.set("inputLayoutForm", nsData);
  }

  const component = indexObj => ({ handleRemoveItem, meta, ns }) => (
    <Fragment key={indexObj}>
      <FzCol span={3}>
        <FieldConnected
          meta={meta[`field_${indexObj}`]}
          ns={ns}
        />
      </FzCol>
      <FzCol span={2}>
        <FieldConnected
          meta={meta[`type_${indexObj}`]}
          ns={ns}
        />
      </FzCol>
      <FzCol span={2}>
        <FieldConnected
          meta={meta[`format_${indexObj}`]}
          ns={ns}
        />
      </FzCol>
      <FzCol span={2}>
        <FieldConnected
          meta={meta[`size_${indexObj}`]}
          ns={ns}
        />
      </FzCol>
      <FzCol span={2}>
        <FieldConnected
          meta={meta[`mapField_${indexObj}`]}
          ns={ns}
        />
      </FzCol>
      <FzCol span={1}>
        <div style={{ margin: "25px 0 0 10px" }}>
          <FzButton
            fzStyle={"alert"}
            onClick={() => handleRemoveItem(indexObj)}
          >-</FzButton>
        </div>
      </FzCol>
    </Fragment>
  )

  const handleAddFieldConnected = () => {
    const increment = fieldCount + 1
    const field = `field_${increment}`
    const type = `type_${increment}`
    const format = `format_${increment}`
    const size = `size_${increment}`
    const mapField = `mapField_${increment}`
    const metaClone = {
      [field]: clone(InputLayoutForm.field_0),
      [type]: clone(InputLayoutForm.type_0),
      [format]: clone(InputLayoutForm.format_0),
      [size]: clone(InputLayoutForm.size_0),
      [mapField]: clone(InputLayoutForm.mapField_0),

    }

    metaClone[field].path = field
    metaClone[type].path = type
    metaClone[format].path = format
    metaClone[size].path = size
    metaClone[mapField].path = mapField
    setFieldCount(increment)
    setMetaDataForm({ ...metaDataForm, ...metaClone })

    setFieldConnectedItens([...fieldConnectedItens, component(increment)])
  }

  const loadSuppliers = async () => {
    setLoading(true)
    const suppliersList = await getAllSuppliers()
    const parseSupplier = ({ internalName, name }) => ({ 
      code: internalName, 
      description: name,
    })   
    setSuppliersOptions(suppliersList.map(parseSupplier));
    setLoading(false)
  }

  return (
    <EditInputLayoutContainer
      metaDataForm={metaDataForm}
      inputLayoutForm={InputLayoutForm}
      suppliersOptions={suppliersOptions}
      ns={ns}
      handleSubmit={handleSubmit}
      goBack={goToInputLayoutList}
      toastInfo={toastInfo}
      handleToastClose={handleToastClose}
      loading={loading}
      handleAddFieldConnected={handleAddFieldConnected}
      handleRemoveItem={handleRemoveItem}
      fieldConnectedItens={fieldConnectedItens}
      mapFieldOptions={mapFieldOptions}
    />
  )
}

export default EditInputLayout;
