import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { FormattedTime, FormattedDate } from 'react-intl';
import { BidsPanel } from '../Containers/Applications/AuctionsBids/index';
import { getApplicationQueues } from '../api/integrations';
import { QueueList } from '../ui/blocks/integration-queues';
import api from '../api/client-api';
import { findSupplierBid } from '../business/application';
import { getFormalization } from '../api/formalization';
import { getSuppliers } from '../scripts/utils/cache-helpers';
import moment from 'moment';
import { FzCard, FzCol, FzRow, FzBadge } from '../ui/fz/layout/index';
import { FzForm } from '../ui/fz/layout/index';
import cloneDeep from 'lodash/cloneDeep';
import { getDomain } from '../../src/scripts/utils/cache-helpers';

const SupplierData = ({ supplier, supplierData, agentName, app, api, ns }) => {
  const formatEtapa = (cod) => {
    const map = {
      CAPDI: 'Captura Digitação',
      MOTLM: 'Chamada Motor Digitação',
      RETLM: 'Retorno Motor Digitação',
      SELIM: 'Seleção de Limites',
    };
    return map[cod] ? map[cod] : cod;
  };

  const formatStatus = (cod) => {
    const map = {
      ANDAM: 'Em andamento',
      CONCL: 'Concluida',
      CANCE: 'Cancelada',
      RECUS: 'Recusada',
      PENDT: 'Pendente',
      APROV: 'Aprovada',
      INDEF: 'Em processo',
      AVERB: 'Averbação concluída',
      TEIMO: 'Teimosinha',
      DVERB: 'Desaverbação concluída',
      ERRAP: 'Erro aplicação',
    };
    return map[cod] ? map[cod] : cod;
  };
  const formatMotor = (cod) => {
    const map = {
      APR: 'Aprovada',
      NEG: 'Negada',
      DIG: 'Pendente de digitação',
      DIT: 'Pendente de digitalização',
    };
    return map[cod];
  };

  let contentLines = [];
  // Verifica se o agentName está na lista de noSupplierAgentList.
  let noSupplierAgentList = ['hs', 'cbss-formalization', 'hs-v2', 'unico'];
  if (!noSupplierAgentList.includes(agentName)) {
    if (supplierData === null || supplierData === undefined) {
      return null;
    }
    let formatRejectReason = (rejectReason = '') => {
      let rr = rejectReason.split('|');
      if (rr.length === 1) {
        return rejectReason;
      }
      return rr[0] + ' (' + rr[1] + ')';
    };

    let row = 1;
    if (supplierData.sent) {
      contentLines.push(
        <FzRow key={row++}>
          <FzCol span={3}>
            <FzForm.Label>Enviado em:</FzForm.Label>
          </FzCol>
          <FzCol span={3}>
            <FormattedDate value={supplierData.dateSent} />
            &nbsp;
            <FormattedTime value={supplierData.dateSent} />
            &nbsp;(
            {supplierData.sentChannel}
            {supplierData.branchCode
              ? ' / ' +
              ns.getShared(
                `CACHE.suppliers.${supplier}.branches.${supplierData.branchCode}.branchNick`,
                supplierData.branchCode
              )
              : ''}
            )
          </FzCol>
        </FzRow>
      );
    }
    if (supplierData.supplierApplicationId) {
      contentLines.push(
        <FzRow key={row++}>
          <FzCol span={3}>
            <FzForm.Label>Proposta "{supplier}":</FzForm.Label>
          </FzCol>
          <FzCol span={3}>{supplierData.supplierApplicationId}</FzCol>
        </FzRow>
      );
    }
    if (supplierData.stageData && supplierData.stageData.branchdata) {
      const branchData = supplierData.stageData.branchdata;
      const branchDataMap = {
        branchPartnerName: 'Parceiro comercial',
        managerName: 'Nome do Gerente',
      };

      Object.keys(branchData).map((data) => {
        if (branchDataMap[data]) {
          let code = data.replace('Name', 'Code');
          contentLines.push(
            <FzRow key={row++}>
              <FzCol span={3}>
                <FzForm.Label>{branchDataMap[data]}:</FzForm.Label>
              </FzCol>
              <FzCol span={3}>
                {branchData[data].indexOf('Unknown') >= 0
                  ? branchDataMap[data] +
                  ' não cadastrado - Código: ' +
                  supplierData.stageData.branchdata[code]
                  : branchData[data]}
              </FzCol>
            </FzRow>
          );
          return data;
        }
      });
    }
    if (supplierData.accepted) {
      contentLines.push(
        <FzRow key={row++}>
          <FzCol span={3}>
            <FzForm.Label>Selecionado:</FzForm.Label>
          </FzCol>
          <FzCol span={3}>
            <FormattedDate value={supplierData.dateAccepted} />
            &nbsp;
            <FormattedTime value={supplierData.dateAccepted} />
          </FzCol>
        </FzRow>
      );
    }
    if (supplierData.rejected) {
      contentLines.push(
        <FzRow key={row++}>
          <FzCol span={3}>
            <FzForm.Label>Rejeitado em:</FzForm.Label>
          </FzCol>
          <FzCol span={3}>
            <FormattedDate value={supplierData.dateRejected} />
            &nbsp;
            <FormattedTime value={supplierData.dateRejected} />
          </FzCol>
        </FzRow>
      );
      contentLines.push(
        <FzRow key={row++}>
          <FzCol span={3}>
            <FzForm.Label>Motivo da rejeição:</FzForm.Label>
          </FzCol>
          <FzCol span={3}>
            {formatRejectReason(supplierData.rejectedReason)}
          </FzCol>
        </FzRow>
      );
      // let codRetornoMotor = ((supplierData.stageData || {}).reject || {}).codRetornoMotor
      // if (codRetornoMotor) {
      //   contentLines.push(
      //     <FzRow key={row++}>
      //       <FzCol span={3}><FzForm.Label>&nbsp;</FzForm.Label></FzCol>
      //       <FzCol span={3} className="dimmed">{codRetornoMotor}</FzCol>
      //     </FzRow>
      //   )
      // }
    }

    if (
      supplierData.stageData &&
      supplierData.stageData.lastStatus &&
      JSON.stringify(supplierData.stageData.lastStatus) !== '{}'
    ) {
      let lastStatus = supplierData.stageData.lastStatus;

      if (lastStatus.description || lastStatus.status) {
        let translatedDescription;
        switch (agentName) {
          case 'noverde':
            translatedDescription = {
              cancelled: 'Cancelado',
              denied: 'Emprestimo Negado',
              granted: 'Garantido',
              lent: 'Pago',
              pending: 'Pendente',
              preapproved: 'Pré-aprovado',
              proposal: 'Proposta',
              waiting_acceptance: 'Recebimento do contrato',
            };
            break;
          case 'supersim':
            translatedDescription = {
              'EMPRÉSTIMO NÃO ENCONTRADO': 'Empréstimo Não Encontrado',
              RECUSADO: 'Recusado',
              EXPIRADO: 'Expirado',
              SOLICITADO: 'Solicitado',
              'PENDENTE DADOS': 'Pendente Dados',
              'PENDENTE ASSINATURA': 'Pendente Assinatura',
              'PENDENTE INSTALACAO APP | AUTOAPPROVAL':
                'Pendente Instalacao App | Autoapproval',
              'PENDENTE INSTALACAO APP': 'Pendente Instalacao App',
              'PENDENTE APROVASIM': 'Pendente Aprovasim',
              'PENDENTE RENDA': 'Pendente Renda',
              LIBERADO: 'Liberado',
            };
            break;
          case 'simplic':
            translatedDescription = {
              issued: 'PAGO',
              invalid_token:
                'Token inválido - Solicitar ao cliente o contato direto com o parceiro',
            };
        }

        const description =
          (translatedDescription &&
            (translatedDescription[lastStatus.description] ||
              translatedDescription[lastStatus.status])) ||
          'Última atualização';

        contentLines.push(
          <FzRow key={row++}>
            <FzCol span={3}>
              <FzForm.Label>{description}:</FzForm.Label>
            </FzCol>
            <FzCol span={3}>
              <FormattedDate value={lastStatus.updated} />
              &nbsp;
              <FormattedTime value={lastStatus.updated} />
            </FzCol>
          </FzRow>
        );
      } else {
        let statusMap = {};
        switch (agentName) {
          case 'bkf':
            statusMap = {
              contact_success: 'Contato com sucesso',
              credit_analysed: 'Crédito Analisado',
              inspection_scheduled: 'Inspeção Agendada',
              inspection_done: 'Inspeção Realizada',
            };
            break;
          case 'focus':
            statusMap = {
              awaiting_registration: 'APR - Aguarda Registro',
              correct_registration: 'APR - Corrigir Registro',
              integrated: 'INT - Integrado',
            };
            break;
          case 'crefaz':
            statusMap = {
              'cancelada': 'Cancelada',
              'Negada': 'Negada',
              'Fila Pagamento': 'Paga',
              'Operação Paga': 'Paga',
              'Falha de Comunicação': 'Falha de Comunicação',
              'Seleção Oferta': 'Seleção Oferta',
              'Aguard. Cadastro': 'Aguardando Cadastro',
              'Aguard. Análise': 'Aguardando Análise',
              'Em Análise': 'Em Análise',
              'Proposta Pendente': 'Proposta Pendente',
              'Fila Contato': 'Fila Contato',
              'Ligando': 'Ligando',
              'Contato Pendente': 'Contato Pendente',
              'Aguard. Assinatura': 'Aguardando Assinatura',
              'Aguard. Validação': 'Aguardando Validação',
              'Análise Validação': 'Análise Validação',
              'Validação Pendente': 'Validação Pendente',
              'Análise Promotora': 'Análise Promotora',
              'Aguard. Checagem': 'Aguardando Checagem',
              'Aguard. Averbação': 'Aguardando Averbação',
              'Atuação Cliente': 'Atuação Cliente'
            };
            break;
        }

        const statusEntries = Object.entries(lastStatus);
        const sortedEntries = statusEntries.sort(
          (a, b) => new Date(a[1]) - new Date(b[1])
        );
        sortedEntries.map(([status, date]) => {
          const statusDescription = statusMap[status] || status.toUpperCase()

          contentLines.push(
            <FzRow key={row++}>
              <FzCol span={3}>
                <FzForm.Label>{statusDescription}:</FzForm.Label>
              </FzCol>
              <FzCol span={3}>
                <FormattedDate value={date} />
                &nbsp;
                <FormattedTime value={date} />
              </FzCol>
            </FzRow>
          );
          return status;
        });
      }
    }

    let supplierBid = findSupplierBid(app, supplier);

    if (supplierBid && supplierBid.refusedReason !== undefined) {
      contentLines.push(
        <FzRow key={row++}>
          <FzCol span={3}>
            <FzForm.Label>Motivo da recusa:</FzForm.Label>
          </FzCol>
          <FzCol span={3}>{supplierBid.refusedReason}</FzCol>
        </FzRow>
      );
    }
    if (supplierData.stageData && supplierData.stageData.formalizationStatus) {
      contentLines.push(
        <div>
          <h4>Status da proposta</h4>
          <FzRow key={row++}>
            <FzCol span={3}>
              <FzForm.Label>Etapa:</FzForm.Label>
            </FzCol>
            <FzCol span={7}>
              {formatEtapa(supplierData.stageData.formalizationStatus.codEtapa)}
            </FzCol>
          </FzRow>
          <FzRow key={row++}>
            <FzCol span={3}>
              <FzForm.Label>Status:</FzForm.Label>
            </FzCol>
            <FzCol span={7}>
              {formatStatus(
                supplierData.stageData.formalizationStatus.codStatus
              )}
            </FzCol>
          </FzRow>
          <FzRow key={row++}>
            <FzCol span={3}>
              <FzForm.Label>Motor:</FzForm.Label>
            </FzCol>
            <FzCol span={7}>
              {formatMotor(
                supplierData.stageData.formalizationStatus.codRetornoMotor
              )}
            </FzCol>
          </FzRow>
          <FzRow key={row++}>
            <FzCol span={3}>
              <FzForm.Label>Status Motor:</FzForm.Label>
            </FzCol>
            <FzCol span={7}>
              {formatStatus(
                supplierData.stageData.formalizationStatus.codMotorStatus
              )}
            </FzCol>
          </FzRow>
          {supplierData.stageData.formalizationStatus.comment ? (
            <FzRow key={row++}>
              <FzCol span={3}>
                <FzForm.Label>Comentário da Mesa:</FzForm.Label>
              </FzCol>
              <FzCol span={7}>
                {supplierData.stageData.formalizationStatus.comment}
              </FzCol>
            </FzRow>
          ) : null}
          <FzRow key={row++}>
            <FzCol span={3}>
              <FzForm.Label>Data da consulta:</FzForm.Label>
            </FzCol>
            <FzCol span={7}>
              <FormattedDate
                value={supplierData.stageData.formalizationStatus.data}
              />
              &nbsp;
              <FormattedTime
                value={supplierData.stageData.formalizationStatus.data}
              />
            </FzCol>
          </FzRow>
        </div>
      );
    }

    let formalizationData = ns.get('formalization');
    if (
      formalizationData &&
      formalizationData.supplierInternalName !== supplier
    ) {
      formalizationData = undefined;
    }
    let selectedBid = findSupplierBid(app, supplier);
    if (
      formalizationData === undefined &&
      selectedBid &&
      selectedBid.formalizationId
    ) {
      getFormalization(app.id, selectedBid.id).then((f) => {
        ns.set('formalization', f);
      });
    }
  }

  return (
    <FzCard>
      <FzCard.Body>
        {contentLines}
        {ns.get('queues') ? (
          <QueueList
            queueData={ns.get('queues')}
            supplier={supplier}
            agentName={agentName}
            ns={ns}
          />
        ) : (
          'Olá, Só um minutinho. Carregando...'
        )}
      </FzCard.Body>
    </FzCard>
  );
};

class PresenterBadges extends React.Component {
  render() {
    let { bid } = this.props;

    let fieldsBadges = null;

    if (bid && bid.badges) {
      fieldsBadges = bid.badges.map((badge) => {
        const description = getDomain(this.props.ns, 'badges', badge);
        return <FzBadge> {description !== null ? description : badge}</FzBadge>;
      });
    }

    return (
      <FzCard>
        <FzCard.Body>{fieldsBadges}</FzCard.Body>
      </FzCard>
    );
  }
}

export class PartnerDataPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeSupplier: this.props.activeSupplier, queues: null };
    this.setActive = this.setActive.bind(this);
    this.hasBid = this.hasBid.bind(this);
    this.getAgentName = this.getAgentName.bind(this);
  }
  getAgentName(supplierIN) {
    let agentName =
      supplierIN === 'hs' ||
        supplierIN === 'hs-v2' ||
        supplierIN === 'unico' ||
        supplierIN === 'cbss-formalization'
        ? supplierIN
        : this.props.app.supplierData[supplierIN].agentName;

    return agentName || supplierIN;
  }

  setActive(e) {
    if (this.state.activeSupplier === e.target.id) {
      this.setState({ activeSupplier: null });
      this.props.ns.unset('queues');
    } else {
      this.setState({ activeSupplier: e.target.id });

      if (api.isITUser() || api.isUserSupplier()) {
        getApplicationQueues(
          this.props.app.id,
          this.getAgentName(e.target.id)
        ).then((queues) => {
          this.props.ns.set('queues', queues);
        });
      }
    }
  }

  hasBid(supplierId, bids) {
    for (let bid of bids) {
      if (bid.supplierId === supplierId) {
        return true;
      }
    }
    return false;
  }

  render() {
    let { app, ns, showBadges } = this.props;
    let openSupplier = null;
    let badgesInBids = null;

    if (this.state.activeSupplier) {
      openSupplier = (
        <SupplierData
          supplier={this.state.activeSupplier}
          supplierData={app.supplierData[this.state.activeSupplier]}
          agentName={this.getAgentName(this.state.activeSupplier)}
          app={app}
          api={api}
          ns={ns}
        />
      );
    }

    if (this.state.activeSupplier && showBadges) {
      badgesInBids = this.getBadges(app, badgesInBids);
    }

    if (!app.supplierData) {
      return null;
    } else {
      let supplierData = cloneDeep(app.supplierData);

      const user = api.getCurrentUser();

      if (user && user.role === 'supplier') {
        const suppliersObj = getSuppliers(this.props.ns);
        const supplierNames = Object.keys(suppliersObj);
        const supplierName = supplierNames[0];
        supplierData = { [supplierName]: { ...supplierData[supplierName] } };
      }

      // Show one button per supplier in SupplierData
      let suppls = [];
      for (var key in supplierData) {
        if (
          supplierData.hasOwnProperty(key) &&
          (!this.props.isSupplier || this.props.activeSupplier === key)
        ) {
          let style = 'default';

          if (
            app.auction &&
            app.auction.bids &&
            app.auction.bids.length > 0 &&
            this.hasBid(supplierData[key].supplierId, app.auction.bids) &&
            !supplierData[key].rejected
          ) {
            style = 'success';
          } else if (supplierData[key].rejected) {
            style = 'danger';
          } else if (supplierData[key].accepted) {
            style = 'info';
          }
          suppls.push(
            <Button
              size="sm"
              variant={style}
              active={this.state.activeSupplier === key}
              key={key}
              id={key}
              onClick={this.setActive}
            >
              {key}
            </Button>
          );
        }
      }

      suppls.push(
        <Button size="sm" key={'hs'} id={'hs'} onClick={this.setActive}>
          {'hs'}
        </Button>,
        <Button
          size="sm"
          key={'acesso'}
          id={'cbss-formalization'}
          onClick={this.setActive}
        >
          {'acesso'}
        </Button>,
        <Button
          size="sm"
          key={'hs-v2'}
          id={'hs-v2'}
          onClick={(e) => this.setActive(e)}
        >
          {'hs-v2'}
        </Button>,
        <Button size="sm" key={'unico'} id={'unico'} onClick={this.setActive}>
          {'unico'}
        </Button>
      );

      return (
        <FzCard>
          <FzCard.Body>
            <div>{suppls}</div>
            {openSupplier}
            {badgesInBids}
          </FzCard.Body>
        </FzCard>
      );
    }
  }

  getBadges(app, badgesInBids) {
    if (app && app.auction && app.auction.bids) {
      let bid = app.auction.bids.find(
        (bid) => bid.supplier.internalName === this.state.activeSupplier
      );

      if (bid && bid.badges) {
        badgesInBids = <PresenterBadges bid={bid} ns={this.props.ns} />;
      }
    }
    return badgesInBids;
  }
}

const AuctionDetailsHeader = ({ auction }) => {
  if (!auction) {
    return <h3>Bancos</h3>;
  }

  let name = auction.startedByName;
  if (auction.startedBySelf) {
    name = 'Usuário';
  }

  let total = new Date(auction.endDate) - new Date(auction.startDate);
  let current = new Date() - new Date(auction.startDate);
  let remain =
    (moment(auction.endDate).diff(moment()) > 0 ? 'Finaliza ' : 'Finalizado ') +
    moment(auction.endDate).from(moment());
  return (
    <FzRow>
      <FzCol span={3}>
        <h3 className="panel-title">Leilão</h3>
      </FzCol>
      <FzCol span={6} className="query-details">
        Iniciado em{' '}
        <FormattedDate
          value={auction.startDate}
          day="2-digit"
          month="2-digit"
        />
        &nbsp;
        <FormattedTime value={auction.startDate} timeZone="America/Sao_Paulo" />
        , por {name}.&nbsp;
        {remain}
      </FzCol>
      <FzCol span={3}>
        <ProgressBar now={current} max={total} />
      </FzCol>
    </FzRow>
  );
};

export class AuctionInfo extends React.Component {
  render() {
    let { ns, api, showBidsPanel, showPartnersData, app } = this.props;
    let bidsPanel = null;

    if (showBidsPanel && app.auction) {
      bidsPanel = (
        <BidsPanel
          ns={ns}
          application={app}
          api={api}
          history={this.props.history}
        />
      );
    }
    let partnersData = null;
    if (showPartnersData && Object.keys(app.supplierData).length !== 0) {
      partnersData = (
        <PartnerDataPanel app={app} api={api} ns={ns} showBadges={true} />
      );
    }

    if (bidsPanel === null && partnersData === null) {
      return null;
    }
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title>
            {<AuctionDetailsHeader auction={app.auction} />}
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {bidsPanel}
          {partnersData}
        </FzCard.Body>
      </FzCard>
    );
  }
}
