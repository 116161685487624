import React from 'react';
import { PaidApplications } from '../../pages/statistics/application/paid-apps';
import { paid, overview } from '../../../biz/metadatas/paid'
import { FzCardGroup } from '../../fz/layout/index';
import { FzScoreStats } from '../../pages/statistics/application/fz-score-stats';
import { CustomerDeletedReports } from '../../pages/statistics/Customer';

const MktStats = ({ ns, activeKey, toggleBlocking, ...props }) => {

  let content
  switch (activeKey) {
    case "paid":
      content = <PaidApplications ns={ns} meta={paid} history={props.history} toggleBlocking={toggleBlocking} />
      break;
    case "fzscore":
      content = <FzScoreStats ns={ns} toggleBlocking={toggleBlocking} />
      break;
    case "deleted-reason":
      content = <CustomerDeletedReports ns={ns} toggleBlocking={toggleBlocking} />
      break;
    default:
      content = null;
      break;
  }
  return (
    <FzCardGroup id="MktStats">
      {content}
    </FzCardGroup>
  );
}

export default MktStats;