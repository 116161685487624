import React from 'react'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzRow, FzCol } from '../../../ui/fz/layout'
import { FzTable } from '../../../ui/fz/grid/table'
import { managerScreeningMeta } from '../../../biz/metadatas/manager-screening'
import { FzButton, FzField } from '../../../ui/fz/form'
import { FzToast } from '../../../ui/fz/form/notification/toast'

const ManagerScreening = ({
  ns,
  data,
  formData,
  visibleColumns,
  handleSearchScreenings,
  handleChangeValue,
  handleGoToDetail,
  handleTostClose,
  showTost,
  toastType,
  toastMessage
}) => {
  const goToScreening = (screeningId) => {
    return (
      <FzButton
        customStyle={{
          borderColor: '#5923DD',
          width: '100%',
          color: '#fff',
          background: '#5923DD'
        }}
        size="small"
        fzStyle=""
        onClick={() => handleGoToDetail(screeningId)}
      >
        Abrir proposta
      </FzButton>
    )
  }

  return (
    <FzCard>
      <FzCard.Body>
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              Análise em aberto
            </FzCard.Title>
          </FzCard.Header>
          <FzCard.Body>
            <FzRow>
              <FzCol span={3}>
                <FzField
                  name="applicationId"
                  dataType="string"
                  label="Application ID"
                  inputType="text"
                  fzStyle="small"
                  onChange={(value) => handleChangeValue(value, 'applicationId')}
                  placeHolder="Enter ID"
                  value={formData.applicationId}
                />
              </FzCol>
              <FzCol span={3}>
                <FzField
                  style={{ background: 'red' }}
                  name="personalNumber"
                  dataType="string"
                  label="CPF"
                  inputProps={{
                    mask: "111.111.111-11",
                    removeMask: true
                  }}
                  inputType="masked"
                  fzStyle="small"
                  onChange={(value) => handleChangeValue(value, 'personalNumber')}
                  value={formData.personalNumber}
                />
              </FzCol>
              <FzCol span={4}>
                <div style={{ margin: '20px 0 0 0' }}>
                  <FzButton onClick={handleSearchScreenings} disabled={!formData.applicationId && !formData.personalNumber} fzStyle="discrete">
                    Buscar Cliente
                  </FzButton>
                </div>
              </FzCol>
            </FzRow>
          </FzCard.Body>
        </FzCard>
        <div className={'mt-3'}>
          <FzCard>
            <FzCard.Header>
              <FzCard.Title>
                Cliente: {data.fullName}
              </FzCard.Title>
            </FzCard.Header>
            <FzTable
              ns={ns}
              metas={managerScreeningMeta}
              data={data.bids}
              exportDownload={false}
              clipboard={false}
              visibleColumns={visibleColumns}
              customColumnFormatters={{ screeningId: (cell) => goToScreening(cell) }}
            />
          </FzCard>
        </div>
      </FzCard.Body>
      <FzToast
        fzStyle={toastType}
        close={handleTostClose}
        show={showTost}
        delay={5000}
        headerMessageTost="Screening"
        bodyMessageTost={toastMessage}
        autoHide={true}
      />
    </FzCard>
  )
}

export { ManagerScreening }
