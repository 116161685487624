import React, { Component } from 'react'
import { existsEmail, existsPersonalNumber, loginWithGAuth, clearAuth, setAuth } from '../../api/auth';
import api from '../../api/client-api';
import FieldConnected from './form/field-connected';
import { Application } from '../../biz/metadatas/application';
import { validateApplication } from '../../biz/validate-application';
import { FzButton } from '../fz/form/button';
import { FzRow, FzCol } from '../fz/layout/index';
import '../fz/style/google.css';
export default class GAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      isNewUser: false,
      existsPersonalNumber: false,
    }
    this.requestLogin = this.requestLogin.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onFailure = this.onFailure.bind(this)
    this.createAccount = this.createAccount.bind(this)
  }

  requestLogin(e) {
    if (e) {
      e.preventDefault()
    }
    if (this.state.existsPersonalNumber) {
      return
    }
    if (!this.state.isNewUser && !this.state.disabled && window.gapi && window.gapi.auth2 && window.gapi.auth2.getAuthInstance) {
      const auth2 = window.gapi.auth2.getAuthInstance()
      auth2.signIn({})
        .then(res => this.onSuccess(res).then((res) => {
          api.signinListen(auth2)
        }), err => this.onFailure(err))
    } else {
      this.setState({ disabled: true })
    }
  }

  createAccount(v) {
    const data = this.props.ns.getChanged()
    if (validateApplication(data, this.props.ns, ["personalNumber", "telephoneNumber"]).length === 0) {
      existsPersonalNumber(data.personalNumber)
        .then(exists => {
          if (exists === "OK") {
            this.setState({ existsPersonalNumber: true })
          } else {
            loginWithGAuth(data.email, data.gtoken, data.telephoneNumber, data.personalNumber)
              .then(login => {
                const user = { ...login.user, tokens: { gtoken: login.gtoken, token: login.token } }
                setAuth(login.token, user, login.gtoken)
                
                let nextPathname
                if (this.props.location?.search?.includes("nextPathname")) {
                  nextPathname = this.props.location.search.replace("?", "").replace("nextPathname=", "")
                }
                
                this.props.history.push(nextPathname || '/')
              })
              .catch(this.onFailure)
          }
        })
        .catch(err => {
          console.log(err)
          loginWithGAuth(data.email, data.gtoken, data.telephoneNumber, data.personalNumber)
            .then(login => {
              const user = { ...login.user, tokens: { gtoken: login.gtoken, token: login.token } }
              setAuth(login.token, user, login.gtoken)
              
              let nextPathname
              if (this.props.location?.search?.includes("nextPathname")) {
                nextPathname = this.props.location.search.replace("?", "").replace("nextPathname=", "")
              }
              
              this.props.history.push(nextPathname || '/')
            })
            .catch(this.onFailure)
        })
    }
  }

  onSuccess(res) {
    const basicProfile = res.getBasicProfile()
    const authResponse = res.getAuthResponse()
    res.googleId = basicProfile.getId()
    res.tokenObj = authResponse
    res.tokenId = authResponse.id_token
    res.accessToken = authResponse.access_token
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName()
    }
    // send to backend for validation res.tokenId
    return existsEmail(res.profileObj.email)
      .then(exists => {
        if (exists === "OK") {
          this.saveSession(res)
            .then(token => loginWithGAuth(res.profileObj.email, token))
            .then(async (login) => {
              const user = { ...login.user, tokens: { gtoken: login.gtoken, token: login.token } }
              await setAuth(login.token, user, login.gtoken)

              let nextPathname
              if (this.props.location?.search?.includes("nextPathname")) {
                nextPathname = this.props.location.search.replace("?", "").replace("nextPathname=", "")
              }
              
              this.props.history.push(nextPathname || '/')
              await window.setTimeout(window.location.reload(false), 2000);
            })
            .catch(this.onFailure)
        } else {
          this.setState({ isNewUser: true, disabled: true }, () => {
            this.props.ns.startEditing({
              email: res.profileObj.email,
              gtoken: res.tokenId
            })
          })
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ isNewUser: true, disabled: true }, () => {
          this.props.ns.startEditing({
            email: res.profileObj.email,
            gtoken: res.tokenId
          })
        })
      })
  }

  saveSession(res) {
    return new Promise((resolve) => {
      let profile = res.getBasicProfile();
      sessionStorage.setItem('fz-ga-authToken', profile.getId());
      sessionStorage.setItem('fz-ga-name', profile.getName());
      sessionStorage.setItem('fz-ga-imageUrl', profile.getImageUrl());
      sessionStorage.setItem('fz-ga-email', profile.getEmail());
      sessionStorage.setItem('fz-ga-token', res.tokenId);
      resolve(sessionStorage.getItem('fz-ga-token'))
    })
  }

  onFailure(err) {
    console.error(err)
    sessionStorage.removeItem('fz-ga-authToken')
    sessionStorage.removeItem('fz-ga-name')
    sessionStorage.removeItem('fz-ga-imageUrl')
    sessionStorage.removeItem('fz-ga-email')
    sessionStorage.removeItem('fz-ga-token')
    clearAuth()
  }

  render() {
    const { ns } = this.props
    const data = ns.getChanged() || {}
    const actionButton = this.state.isNewUser ?
      (<FzRow>
        <FzCol>
          <FzButton name="create" fzStyle="regular" disabled={this.state.linkAccount} onClick={this.createAccount}>Criar conta</FzButton>
        </FzCol>
      </FzRow>) :
      (<div style={{ height: "32px", marginBottom: "10px" }}
        onClick={this.requestLogin}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false, active: false })}
        onMouseDown={() => this.setState({ active: true })}
        onMouseUp={() => this.setState({ active: false })} >
        <div id="gSignInWrapper">
          <div id="customBtn" className="customGPlusSignIn">
            <div className="icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
            </div>
            <div className="buttonText"><div className={"buttonTextWrapper"}>Autenticar com Google</div></div>
          </div>
        </div>
      </div>);
    const newUserForm = (<div className="gauth-new-user-fields">
      <FieldConnected ns={ns} data={data} meta={Application.getField("personalNumber")} />
      <FieldConnected ns={ns} data={data} meta={Application.getField("telephoneNumber")} />
    </div>)
    const existsPersonalNumberMessage = <h3>CPF já registrado</h3>
    return (
      <div className="gauth-container">
        {this.state.existsPersonalNumber ? existsPersonalNumberMessage : null}
        {this.state.isNewUser ? newUserForm : null}
        {actionButton}
      </div>
    )
  }
}
