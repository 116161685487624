import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { FzCard, FzCol, FzForm, FzRow } from '../../fz/layout/index';
import { getBiddableCurrentSupplier, supplierSend } from '../../../api/suppliers';
import { getCurrentSupplier, getCurrentUser } from '../../../api/auth';
import { FilterProductsSupplierPage } from '../../blocks/supplier/product-filters'

export class BiddableApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nbApplications: null,
      currentUser: null,
      supplierInternalName: null,
      currentSupplier: null,
      checked: "*",
      product: "*"
    }

    this.makeBranchesOfProduct = this.makeBranchesOfProduct.bind(this)
    this.updateLeads = this.updateLeads.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  makeBranchesOfProduct() {

    const { ns } = this.props
    let applications = ns.get("biddable-supplier", [])
    let supplier = getCurrentSupplier()
    let user = getCurrentUser()

    if (this.state.nbApplications !== applications.length) {
      this.setState({ "nbApplications": applications.length })
    }

    if (this.state.currentUser !== user) {
      this.setState({ "currentUser": user })
    }

    if (supplier && this.state.supplierName !== supplier.internalName) {
      this.setState({ "supplierInternalName": supplier.internalName })
    }

    let branches = [];
    let branch = "";
    let product = this.state.product || this.state.checked

    if (supplier) {
      var supBranches = supplier.branches;
      for (var b in supBranches) {
        if (supBranches.hasOwnProperty(b)) {
          if (supBranches[b].acceptedProducts.toLowerCase().indexOf(product.toLowerCase()) >= 0 || product === "*") {
            branches.push({
              code: supBranches[b].branchNick,
              value: supBranches[b],
              branchcode: b
            })
            if (branch === "")
              branch = b;
          }
        }
      }
    }

    getBiddableCurrentSupplier(1, product, branch, false)
      .then((data) => {
        if (data) {
          this.setState({ applications: data, nbApplications: data.length })
        }
      })
      .catch((errorCode) => {
        if (errorCode === 403) {
          //TODO: navigate to login page
        }
      })
    this.setState({ "supplierBranches": branches })
  }

  changeBranchCodeSelect(branch) {
    let product = this.props.sharedNS.get("productField", "*")
    getBiddableCurrentSupplier(1, product, branch, false)
      .then((data) => {
        if (data) {
          this.setState({ applications: data, nbApplications: data.length });
        }
      })
  }

  apiError(errorCode) {
    if (errorCode === 403) {
      //TODO: navigate to login page
    }
  }


  getData() {
    const { ns } = this.props

    let product = this.state.product || this.props.checked
    return getBiddableCurrentSupplier(1, product, null, false)
      .then((resultData) => {
        ns.set("biddable-supplier", resultData)
      })
      .catch(err => {
        console.error(err)
        alert(`Erro no servidor contate FinanZero \n  ${err}`)
      })
  }


  async componentDidMount() {
    await this.getData()
    await this.makeBranchesOfProduct()
    await this.updateLeads()
  }


  onChange(value) {
    this.setState({ checked: value, product: value }, () => this.getData())
  }

  async updateLeads() {
    const result = await getBiddableCurrentSupplier(1, "*", null, false)
    this.setState({ totalOfLeads: result.length })

  }

  render() {

    const goToNextApp = () => {

      // find oldest auction.created application
      let now = moment(new Date());
      let age = 0
      let nextApp = null;

      for (let app of this.state.applications) {
        if (age < now.diff(moment(app.auction.created))) {
          age = now.diff(moment(app.auction.created))
          nextApp = app
        }
      }

      // sentChannel set to "cof" into supplierData and navigate to the page then
      if (nextApp) {
        let currentBranchCode = nextApp.supplierData[this.state.supplierInternalName].branchCode
        supplierSend(nextApp.id, this.state.supplierInternalName, currentBranchCode, "cof")
          .then((supplier) => {
            this.props.history.push("/suppliers/" + nextApp.id);
          })
          .catch((err) => {
            console.logalert("Sistema temporariamente indisponível")
          })
      }
    }

    let brch = this.state.supplierBranches;
    var brancList = function (brch) {
      var brancList = [];
      brancList.push(<option value=''>Selecione uma opção...</option>);
      if (brch) {
        brch.forEach(b => {
          brancList.push(<option value={b.branchcode}>{b.value.branchNick}</option>)
          //brancList.push(<option value={b.value}>{b.value.branchNick}</option>)
        });
      }
      return brancList;
    }(brch);



    return (
      <FzCard>

        <FilterProductsSupplierPage updatedLeads={this.updateLeads} page={"biddable"} total={this.state.totalOfLeads} checked={this.state.checked} onChange={this.onChange} />

        <FzCard.Body>


          <FzCard>

            <FzCard.Heading>
              <FzCard.Title>Novos Leads</FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body>
              <FzRow>
                <FzCol span={3}>
                  <FzForm.Control
                    name={"supplierbranches"}
                    as="select"
                    placeholder="Selecione"
                    onChange={(e) => this.changeBranchCodeSelect(e.target.value)} //(e.target.value)}
                  >
                    {brancList}
                  </FzForm.Control>

                  <Button
                    disabled={this.state.nbApplications === 0}
                    onClick={goToNextApp}
                  >Próxima proposta
                    </Button>
                  <span className="badge">{this.state.nbApplications}</span>
                </FzCol>
              </FzRow>
            </FzCard.Body>
          </FzCard>
        </FzCard.Body>
      </FzCard>
    );
  }
}
