import React, { ReactElement, useState } from 'react'
import { DOMElement } from 'react';
import { FzTextarea } from "../../form";
import { FzSelect } from "../../form/old-simple-input";

export function RenderFormBuild(props) {
  const { tablePropsRow, onUpdate, options } = props
  const [data, setData] = useState(tablePropsRow.defaultValue)

  function changeValue(value) {
    setData(value)
  }

  function updateData() {
    onUpdate(data);
  }

  function close() {
    const fakeColumn: $TsFixMe = document.getElementsByClassName('hidden-fake-click')[0]
    fakeColumn.click()
  }

  return (
    <div>
      <div>
        <FormBuildTableEdit
          changeValue={changeValue}
          data={data}
          options={options}
          typeField={props.typeField}
          {...props}
        />
      </div>
      <div >
        <button type='button' className='btn btn-primary' onClick={updateData}>Salvar</button>
        <button type='button' className='btn btn-default' onClick={close}>Cancelar</button>
      </div>
    </div>
  );
}

function FormBuildTableEdit(props) {
  let ComponentField: ReactElement
  switch (props.typeField) {
    case "textarea":
      ComponentField = (
        <FzTextarea
          onChange={props.changeValue}
          value={props.data}
        />
      )
      break;
    case "select":
      ComponentField = (
        <FzSelect
          onChange={props.changeValue}
          value={props.data}
          options={props.options}
        />
      )
      break;
    default:
      ComponentField = <></>
      break;
  }
  return ComponentField
}