import { Meta } from "../../../types/metas"

export const hsReportSummariesMeta: Meta = {
  "description": {
    "path": "description",
    "dataType": "string",
    "label": "Motivos de pendência ou recusa da HS",
    "tableProps": {
      "isKey": true,
      "width": "9rem",
      "dataSort": true,
      rowSpan: "2"
    },
    "isMandatory": () => false
  },
  "suppliers": {
    "path": "suppliers",
    "tableProps": {
      hidden: false,
      "width": "3rem",
      "dataSort": true,
      "formatExtraData": "totalReason",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "------",
            dataAlign: "left",
            pathExtraData: false,
            "width": "3rem",
          },
          {
            row: '1',
            "label": "Count",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "3rem",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            "width": "3rem",
          }]
      },
    },
    "isMandatory": () => false
  },
  "totalReason": {
    "path": "totalReason",
    "dataType": "string",
    "label": "Total por documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      rowSpan: "2"
    },
    "isMandatory": () => false
  },
}

export const statusMeta: Meta = {
  "status": {
    "path": "status",
    "dataType": "string",
    "label": "Status",
    "tableProps": {
      "isKey": true,
     // hidden: true,
      "width": "6rem",
      "dataSort": true,
      'expandable': false,
      rowSpan: "3"
    },
    "isMandatory": () => false
  },
  "templete": {
    "path": "----",
    "label": "suppliers",
    "tableProps": {
      hidden: false,
      "dataSort": true,
      "spanColumns": {
        columns: [
          {
            "label": "Parceiro",
            dataAlign: "center",
            row: '0',
            colSpan: "12",
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Em análise",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Pendenciado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Recusado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Validado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Protocolado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Total",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "InAnalysis",
            hasDynamicPath: true,
            "width": "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "InAnalysis",
            formatExtraData: "totalStatus_InAnalysis",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "pending",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "pending",
            formatExtraData: "totalStatus_pending",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "rejected",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "rejected",
            formatExtraData: "totalStatus_rejected",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            label: "Count",
            row: "2",
            columnFormat: "amountColumn",
            dataAlign: "left",
            path: "validated",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            label: "%",
            row: "2",
            columnFormat: "pctColumn",
            dataAlign: "left",
            path: "validated",
            formatExtraData: "totalStatus_validated",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "Protocoled",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            label: "%",
            row: "2",
            columnFormat: "pctColumn",
            dataAlign: "left",
            path: "Protocoled",
            formatExtraData: "totalStatus_Protocoled",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            label: "Count",
            row: "2",
            columnFormat: "amountColumn",
            dataAlign: "left",
            path: "totalStatus",
            hasDynamicPath: true,
            width: "4rem",
            
          },
          {
            label: "%",
            row: "2",
            columnFormat: "pctColumn",
            dataAlign: "left",
            path: "totalStatus",
            formatExtraData: "totalStatus",
            hasDynamicPath: true,
            width: "4rem",
          }

        ]
      },
    },
    "isMandatory": () => false
  },
  "totalGlobalStatus": {
    "path": "----",
    "label": "suppliers",
    "tableProps": {
      hidden: true,
      "dataSort": true,
      "spanColumns": {
        columns: [
          {
            "label": "Total Global",
            dataAlign: "center",
            row: '0',
            colSpan: "10",
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Em análise",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Pendenciado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Recusado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Validado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Protocolado",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            "width": "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Total",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_InAnalysis",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_InAnalysis",
            formatExtraData: "totalGlobalStatus_InAnalysis",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_pending",
            hasDynamicPath: false,
            width: "4rem"
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_pending",
            formatExtraData: "totalGlobalStatus_pending",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_rejected",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_rejected",
            formatExtraData: "totalGlobalStatus_rejected",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_validated",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_validated",
            formatExtraData: "totalGlobalStatus_validated",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_Protocoled",
            hasDynamicPath: true,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalGlobalStatus_Protocoled",
            formatExtraData: "totalGlobalStatus_Protocoled",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalGlobalStatus",
            hasDynamicPath: true,
            width: "4rem",
            
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalGlobalStatus",
            formatExtraData: "totalGlobalStatus",
            hasDynamicPath: true,
            width: "4rem",
          }
        ]
      },
    },
    "isMandatory": () => false
  }
}
