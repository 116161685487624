import { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import api from '../../../../api/client-api';
import { HSReportsContainer } from '../../../../Containers/HSReports/index';
import { getValidatorsHSReports,  getAnalyticsHSReports } from '../../../../services/api/formalization';
import { buildPeriodFilters } from '../../../../components/table-standard';
import { FzToast } from '../../../fz/form/notification/toast';

const initialState = { 
  summaries: {
    reasons: [],
    status: [],
    periods: {},
    selectedPeriod: "",
  }, 
  analytics: []
}

function HSReports({ ns }) {

  const [typeView, setTypeView] = useState('');
  const [data, setData] = useState<any>(initialState);

  const [toastInfo, setToastInfo] = useState({
    status: false,
    headerMessage: 'Error',
    bodyMessage: 'Erro interno, tente novamente!',
    fzStyle: 'danger',
  });

  useEffect(() => {
    ns.saveChange(
      'from',
      moment().subtract(10, 'days').startOf('day').toISOString()
    );
    ns.saveChange('to', moment().endOf('day').toISOString());
  }, []);

  const handlePage = (pageType: string): void => {
    setTypeView(pageType);
  };

  const handleToast = (status: boolean, headerMessage = 'Error', bodyMessage = 'Erro interno, tente novamente!', fzStyle = 'danger') => {
    setToastInfo({
      ...toastInfo,
      status: status,
      headerMessage: headerMessage,
      bodyMessage: bodyMessage,
      fzStyle: fzStyle,
    });
  };

  async function getSummaraiesData(period: string) {
    try {
      const result = await getValidatorsHSReports(period);
      const periodsFiltered = buildPeriodFilters(result.data.statistics.status);
        
      const summaries = {
        reasons: result.data.statistics.reasons,
        status: result.data.statistics.status,
        periods: periodsFiltered,
      };
      setData({ ...data, summaries: { ...summaries, selectedPeriod: "" } });
    } catch (error) {
      handleToast(true);
      console.log(error);
    }
  }

  const handlePeriodSummary = (selectedPeriod, changePeriod = false) => {
    if(changePeriod) {
      setData({ 
        ...data, 
        summaries: initialState.summaries,
      });
    } else {
      setData({ 
        ...data, 
        summaries: { ...data.summaries, selectedPeriod }
      });
    }
  }

  async function getAnalyticsData(from: string, to: string) {
    try {
      
      const analyticsResult = await getAnalyticsHSReports(from, to);
      var analytics = Object.values(analyticsResult.data).filter(function(item){
        return (typeof item.created === "string" && typeof item.updated === "string");
      });
      setData({ ...data, analytics });
    } catch (error) {
      handleToast(true);
      console.log(error);
    }
  }

  const handleSearchReport = (filter) => {
    if (typeView === 'summaries') {
      getSummaraiesData(filter.period);
    } else {
      if (filter.from instanceof moment) {
        filter.from = filter.from.toISOString()
      }
      if (filter.to instanceof moment) {
        filter.to = filter.to.toISOString()
      }
      getAnalyticsData(filter.from, filter.to);
    }
  };

  const handleToastClose = () => setToastInfo({ ...toastInfo, status: false });
  const { role = 'no-role' } = api.getCurrentUser();
  const filters = ns.getShared("filters") || ns.getChanged();
  return (
    <>
      <HSReportsContainer
        ns={ns}
        handlePage={handlePage}
        typeView={typeView}
        handleSearchReport={handleSearchReport}
        data={data}
        role={role}
        filters={filters}
        handlePeriodSummary={handlePeriodSummary}
      />

      <FzToast
        fzStyle={toastInfo.fzStyle}
        close={handleToastClose}
        show={toastInfo.status}
        delay={5000}
        headerMessageTost={toastInfo.headerMessage}
        bodyMessageTost={toastInfo.bodyMessage}
        autoHide={true}
      />
    </>
  );
}

export { HSReports };
