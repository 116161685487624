import moment from 'moment'

export const formatPeriod = (period) => {
  if (!period) {
    return ""
  }
  if (/\d{4}-W\d*/.test(period)) {
    return "week "+moment(period).format("DD/MMM/YY")
  } else if (/\d{4}-\d{1,2}$/.test(period)) {
    return moment(period).format("MMM/YY")
  } else if (/\d{4}-\d{2}-\d{2}/.test(period)) {
    return moment(period).format("D/M, ddd")
  }
  return period
}
