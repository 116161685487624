import React from 'react';
import moment from 'moment-timezone';
import { FormattedNumber, FormattedRelative } from 'react-intl';
import filterBuilder from './filter-builder';
import { FzBadge } from '../layout';
import { RGDocumentType, IncomeProofDocumentType, statesDescriptions, getRGDocumentType, getIncomeProofDocument, getVehicleType, vehicleType } from '../../../business/application'
import { filters, booleanColumn_en, pctColumn, applicationIdColumn, applicationStateColumn, buildPeriodFilters, buildColumnValuesFilter, buildColumnApplicationState, buildSupplierFilters, buildEventNamesFilters, buildStatusDocumentsFilters, arrayJoinColumun, booleanColumn_approved_rejected } from '../../../components/table-standard';
import { getFormalizationStateDescription, statesFormalizationDescriptions } from '../../../business/formalization'
import { salesQueueDictionary } from "../../../crm/domains";
import { getDomain } from '../../../scripts/utils/cache-helpers'
import { formatPeriod, formatCurrency, formatPeriodForExport, formatPeriodWithTime, formatDateWithTime, formatDateWithTimeAndWeekDay, formatMillisecondsToDate } from "./data-formatter"
import get from 'lodash/get';

export const booleanColumn = (cell, row) => {
  if (typeof cell === "string") {
    cell = cell === "true"
  }
  return cell ? 'Sim' : 'Não';
};

export const fakeColumn = (cell, row) => {
  return <div className='hidden-fake-click'></div>
}

const appliedAmountColumn = (cell, row) => {
  return (
    <FormattedNumber
      value={
        row['appliedAmount'] === 0 && row['vehicleSecurity']
          ? row['vehicleSecurity']['value'] -
          row['vehicleSecurity']['downPayment']
          : row['appliedAmount']
      }
    />
  );
};

const cityColumn = (cell, row) => {
  if (row.city) {
    return row.city + '/' + row.state;
  }
  return '';
};

export const triggers_colors = {
  "__customer_booking": '#0EB546',
  "accepted_bid": '#07C1EA',
  "address": '#0EB546',
  "alternativeSupplierIdPaths": '#0EB546',
  "anexardocumentos": '#CC00B8',
  "applicationIdPath": '#CC00B8',
  "approve_documents": '#07C1EA',
  "bid_rejected": '#BD1616',
  "biometry": '#5308CD',
  "biometry_result": '#5308CD',
  "callbackanalisecadastral": '#C8670D',
  "callbackanalisedocumental": '#C8670D',
  "callbackformalize": '#C8670D',
  "callbackpreanalise": '#C8670D',
  "callbackpreapproval": '#C8670D',
  "ccb": '#CC00B8',
  "check_matchs": '#660066',
  "completed_application": '#0EB546',
  "customer_new_session": '#006400',
  "documents_received": '#CC00B8',
  "documents_uploaded": '#5308CD',
  "files_received": '#CC00B8',
  "fz_get_status": '#006400',
  "generate_ccb": '#CC00B8',
  "get_boleto": '#CC00B8',
  "get_status": '#288A10',
  "getcontractandboleto": '#288A10',
  "getlink": '#288A10',
  "getstatus": '#288A10',
  "hs_documents_result": '#5308CD',
  "hs_documents_sent": '#5308CD',
  "income": '#0EB546',
  "income60": '#0EB546',
  "income90": '#0EB546',
  "initial_info": '#006400',
  "manual": '#660066',
  "manualgetdocumentstatus": '#660066',
  "manualgetstatus": '#660066',
  "manualvalidatebankdata": '#660066',
  "none": '#000000',
  "parallel_getstatus": '#288A10',
  "parallel_preccb": '#CC00B8',
  "parallel_setsigned": '#07C1EA',
  "payment_queue": '#288A10',
  "pending": '#BAA70B',
  "populate_collections": '#0C6AE8',
  "post_agent": '#C8670D',
  "post_agent_aprovado": '#C8670D',
  "post_agent_callback": '#C8670D',
  "post_agent_contratado": '#C8670D',
  "post_agent_finalizar_proposta": '#C8670D',
  "post_agent_reprovado": '#BD1616',
  "post_agent_transaction": '#C8670D',
  "post_agent_update_status": '#C8670D',
  "post_agent_updatestatus": '#C8670D',
  "pre_accepted_bid": '#07C1EA',
  "reject_documents": '#BD1616',
  "renew_offers": '#BAA70B',
  "resend_contract": '#BAA70B',
  "rg-back": '#0EB546',
  "rg-front": '#0EB546',
  "selfie": '#0EB546',
  "send_ccb": '#CC00B8',
  "send_kit_probatorio": '#CC00B8',
  "sendsigndata": '#0EB546',
  "status_polling": '#0EB546',
  "supplierIdPath": '#0EB546',
  "update": '#660066',
  "update_status": '#0C6AE8',
  "updated_bid": '#0C6AE8',
  "updatedocumentstatus": '#0C6AE8',
  "validate_domains": '#0C6AE8',
  "verify_trigger": '#0C6AE8'
};

const queue_colors = (cell, row) => {
  return '<span style="color:' + triggers_colors[triggers_colors[row.sqsMessage.MessageTrigger] ? row.sqsMessage.MessageTrigger : "none"] + '; font-weight:bold">' + row.queue + '</span>';
};

const numberColumn = (cell, row, formatExtraData) => {
  return (<FormattedNumber value={cell} />);
}

const msColumn = (cell, row, formatExtraData) => {
  let n = Number(cell)
  if (n > 60000) {
    n = Math.floor(n / 1000)
    return Math.floor(n / 60) + "m " + ("00" + n % 60).slice(-2) + "s"
  }
  else if (n > 1000) {
    return Math.floor(n / 1000) + "s" + ("000" + n % 100).slice(-3)
  } else {
    return cell
  }
}

const memoryColumn = (cell, row, formatExtraData) => {
  let n = Number(cell)
  let suffix = ""
  // 1.392.869.376
  if (n > 1000000000) {
    n /= 100000000;
    n = Math.round(n)
    n /= 10
    suffix = "G"
  } else if (n > 1000000) {
    n /= 100000;
    n = Math.round(n)
    n /= 10
    suffix = "M"
  } else {
    suffix = '\xa0'; // nbsp
  }
  return (<React.Fragment><FormattedNumber value={n} />{suffix}</React.Fragment>);
}


const amountColumn = (cell, row, formatExtraData) => {
  return (<FormattedNumber value={cell || 0} maximumFractionDigits={0} />);
}




const normalizePercentageDataForPageCapacityReview = (cell, row, formatExtraData) => {

  if (!cell) {
    return null
  }
  return cell.replace("%0", "%")
}

const totalAmountColumn = (cell, row, formatExtraData) => {

  let sufix = ""
  let decimals = 0
  let val = Number(cell)
  const title = val.toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
  if (val > 1000000000) {
    decimals = 1
    sufix = " B"
    val /= 1000000000
  } else if (val > 1000000) {
    sufix = " M"
    decimals = 1
    val /= 1000000
  }
  return (<span title={title}><FormattedNumber value={val || 0} maximumFractionDigits={decimals} />{sufix}</span>);
}

const formatPaidAmount = (cell, row) => {
  if (cell) {
    return Math.floor(cell * 100) / 100
  }
}


export const dateColumn = (cell, row) => {
  return new Date(Date.parse(cell)).toLocaleDateString('pt-BR');
};

export const dateTimeColumn = (cell, row) => {
  if (cell && cell.substring(0, 10) !== '0001-01-01') {
    return moment(cell).format('DD/MM, HH:mm:ss');
  } else {
    return null;
  }
};

export const fullDateTimeColumn = (cell) => {
  if (cell && cell.substring(0, 10) !== '0001-01-01') {
    return moment(cell).format('DD/MM/YYYY - HH:mm:ss');
  }

  return null;
};

export const relativeTimeColumn = (cell, doExport) => {
  if (!cell || cell.substring(0, 4) === '0001') {
    return '';
  }

  if (doExport) {
    return cell;
  } else {
    return <FormattedRelative value={cell} />;
  }
};

const bidsWeightColumn = cell => {
  if (cell === null || cell === undefined || cell.bidsWeight === undefined) {
    return '';
  }
  return cell.bidsWeight;
};

export const bidsWeightSort = (a, b, order) => {
  // order is desc or asc
  if (!a.auction || !b.auction) {
    return 0;
  }
  if (order === 'desc') {
    return b.auction.bidsWeight - a.auction.bidsWeight;
  } else {
    return a.auction.bidsWeight - b.auction.bidsWeight;
  }
};

export const customerColumn = (cell, row) => {
  return (
    <span>
      <b>
        {row.firstName} {row.lastName}
      </b>
      <br />
      {row.personalNumber}
    </span>
  );
};

export const filterEvent = (data, metas) => {
  if (data.length === 0) {
    return {};
  }

  return data.reduce((resultSoFar, cur) => {
    resultSoFar[cur.event] = [cur.event];
    return resultSoFar;
  }, {});
};

const outcomeDescriptions = {
  'close-no-contact': 'Não consegui falar',
  'finish-contact': 'Atendido com sucesso',
  "started": 'Sem tratativa',
  "locked": 'Atendido pelo time',
  'empty-queue': 'Fila vazia',
};

const treatmentDescriptions = {
  'notified-sms': 'Enviado SMS',
  'notified-whatsapp': 'Enviado WhatsAPP',
  'notified-email': 'Enviado email',
  'requested-document': 'Pedido Docs',
  'send-preauth-link': 'Reenvio de Link',
  'resend-email': 'Reenvio de email',
  'notified-phone': 'Notificado por Telefone',
  'edit-email-telephone': 'Dados Alterados',
  "scheduled-return": "Postergado Atendimento",
  "close-application": "Proposta encerrada",
  "accept-bid": "Oferta Aceita",
  "delete-document": "Documento corrigido",
  "upload-document": "Documento enviado",
  "start-auction": "Cotação iniciada",
  "set-as-sent-2bank": "Docs direto com banco",
  "unset-as-sent-2bank": "Reenvio direto com banco",
  "querying-customer-data": "Consulta do Cadastro",
  "formalization-support": "Suporte na Formalização",
  "not-main-contact": "Não é CPC",
  "income-verification-divergence": "Comprovação e divergência de Renda",
  "customer-data-divergence": "Divergência dados do cadastro",
  "no-interest": "Sem interesse",
  "another-partner": "Fechou com outro parceiro",
  "partner-refused": "Recusa Parceiro",
  "loans-no-longer-necessary": "Conseguiu o dinheiro de outra forma",
  "made-with-competitor": "Fechou com concorrente",
  "vehicle-out-of-profile": "Veiculo fora do Perfil",
  "rate-installment-uninteresting": "Taxa e/ou parcela alta e/ou valor baixo",
  "loan-payment-method": "Forma de pagamento do empréstimo",
  "help-with-register-or-exclusion": "Ajuda com cadastro ou exclusão",
  "register-expired": "Cadastro expirado",
  "called-hd": "Chamado HD",
  "customer-evaluating-proposals": "Cliente analisando proposta",
  "customer-not-did-not-receive-an-offer": "Cliente não recebeu oferta",
  "give-up-borrowing": "Desistiu do empréstimo",
  "doubts-product": "Duvidas produtos",
  "doubts-about-fnz": "Duvidas sobre a FnZ",
  "formalization-ce": "Formalização - CE (Comprovante de Endereço)",
  "formalization-contract": "Formalização - Contrato",
  "formalization-cr": "Formalização - CR (Comprovante de Renda)",
  "formalization-differences-form": "Formalização - Divergências no formulário",
  "formalization-several-documents": "Formalização - Diversos documentos",
  "formalization-id": "Formalização - ID (Identificação pessoal)",
  "formalization-selfie": "Formalização - Selfie",
  "formalization-ted-returned": "Formalização - TED devolvida",
  "formalization-token": "Formalização - Tokenização",
  "fraud-or-scam": "Fraude ou golpe",
  "link-instabilities": "Instabilidades ligação",
  "proposal-canceled": "Proposta cancelada",
  "proposal-analysis": "Proposta em análise",
  "proposal-partially-rejected": "Proposta parcialmente recusada",
  "Complaint or Compliments": "Reclamação ou Elogios",
  "redirect-partner": "Redirecionado p/ parceiro",
};

const transactionDescriptions = {
  'VALIDATE_CUSTOMER': 'VALIDATE_CUSTOMER',
  'LEADS': 'LEADS',
  "APPLICATIONS": "APPLICATIONS"
};


export const buildFilterUtmCampaign = (data, metas) => {
  if (data.length === 0) {
    return {};
  }

  return data.reduce((resultSoFar, cur) => {
    return { ...resultSoFar, [cur.utm_campaign]: cur.utm_campaign };
  }, {});
};


export const buildFilterUtmContent = (data, metas) => {
  if (data.length === 0) {
    return {};
  }

  return data.reduce((resultSoFar, cur) => {
    return { ...resultSoFar, [cur.utm_content]: cur.utm_content };
  }, {});
};



export const buildFilterTransaction = (data, metas) => {
  if (data.length === 0) {
    return {};
  }

  return data.reduce((resultSoFar, cur) => {
    return { ...resultSoFar, [cur.transaction]: getTransaction(cur.transaction) };
  }, {});
};

export const getTransaction = transactionKey => {
  return transactionDescriptions[transactionKey] || 'n/a';
};


export const buildFilterOutcome = (data, metas) => {
  if (data.length === 0) {
    return {};
  }

  return data.reduce((resultSoFar, cur) => {
    return { ...resultSoFar, [cur.outcome]: getOutcome(cur.outcome) };
  }, {});
};

export const getOutcome = outcomeKey => {
  return outcomeDescriptions[outcomeKey] || 'n/a';
};

export const buildFilterAcceptedProducts = (data, metas) => {

  if (data.length === 0) {
    return {};
  }

  let pre_processing = data.map(it => {
    return it.acceptedProducts.reduce((acc, item) => ({
      ...acc, item
    }), [])
  })

  let objectFilter = [...new Set(pre_processing.map(it => it.item))].reduce((accumulador, item) => {
    const data = { ...accumulador, [item]: item }
    return data
  }, {})

  return objectFilter
}


export const getTreatments = treatmentsKey => {
  return treatmentDescriptions[treatmentsKey] || 'n/a';
};

const getDescriptionsByDomains = (cell, row, formatExtraData, idx, ns) => {
  return getDomain(ns, formatExtraData, cell) || 'n/a';
};

const getDescriptionsByDomainsOrKeepOriginalValue = (cell, row, formatExtraData, idx, ns) => {
  return getDomain(ns, formatExtraData, cell) || cell;
};

const buildFilterRuleMessages = data => {
  return filterBuilder.buildFilter(data);
};

export const bidsSummaryColumn = (cell, row) => {
  var suppliers = [];
  if (!cell) {
    return null;
  }
  cell.forEach((bid, k) => {
    suppliers.push(
      <FzBadge
        key={k}
        fzStyle={
          bid.rejected
            ? 'alert'
            : bid.accepted
              ? 'success'
              : bid.expired
                ? 'attention'
                : 'default'
        }
      >
        {bid.supplier.internalName}
      </FzBadge>
    );
  });
  return <React.Fragment>{suppliers}</React.Fragment>;
};


export const formatedAcceptedProducts = (cell, row) => {
  let list = [];
  if (!cell) {
    return null;
  }

  cell.forEach((name, k) => {
    list.push(
      <FzBadge
        key={k}
        fzStyle={'default'}
      >
        {name}
      </FzBadge>
    );
  });
  return <React.Fragment>{list}</React.Fragment>;
};



const salesLockColumn = (cell, row) => {
  if (!cell || cell.substring(0, 4) === '0001' || new Date(cell) < new Date()) {
    return '';
  }
  return relativeTimeColumn(cell, row);
};

export const getSalesQueue = queueKey => {
  return salesQueueDictionary[queueKey] || 'n/a';
};

export const getDescriptionPaymentMethod = (cell, row, _, idx, ns) => {
  let resulData
  if (cell) {
    resulData = getDomain(ns, "payment-method", cell)
  }
  return <div>{typeof resulData === "string" ? resulData : cell}</div>
}

export const formatMinutesSeconds = (s) => {
  const minutes = Math.floor((s / 60) % 60);
  const seconds = Math.floor(s % 60);
  const hours = Math.floor(s / 3600);

  if (hours === 0) {
    return minutes + "m " + seconds + "s"
  }
  return hours + "h " + minutes + "m " + seconds + "s"
}

export const filterRulesDocumentType = {
  "pend": "Pendenciado",
  "reject": "Rejeitado",
}



export const fzTableEditSelectValidator = (value) => {
  const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };
  if (!value || value === 'Selecione') {
    response.isValid = false;
    response.notification.type = 'error';
    response.notification.msg = 'Selecione uma opção.';
    response.notification.title = 'Requested Value';
  }

  return response;
}

export const fzTableEditTextValidator = (value) => {
  const response = { isValid: true, notification: { type: 'success', msg: '', title: '' } };
  if (!value) {
    response.isValid = false;
    response.notification.type = 'error';
    response.notification.msg = 'Insira um texto.';
    response.notification.title = 'Requested Value';
  }

  return response;
}

function applicationState(value) {

  return (
    <span>
      {statesDescriptions[value]}
    </span>
  );
}
function breakSpaces(value) {
  return (
    <span style={{ whiteSpace: "break-spaces" }}>
      {value}
    </span>
  );
}

const funnelDescriptions = {
  lead: 'Em cadastro',
  hasBids: 'Aguardando aceite',
  followUp: 'Em formalização',
  archived: 'Paga',
  closed: 'Encerrada',
  excluded: 'Excluída',
};



function formatHermesTicketsReport(value) {
  const statesDescriptions = {
    "cancelledByPartner": 'Cancelado no parceiro',
    "autoCare": 'Encerrado automáticamente',
    "finished": 'Encerrado',
    "expired": "Expirado",
    "no-contact": "Sem Contato"
  }

  const description = value ? statesDescriptions[value] : "Não iniciado"
  return (
    <span>
      {description}
    </span>
  );
}


export const getAttendanceTreatments = (cell, row, _, idx, ns) => {
  let resulData
  if (cell) {
    resulData = getDomain(ns, "attendance-treatments", cell)
  }
  return <div>{typeof resulData === "string" ? resulData : cell}</div>
}

export const notificationConcatWithPipe = (cell) => {
  let resulData
  if (cell.length > 0) {
    resulData = cell.join("|")
  }
  return <div>{typeof resulData === "string" ? resulData : cell}</div>
}

export const contractStatus = (cell) => {
  return <div>{cell === "inactive" ? "Inativo" : "Ativo"}</div>
}

export const buildContractStatusFilters = (data, path = "status") => {
  let result = {};
  for (var i = 0; i < data.length; ++i) {
    let s = get(data[i], path, "Ativo");
    if (s && !result[s]) {
      result[s] = s;
    }
  }
  return result;
};

export default {
  applicationState,
  breakSpaces,
  dateColumn,
  dateTimeColumn,
  fullDateTimeColumn,
  appliedAmountColumn,
  cityColumn,
  numberColumn,
  memoryColumn,
  bidsWeightColumn,
  applicationIdColumn,
  applicationStateColumn,
  periodColumn: value => formatPeriod(false, value),
  periodColumnTz: value => formatPeriod(true, value),
  periodWithTimeColumn: value => formatPeriodWithTime(false, value),
  periodWithTimeColumnTz: value => formatPeriodWithTime(true, value),
  formatDateWithTime,
  formatDateWithTimeAndWeekDay,
  formatMillisecondsToDate,
  getIncomeProofDocument,
  vehicleType,
  getVehicleType,
  getRGDocumentType,
  booleanColumn_en,
  amountColumn,
  msColumn,
  totalAmountColumn,
  formatPaidAmount,
  normalizePercentageDataForPageCapacityReview,
  pctColumn,
  customerColumn,
  relativeTimeColumn: value => relativeTimeColumn(value, false),
  relativeTimeColumnDoExport: value => relativeTimeColumn(value, true),
  filters,
  funnelDescriptions,
  RGDocumentType,
  IncomeProofDocumentType,
  statesDescriptions,
  statesFormalizationDescriptions,
  getFormalizationStateDescription,
  buildPeriodFilters,
  filterEvent,
  buildFilterOutcome,
  salesQueueDictionary,
  getOutcome,
  getTreatments,
  buildColumnValuesFilter,
  buildColumnApplicationState,
  formatCurrency,
  formatPeriodForExport,
  getDescriptionsByDomains,
  getDescriptionsByDomainsOrKeepOriginalValue,
  buildSupplierFilters,
  buildFilterRuleMessages,
  buildEventNamesFilters,
  salesLockColumn,
  bidsSummaryColumn,
  booleanColumn,
  fakeColumn,
  getSalesQueue,
  buildFilterTransaction,
  getTransaction,
  buildFilterUtmCampaign,
  buildFilterUtmContent,
  formatedAcceptedProducts,
  buildFilterAcceptedProducts,
  getDescriptionPaymentMethod,
  getAttendanceTreatments,
  notificationConcatWithPipe,
  formatMinutesSeconds,
  queue_colors,
  triggers_colors,
  buildStatusDocumentsFilters,
  filterRulesDocumentType,
  fzTableEditSelectValidator,
  fzTableEditTextValidator,
  arrayJoinColumun,
  formatHermesTicketsReport,
  contractStatus,
  buildContractStatusFilters,
  booleanColumn_approved_rejected,
};
