import { ItemListType, ReprocessingSearchMeta } from '../../biz/metadatas/bifrost/reprocessing-metadata'
import FieldConnected from '../../ui/components/form/field-connected'
import { FzButton } from '../../ui/fz/form'

export const ReprocessingFiltersContainer = ({ data, ns, suppliersList, usersList, handleSearch }) => {
  return (
    <div className={'fz-panel'}>
      <div className={'fz-panel-border-padding'}>
        <FieldConnected
          meta={ReprocessingSearchMeta.createdFrom}
          data={data}
          ns={ns}
        />
      </div>
      <div className={'fz-panel-border-padding'}>
        <FieldConnected
          meta={ReprocessingSearchMeta.createdTo}
          data={data}
          ns={ns}
        />
      </div>
      <div className={'fz-panel-border-padding'}>
        <FieldConnected
          meta={getFunctionMeta("supplierInternalName", suppliersList)}
          data={data}
          ns={ns}
        />
      </div>
      <div className={'fz-panel-border-padding'}>
        <FieldConnected
          meta={getFunctionMeta("userId", usersList)}
          data={data}
          ns={ns}
        />
      </div>
      <div className={'fz-panel-border-padding'}>
        <FieldConnected
          meta={ReprocessingSearchMeta.limit}
          data={data}
          ns={ns}
        />
      </div>
      <div className={'fz-panel-border-padding'}>
        <FzButton
          onClick={handleSearch}
          tip={'Buscar reprocessamentos'}
          className={null}
          block={true}
          fzStyle={'primary'}
        >
          Buscar
        </FzButton>
      </div>
    </div>
  )
}

function getFunctionMeta(type: string, metaData: ItemListType[]) {
  const searchMeta = ReprocessingSearchMeta[type]
  if (typeof searchMeta === "function") {
    return searchMeta(metaData)
  } else {
    return searchMeta;
  }
}