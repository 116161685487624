import React, { useEffect, useState } from 'react'
import { Navbar, NavItem } from 'react-bootstrap'
import { NavLink } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { FzGrid } from '../layout';
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
class FzNavbar extends React.Component {

  render() {
    return (
      <Navbar inverse staticTop >
        <FzGrid >
          <React.Fragment>
            {this.props.children}
          </React.Fragment>
        </FzGrid>
      </Navbar>
    )
  }
}
FzNavbar.Header = (props) => <Navbar.Header><React.Fragment>{props.children}</React.Fragment></Navbar.Header>
FzNavbar.Link = (props) => <NavLink className="navbar-brand" to={props.link} activeClassName="active"><React.Fragment>{props.children}</React.Fragment></NavLink>
FzNavbar.Text = (props) => <Navbar.Text pullRight={props.pullRight}><React.Fragment>{props.children}</React.Fragment></Navbar.Text>

FzNavbar.Link.propTypes = {
  link: PropTypes.string.isRequired,
}
FzNavbar.Text.propTypes = {
  pullRight: PropTypes.bool
}
FzNavbar.Text.defaultProps = {
  pullRight: false
}

class FzNavSelect extends React.Component {
  constructor(props) {
    super(props)
    this.handlePeriodSelect = this.handlePeriodSelect.bind(this)
  }

  static propTypes = {
    onChange: PropTypes.func,
    itens: PropTypes.object,
  };

  handlePeriodSelect = (eventKey) => {
    this.props.onChange(eventKey)
    if (this.props.extraChangeEffect) {
      this.props.extraChangeEffect(eventKey)
    }
  }

  render() {
    const { options, itens, pullRight, value, selected } = this.props
    let menu

    if (options === undefined) {
      menu = Object.keys(itens).map(key => {
        let description = itens[key].label
        return (
          <Nav.Item key={itens[key].path}>
            <NavLink eventKey={itens[key].path}> {description} </NavLink>
          </Nav.Item>
        )
      })
    } else {
      menu = options.map((key, index) => {
        let description = key.description
        return (
          <Nav.Item key={key.code}>
            <NavLink eventKey={key.code}> {description}</NavLink>
          </Nav.Item>
        )

      })
    }

    return (
      <Nav variant="pills" style={this.props.fzStyle} activeKey={value || selected} pullright={pullRight} onSelect={this.handlePeriodSelect}>
        {menu}
      </Nav>
    )
  }
}

class FzNavSelect2 extends React.Component {
  constructor(props) {
    super(props)
    this.handlePeriodSelect = this.handlePeriodSelect.bind(this)
  }

  static propTypes = {
    onChange: PropTypes.func,
    itens: PropTypes.object,
  };

  handlePeriodSelect = (eventKey) => {
    this.props.onChange(eventKey)
  }

  render() {
    const { options, itens, value, selected } = this.props
    let menu

    if (options === undefined) {
      menu = Object.keys(itens).map(key => {
        let description = itens[key]
        return <NavItem eventKey={key} key={key}> {description}</NavItem>
      })
    } else {

      menu = options.map(key => {
        let description = key.description
        return <NavItem eventKey={key.code} key={key.code}> {description}</NavItem>

      })
    }

    return (
      <Nav activeKey={value || selected} onSelect={this.handlePeriodSelect}>
        {menu}
      </Nav>
    )
  }
}

export function FzNavDropdown(props) {

  const [selectedCode, setSelectedCode] = useState(null)

  useEffect(() => {
    setSelectedCode(props.value)
  }, [props.value])

  function handleSelect(eventKey) {
    const selectedAffiliateItem = getItemFromCodeInState(eventKey);
    const selectedAffiliateCode = selectedAffiliateItem && selectedAffiliateItem.code ? selectedAffiliateItem.code : "";
    if (!selectedAffiliateCode) {
      console.error("Affiliate code is not defined.");
    }
    setSelectedCode(eventKey)
    props.onChange(selectedAffiliateCode)
  }

  function getItemFromCodeInState(eventKey) {
    const options = props.options;
    const foundItem = options.find(item => item.code === eventKey);
    return foundItem;
  }


  const { options } = props

  if (!options) {
    return
  }

  const navComponent = options
    .sort()
    .map((it, index) => {

      return (<NavDropdown.Item
        key={index}
        eventKey={it.code}
        title={it.code}
      >{it.description}
      </NavDropdown.Item>)
    });

  //const name = (this.state.selectedCode) ? this.state.selectedCode.description : "Selecione";
  let descFound = getItemFromCodeInState(selectedCode);

  let name = selectedCode && descFound && descFound.description ? descFound.description : "Selecione";

  return (
    <Nav onSelect={handleSelect} activeKey={selectedCode && selectedCode.code}>
      <NavDropdown title={name} id="fz-dropdown">
        {navComponent}
      </NavDropdown>
    </Nav>
  )
}

export { FzNavSelect, FzNavbar, FzNavSelect2 }
