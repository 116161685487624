import React, { Component } from 'react';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { addBid, rejectCustomer } from '../api/suppliers';
import { FzButton } from '../ui/fz/form';
import { FzCard, FzCol } from '../ui/fz/layout/index';
import { FzForm } from '../ui/fz/layout/index';

export class RejectCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = { rejectReason: "" }
    this.handleChange = this.handleChange.bind(this);
    this.rejectCustomer = this.rejectCustomer.bind(this);
    this.bidCompleted = this.bidCompleted.bind(this);
    this.bidError = this.bidError.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
  }
  handleChange(e) {
    this.setState({ rejectReason: e.target.value })
  }
  getValidationState() {
    if (this.state.rejectReason.length === 0) {
      return 'error';
    } else if (this.state.rejectReason.length < 3) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  bidCompleted(application) {
    this.props.confirmBid();
  }
  bidError(error) {
    alert("Sistema temporariamente indisponível")
    this.props.cancelEdit()
  }

  async rejectCustomer() {
    try {
      const rejectResult = await rejectCustomer(this.props.application.id, this.props.supplier.internalName, this.state.rejectReason)
      this.bidCompleted(rejectResult)
    } catch (err) {
      this.bidError(err)
    }
  }
  render() {
    const header = <h3>Rejeitar Cliente</h3>
    return (
      <FzCard bsStyle="warning">
        <FzCard.Heading>
          <FzCard.Title>{header}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <Form horizontal>
            <FormGroup validationState={this.getValidationState()}>
              <FzCol componentClass={FzForm.Label} span={3}>
                Motivo da Rejeição
                            </FzCol>
              <FzCol span={9}>
                <FzForm.Control
                  as="textarea"
                  name="rejectReason"
                  placeholder="Informe o motivo de não fazer uma oferta"
                  value={this.state.rejectReason}
                  onChange={this.handleChange}>
                </FzForm.Control>
              </FzCol>
            </FormGroup>
            <FormGroup>
              <Button onClick={this.props.cancelEdit}>Voltar</Button>
              <Button variant="warning" onClick={this.rejectCustomer} disabled={this.getValidationState() !== "success"} >Rejeitar Cliente</Button>
            </FormGroup>
          </Form>
        </FzCard.Body>
      </FzCard>
    );
  }
}

export const ShowBid = ({ ns, persistChanges, bid, application, ...props }) => {
  if (bid.rejected) {
    return (
      <FzCard bsStyle="danger">
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Pedido rejeitado</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzForm.Static type="text">
            <FormattedDate value={bid.created} />
          </FzForm.Static>
          <FzForm.Static type="text">
            {bid.reason}
          </FzForm.Static>
        </FzCard.Body>
      </FzCard>
    )
  } else {
    return (
      <FzCard bsStyle="success">
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Detalhes da Oferta Realizada</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <Form horizontal>
            <FormGroup>
              <FzCol componentClass={FzForm.Label} span={6}>
                Valor ofertado
                            </FzCol>
              <FzCol span={6}>
                <FzForm.Static type="text">
                  <FormattedNumber value={bid.approvedAmount} currency="BRL" />
                </FzForm.Static>
              </FzCol>
              <FzCol componentClass={FzForm.Label} span={6}>
                Numero de Parcelas
                            </FzCol>
              <FzCol span={6}>
                <FzForm.Static type="text">
                  <FormattedNumber value={bid.approvedPeriodMonths} />
                </FzForm.Static>
              </FzCol>
              <FzCol componentClass={FzForm.Label} span={6}>
                Parcela Mensal
                            </FzCol>
              <FzCol span={6}>
                <FzForm.Static type="text">
                  <FormattedNumber value={bid.monthlyInstallment} currencty="BRL" />
                </FzForm.Static>
              </FzCol>
              {application.product === "HE" ? (
                <FzCol span={6}><FzForm.Static type="text"><FormattedNumber value={bid.firstInstallment} currencty="BRL" /></FzForm.Static></FzCol>
              ) : (
                  <FzCol></FzCol>
                )}

              {application.product === "HE" ? (
                <FzCol span={6}><FzForm.Static type="text"><FormattedNumber value={bid.lastInstallment} currencty="BRL" /></FzForm.Static></FzCol>
              ) : (
                  <FzCol></FzCol>
                )}

              {application.product === "HE" ? (
                <FzCol span={6}><FzForm.Static type="text"><FormattedNumber value={bid.totalValueInstallments} currencty="BRL" /></FzForm.Static></FzCol>
              ) : (
                  <FzCol></FzCol>
                )}
              <FzCol componentClass={FzForm.Label} span={6}>
                Taxa de Juros Mensal
                            </FzCol>
              <FzCol span={6}>
                <FzForm.Static type="text">
                  <FormattedNumber minimumFractionDigits="2" maximumFractionDigits="2" value={bid.interestRate * 100} />
                  <span> %</span>
                </FzForm.Static>
              </FzCol>
              <FzCol componentClass={FzForm.Label} span={6}>
                Taxa de anual - C.E.T.
                            </FzCol>
              <FzCol span={6}>
                <FzForm.Static type="text">
                  <FormattedNumber minimumFractionDigits="2" maximumFractionDigits="2" value={bid.totalAnnualInterestRate * 100} />
                  <span> %</span>
                </FzForm.Static>
              </FzCol>
            </FormGroup>
          </Form>
        </FzCard.Body>
      </FzCard>
    );
  }

}

export class PlaceBid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvedAmount: this.props.application.appliedAmount,
      approvedPeriodMonths: this.props.application.loanPeriodInMonths,
      monthlyInstallment: "",
      interestRate: "",
      totalAnnualInterestRate: ""
    }
    this.handleApprovedAmount = this.handleApprovedAmount.bind(this);
    this.handleApprovedPeriodMonths = this.handleApprovedPeriodMonths.bind(this);
    this.handleMonthlyInstallment = this.handleMonthlyInstallment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInterestRate = this.handleInterestRate.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.handleDescription = this.handleDescription.bind(this);

    this.placeBid = this.placeBid.bind(this);
    this.bidCompleted = this.bidCompleted.bind(this);
    this.bidError = this.bidError.bind(this);
  }

  bidCompleted(application) {
    this.props.confirmBid();
  }
  bidError(error) {
    alert("Sistema temporariamente indisponível")
    this.props.cancelEdit()
  }
  placeBid() {
    var bid = {
      approvedAmount: this.state.approvedAmount,
      approvedPeriodMonths: this.state.approvedPeriodMonths,
      monthlyInstallment: this.state.monthlyInstallment,
      supplierId: this.props.supplier.id,
      rejected: false,
      description: this.state.description
    }
    if (this.state.interestRate) {
      bid.interestRate = this.state.interestRate
    }
    if (this.state.totalAnnualInterestRate) {
      bid.totalAnnualInterestRate = this.state.totalAnnualInterestRate
    }

    addBid(this.props.application.id, bid)
    .then(this.bidCompleted )
    .catch(this.bidError) 
  }

  handleDescription(e) {
    this.setState({ description: String(e.target.value) });
  }
  handleApprovedAmount(e) {
    this.setState({ approvedAmount: Number(e.target.value) });
  }
  handleApprovedPeriodMonths(e) {
    this.setState({ approvedPeriodMonths: Number(e.target.value) });
  }
  handleMonthlyInstallment(e) {
    this.setState({ monthlyInstallment: Number(e.target.value) });
  }
  handleChange(e) {
    var value = {}
    value[e.target.name] = Number(e.target.value);
    this.setState(value);
  }
  handleInterestRate(e) {
    var value = {}
    value[e.target.name] = Number(e.target.value) / 100;
    e.target.value = +Number(e.target.value).toFixed(4);
    this.setState(value);
  }

  getValidationState() {
    if (this.state.approvedAmount <= 0) {
      return 'error';
    }
    if (this.state.approvedPeriodMonths <= 3) {
      return 'error';
    }
    if (this.state.monthlyInstallment <= 0) {
      return 'error';
    }
    if (this.state.monthlyInstallment < (this.state.approvedAmount / this.state.approvedPeriodMonths)) {
      return 'warning';
    }
    return 'success';
  }

  render() {
    return (
      <FzCard bsStyle="success">
        <FzCard.Heading>
          <FzCard.Title><strong>Fazer uma Oferta</strong></FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FormGroup validationState={this.getValidationState()}>
            <FzForm.Label>Valor ofertado</FzForm.Label>
            <FzForm.Control
              type="number"
              name="approvedAmount"
              placeholder="Informe o valor aprovado"
              value={this.state.approvedAmount}
              onChange={this.handleChange}>
            </FzForm.Control>
            <FzForm.Label>Número de Parcelas</FzForm.Label>
            <FzForm.Control
              type="number"
              name="approvedPeriodMonths"
              placeholder="Informe o período aprovado"
              value={this.state.approvedPeriodMonths}
              onChange={this.handleChange}>
            </FzForm.Control>
            <FzForm.Label>Parcelas Mensal</FzForm.Label>
            <FzForm.Control
              type="number"
              name="monthlyInstallment"
              placeholder="Informe a prestação total"
              value={this.state.monthlyInstallment}
              onChange={this.handleChange}>
            </FzForm.Control>
          </FormGroup>
           <FormGroup>
            <FzForm.Label>Taxa de Juros Mensal</FzForm.Label>
            <InputGroup>
              <FzForm.Control
                type="number"
                name="interestRate"
                placeholder="Taxa de juros nominal"
                defaultValue={this.state.interestRate * 100}
                onBlur={this.handleInterestRate}>
              </FzForm.Control>
              {/* <InputGroup.Addon>%</InputGroup.Addon> */}
            </InputGroup>
            <FzForm.Label>Taxa efetiva anual - C.E.T.</FzForm.Label>
            <InputGroup>
              <FzForm.Control
                type="number"
                name="totalAnnualInterestRate"
                placeholder="Taxa efetiva"
                defaultValue={this.state.totalAnnualInterestRate * 100}
                onBlur={this.handleInterestRate}>
              </FzForm.Control>
              {/* <InputGroup.Addon>%</InputGroup.Addon> */}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <FzForm.Label>Observação</FzForm.Label>
            <FzForm.Control
              type="string"
              name="description"
              placeholder="Observação"
              onBlur={this.handleDescription}>
            </FzForm.Control>
          </FormGroup>
          <FormGroup>
            <FzButton onClick={this.props.cancelEdit}>Voltar</FzButton>
            <FzButton fzStyle="regular"
              onClick={this.placeBid}
              disabled={this.getValidationState() !== "success"}>Enviar Oferta</FzButton> 
          </FormGroup>
        </FzCard.Body>
      </FzCard>
    );

  }
}