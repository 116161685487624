import moment from 'moment-timezone';


// General constants to communicate with server API

export const menuProductNick = {
  Refin: "Refin",
  CP: "CP",
  HE: "HE",
}

export const CampaignsModals = {
  handleSaveTargetException: {
    active: `Deseja ativar o filtro selecionado?\n\nOK para confirmar ou Cancel para cancelar a operação.`,
    activeSuccess: `Filtro ativado com sucesso!`,
    update: `Deseja atualizar o filtro selecionado?\n\nOK para confirmar ou Cancel para cancelar a operação.`,
    updateSuccess: `Filtro atualizado com sucesso!`,
    save: `Deseja criar um novo filtro com os parâmetros selecionados?\n\nOK para confirmar ou Cancel para cancelar a operação.`,
    saveSuccess: `Filtro criado com sucesso!`,
    error: `Erro ao salvar o filtro!\n\n`,
    errorTarget: `Para salvar um template de filtro, é necessário um nome e pelo menos um target especificado`,
    errorDuplicate: `Já existe um filtro com este nome`,
    cancel: 'Operação cancelada',
  },
  handleDeleteTargetException: {
    question: `Deseja desativar o filtro selecionado?`,
    questionSuccess: 'Sucesso!\n\nO filtro foi atualizado e desativado com sucesso!',
    error: 'Erro ao desativar o filtro selecionado!',
    cancel: 'Operação cancelada',
  },
  handleCreateCampaign: {
    question: `Deseja enviar sua campanha com os filtros selecionados?\n\nOK para confirmar ou Cancel para cancelar a operação.`,
    success: `Sucesso!\n\n`,
    error: `Erro ao tentar criar a campanha por rules!\n\nTalvez o intervalo de envio de campanhas ainda esteja vigente.`,
    cancel: 'Operação cancelada.',
  },
};


// Router

var today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
const beforeLastMonth = new Date(today.getFullYear(), today.getMonth() - 2, 1)

export const PERIOD_DAY_WEEK_MONTH = { day: 'Daily', week: 'Weekly', month: 'Month' };

export const PERIOD_MONTHS = [
  { path: moment(currentMonth).format("MMMM"), label: moment(currentMonth).format("MMMM"), title: undefined },
  { path: moment(lastMonth).format("MMMM"), label: moment(lastMonth).format("MMMM"), title: undefined },
  { path: moment(beforeLastMonth).format("MMMM"), label: moment(beforeLastMonth).format("MMMM"), title: undefined },
]