import { Meta } from '../../types/metas';

export interface QueueSummaryResponse {
  period: string;
  queue: {
    id: string;
    name: string;
  };
  tickets: {
    open: number;
    closed: number;
  };
  consultants: {
    list: {
      id: string;
      name: string;
    };
    total: number;
  };
}

export const HermesQueueSummaryResponse: Meta = {
  queueName: {
    path: 'queue.name',
    label: 'Nome da fila',
    tableProps: {
      isKey: true,
      hidden: false,
      width: '2rem',
      dataSort: true,
      expandable: false,
      filter: { type: 'TextFilter', delay: 500 },
    },
    isMandatory: () => false,
  },
  openTickets: {
    path: 'tickets.open',
    label: 'Quant. Tickets Abertos',
    tableProps: {
      hidden: false,
      width: '2rem',
      dataSort: true,
      expandable: false,
      filter: { type: 'NumberFilter', delay: 500 },
    },
    isMandatory: () => false,
  },
  closedTickets: {
    path: 'tickets.closed',
    label: 'Quant. Tickets Fechados',
    tableProps: {
      hidden: false,
      width: '2rem',
      dataSort: true,
      expandable: false,
      filter: { type: 'NumberFilter', delay: 500 },
    },
    isMandatory: () => false,
  },
  consultantAvailable: {
    path: 'consultants.total',
    label: 'Quant. de Consultores Vinculados',
    tableProps: {
      hidden: false,
      width: '2rem',
      dataSort: true,
      expandable: false,
      filter: { type: 'NumberFilter', delay: 500 },
    },
    isMandatory: () => false,
  },
};
