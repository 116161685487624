import { Link } from 'react-router-dom'
import FieldConnected from '../../../ui/components/form/field-connected'
import { FzButton } from '../../../ui/fz/form'
import { FzTable } from '../../../ui/fz/grid/table'
import { analyticsFilter, analyticsColumns, testeColumns } from './metadata'
import { FzIcons } from '../../../ui/fz/form/icon'
import { FzAccordion } from '../../../ui/fz/layout/FzAccordion'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzCol, FzRow } from '../../../ui/fz/layout'
import { formatMinutesSeconds } from '../../../ui/fz/formatter/column-formatter'


export const _getExtraData = (formatExtraData, param, acceptString, defaultValue) => {
  if (
    typeof formatExtraData === 'object' &&
    typeof formatExtraData[param] !== 'undefined'
  ) {
    return formatExtraData[param];
  }
  if (acceptString && typeof formatExtraData === 'string') {
    return formatExtraData;
  }
  return defaultValue;
};


function expandComponent(row, ns) {

  const passages = row.passages || []

  const components = passages.map((passage, index) => {
    if (!passage) {
      return <div>Erro ao apresentar as passagens</div>
    }

    return (
      <div key={index}>
        <FzCard>
          <FzAccordion>
            <FzAccordion.Toggle eventKey={"1"} FzElementType='h6' fzStyle={{ height: "2rem" }} >
              <FzCard.Header>
                <FzRow>
                  <FzCol>
                    {
                      `Passagem ${index}  -  
                Entrada em 
                  ${new Intl.DateTimeFormat('pt-BR', { month: 'numeric', day: 'numeric' }).format(new Date(passage.created))}
                  ${new Intl.DateTimeFormat('pt-BR', { weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(passage.created))}
                 -
                 Saida em 
                  ${new Intl.DateTimeFormat('pt-BR', { month: 'numeric', day: 'numeric' }).format(new Date(passage.updated))}
                  ${new Intl.DateTimeFormat('pt-BR', { weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(passage.updated))}
                `
                    }

                  </FzCol>
                  <FzCol>
                    {`Tempo de validação da HS - ${formatMinutesSeconds(passage.timeSpanInMiliseconds)}`}

                  </FzCol>
                </FzRow>
              </FzCard.Header>
            </FzAccordion.Toggle>
            <FzAccordion.Collapse eventKey={"1"}>
              <div style={{ marginTop: "1.7rem" }}>
                <FzTable
                  ns={ns}
                  metas={testeColumns}
                  data={passage.documents || {}}
                  exportDownload={false}
                  clipboard={false}
                />
              </div>
            </FzAccordion.Collapse>
          </FzAccordion>
        </FzCard>

      </div>

    )
  })

  return (
    <>
      {components}
    </>
  )
}

const Analytics = ({
  ns,
  data,
  handleSearchReport,
  role,
  filters,
}) => {

  function idColumn(cell, row, formatExtraData, idx, ns) {
    let applicationId = cell;
    let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
    url += applicationId;
    let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a>
    ) : null;
    return (
      <div className='d-flex justify-content-center align-items-center'>
        {newTab}&nbsp;
        <Link to={url} title={cell}>
          {'...' + cell.slice(-6)}
        </Link>
      </div>
    );
  }

  return (
    <>
      <div className={"fz-panel"}>
        <div className={"fz-panel-border-padding"} style={{ display: "block", borderBottom: "3px solid #dedede" }}>
          <div style={{ display: "flex" }}>
            <FieldConnected meta={analyticsFilter.from} data={filters} ns={ns} />
            <FieldConnected meta={analyticsFilter.to} data={filters} ns={ns} />
          </div>
        </div>
        <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: "15px 0px 0 0" }}>
              <FzButton
                fzStyle="discrete"
                onClick={() => {
                  const from = ns.getChanged("from")
                  const to = ns.getChanged("to")

                  handleSearchReport({ from, to })
                }}
                className={"btn btn-success"}
              >
                Buscar
              </FzButton>
            </div>
          </div>
        </div>
      </div>

      <FzCard.Body>
        <FzTable
          ns={ns}
          metas={analyticsColumns}
          data={data}
          exportDownload={role === "admin"}
          customColumnFormatters={{
            applicationId: (cell, row, formatExtraData, idx, ns) => idColumn(cell, row, formatExtraData, idx, ns),
          }}
          rowExpand={{
            expandComponent: (row) => expandComponent(row, ns),
          }}
        /> 
      </FzCard.Body>
    </>
  )
}

export { Analytics }