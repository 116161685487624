
import { currentApi } from "../../../api/axios-client-api";
import { InputLayoutResponse } from './types';

const api = currentApi

export const createInputLayout = (values) => {
  const serviceUrl = 'input-layouts';
  return api.Post(serviceUrl, values);
}


export const PutInputLayout = async (id: string, data: InputLayoutResponse): Promise<any> => {
  const serviceUrl = `input-layouts/${id}`;
  return api.Put(serviceUrl, data);
}

export const DeleteInputLayout = async (id: string): Promise<any> => {
  const serviceUrl = `input-layouts/${id}`;
  return api.Delete(serviceUrl);
}

export const GetAllInputLayout = async (): Promise<any> => {
  const serviceUrl = 'input-layouts';
  return api.Get(serviceUrl);
}

export const GetIdInputLayout = async (id: string): Promise<any> => {
  const serviceUrl = `input-layouts/${id}`;
  return api.Get(serviceUrl);
}
