import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clone from "lodash/cloneDeep"
import { Button } from 'react-bootstrap';
import { Namespace } from '../../../scripts/state/redux_ns';
import { UserDetails } from './user-detail';
import { FzTable } from '../../fz/grid/table';
import { users } from '../../../biz/metadatas/users'
import { getSuppliers } from '../../../scripts/utils/cache-helpers';
import { getAllProducts } from '../../../api/applications';

class UsersTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      suppliers: [],
      products: []
    }

    this.expandComponent = this.expandComponent.bind(this)
    this.navigateToUser = this.navigateToUser.bind(this)
  }

  async componentDidMount() {
    const suppliersFromCache = await getSuppliers(this.props.ns)
    const suppliers = Object.keys(suppliersFromCache || {}).map(key => suppliersFromCache[key])

    const productsResponse = await getAllProducts();
    const products = productsResponse.map(it => ({
      code: it.internalName,
      description: it.description
    }))

    this.setState({suppliers, products})
  }

  navigateToUser(user) {
    const { ns, history } = this.props
    let newPath = ns.namespace + "/" + user.id
    let newNs = ns.copy(newPath)
    newNs.set("user", user)
    history.push(newPath)
    ns.unset("users")
  }

  idColumn(cell, row) {
    return (
      <Button bsSize="xsmall" onClick={() => this.navigateToUser(row)}>Ver</Button>
    )
  }


  expandComponent(user, ns) {
    return (
      <UserDetails 
        suppliers={this.state.suppliers}
        products={this.state.products}
        data={user}
        ns={ns}
        metas={clone(users)} />
    )
  }

  render() {
    const { ns, data } = this.props
    const cache = ns.getShared('CACHE', {})
    return (
      <>
        {
          cache.suppliers !== undefined ? (
            <FzTable
              data={data || []}
              metas={users}
              customColumnFormatters={{
                id: (cell, row) => this.idColumn(cell, row)
              }}
              rowExpand={{
                expandComponent: (row) => this.expandComponent(row, ns),
                expandableRow: () => true,
              }}
              visibleColumns={["id", "created", "name", "email", "telephoneNumber", "group", "products"]}
              exportDownload={false}
              clipboard={false}
            />) : null
        }
      </>
    )
  }

}

UsersTable.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.string.isRequired,
};

export default UsersTable;