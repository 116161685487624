import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'

import api from '../../../api/client-api';
import { getAttachmentUrl } from '../../../api/applications';
import { formalizationState, setFormalizationStateSent } from '../../../api/formalization';
import { formalizationStateGlyph } from '../../../components/glyph';
import { FzCard, FzBadge } from '../../fz/layout/index';
import { FormalizationDocumentsList } from '../formalization/formalization-documents-list'
import { FzIcons } from '../../fz/form/icon';
import { getAllSuppliers } from '../../../api/suppliers';

const FORMALIZATION_ACTION_ASK_DOC = 'requested-document';


export const Formalizations = ({
  formalization,
  application,
  bid,
  ns,
  isAdmin,
  sharedNS,
  allSuppliers,
  documentDomains
 }) => {

  if (!formalization || !formalization.Documents || !application) {
    return null
  }

  const supplierIN = (bid && bid.supplier) ? bid.supplier.internalName : null

  const [suppliers, setSuppliers] = useState([])
  const [formalizationInProgressButtonsMark, setFormalizationInProgressButtonsMark] = useState(false)
  const [formalizationInProgressButtonsAsk4Doc, setFormalizationInProgressButtonsAsk4Doc] = useState()

  useEffect(() => {
    getSuppliersData()
  }, [])

  async function getSuppliersData() {
    const suppliersData = await getAllSuppliers()
    setSuppliers(suppliersData)
  }

  useEffect(() => {
    try {
      const supplier = supplierIN ? suppliers[supplierIN] : null
      const formalizationInProgress = (
        [32, 32768].indexOf(formalization.formalizationState) === -1 
        && [16, 128, 28672].indexOf(application.applicationState) === -1
      );
  
      if (
        formalizationInProgress 
        && supplier?.formalization.enableDeleteSentDocuments === true 
        && formalization.documents 
        && !formalization.documents.selfie
      ) {
        setFormalizationInProgressButtonsMark(
          <Button 
            key={bid.id}
            bsSize="xsmall"
            onClick={() => fnSetStateSent(application, bid, ns)}
          >
            <FzIcons icon={"repeat"} fzStyle={{ width: "15px", height: "15px" }} /> 
            Marcar/Desmarcar como Enviado para banco
          </Button>)
      }
  
      if (formalizationInProgress && api.isUserInRole("advisor")) {
        setFormalizationInProgressButtonsAsk4Doc(
          <Button 
            bsSize="xsmall" 
            onClick={() => fnPedidoDocumento(application, bid, ns)}>
              Pedido Documento
          </Button>)
      }
    } catch (error) {
      console.error('Formalizations::useState', { error })
    }
  }, [suppliers])

  const fnPedidoDocumento = (application, bid, ns) => {
    var applicationId = application.id;
    
    let treatment = ns.get("crm.treatments." + applicationId) ? ns.get("crm.treatments." + applicationId) + " * Documento Pedido" : "Documento Pedido";
    let treatmentArray = ns.get("crm.treatmentsArray." + applicationId) ? ns.get("crm.treatmentsArray." + applicationId) : [];
    
    treatmentArray.push(FORMALIZATION_ACTION_ASK_DOC);
    
    ns.set("crm.treatments." + applicationId, treatment);
    ns.set("crm.treatmentsArray." + applicationId, treatmentArray);
  }

  const fnSetStateSent = (application, bid, ns) => {
    setFormalizationStateSent(application.id, bid.id)
      .then((resp) => {
        formalization.formalizationState = formalization.formalizationState === 4;
        ns.set("application", application)

        var applicationId = application.id;
        var commentFormalization = formalization.formalizationState === 4 ? "Marcado enviado p/ banco" : "Desmarcado enviado p/ banco";
        var formalizationMarkSentInfo = formalization.formalizationState === 4 ? "set-as-sent-2bank" : "unset-as-sent-2bank";
        let treatment = ns.get("crm.treatments." + applicationId) ? ns.get("crm.treatments." + applicationId) + " * " + commentFormalization : commentFormalization;
        let treatmentArray = ns.get("crm.treatmentsArray." + applicationId) ? ns.get("crm.treatmentsArray." + applicationId) : [];
        treatmentArray.push(formalizationMarkSentInfo);

        ns.set("crm.treatments." + applicationId, treatment);
        ns.set("crm.treatmentsArray." + applicationId, treatmentArray);
      })
      .catch((error) => { console.warn("Error in setFormalizationStateSent", error) })
  }

  const attachmentsLines = () => {
    let docs = []
    for (let i = 0; i < application.attachedDocuments.length; ++i) {
      let doc = application.attachedDocuments[i]
      docs.push(
        <tr key={i}>
          <td>{doc.documentType}</td>
          <td>{doc.name}</td>
          <td><a href={getAttachmentUrl(application.id, doc.attachmentId, "pdf")} download={doc.name}> <Button bsSize="xsmall"> < FzIcons icon={"download"} fzStyle={{ width: "15px", height: "15px" }} /> Baixar </Button> </a>
          </td>
        </tr>
      )
    }
    return docs
  }

  let anexesTable
  if (application && application.attachedDocuments.length > 0 && formalization.formalizationState === 32) {
    anexesTable =
      <div>
        <h4>Anexos</h4>
        <Table responsive>
          <thead>
            <tr>
              <th>Documento</th>
              <th>Nome</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {attachmentsLines()}
          </tbody>
        </Table>
      </div>
  }

  return suppliers ? (
    <FzCard bsStyle="info" >
      <FzCard.Heading>
        <FzCard.Title componentClass="h3">
          Formalização&nbsp; 
          <FzBadge>
            {formalizationStateGlyph(formalization.formalizationState)} {formalizationState(formalization.formalizationState)}
          </FzBadge> &nbsp;&nbsp; 
          {[8, 32, 32768].indexOf(formalization.formalizationState) === -1 && formalization.signature ? <FzBadge>Pré-assinada</FzBadge> : null}
          {formalizationInProgressButtonsMark}&nbsp;&nbsp;{formalizationInProgressButtonsAsk4Doc}
        </FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <FormalizationDocumentsList
          formalization={formalization}
          ns={ns}
          api={api}
          bidId={bid.id}
          applicationId={application.id}
          supplierIN={supplierIN}
          readOnly={!isAdmin}
          sharedNS={sharedNS}
          allSuppliers={allSuppliers}
          documentDomains={documentDomains}
        />
        {anexesTable}
      </FzCard.Body>
    </FzCard>
  ) : <></>
}
