import moment from 'moment';

export const APPLICATION_STATE = {
  Init: 1,
  Lead: 2,
  Started: 4,
  HasBids: 8,
  Closed: 16,
  Accept: 32,
  FollowUp: 64,
  Paid: 128,
  Excluded: 28672,
};

export const productsList =
  [
    { nick: 'CP', name: 'Crédito Pessoal' },
    { nick: 'Refin', name: 'Refinanciamento de Veículo' },
    { nick: 'Fin', name: 'Financiamento de Veículo' },
    { nick: 'HE', name: 'Refinanciamento de Imóvel (HE)' },
  ];

export const getCurrentBid = (application, supplierID) => {
  if (application.auction) {
    for (let i = 0; i < application.auction.bids.length; ++i) {
      let bid = application.auction.bids[i];
      if (bid.supplierId === supplierID) {
        return bid;
      }
    }
  }
  return null;
};

export const RGDocumentType = {
  'rg': 'RG',
  'driver': 'CNH',
  'professionalcouncil': 'Carteira de Conselho Profissional',
  'rne': 'RNE',
  'passport': 'Passaporte',
  'id': 'Outro documento de identidade',
};

export const IncomeProofDocumentType = {
  'statement': 'Extrato Bancário',
  'hol': 'Holerite',
  'income-tax': 'Declaração de Imposto de Renda',
  'previdence': 'Extrato do INSS',
  'prolabore': 'Pró-labore',
  'assumed': 'Não tenho como comprovar renda',
};

export const vehicleType = {
  'car': 'Carro',
  'truck': 'Caminhão',
  'motorcycle': 'Moto',
}

export const statesDescriptions = {
  1: 'Em cadastro',
  2: 'Cadastrado',
  4: 'Em análise',
  8: 'Proposta enviada',
  16: 'Encerrado',
  32: 'Proposta aceita',
  64: 'Follow-up',
  128: 'Paga',
  0x7000: 'Excluída',
};

export const statesDescriptions_en = {
  1: 'Filled',
  2: 'Lead',
  4: 'Started',
  8: 'Has Bids',
  16: 'Closed',
  32: 'Bid Accepted',
  64: 'Follow-up',
  128: 'Paid',
};

export const getAppStateDescription = (state) => {
  return statesDescriptions[state] || 'n/a';
};

export const getApplicationStates = () => {
  return statesDescriptions;
};

export const getApplicationState = (_applicationState) => {
  return statesDescriptions[_applicationState] || 'n/a';
};

export const getRGDocumentType = (_RGDocumentType) => {
  return RGDocumentType[_RGDocumentType] || 'n/a';
};

export const getIncomeProofDocument = (_incomeProofDocument) => {
  return IncomeProofDocumentType[_incomeProofDocument] || 'n/a';
};

export const getVehicleType = (_vehicleType) => {
  return vehicleType[_vehicleType] || 'n/a';
};

export const normalizeCPF = (cpf) => {
  if (!cpf) {
    return cpf;
  }
  cpf = cpf.replace(/\D/g, '');
  if (cpf && cpf.length > 0) {
    cpf = ('00000000000' + cpf).slice(-11);
  }
  return cpf;
};

export const normalizeEmail = (email) => {
  if (!email) {
    return email;
  }
  email = email.trim();
  return email;
};

export const normalizePhone = (cel) => {
  if (!cel) {
    return cel;
  }
  cel = cel.replace(/ /gi, '').replace(/\(/gi, '').replace(/\)/gi, '').replace(/-/gi, '');

  return cel;
};

export const findSupplierBid = (app, supplierIN) => {
  if (app.auction) {
    for (var i = 0; i < app.auction.bids.length; i++) {
      if (app.auction.bids[i].supplier.internalName === supplierIN) {
        return app.auction.bids[i];
      }
    }
  }
  return null;
};

// function auxiliar of postponeTimes()
const getOptions = (now, targetDate, listOfOptions) => {

  if (targetDate.day() === 6 || targetDate.day() === 0) { // saturday & sunday
    return;
  }

  const msg = now.date() === targetDate.date() ? 'Hoje' : targetDate.format('dddd');


  if (listOfOptions) {
    if (targetDate.hour() <= 6) {
      listOfOptions.push({ code: targetDate.hour(9).toDate().toISOString(), description: msg + ' de manhã' });
    }
    if (targetDate.hour() <= 15) {
      listOfOptions.push({ code: targetDate.hour(16).toDate().toISOString(), description: msg + ' à tarde' });
    }
  } else {

    if (targetDate.hour() <= 6) {
      return { code: targetDate.hour(9).toDate().toISOString(), description: msg + ' de manhã' };
    }
    if (targetDate.hour() <= 15) {
      return { code: targetDate.hour(16).toDate().toISOString(), description: msg + ' à tarde' }
    }
  }

  return listOfOptions
};

export const postponeTimes = (baseTime) => {
  let base = moment(baseTime).minute(0).seconds(0).milliseconds(0);
  let time = new moment(base);
  let options = [];

  options = [getOptions(base, time)];

  while (options.length < 15) {
    time = time.add(1, 'day').hour(1);
    getOptions(base, time, options)
  }

  return options.filter(it => it);
};
