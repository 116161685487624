import React, { Component } from 'react';
import { FzTable } from '../ui/fz/grid/table';
import { campaignApplicant } from '../biz/metadatas/campaign'
import { FzCard } from '../ui/fz/layout/index'
import { FzAlert } from '../ui/fz/form/alert';
import { getCampaignApplicants } from '../api/campaigns';

const PAGE_SIZE = 200

export default class CampaignDetail extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
    this.loadData = this.loadData.bind(this)
    this.idColumn = this.idColumn.bind(this)
  }
  componentDidMount() {
    this.setState({ loading: true })
    this.loadData(this.props.campaign, this.props.state, 1)
  }
  loadData(campaign, state, page) {
    getCampaignApplicants(campaign, state === "active", state === "converted", page, PAGE_SIZE)
      .then((data) => {
        let current = this.props.ns.get("campaign", [])
        Array.prototype.push.apply(current, data)
        this.props.ns.set("campaign", current)
        if (data.length === PAGE_SIZE) {
          this.loadData(campaign, state, page + 1)
        } else {
          this.setState({ loading: false })
        }
      })
      .catch(() => this.setState({ loading: false }))

  }
  idColumn(cell, row) {
    return (<a href={"/applications/" + cell} >{cell}</a>);
  }

  render() {
    let data = this.props.ns.get("campaign", null)
    let loading
    if (this.state.loading) {
      loading = <FzAlert fzStyle="attention">Carregando os dados do servidor!</FzAlert>
    }

    let applicantState = this.props.state === "active" ? "(Ativos)" :
      (this.props.state === "converted" ? "(Convertidos)"
        : "(Inativos)")
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h2">{"Campanha " + this.props.campaign + " " + applicantState}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {loading}
          <FzTable
            data={data}
            metas={campaignApplicant}
            context={"campaignsDetail"}
            visibleColumns={[
              "campaign",
              "firstName",
              "lastName",
              "personalNumber",
              "email",
              "telephoneNumber",
              "url",
              "newApplicationId",
              "approvedAmount",
              "approvedInstallment",
              "approvedPeriodMonths",
              "interestRate",
            ]}
            ns={this.props.ns}
            customColumnFormatters={{
              newApplicationId: (cell, row, ) => this.idColumn(cell, row),
            }}
          />
        </FzCard.Body>
      </FzCard>

    )
  }
}
