import { Meta } from "../../types/metas";

export const contractFilters = {
  applicationid: {
    path: 'applicationid',
    dataType: 'string',
    label: 'Application Id',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'filters-contract',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory:  ()  => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  personalnumber: {
    path: 'personalnumber',
    dataType: 'string',
    label: 'Cpf',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '8ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'filters-contract',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory:  ()  => false,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: (e) => {
      let reCPF = /\d+/g
      let cpfs = e || ""
      cpfs = cpfs.replace(/\.|-/g, "");
      let out : string[] = []
      let result
      while ((result = reCPF.exec(cpfs)) !== null) {
        let x = "00000000000" + result[0]
        out.push(x.slice(-11))
      }
      return out.join(",")
    },
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  supplierinternalname: {
    path: "supplierinternalname",
    label: "Parceiro",
    inputType: "select",
    section: 'filters-contract',
    inputProps: {
      options: [],
      filteredFrom: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    isMandatory: () => false,
    isReadOnly: () => false
  },
  product: {
    path: "product",
    label: "Produto",
    inputType: "select",
    section: 'filters-contract',
    inputProps: {
      options: [],
      filteredFrom: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    isMandatory: () => false,
    isReadOnly: () => false
  },
}

export const ContractColumns: Meta = {
  "applicationId": {
    "path": "applicationId",
    "label": "Application Id",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "bidId": {
    "path": "bidId",
    "label": "Bid Id",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "Cpf",
    "tableProps": {
      "hidden": false,
      "width": "1.2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "tableProps": {
      "hidden": false,
      "width": "1.2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "product": {
    "path": "product",
    "label": "Produto",
    "tableProps": {
      "hidden": false,
      "width": "0.8rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false,
  },
  "status": {
    "path": "status",
    "label": "status",
    "tableProps": {
      "hidden": false,
      "width": "0.6rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "contractStatus",
    },
    "isMandatory": () => false,
  },
  "_id": {
    "path": "_id",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "1rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
