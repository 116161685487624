import get from 'lodash/get';
import { Application, pathsApplication } from './metadatas/application';
import hooks from './hook-validate-application'

const validateApplication = async (data, ns, pathsToValidate) => {
  pathsToValidate = !pathsToValidate ? pathsApplication(Application.fields) : pathsToValidate

  let promises = pathsToValidate.map((path) => {

    const metaField = get(Application.fields, path)
    let value = get(data, path, "")

    return metaField !== undefined ? validateAppField({ value, data, ns, metaField }) : null
  })

  return Promise.all(promises)
}

const validateAppField = async ({ value, data, ns, metaField }) => {
  if (typeof metaField !== 'object') {
    console.error("invalid metaField. typeof object is required", metaField)
    return { path: "invalid", valid: false }
  }
  if (metaField.isMandatory && typeof metaField.isMandatory == 'function' && metaField.isMandatory(data) && !hasValue(value)) {
    return { path: metaField.path, reason: "hasValue", valid: false }
  }

  if (hasValue(value)) {

    const pattern = get(metaField, "pattern")
    if (pattern && typeof pattern.test === "function" && !pattern.test(value)) {
      return { path: metaField.path, valid: false, reason: "pattern" }
    }

    const maxValue = get(metaField, "inputProps.maxValue")
    if (maxValue && value > maxValue) {
      return { path: metaField.path, valid: false, reason: "inputProps.maxValue" }
    }

    const minValue = get(metaField, "inputProps.minValue")
    if (minValue && value < minValue) {
      return { path: metaField.path, valid: false, reason: "inputProps.minValue" }
    }
    
    const minSize = get(metaField, "inputProps.minSize")
    if (minSize && value.length < minSize) {
      return { path: metaField.path, valid: false, reason: "inputProps.minSize" }
    }

    if (metaField.extraValidation) {
      const hook = hooks[metaField.extraValidation]

      return {
        path: metaField.path,
        valid: await hook({ value, data, ns, metaField })
      }
    }
  }
  return { path: metaField.path, valid: true }
}

const hasValue = (value) => {
  return !(value === undefined || value === "" || value === null)
}

export { validateApplication, validateAppField }