import { Meta } from "../../../types/metas"

export const internalPendencyMeta: Meta = {
  "applicationId": {
    "path": "applicationId",
    "label": "ApplicatioId",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5ch",
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "applicationIdColumn",
    },
    "isMandatory": (application) => false,
  },
  "branch": {
    "path": "branch",
    "label": "Branch",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "date": {
    "path": "date",
    "label": "Entrada na fila interna",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": false,
      'expandable': false,
      "columnFormat": "fullDateTimeColumn",
    },
    "isMandatory": () => false
  },
  "screenerName": {
    "path": "screenerName",
    "label": "Analista",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false
  },
  "supplierName": {
    "path": "supplierName",
    "label": "Parceiro",
    "tableProps": {
      "hidden": false,
      "width": "7rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
    },
    "isMandatory": () => false
  },
  "id": {
    "path": "id",
    "label": "",
    "tableProps": {
      "hidden": false,
      "width": "4rem",
      "dataSort": false,
      'expandable': false,
      'isKey': true
    },
    "isMandatory": () => false
  },
}