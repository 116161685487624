import React from 'react';
import { FzJumbotron, FzCol, FzRow, FzRemarks } from '../ui/fz/layout/index';
import { FzButtonGroup } from '../ui/fz/form/button';
import CampaignsOverview from './campaigns-overview'
import CampaignDetail from './campaign-detail'
import { initPresetCampaign } from '../api/campaigns';

const CampaignConsole = ({ns, api, requireAuth, ...props}) => {

  let { campaign, state } = props.match.params

  const newPresetCampaign = async (preset) => {
    ns.set("creatingCampaign", true)
    try {
      await initPresetCampaign(preset, 30).finally(() => ns.set("creatingCampaign", false))
      ns.unset("campaigns");
    } catch(e) {
      window.alert("Erro criando a campanha: "+ e.toString());
    }

  }

  let campaignPanel
  let buttonsRow
  if (!campaign || !state) {
    let buttons = [
      {
        fzStyle: 'regular',
        description: 'Campanhas com regras de filtros',
        onClick: () => {
          props.history.push('/campaigns/prepare');
        },
      },
      {
        fzStyle: 'regular',
        description: 'Campanha manual',
        onClick: () => { props.history.push('/new-campaign/'); }
      },
      {
        fzStyle: 'default',
        description: 'Refresh',
        onClick: () => { 
          ns.unset("campaigns"); 
          ns.unset("_busy");
        }
      },
    ];
    const itButtons = [
      {
        fzStyle: 'regular',
        description: 'Newsletter',
        onClick: () => {
          if (window.confirm("Esta rotina enviará MILHÕES de e-mails para a nossa base de dados.\nConfirma?"))
          {
            newPresetCampaign('NEWSLETTER')
          }
        },
      },
    ];

    const mktButtons = [
      {
        fzStyle: 'regular',
        description: 'Newsletter',
        onClick: () => {
          if (window.confirm("Esta rotina enviará MILHÕES de email para a nossa base de dados.\nConfirma?"))
          {
            newPresetCampaign('NEWSLETTER')
          }
        },
      },
    ]

    const user = api.getCurrentUser();
    if (api.isUserInRole('admin') && user.group.indexOf('it') > -1) {
      buttons = buttons.concat(itButtons);
    }

    if (api.isUserInRole('admin') && user.group.indexOf('mkt') > -1) {
      buttons = buttons.concat(mktButtons);
    }

    if (api.isUserInRole('admin')) {
      buttonsRow = (
        <FzRow>
          <FzCol span={12}>
            <FzButtonGroup disabled={ns.get("creatingCampaign", false)} buttons={buttons}/>
            <FzRemarks fzStyle="success"><a rel="noopener noreferrer" target="_blank" href="https://sites.google.com/finanzero.com.br/intranet/departamentos/marketing/campanhas">ver página de ajuda</a></FzRemarks>
          </FzCol>
        </FzRow>
      );
    }

    campaignPanel = (<CampaignsOverview ns={ns} api={api} history={props.history}/>)

  } else {
    campaignPanel = (<CampaignDetail ns={ns} api={api} campaign={campaign} state={state} />)
  }

  return(
    <FzJumbotron>
      {/* {buttonsRow} */}
      {campaignPanel}
    </FzJumbotron>
  )

}

export default CampaignConsole;
