import { Meta } from "../../types/metas";


// ["key", "majorVersion", "minorVersion", "patchVersion", "formPage", "currentPage", "path", "total"]
export const formSummary: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "width": "50",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
      rowSpan: "2"
    },
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
      rowSpan: "2"
    },
  },
  "product": {
    "path": "product",
    "dataType": "string",
    "label": "Product",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "filter": { type: 'SelectFilter', "options": "filters.product", "condition": 'eq' },
      "dataSort": true,
      "dataAlign": "center",
      rowSpan: "2"
    },
  },
  "total": {
    "path": "total",
    "dataType": "number",
    "label": "Total",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
      rowSpan: '2'
    },
  },
  "closedFirst": {
    "path": "closedFirst",
    "label": "Closed First Page",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "formatExtraData": "total",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Closed First Page",
            dataAlign: "left",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      },
    },
  },
  "closedValidation": {
    "path": "closedValidation",
    "label": "Closed Bank Validation",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "formatExtraData": "total",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Closed Bank Validation",
            dataAlign: "left"
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      },
    },
  },
  "closedThrough": {
    "path": "closedThrough",
    "label": "Auto Close (no-offers)",
    "tableProps": {
      "viewsTable": "",
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "formatExtraData": "total",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Auto Close (no-offers)",
            dataAlign: "left",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }]
      }
    },
  },
  "abandoned": {
    "path": "abandoned",
    "label": "Form Abandoned",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "formatExtraData": "total",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Form Abandoned",
            dataAlign: "left",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }
        ]
      }
    },
  },
  "finished": {
    "path": "finished",
    "label": "Form Completed",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "formatExtraData": "total",
      "spanColumns": {
        columns: [
          {
            row: '0',
            colSpan: '2',
            "label": "Form Completed",
            dataAlign: "left",
          },
          {
            row: '1',
            "label": "Qtty",
            dataAlign: "left",
            "columnFormat": "amountColumn",
          },
          {
            row: '1',
            "label": "%",
            "columnFormat": "pctColumn",
            dataAlign: "left",
          }
        ]
      }
    },
  },
  "majorVersion": {
    "path": "majorVersion",
    "dataType": "number",
    "label": "Major Version",
    "tableProps": {
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildColumnValuesFilter" },
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "minorVersion": {
    "path": "minorVersion",
    "dataType": "number",
    "label": "Minor Version",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "patchVersion": {
    "path": "patchVersion",
    "dataType": "number",
    "label": "Pach Version",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "formPage": {
    "path": "formPage",
    "dataType": "number",
    "label": "Validated Page",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Last successfully submited page",
  },
  "currentPage": {
    "path": "currentPage",
    "dataType": "number",
    "label": "Submited Page",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Last submited page",
  },
  "path": {
    "path": "path",
    "dataType": "number",
    "label": "Field Name",
    "tableProps": {
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Erroneous field name"
  }

}


