import { Meta } from '../../../../types/metas';


export const screeningStatesSummary: Meta = {
  "supplierInternalName": {
    "path": "supplierInternalName",
    "dataType": "string",
    "label": "Parceiro",
    isMandatory: () => true,
    "tableProps": {
      "viewsTable": undefined,
      "hidden": true,
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },

  "notInitialized": {
    "path": "notInitialized",
    "label": "Não iniciado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "inAnalysis": {
    "path": "inAnalysis",
    "label": "Em análise",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "finished": {
    "path": "finished",
    "label": "Total finalizado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "pended": {
    "path": "pended",
    "label": "Pendenciado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "success": {
    "path": "success",
    "label": "Validado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "rejected": {
    "path": "rejected",
    "label": "Rejeitado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "closed": {
    "path": "closed",
    "label": "Fechado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "totalOutcomes": {
    "path": "totalOutcomes",
    "label": "Total finalizado",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "queueTime": {
    "path": "queueTime",
    "label": "Tempo médio em fila",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "analysisTime": {
    "path": "analysisTime",
    "label": "Tempo médio em análise",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "completionTime": {
    "path": "completionTime",
    "label": "Tempo médio total",
    "section": "absolute",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "total": {
    "path": "total",
    "label": "Total de análises",
    "section": "absolute",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },

  "agregado": {
    "path": "agregado",
    "label": "Agregado por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "day", "description": "Daily" },
        { "code": "week", "description": "Weekly" },
        { "code": "month", "description": "Monthly" },
      ],
    },
    "isMandatory": (application) => true,
  },

  "period": {
    "path": "period",
    "label": "Período",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => false,
  }
};


export const screeningSummaryPivotMeta: Meta = {
  "key": {
    "path": "key",
    "dataType": "number",
    "label": "#",
    "tableProps": {
      "width": "3ch",
      "hidden": true,
      "isKey": true,
      "dataSort": true,
      "dataAlign": "center",
    },
    "isMandatory": () => false
  },

  "pivot": {
    "path": "pivot",
    "dataType": "string",
    "label": "Triagens Finalizadas",
    "tableProps": {
      "width": "35ch",
      "dataAlign": "left"
    },
    "isMandatory": () => false
  },

  "template": {
    "path": "---",
    "dataType": "number",
    "label": "",
    "tableProps": {
      "width": "16ch",
      "hidden": true,
      "dataAlign": "right",
      "exportFormat": "formatCurrency",
    },
    "isMandatory": () => false
  }
};
