import { useCallback, useContext, useLayoutEffect, useState } from 'react';
import SplitLine from '../../../components/split-line';
import api from '../../../api/client-api';
import { Link } from 'react-router-dom';
import { FzBadge, FzCard, FzJumbotron } from '../../fz/layout/index';
import { startAgent, stopAgent, getAgents } from '../../../api/integrations';
import { FzIcons } from '../../fz/form/icon';
import { FzButton, FzButtonGroup } from '../../fz/form';
import { FzTable } from '../../fz/grid/table';
import Nav from 'react-bootstrap/Nav';
import ReactJson from 'react-json-view';
import { agentMeta } from '../../../biz/metadatas/agent';
import { ToastContext } from '../../../hooks/ToastProvider';
import { FzModal } from '../../fz/fz-modal';

export const AgentsConsole = (props) => {
  const notAllowed = !api.isITUser();
  const [initAgents, loadingAgents] = useState(false);
  const [agents, setAgents] = useState([]);
  const [activeKey, setActiveKey] = useState('agents');

  const { setHeader, setBody, setIsOpen, setLevel } = useContext(ToastContext);

  useLayoutEffect(() => {
    getAgentsApi(activeKey);
  }, []);

  const normalizeContainer = (agents) =>
    agents.forEach((agent) => {
      if (agent.container) {
        if (!agent.container.Args) {
          agent.container.Args = [];
        }
      } else {
        agent.container = { Args: [] };
      }
    });

  const normalizeConfiguration = (agents) =>
    agents.forEach((agent) => {
      if (agent.configuration) {
        if (!agent.configuration.runtime) {
          agent.configuration.runtime = {};
        }
      } else {
        agent.configuration = { runtime: {} };
      }
    });

  const getAgentsApi = async (integration = '') => {
    const { ns } = props;
    loadingAgents(true);
    try {
      const response = await getAgents(integration);
      ns.set('agents', response, -1);
      loadingAgents(false);

      normalizeConfiguration(response);
      normalizeContainer(response);

      setAgents(response);
    } catch (error) {
      loadingAgents(false);
      setHeader('Error');
      setBody(error.toString());
      setLevel('danger');
      setIsOpen(true);
    }
  };

  const handleRefresh = useCallback(() => {
    getAgentsApi(activeKey);
  }, [activeKey]);

  const handleStartStop = async (running, containerId) => {
    try {
      if (running) {
        await stopAgent(containerId);
      } else {
        await startAgent(containerId);
      }
      await getAgentsApi(activeKey);
    } catch (error) {
      setHeader('Error');
      setBody(error);
      setLevel('danger');
      setIsOpen(true);
    }
  };

  const handleSubPageSelect = (typeService) => {
    getAgentsApi(typeService);
    setActiveKey(typeService);
  };

  const ComponentButtonAction = (containerStatus = '', containerId = '') => {
    return (
      <FzButtonGroup
        buttons={[
          {
            fzStyle: containerStatus ? 'alert' : 'regular',
            bsSize: 'small',
            disabled: notAllowed,
            onClick: () => handleStartStop(containerStatus, containerId),
            description: (
              <FzIcons
                icon={containerStatus ? 'pause-circle' : 'play-circle'}
                fzStyle={{ width: '15px', height: '15px' }}
              />
            ),
          },
          // TODO: button to open Partner's Info (waiting put supplierId on agent's list response)
          // {
          //   fzStyle: 'discrete',
          //   bsSize: 'small',
          //   onClick: () => {
          //     window.open(`/suppliers/{idsupplier}`, '_blank').focus();
          //   },
          //   description: (
          //     <div>
          //       <FzIcons
          //         icon={'cliente'}
          //         fzStyle={{ width: '15px', height: '15px' }}
          //       />{' '}
          //       Dados Parceiro
          //     </div>
          //   ),
          // },
        ]}
      />
    );
  };

  return (
    <FzJumbotron>
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">
            <SplitLine>
              <SplitLine.Left>Agentes</SplitLine.Left>
              <SplitLine.Right>
                <span hidden={!initAgents}>Atualizando</span>
                <FzButton fzStyle="discrete" onClick={handleRefresh}>
                  <FzIcons
                    icon={'repeat'}
                    fzStyle={{ width: '15px', height: '15px' }}
                  />
                </FzButton>
              </SplitLine.Right>
            </SplitLine>
            <Nav
              className="adjusted-nav"
              variant="tabs"
              onSelect={handleSubPageSelect}
              activeKey={activeKey}
            >
              <Nav.Link eventKey="agents" title="Documents status">
                Parceiros
              </Nav.Link>
              <Nav.Link eventKey="validators" title="Documents status">
                Serviços
              </Nav.Link>
            </Nav>
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={agents}
            metas={agentMeta}
            ns={props.ns}
            columns={[
              'agent',
              'packageVersion',
              'coreVersion',
              'nodeVersion',
              'isMock',
              'status',
            ]}
            customColumnFormatters={{
              actions: (_, row) =>
                ComponentButtonAction(row.agent.running, row.agent.containerId),
              ['agent.agent']: (_, row) => (
                <Link to={props.location.pathname + '/' + row.agent.agent}>
                  {row.agent.agent}
                </Link>
              ),
              ['configuration.runtime.version']: (_, row) => {
                if (!row.configuration.runtime.version) {
                  return '-';
                }

                return (
                  <FzModal
                    headerTitle={'Package Info'}
                    containerStyle={{ marginTop: '50px' }}
                    showButtonText={row.configuration.runtime.version}
                    showButtonType="text"
                    fzStyle="primary"
                    buttonDisabled={false}
                  >
                    <ReactJson
                      collapsed={2}
                      displayDataTypes={false}
                      src={row.configuration.runtime}
                      style={{ overflowWrap: "anywhere" }}
                    />
                  </FzModal>
                );
              },
              ['configuration.runtime.dependencies']: (_, row) => {
                if (
                  row.configuration?.runtime?.dependencies &&
                  row.configuration.runtime.dependencies[
                    '@itfinanzero/integration.core'
                  ]
                ) {
                  const coreVersion =
                    row.configuration.runtime.dependencies[
                      '@itfinanzero/integration.core'
                    ].split('#');

                  if (coreVersion.length === 2) {
                    return (
                      <FzModal
                        headerTitle={'Package Info'}
                        containerStyle={{ marginTop: '50px' }}
                        showButtonText={coreVersion[1]}
                        showButtonType="text"
                        fzStyle="primary"
                        buttonDisabled={false}
                      >
                        <ReactJson
                          collapsed={2}
                          displayDataTypes={false}
                          src={row.configuration.runtime}
                          style={{ overflowWrap: "anywhere" }}
                        />
                      </FzModal>
                    );
                  }
                }

                return '-';
              },
              ['container.Config.Env.NODE_VERSION']: (_, row) => {
                if (row.container?.Config?.Env?.NODE_VERSION) {
                  return (
                    <FzModal
                      headerTitle={'Container Info'}
                      containerStyle={{ marginTop: '50px' }}
                      showButtonText={row.container.Config.Env.NODE_VERSION}
                      showButtonType="text"
                      fzStyle="primary"
                      buttonDisabled={false}
                    >
                      <ReactJson
                        collapsed={2}
                        displayDataTypes={false}
                        src={row.container}
                        style={{ overflowWrap: "anywhere" }}
                      />
                    </FzModal>
                  );
                }

                return '-';
              },
              ['container.Args']: (_, row) => {
                if (row.container?.Args?.indexOf('beta_mock_docker') > -1) {
                  return (
                    <FzModal
                      headerTitle={'Container Info'}
                      containerStyle={{ marginTop: '50px' }}
                      showButtonText={
                        row.container.Args.indexOf('beta_mock_docker') > -1
                          ? 'Sim'
                          : 'Não'
                      }
                      showButtonType="text"
                      buttonDisabled={false}
                    >
                      <ReactJson
                        collapsed={2}
                        displayDataTypes={false}
                        src={row.container}
                        style={{ overflowWrap: "anywhere" }}
                      />
                    </FzModal>
                  );
                }
                return '-';
              },
              ['agent.status']: (_, row) => {
                return (
                  <FzModal
                    headerTitle={'Container Info'}
                    containerStyle={{ marginTop: '50px' }}
                    showButtonText={row.agent.status}
                    showButtonType="text"
                    fzStyle="primary"
                    buttonDisabled={false}
                  >
                    <ReactJson
                      collapsed={2}
                      displayDataTypes={false}
                      src={row.container}
                      style={{ overflowWrap: "anywhere" }}
                    />
                  </FzModal>
                );
              },
              configuration: (_, row) => {
                if (Object.keys(row.configuration).length > 1) {
                  return (
                    <FzModal
                      headerTitle={'Configuration Info'}
                      containerStyle={{ marginTop: '50px' }}
                      showButtonText={'Visualizar'}
                      showButtonType="text"
                      fzStyle="primary"
                      buttonDisabled={false}
                    >
                      <ReactJson
                        collapsed={2}
                        displayDataTypes={false}
                        src={row.configuration}
                        style={{ overflowWrap: "anywhere" }}
                      />
                    </FzModal>
                  );
                }
                return 'Não disponível';
              },
            }}
            exportDownload={false}
            clipboard={false}
            hover={true}
          />
        </FzCard.Body>
      </FzCard>
    </FzJumbotron>
  );
};
