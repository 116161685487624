import React from 'react';
import { FzCard } from '../../fz/layout/index';
import { FzTable } from '../../fz/grid/table';
import { affiliateAnalytics } from '../../../biz/metadatas/affiliate'
import api from '../../../api/client-api'
import { StatsDetailHeader } from '../../../statistics/stats-components';
import { FzButton } from '../../fz/form/button';
import { Modal } from 'react-bootstrap'
import copy from 'copy-to-clipboard';
import { _getExtraData } from '../../../components/table-standard'
import { FzIcons } from '../../fz/form/icon';
import { Link } from 'react-router-dom';


export class AffiliateAnalytics extends React.Component<any, any>  {
  constructor(props) {
    super(props)

    this.state = { showPayload: false, payloadTitle: "" }
    this.payloadColumn = this.payloadColumn.bind(this)
    this.idColumn = this.idColumn.bind(this)
  }

  payloadColumn(cell, row) {
    return <FzButton
      onClick={(e) => { e.stopPropagation(), this.setState({ showPayload: true, payload: cell, payloadTitle: "Payload recebido" }) }}
      tip={null}
      disabled={false}
      className={null}
      block={true}
      fzStyle={"regular"}
    >payload</FzButton>
  }

  rejectMessages(cell, row) {
    let errorData = row.invalid ? (row.rejectMessages || row.rejectLogs) : null

    const buttonFace = errorData ? "erros" : "ok"
    return <FzButton
      onClick={(e) => { e.stopPropagation(), this.setState({ showPayload: true, payload: errorData, payloadTitle: "Mensagens de erro" }) }}
      tip={null}
      disabled={errorData == null}
      className={null}
      block={true}
      fzStyle={"regular"}
    >{buttonFace}</FzButton>
  }


  idColumn = (cell, row, formatExtraData, idx, ns) => {
    if (!cell || !api.isUserInRole("admin")) {
      return cell;
    }
    let appId = cell;
    let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
    url += appId;
    let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a>
    ) : null;
    return (
      <div>
        {newTab}&nbsp;
        <Link to={url} title={cell}>
          {'...' + cell.slice(-6)}
        </Link>
      </div>
    );
  }

  render() {
    const { ns, data } = this.props
    const user = api.getCurrentUser()

    if (!data) {
      return null
    }


    return (
      <FzCard>

        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title> {"Affiliate Analytics"} </FzCard.Title>
        </FzCard.Heading>

        <FzCard.Body>
          <PayloadModal show={this.state.showPayload} payloadTitle={this.state.payloadTitle} payloadContent={this.state.payload} onHide={() => this.setState({ showPayload: false })} />

          <FzTable
            data={data}
            metas={affiliateAnalytics}
            ns={ns}
            clipboard={user.role === "admin" ? true : false}
            exportDownload={user.role === "admin" ? true : false}
            customColumnFormatters={{
              "rejectMessages": (cell, row) => this.rejectMessages(cell, row),
              "payload": (cell, row) => this.payloadColumn(cell, row),
              applicationId: (cell, row, formatExtraData, idx, ns) => this.idColumn(cell, row, formatExtraData, idx, ns)
            }}
            visibleColumns={[
              "id",
              "created",
              "affiliateName",
              "affiliateId",
              "transaction",
              "applicationId",
              "personalNumber",
              "Email",
              "telephoneNumber",
              "invalid",
              "rejected",
              "filtered",
              "started",
              "rejectReason",
              "rejectMessages",
              "payload"
            ]}
            pagination
          />
        </FzCard.Body>

      </FzCard>

    )
  }
}

class PayloadModal extends React.Component<any, any> {

  render() {
    let payloadContent = ""
    if (this.props.payloadContent) {
      if (this.props.payloadContent === 0) {
        payloadContent = "Nenhum payload registrado"
      } else {
        payloadContent = JSON.stringify(this.props.payloadContent, null, 2)
      }
    }

    return (
      <Modal bsSize="large" show={this.props.show} onHide={() => this.props.onHide ? this.props.onHide() : undefined}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.payloadTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div><pre>
            {payloadContent}
          </pre></div>
        </Modal.Body>
      </Modal>
    )
  }
}