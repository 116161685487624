
import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Funnel from 'highcharts/modules/funnel';
Funnel(Highcharts)




type FzOption =

  {
    chart: {
      type: string
    },

    title: {
      text: string
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: boolean,
          format: string,
          softConnector: boolean
        },
        center: [string, string],
        neckWidth: string,
        neckHeight: string,
        width: string

      }
    },

    legend: {
      enabled: boolean
    },
    series: [{
      name: string,
      data: [string, number][]
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: number
        },
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                inside: boolean
              },
              center: [string, string],
              width: string
            }
          }
        }
      }]
    }
  }

const metaFunnel = {
  options: {

    chart: {
      type: 'funnel'
    },

    title: {
      text: 'Funnel Operational Overview'
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b> ({point.y:,.0f})',
          softConnector: true
        },
        center: ['40%', '50%'],
        neckWidth: '30%',
        neckHeight: '25%',
        width: '80%'
      }
    },


    legend: {
      enabled: false
    },
    series: [{
      data: [[]]  //
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 250
        },
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                inside: true
              },
              center: ['50%', '50%'],
              width: '100%'
            }
          }
        }
      }]
    }
  }
};

type FzFunnelProps = {
  options: FzOption,
}


class FzFunnel extends React.Component<FzFunnelProps, any> {

  render() {
    const { options } = this.props

    return (
      <div>
        {options ? (<HighchartsReact
          highcharts={Highcharts}
          options={options} />) : null  }
      </div>

    )
  }

}
export { metaFunnel, FzFunnel }