import { useParams } from 'react-router-dom';
import { Edit } from '../../../components/form/form-generator';
import {
  GetAffiliateWebhook,
  PostAffiliateWebhook,
  PutAffiliateWebhook,
} from '../../../../services/api/parameters/formalization';
import { AffiliateForm } from '../../../../biz/metadatas/affiliate';
import { useEffect } from 'react';
import { getCurrentUser } from '../../../../api/auth';

const EditAffiliate = ({ ns }) => {
  let { affiliateName }: { affiliateName: string | undefined } = useParams<{
    affiliateName: string;
  }>();

  if (affiliateName === 'add') {
    affiliateName = undefined;
  }

  useEffect(() => {
    ns.setShared('CACHE.webhook', affiliateName === undefined ? 'new' : '');
  }, [affiliateName]);

  const webhookDomains = [
    'appparamsmap',
    'method',
    'params',
    'stepsmap',
    'steps',
    'url',
    'urlvariables',
  ];

  const ActionGet = async () => {
    let response: any;
    if (affiliateName !== undefined) {
      response = await Promise.all(
        webhookDomains.map((domain) =>
          GetAffiliateWebhook(domain, affiliateName ?? '')
        )
      );
    }

    const customData: { data: { [key: string]: string | [] | undefined } } = {
      data: {
        path: affiliateName === 'add' ? undefined : affiliateName,
      },
    };

    webhookDomains.forEach((item, index) => {
      customData.data[item] = response[index]?.data?.value
        ? response[index].data.value
        : item === 'steps'
        ? []
        : '';
    });

    return customData;
  };

  const callSaveEndpoint = async (
    affiliateName: string,
    data: any,
    update = false
  ) => {
    try {
      await Promise.all(
        webhookDomains.map((domain) => {
          if (update) {
            return PutAffiliateWebhook(
              domain,
              affiliateName,
              data[domain] ?? ''
            );
          } else {
            return PostAffiliateWebhook(
              domain,
              affiliateName,
              data[domain] ?? ''
            );
          }
        })
      );

      return {
        body: {
          id: affiliateName ?? data.path,
        },
      };
    } catch (error) {
      console.log('Erro ao salvar:', error);
    }
  };

  const ActionSave = async (data: any) => callSaveEndpoint(data.path, data);

  const ActionUpdate = async (_: any, data: any) =>
    callSaveEndpoint(affiliateName ?? '', data, true);

  const user = getCurrentUser()
  const setToast = () => {};

  return (
    <Edit
      user={user}
      meta={AffiliateForm}
      title={'Afiliados'}
      entity={'affiliates'}
      onCreate={undefined}
      ns={ns}
      actionCreate={ActionSave}
      actionUpdate={ActionUpdate}
      actionGet={ActionGet}
      id={affiliateName}
      setToast={setToast}
    />
  );
};

export default EditAffiliate;
