
import { clientService } from "../../../api/axios-client-api";
import { FormValidationStatsResponse } from "./types";

const misApi = clientService.misApi.v1
const apiV2 = clientService.api.v2

export const  getFormValidationStats = (period): Promise<FormValidationStatsResponse> => {
  return misApi.Get("/stats/formvalidation/" + period);
}

export const getFormUXStats = (period) => {
  return apiV2.Get("/stats/formvalidation/" + period);
}

export const getDeletedReason = (period) => {
  return misApi.Get(`/lending/applications/deletedreason/${period}`);
}


