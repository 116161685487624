import { useState } from "react";

export function DropdownIconsBullets({ open, icons, listIconFiltered, listIcon, handleChangeOpen, setListIconFiltered, setIconDefault, changeField, index, iconDefault }) {
  const [type, setType] = useState("solid");
  const [searchText, setSearchText] = useState(iconDefault ? iconDefault : "");

  const listTypeIcons = ["solid", "regular", "brands"];

  const listFilteredByType = listIconFiltered.map((item, index) => {
    if (icons[item].free.indexOf(type) > -1) {
      return icons[item];
    }
  }).filter(Boolean);

  function NavTypeIconsButtons() {
    if (searchText.includes("fa-")) {
      return <></>
    } else {
      return listTypeIcons.map((item, index) => {
        return (
          <button
            className={`col-md-4 btn border border-bottom-0 rounded-0 ${item == type ? "btn-primary" : ""}`}
            onClick={() => setType(item)}
            style={{ fontSize: "12px" }}
          >
            {item}
          </button>
        )
      })
    }
  }

  function IconsButtons() {
    if (listFilteredByType.length) {
      return listFilteredByType.map((item, int) => {
        return <button
          className="btn btn-light col-md-3"
          onClick={() => {
            const val = `fa-${type} fa-${item.label.replace(" ", "-").toLowerCase()}`;
            setIconDefault(val);
            changeField(index, val, "icon")
            handleChangeOpen(false)
          }}
        >
          <span className="iconList" dangerouslySetInnerHTML={{ __html: item.svg[type].raw }} />
        </button>
      })
    } else if (searchText.includes("fa-")) {
      return (
        <>
          <div className="col-md-12">
            <p className="m-0">
              Visualize seu ícone:
            </p>
            <p>
              <button className="btn btn-light border mt-2"><i className={searchText}></i></button>
            </p>
            <button
              className="btn btn-success w-100 mt-2"
              onClick={() => {
                changeField(index, searchText, "icon")
                handleChangeOpen(false)
              }}
            >Usar esse mesmo</button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div>
            <p>
              Caso não encontre seu ícone importe igual no <a target={"_blank"} href="https://fontawesome.com/icons/house?s=solid&f=classic">FontAwesome</a>
            </p>
            <p className="p-0 m-0">Por exemplo:</p>
            <code>fa-solid fa-house</code>
          </div>
        </>
      )
    }
  }


  function search(text) {
    setSearchText(text)
    if (text.length >= 0) {
      const newData = listIcon.filter(function (item) {
        const itemData = item
          ? item.toUpperCase()
          : ''.toUpperCase();
        return itemData.indexOf(text.toUpperCase()) > -1;
      });
      setListIconFiltered(newData)
    } else {
      setListIconFiltered(listIcon);
    }
  }

  if (open) {
    return (
      <div
        className="position-absolute w-100 pr-4"
        style={{ zIndex: 10, marginTop: "-40px", minWidth: "300px" }}
      >
        <div
          className="pl-4 pr-4 pt-1 bg-white shadow"
          style={{ height: 200, overflow: "auto" }}
          onMouseLeave={() => handleChangeOpen(false)}
        >
          <div className="row">
            <div
              className="col-md-12 border p-0 mb-2 d-flex"
              style={{
                borderRadius: "0.25rem"
              }}
            >
              <div className="position-absolute d-flex align-items-center justify-content-center"
                style={{
                  height: "100%",
                  width: "2rem"
                }}
              >
                <i className="fa-light fa-magnifying-glass "></i>
              </div>
              <input
                type="text"
                className="form-control rounded-pill border-0"
                name=""
                id=""
                value={searchText}
                aria-describedby="helpId"
                placeholder="Pesquisar ícone"
                onChange={(e) => {
                  search(e.target.value);
                }}
                onFocus={() => handleChangeOpen(true)}
                style={{
                  paddingLeft: "2rem",
                  paddingRight: "2rem"
                }}
              />
              <div className="position-absolute d-flex align-items-center justify-content-center"
                onClick={() => setSearchText("")}
                style={{
                  height: "100%",
                  width: "2rem",
                  right: 0
                }}
              >
                <i className="fa-duotone fa-circle-xmark text-muted"></i>
              </div>
            </div>
            <NavTypeIconsButtons />
            <div className="col-md-12 border mt-0 mb-2"></div>
            <IconsButtons />
          </div>
        </div>
      </div>
    )
  }

  return <></>

}