import { Meta } from "../../../types/metas";

export const fieldValidationMeta: Meta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "width": "50",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "majorVersion": {
    "path": "majorVersion",
    "dataType": "number",
    "label": "Major Version",
    "tableProps": {
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildColumnValuesFilter" },
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "minorVersion": {
    "path": "minorVersion",
    "dataType": "number",
    "label": "Minor Version",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "patchVersion": {
    "path": "patchVersion",
    "dataType": "number",
    "label": "Pach Version",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
  },
  "formPage": {
    "path": "formPage",
    "dataType": "number",
    "label": "Validated Page",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Last successfully submited page",
  },
  "currentPage": {
    "path": "currentPage",
    "dataType": "number",
    "label": "Submited Page",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Last submited page",
  },
  "path": {
    "path": "path",
    "dataType": "number",
    "label": "Field Name",
    "tableProps": {
      "width": "12ch",
      "filter": { type: 'TextFilter', delay: 1000 },
      "dataSort": true,
      "dataAlign": "center",
    },
    "tip": "Erroneous field name"
  },
  "total": {
    "path": "total",
    "dataType": "number",
    "label": "Total",
    "tableProps": {
      "width": "12ch",
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
    },
  }
}


