import InputMask from 'inputmask-core'
import moment from 'moment'


const formatTelephone = (phone) => {
  if (!phone) return ""
  const pattern = (phone.length === 11 ? "(11) 1 1111-1111" : "(11) 1111-1111");
  const mask = new InputMask({pattern: pattern, value: phone})
  return mask.getValue()
}
const formatPersonalNumber = (pn) => {
  if (!pn) return ""
  const pattern = "111.111.111-11";
  const mask = new InputMask({pattern: pattern, value: pn})
  return mask.getValue()
}

const formatDateTime = (date) => {
  if (date && date.substring(0, 10) !== "0001-01-01") {
    return moment(date).format("DD/MM/YYYY, HH:mm:ss");
  } else {
    return ""
  }
}

export {formatTelephone, formatPersonalNumber, formatDateTime}