import { Product } from "../../../../../biz/metadatas/product";
import { RenderInput } from "./RenderInput";

export function Priority(props) {
  const { priority, setProducts, ns, index, supplier, onChange, product, isEditing } = props;
  const metaFields = Product.getFieldBySection("partner-priority")
  function verifyEmpty(value) {
    return (value == "" || value == undefined || value == "undefined") ? false : true
  }

  if (metaFields) {
    const availableBranches = supplier.suppliers.map((s) => {
      let branches = s.branches.filter((branch) => {
        return !product.offersPriorities.find(o => (o && o.branchCode && o.branchCode == branch.code && o.supplierInternalName == s.code))
      });
      return branches.length > 0 ? {
        ...s,
        branches
      } : undefined

    }).filter(e => e);

    return metaFields.map((meta, key) => {
      let options;

      if(meta?.inputProps?.options && meta.inputProps.options.length) {
        options = meta.inputProps.options;
      } else {
        if (meta.path == "supplierInternalName") {
          const verifyBranchWithEmpty = availableBranches.filter((branch) => {
            return !product.offersPriorities.find((individualProduct, keyProduct) => {
              if (individualProduct && individualProduct.supplierInternalName && individualProduct.supplierInternalName == branch.code && !verifyEmpty(individualProduct.branchCode)) {
                if (index == keyProduct) {
                  if (product.offersPriorities.filter(e => e.supplierInternalName == individualProduct.supplierInternalName).length > 1) {
                    priority.branchCode = branch.branches[0].code;
                  }
                }
                return branch;
              }
            });
          });
          options = verifyBranchWithEmpty;
        } else {
          const find = availableBranches.find((s) => s.code == priority.supplierInternalName);
          options = find ? find.branches : [];
        }
      }

      const renderInputProps = {
        options,
        meta,
        index,
        priority,
        product,
        setProducts,
        onChange,
        supplier,
        ns,
        isEditing,
      }

      return <div key={key} className={meta.customClass}>
        <RenderInput {...renderInputProps} />
      </div>
    })
  }
  return <></>;
}