import React from 'react';
import CampaignDetails from './campaign-details';
import CampaignActions from './campaign-actions';
import {getByID} from '../api/applications';
import { FzCardGroup, FzCol, FzRow, FzJumbotron } from '../ui/fz/layout/index';
import { getApplicant } from '../api/campaigns';

const SalesConvertCampaign = ({ns, api, ...props}) => {

  let campaign = ns.get("campaignApplicant")
  let app = ns.get("application")

  if (!campaign) {
    getApplicant(props.match.params.campaignId)
    .then((campainApplicant) => {
      ns.set("campaignApplicant", campainApplicant)
    })
    .catch((error) => {
      console.warn(error)
      props.history.push("/sales")
    });
  }
  if (!app && campaign) {
    getByID(campaign.originalApplicationId)
    .then((app) => {
      ns.set("application", app)
    })
    .catch((error) => {
      console.warn(error)
      props.history.push("/sales")
    });
  }
  if (!campaign || !app) {
    return null
  }

  return (
    <FzJumbotron>
      <FzCardGroup id="SalesConvertCampaign">
        <FzRow>
          <FzCol span={8}>
            <CampaignDetails api={api} ns={ns} applicant={campaign}  application={app}  />
          </FzCol>
          <FzCol span={4}>
            <CampaignActions ns={ns} api={api} applicant={campaign} application={app}  history={props.history}/>
          </FzCol>
      </FzRow>
      </FzCardGroup>
    </FzJumbotron>

  )
}

export default SalesConvertCampaign;
