import React, { useState } from 'react';
import { panelOverwriteDocuments } from '../../../../biz/metadatas/stats/screening/overwrite-documents';
import { prioritizedBidsMeta } from '../../../../biz/metadatas/stats/screening/prioritized-bids';
import { FzCard } from '../../../fz/layout/FzCard';
import { FzButton } from '../../../fz/form/button';
import { FzTable } from '../../../fz/grid/table';
import FieldConnected from '../../../components/form/field-connected';
import { _getExtraData } from '../../../../components/table-standard';
import { Link } from 'react-router-dom';
import { FzIcons } from '../../../fz/form/icon';

function PrioritizedBidsLayout({
  data,
  setUrlQueryString,
  ns
}) {

  function idColumn(cell, row, formatExtraData, idx, ns) {
    let applicationId = cell;
    let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
    url += applicationId;
    let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a>
    ) : null;
    return (
      <div>
        {newTab}&nbsp;
        <Link to={url} title={cell}>
          {'...' + cell.slice(-6)}
        </Link>
      </div>
    );
  }

  return (
    <FzCard>
      <FzCard>
        <div className={"fz-panel"} >
          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={data} meta={panelOverwriteDocuments["createdAfter"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={data} meta={panelOverwriteDocuments["createdBefore"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"}>
            <FzButton
              onClick={
                setUrlQueryString
              }
              tip={"buscar"}
              disabled={false}
              className={null}
              block={true}
              fzStyle={"regular"}
            >
              Buscar
            </FzButton>
          </div>
        </div>
      </FzCard>

      <FzCard.Body>
        <FzTable
          data={data}
          metas={prioritizedBidsMeta}
          context={"screening-prioritized-bids"}
          ns={ns}
          pagination
          customColumnFormatters={{
            applicationId: (cell, row, formatExtraData, idx, ns) => idColumn(cell, row, formatExtraData, idx, ns),
          }}
        />
      </FzCard.Body>
    </FzCard >
  )
}

export {PrioritizedBidsLayout}