import React, { Component } from 'react';
import MatchRange from './ranges';
import { DomainsMatch } from './domains';
import { MatchCampaigns } from './campaigns';
import { FzRow } from '../../fz/layout/index';

import '../../../css/rules.css';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
  },
};

export class RulesBuilder extends Component {

  handleChange = (index, value) => {
    const { data, onChange } = this.props;
    let temp = data;
    if (value === false) {
      temp.splice(index, 1);
    } else {
      temp[index] = value;
    }
    onChange(temp);
  };

  render() {
    const { ns, data = [], rulesPath, products } = this.props;
    const { container } = styles;
    let content = [];

    data.map((rule, index) => {
      const { type } = rule;
      if (type === 'range') {
        return content.push(
          // <FzRow key={`range-${rulesPath}-${Math.random()}`}>
            <MatchRange
              data={rule}
              rulesPath={rulesPath}
              onChange={(value) => this.handleChange(index, value)}
              index={index}
              key={'match-range-' + index}/>
          // </FzRow>,
        );
      } else if (type === 'campaigns') {
        return content.push(
          // <FzRow key={`campaign-${rulesPath}-${Math.random()}`}>
            <MatchCampaigns
              data={rule}
              rulesPath={rulesPath}
              onChange={(value) => this.handleChange(index, value)}
              index={index}
              ns={ns}
              key={'match-campaign-' + index}/>
          // </FzRow>
        );
      } else {
        return content.push(
          // <FzRow key={`domain-${rulesPath}-${Math.random()}`}>
            <DomainsMatch
              data={rule}
              rulesPath={rulesPath}
              onChange={(value) => this.handleChange(index, value)}
              index={index}
              ns={ns}
              key={'match-domain-' + index}/>
          // </FzRow>,
        );
      }
    });

    return (
      <div key={`rules-builder-container-${Math.random()}`} style={container}>
        {content}
      </div>
    );
  }
}