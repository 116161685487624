import {
  InfoType,
  ModalReprocess,
  ReprocessMultiplePayloadType,
  StateType,
  ToastType,
  toastTypes,
  UserType,
} from '../../../biz/metadatas/reprocess-multiple-metadata';
import FieldConnected from '../../components/form/field-connected';
import { postReprocessMultipleQueues } from '../../../api/integrations';
import { FzModal } from '../../fz/fz-modal';
import { useState } from 'react';
import { FzToast } from '../../fz/form/notification/toast';

export async function ReprocessMultiple(
  user: UserType,
  info: InfoType,
  queues: string[],
  applicationIds: string[] | undefined
): Promise<string> {
  if (!info.queueInternalName && !info.includeQueuesInternalName) {
    console.error({ error: 'missing params', info });
    throw {};
  }

  const payload: ReprocessMultiplePayloadType = {
    userId: user.id,
    userName: user.name,
    userEmail: user.email,
    queuesId: queues,
    applications: applicationIds,
    query: {
      dateFrom: info.createdAfter,
      dateTo: info.createdBefore,
      supplierInternalName: info.supplierInternalName,
      ...(info.timeout && { timeouted: Boolean(info.timeout) }),
      ...(info.canceled && { cancelled: Boolean(info.canceled) }),
      ...(info.queueInternalName && { queue: info.queueInternalName }),
      ...(info.includeQueuesInternalName && {
        includedQueues: info.includeQueuesInternalName,
      }),
      ...(info.excludeQueuesInternalName && {
        excludedQueues: info.excludeQueuesInternalName,
      }),
    },
    funnelStage: info.funnelStage,
    cardId: info.cardId,
  };
  const reprocess = await postReprocessMultipleQueues(payload);

  return reprocess.data.id;
}

function handleToast(
  type: string,
  redirect: string,
  setShowToast: StateType,
  setToastInfo: StateType
) {
  const toast = toastTypes[type];
  let bodyMessageTost = toast.messageTost;
  if (redirect !== '') {
    bodyMessageTost = (
      <>
        {toast.messageTost}{' '}
        <a href={`/manager/reprocessing/${redirect}`}>aqui.</a>
      </>
    );
  }
  setToastInfo({ bodyMessageTost, ...toast });
  setShowToast(true);
}

async function handleReprocessButton(
  ns: any,
  user: UserType,
  setShowToast: StateType,
  setToastInfo: StateType
) {
  const queues = ns.get('queueSimple') || [];
  const appIds: Array<string> = [];
  const treatedQueues = queues
    .filter((queue: { active: boolean }) => queue.active === false)
    .map((queue: { _id: string; applicationId: string; }) => {
      appIds.push(queue.applicationId);
      return queue._id;
    });
  const info = ns.getChanged();

  if (
    !info.cardId ||
    info.cardId === '' ||
    !info.funnelStage ||
    info.funnelStage === ''
  ) {
    handleToast('error', '', setShowToast, setToastInfo);
  } else {
    try {
      const success = await ReprocessMultiple(user, info, treatedQueues, appIds);
      handleToast('success', success, setShowToast, setToastInfo);
      ns.unset('CHANGED.cardId');
      ns.unset('CHANGED.funnelStage');
    } catch (error) {
      handleToast('default', '', setShowToast, setToastInfo);
    }
  }
}

export function ReprocessMultipleModal({ ns, user, disabled, data }) {
  const [showToast, setShowToast] = useState(false);
  const [toastInfo, setToastInfo] = useState<ToastType>({
    bodyMessageTost: toastTypes['default'].messageTost,
    ...toastTypes['default'],
  });

  return (
    <>
      <FzModal
        headerTitle={'Reprocessamento'}
        containerStyle={{ marginTop: '50px' }}
        successText="Reprocessar"
        showButtonText="Reprocessar todas"
        handleSuccessButton={() =>
          handleReprocessButton(ns, user, setShowToast, setToastInfo)
        }
        cancelText="Cancelar"
        fzStyle="primary"
        buttonDisabled={disabled}
      >
        <FieldConnected data={data} meta={ModalReprocess['CardId']} ns={ns} />
        <FieldConnected
          data={data}
          meta={ModalReprocess['FunnelStage']}
          ns={ns}
        />
        <p>
          Usuário logado:{' '}
          <b>
            {user.name} ({user.email})
          </b>
        </p>
      </FzModal>

      <FzToast
        {...toastInfo}
        close={() => setShowToast(false)}
        show={showToast}
      />
    </>
  );
}
