import React, { Component } from 'react';
import { Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import Field from '../../../components/field';
import api from '../../../api/client-api';

import { FzCard, FzCol, FzRow } from '../../fz/layout/index'
import { FzTable } from '../../fz/grid/table'
import { FzIcons } from '../../fz/form/icon';
import { FilterProductsSupplierPage } from '../../blocks/supplier/product-filters';


const metas = {
  "applicationId": {
    "path": "applicationId",
    "dataType": "id",
    "label": "Propostas",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "personalNumber": {
    "path": "personalNumber",
    "dataType": "dataType",
    "label": "CPF",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "reason": {
    "path": "reason",
    "dataType": "dataType",
    "label": "Resultado",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}

export class RejectAndPayOutApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationsCount: null, alertMessage: "", reportDisabled: true, checked: "*"
    }

    this.idColumn = this.idColumn.bind(this)
    this.handleChange = this.handleChange.bind(this)

  }


  handleChange(value) {
    this.setState({ checked: value, product: value })
  }


  normalizeCPFFilter(e) {
    let reCPF = /\d+/g
    let cpfs = e.target.value || ""
    cpfs = cpfs.replace(/\.|-/g, "");
    let out = []
    let result

    while ((result = reCPF.exec(cpfs)) !== null) {
      let x = "00000000000" + result[0]
      out.push(x.slice(-11))
    }

    let ns = this.props.ns
    ns.saveChange("personalNumbers", out.join(","));
    ns.set("personalNumbersFilter", out);
    ns.set("dirty", true, -1)

    this.setState({ reportSpinner: true, reportDisabled: false })
  }

  displayProcessedTable(resp) {
    var resultS = [];
    var apps = null;
    var pns = null;
    var i = 0;
    if (resp.notProcessedApplications) {
      apps = resp.notProcessedApplications.substring(1, resp.notProcessedApplications.length - 1).split(' ');
      pns = resp.notProcessedPersonalNumbers.substring(1, resp.notProcessedPersonalNumbers.length - 1).split(' ');
      var reasons = resp.notProcessedReason.substring(1, resp.notProcessedReason.length - 1).split('|');
      for (i = 0; i < apps.length; i++) {
        if (apps[i].trim() !== "")
          resultS.push({
            "applicationId": apps[i],
            "personalNumber": pns[i],
            "reason": reasons[i]
          });
      }
    }

    if (resp.processedApplicationsList) {
      apps = resp.processedApplicationsList.substring(1, resp.processedApplicationsList.length - 1).split(' ');
      pns = resp.processedPersonalNumbersList.substring(1, resp.processedPersonalNumbersList.length - 1).split(' ');
      for (i = 0; i < apps.length; i++) {
        if (apps[i].trim() !== "")
          resultS.push({
            "applicationId": apps[i],
            "personalNumber": pns[i],
            "reason": "Sucesso"
          });
      }
    }

    this.props.ns.set("resultProcessed", resultS);
  }

  reportPayApplications() {
    this.props.ns.set("resultProcessed", []);
    let { sharedNS } = this.props
    var that = this;
    this.setState({ reportSpinner: true })
    let product = sharedNS.get("productField", "*")
    if (product === "*") {
      alert("Você precisa especificar um produto para efetuar as modificações!")
      return
    }
    let ns = this.props.ns;
    let pns = ns.get("personalNumbersFilter", []);

    if (pns.length > 500) {
      alert("Existem " + pns.length + " CPFs na lista! Limite de 500 CPFs apenas, por favor, retire os números de CPF excedentes!")
      return
    } else if (pns.length === 0) {
      alert("Pelo menos um CPF deve ser especificado!")
      return
    }
    var process = window.confirm("Esta rotina irá processar as ofertas de " + product + " para " + pns.length + " cliente(s) com o(s) CPF(s) especificado(s), modificando status como pagas, confirma?");

    if (process) {
      api.payOutApplicationsByPersonalNumbers({ personalNumbers: pns, product, infoChannel: "cof" }, function (resp) {
        if (resp && resp.length > 0) {
          that.setState({ reportSpinner: false })
          let respJ = JSON.parse(resp);
          if (respJ.updatedPersonalNumbers === 0)
            alert("Não foram encontrados registros de propostas para os CPFs procurados! Nenhuma proposta foi atualizada!");
          else
            alert("Foram atualizadas ao todo " + respJ.updatedApplications + " propostas de " + respJ.updatedPersonalNumbers + (pns.length !== respJ.updatedPersonalNumbers ? " clientes. CPFs não processados: " + respJ.notProcessedPersonalNumbers : " clientes."));

          that.displayProcessedTable(respJ);
        }
      }, function (error) {
        if (error.status === 403) {
          that.props.history.push("/login")
        }
        else if (error.status === 429) {
          console.log("-- API MSG: " + JSON.stringify(error))
          alert("Atenção! Nenhuma proposta foi atualizada! " + error.body.error)
          that.setState({ alertMessage: error.body.error, reportSpinner: false, reportDisabled: true })
        } else {
          console.warn("ApiError ****")
          that.setState({ alertMessage: "API Error: " + error.status, reportSpinner: false, reportDisabled: true })
        }
      });
    }
  }

  reportCancelBidsApplications() {
    this.props.ns.set("resultProcessed", []);

    var that = this;
    this.setState({ reportSpinner: true })
    let product = this.state.product || this.state.checked
    if (product === "*") {
      alert("Você precisa especificar um produto para efetuar as modificações!")
      return
    }
    let ns = this.props.ns;
    let pns = ns.get("personalNumbersFilter", []);

    if (pns.length > 500) {
      alert("Existem " + pns.length + " CPFs na lista! Limite de 500 CPFs apenas, por favor, retire os números de CPF excedentes!")
      return
    } else if (pns.length === 0) {
      alert("Pelo menos um CPF deve ser especificado!")
      return
    }

    var process = window.confirm("Esta rotina irá processar as ofertas de " + product + " para " + pns.length + " cliente(s) com o(s) CPF(s) especificado(s), modificando status como rejeitadas, confirma?");
    if (process) {
      api.rejectApplicationsByPersonalNumbers({ personalNumbers: pns, product }, function (resp) {
        if (resp && resp.length > 0) {
          that.setState({ reportSpinner: false })
          let respJ = JSON.parse(resp);
          if (respJ.updatedPersonalNumbers === 0)
            alert("Não foram encontrados registros de propostas para os CPFs procurados! Nenhuma proposta foi atualizada!");
          else
            alert("Foram atualizadas ao todo " + respJ.updatedApplications + " propostas de " + respJ.updatedPersonalNumbers + (pns.length !== respJ.updatedPersonalNumbers ? " clientes. CPFs não processados: " + respJ.notProcessedPersonalNumbers : " clientes."));

          that.displayProcessedTable(respJ);
        }
      }, function (error) {
        if (error.status === 403) {
          that.props.history.push("/login")
        }
        else if (error.status === 429) {
          console.log("-- API MSG: " + JSON.stringify(error))
          alert("Atenção! Nenhuma proposta foi atualizada! " + error.body.error)
          that.setState({ alertMessage: error.body.error, reportSpinner: false, reportDisabled: true })
        } else {
          console.warn("ApiError ****")
          that.setState({ alertMessage: "API Error: " + error.status, reportSpinner: false, reportDisabled: true })
        }
      });
    }
  }

  idColumn(cell, row) {
    return (<Button title={row['applicationId']} bsSize="xsmall" onClick={() => { this.props.history.push("/suppliers/" + row["applicationId"]); }}>
      < FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Visualizar Proposta
    </Button>);
  }


  render() {
    let { ns } = this.props
    let filters = ns.getChanged() || ns.get("filters")
    if (filters === undefined) {
      Promise.resolve().then(() => {
        this.props.ns.set("filters", {
        }, -1)
      })
      return (null)
    }
    if (!ns.isEditing()) {
      Promise.resolve().then(() => ns.startEditing(filters))
    }

    const header = <h2>Rejeitar ofertas</h2>
    const resultProcessed = ns.get("resultProcessed");

    return (
      <FzCard>
        <FilterProductsSupplierPage checked={this.state.checked} onChange={this.handleChange} />

        <FzCard.Heading>
          <FzCard.Title>{header}</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body >
          <Field
            cols={1}
            type="text"
            componentClass="textarea"
            path="personalNumbers"
            placeholder="lista de CPFs separados por ','"
            label="CPFs (Máximo: 500 CPFs):"
            ns={ns}
            data={filters}
            onBlur={(e) => this.normalizeCPFFilter(e)}
            {...this.props}
          />
        </FzCard.Body>

        <FzRow>
          <FzCol span={2} />
          <FzCol span={8}>
            <ButtonToolbar>
              <ButtonGroup>
                <Button bsSize="small" variant="danger" loading={this.state.reportSpinner} disabled={this.state.reportDisabled} onClick={() => this.reportCancelBidsApplications()}>Reportar ofertas como rejeitadas</Button>
              </ButtonGroup>
            </ButtonToolbar>
          </FzCol>
        </FzRow>

        <FzCard.Heading>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            data={resultProcessed}
            metas={metas}
            ns={this.props.ns}
            visibleColumns={["applicationId", "personalNumber", "reason"]}
            exportDownload={false}
            clipboard={false}
            customColumnFormatters={{
              applicationId: (cell, row) => this.idColumn(cell, row)
            }}
          />
        </FzCard.Body>
      </FzCard>

    );
  }
}
