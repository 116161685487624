import React from 'react';
import Field from '../components/field';
import { FzCard } from '../ui/fz/layout/index'

const FilterDetails = ({ns, channel, index, readOnly, message, code, persistChanges, ...props}) => {

    if (!readOnly && persistChanges === undefined) {
        readOnly = true;
    }

    return (
      <FzCard onClick={(e)=>{e.stopPropagation()}}>
        <FzCard.Body>
          <Field
            type="string"
            cols={3}
            path={"channels["+code+"].filters["+index+"].eval"} //path={"filters["+index+"].eval"}
            label="Expressão"
            ns={ns}
            data={message} //data={channel}
          />
        </FzCard.Body>
      </FzCard>
    );
}

export default FilterDetails;
