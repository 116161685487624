import FieldConnected from "../../../ui/components/form/field-connected"
import './style.css'
import { FilterQueues } from "./FiltersQueue"

let count = 0
export const QueueDetailsContainer = ({
  ns,
  history,
  data,
  saveQueue,
  changeDelayFormat,
  meta,
  addNewBranch,
  clearBranches,
  clearBranchesByRuleChange,
  removeBranch,
  loading
}) => {
  const handleCancel = () => {
    count = 0
    history.push('/hermes/queues')
  }

  if(data && meta){
    return (
      <div style={{padding: "20px"}}>
        <button className="btn btn-primary" disabled={loading} onClick={saveQueue}>Salvar</button>
        <button className="btn btn-default ml-2" disabled={loading} onClick={handleCancel}>Cancelar</button>
        <div className="row queue-details-fields mt-2">
          <div className="col-3">
            <FieldConnected
              meta={meta['queueName']}
              data={data}
              ns={ns}
            />
          </div>
          <div className="col-3">
            <label className="form-label">Delay de entrada do cliente</label>
            <div style={{display: 'flex'}}>
              <div className="form-group">
                <select name="currentQueue.delaySelect" placeholder="Selecione" 
                onChange={(e) => changeDelayFormat(e.target.value)} 
                disabled={!data.currentQueue.delay}
                defaultValue='segundos'
                className="form-control">
                  <option value="">Selecione ...</option>
                  <option value="horas">Horas</option>
                  <option value="minutos">Minutos</option>
                  <option value="segundos">Segundos</option>
                </select>
              </div>
              <FieldConnected
                meta={meta['delay']}
                data={data}
                ns={ns}
              />
            </div>
          </div>
          {/* <div className="col-3">
            <FieldConnected
              meta={meta['validade']}
              data={data}
              ns={ns}
            />
          </div> */}
          <div className="col-3">
            <FieldConnected
              meta={meta['cadencia']}
              data={data}
              ns={ns}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className="queue-details-subtitle">Regras</label>
          </div>
        </div>
        <div className="rules-fields mb-2">
          <div className="row queue-details-fields">
            <div className="col-4">
              <FieldConnected
                meta={meta['products']}
                data={data}
                ns={ns}
              />
            </div>
            <div className="col-4">
              <FieldConnected
                meta={meta['suppliers']}
                data={data}
                ns={ns}
                extraChangeEffect={clearBranches}
              />
            </div>
            <div className="col-4">
              <FieldConnected
                meta={meta['rule']}
                data={data}
                ns={ns}
                extraChangeEffect={clearBranchesByRuleChange}
              />
            </div>
          </div>
        </div>
        {data.currentQueue.suppliers[0].supplier && data.currentQueue.suppliers[0].rule && 
        <>
          <div className="rules-buttons mb-2">
            <div className="row">
              <div className="col-4">
                <div className="rules-button-label">
                  Branch
                </div>
                <button disabled={!data.currentQueue.suppliers[0].supplier} onClick={() => addNewBranch()} className="btn btn-primary">+</button>
              </div>
            </div>
          </div>
          <div className="rules-fields mb-2">
            <div className="row queue-details-fields">
              {data.currentQueue.suppliers && data.currentQueue.suppliers[0].branches.map((i, idx) => {
                const fieldMeta = JSON.parse(JSON.stringify(meta['branch']))
                fieldMeta.isMandatory = () => true
                fieldMeta.isReadOnly = () => false
                fieldMeta.path = `currentQueue.suppliers[0].branches[${idx}]`
                return (
                  <div key={idx} className="col-4">
                    <div className="flex-col">
                      <FieldConnected
                        meta={fieldMeta}
                        data={data}
                        ns={ns}
                      />
                      <button 
                        disabled={data.currentQueue.suppliers[0].branches.length < 2} 
                        onClick={() => removeBranch(idx)}
                        className="btn btn-danger">-
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>}
        <div className="rules-fields mb-2">
          <div className="row queue-details-fields">
            <div className="col-4">
              <FieldConnected
                meta={meta['orderBy']}
                data={data}
                ns={ns}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className="queue-details-subtitle">Gatilhos de entrada</label>
          </div>
        </div>
        <div className="events-fields mb-2">
          <div className="row queue-details-fields">
            <div className="col-4">
              <FieldConnected
                meta={meta['events']}
                data={data}
                ns={ns}
              />
            </div>
          </div>
        </div>
        <div className="rules-container">
          <FilterQueues count={count} ns={ns} data={data} />
        </div>
      </div>
    )
  }
  return null
}