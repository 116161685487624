import React, { ReactNode, ReactElement } from 'react';
import { Accordion, AccordionCollapseProps, AccordionProps, AccordionToggleProps  } from 'react-bootstrap';

interface FzAccordionProps extends AccordionProps{
  children: ReactNode;
  activeKey?: string;
  defaultActiveKey?: string;
  onSelects?: (e) => void;
}

export function FzAccordion ({ children , activeKey, defaultActiveKey, onSelects, }: FzAccordionProps){
  return (
    <Accordion defaultActiveKey={defaultActiveKey} activeKey={activeKey} onSelect={onSelects} >
      {children}
    </Accordion>
  )
}

interface ToggleProps extends AccordionToggleProps {
  eventKey: string;
  fzStyle?: any;
  FzElementType?: any;
  children: ReactNode;
  onClick?: () => void;
}

FzAccordion.Toggle = ({ FzElementType, fzStyle, eventKey, children, onClick }: ToggleProps) => {
  return (
    <Accordion.Toggle as={FzElementType} eventKey={eventKey} style={fzStyle} onClick={onClick} >
      {children}
    </Accordion.Toggle>
  )
}

interface FzAccordionCollapseProps extends AccordionCollapseProps {
  children: ReactElement;
  eventKey: string;
  className?: string;
}

FzAccordion.Collapse = ({ children, eventKey, className }: FzAccordionCollapseProps) => {
  return (
    <Accordion.Collapse eventKey={eventKey} className={className}>
      {children}
    </Accordion.Collapse>
  )
}