export const blocklistCustomPlaceholder = (docType: string) => {
  let placeholderForDocType = 'Digite ...';

  if (docType && docType === 'personalnumber') {
    placeholderForDocType = 'Digite o número do CPF';
  }
  else if (docType && docType === 'email') {
    placeholderForDocType = 'Digite o email';
  }
  else if (docType && docType === 'telephone') {
    placeholderForDocType = 'Digite o número do telefone';
  } 
  else if (docType) {
    placeholderForDocType = 'Digite o número do documento';
  }

  return placeholderForDocType;
};


export const optionsType = [
  { description: 'Email', code: 'email' },
  { description: 'Telefone', code: 'telephone' },
  { description: 'Telefone', code: 'telefone' },
  { description: 'CPF', code: 'personalnumber' },
  { description: 'CPF', code: 'cpf' },
  { description: 'RG', code: 'rg' },
  { description: 'CNH', code: 'driver' },
  { description: 'CNH', code: 'CNH' },
  { description: 'Carteira de Conselho Profissional', code: 'professionalcouncil' },
  { description: 'RNE', code: 'rne' },
  { description: 'Passaporte', code: 'passport' },
  { description: 'Passaporte', code: 'passaporte' },
  { description: 'Outro documento de identidade', code: 'id' },
];

export const selectDocTypeBlocklist = () => {
  const selectType = optionsType.map((opt, index) => {
    return (
      <option key={index} value={opt.code}>
        {opt.description}
      </option>
    );
  });

  return selectType
}

export const getDescriptionType = (cell: string, row: any): string => {
  if (cell === 'docid') {
    return optionsType.find( it => it.code === row.doctype)?.description ?? "Não encontrado";
  }
  return optionsType.find( it => it.code === cell)?.description ?? "Não encontrado";
}

export const toastDefaultConfig = {
  fzStyle: 'danger',
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  show: false,
  delay: 1000,
  autoHide: false,
  close: () => null,
};

export const toastMessages = {
  getSearch: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro: Buscar cliente',
      bodyMessageTost:
        'Cliente não encontrado. Confira se digitou tudo corretamente.',
    },
  },
  insert: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro ao inserir',
      bodyMessageTost:
        'Preencha os campos obrigatórios para inserir uma linha.',
    },
    success: {
      fzStyle: 'success',
      headerMessageTost: 'Blocklist',
      bodyMessageTost: 'Linha inserida com sucesso.',
    },
  },
  get: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro: Blocklist',
      bodyMessageTost: 'Não foi possível buscar blocklist',
    },
  },
  import: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro: Blocklist',
      bodyMessageTost:
        'Não foi possível importar todos blocklist, alguma linha pode está errada',
    },
    success: {
      fzStyle: 'success',
      headerMessageTost: 'Blocklist',
      bodyMessageTost: 'Arquivo inserido com sucesso'
    },
  },
  update: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro ao atualizar',
      bodyMessageTost: 'Não foi possível atualizar esse campo, tente novamente',
    },
    success: {
      fzStyle: 'success',
      headerMessageTost: 'Dado atualizada com sucesso',
      bodyMessageTost: 'Está o dado está atualizada a partir de agora',
    },
  },
};