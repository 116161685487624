import React, { Component, useEffect } from 'react';
import { CampaignsModals } from '../../../api/constants';
import { FzCard, FzCol, FzJumbotron, FzRow } from '../../fz/layout/index';
import { FzButton, FzField } from '../../fz/form';
import { RulesBuilder } from '../../components/match/rules-builder';
import BlockUi from 'react-block-ui';
import { getDomain, getSuppliers } from '../../../scripts/utils/cache-helpers';
import { initPresetCampaign } from '../../../api/campaigns';
import { getAllRules } from "../../../api/client-messages";
import { getAllProducts } from '../../../api/applications';
import { partnerMeta } from '../../../biz/metadatas/campaign_info';

const styles = {
  cardTitle: {
    paddingLeft: '12px',
  },
  fieldContainer: {
    width: '100%',
    padding: '16px',
  },
  fieldTitle: {
    marginBottom: '10px',
  },
  fields: {
    marginBottom: '20px',
  },
  field: {
    flexGrow: 1,
  },
  sectionTitle: {
    paddingLeft: '16px',
    margin: '3px',
  },
  sectionDescription: {
    marginLeft: '18px',
    opacity: 0.6,
  },
  sectionContainer: {
    padding: '16px 0',
    backgroundImage: 'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
    margin: '10px',
    border: 'solid 1px #d8d8d8',
    borderRadius: '4px',
    width: '100%',
  },
  headerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonSpacement: {
    marginRight: '10px !important',
    flex: 50
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexGrow: 1,
    padding: '0 10px',
  },
  countPreview: {
    padding: '10px',
    border: 'solid 1px #eeeeee',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
  },
};

export class CampaignsInfo extends Component {
  state = {
    version: 0,
    lastSearchVersion: 0,
    applications: [],
    blocking: false,
    hasText: false,
    notificationEventsOptions: [],
    hasVerified: false,
    isScheduled: false,
    scheduleDate: undefined,
    products: [],
    activeFinanvc: false,
    nameValue: undefined,
    validationMessage: {
      name: {
        isInvalid: false,
        currValidationMessage: 0,
        texts: [
          "Campo inválido",
          "Não é permitido número no começo do texto!",
          "Caracteres especias e letras com acento não são permitidos, exceto ( _ ou -)",
          "Somente _ ou - não permitido, digite mais informação como este exemplo: _teste",
        ]
      }
    }
  };

  async componentDidMount() {
    const { ns, api } = this.props;

    getSuppliers(ns);
    getDomain(ns);

    const productsResp = await getAllProducts();
    this.setState({ products: productsResp })

    try {
      const targetsExceptions = await api.Get(`/rules`);
      ns.set('targetsExceptions', targetsExceptions);
      this.loadMercurioEvents();
    } catch (e) {
      console.error(e);
    }
  }

  async loadMercurioEvents() {
    const eventsRes = await getAllRules();
    this.setState({
      notificationEventsOptions: eventsRes.map((it) => ({
        code: it.event,
        description: it.event,
      })),
    });
  }

  sumVersion = () => {
    const { version } = this.state;
    return new Promise((resolve) => {
      resolve(this.setState({ version: version + 1 }));
    });
  };

  block = () => {
    return new Promise((resolve) => {
      resolve(this.setState({ blocking: true }));
    });
  };

  unblock = () => {
    return new Promise((resolve) => {
      resolve(this.setState({ blocking: false }));
    });
  };

  handleScheduleDateChange = (value) => {
    this.setState({ scheduleDate: value === '' ? undefined : value });
  };

  handleAdd = async (event, ruleType, type) => {
    const { ns } = this.props;
    await this.sumVersion();
    ns.push(`campaign.${ruleType}`, { type: type });
  };

  handleChange = async (ruleType, value) => {
    const { ns } = this.props;
    await this.sumVersion();
    ns.set(`campaign.${ruleType}`, value);

    this.setState({ hasVerified: false });
  };

  handleNameChange = async (newName) => {
    this.setState({ nameValue: newName });
    const validationState = this.state.validationMessage;
    if (!/^[_\-]*$/.test(newName)) {
      if (/^[a-zA-Z_]{1}[a-zA-Z_\-0-9]*$/.test(newName)) {
        const { ns } = this.props;
        if (newName.length > 0) {
          this.setState({ hasText: true });
        } else {
          this.setState({ hasText: false });
        }
        await this.sumVersion();
        const target = ns.get("targetsExceptions", []).find((el) => el.name == newName);

        if (target) {
          alert(`Esse nome sistêmico "${newName}" já existe, tente outra por favor.`)
          ns.set(`campaign`, target);
        } else {
          ns.set(`campaign.name`, newName);
        }
        validationState.name.isInvalid = false;
      } else {
        validationState.name.isInvalid = true;
        if (!/^[0-9]{1}/.test(newName) && !/^[a-zA-Z_\-0-9]*$/.test(newName)) {
          validationState.name.currValidationMessage = 2;
        } else {
          validationState.name.currValidationMessage = 1;
        }
      }
    } else {
      validationState.name.isInvalid = true;
      validationState.name.currValidationMessage = 3;
    }


    this.setState({ validationMessage: validationState })
  };



  handleSubjectChange = async (newSubject) => {
    const { ns } = this.props;
    await this.sumVersion();
    ns.set(`campaign.subject`, newSubject);
  };

  handleNotificationEventChange = async (notificationEvent) => {
    const { ns } = this.props;
    ns.set(
      'campaign.notificationEvent',
      notificationEvent === 'undefined' ? undefined : notificationEvent
    );

    this.setState({ hasVerified: false });
  };

  handleFilterNameChange = async (filterNameChange) => {
    const { ns } = this.props;
    ns.set(
      'campaign.description',
      filterNameChange === 'undefined' ? undefined : filterNameChange
    );
    this.setState({ hasVerified: false });
  };

  handleSelectTargetException = async (targetExceptionName) => {
    const { ns } = this.props;
    await this.sumVersion();
    const preset = ns
      .get('targetsExceptions', [])
      .find((el) => el.name === targetExceptionName);
    ns.set('campaign', preset);
    ns.set('campaignBackup', preset);

    this.setState({ hasVerified: false });
  };

  handleSearch = async (firstRun) => {
    const { ns, api } = this.props;
    const { version } = this.state;

    this.setState({ lastSearchVersion: version }, async () => {
      await this.block();
      const campaign = ns.get('campaign') || {}
      const deepCopy = JSON.parse(JSON.stringify(campaign))
      const normalizedCampaign = this.normalizePreviewReqPayload(deepCopy)
      const applicationsFilter = await api.Post(
        `/campaigns/rules/preview`,
        normalizedCampaign
      );
      const { applications, total, exceptionCount } = applicationsFilter;
      this.setState(
        { applications, count: total, exceptionCount, hasVerified: true },
        async () => {
          await this.unblock();
        }
      );
    });

    if (typeof firstRun === 'object' && ns.get('campaign.id', false)) {
      this.handleSaveTargetException();
    }
  };

  normalizePreviewReqPayload(campaign) {
    const { targets, exceptions } = campaign;
    if (targets && Array.isArray(targets) && targets.length > 0) {
      const updatedTargets = iteratesRules(targets);
      campaign.targets = updatedTargets;
    }
    if (exceptions && Array.isArray(exceptions) && exceptions.length > 0) {
      const updatedExceptions = iteratesRules(exceptions);
      campaign.exceptions = updatedExceptions;
    }
    function iteratesRules(rules) {
      return rules.map((rule) => {
        if (rule.type === "campaigns") {
          rule.type = "range"
        }
        if (rule.dataType === 'date' || rule.dataType === 'mixed') {
          rule.datefrom =
            rule.datefrom &&
            new Date(
              new Date(rule.datefrom).setUTCHours(3, 0, 0, 0)
            ).toISOString();
          rule.dateto =
            rule.dateto &&
            new Date(
              new Date(rule.dateto).setUTCHours(2, 59, 59, 59)
            ).toISOString();
        }
        return rule;
      });
    }
  
    return campaign;
  }

  handleSaveTargetException = async (operation) => {

    const {
      active,
      activeSuccess,
      update,
      updateSuccess,
      save,
      saveSuccess,
      error,
      errorTarget,
      errorDuplicate,
      cancel,
    } = CampaignsModals.handleSaveTargetException;
    let confirm, success, verifyDuplicate;

    switch (operation) {
      case 'save':
        confirm = window.confirm(save);
        success = saveSuccess;
        verifyDuplicate = true;
        break;
      case 'update':
        if (this.props.ns.get("campaignBackup.active") != this.props.ns.get("campaign.active") && this.props.ns.get("campaign.active") == "false") {
          const { question, questionSuccess } = CampaignsModals.handleDeleteTargetException
          confirm = window.confirm(question);
          success = questionSuccess;
        } else {
          confirm = window.confirm(update);
          success = updateSuccess;
        }

        verifyDuplicate = false;
        break;
      case 'active':
        confirm = window.confirm(active);
        success = activeSuccess;
        verifyDuplicate = false;
        break;
      default:
        confirm = true;
        success = '';
        verifyDuplicate = false;
        break;
    }
    const { ns, api } = this.props;
    let nsCampaign = ns.get('campaign');
    nsCampaign.active = (nsCampaign.active == "true" || typeof nsCampaign.active == "boolean" && nsCampaign.active) ? true : false;
    if (
      ns.get('campaign.name', false) &&
      ns.get('campaign.targets.length', 0) > 0
    ) {
      try {
        await this.block();
        if (confirm) {
          try {
            const targetsExceptions = await api.Post(
              `/rules/${ns.get('campaign.id', '')}`,
              nsCampaign
            );
            ns.set('targetsExceptions', targetsExceptions);
            alert(success);
          } catch (error) {
            ns.set("campaign", ns.get("campaignBackup"))
            alert(`${error}`);
          }
        } else {
          ns.set("campaign", ns.get("campaignBackup"))
          alert(cancel);
        }
        await this.unblock();

      } catch (e) {
        ns.set("campaign", ns.get("campaignBackup"))
        alert(e);
        throw e;
      }
    }
  };


  handleActiveTargetException = (isActive) => {
    this.props.ns.set("campaign.active", isActive)
  }

  handleCreateCampaign = async () => {
    const { ns } = this.props;
    const { question, success, error, cancel } =
      CampaignsModals.handleCreateCampaign;
    if (!ns.get('campaign.notificationEvent')) {
      return alert(
        'Necessário escolher nome da notificação e Atualizar Filtro'
      );
    }
    const confirm = window.confirm(question);
    if (confirm) {
      try {
        await this.block();
        const mustNotify =
          this.state.scheduleDate === '' ||
          this.state.scheduleDate === undefined;
        const response = await initPresetCampaign(
          ns.get('campaign.name'),
          30,
          !mustNotify,
          false,
          false,
          this.state.scheduleDate,
          ns.get('campaign.subject'),
          this.state.activeFinanvc
        );

        alert(success + JSON.stringify(response));

        await this.unblock();
      } catch (e) {
        alert(error);
        await this.unblock();
        throw e;
      }
    } else {
      alert(cancel);
    }
  };

  render() {
    const {
      cardTitle,
      fieldContainer,
      fieldTitle,
      fields,
      field,
      buttonSpacement,
      countPreview,
    } = styles;
    const { ns } = this.props;
    const {
      blocking,
      version,
      lastSearchVersion,
      count,
      hasText,
      exceptionCount,
    } = this.state;
    const targetsExceptions = ns.get('targetsExceptions', []);
    const targetsExceptionsOptionsActive = targetsExceptions
      .filter((te) => !te.campaignid)
      .map((el) => {
        if (el.active) {
          return ({
            code: el.name,
            description: `${el.description}`,
          })
        }
      }).filter(Boolean);

    const targetsExceptionsOptionsInactive = targetsExceptions
      .filter((te) => !te.campaignid)
      .map((el) => {
        if (!el.active) {
          return ({
            code: el.name,
            description: `${el.description} (Inativo)`,
          })
        }
      }).filter(Boolean);

    targetsExceptionsOptionsActive.sort(function (a, b) {
      return a.description < b.description ? -1 : a.description > b.description ? 1 : 0;
    });
    targetsExceptionsOptionsInactive.sort(function (a, b) {
      return a.description < b.description ? -1 : a.description > b.description ? 1 : 0;
    });

    const targetsExceptionsOptions = [...targetsExceptionsOptionsActive, ...targetsExceptionsOptionsInactive];

    const selectProps = ns.get('campaign.name') && { value: ns.get('campaign.name') }

    const filterOptions = [
      <FzButton
        fzStyle={version === lastSearchVersion ? 'regular' : 'attention'}
        customStyle={buttonSpacement}
        onClick={() => this.handleSaveTargetException('update')}
      >
        Atualizar Filtro
      </FzButton>
    ];

    const newFilterOptions = [
      <FzButton
        fzStyle={'regular'}
        onClick={() => this.handleSaveTargetException('save')}
      >
        Salvar Filtro
      </FzButton>,
    ];

    return (
      <BlockUi tag="div" blocking={blocking}>
        <FzJumbotron>
          <FzCard>
            <FzCard.Heading>
              <FzRow>
                <FzCol span={8}>
                  <p style={cardTitle}>Regras de envio das Campanhas</p>
                </FzCol>
              </FzRow>
            </FzCard.Heading>

            <FzCard.Body>
              <div style={fieldContainer}>
                <FzCard>
                  <FzCard.Heading>Configurações de Envio</FzCard.Heading>

                  <FzCard.Body>
                    <FzRow>
                      <FzCol span={6}>
                        <h4 style={fieldTitle}>
                          <input
                            type="checkbox"
                            value={this.state.isScheduled}
                            onChange={(value) => {
                              const currentValue = this.state.isScheduled;
                              this.setState({ isScheduled: !currentValue });
                              ns.set('campaign.scheduleDate', '');
                            }}
                          />{' '}
                          Definir data e hora de envio
                        </h4>

                        {this.state.isScheduled && (
                          <FzField
                            name={'scheduleDate'}
                            inputType={'dateTime'}
                            dataType={'text'}
                            utc={false}
                            displayTimeZone={'America/Sao_Paulo'}
                            value={ns.get('campaign.scheduleDate')}
                            onChange={(value) =>
                              this.handleScheduleDateChange(value)
                            }
                          />
                        )}
                      </FzCol>
                      <FzCol span={6}>
                        <div style={fields}>
                          <React.Fragment>
                            <h4 style={fieldTitle}>Assunto</h4>
                            <FzField
                              value={ns.get('campaign.subject')}
                              placeHolder="Se preenchido, altera o assunto da campanha."
                              name={'subject'}
                              dataType={'text'}
                              inputType={'text'}
                              onChange={this.handleSubjectChange}
                            />
                          </React.Fragment>
                        </div>
                      </FzCol>
                    </FzRow>
                    <FzRow>
                      <FzCol span={6}>
                        <h4 style={fieldTitle}>
                          <input
                            type="checkbox"
                            name={'generateFinanvc'}
                            value="true"
                            onChange={() => {
                              this.setState({ activeFinanvc: !this.state.activeFinanvc })
                            }}
                          />{' '}
                          Gerar link (finan.vc)
                        </h4>
                      </FzCol>
                    </FzRow>
                  </FzCard.Body>
                </FzCard>

                <hr />

                <FzCard>
                  <FzCard.Heading>
                    <p style={cardTitle}>Resultado de filtro</p>
                  </FzCard.Heading>
                  <FzCard.Body>
                    {this.state.hasVerified && (
                      <>
                        <FzRow>
                          {count === 0 && (
                            <FzCol span={12}>
                              <div style={countPreview}>
                                Nenhum resultado encontrado a partir dos filtros
                                propostos
                              </div>
                            </FzCol>
                          )}

                          {count > 0 && (
                            <>
                              <FzCol span={6}>
                                <div style={countPreview}>
                                  <strong>Target:</strong> Total de {count}{' '}
                                  propostas filtradas
                                </div>
                              </FzCol>
                              <FzCol span={6}>
                                <div style={countPreview}>
                                  <strong>Exception:</strong> Total de{' '}
                                  {exceptionCount} propostas filtradas
                                </div>
                              </FzCol>
                            </>
                          )}
                        </FzRow>

                        <hr />
                      </>
                    )}

                    <FzRow>
                      <FzCol>
                        <FzButton onClick={this.handleSearch}>
                          Verificar massa de envios
                        </FzButton>
                        &nbsp;
                        <FzButton
                          fzStyle={'regular'}
                          onClick={this.handleCreateCampaign}
                        >
                          Disparar campanha
                        </FzButton>
                      </FzCol>
                    </FzRow>
                  </FzCard.Body>
                </FzCard>

                <hr />

                <FzCard>
                  <FzCard.Heading>Seleção de Filtro</FzCard.Heading>
                  <FzCard.Body>
                    <FzRow>
                      <FzCol span={4}>
                        <h4 style={fieldTitle}>Filtros salvos</h4>
                        <div style={fields}>
                          <FzField
                            {...selectProps}
                            style={field}
                            name={'ranges-name'}
                            dataType={'text'}
                            inputType={'select'}
                            inputProps={{ options: targetsExceptionsOptions }}
                            onChange={this.handleSelectTargetException}
                          />
                        </div>
                      </FzCol>
                      {!ns.get('campaign.id') && (
                        <FzCol span={4}>
                          <div style={fields}>
                            <React.Fragment>
                              <FzField
                                label={<h4 style={{ marginBottom: "5px" }}>Nome do filtro</h4>}
                                value={this.state.nameValue || ns.get('campaign.name')}
                                placeHolder="Digite o nome para o filtro escolhido"
                                name={'name'}
                                dataType={'text'}
                                inputType={'text'}
                                readOnly={!!ns.get('campaign.id')}
                                onChange={this.handleNameChange}
                                invalid={this.state.validationMessage.name.isInvalid}
                                validationMessage={this.state.validationMessage.name.texts[this.state.validationMessage.name.currValidationMessage]}
                                tip={
                                  <>
                                    <b>Exemplo válido de Nome de filtro:</b> campanha_Manual-2021
                                  </>
                                }
                              />
                            </React.Fragment>
                          </div>
                        </FzCol>
                      )}
                      {
                        ns.get('campaign.description') && <FzCol span={2}>
                          <h4 style={fieldTitle}>Nome do filtro</h4>
                          <FzField
                            value={ns.get('campaign.description')}
                            placeHolder="Digite o nome para o filtro escolhido"
                            name={'campaign.description'}
                            dataType={'text'}
                            inputType={'text'}
                            inputProps={{
                              options: this.state.notificationEventsOptions,
                            }}
                            onChange={this.handleFilterNameChange}
                          />
                        </FzCol>
                      }
                      {

                        ns.get('campaign.description') && <FzCol span={2}>
                          <h4 style={fieldTitle}>Status do filtro</h4>
                          <FzField
                            style={field}
                            name={'ranges-state'}
                            dataType={'text'}
                            inputType={'select'}
                            value={ns.get("campaign.active")}
                            inputProps={{
                              options: [{
                                code: false,
                                description: "Desativado"
                              }, {
                                code: true,
                                description: "Ativado"
                              }]
                            }}
                            onChange={this.handleActiveTargetException}
                          />
                        </FzCol>
                      }
                      <FzCol span={4}>
                        <h4 style={fieldTitle}>Nome da notificação</h4>
                        <FzField
                          value={ns.get('campaign.notificationEvent')}
                          placeHolder="Escolha qual evento será disparado"
                          name={'campaign.notificationEvent'}
                          dataType={'text'}
                          inputType={'select'}
                          inputProps={{
                            options: this.state.notificationEventsOptions,
                          }}
                          onChange={this.handleNotificationEventChange}
                        />
                      </FzCol>
                      <FzCol span={12}>
                        <div className='d-flex gap-1'>
                          {ns.get('campaign.id', false) &&
                            filterOptions.map((btn) => btn)}
                          {!ns.get('campaign.id', false) &&
                            hasText &&
                            ns.get('campaign.notificationEvent') &&
                            newFilterOptions.map((btn) => btn)}
                        </div>
                      </FzCol>
                    </FzRow>
                  </FzCard.Body>
                </FzCard>

                <hr />
                  <TargetException ns={ns} products={this.state.products} entity={"campaign"} meta={partnerMeta["targets"]} />
                  <TargetException ns={ns} products={this.state.products} entity={"campaign"} meta={partnerMeta["exceptions"]} />
              </div>
            </FzCard.Body>
          </FzCard>
        </FzJumbotron>
      </BlockUi>
    );
  }
}


export const TargetException = (props) => {
  const {
    sectionTitle,
    sectionDescription,
    sectionContainer,
  } = styles;
  
  const { ns, products, entity, onChange, onAdd, meta: { path, label, tip, inputProps: {options} }} = props;

  let data = ns.isEditing() ? ns.getChanged(`${path}`) : ns.get(`${entity}.${path}`, []);

  useEffect(() => {
    data = ns.isEditing() ? ns.getChanged(`${path}`) : ns.get(`${entity}.${path}`, []);
  }, [ns.getChanged(`${path}`), ns.get(`${entity}`)])

  const handleAdd = (e, p, type) => {
    if (onAdd) {
      return onAdd(p, { type: type })
    } else if (onChange) {
      return onChange(p, { type: type }, true)
    }
    ns.push(`${entity}.${p}`, { type: type });
  }
  const handleChange = (p, value) => {
    if (onChange) {
      return onChange(p, value)
    }
    ns?.saveChange(`${p}`, value);
  }

  const types = options.map((el, key) => <FzCol key={`campaign-info-btn-col-${key}`} span={4}>
    <FzButton
      block
      className={'rule-' + el.code}
      onClick={(e) => handleAdd(e, path, el.code)}
    >
      {el.description}
    </FzButton>
  </FzCol>)


  return <FzCard key={`fz-campaign-info-te`}>
  <FzCard.Body>
    <FzRow key={`fz-rule-row1-${path}`}>
      <div style={sectionContainer} key={`fz-rule-div-${path}`}>
        <h3 style={sectionTitle} key={`fz-rule-h3-${path}`}>
          {label}
        </h3>
        <p style={sectionDescription} key={`fz-rule-p-${path}`}>
          {tip}
        </p>
      </div>
    </FzRow>
    <FzRow key={`fz-rule-row2-${path}`}>
      {types}
        </FzRow>
        <FzRow>
      <FzCol span={12}>
        <RulesBuilder
          products={products}
          data={data}
          rulesPath={`${entity}.${path}`}
          ns={ns}
          onChange={(value) =>
            handleChange(path, value)
          }
        />
      </FzCol>
    </FzRow>
    </FzCard.Body>
    </FzCard>
}
