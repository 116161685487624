import { Meta } from '../../types/metas';

export const ModalReprocess: Meta = {
  CardId: {
    path: 'cardId',
    label: 'Card ID',
    dataType: 'string',
    inputType: 'text',
    validationMessage: 'Card ID inválido',
    pattern: /^[a-zA-Z]{2,}\d{1,}$|^[a-zA-Z]{2,}-\d{1,}$/,
    normalize: (value) => {
      return normalizeCardId(value);
    },
    isMandatory: () => true,
  },
  FunnelStage: {
    path: 'funnelStage',
    domainName: 'application-state',
    label: 'Estágio do Funil',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'lead', description: 'Em cadastro' },
        { code: 'hasBids', description: 'Aguardando aceite' },
        { code: 'followUp', description: 'Em formalização' },
        { code: 'archived', description: 'Paga' },
        { code: 'closed', description: 'Encerrada' },
        { code: 'excluded', description: 'Excluída' },
      ],
    },
    normalize: (value) => {
      return value === 'undefined' ? '' : value;
    },
    isMandatory: () => true,
  },
};

function normalizeCardId(value: string) {
  if (
    /^[a-zA-Z]{2,}\d{1,}$|^[a-zA-Z]{2,}-\d{1,}$/.test(value) &&
    value.indexOf('-') === -1
  ) {
    return (
      value.substring(0, value.search(/\d/)) +
      '-' +
      value.substring(value.search(/\d/))
    ).toUpperCase();
  } else {
    return value.toUpperCase();
  }
}

export type ReprocessMultiplePayloadType = {
  userId: string;
  userName: string;
  userEmail: string;
  queuesId: string[];
  applications?: string[];
  query: {
    dateFrom: Date;
    dateTo: Date;
    supplierInternalName: string;
    timeouted?: boolean;
    cancelled?: boolean;
    queue?: string;
    includedQueues?: string[];
    excludedQueues?: string[];
  };
  funnelStage: string;
  cardId: string;
};

export type ToastType = {
  delay: number;
  autoHide: boolean;
  fzStyle: string;
  headerMessageTost: string;
  messageTost: any;
  bodyMessageTost?: string;
};

export const toastTypes = {
  default: {
    delay: 15000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Erro',
    messageTost: 'Erro interno.',
  },
  error: {
    delay: 15000,
    autoHide: true,
    fzStyle: 'danger',
    headerMessageTost: 'Erro na solicitação',
    messageTost: 'Preencha os campos obrigatórios.',
  },
  success: {
    delay: 30000,
    autoHide: true,
    fzStyle: 'success',
    headerMessageTost: 'Sucesso!',
    messageTost: 'Reprocessamento solicitado, vide: ',
  },
};

export type UserType = {
  id: string;
  name: string;
  email: string;
};

export type StateType = (arg0: any) => void;

export type InfoType = {
  createdAfter: Date;
  createdBefore: Date;
  supplierInternalName: string;
  queueInternalName: string;
  timeout: boolean;
  canceled: boolean;
  funnelStage: string;
  cardId: string;
  includeQueuesInternalName: string[];
  excludeQueuesInternalName: string[];
};
