import React from 'react';
import PropTypes from 'prop-types';
import { Namespace } from '../scripts/state/redux_ns';
import { FzTable } from '../ui/fz/grid/table';
import { FzCard, FzBadge } from '../ui/fz/layout/index'

const SuppliersUsers = ({ ns, supplierUsers, userIds, checkedUsers, readOnly, ...props }) => {

  const metas = {
    "id": {
      "path": "id",
      "dataType": "text",
      "label": "Id",
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "12ch",
        "filter": { type: 'TextFilter', delay: 500 },
        "dataSort": true,
        "dataAlign": "center",
        "columnFormat": null,
        "isKey": true,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "tip": null
    },
    "name": {
      "path": "name",
      "dataType": "text",
      "label": "Nome",
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "12ch",
        "filter": { type: 'TextFilter', delay: 500 },
        "dataSort": true,
        "dataAlign": "center",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "tip": null
    },
    "email": {
      "path": "email",
      "dataType": "text",
      "label": "E-mail",
      "tableProps": {
        "viewsTable": "",
        "hidden": null,
        "shortLabel": null,
        "width": "12ch",
        "filter": { type: 'TextFilter', delay: 500 },
        "dataSort": true,
        "dataAlign": "center",
        "columnFormat": null,
        "isKey": null,
        "exportFormat": null,
        "rowSpan": null,
        "sortFunc": null
      },
      "tip": null
    },
  }

  if (!supplierUsers) {
    return null
  }

  const onRowSelect = ({ id }, isSelected) => {
    if (isSelected) {
      userIds.push(id);
    } else {
      for (var i = 0; i < userIds.length; i++) {
        if (userIds[i] === id) {
          userIds.splice(i, 1);
          break;
        }
      }
    }
    ns.saveChange("users", userIds);
  }

  const selectRowProp = {
    multiple: "checkbox",
    clickToExpand: true,
    onSelect: onRowSelect,
    selectedKeys: userIds
  };

  let nbCheckedUser = 0
  if (checkedUsers) nbCheckedUser = checkedUsers.length

  if (readOnly) {
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Usuários associados</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzBadge>{nbCheckedUser}</FzBadge>
          <FzTable
            data={checkedUsers}
            metas={metas}
            ns={ns}
            exportDownload={false}
            clipboard={false}
          />
        </FzCard.Body>
      </FzCard>
    )
  } else {
    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Usuários associados</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzBadge>{supplierUsers.length}</FzBadge>
          <FzTable
            data={supplierUsers}
            metas={metas}
            ns={ns}
            rowSelection={selectRowProp}
            exportDownload={false}
            clipboard={false}
        />
        </FzCard.Body>
      </FzCard>
    )
  }
}
SuppliersUsers.propTypes = {
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }).isRequired,
};

export default SuppliersUsers;