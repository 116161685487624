import React from 'react';
import { FzCard } from '../../fz/layout/index'
import { FzTable } from '../../fz/grid/table'
import { affiliateSummaryApi } from '../../../biz/metadatas/affiliate'
import { StatsDetailHeader } from '../../../statistics/stats-components';



export class AffiliateSummariesApi extends React.Component<any, any>  {

  constructor(props) {
    super(props);
  }

  render() {
    const { data, ns } = this.props

    if (!data) {
      return null
    }


    return (

      <FzCard>
        <FzCard.Body>
          <FzCard>
            <FzCard.Heading  fzStyle={{}}>
              <FzCard.Title> <StatsDetailHeader stats={data} title={"Affiliate API"} periods={null} currPeriod={null} onChange={null} /> </FzCard.Title>
            </FzCard.Heading>
            <FzTable
              ns={ns}
              metas={affiliateSummaryApi}
              data={data.statistics}
              visibleColumns={[
                "_id",
                "period",
                "transaction",
                "invalid",
                "rejected",
                "filtered",
                "started",
                "affiliate",
                "total"
              ]}
            />

          </FzCard>
        </FzCard.Body>
      </FzCard>

    )
  }
}