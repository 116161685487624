import moment from 'moment'

import { Meta } from "../../types/metas";

export let affiliateSummary: Meta = {
  "_id": {
    "path": "_id",
    "label": "id",
    "dataType": "id",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": true,
    },

    "isMandatory": (application) => false,
  },
  "period": {
    "path": "period",
    "label": "period",
    "tableProps": {
      "width": "7ch",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
    },
    "isMandatory": (application) => false,
  },
  "leads": {
    "path": "leads",
    "label": "Leads",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },
  "completed": {
    "path": "completed",
    "label": "Completed",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },
  "approvals": {
    "path": "approvals",
    "label": "Approvals",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },
  "accepted": {
    "path": "accepted",
    "label": "Accepted",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },
  "paid": {
    "path": "paid",
    "label": "Paid",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },
  "affiliateCommission": {
    "path": "affiliateCommission",
    "label": "Preliminary Comission",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },
  "affiliate": {
    "path": "affiliate",
    "label": "affiliate",
    "tableProps": {
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": false,
    },
    "isMandatory": (application) => false,
  },
  "key": {
    "path": "key",
    "label": "key",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "hidden": true
    },
    "isMandatory": (application) => false,
  },
}

export let affiliateSummaryCampaign: Meta = {
  "_id": {
    "path": "_id",
    "label": "id",
    "dataType": "id",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": true,
    },
    "isMandatory": (application) => false,
  },
  "period": {
    "path": "period",
    "label": "Period",
    "dataType": "date",
    "tableProps": {
      "width": "7ch",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodWithTimeColumnTz",
    },
    "isMandatory": (application) => false,
  },
  "utm_campaign": {
    "path": "utm_campaign",
    "label": "utm_campaign",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": { "type": 'SelectFilter', "options": "buildFilterUtmCampaign" },
    },
    "isMandatory": (application) => false,
  },
  "utm_content": {
    "path": "utm_content",
    "label": "utm_content",
    "dataType": "string",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "center",
      "filter": { "type": 'SelectFilter', "options": "buildFilterUtmContent" },
    },
    "isMandatory": (application) => false,
  },
  "leads": {
    "path": "leads",
    "label": "Leads",
    "dataType": "number",
    "tableProps": {
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "completed": {
    "path": "completed",
    "label": "Completed",
    "dataType": "number",
    "tableProps": {
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "approvals": {
    "path": "approvals",
    "label": "Approvals",
    "dataType": "number",
    "tableProps": {
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "accepted": {
    "path": "accepted",
    "label": "Accepted",
    "dataType": "number",
    "tableProps": {
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "paid": {
    "path": "paid",
    "label": "Paid",
    "dataType": "number",
    "tableProps": {
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "affiliateCommission": {
    "path": "affiliateCommission",
    "label": "Preliminary Comission",
    "dataType": "number",
    "tableProps": {
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": false,
      "columnFormat": "numberColumn",
    },
    "isMandatory": (application) => false,
  },


}

export let affiliateAnalytics: Meta = {
  "id": {
    "path": "id",
    "label": "App id",
    "dataType": "id",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "shortLabel": undefined,
      "width": "10ch",
      "dataSort": true,
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "affiliateName": {
    "path": "affiliateName",
    "label": "Afiliado",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "shortLabel": undefined,
      "width": "5ch",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": undefined,
      "exportFormat": undefined,
      "rowSpan": undefined,
      "sortFunc": undefined
    },
    "isMandatory": (application) => false,
  },
  "affiliateId": {
    "path": "affiliateId",
    "label": "Affiliate Id",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": true,
      "width": "7ch",
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "transaction": {
    "path": "transaction",
    "label": "API",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5ch",
      "filter": { "type": 'SelectFilter', "options": "buildFilterTransaction" },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "getTransaction",
    },
    "isMandatory": (application) => false,
  },
  "created": {
    "path": "created",
    "label": "Data do Pedido",
    "dataType": "date",
    "tableProps": {
      "viewsTable": "",
      "width": "6ch",
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "dateTimeColumn",
    },
    "isMandatory": (application) => false,
  },
  "applicationId": {
    "path": "applicationId",
    "label": "App id",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5ch",
      "filter": { type: 'TextFilter', delay: 500 },
      "dataSort": false,
      "dataAlign": "center",
    },
    "isMandatory": (application) => false,
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "dataType": "number",
    "tableProps": {
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { "type": 'TextFilter', "delay": 500 },
    },
    "isMandatory": (application) => false,
  },
  "Email": {
    "path": "Email",
    "label": "Email",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "left",
    },
    "isMandatory": (application) => false,
  },
  "telephoneNumber": {
    "path": "telephoneNumber",
    "label": "Número de telefone",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5ch",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": undefined,
      "exportFormat": undefined,
      "rowSpan": undefined,
      "sortFunc": undefined
    },
    "isMandatory": (application) => false,
  },
  "invalid": {
    "path": "invalid",
    "label": "Invalida",
    "dataType": "bool",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "columnFormat": "booleanColumn"
    },
    "isMandatory": (application) => false,
  },
  "rejected": {
    "path": "rejected",
    "label": "Rejeitada",
    "dataType": "bool",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "columnFormat": "booleanColumn"
    },
    "isMandatory": (application) => false,
  },
  "filtered": {
    "path": "filtered",
    "label": "Filtrada",
    "dataType": "bool",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "columnFormat": "booleanColumn"
    },
    "isMandatory": (application) => false,
  },
  "started": {
    "path": "started",
    "label": "Iniciada",
    "dataType": "bool",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": {
        "type": "SelectFilter",
        "options": "filters.boolean"
      },
      "columnFormat": "booleanColumn"
    },
    "isMandatory": (application) => false,
  },
  "payload": {
    "path": "payload",
    "label": "payload",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5ch",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": undefined,
      "exportFormat": undefined,
      "rowSpan": undefined,
      "sortFunc": undefined
    },
    "isMandatory": (application) => false,
  },
  "rejectMessages": {
    "path": "rejectMessages",
    "label": "mensagens de erro",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "5ch",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": undefined,
      "exportFormat": undefined,
      "rowSpan": undefined,
      "sortFunc": undefined
    },
    "isMandatory": (application) => false,
  },
  "rejectLogs": {
    "path": "rejectLogs",
    "label": "rejectLogs",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "hidden": undefined,
      "shortLabel": undefined,
      "width": "7ch",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": undefined,
      "isKey": undefined,
      "exportFormat": undefined,
      "rowSpan": undefined,
      "sortFunc": undefined
    },
    "isMandatory": (application) => false,
  },
  "rejectReason": {
    "path": "rejectReason",
    "label": "Motivo da rejeição",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "left",
      "filter": { type: 'TextFilter', delay: 500 },
    },
    "isMandatory": (application) => false,
  },
}

export let affiliateSummaryApi: Meta = {
  "_id": {
    "path": "_id",
    "label": "id",
    "dataType": "id",
    "tableProps": {
      "viewsTable": "",
      "width": "2ch",
      "dataSort": true,
      "dataAlign": "right",
      "isKey": true,
    },

    "isMandatory": (application) => false,
  },
  "filtered": {
    "path": "filtered",
    "label": "Filtrada",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },

    "isMandatory": (application) => false,
  },
  "invalid": {
    "path": "invalid",
    "label": "Inválida",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "rejected": {
    "path": "rejected",
    "label": "Rejeitada",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "started": {
    "path": "started",
    "label": "Iniciada",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
    },
    "isMandatory": (application) => false,
  },
  "period": {
    "path": "period",
    "label": "Período",
    "dataType": "date",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "periodWithTimeColumnTz",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
    },
    "isMandatory": (application) => false,
  },
  "affiliate": {
    "path": "affiliate",
    "label": "Affiliate",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
      "hidden": true
    },
    "isMandatory": (application) => false,
  },
  "transaction": {
    "path": "transaction",
    "label": "api",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "",
      "width": "3ch",
      "dataSort": true,
      "dataAlign": "right",
      "filter": { "type": 'SelectFilter', "options": "buildFilterTransaction" },
    },
    "isMandatory": (application) => false,
  },
}

export let panelControlAffiliate: Meta = {
  "source": {
    "path": "source",
    "label": "Origem",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { code: "application", description: "Application" },
        { code: "api", description: "API" }
      ],
    },
    "isMandatory": (application) => true,
  },
  "typeView": {
    "path": "typeView",
    "label": "Tipo de visualização",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { code: "analytics", description: "Analytics" },
        { code: "summaries", description: "Summaries" },
        { code: "campaign", description: "Campaign" }
      ],
    },
    "isMandatory": (application) => true,
  },
  "affiliate": {
    "path": "affiliate",
    "label": "Afiliado",
    "dataType": "string",
    "inputType": "nav-dropdown",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => true,
  },
  "agregado": {
    "path": "agregado",
    "label": "Agregado por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "day", "description": "Daily" },
        { "code": "week", "description": "Weekly" },
        { "code": "month", "description": "Month" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "startDate": {
    "path": "startDate",
    "label": "Data Inicial",
    "dataType": 'moment',
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    "isMandatory": (application) => true,
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T00") : value,
  },
  "endDate": {
    "path": "endDate",
    "label": "Data Final",
    "dataType": 'moment',
    "inputType": "date",
    "inputProps": {
      "maxValue": moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    },
    normalize: (value) => typeof (value) === "object" && value !== null ? value.toISOString().replace(/T\d\d/, "T00") : value,
    "isMandatory": (application) => true,
  },
}

export const AffiliateForm = {
  "$name": "Afiliado",
  "$span": 12,
  "$namePath": "path",
  path: {
    path: 'path',
    dataType: 'text',
    label: 'Nome do Afiliado',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: (ns) => ns.isEditing() && ns.getShared("CACHE.webhook") === "new" ? false : true,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  appparamsmap: {
    path: 'appparamsmap',
    dataType: 'text',
    label: 'Mapa de Parâmetros',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  method: {
    path: 'method',
    dataType: 'text',
    label: 'Método',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  params: {
    path: 'params',
    dataType: 'text',
    label: 'Parâmetros',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  stepsmap: {
    path: 'stepsmap',
    dataType: 'text',
    label: 'Mapa de Etapas',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  steps: {
    path: 'steps',
    dataType: 'text',
    label: 'Etapas',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'multiselect',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      "options": [
        { code: 'completed', description: "Completo" },
        { code: 'new-bid', description: "Novo Bid" },
        { code: 'accepted-bid', description: "Bid aceito" },
        { code: 'paid', description: "Pago" },
        { code: 'canceled', description: "Cancelado" },
      ],
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  url: {
    path: 'url',
    dataType: 'text',
    label: 'URL',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
  urlvariables: {
    path: 'urlvariables',
    dataType: 'text',
    label: 'Variáveis de URL',
    tableProps: {
      viewsTable: '',
      hidden: undefined,
      shortLabel: undefined,
      width: '12ch',
      filter: { type: 'TextFilter', delay: 500 },
      dataSort: true,
      dataAlign: 'left',
      columnFormat: undefined,
      isKey: undefined,
      exportFormat: undefined,
      rowSpan: undefined,
      sortFunc: undefined,
    },
    inputType: 'text',
    section: 'pathnameForm',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: undefined,
      minValue: undefined,
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    relatedFields: undefined,
    validationMessage: undefined,
    pattern: undefined,
    placeholder: undefined,
    tip: undefined,
    product: undefined,
    isMandatory: value => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },
}

export const AffiliateColumns: Meta = {
  "key": {
    "path": "key",
    "label": "Afiliado",
    "tableProps": {
      "isKey": true,
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
      "filter": { "type": 'TextFilter', delay: 500 },
    },
    "isMandatory": () => false,
  },
  "linkPath": {
    "path": "linkPath",
    "label": "Ação",
    "tableProps": {
      "hidden": false,
      "width": "2rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
}
