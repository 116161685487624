import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ApplicationState, ProductName } from '../application-components/input-domains';
import { StaticDomains } from '../api/client-api';
import { APPLICATION_STATE } from '../business/application';
import { FzCard, FzRow, FzCol, FzBadge } from '../ui/fz/layout/index'
import { UserName } from '../ui/components/form/userid'
import { FzButton } from '../ui/fz/form';

const UIState = ({ uiState }) => {

  let uiStateCtrl = [(<FzBadge key="1" title="Primeira página">1</FzBadge>)]
  if (uiState.validated) {
    uiStateCtrl.push((<FzBadge key="2" title="Segunda página (validação)">2</FzBadge>))
  }
  if (uiState.formPage) {
    let page = uiState.formPage === "last" || uiState.hasCompletedApplication === "true" ? "fim" : uiState.formPage
    let bsStyle = page === "fim" ? "regular" : "discrete"
    uiStateCtrl.push((<FzBadge key="3" fzStyle={bsStyle}>{page}</FzBadge>))
  }
  if (uiState.reminderEmail) {
    let title = "Lembretes de prenchimento enviados"// + uiState.reminderEmail
    uiStateCtrl.push(<FzBadge key="e" title={title} glyph="envelope">{uiState.reminderEmail}</FzBadge>)
  }
  return (<div>
    {uiStateCtrl}
  </div>)
}


const styles = {
  marginContainer: {
    marginBottom: '1rem',
  }
};

class SystemInfo extends React.Component {

  constructor(props) {
    super(props)
    this.state = { showBrowserOsInfos: false, showDeviceInfos: false }
  }

  handleShowBrowserInfos() {
    this.setState({ showBrowserOsInfos: true })
  }

  handleShowDeviceInfo() {
    this.setState({ showDeviceInfos: true })
  }

  render() {
    const { marginContainer } = styles;


    const { api, application } = this.props;
    let updateStatus = getUpdateStatus(application);
    let attendence = getAttendence(application);
    let auctionResult = getAuctionResult(application);

    let deviceInfos = this.state.showDeviceInfos ?
      getDeviceInfos(application) : (
        <FzButton size='sm' block onClick={() => this.handleShowDeviceInfo()}>Ver Device Infos</FzButton>
      )

    let browserOsInfos = this.state.showBrowserOsInfos ?
      getBrowserOsInfos(application) : (
        <FzButton size='sm' block onClick={() => this.handleShowBrowserInfos()}>Ver Browser Os Infos</FzButton>
      )

    let bidsWeight
    let title = "Prioridade"
    if (application && application.auction && application.auction.bidsWeight) {
      bidsWeight = <span> / <FormattedNumber value={application.auction.bidsWeight} /></span>
      title += "/bids"
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title>Detalhes do Pedido</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <div style={marginContainer}>
            <FzRow>
              <FzCol>
                <strong>{title}</strong>
                <div>
                  <FormattedNumber value={application.salesPriority || 0} />
                  {bidsWeight}
                </div>
              </FzCol>
              <FzCol>
                <strong>Produto</strong>
                <ProductName product={application.product} />
              </FzCol>
            </FzRow>
          </div>
          <div style={marginContainer}>
            <FzRow>
              <FzCol>
                <strong>Criado</strong>
                <div>
                  {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(application.created))}
                </div>
              </FzCol>
              <FzCol>
                <strong >Status</strong>
                <div>
                  <ApplicationState application={application} api={api} />
                </div>
              </FzCol>
            </FzRow>
          </div>

          {updateStatus}
          {attendence}
          {auctionResult}
          <strong>ID</strong>
          <div className="dimmed">{application.id}</div>
          {deviceInfos}
          {browserOsInfos}

        </FzCard.Body>
      </FzCard>
    );
  }
}

function getUpdateStatus(application) {
  const { marginContainer } = styles;
  let updateStatus;

  if (application.applicationState === APPLICATION_STATE.Closed) {
    updateStatus =
      <div style={marginContainer}>
        <FzRow>
          <FzCol>
            <strong>
              <React.Fragment>
                Fechado por <UserName customerId={application.customerId} userId={application.closedByUserId} />
              </React.Fragment>
            </strong>
            <div>
              {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(application.closedDate))}
            </div>
            <strong>
              Preenchimento
            </strong>
            <div>
              <UIState uiState={application.uiState} />
            </div>
          </FzCol>
          <FzCol>
            <strong>
              Motivo Fechamento
            </strong>
            <div>
              {StaticDomains.getCloseReasonDescription(application.closedReason)}
            </div>
          </FzCol>
        </FzRow>
      </div>;
  } else {
    updateStatus =
      <div style={marginContainer}>
        <FzRow>
          <FzCol>
            <strong>Alterado em</strong>
            <div>
              {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(application.updated))}
            </div>
          </FzCol>
          <FzCol>
            <strong>Preenchimento</strong>
            <UIState uiState={application.uiState} />
          </FzCol>
        </FzRow>
      </div>;
  }
  return updateStatus;
}

function getDeviceInfos(application) {
  const { marginContainer } = styles;
  let deviceInfos = [];

  if ('deviceId' in application.trackingData) {
    deviceInfos.push(
      <div key={1} style={marginContainer}>
        <FzCol span={8}>
          <strong>DeviceID</strong>
          <div className="dimmed">{application.trackingData.deviceId}</div>
        </FzCol>
      </div>);
  }
  if ('isMobile' in application.trackingData) {
    if (application.trackingData.isMobile === "True") {
      deviceInfos.push(
        <div key={2} style={marginContainer}>Mobile Device</div>
      );
    } else {
      deviceInfos.push(
        <div key={3} style={marginContainer}>Fixed Device</div>
      );
    }
  }
  return (<FzRow>{deviceInfos}</FzRow>);
}

function getBrowserOsInfos(application) {
  let browserOsInfos = [];
  if ('browser' in application.trackingData) {
    browserOsInfos.push(
      <FzCol key={0} span={3}>
        <strong>Browser</strong>
        <div className="dimmed">{application.trackingData.browser}</div>
      </FzCol>
    );
  }
  if ('browserVersion' in application.trackingData) {
    browserOsInfos.push(<FzCol key={1} span={4}>
      <strong>Browser Version</strong>
      <div className="dimmed">{application.trackingData.browserVersion}</div>
    </FzCol>);
  }
  if ('os' in application.trackingData) {
    browserOsInfos.push(<FzCol key={2} span={2}>
      <strong>OS</strong>
      <div className="dimmed">{application.trackingData.os}</div>
    </FzCol>);
  }
  if ('osVersion' in application.trackingData) {
    browserOsInfos.push(<FzCol key={3} span={3}>
      <strong>OS Version</strong>
      <div className="dimmed">{application.trackingData.osVersion}</div>
    </FzCol>);
  }
  return (
    <FzRow>
      {browserOsInfos}
    </FzRow>
  );
}

function getAuctionResult(application) {
  const { marginContainer } = styles;
  if (application.applicationState !== APPLICATION_STATE.Lead && application.auction) {
    return (
      <div style={marginContainer}>
        <FzRow>
          <FzCol>
            <strong>Convertido por </strong>
            <div>
              {application.auction.startedBySelf ? "Usuário" : application.auction.startedByName} em &nbsp;
              {new Intl.DateTimeFormat('pt-BR').format(new Date(application.auction.created))}
            </div>
          </FzCol>
        </FzRow>
      </div>
    )
  }
}

function getAttendence(application) {
  const { marginContainer } = styles;
  if (application.callbackTime.slice(0, 4) !== "0001") {
    return (<div style={marginContainer}>
      <FzRow>
        <FzCol>
          <strong>Cliente agendado:</strong>
          <div>
            {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(application.callbackTime))}
          </div>
        </FzCol>

      </FzRow>
    </div>);
  }
}

export default SystemInfo;

