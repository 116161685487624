import React from 'react';
import moment from 'moment'
import { FormGroup, Button } from 'react-bootstrap';
import { FzCard } from '../ui/fz/layout/index';
import { couldntTalk, inactive} from '../api/campaigns';


const CampaignActions = ({ns, applicant, application, api, ...props}) => {

  if (!applicant || !application) {
    return null
  }

  const convertApplication = () => {
    let newURL = "https://finanzero.com.br/campaign/" + applicant.supplierInternalName + "/" + applicant.campaign  + "/" + applicant.originalApplicationId;
    let win = window.open(newURL, '_blank');
    win.focus();
  }
  const doCouldntTalk = () => {
    let postponeReason = api.getCurrentUser().name + ": Pediu para ligar mais tarde"
    couldntTalk(applicant.id, {callbackTime: moment().add(4,'hour'), postponeReason: postponeReason})
    .then(() => props.history.push("/sales/out/") )
  }
  const callTomorrow = () => {

    let postponeReason = api.getCurrentUser().name + ": Pediu para ligar amanhã"
    couldntTalk(applicant.id, {callbackTime: moment().add(23,'hour'), postponeReason: postponeReason})
    .then(() => props.history.push("/sales/out/") )
  }
  const invalidPhoneNumber = () => {

    let postponeReason = api.getCurrentUser().name + ": Telefone inválido"
    let callbackTime = moment(applicant.endDate).add(1,'day')
    couldntTalk(applicant.id, {callbackTime: callbackTime, postponeReason: postponeReason})
    .then(() => props.history.push("/sales/out/") )
  }
  const inactiveCampaign = () => {
    inactive(applicant.id)
    .then(() => props.history.push("/sales/out/") )
  }
  let convertedApp
  if (applicant.newApplicationId) {
        convertedApp = <Button
        variant="default"
        onClick={ () =>   props.history.push("/sales/" + applicant.newApplicationId) }
        block
        >Ver proposta Convertida
      </Button>
  }
  let active = applicant.active && moment(applicant.endDate) > moment()

  return (
    <FzCard>
      <FzCard.Heading>
        <FzCard.Title componentClass="h3">Ações</FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <FormGroup>
          <Button
            variant="default"
            onClick={() =>   props.history.push("/sales/"+application.id)}
            block
            >Ver proposta Original
          </Button>
          {convertedApp}
          <Button
            variant="success"
            onClick={convertApplication}
            disabled={!active}
            block
            >Converter agora (abrir o link)
          </Button>
          <Button
            variant="warning"
            disabled={!active}
            onClick={doCouldntTalk}
            block
            >Não Consegui Falar
          </Button>
          <Button
            variant="warning"
            disabled={!active}
            onClick={callTomorrow}
            block
            >Ligar amanhã
          </Button>
          <Button
            variant="warning"
            disabled={!active}
            onClick={invalidPhoneNumber}
            block
            >Telefone inválido
          </Button>
          <Button
            variant="danger"
            disabled={!active}
            onClick={inactiveCampaign}
            block
            >Cliente sem Interesse
          </Button>
        </FormGroup>
      </FzCard.Body>
    </FzCard>
  );
}

export default CampaignActions;
