import React, { Component } from 'react';
import { Application } from '../biz/metadatas/application'
import { FzTable } from '../ui/fz/grid/table'

/*
  ApplicationsList

  comlumnPaths could be any application column of one of the following "pseudo columns",
  derived from application data:
   * productNick
   * customer
   * auction.endDate
   * trackingData
*/

export class ApplicationsList extends Component {
  constructor(props) {
    super(props)
    this.gotoApplicationDetails = this.gotoApplicationDetails.bind(this);
    this.applicationIdLink = this.applicationIdLink.bind(this);
    this.scores = this.scores.bind(this)
  }

  gotoApplicationDetails = (application) => {
    this.props.ns.copy(this.props.detailsBaseRoute + application.id).set("application", application)
    this.props.history.push(this.props.detailsBaseRoute + application.id)
  }

  applicationIdLink = (cell, row) => {
    return (<a onClick={() => this.gotoApplicationDetails(row)} href="#nop" >{cell}</a>);
  }

  scores(cell, row) {

    var scores = [];
    if (!cell) {
      return null;
    }
    cell.forEach((it, k) => {
      scores.push(
        <div>{it.score}</div>
      );
    });
    return <React.Fragment>{scores}</React.Fragment>;
  }

  render() {
    let { ns, data, count } = this.props
    let msgData = ''
    if (count !== null && count > 300) {
      msgData = "Too much data to be displayed, you can use the 'Download CSV' function to visualize all."
    }

    const onRowSelect = ({ id }, isSelected) => {
      if (isSelected) {
        ns.push("selected", id);
      } else {
        ns.pull("selected", id);
      }
    }

    const onSelectAll = (isSelected) => {
      ns.unset("selected");
      if (isSelected) {
        for (let app of data) {
          ns.push("selected", app.id);
        }
      }
    }

    let selectProp
    if (this.props.multipleSelect) {
      selectProp = {
        onSelect: onRowSelect,
        onSelectAll: onSelectAll,
        multiple: true,
        selectedKeys: ns.get("selected", [])
      };
    }

    return (
      <div className="table">
        <div className="message">{msgData}</div>
        <FzTable
          ns={ns}
          rowSelection={selectProp}
          context={"applicationsList"}
          data={data}
          pagination
          visibleColumns={[
            "id",
            "product",
            "applicationState",
            "created",
            "appliedAmount",
            "customer",
            "auction.endDate",
            "auction.bidsWeight",
            "scores.score",
            "paidDate",
            "paidAmount"
          ]}
          metas={Application.fields}
          exportDownload
          clipboard
          customColumnFormatters={{ "scores.score": (cell, row) => this.scores(cell, row) }}
        />
      </div>
    );
  }
}