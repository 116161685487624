import React, { useEffect, useState } from 'react';
import IntegrationSummary from '../blocks/integration/summary';
import { IntegrationAnalytics } from '../blocks/integration/analytics';
import { IntegrationFormalizations } from '../blocks/integration/formalizations'
import { FzCard } from '../fz/layout/index'
import BlockUi from 'react-block-ui';
import FieldConnected from '../components/form/field-connected';
import moment from 'moment'
import clone from "lodash/cloneDeep"
import { FzCheckbox } from '../fz/form';
import { panelControl } from '../../biz/metadatas/panel-control-integration'
import { FunnelFormalizations } from './statistics/formalization-funnel';
import '../fz/style/blocks/panel-control.css'
import qs from 'qs'
import { getSuppliersList } from '../../scripts/utils/cache-helpers';


export function APIsConsole(props) {
  const { ns, sharedNS } = props

  const [blockStatsConsole, setBlockStatsConsole] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [urlChecked, setUrlChecked] = useState(false)
  const [data, setData] = useState(ns.getChanged())


  const [filters, setFilters] = useState({
    days: false,
    twentyFourHours: false,
    sevenDays: false,
    tenDays: false,
  })

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
    setData(ns.getChanged())
  }, [ns])

  useEffect(() => {
    ns.startEditing()
    supplierSubMenu()
    getQueryStringFromUrl()
  }, [])

  function setUrlQueryString() {
    let changes = ns.getChanged()
    if (changes) {
      if (changes.startDate instanceof moment) {
        changes.startDate = getValueOfDates(changes, 'startDate');
      }

      if (changes.endDate instanceof moment) {
        changes.endDate = getValueOfDates(changes, 'endDate');
      }

      let supplierCode = getSupplierCodeFromDescription(changes);

      if (supplierCode) {
        changes.suppliers = supplierCode;
      }
    }

    const query = qs.stringify(changes, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
  }

  function getSupplierCodeFromDescription(changes) {
    if (suppliers && changes.suppliers) {
      let sup = suppliers.find(s => s.description === changes.suppliers);

      if (sup) {
        return sup.code;
      }
    }

    return undefined;
  }

  function getValueOfDates(changes, paramsData) {
    return typeof changes[paramsData]._i === 'string' ? changes[paramsData]._i : undefined;
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const listOfQueryParams = Object.keys(params)
    if (listOfQueryParams.length > 0) {
      for (let i = 0; i < listOfQueryParams.length; i++) {
        ns.saveChange(panelControl[listOfQueryParams[i]].path, params[listOfQueryParams[i]])
      }
    }
    setUrlChecked(true)
  }

  function cleanFilters() {
    ns.clear()
    setFilters({
      days: false,
      twentyFourHours: false,
      sevenDays: false,
      tenDays: false,
    })
    ns.startEditing()
  }

  function toggleBlocking(callback = function () { }) {
    setBlockStatsConsole(!blockStatsConsole)
    callback()
  }

  async function supplierSubMenu() {

    const listOfSuppliers = await getSuppliersList(ns)
    setSuppliers(listOfSuppliers)
  }

  function periodDefault(e, days, twentyFourHours, sevenDays, tenDays) {
    if (e) {
      setFilters({ ...filters, twentyFourHours: twentyFourHours, sevenDays: sevenDays, tenDays: tenDays })
      const start = moment().subtract(days, 'days').toISOString().replace(/T\d\d:\d\d:\d\d.\d\d\d/, "T03:00:00.000")
      const end = moment().add(1, 'days').toISOString().replace(/T\d\d:\d\d:\d\d.\d\d\d/, "T03:00:00.000")
      ns.saveChange("startDate", start)
      ns.saveChange("endDate", end)

    }
    else {
      setFilters({ ...filters, twentyFourHours: false, sevenDays: false, tenDays: false })
      ns.saveChange("startDate", undefined)
      ns.saveChange("endDate", undefined)
    }
  }

  function calculateLast24Hours(e) {
    periodDefault(e, false, true, false, false);
  }

  function calculateLast7Days(e) {
    periodDefault(e, 6, false, true, false);
  }

  function calculateLast10Days(e) {
    periodDefault(e, 9, false, false, true);
  }


  function getCustomFieldSource(panelControl) {
    const supplier = props.api.getCurrentSupplier()
    let customFieldView = clone(panelControl["source"])
    if (supplier !== null) {
      supplier && supplier.formalization && supplier.formalization.hasFormalization ? (
        customFieldView.inputProps.options = [
          { "code": "application", "description": "Application" },
          { "code": "formalization", "description": "Formalization" }
        ]) : (
        customFieldView.inputProps.options = [
          { "code": "application", "description": "Application" },
        ])
    }
    return customFieldView
  }

  let applicationsView
  let header


  const customFieldSource = getCustomFieldSource(panelControl)

  let customField

  if (suppliers) {
    customField = panelControl["suppliers"];
    customField.inputProps.options = [...suppliers];
    customField.inputProps.disableSaveOnEnter = true;
    customField.inputProps.lookupIncludingCode = true;
    customField.inputType = "auto-complete";
  }

  switch (ns.getChanged("view")) {
    case 'analytics':
      if (ns.getChanged("suppliers")) {
        if (ns.getChanged("channel") === "api") {
          header = (ns.getChanged("suppliers") + "'s Analytics API")
        } else if (ns.getChanged("channel") === "cof") {
          header = (ns.getChanged("suppliers") + "'s Analytics Manual Process")
        }
      }
      break;

    case "summaries":
      if (ns.getChanged("channel") === "api") {
        header = (ns.getChanged("suppliers") + "'s Summary API")
      } else {
        header = (ns.getChanged("suppliers") + "'s Summary Manual Process")
      }
      break;
    default:
      applicationsView = null
  }

  function presenterFieldSupplier(data, customField, ns) {
    if (!suppliers) {
      return null
    }

    if (ns.getChanged("source") === "formalization" && ns.getChanged("view") === "summaries") {
      return null
    }

    return (
      <div className={"fz-panel-border-padding"} >
        <FieldConnected data={data} meta={customField} ns={ns} />
      </div>
    )
  }

  let panel =
    <FzCard>
      <div className={"fz-panel"} style={{ display: "flex", width: "1500px" }}>

        <div className={"fz-panel-border-padding"}>
          <div className={"fz-panel-integration"}>
            <FieldConnected data={data} meta={customFieldSource} ns={ns} />
          </div>
        </div>

        <div className={"fz-panel-border-padding"} >
          <div className={"fz-panel-integration"}>
            <FieldConnected data={data} meta={panelControl.view} ns={ns} />
          </div>
        </div>


        {
          ns.getChanged("source") !== "formalization" ? (
            <div className={"fz-panel-border-padding"} >
              <div className={"fz-panel-integration"}>
                <FieldConnected data={data} meta={panelControl.channel} ns={ns} />
              </div>
            </div>

          ) : null
        }

        {
          ns.getChanged("view") !== "summaries" ? (
            <>
              <div className={"fz-panel-border-padding"} style={{ display: "block" }}>
                <div style={{ display: "flex" }}>
                  <FieldConnected meta={panelControl.startDate} data={data} ns={ns} />
                  <FieldConnected meta={panelControl.endDate} data={data} ns={ns} />
                </div>
                <div style={{ display: "block" }}>
                  <FzCheckbox checked={filters.twentyFourHours} onChange={calculateLast24Hours} inline> {"Last 24 hours"} </FzCheckbox>
                  <FzCheckbox checked={filters.sevenDays} onChange={calculateLast7Days} inline> {"Last 7 days"} </FzCheckbox>
                  <FzCheckbox checked={filters.tenDays} onChange={calculateLast10Days} inline> {"Last 10 days"} </FzCheckbox>
                </div>
              </div>
            </>
          ) : (
            <div className={"fz-panel-border-padding"}>
              <FieldConnected meta={panelControl.agregado} data={data} ns={ns} />
            </div>
          )
        }
        {
          presenterFieldSupplier(data, customField, ns)
        }
        <div className={"fz-panel-border-padding"} style={{ display: "flex", alignItems: 'center' }}>
          <button className={"btn btn-primary"} onClick={cleanFilters}>Limpar</button>
        </div>
      </div>
    </FzCard>

  const dates = {
    startDate: ns.getChanged("startDate"),
    endDate: ns.getChanged("endDate")
  }

  return (
    <BlockUi tag="div" blocking={blockStatsConsole}>
      <FzCard.Body fzStyle={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "2fr", padding: "0.5rem" }}>
        <FzCard.Body fzStyle={{ gridColumnStart: "1", gridRow: "1", padding: "0.5rem" }}>
          {panel}
        </FzCard.Body>
        <FzCard.Body fzStyle={{ padding: "0.5rem" }}>
          {applicationsView}

          {ns.getChanged("source") !== undefined ? (
            <>
              {ns.getChanged("source") === "application" && ns.getChanged("view") === "analytics" ? (
                <IntegrationAnalytics
                  ns={ns}
                  sharedNS={sharedNS}
                  supplier={ns.getChanged("suppliers")}
                  channel={ns.getChanged("channel")}
                  history={props.history}
                  toggleBlocking={toggleBlocking}
                  filters={dates}
                  header={header}
                />
              ) : null}

              {ns.getChanged("source") === "application" && ns.getChanged("view") === "summaries" ? (
                <IntegrationSummary
                  ns={ns}
                  sharedNS={sharedNS}
                  supplier={ns.getChanged("suppliers")}
                  channel={ns.getChanged("channel")}
                  period={ns.getChanged("agregado")}
                  toggleBlocking={toggleBlocking}
                  header={header}
                />
              ) : null}

              {ns.getChanged("source") === "formalization" && ns.getChanged("view") === "analytics" ? (
                <IntegrationFormalizations
                  ns={ns}
                  sharedNS={sharedNS}
                  supplier={ns.getChanged("suppliers")}
                  history={props.history}
                  toggleBlocking={toggleBlocking}
                  filters={dates}
                />
              ) : null}

              {ns.getChanged("source") === "formalization" && ns.getChanged("view") === "summaries" ? (
                (<FunnelFormalizations ns={ns}
                  toggleBlocking={toggleBlocking}
                  period={ns.getChanged("agregado")}
                />)
              ) : null}

            </>

          ) : null}

        </FzCard.Body>
      </FzCard.Body>
    </BlockUi>
  )
}
