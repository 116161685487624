import { getProduct } from "../../../../../api/applications";
import { FzButton } from "../../../../fz/form"
import { Priority } from "./RenderInputsPriority";
import { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { ProductParameters } from "./product-parameters";
import { FieldMetaPartner } from "./field-meta-partner";
import { Product } from "../../../../../biz/metadatas/product";

export function RenderProducts({ meta, ns, product, setProducts, allProducts, onChange, onSubmit, isEditing, handleSetIsEditing, setToastConfig, index, setActiveElement, activeElement }) {

  const [saveDisabled, setSaveDisabled] = useState(false)
  const currElement = (activeElement && activeElement[product.name] && activeElement[product.name].active) ?
    activeElement[product.name].active :
    "supplierList"

  function onChangeProductFields(val) {
    let productChange = allProducts.find(p => p.internalName === product.internalName);
    productChange["offersPriorities"][val["index"]][val["path"]] = (val["value"] === "undefined") ? "" : val["value"];
    onChange([...allProducts], { isEditing: isEditing.isEditing, disabled: handleExistError(product) }, meta.code)
  }

  function isEmpty(value) {
    return (value == "" || value == undefined || value == "undefined") ? true : false
  }

  function handleExistError(product) {
    const existError = product.offersPriorities.map((item) => {
      return isEmpty(item["supplierInternalName"]) || !(!isEmpty(item["priority"]) && !(item["priority"] == (0)) && item["priority"] < 100) || false
    });
    return (existError.indexOf(true) != -1) ? true : false
  }

  function RenderFields() {
    if (product?.offersPriorities && product.offersPriorities.length > 0) {
      return product.offersPriorities.map((priority, key) => {
        return <div key={key} className="row">
          <Priority
            product={product}
            priority={priority}
            supplier={meta}
            index={key}
            ns={ns}
            setProducts={setProducts}
            onChange={onChangeProductFields}
            isEditing={isEditing}
          />
          <div className="col-md-1 d-flex align-items-center pt-3">
            <FzButton
              fzStyle={"alert"}
              className="w-100"
              onClick={() => handleRemoveLine(key)}
              disabled={!isEditing.isEditing}
            >-</FzButton>
          </div>
        </div>
      });
    }
    return <div className="d-flex p-2 border justify-content-center align-items-center mb-2">Sem parceiros adicionados</div>;
  }

  function handleRemoveLine(key) {
    product.offersPriorities.splice(key, 1);

    setToastConfig({
      showToast: true,
      fzStyle: "success",
      headerMessageToast: "Relevância de Parceiros",
      bodyMessageToast: "Linha removida!"
    });

    handleSetIsEditing({ isEditing: true, disabled: handleExistError(product) }, meta.code);
    setProducts([...allProducts]);
  }

  function handleAddlines() {
    product.offersPriorities.push({ supplierInternalName: "", branchCode: "", priority: undefined });
    setProducts([...allProducts]);
    handleSetIsEditing({ isEditing: true, disabled: handleExistError(product) }, meta.code);
  }

  function handleIsCancel() {
    getProduct(product.internalName).then((uniqueProduct) => {
      allProducts[index] = uniqueProduct
      setProducts([...allProducts]);
    }).finally(e => {
      setToastConfig({
        showToast: true,
        fzStyle: "success",
        headerMessageToast: "Relevância de Parceiros",
        bodyMessageToast: "Nenhuma linha foi afetada!"
      });
    })
  }

  function handleCancelarClick(){
    handleIsCancel()
    handleSetIsEditing({ isEditing: false, disabled: true }, meta.code);
  }

  function handleEditar(){
    handleSetIsEditing({ isEditing: true, disabled: handleExistError(product) }, meta.code)
  }

  function handleSalvar() {
    let msgError = ""
    if (!product.icon || isEmpty(product.icon)) {
      msgError += "Na aba de Parâmetros, o campo 'Ícone do Produto' é obrigatório"
    }
    else if (!product.description || isEmpty(product.description)) {
      msgError += "Na aba de Parâmetros, o campo 'Descrição do Produto' é obrigatório"
    } 
    else if (!product.position || isEmpty(product.position)) {
      msgError += "Na aba de Parâmetros, o campo 'Ordem do Produto' é obrigatório e deve ser maior que 0"
    }

    if (msgError.length > 0) {
      setToastConfig({
        showToast: true,
        fzStyle: "danger",
        headerMessageToast: "Erro ao salvar",
        bodyMessageToast: msgError,
      })
    } else {
      onSubmit(handleExistError(product), product, meta.code)
    }
  }

  const externalProps = {
    isEditing,
    onChange,
    allProducts,
    index,
    setSaveDisabled,
    meta: Product.getField("productExternalPartnerUrl")
  }
  const parametersProps = {
    isEditing,
    onChange,
    allProducts,
    index,
    setSaveDisabled
  }

  const elementsRender = {
    parameters: { name: "Parâmetros", element: <ProductParameters {...parametersProps} /> },
    supplierList: { name: "Lista de parceiros", element: <RenderFields /> },
    external: { name: "External", element: <FieldMetaPartner {...externalProps} /> },
  }

  function saveTab(tabName) {
    setActiveElement({ ...activeElement, [product.name]: { active: tabName } })
  }

  return <>
    <div className="mb-3">
      <div><b>{meta.description}</b></div>
      <section className="mb-3">
        {!isEditing.isEditing ? <FzButton
          onClick={handleEditar}
        >
          Editar
        </FzButton> : <FzButton disabled={saveDisabled}
          onClick={handleSalvar}
        >
          Salvar
        </FzButton>
        }
        {isEditing.isEditing ?
          <FzButton
            onClick={handleCancelarClick}
            fzStyle={"discrete"}
            className={"ml-3"}
          >
            Cancelar
          </FzButton> : <></>}
      </section>
      <div>
        <Navbar className="pl-0">
          <Nav variant="tabs"
            activeKey={currElement}
            onSelect={saveTab}
          >
            {
              Object.keys(elementsRender).map((navPath, index)=>{
                const navItem = elementsRender[navPath]
                return (
                  <Nav.Link eventKey={navPath} key={index}>{navItem.name}</Nav.Link>
                )
              })
            }
          </Nav>
        </Navbar>
        {elementsRender[currElement].element}
      </div>
      {currElement == "supplierList" && <FzButton
        onClick={handleAddlines}
        disabled={!isEditing.isEditing || isEditing.disabled}
        fzStyle={"discrete"}>
        Adicionar linha
      </FzButton>}
    </div>
    <hr />
  </>
}