import React, {Component } from 'react';
import PropTypes from 'prop-types';
import { productsList, APPLICATION_STATE } from '../business/application'
import {StaticDomains} from '../api/client-api';
import  api  from '../api/client-api';
import { FzBadge, FzForm } from '../ui/fz/layout/index';
import { getAllProducts } from '../api/applications';

export const CloseAppReasonStatus = ({name, disabled, value, onChange, ...opts}) => {
  return (
    <FzForm.Control
      name={name}
      as="select"
      placeholder="Selecione"
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}>
        <option key="-1" value="">Selecione o motivo...</option>
        {
            StaticDomains.getCloseReasons().map((reason, i) => {
                if (!reason.systemic) {
                    return (<option key={i} value={reason.closedReason} >{reason.description}</option>);
                }
                return null
            })
        }
    </FzForm.Control>
  )
}

export const SelectProduct = ({value, path, disabled, onChange, defaultEmpty, ...opts}) => {
    let emptyValue = defaultEmpty || ''
    return (<FzForm.Control
                    name={path}
                    as="select"
                    placeholder="Selecione"
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e.target.value)} >
            <option value={emptyValue}>Selecione...</option>
            {productsList.map((b) => <option key={b.nick} value={b.nick}>{b.name}</option>)}
        </FzForm.Control>
    );
}

export class ServerManagedDomain extends React.Component {
  constructor(props) {
    super(props);
    if (!props.domainName) {
        console.warn("ServerManagedDomain should have props.domainName for field: ", this.path)
    }
    this.state = { values: undefined };
  }
  componentDidMount() {
    this.props.api.domains.getDomainValues(this.props.domainName)
              .then((domainValues) => this.setState({"values": domainValues}))
              .catch((err) => console.warn(err))
  }

  render() {
    let {path, disabled, value, onChange} = this.props
    let options
    if (this.state.values) {
        options = this.state.values.map((v) => <option key={v.code} value={v.code}>{v.description}</option>)
    }
    return (<FzForm.Control
                    name={path}
                    as="select"
                    placeholder="Selecione"
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e.target.value)} >
            <option value=''>Selecione...</option>
            {options}
    </FzForm.Control>
    );
  }
}
ServerManagedDomain.propTypes = {
  path: PropTypes.string,
  api:  PropTypes.object.isRequired,
  domainName: PropTypes.string.isRequired,
};

export class ProductName extends Component {

  constructor(props) {
    super(props)
    this.state = { allProductsList : []}
  }

  componentDidMount() {
    this.loadAllProducts();
  }

  async loadAllProducts() {
    const productsResp = await getAllProducts();
    this.setState({
      allProductsList: productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
    });
  }


  render(){
    const { product } = this.props

    if(!product){
      return 'Produto não definido'
    }
  
    return (
      <p>
        <FzBadge>
          {
          this.state.allProductsList.length > 0 ?
            this.state.allProductsList.filter(it => {
              it.code === product
            })[0]?.description ?? product : 'Carregando...'
          }
        </FzBadge>
      </p>
    )
  }
}

export const ApplicationState = ({api, application, ...opts}) => {
    let stateDescription
    let labelStyle = "disable";
    switch(application.applicationState) {
        case APPLICATION_STATE.Excluded:
            stateDescription = "Excluida";
            labelStyle = "alert";
            break;
        case APPLICATION_STATE.Started:
            stateDescription = "Em análise";
            labelStyle = "regular";
            break;
        case APPLICATION_STATE.HasBids:
            stateDescription = "Tem ofertas";
            labelStyle = "primary";
            break;
        case APPLICATION_STATE.Accept:
            stateDescription = "Oferta aceita";
            labelStyle = "attention";
            break;
        case APPLICATION_STATE.FollowUp:
            stateDescription = "Follow-up";
            labelStyle = "attention";
            break;
        case APPLICATION_STATE.Closed:
            stateDescription = "Encerrado";
            labelStyle = "danger";
            break;
        case APPLICATION_STATE.Paid:
            stateDescription = "Pago";
            labelStyle = "success";
            break;
        case APPLICATION_STATE.Init:
        case APPLICATION_STATE.Lead:
        default:
            stateDescription = "Lead";
            break;
    }
    return (<FzBadge fzStyle={labelStyle}>{stateDescription}</FzBadge>);
}

export class BankName extends React.Component {

    constructor(props) {
        super(props);
        if (!props.domainName) {
            console.warn("BankName should have props.domainName for field: ", this.path)
        }
        this.state = { values: undefined };
      }
      componentDidMount() {
        this.props.api.domains.getDomainValues( this.props.domainName )
                  .then((domainValues) => this.setState({"values": domainValues}))
                  .catch((err) => console.warn(err))
      }
      render() {
        let sort = (a,b) => {
          if (a.description > b.description) {
            return 1
          } else if (a.description < b.description) {
            return -1
          } else {
            return 0
          }
        }
          let {path, disabled, value, onChange} = this.props
        let options
        if (this.state.values) {
            options = this.state.values.slice().sort(sort).map((v) => <option key={v.code} value={v.code}>{v.description}</option>)
        }
        return (<FzForm.Control
                        name={path}
                        as="select"
                        placeholder="Selecione"
                        disabled={disabled}
                        value={value}
                        onChange={(e) => onChange(e.target.value)} >
                <option value=''>Selecione...</option>
                {options}
            </FzForm.Control>
        );
      }
}

BankName.propTypes = {
    path: PropTypes.string,
    api:  PropTypes.object.isRequired,
};


export class GenericBankFiltersSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = { values: undefined };
      }
      componentDidMount() {
          api.domains.getDomainValues("generic-bank-filters")
                  .then((filters) => {
                    let fil = []
                    for (let i = 0 ; i < filters.length ; i++) {
                        fil.push({code:filters[i].code, description:filters[i].description})
                    }
                    this.setState({"values": fil})})
                  .catch((err) => console.warn(err))
      }
      render() {
        let sort = (a,b) => {
          if (a.description > b.description) {
            return 1
          } else if (a.description < b.description) {
            return -1
          } else {
            return 0
          }
        }
        let {name, disabled, value, onChange} = this.props
        let options
        if (this.state.values) {
            options = this.state.values.slice().sort(sort).map((v) => <option key={v.code} value={v.code}>{v.description}</option>)
        }
        return (<FzForm.Control
                        name={name}
                        as="select"
                        placeholder="Selecione"
                        disabled={disabled}
                        value={value}
                        onChange={(e) => onChange(e.target.value)} >
                <option key='-' value='empty'>Selecione...</option>
                {options}
            </FzForm.Control>
        );
      }
}

GenericBankFiltersSelection.propTypes = {
    path: PropTypes.string,
};