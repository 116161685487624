import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import CSVReader from 'react-csv-reader'
import { Button, ButtonGroup, Jumbotron } from 'react-bootstrap';
import { setTTL } from '../business/misc-functions';
import { FzCard, FzRow, FzCol } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';
import FieldConnected from "../ui/components/form/field-connected"
import { getAllRules } from "../api/client-messages";
import { partnerMeta } from "../biz/metadatas/campaign_info";
import { initNewCampaign } from '../api/campaigns';
import { TargetException } from '../ui/pages/campaigns/campaigns-info';

class NewCampaign extends React.Component<any, any> {

  constructor(props) {
    super(props)
    this.state = { nbApplicants: 0, blockUI: false, errorMessage: "", campaignPersonalInfos: null, columns: null, notificationEventsOptions: [], version: 0 }
    this.handleCSV = this.handleCSV.bind(this)
    this.handleCSVError = this.handleCSVError.bind(this)
    this.parseColumns = this.parseColumns.bind(this)
    this.sendNewCampaign = this.sendNewCampaign.bind(this)
    this.isValidForSubmit = this.isValidForSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.sumVersion = this.sumVersion.bind(this)
  }

  parseColumns(data, columns, header) {
    if (Array.isArray(data) && data.length > 1) {
      header.maxId = 0
      for (var i = 0; i < data[0].length; i++) {
        if (data[0][i].toLowerCase() === "cpf" || data[0][i] === "NR_CPF") {
          columns.personalNumber = i
          header.maxId = Math.max(header.maxId, i)
        } else if (data[0][i].toLowerCase() === "cpfregistration" || data[0][i] === "ID_CPFREGISTRATION") {
          columns.cpfRegistration = i
          header.maxId = Math.max(header.maxId, i)
        } else if (data[0][i].toLowerCase() === "limite" || data[0][i] === "VL_LIMIT_TOTAL_CAMPANHA") {
          columns.approvedAmount = i
          header.maxId = Math.max(header.maxId, i)
        } else if (data[0][i].toLowerCase() === "taxa") {
          columns.interestRate = i
          header.maxId = Math.max(header.maxId, i)
        } else if (data[0][i].toLowerCase() === "parcela" || data[0][i] === "VL_PMT_MAX_CAMPANHA") {
          columns.approvedInstallment = i
          header.maxId = Math.max(header.maxId, i)
        } else if (data[0][i].toLowerCase() === "prazo" || data[0][i] === "VL_PRAZO_MAX_CAMPANHA") {
          columns.approvedPeriodMonths = i
          header.maxId = Math.max(header.maxId, i)
        } else if (data[0][i].toLowerCase() === "cet") {
          columns.totalAnnualInterestRate = i
          header.maxId = Math.max(header.maxId, i)
        }
      }
      if (!("personalNumber" in columns) && !("cpfRegistration" in columns)) {
        return false
      }
    } else {
      return false
    }
    return true
  }

  handleChange = (ruleType, value, isAdd = false) => {
    const { ns } = this.props;
    if (isAdd) {
      ns.saveChange(`${ruleType}`, [...ns.getChanged(ruleType, []), value]);
    } else {
      ns.saveChange(`${ruleType}`, value);
    }

    this.setState({ hasVerified: false });
  };

  handleCSV(data) {
    this.setState({ blockUI: true, errorMessage: "", campaignPersonalInfos: null, columns: null, nbApplicants: 0 })
    let columns : any = {} 
    let header : any = {}
    if (this.parseColumns(data, columns, header)) {
      let campaignPersonalInfos: any[] = [] 
      for (var i = 1; i < data.length; i++) {
        if (Array.isArray(data[i]) && data[i].length >= header.maxId) {
          let campaignPersonalInfo: any = {} 
          for (var key in columns) {
            let value = data[i][columns[key]]
            if (value) {
              switch (key) {
                case "personalNumber":
                case "cpfRegistration":
                  campaignPersonalInfo[key] = value
                  break;
                case "approvedAmount":
                case "interestRate":
                case "approvedInstallment":
                case "totalAnnualInterestRate":
                  value = value.replace(",", ".")
                  campaignPersonalInfo[key] = parseFloat(value)
                  break;
                case "approvedPeriodMonths":
                  campaignPersonalInfo[key] = parseInt(value)
                  break;
                default:
                  break;
              }
            }
          }
          // checking fields validity
          if (!(/^\d{11}$/.test(campaignPersonalInfo["personalNumber"])) && !(/^[0-9a-f]{24}$/.test(campaignPersonalInfo["cpfRegistration"]))) {
            this.displayError("ERROR parsing CSV file: Please, verify columns formating. Invalid CPF or invalid cpfRegistration detected: " + campaignPersonalInfo["personalNumber"] + "  " + campaignPersonalInfo["cpfRegistration"])
            this.setState({ blockUI: false })
            return;
          }

          campaignPersonalInfos.push(campaignPersonalInfo)
        }
      }
      this.displayError(null)
      this.setState({ blockUI: false, nbApplicants: campaignPersonalInfos.length, campaignPersonalInfos: campaignPersonalInfos, columns: Object.keys(columns) })
    } else {
      this.displayError("ERROR parsing CSV file: Please, verify columns formating")
      this.setState({ blockUI: false })
    }
  };

  handleCSVError(err) {
    this.displayError(err.message)
  };

  displayError(err) {
    this.setState({ errorMessage: err })
  }

  sendNewCampaign() {
    let { ns } = this.props
    let campaign = ns.getChanged() || ns.get("campaign")
    if (campaign) {
      if (campaign.auth) {
        if (campaign.auth.indexOf("generatePreAuth") > -1 && !confirm("Tem certeza que quer permitir o usuário SEMPRE entrar logado? \n esta opção é geralmente utilizada para campanhas offline")) {
          return false
        }

        let authOpts = campaign.auth.reduce((acc, item) => ({ ...acc, [item]: true }), {})
        campaign = { ...campaign, ...authOpts }
      }

      campaign.campaignPersonalInfos = this.state.campaignPersonalInfos

      // check applied amount for off-line campaings
      if (campaign.campaignType === "offline") {
        let notValidAmount = campaign.campaignPersonalInfos.find(function (obj) { return (obj.approvedAmount === undefined || obj.approvedAmount === null || obj.approvedAmount <= 0 || Number.isNaN(obj.approvedAmount)); });
        if (campaign.campaignPersonalInfos === undefined || campaign.campaignPersonalInfos === null || notValidAmount) {
          alert("Off-Line Campaigns must have Limit for all applicants!");
          return;
        }
      }
      this.displayError(null)

      initNewCampaign(campaign)
        .then((data) => {
          this.props.ns.unset("campaigns")
          this.props.history.push('/campaigns/')
        })
        .catch((err) => { this.displayError(err) })
    }
  }

  componentDidMount(): void {
    this.props.ns.startEditing();
    this.loadMercurioEvents();
  }

  async loadMercurioEvents() {
    const eventsRes = await getAllRules();
    this.setState({
      notificationEventsOptions: eventsRes.map(it => ({
        code: it.event,
        description: it.event
      }))
    });
  }

  isValidForSubmit() {
    let { ns } = this.props
    let campaign = ns.getChanged() || ns.get("campaign")
    if (campaign) {
      return ((campaign.campaign || campaign.campaign !== "") && (campaign.supplierInternalName || campaign.campaignType === "online") && campaign.startDate !== null && campaign.endDate !== null && campaign.campaignType && (this.state.nbApplicants > 0 || (campaign.origin == 'alexandria' && campaign.table)))
    }
  }

  sumVersion = () => {
    const { version } = this.state;
    return new Promise((resolve) => {
      resolve(this.setState({ version: version + 1 }));
    });
  };

  render() {
    let { ns, api } = this.props
    let campaign = ns.getChanged() || ns.get("campaign") || {}
    var error: any = null
    if (this.state.errorMessage) {
      error = (<FzRow>
        <FzAlert fzStyle="attention">{this.state.errorMessage}</FzAlert>
      </FzRow>)
    }
    var parsing: any = null
    if (this.state.nbApplicants > 0 && this.state.columns) {
      let appStr = (this.state.nbApplicants > 1) ? "applicants" : "applicant"
      let nbApplicants = (<strong>{this.state.nbApplicants} {appStr}</strong>)
      let colStr = (this.state.columns.length > 1) ? "columns" : "column"
      let columnsMsg = (<strong>{this.state.columns.length} {colStr}</strong>)
      let columnsContent = JSON.stringify(this.state.columns)
      parsing = (<FzRow>
        <FzAlert fzStyle="attention">{columnsMsg} ({columnsContent}) and {nbApplicants}</FzAlert>
      </FzRow>)
    };

    partnerMeta.notificationEvent.inputProps && 
      partnerMeta.notificationEvent.inputProps.options ? 
        partnerMeta.notificationEvent.inputProps.options =  this.state.notificationEventsOptions : [];

    const csvOptions = { skipEmptyLines: true };
    return (
      <div>
        <BlockUi tag="div" blocking={this.state.blockUI}>
          <Jumbotron>
            <FzCard>
              <FzCard.Heading fzStyle={{}}>
                <FzCard.Title componentClass="h3">Campaign General Infos</FzCard.Title>
              </FzCard.Heading>
              <FzCard.Body>
                <FzRow  key={`fz-new-campaign-row1`}>
                  <FzCol key={`fz-new-campaign-row1-col1`}><FieldConnected ns={ns} meta={partnerMeta.campaign} data={campaign} /></FzCol>
                  <FzCol key={`fz-new-campaign-row1-col2`}><FieldConnected ns={ns} meta={partnerMeta.supplierInternalName} data={campaign} /></FzCol>
                </FzRow>

                <FzRow  key={`fz-new-campaign-row2`}>
                  <FzCol key={`fz-new-campaign-row2-col1`}><FieldConnected ns={ns} meta={partnerMeta.startDate} data={campaign} /></FzCol>
                  <FzCol key={`fz-new-campaign-row2-col2`}><FieldConnected ns={ns} meta={partnerMeta.endDate} data={campaign} /></FzCol>
                </FzRow>

                <FzRow  key={`fz-new-campaign-row3`}>
                  <FzCol key={`fz-new-campaign-row3-col1`}><FieldConnected ns={ns} meta={partnerMeta.campaignType} data={campaign} /></FzCol>
                  <FzCol key={`fz-new-campaign-row3-col2`}><FieldConnected ns={ns} meta={partnerMeta.notificationEvent} data={campaign} /></FzCol>
                </FzRow>

                <FzRow  key={`fz-new-campaign-row4`}>
                  <FzCol key={`fz-new-campaign-row4-col1`}><FieldConnected ns={ns} meta={partnerMeta.scheduleDate} data={campaign} /></FzCol>
                  <FzCol key={`fz-new-campaign-row4-col2`}><FieldConnected ns={ns} meta={partnerMeta.auth} data={campaign} /></FzCol>
                </FzRow>
                <FzRow  key={`fz-new-campaign-row5`}>
                  <FzCol key={`fz-new-campaign-row5-col1`}><FieldConnected ns={ns} meta={partnerMeta.origin} data={campaign}/></FzCol>
                </FzRow>
                {campaign.origin == 'alexandria' && 
                  <FzRow key={`fz-new-campaign-row6`}>
                    <FzCol key={`fz-new-campaign-row2-col1`}><FieldConnected ns={ns} meta={partnerMeta.table} data={campaign}/></FzCol>
                  </FzRow>
                }
              </FzCard.Body>
            </FzCard>
            {campaign.origin == 'csv' && <ButtonGroup  key={`fz-te-btngroup`}>
              <CSVReader
                cssClass="csv-input"
                label="Select CSV personal data file"
                parserOptions={csvOptions}
                onFileLoaded={this.handleCSV}
                onError={this.handleCSVError}
              />
            </ButtonGroup>}
              <TargetException key={`fz-te-exception`} ns={ns} products={this.state.products} entity={"campaign"} onChange={this.handleChange} onAdd={(p, v) => this.handleChange(p, v, true)} meta={partnerMeta["filterConditions.exceptions"]} />
            {error}
            {parsing}
            <div key={`div-btn`}>
              <Button variant="success" onClick={this.sendNewCampaign} disabled={!this.isValidForSubmit()}>Confirm</Button>
            </div>
          </Jumbotron>
        </BlockUi>
      </div>
    );

  }
}

export default NewCampaign;
