import React from 'react';
import { filterActiveAuctionApplications, filterInactiveAuctionApplications, filterLeadApplications } from '../../../biz/application';
import { ActiveApplications } from '../active-applications';
import { CustomerLeads } from './customer-leads';
import { InactiveApplications } from '../inactive-applications'
import {FzBadge, FzCard} from '../../fz/layout/index';
import {salesQueueDictionary} from "../../../crm/domains";


class CustomerApplications extends React.Component {

  render() {
    const all = this.props.applications;
    const searchParams = new URLSearchParams(window.location.search);
    const queue = searchParams.get("queue");
    const priorityAttendance = this.props.priorityAttendance

    if (!all) {
      return null
    }
    const lead = filterLeadApplications(all) || []
    const active = filterActiveAuctionApplications(all) || []
    const inactive = filterInactiveAuctionApplications(all) || []
    let leadPanel = null
    let activePanel = null
    let inactivePanel = null

    if (lead.length > 0) {
      leadPanel = <FzCard bsStyle="danger">
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">Propostas Incompletas</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <CustomerLeads priorityAttendance={priorityAttendance} customer={this.props.customer} ns={this.props.ns} applications={lead} history={this.props.history} />
        </FzCard.Body>
      </FzCard>
    }
    if (active.length > 0) {
      activePanel = (
        <FzCard bsStyle="success">
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">Propostas Ativas {queue && <span style={{marginLeft: "15px"}}>Fila: <FzBadge fzStyle="success">{(salesQueueDictionary[queue])}</FzBadge></span>}</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <ActiveApplications priorityAttendance={priorityAttendance} customer={this.props.customer} applications={active} ns={this.props.ns} history={this.props.history} basePath={this.props.basePath} onNeedUpdate={this.props.onNeedUpdate} />
          </FzCard.Body>
        </FzCard>
      )
    }

    if (inactive.length > 0) {
      inactivePanel = (
        <FzCard bsStyle="warning">
          <FzCard.Heading>
            <FzCard.Title componentClass="h3">Propostas Anteriores</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <InactiveApplications priorityAttendance={priorityAttendance} applications={inactive} ns={this.props.ns} history={this.props.history} basePath={this.props.basePath} />
          </FzCard.Body>
        </FzCard>
      )
    }

    return (
      <div>
        {leadPanel}
        {activePanel}
        {inactivePanel}
      </div>
    )
  }
}

export { CustomerApplications }
