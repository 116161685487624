import React from 'react';
import { FzIcons } from '../ui/fz/form/icon';

const formalizationStateGlyph = (state) => {
  switch (state) {
    case 1:
      // Não iniciado
      return (< FzIcons icon={"hourglass-start"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 2:
      // Parcial
      return (< FzIcons icon={"long-arrow-up"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 4:
      // Enviado para análise
      return (< FzIcons icon={"repeat"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 6:
      // Parcialmente recusado
      return (< FzIcons icon={"thumbs-down"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 8:
      // Aguardando assinatura
      return (< FzIcons icon={"receipt"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 16:
      // Finalizado (assinado)
      return (< FzIcons icon={"file-signature"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 32:
      // Pago
      return (< FzIcons icon={"piggy-bank"} fzStyle={{ width: "15px", height: "15px" }} />)
    case 0x8000:
      // Cancelado
      return (< FzIcons icon={"trash"} fzStyle={{ width: "15px", height: "15px" }} />)
    default:
      return null
  }
}
export {formalizationStateGlyph};


