export const responseTimeSummaryMeta = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      rowSpan: "1",
      "hidden": false,
      "shortLabel": false,
      "width": "5ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": true,
    },
  },
  "queue": {
    "path": "queue",
    "label": "Fila",
    "tableProps": {
      rowSpan: "1",
      "width": "25ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "left",
      "exportFormat": undefined,
    },
  },
  "period": {
    "path": "period",
    "label": "Período",
    "tableProps": {
      rowSpan: "1",
      "width": "10ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": true,
      "dataAlign": "left",
      "columnFormat": "periodColumn",
      "exportFormat": undefined,
    },
  },
  "qtty": {
    "path": "qtty",
    "label": "Total",
    "tableProps": {
      rowSpan: "1",
      "width": "8ch",
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "amountColumn",
      "formatExtraData": undefined,
    },
  },
  "timeAvg": {
    "path": "timeAvg",
    "label": "Média (ms)",
    "tableProps": {
      rowSpan: "1",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "formatExtraData": undefined,
    },
  },
  "timeMax": {
    "path": "timeMax",
    "label": "Máximo (ms)",
    "tableProps": {
      rowSpan: "1",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "formatExtraData": undefined,
    },
  },
  "timeMin": {
    "path": "timeMin",
    "label": "Mínimo (ms)",
    "tableProps": {
      rowSpan: "1",
      "width": "10ch",
      "dataSort": true,
      "dataAlign": "right",
      "formatExtraData": undefined,
    },
  },
};