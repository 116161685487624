import { FzCard, FzSplitLine} from '../../../fz/layout/index'
import { UserDetailsContainer } from '../../../../Containers/Hermes/users/index'
import { useEffect, useState } from 'react'
import { getUserById } from '../../../../api/users'
import { createHermesUser, getHermesUser, getHermesQueues, updateHermesUser, getHermesUsers } from '../../../../services/api/hermes'
import { hermesUserMeta } from '../../../../Containers/Hermes/users/index'
import { FzToast } from '../../../fz/form/notification/toast'
import BlockUi from 'react-block-ui'

const HermesUserDetails = (props) => {
  const { ns } = props
  let { userId } = props.match.params
  const [user, setUser]: any = useState(null)
  const [users, setUsers]: any = useState(null)
  const [queues, setQueues]: any = useState(null)
  const [queuesOptions, setQueuesOptions]: any = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [assignedQueues, setAssignedQueues] = useState([])
  const [hermesUserBackup, setHermesUserBackup]: any = useState(null)
  const [hermesUser, setHermesUser]: any = useState(null)
  const [isNewUser, setIsNewUser] = useState(false)
  const [addQueue, setAddQueue] = useState(false)

  //toast messages
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, seColorTost] = useState('')

  useEffect(()=> {
    getUserById(userId).then((user) => {
      setUser(user)
      if(user) {
        verifyHermesUser(user)
      }
      getQueues()
      getUsers()
    })
  }, [])

  useEffect(()=> {
    filterQueuesListByUserPriority()
    generateQueuesOptionsMeta()
  }, [hermesUser, queues])

  function toggleAdvisorEdit() {
    setIsEditing(!isEditing)
  }

  async function getQueues() {
    const {data} = await getHermesQueues()
    setQueues(data)
  }

  async function getUsers() {
    let {data} = await getHermesUsers()
    setUsers(data)
  }

  async function verifyHermesUser(user) {
    try {
      const { data } = await getHermesUser(user.id)
      if(data && data.queues) {
        setHermesUser(data)
        backupUser(data)
      }else {
        const newUser = {
          _id: user.id,
          userName: user.name,
          userEmail: user.email,
          queues: []
        }
        setHermesUser(newUser)
        backupUser(newUser)
        setIsNewUser(true)
      }
    }catch(e) {
      console.log(e)
    }
  }

  function backupUser(user) {
    setHermesUserBackup(JSON.parse(JSON.stringify(user)))
  }

  function filterQueuesListByUserPriority() {
    if(hermesUser && queues){
      let resultList: any = []
      for (let queueIdx in hermesUser.queues) {
        const currentQueueId = hermesUser.queues[queueIdx]
        for (let i in queues) {
          if(queues[i]._id === currentQueueId){
            resultList.push(queues[i])
          }
        }
      }
      setAssignedQueues(resultList)
    }
  }

  function removeQueue(idx) {
    let queues = hermesUser.queues
    queues.splice(idx, 1)
    setHermesUser({...hermesUser, queues: queues})
  }

  function moveQueue(queues) {
    setHermesUser({...hermesUser, queues: queues})
  }

  function generateQueuesOptionsMeta() {
    if(queues && hermesUser){
      const queuesOptionsList: any = []
      queues.map((item) => {
        if(hermesUser.queues.indexOf(item._id) === -1){
          queuesOptionsList.push(
            {
              code: item._id,
              description: item.queueName
            }
          )
        }
      })
      const userMeta: any = hermesUserMeta.queueSelect
      userMeta.inputProps.options = queuesOptionsList
      setQueuesOptions(userMeta)
      ns.startEditing()
    }
  }

  function toggleAddQueue(){
    setAddQueue(!addQueue)
  }

  function addNewQueueToUser() {
    const queueSelected = ns.getChanged('queueSelect')
    const queuesList = hermesUser.queues
    if(queueSelected !== 'undefined'){
      queuesList.push(queueSelected)
      setHermesUser({...hermesUser, queues: queuesList})
    }
  }

  function cancelAdvisorEdit() {
    setHermesUser(JSON.parse(JSON.stringify(hermesUserBackup)))
    setIsEditing(false)
  }

  async function saveAdvisor() {
    try {
      if(!isLoading){
        if(isNewUser){
          setIsLoading(true)
          const result = await createHermesUser(hermesUser)
          if(result.data.result.ok){
            showResultToast('Sucesso!', 'Novo advisor(Hermes) criado.', 'success')
          }
          setIsEditing(false)
          setIsLoading(false)
          backupUser(hermesUser)
        }else {
          setIsLoading(true)
          const updatePayload = {
            userName: hermesUser.userName,
            userEmail: hermesUser.userEmail,
            queues: hermesUser.queues
          }
          const result = await updateHermesUser(hermesUser._id, updatePayload)
          if(result.data.ok){
            showResultToast('Sucesso!', 'Dados do advisor salvos.', 'success')
          }
          setIsEditing(false)
          setIsLoading(false)
          backupUser(hermesUser)
        }
      }
    }catch(err) {
      let error: $TsFixMe = err
      showResultToast('Erro ao salvar o advisor!', error.response.data, 'danger')
      setIsLoading(false)
      console.log(error)
    }
  }

  function handleClose() {
    setShow(false)
  }

  function showResultToast(header, message, color) {
    setHeaderMessageTost(header)
    setBodyMessageTost(message)
    seColorTost(color)
    setShow(true)
  }

  return (
      <BlockUi tag="div" blocking={!(hermesUser && queues && queuesOptions)} message={"Carregando dados do advisor"}>
        <div style={{padding: "20px"}}>
          <FzCard>
              <FzCard.Heading fzStyle={{}}>
                <FzSplitLine>
                  <FzSplitLine.Left>
                    <FzCard.Title componentClass="h3">Parametrização dos Consultores</FzCard.Title>
                  </FzSplitLine.Left>
                </FzSplitLine>
              </FzCard.Heading>
              <FzCard.Body>
                <UserDetailsContainer
                  user={user} 
                  users={users}
                  ns={ns} 
                  queuesOptions={queuesOptions}
                  assignedQueues={assignedQueues}
                  userQueues={hermesUser?.queues}
                  isEditing={isEditing}
                  isLoading={isLoading}
                  addQueue={addQueue}
                  toggleAddQueue={toggleAddQueue}
                  toggleAdvisorEdit={toggleAdvisorEdit}
                  removeQueue={removeQueue}
                  moveQueue={moveQueue}
                  addNewQueueToUser={addNewQueueToUser}
                  cancelAdvisorEdit={cancelAdvisorEdit}
                  saveAdvisor={saveAdvisor}
                />
              </FzCard.Body>
            </FzCard>
        </div>

        <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
        />
      </BlockUi>
  )
}

export { HermesUserDetails }