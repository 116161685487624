import { useEffect, useState } from "react"
import { getStatisticsRunRateDS } from "../../../../api/stats"

export const DashRunRatePanel = (props) => {
  const [dataStudioValue, setDataStudioValue] = useState(null)

  useEffect(() => {
    getStatisticsRunRateDS().then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get RUN-RATE Data Studio iframe", err)
    })
  }, []) 

  return (
    <div>
      {
        dataStudioValue ? 
          <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} /> 
        : 
        <div> Carregando o dashboard, aguarde...</div>
      }
    </div>
  )
}