import moment from "moment";
import { Meta } from "../../../types/metas";


export const analyticsColumns: Meta = {

  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "tableProps": {
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "created": {
    "path": "created",
    "label": "Data da consulta",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "periodWithTimeColumn"
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Tipo de doc",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "match": {
    "path": "match",
    "label": "Match",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "booleanColumn"
    },
    "isMandatory": () => false
  },
  "isCached": {
    "path": "matchType",
    "label": "Lugar de consulta",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },

    },
    "isMandatory": () => false
  },
}


function normalizeDate(value){
  return typeof (value) === "object" && value !== null
    ? value.toISOString()
    : value
}


export const analyticsFilter: Meta = {
  "from": {
    "path": "from",
    "label": "De",
    // "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "isMandatory": (application) => false,
    normalize: normalizeDate
  },
  "to": {
    "path": "to",
    "label": "Até",
    // "dataType": "moment",
    "inputType": "date",
    "isReadOnly": () => false,
    "isMandatory": (application) => false,
    normalize: normalizeDate
  },
  "documentType": {
    "path": "documentType",
    "label": "Doc consultado",
    "dataType": "string",
    "inputType": "select",
    "isMandatory": (application) => false,
    "inputProps": {
      "options": [
        {
          code: "income", description: "C. Renda"
        },
        {
          code: "address", description: "C. Endereço"
        }
      ]
    }
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "mask": "111.111.111-11",
      "removeMask": true
    },
    "isMandatory": (application) => false,
    "isReadOnly": () => false
  },
}


export const testeColumns: Meta = {
  "id": {
    "path": "id",
    "label": "id",
    "tableProps": {
      "isKey": true,
      "hidden": true,
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "status": {
    "path": "status",
    "label": "Status do documento",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "reasons": {
    "path": "reasons",
    "label": "Motivo de pend ou recusa",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  }
}

