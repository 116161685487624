import React, { useState } from "react";
import { StatsDetailHeader } from "../../../statistics/stats-components";
import { FzTable } from "../../../ui/fz/grid/table";
import { FzCol, FzRow, FzSplitLine } from "../../../ui/fz/layout";
import { FzCard } from "../../../ui/fz/layout/FzCard";
import { FzNavSelect } from "../../../ui/fz/nav";
import { fieldValidationMeta } from "./metadata";
import { SummariesReportProps } from "./types";

export function SummariesReport({
  data,
  ns,
  handleSearchReport
}: SummariesReportProps) {

  const [selectedPeriod, setSelectedPeriod] = useState(undefined)
  const [selectIn, setSelect] = useState("")
  const [exibitionSelect, setExibitionSelect] = useState("Daily")
  
  const handleChangeSelect = (period) => {
    setSelect(period);
    switch (period) {
      case "day":
        setExibitionSelect("Daily")
        break;
      case "week":
        setExibitionSelect("Weekly")
        break;
      case "month":
        setExibitionSelect("Monthly")
        break;
    }
    handleSearchReport(period)
  }

  function handleChangePeriod(event) {
    setSelectedPeriod(event)
  }

  function expandComponent(row) {
    return (
      <div className={"no-white-space"}>
        <p>{JSON.stringify(row.values).replace(/,/g, ", ")}</p>
      </div>
    )
  }
  return (
    <FzCard>
      <FzCard.Header className='w-100'>
        <FzSplitLine>
          <FzSplitLine.Left></FzSplitLine.Left>
          <FzSplitLine.Right>
            <FzNavSelect
              selected={selectIn}
              onChange={handleChangeSelect}
              options={[
                { "code": "day", "description": "Daily" },
                { "code": "week", "description": "Weekly" },
                { "code": "month", "description": "Monthly" },
              ]}
            />
          </FzSplitLine.Right>
        </FzSplitLine>
        <FzRow>
          <FzCol span={12}>
            <StatsDetailHeader
              title={`Form Versions Fields Validation`}
              stats={data}
              periods={null}
              currPeriod={null}
              onChange={null}
            />
          </FzCol>
        </FzRow>
      </FzCard.Header>
      <FzCard.Body>
        <FzCard.Body>
          <FzTable
            data={data.statistics}
            metas={fieldValidationMeta}
            visibleColumns={["key", "majorVersion", "minorVersion", "patchVersion", "formPage", "currentPage", "path", "total"]}
            ns={ns}
            rowExpand={{
              expandComponent: (row) => expandComponent(row)
            }}
            exportDownload
            clipboard
            pagination
          />
        </FzCard.Body>
      </FzCard.Body>
    </FzCard>
  )
}