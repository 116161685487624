import React from 'react';
import { ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import { crmChannel } from './domains';

class CrmChannel extends React.Component {

  render () {
    const { value } = this.props
    var options = []
    for (var k in crmChannel) {
      if (crmChannel.hasOwnProperty(k)) {
        options.push(<ToggleButton key={k} value={k}>{crmChannel[k]}</ToggleButton>)
      }
    }

    return (
      <div>
        <ToggleButtonGroup 
          size='sm' 
          type="radio" 
          name="options" defaultValue={value} onChange={this.props.onChange}
        >
          {options}
        </ToggleButtonGroup>
      </div>
    )
  }
} 

export {CrmChannel};