import { useEffect, useState } from "react"
import FieldConnected from "../../../ui/components/form/field-connected"
import { FzCheckbox } from "../../../ui/fz/form"
import moment from "moment"
import { ticketsReportFilters, ticketsReportTableMeta } from "./ticketsReportMeta"
import { FzCard } from "../../../ui/fz/layout"
import { FzTable } from "../../../ui/fz/grid/table"

export const TicketsAttendanceReport = ({setPeriod, period, data, ns}) => {
  const [filters, setFilters] = useState({
    days: false,
    twentyFourHours: false,
    sevenDays: false,
    tenDays: false,
  })

  const notInitializedMeta = {...JSON.parse(JSON.stringify(ticketsReportTableMeta))}
  delete notInitializedMeta.closedDate
  delete notInitializedMeta.closedReason
  delete notInitializedMeta.closedByUserName
  delete notInitializedMeta.relation

  useEffect(() => {
    const newFilter = ns.getChanged()
    if(newFilter){
      setPeriod({
        from: newFilter.from && moment(newFilter.from).isValid() ? moment(newFilter.from).toISOString() : moment().subtract(1, 'days').toISOString(),
        to: newFilter.to && moment(newFilter.to).isValid() ? moment(newFilter.to).toISOString() : moment().toISOString(),
        notInitialized: newFilter.notInitialized
      })
    }
  }, [ns])

  function periodDefault(e, days, twentyFourHours, sevenDays, tenDays) {
    if (e) {
      setFilters({ ...filters, twentyFourHours: twentyFourHours, sevenDays: sevenDays, tenDays: tenDays })
      const start = moment().subtract(days, 'days').toISOString().replace(/T\d\d:\d\d:\d\d.\d\d\d/, "T03:00:00.000")
      const end = moment().add(1, 'days').toISOString().replace(/T\d\d:\d\d:\d\d.\d\d\d/, "T03:00:00.000")
      ns.saveChange("from", start)
      ns.saveChange("to", end)
    }
    else {
      setFilters({ ...filters, twentyFourHours: false, sevenDays: false, tenDays: false })
      ns.saveChange("from", undefined)
      ns.saveChange("to", undefined)
    }
  }

  function calculateLast24Hours(e) {
    periodDefault(e, false, true, false, false);
  }

  function calculateLast7Days(e) {
    periodDefault(e, 6, false, true, false);
  }

  function calculateLast10Days(e) {
    periodDefault(e, 9, false, false, true);
  }

  return (
    <>
      <FzCard>
        <div style={{ display: "block", padding: '1em'}}>
            <div className="row">
              <div className="col-3">
                <div style={{ display: "flex" }}>
                  <FieldConnected meta={ticketsReportFilters.from} data={period} ns={ns} />
                  <FieldConnected meta={ticketsReportFilters.to} data={period} ns={ns} />
                </div>
                <div style={{ display: "block" }}>
                  <FzCheckbox checked={filters.twentyFourHours} onChange={calculateLast24Hours} inline> {"Last 24 hours"} </FzCheckbox>
                  <FzCheckbox checked={filters.sevenDays} onChange={calculateLast7Days} inline> {"Last 7 days"} </FzCheckbox>
                  <FzCheckbox checked={filters.tenDays} onChange={calculateLast10Days} inline> {"Last 10 days"} </FzCheckbox>
                </div>
              </div>
              <div className="col">
                <FieldConnected meta={ticketsReportFilters.notInitialized} data={period} ns={ns} />
              </div>
          </div>
        </div>
      </FzCard>
      <div className="mt-3">
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title componentClass="h2">Hermes Ticket Attendance</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <FzTable
              exportDownload={true}
              pagination
              ns={ns}
              context={"hermesTickets"}
              data={data}
              metas={period.notInitialized == true ? notInitializedMeta : ticketsReportTableMeta}
              visibleColumns={[
                "applicationId",
                "created",
                "closedDate",
                "_id",
                "queueName",
                "event",
                "relation",
                "outcome",
                "closedReason",
                "closedByUserName",
                "cadencia",
                "contacts.notificationsSent",
                "contacts.moreNotifications",
              ]}
            />
          </FzCard.Body>
        </FzCard>
      </div>
    </>
  )
}