import React, { useState, ReactNode } from 'react';
import { FzCard } from '../../ui/fz/layout/FzCard';
import { FzNavSelect } from '../../ui/fz/nav';
import { Analytics } from './analytics';
import { Summaries } from './summaries';
import { boaVistaReportsContainerProps } from './types';

function BoaVistaReportsContainer({ 
  handlePage, 
  typeView, 
  ns,
  role,
  filters,
  data,
  handleSearchReport,
  showTost,
  handleTostClose,
  documentDomains
}: boaVistaReportsContainerProps) {

  const contents = {
    analytics: (
      <Analytics 
        filters={filters}  
        role={role} 
        ns={ns} 
        data={data} 
        handleSearchReport={handleSearchReport} 
        showToast={showTost}
        handleToastClose={handleTostClose}
        documentDomains={documentDomains}
      />
    ),
    summaries: ( 
      <Summaries 
        ns={ns} 
        data={data} 
        handleSearchReport={handleSearchReport} 
      />
    ),
  }
  

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={handlePage}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[typeView] || 'Aguardando seleção de relatório'}
      </FzCard>
    </>
  )
}

export { BoaVistaReportsContainer }
