import React from 'react';
import { useEffect, useState } from "react";
import clone from "lodash/cloneDeep"
import get from 'lodash/get';

import { StatsDetailHeader } from "../../../../statistics/stats-components";
import { FzTable } from "../../../../ui/fz/grid/table";
import { FzCol, FzRow, FzSplitLine } from "../../../../ui/fz/layout";
import { FzCard } from "../../../../ui/fz/layout/FzCard";
import { FzNavSelect } from "../../../../ui/fz/nav";
import { SummariesReportProps } from "./types";
import { metaSummariesReport } from './metadata';
import { Meta } from '../../../../types/metas';

export function SummariesReport({
  ns,
  data,
  handleSearchReport,
  role,
  filters,
}: SummariesReportProps) {

  const [selectedPeriod, setSelectedPeriod] = useState(undefined)
  const [exibitionSelect, setExibitionSelect] = useState("Daily")
  const [selectIn, setSelect] = useState("")
  const [editionApplicationResult, setEditionApplicationResult] = useState([])
  const [supplierList, setSupplierList] = useState<string[]>([])

  useEffect(() => {
    getData(data)
  }, [selectedPeriod])

  function getData(data) {

    if (data.result && data.result.length > 0 && selectedPeriod) {
      let { values, supplierList } = handleFilterDates(data)

      setEditionApplicationResult(values)
      setSupplierList(supplierList)
    }
  }

  function SORT_LABEL(a, b) {
    if (a.label > b.label) {
      return 1
    } else if (a.label < b.label) {
      return -1
    } else {
      return 0
    }
  }

  function handleFilterDates(data) {
    const values = data.result.filter(it => it.period === selectedPeriod)[0].fields.sort(SORT_LABEL)
    const supplierList = data.result.filter(it => it.period === selectedPeriod)[0].suppliersInternalName
    return { values, supplierList }
  }

  const handleChangeSelect = (period) => {
    setSelect(period);
    switch (period) {
      case "day":
        setExibitionSelect("Daily")
        // setSelectedPeriod(undefined)
        break;
      case "week":
        setExibitionSelect("Weekly")
        // setSelectedPeriod(undefined)
        break;
      case "month":
        setExibitionSelect("Monthly")
        // setSelectedPeriod(undefined)
        break;
    }
    handleSearchReport({ period })
  }

  function handleChangePeriod(event) {
    setSelectedPeriod(event)
  }

  let customMetas: Meta = metaSummariesReport
  if (data && selectedPeriod && supplierList.length >= 0) {
    buildMetadata(supplierList);
  }

  function buildMetadata(suppliersList: string[]) {
    if (data && data.result && data.result.length > 0) {
      let parceiros = suppliersList;
      customMetas = parceiros.reduce((resultSoFar, supplierName) => {

        let templete = clone(get(metaSummariesReport, 'templete'));

        templete.path = supplierName;
        templete.tableProps.formatExtraData = supplierName;
        templete.tableProps.spanColumns.columns[0].label = supplierName;
        templete.tableProps.formatExtraData = `total_${supplierName}`

        return {
          ...resultSoFar,
          [supplierName]: templete
        };
      }, {
        ...metaSummariesReport,
      });

      delete customMetas.templete;
    }
  }

  return (
    < FzCard >
      <FzCard.Title>
        <FzCard.Header className='w-100'>
          <FzSplitLine>
            <FzSplitLine.Left>
            </FzSplitLine.Left>
            <FzSplitLine.Right>
              <FzNavSelect
                selected={selectIn}
                onChange={handleChangeSelect}
                options={[
                  { "code": "day", "description": "Daily" },
                  { "code": "week", "description": "Weekly" },
                  { "code": "month", "description": "Monthly" },
                ]}
              />
            </FzSplitLine.Right>
          </FzSplitLine>
          <FzRow>
            <FzCol span={12}>
              <StatsDetailHeader
                title={`Summaries Edição de Propostas`}
                stats={data.rawData}
                periods={data.periods}
                currPeriod={selectedPeriod}
                onChange={handleChangePeriod}
              />
            </FzCol>
          </FzRow>
        </FzCard.Header>
      </FzCard.Title>

      <FzCard.Body>
        <FzTable
          data={editionApplicationResult}
          ns={ns}
          metas={customMetas}
          visibleColumns={[
            'label',
            ...supplierList,
            'totalField'
          ]}
          clipboard={false}
          exportDownload={false}
        />
      </FzCard.Body>

    </FzCard >
  )
}
