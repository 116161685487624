
export const formalizationAnalytic = {
  "applicationId": {
    "path": "applicationId",
    "dataType": "number",
    "label": "App Id",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "14ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": "applicationIdColumn",
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "id": {
    "path": "id",
    "dataType": "number",
    "label": "Formalization Id",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "26ch",
      "filter": null,
      "dataSort": false,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "created": {
    "path": "created",
    "dataType": "date",
    "label": " Formalization Created",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "10em",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "periodColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": "Formalization created date"
  },
  "personalNumber": {
    "path": "personalNumber",
    "dataType": "number",
    "label": "CPF",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "15ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": null,
      "dataAlign": null,
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": "User personal id"
  },
  "supplierApplicationId": {
    "path": "supplierApplicationId",
    "dataType": "number",
    "label": "Supplier's id",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "24ch",
      "filter": {
        "type": "TextFilter",
        "delay": 500
      },
      "dataSort": null,
      "dataAlign": null,
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": "Supplier's internal application number"
  },
  "formalizationState": {
    "path": "formalizationState",
    "dataType": "number",
    "label": "Current Status",
    "domainName": "formalization-state",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "10em",
      "filter": { "type": "SelectDomain" },
      "dataSort": true,
      "dataAlign": null,
      "columnFormat": "getDescriptionsByDomains",
      "formatExtraData": "formalization-state",
      "isKey": false,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "countPending": {
    "path": "countPending",
    "dataType": "number",
    "label": "Pending documents",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "15ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": null,
      "columnFormat": "numberColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": "Number of pending documents"
  },
  "countSent": {
    "path": "countSent",
    "dataType": "number",
    "label": "Sent documents",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "15ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": null,
      "columnFormat": "numberColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": "Number of sent documents"
  },
  "branchCode": {
    "path": "branchCode",
    "dataType": "string",
    "label": "Branch Code",
    "tableProps": {
      "viewsTable": "|formalizationListColumns|",
      "hidden": null,
      "shortLabel": null,
      "width": "15ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": "Branch Code"
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "dataType": "string",
    "label": "SupplierInternalName",
    "tableProps": {
      "hidden": true,
      "isKey": false,
    }
  },
}

export const formalizationAnalyticInside = {
  "documentType": {
    "path": "documentType",
    "dataType": "text",
    "label": "Tipo de documento",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "20rem",
      "filter": null,
      "dataSort": true,
      "dataAlign": "rigth",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "_created": {
    "path": "created",
    "dataType": "date",
    "label": "Enviado pelo cliente",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "9rem",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateTimeColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "needUpload": {
    "path": "needUpload",
    "dataType": "bool",
    "label": "Status",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5rem",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "statusColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "sentDate": {
    "path": "sentDate",
    "dataType": "date",
    "label": "Enviado p/Banco",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "9rem",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "dateTimeColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "partnerDocumentId": {
    "path": "partnerDocumentId",
    "dataType": "id",
    "label": "Nº Formalização",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "20rem",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "actions": {
    "path": "actions",
    "dataType": "dataType",
    "label": "actions",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5rem",
      "filter": null,
      "dataSort": true,
      "dataAlign": "rigth",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "tags": {
    "path": "tags",
    "dataType": "array",
    "label": "Tags",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "20rem",
      "filter": { "type": 'TextFilter', "delay": 500 },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "sizeMB": {
    "path": "sizeMB",
    "dataType": "number",
    "label": "Size",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "5rem",
      "filter": undefined,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "numberColumn",
      "isKey": null,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },

}

export const formalizationSummary = {
  "key": {
    "path": "key",
    "dataType": "id",
    "label": "#",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "50",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Period",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "10em",
      "filter": { type: 'SelectFilter', options: "buildPeriodFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "supplier": {
    "path": "supplier",
    "dataType": "number",
    "label": "Supplier",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "formalizationstate": {
    "path": "formalizationstate",
    "dataType": "domains",
    "label": "Current Status",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": { type: 'SelectFilter', options: "statesFormalizationDescriptions", condition: 'eq' },
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": "getFormalizationStateDescription",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "total": {
    "path": "total",
    "dataType": "number",
    "label": "Total",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "sentToPartnerAll": {
    "path": "sentToPartnerAll",
    "dataType": "number",
    "label": "Sent All",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColun",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "hasPending": {
    "path": "hasPending",
    "dataType": "bool",
    "label": "Has Pending",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "hasRejected": {
    "path": "hasRejected",
    "dataType": "bool",
    "label": "Has Rejected",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": "numberColumn",
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "hasPartnerGenerated": {
    "path": "hasPartnerGenerated",
    "dataType": "bool",
    "label": "Has Generated",
    "tableProps": {
      "viewsTable": "",
      "hidden": null,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "right",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}

export const formalization_funnel = {
  "_id": {
    "path": "_id",
    "dataType": "id",
    "label": "ID",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": true,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": true,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-address": {
    "path": "avg-retries-address",
    "dataType": "number",
    "label": "Comprovante endereço(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-income": {
    "path": "avg-retries-income",
    "dataType": "number",
    "label": "Comprovante renda (Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-income60": {
    "path": "avg-retries-income60",
    "dataType": "number",
    "label": "Comprovante renda 60 dias(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-income90": {
    "path": "avg-retries-income90",
    "dataType": "number",
    "label": "Comprovante renda 90 dias(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-rg-back": {
    "path": "avg-retries-rg-back",
    "dataType": "number",
    "label": "RG Verso(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-rg-front": {
    "path": "avg-retries-rg-front",
    "dataType": "number",
    "label": "RG Frente(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-selfie": {
    "path": "avg-retries-selfie",
    "dataType": "number",
    "label": "Selfie(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "avg-retries-selfie-id": {
    "path": "avg-retries-selfie-id",
    "dataType": "number",
    "label": "Selfie ID(Média tentativas)",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-formalizations": {
    "path": "count-formalizations",
    "dataType": "number",
    "label": "Número Formalizações",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-ocr-rejection": {
    "path": "count-ocr-rejection",
    "dataType": "number",
    "label": "Número rejeições pelo OCR",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-advisor-rejection": {
    "path": "count-advisor-rejection",
    "dataType": "number",
    "label": "Número rejeições pelo advisor",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-supplier-rejection": {
    "path": "count-supplier-rejection",
    "dataType": "number",
    "label": "Número rejeições pelo supplier",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-sent-supplier": {
    "path": "count-sent-supplier",
    "dataType": "number",
    "label": "Número enviados para supplier",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-sent-supplier-once": {
    "path": "count-sent-supplier-once",
    "dataType": "number",
    "label": "Número enviados para supplier uma vez",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-paid": {
    "path": "count-paid",
    "dataType": "number",
    "label": "Número de pagos",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "count-untouched": {
    "path": "count-untouched",
    "dataType": "number",
    "label": "Número de untouched",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "period": {
    "path": "period",
    "dataType": "date",
    "label": "Período",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
  "product": {
    "path": "product",
    "dataType": "string",
    "label": "Produto",
    "tableProps": {
      "viewsTable": undefined,
      "hidden": false,
      "shortLabel": null,
      "width": "12ch",
      "filter": null,
      "dataSort": true,
      "dataAlign": "center",
      "columnFormat": null,
      "isKey": false,
      "exportFormat": null,
      "rowSpan": null,
      "sortFunc": null
    },
    "tip": null
  },
}