import { DeviceHubOutlined } from "@material-ui/icons";
import { ReactElement, ReactNode } from "react";
import { FzToast } from "../../ui/fz/form/notification/toast";
import { FzCard } from "../../ui/fz/layout/FzCard";
import { FzNavSelect } from "../../ui/fz/nav";
import { AnalyticsCustomerDeletedReport } from "./analytics";
import { SummariesCustomerDeletedReport } from "./summaries";
import { DeletedCustomerContainerProps } from "./types";

export function DeletedCustomerContainer({
  data,
  typeView,
  ns, 
  filters,
  handleSearchReport,
  handlePage,
  currentPeriod,
  showToast,
  bodyMessageTost,
  handleTostClose,
}: DeletedCustomerContainerProps): ReactElement {

  const contents = {
    analytics: (
      <AnalyticsCustomerDeletedReport
        data={data}
        ns={ns}
        filters={filters}
        handleSearchReport={handleSearchReport}
      />
    ),
    summaries: (
      <SummariesCustomerDeletedReport
        ns={ns}
        data={data}
        currentPeriod={currentPeriod}
        handleSearchReport={handleSearchReport}
      />
    ),
  }

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={handlePage}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[typeView] || 'Aguardando seleção de relatório'}
      </FzCard>
      <FzToast
        fzStyle="danger"
        show={showToast}
        delay={5000}
        headerMessageTost="Error"
        bodyMessageTost={bodyMessageTost}
        autoHide={true}
        close={handleTostClose}
      />
    </>

  )
}