import React from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../style/form.css';
import '../style/module/auto-complete.css';
import cloneDeep from 'lodash/cloneDeep';
import DatePicker from 'react-date-picker';
import MaskedInput from 'react-maskedinput';
import { injectIntl } from 'react-intl';
import DateTimePicker from 'react-datetime';
import moment from 'moment';
import { FzIcons } from './icon';
import { dialTelephone } from '../../../api/pabx';
import api from '../../../api/client-api';
import { FzCol, FzRow } from '../layout';
import { FzForm } from '../layout';
import Picker from 'emoji-picker-react';
import Select, {
  components,
} from 'react-select';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { FzButton } from './button';
import { FzField } from './field';

const URL = 'https://web.whatsapp.com/send?phone=+55';

class FzCheckbox extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    checked: PropTypes.bool,
    inline: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onChangeType: PropTypes.string,
  };

  render() {
    const {
      children,
      checked,
      inline,
      onChange,
      onChangeType,
      name,
      isDisabled,
      value
    } = this.props;
    const onChangeFunc = (
      onChangeType === 'event'
        ? event => onChange(event)
        : (e, key) => onChange(e.target.checked, key)
    )

    return (
      <FzForm.Check
        isDisabled={isDisabled}
        name={name}
        checked={checked}
        inline={inline}
        value={value}
        onChange={onChangeFunc}
      >
        <React.Fragment>{children}</React.Fragment>
      </FzForm.Check>
    );
  }
}
class FzText extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    showLength: PropTypes.bool,
    maxSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
    validationMessage: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.state = { value: null };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  saveOnEnter(event) {
    if (event.keyCode === 13) {
      this.saveValue();
    }
  }

  saveValue() {
    if (this.state.value !== this.props.value) {
      if (this.state.value || this.state.value === "") {
        this.props.onChange(this.state.value.trim(), this.props.dataType);
      }
    }
    this.setState({ value: null });
  }

  render() {

    let showCurrentLength = null;
    if (this.props.showLength) {
      let length = this.props.value ? this.props.value.length : 0;
      if (this.props.maxSize) {
        length = length + '/' + this.props.maxSize;
      }
      showCurrentLength = <div className="fz-overlay-size">{length}</div>;
    }
    const value = (this.state.value === null ? this.props.value : this.state.value) || '';

    const type = this.props.type || 'text';

    return (
      <>
        <FzForm.Control
          name={this.props.name}
          type={type}
          className={this.props.className}
          readOnly={this.props.readOnly}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.saveOnEnter}
          onFocus={this.loadValue}
          onBlur={this.saveValue}
          placeholder={this.props.placeHolder}
          isInvalid={this.props.invalid}
        />
        {showCurrentLength}
      </>
    );
  }
}
class FzTextarea extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    showLength: PropTypes.bool,
    maxSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.state = { value: null };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  saveOnEnter(event) {
    if (event.keyCode === 13) {
      this.saveValue();
    }
  }

  saveValue() {
    if (this.state.value !== this.props.value) {
      if (this.state.value) {
        this.props.onChange(this.state.value.trim(), this.props.dataType);
      }
    }
    this.setState({ value: null });
  }

  render() {

    let showCurrentLength = null;
    if (this.props.showLength) {
      let length = this.props.value ? this.props.value.length : 0;
      if (this.props.maxSize) {
        length = length + '/' + this.props.maxSize;
      }
      showCurrentLength = <div className="fz-overlay-size">{length}</div>;
    }
    let value = (this.state.value === null ? this.props.value : this.state.value) || '';

    return (
      <div className="fz-form-control-container">
        <FzForm.Control
          type="text"
          as="textarea"
          value={value}
          name={this.props.name}
          onChange={this.handleChange}
          onKeyDown={this.saveOnEnter}
          onFocus={this.loadValue}
          onBlur={this.saveValue}
          style={{ height: '72px' }}
          readOnly={this.props.readOnly}
          isInvalid={this.props.invalid}
          className={this.props.className}

        />
        {showCurrentLength}
      </div>
    );

  }
}
class FzTextAreaOptionsEmojicon extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    showLength: PropTypes.bool,
    maxSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.setOptions = this.setOptions.bind(this)
    this.state = { value: null, chosenEmoji: null };
    this.handleEmojiClick = this.handleEmojiClick.bind(this)
  }

  handleChange(e) {
    this.setState({ value: e.target.value }, () => {
      this.props.onChange(this.state.value.trim(), this.props.dataType);
    });
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  saveOnEnter(event) {
    if (event.keyCode === 13) {
      this.saveValue();
    }
  }

  saveValue() {
    if (this.state.value !== this.props.value) {
      if (this.state.value) {
        this.props.onChange(this.state.value.trim(), this.props.dataType);
      }
    }
    this.setState({ value: null });
  }

  setOptions(value) {
    let data = (this.state.value === null ? this.props.value : this.state.value) || '';
    if (value && value.target && value.target.name && typeof value.target.name === "string") {
      let appendString = data + " {{" + value.target.name + '}} '
      this.setState({ value: appendString }, () => this.saveValue())
    }
    return value
  }

  handleEmojiClick(event, emojiObject) {
    let data = (this.state.value === null ? this.props.value : this.state.value) || '';
    let dataWithEmoji = data + emojiObject.emoji + " "
    this.setState({ chosenEmoji: emojiObject, value: dataWithEmoji }, () => this.saveValue())
  };


  getRowsWithFields() {
    let lastRow = [];
    let rows = [];

    for (let iteratorFieldElms = 1; iteratorFieldElms < this.props.options.length; iteratorFieldElms++) {

      lastRow.push(
        <FzCol span={6}>
          <button
            disabled={this.props.readOnly}
            style={{
              backgroundColor: "transparent",
              alignSelf: "center", borderRadius: "5px", border: "2px solid #dedede"
            }}
            name={this.props.options[iteratorFieldElms].code}
            onClick={this.setOptions}>
            {this.props.options[iteratorFieldElms].description}
          </button>
        </FzCol>
      )

      if (iteratorFieldElms % 2 === 0) {
        rows.push(
          <FzRow>
            {lastRow}
          </FzRow>);
        lastRow = [];
      }
    }

    return rows;
  }

  render() {

    let showCurrentLength = null;
    if (this.props.showLength) {
      let length = this.props.value ? this.props.value.length : 0;
      if (this.props.maxSize) {
        length = length + '/' + this.props.maxSize;
      }
      showCurrentLength = <div className="fz-overlay-size">{length}</div>;
    }
    let value = (this.state.value === null ? this.props.value : this.state.value) || '';

    const fieldsRows = this.getRowsWithFields();


    return (
      <div>
        <div className="fz-form-control-container">
          <FzForm.Control
            type="text"
            as="textarea"
            value={value}
            name={this.props.name}
            onChange={this.handleChange}
            onKeyDown={this.saveOnEnter}
            onFocus={this.loadValue}
            onBlur={this.saveValue}
            style={{ height: '80px', width: "100%" }}
            readOnly={this.props.readOnly}
            isInvalid={this.props.invalid}
          />
          {showCurrentLength}
        </div>
        <div>

          <FzRow>
            <FzCol span={6}>
              <div >
                {fieldsRows.map(field => field)}
              </div>
            </FzCol>
            {this.props.emoticons === true ?
              <FzCol>
                <div className={"fz-textarea-whatsApp"}>
                  <Picker
                    onEmojiClick={this.handleEmojiClick}
                    disableSearchBar={true}
                  />
                </div>
              </FzCol> : null}
          </FzRow>
        </div>
      </div>
    );
  }
}

class FzCodeEditor extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    showLength: PropTypes.bool,
    maxSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.state = { value: null };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  loadValue() {
    if (!this.props.readOnly) {
      this.setState({ value: JSON.stringify(this.props.value, null, 2) });
    }
  }

  saveValue() {
    if (this.state.value !== null) {
      this.props.onChange(this.state.value.trim(), this.props.dataType);
    }
    this.setState({ value: null });
  }

  render() {

    let showCurrentLength = null;
    if (this.props.showLength) {
      let length = this.props.value ? this.props.value.length : 0;
      if (this.props.maxSize) {
        length = length + '/' + this.props.maxSize;
      }
      showCurrentLength = <div className="fz-overlay-size">{length}</div>;
    }
    let value = (this.state.value === null ? JSON.stringify(this.props.value, null, 2) : this.state.value) || '';

    return (
      <div className="fz-form-control-container">
        <FzForm.Control
          type="text"
          as="textarea"
          value={value}
          name={this.props.name}
          onChange={this.handleChange}
          onFocus={this.loadValue}
          onBlur={this.saveValue}
          style={{ height: '144px', fontFamily: 'Roboto Mono' }}
          readOnly={this.props.readOnly}
          wrap="hard"
          isInvalid={this.props.invalid}
        />
        {showCurrentLength}
      </div>
    );

  }
}

class FzSelect extends React.Component {
  constructor(props) {
    super(props);
    this.requesting = false;
  }

  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    domainName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };


  render() {

    const { readOnly, value, name, onChange, extraChangeEffect, dataType, placeHolder, fzStyle } = this.props;
    let options = this.props.options || [];
    // this.props.options sometimes is coming as an object TO-DO: check why
    if (!Array.isArray(options)) {
      options = []
    }

    return (
      <FzForm.Control
        isInvalid={this.props.invalid}
        name={name}
        as="select"
        placeholder="Selecione"
        disabled={readOnly}
        value={value}
        style={fzStyle}
        onChange={(e) => {
          onChange(e.target.value.trim(), dataType)
          if (extraChangeEffect) {
            extraChangeEffect(e.target.value)
          }
        }
        }>

        <option key="-1" value="" defaultValue={value === undefined || value === null || value === ""}>{(placeHolder || "Selecione ...")}</option>
        {
          options.map((opt, i) => {
            const inactiveOption = opt?.description?.includes("Inativo");
            return (<option key={i} style={{backgroundColor: inactiveOption && "gray", color: inactiveOption && "white"}} value={opt.code}>{opt.description}</option>);
          })
        }
      </FzForm.Control>
    );
  }
}

class FzAutoComplete extends React.Component {

  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    domainName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disableSaveOnEnter: PropTypes.bool,
    lookupIncludingCode: PropTypes.bool
  };


  constructor(props) {
    super(props);
    this.state = { value: undefined, currentIndex: 0, show: false };
    this.inputAutoComplete = null;
    this.suggestionList = React.createRef();
    this.activeItem = React.createRef();
    this.loadCache = this.loadCache.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    this.move = this.move.bind(this);
  }

  saveOnEnter(event) {
    if (event.keyCode === 38) {
      this.move(-1);
    } else if (event.keyCode === 40) {
      this.move(1);
    } else if (event.keyCode === 13 || event.keyCode === 9) {
      const suggestionList = this.props.options || [];
      const writed = this.state.value ? this.state.value.split(' ').join('.*') : '';
      const reg = new RegExp('(' + writed + ')', 'i');
      const suggestions = suggestionList.filter(item => reg.test(item.description));
      this.setState({ value: undefined, show: false }, function () {
        this.props.onChange(suggestions[this.state.currentIndex].description);
        ReactDOM.findDOMNode(this.inputAutoComplete).blur();
      });
    }
  }


  move(amount) {
    if (this.activeItem && this.activeItem.current) {
      this.suggestionList.current.childNodes[0].scrollTo(0, this.activeItem.current.offsetTop - 100);
      this.setState({ currentIndex: this.state.currentIndex + amount });
    }
  }


  loadCache() {
    this.setState({ value: this.props.value, show: true });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }


  handleSuggestionClick(event, item) {
    event.preventDefault();
    this.setState({ value: undefined, show: false }, function () {
      this.props.onChange(item.code, this.props.dataType);
    });
    //ReactDOM.findDOMNode(this.inputAutoComplete).blur();
  }


  render() {
    let { dataType, name, value, options, readOnly, placeholder, disableSaveOnEnter, lookupIncludingCode } = this.props;

    value = (value === undefined) || (value === null) ? '' : value;
    const suggestionList = options || [];
    const writed = this.state.value ? this.state.value.replace(/[^\w]/g, '').split(' ').join('.*') : '';
    const reg = new RegExp('(' + writed + ')', 'i');
    const suggestions = suggestionList
      .filter(item => reg.test(item.description) || lookupIncludingCode && reg.test(item.code))
      .map((item, k) => {
        return <li key={k}
          ref={this.state.currentIndex === k ? this.activeItem : undefined}
          className={'fz-auto-complete--item ' + (this.state.currentIndex === k ? 'selected' : '')}
          onClick={(e) => this.handleSuggestionClick(e, item)}>
          {item.description}
        </li>;
      });
    const content = this.state.value === undefined ? value : this.state.value;
    return (
      <div className={'fz-auto-complete'}>
        <FzForm.Control
          isInvalid={this.props.invalid}
          inputRef={(ref) => {
            this.inputAutoComplete = ref;
          }}
          disabled={!!readOnly}
          autoComplete={'off'}
          id={name}
          type={dataType}
          value={content}
          placeholder={placeholder}
          onChange={this.handleChange}
          onFocus={this.loadCache}
          onKeyDown={disableSaveOnEnter ? undefined : this.saveOnEnter}
        />
        <div
          className={'fz-auto-complete--container'}
          style={{ visibility: `${this.state.show ? 'visible' : 'hidden'}` }}>
          <ul className={'fz-auto-complete--list'} ref={this.suggestionList}>
            {suggestions.length > 0 ? suggestions : null}
          </ul>
        </div>
        {this.props.copyButton && <button className={'btn btn-primary'} disabled={!content} onClick={() => this.props.copyButton.action(content)}>{this.props.copyButton.text}</button>}
      </div>
    );
  }
}

class FzMultiSelect extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    readOnly: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    domainName: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.checkboxRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onClickCheckboxItem = this.onClickCheckboxItem.bind(this);
  }

  onChange(e) {
    var newval = [...(this.props.value || []), e.target.value];

    if (!e.target.checked) {
      newval = newval.filter(it => it !== e.target.value);
    }
    this.props.onChange(newval);
  }
  onClickCheckboxItem(valueToSwitch) {
    const { readOnly } = this.props
    if (!readOnly) {
      const prevValues = this.props.value || []
      if (prevValues.includes(valueToSwitch)) {
        const filteredValues = prevValues.filter(val => val !== valueToSwitch)
        this.props.onChange(filteredValues)
      } else {
        const arrayWithNewValue = prevValues.concat(valueToSwitch)
        this.props.onChange(arrayWithNewValue)
      }
    }
  }

  render() {
    const { name, order, direction, customClass } = this.props
    let inputSize = 'input-sm';
    if (this.props.fzStyle === 'large' || this.props.fzStyle === 'lg' || this.props.fzStyle === 'big') {
      inputSize = 'input-lg';
    }
    const { readOnly } = this.props;
    const options = this.state.options || this.props.options || [];
    const value = this.props.value || [];
    let checksInputs = options.map((opt, i) => {
      return (
        <div
          key={i}
          className={customClass ? customClass : 'col-lg-6 col-md-12'}
          style={{ display: 'flex', alignItems: 'baseline' }}
        >
          <input
            type="checkbox"
            disabled={readOnly}
            value={opt.code}
            ref={this.checkboxRef}
            className={`${order === 'left-to-right' ? 'mr-2' : 'ml-2'}`}
            checked={value.indexOf(opt.code) < 0 ? false : true}
            onChange={this.onChange} />
          <div
            className={'checks-inline ' + inputSize} key={i}
            onClick={() => {
              this.onClickCheckboxItem(opt.code)
            }}
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              alignItems: direction === 'column' ? 'baseline' : 'center',
              flexDirection: order === 'left-to-right' ? 'row-reverse' : ''
            }}>
            {opt.description}

          </div>
        </div>
      );
    });
    return (
      <div className={'container-fluid'}>
        <div
          disabled={readOnly}
          className="form-control row"
          style={{
            display: 'flex',
            alignItems: direction === 'column' ? 'flex-start' : 'center',
            flexDirection: direction === 'column' ? 'column' : 'row',
            flexWrap: 'wrap',
            flex: 1,
            height: 'auto',
            width: 'auto',
            maxHeight: "400px",
            overflowY: "auto",
          }}
          name={name}
        >
          {checksInputs}
        </div>
      </div>
    );
  }
}


// https://react-select.com/advanced#sortable-multiselect
const FzMultiSelectAutoCompleteSortable = ({ defaultValue = [], onChange, options, readOnly, value = [] }) => {
  // start of auxiliary functions:
  function arrayMove(array, from, to) {
    const slicedArray = array.slice();
    slicedArray.splice(
      to < 0 ? array.length + to : to,
      0,
      slicedArray.splice(from, 1)[0]
    );
    return slicedArray;
  }

  const SortableMultiValue = SortableElement(
    (props) => {
      // this prevents the menu from being opened/closed when the user clicks
      // on a value to begin dragging it. ideally, detecting a click (instead of
      // a drag) would still focus the control and toggle the menu, but that
      // requires some magic with refs that are out of scope for this example
      const onMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
      const innerProps = { ...props.innerProps, onMouseDown };
      return <components.MultiValue {...props} innerProps={innerProps} />;
    }
  );

  const SortableMultiValueLabel = SortableHandle(
    (props) => <components.MultiValueLabel {...props} />
  );

  const SortableSelect = SortableContainer(Select);
  // end of auxiliary functions

  // start of component handler logic
  let values = (value && Array.isArray(value) ? value : (value.value ? [value] : defaultValue)).map(el => ({ value: el, label: el }))

  const onChangeCall = (v) => {
    return onChange(v.map(el => el.value))
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(values, oldIndex, newIndex);
    onChangeCall(newValue);
  };
  // end of component handler logic
  return (
    <SortableSelect
      useDragHandle
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      closeMenuOnSelect={false}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      styles={{
        multiValueLabel: (base) => ({
          ...base,
          backgroundColor: "white",
          color: 'black',
        }),
      }}
      isMulti
      isDisabled={readOnly}
      // data props:
      defaultValue={values}
      options={options && options.map(el => ({ value: el.code, label: el.description }))}
      onChange={onChangeCall}
      value={values}
    />
  );
}

const FzMultiSelectAutoComplete = ({ defaultValue = [], onChange, options, readOnly, value = [] }) => {
  const onChangeCall = (v) => {
    return onChange(v.map(el => el.value))
  }
  let values = (value && Array.isArray(value) ? value : (value.value ? [value] : defaultValue)).map(el => ({ value: el, label: el }))
  return (<Select
    closeMenuOnSelect={false}
    // components={{ MultiValueLabel }}
    styles={{
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: "white",
        color: 'black',
      }),
    }}
    isDisabled={readOnly}
    defaultValue={values}
    isMulti
    useDragHandle
    options={options && options.map(el => ({ value: el.code, label: el.description }))}
    onChange={onChangeCall}
    value={values}
  />)
};

const FzMapStringString = ({ name, className, readOnly, placeHolder, invalid, defaultValue = {}, onChange, value = {} }) => {

  const renameKey = (keyToReplace, newKey) => {
    return Object.keys(value)
      .reduce((prev, curr) => {
        let newK = curr == keyToReplace ? newKey : curr;
        return {
          ...prev,
          [newK]: value[curr]
        }
      }, {})
  }
  const handleChange = (key, val, newKey) => {
    if (newKey != key) {
      onChange(renameKey(key, newKey, value))
    } else {
      handleAddKey(key, val)
    }
  }
  const handleAddKey = (key = "", val = "") => {
    value[key] = val
    onChange(value)
  }

  const removeKey = (key) => {
    delete value[key]
    onChange(value)
  }

  const addButton = readOnly ? null : <FzButton onClick={() => handleAddKey()} fzStyle="regular">
    Adicionar {name}
  </FzButton>

  const fields = Object.keys(value)
    .map((k, index) => {
      const handleKeyUpdate = (newValue) => handleChange(k, value[k], newValue)
      const handleValueUpdate = (newValue) => handleChange(k, newValue, k)
      
      const inputSpan = readOnly ? 6 : 5;
      const removeSpan = 2
      const removeButton = readOnly ? null : <FzCol span={removeSpan} key={`${name}-removeButton-${k}-${index}`}>
        <Form.Group>
          <FzButton  onClick={() => removeKey(k)} fzStyle="alert">X</FzButton>
        </Form.Group>
      </FzCol>;

      const v = value[k]
      return <FzRow key={`${name}-row-${k}`}>
        <FzCol span={inputSpan} key={`${name}-col-${k}-${index}`}>
          <Form.Group>
            <FzForm.Label key={`${name}-label-${k}-${index}`}>
                {"Chave"}
            </FzForm.Label>
            <FzField key={`${name}-control-${k}-${index}`}
              name={`${name}.${k}.k`}
              type={"text"}
              className={className}
              readOnly={readOnly}
              value={k}
              onChange={handleKeyUpdate}
              placeholder={"Chave"}
              isInvalid={invalid}
              />
          </Form.Group>
        </FzCol>
        <FzCol span={inputSpan} key={`${name}-col2-${k}-${index}-2`}>
          <Form.Group>
            <FzForm.Label>
                {"Valor"}
              </FzForm.Label>
                <FzField
                name={`${name}.${k}.v`}
                type={"text"}
                className={className}
                readOnly={readOnly}
                value={v}
                onChange={handleValueUpdate}
                placeholder={"Valor"}
                isInvalid={invalid}
              />
          </Form.Group>
        </FzCol>
        <FzCol span={inputSpan} key={`${name}-col3-${k}-${index}-2`}>
          {removeButton}
        </FzCol>
      </FzRow>
    })
  return ( <FzRow>
      <FzCol span={12} key={`${name}-col-fields-${Math.random()}`}>
          {fields}
      </FzCol>
      <FzCol span={12} key={`${name}-col-addbutton`}>
        <Form.Group>
          {addButton}
        </Form.Group>
      </FzCol>
    </FzRow>)
}

class FzRadio extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    valueSelected: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange(this.props.value, this.props.dataType);
  }

  render() {

    let inputSize = 'input-sm';
    if (this.props.fzStyle === 'large' || this.props.fzStyle === 'lg' || this.props.fzStyle === 'big') {
      inputSize = 'input-lg';
    }
    const { valueSelected, name, readOnly } = this.props;
    const value = this.props.value || '';
    return (
      <div disabled={readOnly}
        onFocus={this.loadValue}
        onBlur={this.saveValue}
        className="fz-radio"
      >
        <input className={`radio-input ${inputSize}`}
          id={`${name}-${value}`}
          type="radio"
          name={name}
          value={value}
          checked={valueSelected === value}
          disabled={readOnly}
          onChange={this.onChange} />
      </div>
    );
  }
}

class FzRadioGroup extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    readOnly: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    var newval = this.props.value || [];
    var index = newval.indexOf(e.target.value);
    if (index < 0) {
      newval.push(e.target.value);
    } else {
      newval.splice(index, 1);
    }
    this.props.onChange(newval, this.props.dataType);
  }

  render() {

    let inputSize = 'input-sm';
    if (this.props.fzStyle === 'large' || this.props.fzStyle === 'lg' || this.props.fzStyle === 'big') {
      inputSize = 'input-lg';
    }
    const { name, readOnly } = this.props;
    const options = this.state.options || this.props.options || [];
    const value = this.props.value || '';
    let radios = options.map((opt, i) => {
      return (
        <div className={'radio-container ' + inputSize} key={`${name}-${i}`}>
          <FzRadio name={name} readOnly={readOnly} value={opt.code} valueSelected={value} onChange={this.onChange} />
          <label className={'radio-label'} htmlFor={`${name}-${opt.code}`}>{opt.description}</label>
        </div>
      );
    });
    return (
      <div disabled={readOnly}
        onFocus={this.loadValue}
        onBlur={this.saveValue}
        className="form-control fz-radio"
      >
        {radios}
      </div>
    );
  }
}
class FzDate extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    maxValue: PropTypes.instanceOf(Date),
    minValue: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.state = { value: null };
  }

  handleChange(value) {
    this.setState({ value: value });
    this.props.onChange(value, this.props.dataType);
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  getDateInUTC(value) {

    if (value !== null && value !== undefined) {
      let dayUTC = new Date(value).getUTCDate()
      let monthUTC = new Date(value).getUTCMonth()
      let yearUTC = new Date(value).getUTCFullYear()
      return new Date(yearUTC, monthUTC, dayUTC)
    }
    return undefined
  }

  render() {
    const { readOnly, name, minValue, maxValue } = this.props;

    let minDate;
    let maxDate;

    if (minValue) {
      minDate = minValue && minValue.toISOString ? minValue.toISOString() : minValue;
    }
    if (maxValue) {
      maxDate = maxValue && maxValue.toISOString ? maxValue.toISOString() : maxValue;
    }

    let value = readOnly ? this.props.value : (this.props.value && !this.state.value ? this.props.value : this.props.value);

    if (value instanceof moment) {

      if (value._isValid) {
        value = new Date(...value._a)
      } else {
        value = undefined
      }
    }

    return (
      <DatePicker
        value={this.getDateInUTC(value)}
        maxDate={new Date(maxDate)}
        minDate={new Date(minDate)}
        onChange={this.handleChange}
        disabled={readOnly}
        className={"fz_date_picker"}
        name={name}
        id={name}
        format={"dd-MM-yyyy"}
      />
    );
  }
}

class FzDateTime extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.object || PropTypes.string,
    maxValue: PropTypes.instanceOf(Date).isRequired,
    minValue: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.state = { value: null };
  }

  handleChange(value) {
    this.setState({ value: value });
    this.props.onChange(value, this.props.dataType);
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  render() {
    const { readOnly, name, minValue, maxValue, utc, displayTimeZone } = this.props;

    let minDate;
    let maxDate;

    if (minValue) {
      minDate = minValue && minValue.toISOString ? minValue.toISOString() : minValue;
    }
    if (maxValue) {
      maxDate = maxValue && maxValue.toISOString ? maxValue.toISOString() : maxValue;
    }

    let value;
    value = readOnly ? this.props.value : (this.props.value && !this.state.value ? this.props.value : this.state.value);
    value = (typeof (value) === 'string' && value.slice(0, 4) === '0001') ? null : moment(value);

    const isUtc = utc === undefined ? true : utc
    return (
      <DateTimePicker
        id={name}
        name={name}
        disabled={readOnly}
        value={value}
        onChange={this.handleChange}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={this.saveValue}
        onFocus={this.loadValue}
        utc={isUtc}
        displayTimeZone={displayTimeZone}
        timeFormat="H:mm"
        dateFormat="DD/MM/YYYY"
      />
    );
  }
}

class FzDateV2 extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.state = { value: null };
  }

  handleChange(value) {
    let dateValue;

    try {
      dateValue = value.format("YYYY-MM-DD")
    } catch (error) {
      dateValue = value
    }

    this.setState({ value: dateValue });
    this.props.onChange(value, this.props.dataType);
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  render() {
    const { readOnly, name, minValue, maxValue, utc, displayTimeZone } = this.props;

    let minDate;
    let maxDate;

    if (minValue) {
      minDate = minValue && minValue.toISOString ? minValue.toISOString() : minValue;
    }
    if (maxValue) {
      maxDate = maxValue && maxValue.toISOString ? maxValue.toISOString() : maxValue;
    }

    const isUtc = utc === undefined ? true : utc

    return (
      <DateTimePicker
        id={name}
        name={name}
        disabled={readOnly}
        value={this.props.value}
        onChange={this.handleChange}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={this.saveValue}
        onFocus={this.loadValue}
        utc={isUtc}
        displayTimeZone={displayTimeZone}
        timeFormat={false}
        dateFormat="DD/MM/YYYY"
      />
    );
  }
}

class FzMasked extends React.Component {
  static propTypes = {
    fzStyle: PropTypes.string,
    name: PropTypes.string,
    mask: PropTypes.string,
    removeMask: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.loadValue = this.loadValue.bind(this);
    this.saveOnEnter = this.saveOnEnter.bind(this);
    this.webWhatsapp = this.webWhatsapp.bind(this);
    this.state = { value: null };
  }

  onChange(newVal) {
    this.setState({ value: newVal });
  }

  loadValue() {
    this.setState({ value: this.props.value });
  }

  saveValue() {
    if (!this.props.value || this.props.value.toString() !== this.state.value) {
      let content = this.state.value;
      if (this.props.removeMask && content) {
        content = content.replace(/[^\d\w]/g, '');
      }
      if (content) {
        this.props.onChange(content.trim(), this.props.dataType);
      } else {
        this.props.onChange(content, this.props.dataType);
      }

    }
    this.setState({ value: null });
  }

  saveOnEnter(event) {
    if (event.keyCode === 13) {
      this.saveValue();
    }
  }

  webWhatsapp(telephoneNumber, user) {
    window.open(URL + telephoneNumber)
  }

  handlerDialTelephone(telephoneNumber) {
    dialTelephone(telephoneNumber)
  }

  render() {
    const value = this.state.value !== null ? this.state.value : this.props.value;
    const user = api.getCurrentUser();
    const isAltLayout = this.props.altInputLayout
    return (
      !isAltLayout ?
        <div style={{ display: 'flex' }}>
          <MaskedInput
            disabled={this.props.readOnly}
            name={this.props.name}
            value={value}
            mask={this.props.mask}
            readOnly={this.props.readOnly}
            onChange={(e) => this.onChange(e.target.value)}
            onBlur={this.saveValue}
            onKeyDown={this.saveOnEnter}
            onFocus={this.loadValue}
            style={{ width: "100%" }}
            className={this.props.className + (this.props.invalid ? ' is-invalid' : '')}
          />
          {this.props.inputType === "maskedPhone" ?
            <button
              id="close"
              style={{ paddingRight: "4px", paddingLeft: "4px", borderRadius: "4px" }}
              className="btn btn-success closing"
              disabled={this.props.whatsappOptin}
              onClick={() => this.webWhatsapp(value, user)}
            >
              <FzIcons icon={"whatsApp"} />
            </button> : null}
          {this.props.inputType === "maskedPhone" ?
            <button
              style={{ paddingRight: "4px", paddingLeft: "4px", borderRadius: "4px" }}
              id="close"
              className="btn btn-primary closing"
              disabled={value.length < 8}
              onClick={() => this.handlerDialTelephone(value)}
            >
              <FzIcons icon={"phone"} />
            </button> : null}
        </div> :
        <MaskedInput
          disabled={this.props.readOnly}
          className={'form-control'}
          name={this.props.name}
          value={value}
          mask={this.props.mask}
          readOnly={this.props.readOnly}
          onChange={(e) => this.onChange(e.target.value)}
          onBlur={this.saveValue}
          onKeyDown={this.saveOnEnter}
          onFocus={this.loadValue}
        />
    );
  }
}

class fzNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: null };
    this.loadCache = this.loadCache.bind(this);
    this.saveCache = this.saveCache.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  formatNumber(value) {
    if (value === undefined || value === null) {
      return '';
    }
    const decimals = this.props.decimalDigits || 0;
    let opts = { maximumFractionDigits: decimals, minimumFractionDigits: decimals };
    if (this.props.currency === true) {
      opts.style = 'currency';
      opts.currency = 'BRL';
    }
    return this.props.intl.formatNumber(value, opts);
  }

  loadCache() {
    this.setState({ value: this.props.value ? this.props.value.toString() : '' });
  }

  saveCache() {
    if (this.state.value === null) {
      return;
    }
    let currentValue = this.state.value.replace('.', '');
    currentValue = Number(this.state.value.replace(',', '.'));
    if (isNaN(currentValue)) {
      currentValue = 0;
    }
    const m = this.props.decimalDigits ? Math.pow(10, this.props.decimalDigits) : 1;
    currentValue = Math.trunc(currentValue * m) / m;
    if (this.props.value !== currentValue) {
      this.props.onChange(currentValue, this.props.dataType);
    }
    this.setState({ value: null });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    let { name, value, readOnly } = this.props;

    value = this.state.value === null ? this.formatNumber(value) : (this.state.value || '');

    return (
      <FzForm.Control
        name={name}
        disabled={readOnly}
        value={value}
        type="telephone"
        onBlur={this.saveCache}
        onFocus={this.loadCache}
        onChange={this.handleChange}
        isInvalid={this.props.invalid}
      />
    );
  }
}

fzNumber.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  decimalDigits: PropTypes.number,
  currency: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const FzNumber = injectIntl(fzNumber);

export {
  FzCheckbox,
  FzText,
  FzTextarea,
  FzNumber,
  FzRadio,
  FzRadioGroup,
  FzSelect,
  FzMasked,
  FzAutoComplete,
  FzDateTime,
  FzCodeEditor,
  FzDate,
  FzDateV2,
  FzTextAreaOptionsEmojicon,
  FzMultiSelect,
  FzMultiSelectAutoComplete,
  FzMultiSelectAutoCompleteSortable,
  FzMapStringString,
};