import moment from 'moment';
import { Meta } from '../../types/metas';

export const QueueAggregation: Meta = {
  supplierInternalName: {
    path: 'supplierInternalName',
    label: 'Parceiro',
    dataType: 'string',
    inputType: 'nav-dropdown',
    inputProps: {
      options: undefined,
    },
    isMandatory: () => true,
  },

  queueInternalName: {
    path: 'queueInternalName',
    label: 'Fila',
    dataType: 'string',
    inputType: 'nav-dropdown',
    inputProps: {
      options: undefined,
    },
    isMandatory: () => true,
    isReadOnly: undefined,
  },

  includeQueuesInternalName: {
    path: 'includeQueuesInternalName',
    label: 'Incluir Filas',
    dataType: 'string',
    inputType: 'multiselect',
    inputProps: {
      options: undefined,
    },
    isMandatory: () => true,
    isReadOnly: undefined,
  },

  excludeQueuesInternalName: {
    path: 'excludeQueuesInternalName',
    label: 'Excluir Filas',
    dataType: 'string',
    inputType: 'multiselect',
    inputProps: {
      options: undefined,
    },
    isMandatory: () => false,
    isReadOnly: undefined,
  },

  createdAfter: {
    path: 'createdAfter',
    label: 'De:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: moment()
        .add('year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      minValue: moment()
        .add(-70, 'year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    isMandatory: () => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },

  createdBefore: {
    path: 'createdBefore',
    label: 'Até:',
    dataType: 'dateTime',
    inputType: 'dateTime',
    inputProps: {
      domainName: undefined,
      filteredFrom: undefined,
      options: undefined,
      maxValue: moment()
        .add('year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      minValue: moment()
        .add(-70, 'year')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      maxSize: undefined,
      minSize: undefined,
      autoComplete: undefined,
      mask: undefined,
      removeMask: undefined,
    },
    isMandatory: () => true,
    isReadOnly: undefined,
    isVisible: undefined,
    normalize: undefined,
    extraValidation: undefined,
    hookOnSave: undefined,
  },

  active: {
    path: 'active',
    label: 'Active',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'true', description: 'True' },
        { code: 'false', description: 'False' },
      ],
    },
    normalize: (value: any) => {
      return value === 'undefined' ? '' : value;
    },
    isMandatory: () => false,
  },

  canceled: {
    path: 'canceled',
    label: 'Canceled',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'true', description: 'True' },
        { code: 'false', description: 'False' },
      ],
    },
    normalize: (value) => {
      return value === 'undefined' ? '' : value;
    },
    isMandatory: () => false,
  },

  timeout: {
    path: 'timeout',
    label: 'Timeout',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 'true', description: 'True' },
        { code: 'false', description: 'False' },
      ],
    },
    normalize: (value) => {
      return value === 'undefined' ? '' : value;
    },
    isMandatory: () => false,
  },

  limit: {
    path: 'limit',
    label: 'Limit',
    dataType: 'string',
    inputType: 'select',
    inputProps: {
      options: [
        { code: 1000, description: "1000" },
        { code: 500, description: "500" },
        { code: 250, description: "250" },
        { code: 100, description: "100" },
        { code: 50, description: "50" },
        { code: 20, description: "20" },
        { code: 10, description: "10" },
      ],
    },
    normalize: (value) => {
      return value === 'undefined' ? '' : value;
    },
    isMandatory: () => false,
  },
};

export type FiltersType = {
  createdAfter: Date | string | null;
  createdBefore: Date | string | null;
  supplierInternalName: string | null;
  active: string | null;
  canceled: string | null;
  timeout: string | null;
  limit: string | null;
  queueInternalName?: string[] | string | null;
  includeQueuesInternalName?: string[] | string | null;
  excludeQueuesInternalName?: string[] | string | null;
};

export type QueueTraceType = {
  _id: string;
  applicationId: string;
  hasErrors: boolean;
  methods: string;
  queueId: string;
  step: string;
  supplierInternalName: string;
  created: string;
  data_received: any;
  data_sent: any;
};

export type QueueEntryType = {
  /** stringified Object ID */
  _id: string;
  queue: string;
  /** stringified Object ID */
  applicationId: string;
  active: false;
  /** stringified date */
  created: string;
  extraData: any;
  locked: boolean;
  maxAttempts: number;
  attempts: number;
  /** stringified date */
  readyTime: string;
  sqsMessage: {
    /** stringified Object ID */
    ApplicationID?: string;
    ApplicationState?: number;
    /** stringified Object ID */
    SupplierID?: string;
    SupplierName?: string;
    PersonalNumber?: string;
    BranchCode?: string;
    BranchAPICode?: string;
    MessageTrigger?: string;
    /** stringified date */
    SentDate?: string;
    CustomerDataUpdated?: boolean;
    ReceivedData?: any | null;
  };
  /** stringified date */
  updated: string;
  timedOut: boolean;
  subQueue: string;
};