import React from 'react'
import EntityPack from '../../components/lazyWay';
import { OCRModelColumns, OCRModelForm } from '../../../biz/metadatas/ocrModels/ocr-models';
import { GetAllOCRModels, GetOCRModelById, PostOCRModel, PutOCRModel } from '../../../services/api/ocr';

const OCRModelPack = ({ ns }) => {

  const onCreate = () => {
    // console.log("onCreate")
  }
  return EntityPack({
    ns,
    metaList: OCRModelColumns,
    meta: OCRModelForm,
    title:"ocr-models",
    entity:"ocr/models",
    linkPath: "id",
    onCreate: onCreate,
    handleUpdate: PutOCRModel,
    actionCreate: PostOCRModel,
    actionGet: GetOCRModelById,
    actionGetList: GetAllOCRModels,
    actionUpdate: PutOCRModel
  })
}

export default OCRModelPack;
