import get from 'lodash/get';

const metaData = {
  productMeta: {
    getField: (path) => {
      return get(metaData.productMeta.fields, path)
    },
    getFieldBySection: (sectionName) => {
      return Object.keys(fields).map((pathName) => {
        if (fields[pathName] && fields[pathName].section && fields[pathName].section.includes(sectionName)) {
          return fields[pathName]
        }
      }).filter(Boolean)
    },
    getFieldByPath: (applicationPath) => {
      return get(metaData.productMeta.fields, Object.keys(metaData.productMeta.fields).find(fieldPath => metaData.productMeta.fields[fieldPath].path === applicationPath.toLowerCase()))
    },
    getFieldInsensitive: (path) => {
      return get(metaData.productMeta.fields, Object.keys(metaData.productMeta.fields).find(fieldPath => fieldPath.toLowerCase() === path.toLowerCase()))
    },
    getFieldPaths: (application) => {
      return colectFields(metaData.productMeta.fields, "", application.product)
    },

    fields: {
      "supplierInternalName": {
        "path": "supplierInternalName",
        "dataType": "",
        "inputType": "select",
        "readOnly": false,
        "customClass": "col-md-4",
        "label": "Parceiro",
        "placeholder": "",
        "isMandatory": true,
        "validationMessage": "",
        "section": "partner-priority"
      },
      "branchCode": {
        "path": "branchCode",
        "dataType": "",
        "inputType": "select",
        "readOnly": false,
        "customClass": "col-md-3",
        "label": "Branch",
        "placeholder": "",
        "isMandatory": "",
        "validationMessage": "",
        "section": "partner-priority"
      },
      "priority": {
        "path": "priority",
        "dataType": "",
        "inputType": "number",
        "readOnly": false,
        "customClass": "col-md-2",
        "label": "Grau de relevância",
        "placeholder": "De 1 até 99",
        "isMandatory": true,
        "validationMessage": "",
        "section": "partner-priority"
      },
      "status": {
        "path": "status",
        "dataType": "",
        "inputType": "select",
        "inputProps": {
          "options": [
            { "code": 'high', "description": 'Alto' },
            { "code": 'medium', "description": 'Médio' },
            { "code": 'low', "description": 'Baixo' },
          ],
        },
        "readOnly": false,
        "customClass": "col-md-2",
        "label": "Status de aprovação",
        "placeholder": "",
        "isMandatory": "",
        "validationMessage": "",
        "section": "partner-priority"
      },
      "productExternalPartnerUrl": {
        "path": "productExternalPartnerUrl",
        "dataType": "",
        "inputType": "text",
        "readOnly": false,
        "customClass": "col-md-4",
        "label": "URL de parceiro externo",
        "placeholder": "URL de parceiro externo",
        "isMandatory": true,
        "regexValidation": /^(https?:\/\/)(?:www.|(?!www.))(([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]|[a-zA-Z0-9]+).)+(([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]|[a-zA-Z0-9]){2,})\/?((\?|\&)([^=]+)\=([^&]+))*/,
        "validationMessage": "Digite uma URL válida",
        "section": "external"
      },
      "icon": {
        "path": "icon",
        "dataType": "",
        "inputType": "text",
        "customClass": "col-md-6",
        "label": "Ícone do produto",
        "placeholder": "Ícone do produto",
        "isMandatory": true,
        "section": "parameters-product"
      },
      "description": {
        "path": "description",
        "dataType": "",
        "inputType": "text",
        "customClass": "col-md-6",
        "label": "Descrição do produto",
        "placeholder": "Descrição do produto",
        "isMandatory": true,
        "section": "parameters-product"
      },
      "position": {
        "path": "position",
        "dataType": "number",
        "inputType": "number",
        "customClass": "col-md-6",
        "label": "Ordem do produto",
        "placeholder": "",
        "isMandatory": true,
        "section": "parameters-product"
      },
      "loanPeriodsInMonths": {
        "path": "loanPeriodsInMonths",
        "inputType": "text",
        "customClass": "col-md-6",
        "label": "Opções de parcelamento em meses",
        "placeholder": "Ex: 2, 6, 8, 12...",
        "isMandatory": true,
        normalize: (value) => {
          const values = value.split(/, ?/);
          const intValues = values.map((period) => Number(period));
          return intValues
        },
        "section": "parameters-product"
      }
    },
  }
}

const fields = metaData.productMeta.fields;

export const Product = metaData.productMeta