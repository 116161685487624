import React, { Component } from 'react';
import SplitLine from "../../../components/split-line";
import { FzCard, FzJumbotron } from '../../fz/layout/index';
import { getGoServersMemory } from '../../../api/integrations';
import { FzTable } from "../../fz/grid/table";
import { apiMemory } from "../../../biz/metadatas/go-servers"
import { FzIcons } from '../../fz/form/icon';
import { FzButton } from '../../fz/form';


export class ServersConsole extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.gerServersData = this.gerServersData.bind(this);
  }
  gerServersData() {
    const { ns } = this.props;
    this.setState({ loading: true });

    return getGoServersMemory().then((memData) => {
      ns.set("memData", memData, -1);
      this.setState({ loading: false });
    })
  }

  componentDidMount() {
    const { ns } = this.props;
    if (!ns.get("memData")) {
      this.gerServersData()
    }
  }

  render() {
    let { ns } = this.props;
    let memData = ns.get("memData");

    return (<FzJumbotron>
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title componentClass="h3">
            <SplitLine>
              <SplitLine.Left>Servidores Go</SplitLine.Left>
              <SplitLine.Right>
                <span hidden={!this.state.loading}>Atualizando</span>
                <FzButton onClick={() => this.gerServersData()}><FzIcons icon="repeat" fzStyle={{ width: "15px", height: "15px" }}  /></FzButton>
              </SplitLine.Right>
            </SplitLine>
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzTable
            ns={ns}
            data={memData}
            visibleColumns={["hostName", "goRoutines", "authCacheObjects", "heapObjects", "heapAlloc", "heapInuse", "heapIdle", "heapReleased", "serverVersion", "numCPU"]}
            pagination
            metas={apiMemory}
            clipboard={true}
            exportDownload={false}
          />
        </FzCard.Body>
      </FzCard>
    </FzJumbotron>
    )
  }
}
