import { Meta } from '../../types/metas';

export const VALIDATE_URL_PATTERN =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,20}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const VALIDATE_EVENT_PATTERN = /^[a-z0-9_\+\-#]{5,128}/;

export const ShortenerContainer: Meta = {
  TargetUrl: {
    path: 'targetUrl',
    label: 'URL Alvo',
    dataType: 'string',
    inputType: 'text',
    validationMessage: 'URL Inválida',
    pattern: VALIDATE_URL_PATTERN,
    isMandatory: () => true,
    isReadOnly: () => false,
  },
  Event: {
    path: 'event',
    label: 'Identificador do Evento',
    dataType: 'string',
    inputType: 'text',
    validationMessage: 'Use letras minúsculas, numeros, ou os símbolos  _ - #',
    pattern: VALIDATE_EVENT_PATTERN,
    isMandatory: () => true,
    isReadOnly: () => false,
  },
  ExpireAt: {
    path: 'expireAt',
    label: 'Validade',
    dataType: 'dateTime',
    inputType: 'dateTime',
    isVisible: () => true,
    cleanIfHidden: true,
    isMandatory: () => true,
    isReadOnly: () => false,
  },
  ExpiredUrl: {
    path: 'expiredUrl',
    label: 'URL de expiração',
    dataType: 'string',
    inputType: 'text',
    
    tip: 'Caso informada, esta URL será utilizada após a expiração da original.',
    validationMessage: 'URL Inválida',
    isReadOnly: () => false,
    // pattern: VALIDATE_URL_PATTERN,
    isMandatory: () => false,
  },
  ResultShortUrl: {
    path: 'shortUrl',
    label: 'URL encurtada',
    dataType: 'string',
    isMandatory: () => false,
  },
  ResultHash: {
    path: 'hash',
    label: 'Hash gerado',
    dataType: 'string',
    isMandatory: () => false,
  },
  ResultExpireAt: {
    path: 'expireAt',
    label: 'Data de validade registrada',
    dataType: 'string',
    normalize: (value) => new Date(value).toLocaleDateString(),
    isMandatory: () => false,
  },
};

export interface FinanvcRequestInterface {
  targetUrl: string;
  event: string;
  expireAt?: string;
  expiredUrl?: string;
}

export interface FinanvcResponseInterface {
  hash: string;
  shortUrl: string;
  expireAt: string;
}