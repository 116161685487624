import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Namespace } from '../../../scripts/state/redux_ns';
import { ButtonGroup, Button } from "react-bootstrap";
import copy from 'copy-to-clipboard';
import { getAllColumns } from "./helpers-table"
import { doExport } from '../formatter/export-data'
import { formatPeriod } from "../formatter/data-formatter"
import clone from "lodash/cloneDeep"
import "../style/grid.css"
import { FzTable } from './table';
import { FzIcons } from '../form/icon';

class FzPivotTable extends Component {
  constructor(props) {
    super(props)
    this.pivotData = this.pivotData.bind(this)
  }

  pivotData() {
    let { data, metas, pivotMetas, filterColumnName, filterValue, pivotColumnName } = this.props


    let rows = getAllColumns(metas).filter(it => it !== filterColumnName && it !== pivotColumnName)

    let pivotColumns = {}

    let filteredData = data.filter((it) => {

      it[pivotColumnName] ? pivotColumns[it[pivotColumnName]] = true : pivotColumns[it[pivotColumnName]] = true
      return it[filterColumnName] === filterValue
    });
    let pivotMeta = metas[pivotColumnName]
    if (pivotMeta.defaultSort < 0) {
      pivotColumns = Object.keys(pivotColumns).sort((a, b) => b > a)
    } else {
      pivotColumns = Object.keys(pivotColumns).sort()
    }
    this.pivotValues = []
    if (filteredData.length) {
      rows.forEach((col, k) => {
        let v = { pivot: metas[col].label, key: k }
        pivotColumns.forEach(pivot => {
          v[pivot] = filteredData.filter(it => it[pivotColumnName] === pivot).reduce((previous, it) => {
            return it[col] + previous
          }, 0)
        })
        this.pivotValues.push(v)
      })
    }
    this.resultMetas = clone(pivotMetas)
    pivotColumns.forEach(col => {
      let newCol = clone(pivotMetas["template"])
      newCol.path = col
      newCol.label = formatPeriod(false, col)
      newCol.tableProps.hidden = false
      this.resultMetas[col] = newCol
    })
  }

  render() {
    const { exportDownload, clipboard, ns } = this.props
    this.pivotData()

    if (!this.resultMetas)
      return null

    return <FzTable
      ns={ns}
      context={undefined}
      data={this.pivotValues}
      metas={this.resultMetas}
      exportDownload={exportDownload}
      clipboard={clipboard}

    />
  }
}

FzPivotTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  metas: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  ns: PropTypes.shape({ type: PropTypes.oneOf([Namespace]) }),
  exportDownload: PropTypes.bool,
  clipboard: PropTypes.bool,
  filterValue: PropTypes.string,
  filterColumnName: PropTypes.string.isRequired,
  pivotColumnName: PropTypes.string.isRequired
};


class FzTableCustomToolBar extends Component {
  constructor(props) {
    super(props)
    this.state = { valueClipboard: "" }
    this.exportCSV = this.exportCSV.bind(this)
    this.clipboardCSV = this.clipboardCSV.bind(this)
  }


  clipboardCSV() {
    const { data, metas, columnsPaths, dateFilter, context } = this.props
    return doExport(data, metas, columnsPaths, dateFilter, false, context)
  }

  exportCSV() {

    const { data, metas, columnsPaths, dateFilter, context } = this.props
    doExport(data, metas, columnsPaths, dateFilter, true, context)
  }


  render() {
    const { exportDownload } = this.props

    return (
      <div>
        <ButtonGroup className="btn-group-xs">

          {this.props.clipboard ?

            (<Button bsSize="xsmall" onClick={() => copy(this.clipboardCSV(), { format: "text/plain" })}>
              < FzIcons icon={"copy"} fzStyle={{ width: "15px", height: "15px" }} /> Copiar
            </Button>
            )
            : (null)}

          {exportDownload ? (
            <Button bsStyle="info" onClick={this.exportCSV}>
              < FzIcons icon={"file-export"} fzStyle={{ width: "15px", height: "15px" }} /> Export data as CSV
            </Button>) : (null)}

        </ButtonGroup>
      </div>
    )

  }
}

FzTableCustomToolBar.propTypes = {
  metas: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  columnsPaths: PropTypes.arrayOf(PropTypes.string),
  exportDownload: PropTypes.bool,
  clipboard: PropTypes.bool,
  dateFilter: PropTypes.string
}

export { FzPivotTable }
