import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { FzCard } from '../../fz/layout';
import { FzAccordion } from '../../fz/layout/FzAccordion';
import { FzButton } from '../../fz/form';
import { FzSelect } from '../../fz/form/old-simple-input';
import { FzToast } from '../../fz/form/notification/toast'
import { ScreeningDetails, ScreeningDocumentStatesMeta } from './screening-index'
import api from '../../../api/client-api'
import { FullScreenView } from '../../blocks/screening/images/fullscreen-view';
import PdfViewComponent from '../../../components/PdfView';
import { FzModal } from '../../fz/fz-modal';
import { 
  blankDocument,
  cloneDocument,
  postponeAnalisys,
  releaseScreening,
  swapDocument,
  getFormalizationDocumentUrl } from '../../../services/api/screening';
import { CustomerType, DocumentsType, ScreeningResponseData } from '../../../services/api/screening/types';
import { DocumentText } from '../../blocks/screening/document-text';

type Documento = DocumentsType & {
  description: string;
  urlImg: string;
}
interface InitialAnalysisProps {
  ns: any
}

interface DomainType {
  code: string,
  description: string
}

interface FormalizationDocumentsType extends Array<DomainType> { }

const hook = {
  'clone': (data) => cloneDocument({ "originalDocumentType": data.documentTypeFrom, "documentToCopyType": data.documentTypeTo }),
  'swap': swapDocument,
  'blank_doc': (data) => blankDocument(data.documentType, { 'pendedReasons': ['doc-is-blank'] }),
}

const handleReleaseScreening = async (history, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow) => {
  try {
    await releaseScreening()
    history.push("/screening/queue")
  }catch (err) {
    let error: $TsFixMe = err
    setHeaderMessageTost('Screening error')
    setBodyMessageTost(error.response.data.message)
    seColorTost('danger')
    setShow(true)
  }
}

function showAllAccordionTabs() {
  const getCollapsedAccordionSections = document.getElementsByClassName(
    "collapse"
  )
  for (var i = 0; i < getCollapsedAccordionSections.length; i++) {
    getCollapsedAccordionSections[i].classList.add("show")
  }
}

function hideAllAccordionTabs() {
  const getCollapsedAccordionSections = document.getElementsByClassName(
    "collapse"
  )
  for (var i = 0; i < getCollapsedAccordionSections.length; i++) {
    getCollapsedAccordionSections[i].classList.remove("show")
  }
}

export function InitialAnalysis({ ns }: InitialAnalysisProps) {
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, seColorTost] = useState('')

  const history = useHistory();
  const [openModal, setOpenModal] = useState(false)

  const [titleModal, setTitleModal] = useState('')
  const [action, setAction] = useState('')

  const [documentTypeFrom, setDocumentTypeFrom] = useState('')
  const [documentTypeTo, setDocumentTypeTo] = useState('')
  const [viewImage, setViewImage] = useState(false);
  const [postponeModalOpen, setPostponeModalOpen] = useState(false);

  function handleView() {
    setViewImage(!viewImage);
  }

  function handleSetModal() {
    setOpenModal(!openModal)
  }

  function handleCloneDocument(event) {
    setDocumentTypeFrom(event.target.id)
    setTitleModal('Clonar por')
    setAction('clone')
    setOpenModal(true)
  }

  function handleSwapDocument(event) {
    setDocumentTypeFrom(event.target.id)
    setTitleModal('Inverter documento com')
    setAction('swap')
    setOpenModal(true)
  }

  function handleBlankDocument(event) {
    setDocumentTypeTo(event.target.id)
    setTitleModal('Documento em Branco')
    setAction('blank_doc')
    setOpenModal(true)
  }

  function handleCancelAction() {
    setAction('');
    setDocumentTypeTo('');
    setDocumentTypeFrom('');
    handleSetModal();
  }

  function handleClose() {
    setShow(false)
  }

  /**
   * Open postpone modal
   */
  function handlePostponeModalOpen() {
    setPostponeModalOpen(true);
  }

  /**
   * Close postpone modal
   */
  function handlePostponeModalClose() {
    setPostponeModalOpen(false);
  }

  /**
   * Postpone screening
   */
  async function handlePostpone() {
    try {
      const response = await postponeAnalisys();
      
      if (response.status === 200) {  
        ns.clear()
        history.push(`/screening/queue`);
        return ;
      }  
    } catch (err) {
      console.error({err});
    }

  }

  function handleRenderDocument(doc: Documento): React.ReactNode {
    if (!doc) {
      return <></>
    }
    if (doc.mimeType && doc.mimeType.includes('application/pdf')) {
      return <PdfViewComponent url={doc && doc.urlImg}
        documentType={doc.documentType}
        optionsProps={{ height: '550px' }} />
    }
    // TODO: change to mimetype later
    else if (doc.documentType === 'address-eletricbill-number') {
      return <DocumentText
        url={String(doc.urlImg)}
        documentType={String(doc.documentType)}
        ns={ns} />
    }
    else {
      return <img
        style={{ height: '500px', width: '100%' }}
        src={doc.urlImg}
        alt={doc.documentType} />;
    }
  }

  const screening: ScreeningResponseData = ns.getShared("SCREENING.screening_response")
  const domains: FormalizationDocumentsType = ns.getShared("SCREENING.domains.formalization_documents", [])
  const customerFields: CustomerType[] = ns.getShared("SCREENING.screening_response.customerFields")
  const applicationId: string = ns.getShared("SCREENING.screening_response.applicationId", null)
  const bidId: string = ns.getShared("SCREENING.screening_response.bidId", null)
  var currentUser = api.getCurrentUser()
  if (!screening || !domains || !customerFields) {
    return <div>
      Carregando...
    </div>
  }

  const fullName: string = customerFields.find(it => it.path === 'fullName')?.value ?? 'Não encontrado'

  let options = screening.documents.reduce((acc: any, current: DocumentsType) => {
    let description = domains.find(doc => doc.code === current.documentType)?.description ?? 'Não encontrado'
    return [...acc, { code: current.documentType, description }]
  }, [])

  let documents: Documento[] = screening.documents.map((doc) => {
    let docDomains: DomainType | any = domains.find((docDomain) => { return docDomain.code === doc.documentType })
    // TODO: change to mimetype later
    const fmt = doc.documentType === 'address-eletricbill-number' ? '' : 'png';
    const url = getFormalizationDocumentUrl(applicationId, bidId, doc.documentType, fmt, doc.userDocumentId);
    return { ...doc, urlImg: url, description: docDomains.description };
  })
  
  let docHasPendedLastState = false
  const pendingDocuments: Documento[] = []
  const images = documents?.map(doc => {
    if(doc.lastState && doc.lastState === 'pending'){
      docHasPendedLastState = true
      pendingDocuments.push(doc)
    }
    
    return {
      url: doc.urlImg,
      title: doc.description,
    }
  })

  return (
    <div>
      <header style={{ display: "flex", justifyContent: "space-between" }} >
        <div>
          <div> Usuário: {fullName} </div>
          <ScreeningDetails className="mb-3" screeningResponse={screening} ns={ns}/>
        </div>
        <div>
          {currentUser.group.indexOf('it') > -1 &&
            <FzButton
              className="mr-3"
              fzStyle='alert'
              onClick={() => {
                handleReleaseScreening(history, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow)
              }}
            >
              Liberar Screening
            </FzButton>
          }
          
          <FzModal 
            showButtonText='Pendência Interna (Refactor)'
            headerTitle='Desviar para pendência interna ?'
            handleSuccessButton={handlePostpone}
            cancelText='Não'
            successText='Sim'            
          >
            <div>
              A proposta ficará na fila interna por até 1 hora. Após isso, voltará para a fila inicial.
              <br/>
              Deseja prosseguir ?
            </div>
          </FzModal>
          
          <FzButton
            fzStyle='regular'
            customStyle={{marginLeft: '15px'}}
            onClick={() => {
              ns.clear()
              history.push(`/screening/individual-analysis/${documents[0].documentType}`)
            }}
          >
            Iniciar análise individual
          </FzButton>

          <FzButton
            fzStyle='outline-success'
            customStyle={{marginLeft: '15px'}}
            disabled={!docHasPendedLastState}
            onClick={() => {
              ns.clear()
              history.push(`/screening/continue-analysis/${pendingDocuments[0].documentType}`)
            }}
          >
            Continuar análise
          </FzButton>
        </div>
      </header>

      <div className='mt-3' style={{ display: 'flex', width: "1000px" }}>
        <FzButton
          className='mt-0 mr-3'
          size='sm'
          fzStyle='outline-secondary'
          block
          onClick={() => setViewImage(true)}
        >
          Visualizar
        </FzButton>
        <FzButton
          className='mt-0 mr-3'
          size='sm'
          fzStyle='outline-secondary'
          block
          onClick={showAllAccordionTabs}
        >
          Expandir todos os documentos
        </FzButton>
        <FzButton
          className='mt-0'
          size='sm'
          fzStyle='outline-secondary'
          block
          onClick={hideAllAccordionTabs}
        >
          Minimizar todos os documentos
        </FzButton>
      </div>
      {documents.map((doc) => (
        <div className="mt-3" style={{ border: "1px solid lightgrey", borderRadius: "5px", width: "1000px" }} key={doc.documentType} >
          <FzAccordion defaultActiveKey={doc.documentType}>
            <FzCard.Heading fzStyle={{ padding: 0 }}>
              <FzAccordion.Toggle FzElementType='h4' fzStyle={{ cursor: 'pointer', padding: '10px' }} eventKey={doc.documentType}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <strong >
                    {
                      (domains.find(it => it.code === doc.documentType)?.description || '')
                    }
                  </strong>
                  <div style={{ textTransform: "capitalize" }} className="text-secondary" >
                    {ScreeningDocumentStatesMeta[doc.state] ? ScreeningDocumentStatesMeta[doc.state].label : "Status não definido"}
                  </div>
                </div>
              </FzAccordion.Toggle>
            </FzCard.Heading>
            <FzAccordion.Collapse eventKey={doc.documentType} >
              <div className="p-3" style={
                {
                  display: "flex",
                  justifyContent: 'space-between',
                  width: "100%",
                  height: '600px'
                }}>
                <div style={{ width: '700px', height: '550px' }}>
                  { handleRenderDocument(doc) }
                </div>
                <div className="ml-3" style={{ minWidth: "150px" }}>
                  <FzButton
                    disabled={doc.pendedRules.indexOf('doc-is-blank') !== -1 || doc.state === 'validated' || doc.finanzeroGenerated}
                    id={`${doc.documentType}`} block
                    onClick={handleCloneDocument}
                  >
                    Clonar
                  </FzButton>
                  <FzButton
                    disabled={doc.pendedRules.indexOf('doc-is-blank') !== -1 || doc.state === 'validated' || doc.finanzeroGenerated}
                    id={`${doc.documentType}`}
                    block
                    className='mt-2'
                    onClick={handleSwapDocument}
                  >
                    Inverter
                  </FzButton>
                  <FzButton
                    disabled={doc.pendedRules.indexOf('doc-is-blank') !== -1 || doc.state === 'validated' || doc.finanzeroGenerated}
                    id={`${doc.documentType}`}
                    block
                    className='mt-2'
                    onClick={handleBlankDocument}
                  >
                    Em branco
                  </FzButton>
                </div>
              </div>
            </FzAccordion.Collapse>
          </FzAccordion>
        </div>
      ))
      }
      {
        !documents.find(doc => doc.documentType == "selfie") ? (<FzButton
          disabled={false}
          id={"selfie"}
          block
          className='mt-2'
          onClick={handleBlankDocument}
        >
          Selfie Em branco
        </FzButton>) : null
      }
      {
        !documents.find(doc => doc.documentType == "address") ? (<FzButton
          disabled={false}
          id={"address"}
          block
          className='mt-2'
          onClick={handleBlankDocument}
        >
          Comprovante de endereço Em branco
        </FzButton>) : null
      }
      {
        !documents.find(doc => doc.documentType == "income") ? (<FzButton
          disabled={false}
          id={"income"}
          block
          className='mt-2'
          onClick={handleBlankDocument}
        >
          Comprovante de Renda Em branco
        </FzButton>) : null
      }
      {
        !documents.find(doc => doc.documentType == "ctps") ? (<FzButton
          disabled={false}
          id={"ctps"}
          block
          className='mt-2'
          onClick={handleBlankDocument}
        >
          Carteira de Trabalho e Previdência Social
        </FzButton>) : null
      }

      <Modal
        size="lg"
        centered
        show={openModal}
        onHide={() => handleSetModal()}
      >
        <Modal.Header>
          <Modal.Title >
            {titleModal}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {action !== 'blank_doc' ? (
            <div>
              <FzSelect
                name='teste'
                readOnly={false}
                onChange={(value) => setDocumentTypeTo(value)}
                options={options.filter(it => it.code !== documentTypeFrom)}
              />

              <div className="pt-3">
                {documentTypeTo && (
                  <div>
                    Você tem certeza que deseja <strong> {action === 'swap' ? 'inverter' : 'clonar'}</strong> o documento
                    <strong> {domains.find(it => it.code === documentTypeTo)?.description ?? ''} </strong>
                    <span> {action === 'swap' ? 'com o' : 'no'}</span> documento
                    <strong> {domains.find(it => it.code === documentTypeFrom)?.description ?? ''} </strong>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              Você tem certeza que o documento&nbsp;
              <strong>
                {domains.find(it => it.code === documentTypeTo)?.description ?? ''}
              </strong> está em branco?<br />
              Essa ação pendenciará este documento!
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <FzButton
            fzStyle='secondary'
            onClick={() => {
              handleCancelAction();
            }}
          >
            Cancelar
          </FzButton>
          <FzButton
            fzStyle={action !== 'blank_doc' ? 'attention' : 'alert'}
            disabled={documentTypeTo === ''}
            onClick={async () => {
              const documentType = action !== 'blank_doc' ? documentTypeFrom : documentTypeTo
              try {
                const screeningResponse = await hook[action]({ documentTypeFrom, documentTypeTo, documentType })
                ns.setShared("SCREENING.screening_response", screeningResponse.data)
                setAction('')
                setDocumentTypeTo('')
                setDocumentTypeFrom('')
                handleSetModal()
              } catch (err) {
                let error: $TsFixMe = err
                setOpenModal(false)
                setHeaderMessageTost('Screening error')
                setBodyMessageTost(error)
                seColorTost('danger')
                setShow(true)
              }
            }}
          >
            Confirmar
          </FzButton>
        </Modal.Footer>
      </Modal>

      <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
      />

      { viewImage && <FullScreenView images={images} handleClose={handleView} /> }

    </div >
  )
}




