import { useState } from "react"
import { FzAlert } from '../../../../fz/form/alert'
import { FzTable } from "../../../../fz/grid/table"
import { getAgentTimedOutStats } from '../../../../../api/integrations'
import { agentQueuesSummary } from './timedOutSummary-meta'
import { Link } from "react-router-dom"

export function TimedOutSummary (props) {

  const [querying, setQuerying] = useState(false)
  const agent = props.agent
  const stats = props.ns.get(props.name)

  if (!stats) {
    if (!querying) {
      setQuerying(true)
      getAgentTimedOutStats(agent)
        .then((s) => {
          props.ns.set(props.name, s)
        })
        .catch(() => {
          props.ns.set(props.name, [])
        })
        .finally(() => setQuerying(false));
    }
    return (<FzAlert fzStyle="attention">Carregando mensagens do servidor...</FzAlert>);
  }

  return (
    <FzTable
      data={stats}
      metas={agentQueuesSummary}
      ns={props.ns}
      visibleColumns={["key", "queue", "qtty", "timedOut"]}
      customColumnFormatters={{ "queue": (col) => <Link to={props.pathname + "/" + col} >{col}</Link> }}
    />
  )
}