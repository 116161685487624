const salesQueueDictionary = {
  "missing-signature": "Aguardando assinatura",
  "docs-refused": "Docs parciamente recusados",
  "docs-incomplete": "Docs incompletos",
  "docs-not-started": "Docs não iniciados",
  "has-bids": "Proposta enviada",
  "reserved":"Reservado",
  "receptive":"Receptivo",
  "continuation":"Continuidade",
  "he-all": "Home Equity",
  "followup": "Continuação - follow-up",
  "followup-new": "Novo follow-up",
  "refin-top": "Refin: prioritários",
  "refin-average": "Refin: não prioritários",
  "cp-ibi": "CBSS P1",
  "docs-refused-low-priority": "Parcialmente recusado não prioritário",
  "has-bids-low-priority": "Proposta enviada não prioritário",
  "docs-incomplete-low-priority": "Docs incompletos não prioritário",
  "docs-not-started-low-priority": "Docs não iniciados não prioritário",
  "manual-ative": "Fila Manual",
  "selfie" : "Erro na selfie",
  "deal": "Demais ofertas",
  "deal-previous-day": "Ofertas de ontem",
  "deal-today": " Ofertas de hoje"
}

const crmChannel = {
	"chat": "Chat",
	"phone": "Telefone",
  "email": "Email",
  "whats": "WhatsApp",
	"app": "Notificação interna COF"
}

export {salesQueueDictionary, crmChannel};
