import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';

class FzButtonGroup extends React.Component {
  static propTypes = {
    handleAction: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
  };

  render() {
    const { buttons, disabled } = this.props;
    let btnList = [];
    for (var i = 0; i < buttons.length; ++i) {
      const b = buttons[i];
      const isDisabled = (b.itemDisabled && typeof b.itemDisabled == 'function' && b.itemDisabled()) || !!disabled;
      btnList.push(
        <FzButton
          block={b.block}
          id={buttons[i].id}
          key={i}
          disabled={isDisabled}
          onClick={b.onClick}
          fzStyle={b.fzStyle}
          tip={b.tip}
          className={b.className}
        >
          <React.Fragment>
            {b.description}
          </React.Fragment>
        </FzButton>
      );
    }
    return <ButtonGroup> {btnList} </ButtonGroup>;
  }
}

const buttonStyles = {
  'disable': '',
  'discrete': 'default',
  'regular': 'success',
  'attention': 'warning',
  'alert': 'danger',
  'info': 'info',
  'secondary': 'secondary',
  'outline-secondary': 'outline-secondary',
  'outline-success': 'outline-success',
  'outline-primary': 'outline-primary',
  'primary': 'primary'
};

class FzButton extends React.Component {

  static propTypes = {
    onClick: PropTypes.func,
    tip: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    fzStyle: PropTypes.string,
    className: PropTypes.string,
    block: PropTypes.bool,
    customStyle: PropTypes.any
  };

  render() {
    const {
      fzStyle,
      onClick,
      children,
      tip,
      active,
      disabled,
      className,
      block,
      size,
      customStyle,
      value
    } = this.props;
    const variant = buttonStyles[fzStyle];

    return (
      <Button
        value={value}
        block={block}
        id={this.props.id}
        className={className}
        variant={variant}
        title={tip}
        size={size}
        active={active}
        disabled={disabled}
        onClick={onClick}
        style={customStyle}
      >
        {children}
      </Button>
    );
  }
}

export { FzButtonGroup, FzButton };