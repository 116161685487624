import { getCookie, setCookie, removeCookie, getGAuthToken, clearAuth, getLogoutURL } from './helpers';
import { APPLICATION_STATE } from '../business/application';
import ClientDomains from './client-domains'
import { revokeTokens } from './auth';
import moment from 'moment';


export class FzClientApi {

  constructor(baseTarget = "api/v2/", devPort = 4000, ignoreBaseTarget) {
    // Could use webpack proxy but this need our local go server to understand /api/* urls
    window.finanzero = window.finanzero ? window.finanzero : {};
    var isBeta = /((beta)|(^home\.)|(finan0\.))/i.test(window.location.hostname)
    var isDev = /(local)|(192.168.0)/i.test(window.location.hostname)
    var isHomol = window?.finanzero?.isHomol || getCookie("isHomol") == "true"
    if (isDev) {
      this.baseUrl = "http://localhost:" + devPort + "/" + (ignoreBaseTarget ? "" : baseTarget)
    } else {
      this.baseUrl = isBeta ? "https://hub-beta.finanzero.com.br/" + baseTarget : "https://hub.finanzero.com.br/" + baseTarget;
      if ((isHomol || window?.finanzero?.isHomol) && /^api.+/.exec(baseTarget)) {
        this.baseUrl = "https://api-homol.finanzero.com.br/" + baseTarget;
      }
    }

    window.finanzero.isTest = isDev || isBeta
    window.finanzero.isBeta = isBeta
    window.finanzero.isProd = !isDev && !isBeta
    this.baseStaticFilesUrl = isDev || isBeta ? "https://beta.finanzero.com.br" : "https://finanzero.com.br";
    this.DefaultPage = 20;
    this.DefaultAppStack = 200;

    if (baseTarget.startsWith("api/v2")) {
      this.domains = new ClientDomains(this);
    }
  }


  _buildQueryString(filter) {
    var queryFilter = (query, value) => {
      return ((value !== undefined && value !== null && value.length > 0) ? ('&' + query + '=' + encodeURIComponent(value)) : '')
    }

    var isObject = (object) => {
      return Object.keys(object).reduce((prev, curr) => {
        let value = object[curr];
        return value && value.length > 0 ? prev + queryFilter(curr, value) : prev
      }, "")
    }

    var queryString = ""
    for (var f in filter) {
      if (filter.hasOwnProperty(f)) {

        var value = filter[f] instanceof moment ? filter[f].toISOString() : filter[f]

        if (value instanceof Object && !Array.isArray(value)) {
          queryString += isObject(value)
        } else {
          queryString += queryFilter(f, value)

        }
      }
    }
    return (queryString);
  }

  requestNewCode(email, callback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');

    var json = JSON.stringify({ Email: email })
    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    };
    var serviceUrl = '/auth/request-code';
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (json) {
          callback(json.key);
        });
      } else {
        response.json().then(function (json) {
          callback(null, json)
        });

        console.warn("requestNewCode(): error", response.status);
      }

    }).catch(function (error) {
      callback(null, error)
      console.warn("requestNewCode(): error");
    });
  }

  loginWithPassword(email, password, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');

    var json = JSON.stringify({ Email: email, Password: password })
    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit",
    };
    var serviceUrl = '/auth/login/password';
    var req = new Request(this._apiUrl(serviceUrl));

    var thisAPI = this;

    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (json) {
          thisAPI.setAuth(json.token);
          thisAPI.setCurrentUser(json.user);
          callback();
        });
      } else {
        console.warn("loginWithPassword(): error", response.status);
        if (errorCallback !== undefined) {
          errorCallback(response.status)
        }
      }
    }).catch(function (error) {
      console.warn("loginWithPassword(): error");
      if (errorCallback !== undefined) {
        errorCallback(null)
      }
    });
  }

  loginWithCode(key, code, callback, errorCallback) {
    var header = new Headers();
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())
    header.append('Content-Type', 'application/json; charset=UTF-8');
    
    var json = JSON.stringify({ Key: key, Code: code })
    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    };
    var serviceUrl = '/auth/login/code';
    var req = new Request(this._apiUrl(serviceUrl));

    var thisAPI = this;
    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (json) {
          thisAPI.setAuth(json.token);
          localStorage.setItem("fz-cof-token", json.token)
          thisAPI.setCurrentUser(json.user);
          callback();
        });
      } else {
        console.warn("loginWithCode(): error", response.status);
        if (errorCallback !== undefined) {
          errorCallback(response.status)
        }
      }
    }).catch(function (error) {
      console.warn("loginWithCode(): error");
      if (errorCallback !== undefined) {
        errorCallback(null)
      }
    });
  }

  setAuth(token, user, gtoken) {
    setCookie("fz-cof-token", token);
    localStorage.setItem("fz-cof-token", token, 10)
    if (user) {
      this.setCurrentUser(user)
    }
    if (gtoken) {
      setCookie("fz-ga-token", gtoken, 10);
      localStorage.setItem("fz-ga-token", gtoken)
    }
  }

  checkGAuthSession(auth2) {
    if (!auth2.isSignedIn.get() && localStorage.getItem("fz-ga-token")) {
      revokeTokens().then(this.clearAuth)
      return false
    } else {
      return auth2.currentUser.get().getBasicProfile()
    }
  }

  getAuth(auth2) {
    var token = localStorage.getItem("fz-cof-token");
    if (token && token.length > 0) {
      if (auth2 !== undefined) {
        this.checkGAuthSession(auth2)
      }
      this.setAuth(token);
    }

    return token;
  }

  signinChanged(val, that) {
    if (!val) {
      that.clearAuth()
    }
  }

  signinListen(auth2) {
    const that = this;
    auth2.isSignedIn.listen((val) => this.signinChanged(val, that))
  }


  clearAuth() {
    localStorage.clear();
    let cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; ++i) {
      let cookie = cookies[i]
      removeCookie(cookie.split('=')[0]);
    }
    window.location.href = getLogoutURL(window.location)
    return Promise.resolve(true)
  };

  setCurrentUser(user) {
    localStorage.setItem("currentUser", JSON.stringify(user));

    if (user.role === "supplier") {
      this._getCurrentSupplier((supplier) => {
        localStorage.setItem("currentSupplier", JSON.stringify(supplier));
      })
    } else {
      localStorage.removeItem("currentSupplier");
    }

  }

  getCurrentUser() {
    var userAsString = localStorage.getItem("currentUser");
    if (userAsString === null) {
      return null;
    }

    try {
      return JSON.parse(userAsString);
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  getCurrentSupplier() {
    var supplierAsString = localStorage.getItem("currentSupplier");
    if (supplierAsString === null) {
      return null;
    }

    try {
      return JSON.parse(supplierAsString);
    } catch (ex) {
      return null;
    }
  }

  isUserSales() {
    return this.isUserInRole("sales") || this.isUserInRole("advisor")
  }

  isUserSupplier() {
    return this.isUserInRole("supplier")
  }

  isSuperUser() {
    var user = this.getCurrentUser();
    if (user === null) {
      return false;
    }
    return user.role === "admin" && /\b(su)\b/.test(user.group)
  }

  isITUser() {
    var user = this.getCurrentUser();
    if (user === null) {
      return false;
    }
    return user.role === "admin" && /\b(su|it)\b/.test(user.group)
  }

  isAsgardianUser() {
    var user = this.getCurrentUser();
    if (user === null) {
      return false;
    }
    return user.role === "admin" && /\b(su|asgd)\b/.test(user.group)
  }

  isUserInRole(role) {
    if (this.getAuth() === null) {
      return false;
    }
    var user = this.getCurrentUser();
    if (user === null) {
      return false;
    }
    if (role === null || user.role === role) {
      return true;
    }
    if (role === "mis" && (user.role === "admin" || user.role === "mis")) {
      return true;
    }
    return false;
  }

  fetchApplicationsStats(type, period, dateRef, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var init = {
      method: 'GET',
      headers: header,
      cache: 'default',
      credentials: "omit"
    };
    var formattedDate = dateRef.toISOString().slice(0, 10);
    var req = new Request(this._apiUrl('/statistics/' + type + '/' + period + '?createdAfter=' + formattedDate));

    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      console.warn("fetchApplicationsStats", contentType)

      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then(function (json) {
          callback(json);
        });
      } else {
        errorCallback(response.status);
      }
    }).catch(function (error) {
      console.warn("fetchApplicationsStats(): error");
      //app.ApiError();
    });

  }

  fetchCreditScoreStats(dateRef, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var init = {
      method: 'GET',
      headers: header,
      cache: 'default',
      credentials: "omit"
    };
    var formattedDate = dateRef.toISOString().slice(0, 10);
    var req = new Request(this._apiUrl('/statistics/creditscore?createdAfter=' + formattedDate));

    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then(function (json) {
          callback(json);
        });
      } else {
        errorCallback(response.status);
      }
    }).catch(function (error) {
      console.warn("fetchCreditScoreStats(): error");
      //app.ApiError();
    });

  }

  getApplications(filter, callback, errorCallback) {
    var serviceUrl = '/applications/all?page=1&pageSize=' + this.DefaultAppStack + '&orderBy=-created';
    serviceUrl += this._buildQueryString(filter)
    this._get(serviceUrl, callback, errorCallback);
  }

  getApplicationsByAuthId(userId, callback, errorCallback) {
    //var serviceUrl = '/applications/user?page=1&pageSize='+this.DefaultPage+'&orderBy=-created&uid='+userId;
    var serviceUrl = '/applications/user?page=1&pageSize=' + this.DefaultPage + '&orderBy=-created';
    let filter = { 'uid': userId };
    serviceUrl += this._buildQueryString(filter)
    this._get(serviceUrl, callback, errorCallback);
  }

  getApplicationsFollowUp(callback, errorCallback) {
    this._get("/applications/follow-up?page=1&pageSize=" + this.DefaultAppStack + '&orderBy=-created', callback, errorCallback);
  }

  getApplicationsFollowUpCount(callback, errorCallback) {
    this._get("/applications/follow-up/count", callback, errorCallback);
  }

  getApplicationByID(appID, callback, errorCallback) {
    this._get('/applications/' + appID, callback, errorCallback);
  }

  getApplicationsAuctionBreakdown(filter, limit, callback, errorCallback) {
    var serviceUrl = '/applications/export/auction-breakdown?page=1&pageSize=' + limit;
    serviceUrl += this._buildQueryString(filter)
    this._get(serviceUrl, callback, errorCallback);
  }

  getApplicationsAuctionBreakdownPage(filter, page, pageSize, callback, errorCallback) {
    var serviceUrl = '/applications/export/auction-breakdown?page=' + page + '&pageSize=' + (pageSize || this.DefaultAppStack);
    serviceUrl += this._buildQueryString(filter)
    this._get(serviceUrl, callback, errorCallback);
  }

  startAuction(appId, revision, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify({ revision })

    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    }

    var req = new Request(this._apiUrl('/applications/' + appId + '/start-auction'))

    var err = null

    fetch(req, init).then(function (response) {
      if (response.status >= 400) {
        console.warn("startAuction(): error ", response.status, response.text())
        err = response.status;
      }
      callback(err);
    }).catch(function (error) {
      err = error
      callback(err)
    })
  }

  appPostpone(appId, revision, callbackTime, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify({ revision, callbackTime })

    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    }

    var req = new Request(this._apiUrl('/applications/' + appId + '/postpone'))

    var err = null

    fetch(req, init).then(function (response) {
      if (response.status >= 400) {
        console.warn("appPostPone(): error ", response.status, response.text())
        err = response.status
      }
      callback(err)
    }).catch(function (error) {
      err = error
      callback(err)
    })
  }

  appUpdateUserData(usrId, email, telephone, updated, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify({ "email": email, "telephoneNumber": telephone, "updated": updated })

    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    }

    var req = new Request(this._apiUrl('/auth/' + usrId + '/updateuserdata'))

    var err = null

    fetch(req, init).then(function (response, err) {
      // callback("typeof response")

      var contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then(function (json) {
          callback(response.status, json);
        });
      } else {
        callback("err", null)
      }
    }).catch(function (error) {
      err = error
      callback("err")
    })
  }

  appGetUserData(appId, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var init = {
      method: 'GET',
      credentials: "omit",
      headers: header//,
      //body: json
    }

    //var req = new Request(this._apiUrl('/auth/all?role=customer&email=' + appEmail))
    var req = new Request(this._apiUrl('/auth/userfromapp/' + appId))

    var err = null

    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then(function (json) {
          callback(200, json);
        });
      } else {
        callback(err, null)
      }
    }).catch(function (error) {
      err = error
      callback(err, null)
    })
  }

  appGetAuditLog(objectId, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var init = {
      method: 'GET',
      headers: header,
      credentials: "omit"
    }

    var req = new Request(this._apiUrl('/auditlog/' + objectId))

    var err = null

    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (response.status === 204) {
        return callback(204, null)
      }
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then(function (json) {
          callback(200, json);
        });
      } else {
        callback(err, null)
      }
    }).catch(function (error) {
      err = error
      callback(err, null)
    })
  }

  createSupplier(name, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify({ name })

    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    }

    var req = new Request(this._apiUrl('/suppliers/'))

    var err = null

    fetch(req, init).then(resp => resp.json().then(function (response) {
      if (response.status >= 400 || response.error) {
        console.warn("createSupplier(): error ", response)
        callback(response)
      } else
        callback({ status: 200, body: response })
    })).catch(function (error) {
      console.log("Error APICall")
      err = error
      callback({ error: err })
    })
  }

  createChildApplication(appId, trackingData, callback) {
    var header = new Headers()
    header.append('Content-Type', 'application/json; charset=UTF-8')
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify({ trackingData, targetProduct: "Refin", targetApplicationState: APPLICATION_STATE.Lead })

    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    }

    var req = new Request(this._apiUrl('/applications/' + appId + '/child'))

    fetch(req, init).then(resp => resp.json().then(function (response) {
      if (response.status >= 400 || response.error || response.validationErrors) {
        console.warn("createChildApplication(): error ", response)
        callback({ status: 400, error: JSON.stringify(response.validationErrors || response) })
      } else
        callback({ status: 200, body: response })
    })).catch(function (error) {
      console.log("Error APICall")
      callback({ status: 500, error: JSON.stringify(error) })
    })
  }

  closeLeads(applications, reason, finishedCallback, errorCallback) {
    let applicationsToClose = applications.length
    let countFinished = () => {
      applicationsToClose--;
      if (applicationsToClose === 0 && finishedCallback) {
        finishedCallback()
      }
    }
    for (let i = 0; i < applications.length; ++i) {
      this.closeLead(applications[i], reason, countFinished);
    }
  }

  closeLead(application, reason, finishedCallback, errorCallback) {
    var json = { reason: reason, revision: application.revision }
    var serviceUrl = '/applications/' + application.id + '/close';
    this._post(serviceUrl, json, finishedCallback, errorCallback);
  }

  paidLeads(applications, reason, paidDate, remark, comissionCode, finishedCallback, errorCallback) {
    let applicationsToArch = applications.length
    let countFinished = () => {
      applicationsToArch--;
      if (applicationsToArch === 0 && finishedCallback) {
        finishedCallback()
      }
    }
    for (let app of applications) {
      this.paidLead(app, reason, paidDate, null, remark, comissionCode, countFinished);
    }
  }

  paidLead(application, reason, paidDate, paidAmount, remark, comissionCode, finishedCallback, errorCallback) {
    var json = {
      Reason: reason,
      Revision: application.revision,
      PaidDate: paidDate,
      Remark: remark,
      ComissionCode: comissionCode,
      SupplierInternalName: reason,
      InfoChannel: "cof"
    }
    if (paidAmount) {
      json.PaidAmount = paidAmount
    }
    var serviceUrl = '/applications/' + application.id + '/paid';
    this._post(serviceUrl, json, finishedCallback, errorCallback);
  }

  addBid(id, bid, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify(bid)
    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    };
    var serviceUrl = '/applications/' + id + '/bids';
    var req = new Request(this._apiUrl(serviceUrl));

    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(callback(json));
      } else {
        console.warn("addBid(): error", response.status);
        if (errorCallback !== undefined) {
          errorCallback(response.status)
        }
      }
    }).catch(function (error) {
      console.warn("addBid() error:", error);
      if (errorCallback !== undefined) {
        errorCallback(null)
      }
    });
  }

  payOutApplicationsByPersonalNumbers(data, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))

    var json = JSON.stringify(data)
    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    };
    var serviceUrl = '/suppliers/me/applications/bids/payout';
    var req = new Request(this._apiUrl(serviceUrl));

    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(resp => callback(resp));
      } else {
        console.warn("payOutApplicationsByPersonalNumbers(): error", response.status);
        if (errorCallback !== undefined) {
          response.json().then(resp => errorCallback({ status: response.status, body: resp }));
        }
      }
    }).catch(function (error) {
      console.warn("payOutApplicationsByPersonalNumbers() error:", error);
      if (errorCallback !== undefined) {
        errorCallback(error)
      }
    });
  }

  rejectApplicationsByPersonalNumbers(data, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    // header.append('fz-gauth-authorization', getGAuthToken())

    var json = JSON.stringify(data)
    var init = {
      method: 'POST',
      headers: header,
      body: json,
      credentials: "omit"
    };
    var serviceUrl = '/suppliers/me/applications/bids/reject';
    var req = new Request(this._apiUrl(serviceUrl));

    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(resp => callback(resp));
      } else {
        console.warn("rejectApplicationsByPersonalNumbers(): error", response.status);
        if (errorCallback !== undefined) {
          response.json().then(resp => errorCallback({ status: response.status, body: resp }));
        }
      }
    }).catch(function (error) {
      console.warn("rejectApplicationsByPersonalNumbers() error:", error);
      if (errorCallback !== undefined) {
        errorCallback(error)
      }
    });
  }

  removeBid(appId, bidId, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())

    var init = {
      method: 'DELETE',
      headers: header,
    };

    var serviceUrl = '/applications/' + appId + '/bids/' + bidId;
    var req = new Request(this._apiUrl(serviceUrl));

    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then((newapp) => {
          callback(newapp)
        });
      } else {
        console.warn("removeBid(): error", response.status);
        if (errorCallback !== undefined) {
          errorCallback(response.status)
        }
      }
    }).catch(function (error) {
      console.warn("removeBid() error:", error);
      if (errorCallback !== undefined) {
        errorCallback(null)
      }
    });
  }

  _getCurrentSupplier(callback, errorCallback) {
    if (!this.isUserInRole("supplier")) {
      errorCallback("Invalid USER");
      return;
    }
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    var init = {
      method: 'GET',
      headers: header,
      cache: 'default'
    };
    var req = new Request(this._apiUrl('/suppliers/me'));

    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        if (response.status === 200) {
          response.json().then(function (json) {
            callback(json);
          });
        } else {
          console.warn("_getCurrentSupplier(): error", response.status);
          if (errorCallback !== undefined) {
            errorCallback(response.status)
          }
        }
      } else {
        errorCallback("content-type", contentType, "status", response.status);
      }
    }).catch(function (error) {
      console.warn("_getCurrentSupplier() error:", error);
    });

  }

  _post(serviceUrl, data, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_post() ", err);
      }
    }

    var init = {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {

      if ([403, 401].includes(response.status)) {
        clearAuth()
        window.location.href = getLogoutURL(window.location)
      }
      if (response.status === 200) {
        response.json().then(function (json) {
          if (callback) {
            callback(json);
          }
        }).catch((e) => {
          console.warn("ERROR", e)
        });
      } else if (response.status === 205) {
        callback({ requestStatus: 205, message: 'Reset Content' })
      } else if (response.status > 200 && response.status < 300) {
        if (callback) {
          callback(null);
        }
      } else if (response.status >= 400) {
        let errorMsg = String(response.status) + " - " + response.statusText
        response.json().then((err) => errorCallback(errorMsg + ": " + JSON.stringify(err))).catch(() => errorCallback(errorMsg));
      }
    }).catch(function (error) {
      errorCallback("_post API error", error);
    });
  }

  Post(serviceUrl, data) {
    return new Promise((fulfill, reject) => {
      this._post(serviceUrl, data, (json) => fulfill(json), (err) => reject(err))
    })
  }

  _get(serviceUrl, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_get() ", err);
      }
    }
    var init = {
      method: 'GET',
      headers: header,
      mode: 'cors',
      cache: 'default',
      credentials: "omit"
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      
      if ([403, 401].includes(response.status)) {
        clearAuth()
        window.location.href = getLogoutURL(window.location)
      } else if (response.status === 204) {
        callback(null)
      } else if (response.status === 205) {
        callback({ requestStatus: 205, message: 'Reset Content' })
      } else if (response.status < 400 && contentType && contentType.indexOf("application/json") !== -1) {
        response.json()
          .then(function (json) {
            callback(json);
          }).catch(function (error) {
            callback(response.text())
          });
      } else {
        let errorMsg = String(response.status) + " - " + response.statusText
        if (response.status >= 400) {
          response.json()
            .then((err) => errorCallback(errorMsg + ": " + err.message)).catch(() => errorCallback(errorMsg));
        } else {
          errorCallback(errorMsg + ": Invalid response type");
        }
      }
    }).catch(function (error) {
      errorCallback(error);
    });
  }


  Get(serviceUrl, queryObject) {
    const queryString = this._buildQueryString(queryObject);
    return new Promise((fulfill, reject) => {
      this._get(serviceUrl + (queryString ? `?${queryString}` : ""), (json) => fulfill(json), (err) => reject(err))
    })
  }

  _put(serviceUrl, data, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json; charset=UTF-8');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_put() ", err);
      }
    }

    var init = {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(data),
      credentials: "omit"
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (json) {
          callback(json);
        });
      } else if (response.status >= 400) {
        response.json().then((json) => {
          if (errorCallback !== undefined) {
            errorCallback(response.status + ":" + JSON.stringify(json))
          }
        }).catch(function (error) {
          console.warn(error)
          callback(error)
        });
      }

    }).catch(function (error) {
      errorCallback("_put()  API error", error);
      //app.ApiError();
    });

  }

  Put(serviceUrl, data) {
    return new Promise((fulfill, reject) => {
      this._put(serviceUrl, data, (json) => fulfill(json), (err) => reject(err))
    })
  }

  _delete(serviceUrl, callback, errorCallback) {
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', localStorage.getItem("fz-cof-token"))
    header.append('fz-gauth-authorization', getGAuthToken())
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_get() ", err);
      }
    }
    var init = {
      method: 'DELETE',
      headers: header,
      cache: 'default'
    };
    var req = new Request(this._apiUrl(serviceUrl));

    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (response.status === 204) {
        return callback({})
      }

      if (response.status < 400 && contentType && contentType.indexOf("application/json") !== -1) {

        response.json().then(function (json) {
          callback(json);
        }).catch(function (error) {
          console.warn(error)
          callback(response.text())
        });
      } else {
        let errorMsg = String(response.status) + " - " + response.statusText
        if (response.status >= 400) {
          response.json().then((err) => errorCallback(errorMsg + ": " + err.message)).catch(() => errorCallback(errorMsg));
        } else {
          errorCallback(errorMsg + ": Invalid response type");
        }
      }
    }).catch(function (error) {
      errorCallback(error);
    });
  }

  Delete(serviceUrl) {
    return new Promise((fulfill, reject) => {
      this._delete(serviceUrl, (json) => fulfill(json), (err) => reject(err))
    })
  }

  BuildImgUrl(image) {
    return this.baseStaticFilesUrl + image
  }

  _apiUrl(serviceUrl) {
    if (serviceUrl[0] === "/") {
      return this.baseUrl + serviceUrl.slice(1);
    }
    return this.baseUrl + serviceUrl;
  }
}

const applicationCloseReasons = [
  {
    closedReason: "customer-rejected",
    systemic: false,
    description: "Cliente Desistiu"
  }, {
    closedReason: "duplicated",
    description: "Pedido Duplicado"
  }, {
    closedReason: "customer-wrong-phone",
    systemic: false,
    description: "Telefones Inválidos"
  }, {
    closedReason: "customer-rejected-installment",
    systemic: false,
    description: "Valor da Parcela ou Taxa Muito Alto"
  }, {
    closedReason: "customer-rejected-amount",
    systemic: false,
    description: "Valor Aprovado Muito Baixo"
  }, {
    closedReason: "offer-expired",
    systemic: false,
    description: "Oferta Expirada"
  }, {
    closedReason: "filter-out-vehicle",
    systemic: false,
    description: "Veiculo fora do Perfil"
  }, {
    closedReason: "auto-close-too-many-calls",
    systemic: true,
    description: "Muitas tentativas de contado"
  }, {
    closedReason: "auto-close",
    systemic: true,
    description: "Fechado por tempo de inatividade"
  }, {
    closedReason: "not-matching",
    systemic: false,
    description: "Cliente fora do perfil"
  }, {
    closedReason: "filter-out-home",
    systemic: false,
    description: "Imóvel fora do Perfil"
  }, {
    closedReason: "competitor",
    systemic: false,
    description: "Fechou com outra empresa"
  }, {
    closedReason: "rejected",
    systemic: false,
    description: "Proposta rejeitada por todos parceiros"
  }, {
    closedReason: "excluded-data",
    systemic: false,
    description: "Exclusão de dados"
  },
  {
    closedReason: "has-no-check",
    systemic: false,
    description: " Não trabalha com cheque."
  }
]

export const StaticDomains = {
  getCloseReasons: () => {
    return applicationCloseReasons
  },
  getCloseReasonDescription: (reason) => {
    let r = applicationCloseReasons.filter((cr) => cr.closedReason === reason)[0]
    if (r) {
      return r.description;
    } else return reason;
  }
}

var currentApi
currentApi = !currentApi ? new FzClientApi("api/v2/", 4000, true) : currentApi
export default currentApi

var msgApi
msgApi = !msgApi ? new FzClientApi("messages/v2/api/", 3021) : msgApi
var lendingApi = currentApi

var misApi
misApi = !misApi ? new FzClientApi("mis/v1/", 3020) : misApi

var managerApi
managerApi = !managerApi ? new FzClientApi("manager/v1/", 3011) : managerApi

var campaignApi
campaignApi = !campaignApi ? new FzClientApi("api/v3/", 4000) : campaignApi

var screeningApi
screeningApi = !screeningApi ? new FzClientApi("screening/v1/", 3023) : screeningApi

export { msgApi, lendingApi, misApi, managerApi, campaignApi, screeningApi }