import React from 'react';
import { FzCard } from '../../fz/layout/index';
import { FzTable } from '../../fz/grid/table';
import { affiliateSummary } from '../../../biz/metadatas/affiliate'
import api from '../../../api/client-api'
import { StatsDetailHeader } from '../../../statistics/stats-components';

export class AffiliateOverview extends React.Component<any, any>  {

  render() {
    const { ns, data } = this.props

    if (!data) {
      return null
    }

    const user = api.getCurrentUser()

    return (

      <FzCard>

        <FzCard.Body>
          <FzCard>

            <FzCard.Heading  fzStyle={{}}>
              <FzCard.Title> <StatsDetailHeader stats={data} title={"Affiliate Overview"} periods={null} currPeriod={null} onChange={null} /> </FzCard.Title>
            </FzCard.Heading>

            <FzCard.Body>
              <FzTable
                data={data.statistics}
                metas={affiliateSummary}
                ns={ns}
                clipboard={user.role === "admin" ? true : false}
                exportDownload={user.role === "admin" ? true : false}
                visibleColumns={["_id", "period", "leads", "completed", "approvals", "accepted", "paid", "affiliateCommission", "affiliate", "key"]}
              />
            </FzCard.Body>

          </FzCard>

        </FzCard.Body>
      </FzCard >

    )
  }
}

