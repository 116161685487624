import React from 'react';

import { StatsDetailHeader } from '../../../statistics/stats-components'
import { setTTL } from '../../../business/misc-functions';
import { getRevenueModelOverview } from '../../../api/stats';
import { revenue } from "../../../biz/metadatas/revenue-model"
import { FzTable } from '../../fz/grid/table';
import { FzCard } from '../../fz/layout/index'
import { FzNavSelect } from '../../fz/nav';
import { PERIOD_DAY_WEEK_MONTH } from '../../../api/constants';

class RevenueModelOverview extends React.Component {

  constructor(props) {
    super(props)

    this.state = { requestState: "idle" };
    this.handleSelect = this.handleSelect.bind(this)
    this.getStatistics = this.getStatistics.bind(this)
  }

  getStatistics(period) {
    const { ns } = this.props
    this.setState({ requestState: "requesting" }, function () {
      this.props.toggleBlocking(() => {
        getRevenueModelOverview(period)
          .then((data) => {
            ns.set("revenueOverview", data, setTTL(5))
            this.setState({ requestState: "idle" }, () => this.props.toggleBlocking());
          })
          .catch((err) => {
            console.warn("API ERROR", err)
            this.props.toggleBlocking()
          })
      })
    })
  }

  handleSelect(periodo) {
    this.setState({ currentPeriodo: periodo }, () => this.getStatistics(this.state.currentPeriodo))
  }

  render() {
    const { ns } = this.props

    let data = ns.get("revenueOverview", {});
    let { requestState } = this.state;

    if (!data && requestState === "requesting") {
      return null
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title>
            <StatsDetailHeader stats={ns.get("revenueOverview")} />
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzNavSelect
            selected={this.state.currentPeriodo}
            onChange={this.handleSelect}
            options={[
              { "code": "day", "description": "Daily" },
              { "code": "week", "description": "Weekly" },
              { "code": "month", "description": "Monthly" },
            ]}
          />
          <FzCard.Body>
            <FzTable
              data={data.statistics}
              metas={revenue}
              ns={ns}
              visibleColumns={["key", "period", "leads", "auctions",
                "receivedOneBid", "acceptedOneBid", "paid"]}
            />
          </FzCard.Body>
        </FzCard.Body>
      </FzCard>
    )
  }
}

export { RevenueModelOverview }