import React from 'react';
import { getCurrentUser } from '../../../api/auth';
import { FzAccordion, FzCard, FzCol, FzRow } from '../../fz/layout/index'

interface AuditLogProps {
  auditLog: {
    Created: string,
    History: any,
    Id: string,
    Ip: string,
    Referer: string,
    UpdatedBy: string,
    UserAgent: string,
  }[];
}


interface AuditProps {
  audit: {
    Created: string,
    History: any,
    Id: string,
    Ip: string,
    Referer: string,
    UpdatedBy: string,
    UserAgent: string,
  }[];
}


export function AuditLog({ auditLog }: AuditLogProps) {

  const user = getCurrentUser() || {}
  if (!auditLog || auditLog.length === 0 || user.role === 'advisor') {
    return null
  }

  const auditLogInfo = auditLog.map((audit: any, index) =>
    <div key={index}>
      <FzAccordion>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzAccordion.Toggle FzElementType={"h5"} fzStyle={{ cursor: 'pointer' }} eventKey="0">
              Logs de Auditoria -
            {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(new Date(audit.Created))}
            </FzAccordion.Toggle>
          </FzCard.Heading>

          <FzAccordion.Collapse eventKey="0" className=''>
            <FzCard.Body>
              <AuditLogBody audit={audit} />
            </FzCard.Body>
          </FzAccordion.Collapse>
        </FzCard>
      </FzAccordion>
    </div>
  );
  return (
    <>
      {auditLogInfo}
    </>
  )
}


function AuditLogBody({ audit }) {

  if (!audit || !audit.History || audit.History.length === 0) {
    return null;
  }
  let k = 0
  const updatedAuditLogs = audit.History.map((adl) =>
    <FzCard key={k++}>
      <FzCard.Body>
        <FzRow>
          <FzCol span={6}>
            <strong>Affected {adl.Collection}</strong>
            <div className="dimmed">{adl.UpdatedId}</div>
          </FzCol>
          <FzCol span={6}>
            <strong>Operação</strong>
            <div className="dimmed">{adl.Description}</div>
          </FzCol>
        </FzRow>
        <FzRow>
          <pre>{JSON.stringify(adl.AffectedColumns, undefined, 2)}</pre>
        </FzRow>
      </FzCard.Body>
    </FzCard>
  );
  return (
    <FzCard.Body>
      <FzRow>
        <FzCol span={6}>
          <strong>Usuário</strong>
          <div>{audit.UpdatedBy}</div>
        </FzCol>
        <FzCol span={6}>
          <strong>Data</strong>
          <div>
            {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(audit.Created))}
          </div>
        </FzCol>
      </FzRow>
      {updatedAuditLogs}
    </FzCard.Body>
  );
}

