import React, { ReactNode } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Namespace } from '../../../scripts/state/redux_ns';
import { FzTableCustomToolBar, getVisibleColumns } from './toolbar-custom'
import { getDomain } from "../../../scripts/utils/cache-helpers";
import hooks from '../formatter/column-formatter'
import get from 'lodash/get'
import "../../fz/style/grid.css"

type FzRowExpandType = {
  expandComponent?: $TsFixMeFunction,
  expandableRow?: $TsFixMeFunction,
  onExpandRow?: $TsFixMeFunction,
}

type FzRowSelectionType = {
  multiple?: boolean,
  onSelect?: any,
  onSelectAll?: any,
  selectedKeys?: number[],
  clickToExpand?: boolean,
}

type FzTableOptionsType = {
  sizePerPageList?: { text: string, value: number }[];
  sizePerPage?: number;
  pageStartIndex?: number;
  paginationSize?: number;
  prePage?: string;
  nextPage?: string;
  firstPage?: string;
  lastPage?: string;
  paginationShowsTotal?: (start: string, to: string, total: string) => string;
  clearSearch?: boolean;
  expanding?: $TsFixMeFunction;
  expandBy?: string;
  components?: string | null;
  paginationPanel?: () => null,
  noDataText?: string;
  onExpand?: $TsFixMeFunction;
}

interface FzTableProps {
  data: any;
  pagination?: boolean;
  dataTotalSize?: number;
  remote?: boolean;
  customPagination?: boolean;
  context?: string;
  visibleColumns?: string[];
  customColumnFormatters?: $TsFixMe;
  rowExpand?: FzRowExpandType;
  rowDisabled?: (row: $TsFixMe, idx: string) => $TsFixMe;
  trClassFunction?: (row: $TsFixMe, idx: string) => $TsFixMe;
  rowSelection?: FzRowSelectionType;
  metas: any;
  ns: Namespace;
  exportDownload?: boolean;
  clipboard?: boolean;
  selectColumns?: boolean;
  hasSubsetVisibleColumns?: boolean;
  search?: boolean;
  instance?: string;
  hover?: boolean;
  configSort?: {
    orderBy: string;
    sortOrder: string
  },
  customHeader?: string;
  onPageChange?: (page: number, sizePerPage: number) => void;
  onFilterChange?: (filterObj) => void;
}

export function FzTable(props: FzTableProps) {
  let { data = [] } = props
  const {
    remote,
    ns,
    rowExpand,
    context,
    instance,
    rowSelection,
    rowDisabled,
    trClassFunction,
    pagination,
    customPagination,
    metas,
    customColumnFormatters,
    visibleColumns,
    exportDownload,
    clipboard,
    selectColumns,
    hasSubsetVisibleColumns,
    search,
    configSort,
    customHeader = 'default',
    onPageChange,
    onFilterChange,
   } = props


  let newInstance
  if (instance) {
    newInstance = context + "_" + instance
  }
  const columnsPaths = getVisibleColumns(visibleColumns, metas, ns, context, newInstance, hasSubsetVisibleColumns)
  const forceDataToNotBeUndefined = data || [] //TODO - Search for a better strategy to ensure that the data is not undefined

  let expandComponent
  let expandableRow
  let onExpandRow

  if (rowExpand) {
    expandComponent = rowExpand.expandComponent

    // If expandableRow is ommited, defaults to true
    expandableRow = rowExpand.expandableRow || (() => true)

    if (rowExpand.onExpandRow) {
      onExpandRow = rowExpand.onExpandRow
    }
  }

  let selectRow
  if (rowSelection) {
    selectRow = {
      mode: rowSelection.multiple ? "checkbox" : "radio",
      clickToExpand: true,
      clickToSelect: true,
      onSelect: rowSelection.onSelect,
      onSelectAll: rowSelection.onSelectAll,
      selected: rowSelection.selectedKeys
    }
  }

  if (configSort) {
    const { orderBy, sortOrder } = configSort
    data = sortOrder === 'desc' ?
      sortDescending(data, orderBy) :
      sortCresent(data, orderBy)
  }

  function createSpanColumns(columns: $TsFixMe[], columnNumber: number, colObject: $TsFixMe) {

    const extraColumns = colObject.tableProps.spanColumns.columns.map((column, index) => {

      // const path = column.pathExtraData ?
      //   colObject.tableProps.formatExtraData :
      //   column.path ? column.path : undefined;


      const path = column.path ? column.path : colObject.path
      const formatExtraData = column.formatExtraData ?
        column.formatExtraData :
        colObject.tableProps.formatExtraData

      return (
        <TableHeaderColumn
          key={columnNumber + index + 1}
          dataAlign={column.dataAlign || "center"}
          title={column.label}
          dataField={path}
          dataFormat={(cell, row, _, idx) => {
            const hook = hooks[column.columnFormat];
            cell = get(row, path);
            return hook(cell, row, formatExtraData, idx);
          }}
          formatExtraData={formatExtraData}
          row={column.row}
          width={column.width}
          colSpan={column.colSpan}
        >{column.label}</TableHeaderColumn>
      );
    });
    columns = [...columns, ...extraColumns];
    columnNumber += colObject.tableProps.spanColumns.columns.length;
    return { columns, columnNumber };
  }

  function createColumns(
    colObject: $TsFixMe,
    ns: Namespace,
    data: any,
    customColumnFormatters: $TsFixMe,
    columnNumber: number,
    columns: ReactNode[],
    hasSpanColumns: boolean,
    rowSpan?: string
  ) {
    const headerStyles = {
      default: {},
      success: { 'background': '#B1EDDC', borderColor: "#B1EDDC" },
      error: { 'background': '#FFDFDF', borderColor: "#FFDFDF" },
    }

    const dataFormat = (cell, row, _, idx) => {
      cell = get(row, colObject.path, cell)
      if (customColumnFormatters && customColumnFormatters[colObject.path]) {
        cell = customColumnFormatters[colObject.path](cell, row, colObject.tableProps.formatExtraData, idx, ns);
      } else if (hooks[colObject.tableProps.columnFormat]) {
        cell = hooks[colObject.tableProps.columnFormat](cell, row, colObject.tableProps.formatExtraData, idx, ns);
      }
      return cell;
    };

    const col = (
      <TableHeaderColumn
        isKey={colObject.tableProps.isKey}
        key={columnNumber}
        rowSpan={colObject.tableProps.rowSpan}
        dataField={colObject._extendedPath || colObject.path}
        width={colObject.tableProps.width}
        dataFormat={dataFormat}
        hidden={colObject.tableProps.hidden}
        dataAlign={colObject.tableProps.dataAlign}
        dataSort={colObject.tableProps.dataSort}
        sortFunc={hooks[colObject.tableProps.sortFunction]}
        headerText={colObject.tableProps.headerText}
        filter={createFilters(colObject, ns, data)}
        expandable={colObject.tableProps.expandable}
        thStyle={headerStyles[customHeader]}
      >
        {colObject.tableProps.shortLabel || colObject.label}
      </TableHeaderColumn>
    );
    columns.push(col);
  }

  function createFilters(colObject: $TsFixMe, ns: Namespace, data: any) {
    let columnFilter = {
      ...colObject.tableProps.filter,
    };

    if (colObject.tableProps.filter) {
      if (colObject.tableProps.filter.type === "SelectDomain") {
        columnFilter = {
          type: 'SelectFilter',
          condition: 'eq',
          options: getDomain(ns, colObject.domainName).reduce((resultSoFar, dom) => {
            return { ...resultSoFar, [dom.code]: dom.description };
          }, {})
        };

      } else {
        const filterObject = get(hooks, colObject.tableProps.filter.options);
        columnFilter.options = typeof filterObject === "function" ? filterObject(data, colObject.path) : filterObject;
      }
    }
    return columnFilter;
  }

  function extendedPathName(path) {
    if (path && path.indexOf(".") >= 0) {
      return "_" + path.replace(/\./g, "_")
    }
    return null
  }

  function buildVisibleColumns(data, metas, columnsPaths, customColumnFormatters, ns) {

    if (!metas) {
      console.warn("Invalid metadata in buildVisibleColumns")
      return null
    }

    let hasSpanColumns = false
    for (let path of columnsPaths) {
      let colObject = get(metas, path)
      if (colObject && colObject.tableProps.spanColumns) {
        hasSpanColumns = true
        break
      }
    }

    // Build Columns
    let columns: ReactNode[] = []
    let columnNumber: number = 0

    for (let path of columnsPaths) {
      let colObject = get(metas, path)
      if (!colObject) {
        console.warn("FzTable: Invalid key", path)
        continue
      }

      colObject._extendedPath = extendedPathName(colObject.path);
      if (colObject._extendedPath) {
        data = data.map(app => {
          app[colObject._extendedPath] = get(app, colObject.path)
          return app
        })
      }

      if (columnsPaths.indexOf(path) >= 0) {

        if (colObject.tableProps.spanColumns) {
          ({ columns, columnNumber } = createSpanColumns(columns, columnNumber, colObject));
        } else {
          createColumns(
            colObject,
            ns,
            data,
            customColumnFormatters,
            columnNumber,
            columns,
            hasSpanColumns);
        }
      }
      columnNumber += 1
    }
    return columns
  }

  function renderPaginationPanel(props) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div>
          <button onClick={() => {
            const size = props.sizePerPage === forceDataToNotBeUndefined.length ?
              5 :
              forceDataToNotBeUndefined.length
            props.changeSizePerPage(size)
          }} className='btn btn-link'>Mostrar Mais</button>
        </div>
      </div>
    );
  }

  function buildTableOptions(pagination, onExpandRow, customPagination) {
    const isRemote = (
      remote 
        ? {
            onPageChange: (page, sizePerPage) => onPageChange && onPageChange(page, sizePerPage),
            onFilterChange: (filterObj) => onFilterChange && onFilterChange(filterObj)
          } 
        : {}
    )
    const tablePaginationSettings: FzTableOptionsType = {
      sizePerPageList: [
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      sizePerPage: 50,
      pageStartIndex: 1,
      paginationSize: 4,
      prePage: 'Anterior',
      nextPage: 'Próxima',
      firstPage: 'Primeira',
      lastPage: 'Última',
      paginationShowsTotal: (start, to, total) => (`De ${start} até ${to} em ${total} `),
      clearSearch: true,
      noDataText: 'Nenhum dado carregado',
      ...(onExpandRow ? { onExpand: onExpandRow } : {}),
      ...isRemote,
    }

    if (customPagination) {
      return {
        paginationPanel: renderPaginationPanel,
        sizePerPage: 5,
      }

    }

    if (pagination) {
      return (tablePaginationSettings)
    }

    return ({
      paginationPanel: renderPaginationPanel,
    })
  }


  const handleTrClass = (row, idx) => {
    if (rowDisabled && rowDisabled(row, idx)) {
      return "fz-row-disabled"
    }

    if (trClassFunction) {
      return trClassFunction(row, idx)
    }

    return undefined
  }

  const fetchInfo = {
    dataTotalSize: get(props, ["dataTotalSize"], 0) 
  }

  const remoteFetch = (
    remote 
    ? {
        remote,
        fetchInfo,
      } 
    : {}
  )

  return (
    <>
      <FzTableCustomToolBar
        context={context}
        instance={instance}
        data={forceDataToNotBeUndefined}
        ns={ns}
        metas={metas}
        columnsPaths={columnsPaths}
        exportDownload={exportDownload}
        clipboard={clipboard}
        selectColumns={selectColumns}
      />
      <BootstrapTable
        {...remoteFetch}
        data={forceDataToNotBeUndefined}
        options={buildTableOptions(pagination, onExpandRow, customPagination)}
        trClassName={handleTrClass}
        striped
        hover
        pagination={pagination || customPagination}
        selectRow={selectRow}
        expandableRow={expandableRow}
        expandComponent={expandComponent}
        search={search}
      >
        {buildVisibleColumns(forceDataToNotBeUndefined, metas, columnsPaths, customColumnFormatters, ns)}
      </BootstrapTable>
    </>
  )
}


function sortDescending(data, orderBy) {
  return data.sort(function (a, b) {
    if (a[orderBy] > b[orderBy]) {
      return 1;
    }
    if (a[orderBy] < b[orderBy]) {
      return -1;
    }
    return 0;
  });
}

function sortCresent(data, orderBy) {
  return data.sort(function (a, b) {
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    return 0;
  });
}