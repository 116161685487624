import React from 'react';
import { connect as reactReduxConnect } from 'react-redux';
import { extractChanges, copyFields } from './diff_helpers.js';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import { createStore, combineReducers } from 'redux';

const SET = 'NS-SET',
  UNSET = 'NS-UNSET',
  PUSH = 'NS-PUSH',
  PULL = 'NS-PULL',
  CLEAR = 'NS-CLEAR';

export const IS_EDITING = 'IS_EDITING';
export const ORIGINAL = 'ORIGINAL';
export const CHANGED = 'CHANGED';
export const INVALID = 'INVALID';
export const FIELDS_TO_ENABLE = 'FIELDS_TO_ENABLE';

// Namespace is instantiated with a namespace (string), the store dispatcher and the 'namespaces' state.
//
// The get method returns a value for a key in the namespaced state and other methods dispatch an action
// to modify a value under a namespaced key.
//
// All methods allow use of keys like: 'a[0].b.c' to access subfields deep in the namespace tree.
//
export class Namespace {
  constructor(namespace, dispatch, state = {}, ttl) {
    if (!isString(namespace) || !isFunction(dispatch)) {
      throw new Error('Namespace constructor requires (namespace[string], dispatch[function], state[object]).');
    }
    this.namespace = namespace;
    this.dispatch = dispatch;
    this.state = state;
    this.defaultTtl = ttl || -1;
  }

  peek(ns) {
    return this.state[ns];
  }

  getShared(path, defaultValue) {
    if (!this.shared) {
      throw new Error('Namespace#getShared requires a shared NS.');
    }
    return this.shared.get(path, defaultValue)
  }

  setShared(path, value, ttl) {
    if (!this.shared) {
      throw new Error('Namespace#setShared requires a shared NS.');
    }
    if (!path) {
      throw new Error('Namespace#setShared requires a pair (path, value).');
    }
    this.shared.set(path, value, ttl)
  }

  clearShared(path) {
    this.shared.clear(path)
  }

  get(key, defaultValue) {
    if (key) {
      const val = get(this.state[this.namespace], key, defaultValue);
      if (val && val.ttl && new Date().getTime() > val.ttl && key !== CHANGED) {
        this.unset(key)
      }
      return val
    } else {
      return this.state[this.namespace];
    }
  }

  getChanged(path, defaultValue) {
    if (path) {
      return this.get(`${CHANGED}.${path}`, defaultValue);
    }
    return defaultValue ? defaultValue : this.get(CHANGED);
  }
  setDirty(dirty) {
    if (dirty) {
      this.dispatch({ type: SET, payload: { namespace: this.namespace, key: "dirty", value: true } });
    } else {
      this.dispatch({ type: UNSET, payload: { namespace: this.namespace, key: "dirty" } });
    }
  }
  set(key, value, ttl) {
    if (!key) {
      throw new Error('Namespace#set requires a pair (key, value).');
    }
    if (typeof value === "object" && this.defaultTtl !== -1 && ttl !== -1) {
      const now = new Date()
      now.setMinutes(new Date().getMinutes() + this.defaultTtl)
      value.ttl = ttl ? ttl : now.getTime()
    }
    this.dispatch({ type: SET, payload: { namespace: this.namespace, key, value } });
  }
  clear() {
    this.dispatch({ type: CLEAR, payload: { namespace: this.namespace, key: "" } })
  }
  isInvalid(path) {
    if (path !== undefined) {
      return this.get(INVALID, []).indexOf(path) >= 0
    } else {
      return this.get(INVALID, []).length > 0;
    }
  }
  getInvalidFields() {
    return this.get(INVALID, [])
  }
  // what could be a path or an Array of paths
  setInvalid(what) {
    if (Array.isArray(what)) {
      this.set(INVALID, what);
    } else {
      let tmp = this.get(INVALID, [])
      if (tmp.indexOf(what) < 0) {
        tmp.push(what);
        this.set(INVALID, tmp)
      }
    }
  }
  setValid(what) {
    if (!what) {
      this.unset(INVALID);
    } else {
      let tmp = this.get(INVALID, [])
      let x = tmp.indexOf(what)
      if (x >= 0) {
        tmp.splice(x, 1)
        this.set(INVALID, tmp)
      }
    }
  }
  unset(key) {
    if (!key) {
      throw new Error('Namespace#unset requires a key.');
    }
    this.dispatch({ type: UNSET, payload: { namespace: this.namespace, key } });
  }

  push(key, value) {
    if (!key) {
      throw new Error('Namespace#push requires a pair (key, value).');
    }
    this.dispatch({ type: PUSH, payload: { namespace: this.namespace, key, value } });
  }

  pull(key, value) {
    if (!key) {
      throw new Error('Namespace#pull requires a pair (key, value).');
    }
    this.dispatch({ type: PULL, payload: { namespace: this.namespace, key, value } });
  }

  startEditing(data, editingFields = []) {
    this.set(IS_EDITING, true);
    this.set(ORIGINAL, data);
    this.set(CHANGED, data);
    this.set(FIELDS_TO_ENABLE, editingFields);
  }

  stopEditing() {
    this.unset(IS_EDITING)
    this.unset(ORIGINAL);
    this.unset(CHANGED);
    this.unset(INVALID);
    this.unset(FIELDS_TO_ENABLE);
  }

  editingFields() {
    return this.get(FIELDS_TO_ENABLE)
  }

  isEditing() {
    return this.get(IS_EDITING)
  }

  saveChange(path, value) {
    this.set(`${CHANGED}.${path}`, value);
    this.setDirty(true)
  }

  // Compare CHANGED and ORIGINAL objects, find fields that have been changed,
  // created or removed (*) on CHANGED and return an object with 'changes' and 'original'
  // fields ('original' will only have fields that are also present in 'changes').
  //
  // (*) removed fields in the 'changes' object is indicated with an empty string value.
  //
  // See comments on extractChanges and copyFields for details of what is copied into
  // changes and original.
  //
  changes() {
    let changed = this.get(CHANGED);
    let original = this.get(ORIGINAL);
    const changes = extractChanges(changed, original);

    if (isEmpty(changes)) {
      return;
    } else {
      original = copyFields(changes, original);
      if (isEqual(changes, original)) {
        return;
      } else {
        return { changes, original };
      }
    }
  }

  // returns a new namespace. If the namespace start with '/', it is a full name.
  // Otherwise, it is a sufix for the current namespace.
  // reuse the state and dispatch, changing only the namespace name.
  copy(_sufix) {
    let ns;
    let sufix = String(_sufix);

    if (sufix.startsWith('/')) {
      ns = sufix;
    } else {
      ns = `${this.namespace}/${sufix}`;
    }

    let res = clone(this);
    res.namespace = ns;

    return res;
  }
};

// The Namespace methods that change state (set, unset, push, etc) dispatch actions with namespace, key, value.
// combineReducers calls this reducer with the state from 'namespaces' key on the global state. This is accomplished
// because the App creates the store in the following way:
// . const store = createStore(combineReducers({ namespaces: reducer }));
// This means 'state' received by this reducer holds all states for all namespaces and the namespace in the action payload
// is used to select the specific namespace to apply the action.
// 
export const reducer = (state = {}, action = {}) => {
  if (action.type === SET || action.type === UNSET || action.type === PUSH || action.type === PULL || action.type === CLEAR) {
    const { type, payload: { namespace, key, value } } = action;
    // get and shallow clone the ns subtree from the whole 'namespaces' state
    const ns = clone(get(state, namespace, {}));
    const rootKey = key.split('[')[0].split('.')[0];
    if (rootKey !== key) {
      // deep clone the ns subtree we will change unless it is a value directly at root of the ns
      ns[rootKey] = cloneDeep(ns[rootKey]);
    }
    let aux;
    let clear = false;
    switch (type) {
      case SET:
        set(ns, key, value);
        break;
      case UNSET:
        unset(ns, key);
        break;
      case PUSH:
        aux = get(ns, key, []);
        aux.push(value);
        set(ns, key, aux);
        break;
      case PULL:
        aux = get(ns, key);
        if (aux) {
          aux = pull(aux, value);
          set(ns, key, aux);
        }
        break;
      case CLEAR:
        clear = true;
        break;
      default:
        return state;
    }
    return { ...state, [namespace]: clear ? {} : ns };
  }

  return state;
};


// connect expect a 'namespace' name (string) as param.
// It returns a a wrapper function that can be called with a component and will return
// a component connected with the store that receives a 'ns' prop with a instance of
// NS with namespace 'namespace'.
export const connect = (namespace, ttl) => {
  const mapStateToProps = state => state;
  const mapDispatchToProps = dispatch => ({ dispatch: dispatch });
  const mergeProps = (state, dispatch, ownProps) => {
    let sharedNS
    if (Namespace.sharedNamespace) {
      sharedNS = new Namespace(Namespace.sharedNamespace, dispatch.dispatch, state.namespaces, -1)
    }
    const ns = new Namespace(namespace, dispatch.dispatch, state.namespaces, ttl);
    ns.shared = sharedNS;
    return Object.assign({}, ownProps, {
      ns: ns,
      sharedNS
    })
  };

  return (component) => reactReduxConnect(mapStateToProps, mapDispatchToProps, mergeProps)(component);
};

// withNS returns a namespaced Redux connected component that uses the current pathname
// (at that moment of use) as namespace.
// The namespace will have the helper methods defined in nsHelpers (above).
// Besides the 'ns' prop, extraProps can be used to pass additional props to the connected component.
export const withNS = (component, extraProps = {}) => (props) => {
  let pathname = extraProps.objectName || props.location.pathname
  const ConnectedComponent = connect(pathname, extraProps.ttl)(component);
  return <ConnectedComponent {...props} {...extraProps} />
};

export const createStoreNS = (options) => {
  const { sharedNSName, preload, enhancer } = options || {}
  Namespace.sharedNamespace = sharedNSName
  const store = createStore(combineReducers({ namespaces: reducer }), preload, enhancer);
  return store
}

export default { Namespace, IS_EDITING, ORIGINAL, CHANGED, INVALID, reducer, connect, withNS, createStoreNS };
