import React, { useEffect, useState } from 'react';
import api from "../../../api/client-api";
import { FzField, FzButton } from '../../fz/form';
import { FzCol, FzRow } from '../../fz/layout/index';

const styles = {
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  deleteButton: {
    fontSize: '18px',
  },
  inputTitle: {
    marginBottom: '8px',
  },
  spacement: {
    margin: '6px',
  },
};

export const MatchCampaigns = (props) => {
  const { deleteContainer, deleteButton, inputTitle, spacement } = styles;
  const { data, onChange } = props;
  const { dataType } = data;

  const [campaignOptions, setCampaignOptions] = useState([])
  const [formData, setFormData] = useState({
    from: false,
    to: false,
    initialValue: {
      type: 'int',
      from: null,
      to: null,
    },
    newFromType: {
      date: new Date().toISOString(),
      number: 0,
    }
  })

  useEffect(() => {
    getRules();
  }, []);

  const getRules = async () => {
    try {
      const rules = await api.Get(`/rules`);
      const campaignOptionList = rules.map(el => ({
        code: el.name,
        description: el.name,
      }))

      setCampaignOptions(campaignOptionList)
    } catch (error) {
      console.error(error)
    }
  }

  const toggleFrom = () => {
    const { datefrom } = data;
    const { newFromType } = formData;

    let value = datefrom !== undefined ? undefined : newFromType.date;
    value = value && new Date(new Date(value).setUTCHours(3, 0, 0, 0))
    onChange({
      ...data,
      datefrom: value
    });
  };

  const toggleTo = () => {
    const { dateto } = data;
    const { newFromType } = formData;

    let value = dateto !== undefined ? undefined : newFromType.date;
    value = value && new Date(new Date(value).setUTCHours(26, 59, 59, 59))
    onChange({
      ...data,
      dateto: value,
    });
  };

  const handleChange = (changeData) => {
    let { newFrom, newTo, campaign } = changeData
    const { datefrom, dateto } = data;
    const dataChange = {
      ...data,
      field: "campaign",
      dataType: "mixed"
    };

    if (newFrom) {
      newFrom = new Date(newFrom).toISOString();
      dataChange.datefrom = newFrom;
    } else if (newTo !== undefined) {
      newTo = new Date(newTo).toISOString();
      dataChange.dateto = newTo;
    } else if (campaign !== undefined) {
      dataChange.campaign = campaign
    }

    onChange(dataChange);
  };

  return (
    <div key={`rule-campaigns-${Math.random()}`} className="fz-rule rule-campaigns">
      <FzRow>
        <FzCol span={4}>
          <h4 style={inputTitle}>
            Recebeu a Campanha
          </h4>

        <FzField
          name={'campaigns-match-name'}
          dataType={'text'}
          inputType={'select'}
          value={data.campaign}
          inputProps={{ options: campaignOptions }}
          onChange={(value) => handleChange({campaign: value})}/>
        </FzCol>

        <FzCol span={3}>
          <h4 style={inputTitle}>De: (inclusive)</h4>
          <div className={'flex-line'}>
            <FzButton onClick={toggleFrom}>
              {data.datefrom !== undefined ? '-' : '+'}
            </FzButton>

            {data.datefrom && (
              <FzField
                name={'_' + dataType}
                inputType={'date'}
                dataType={'moment'}
                value={data.datefrom}
                onChange={(value) => handleChange({newFrom: value})} />
            )}
          </div>
        </FzCol>

        <FzCol span={3}>
          <h4 style={inputTitle}>Até: (exclusivo)</h4>
          <div className={'flex-line'}>
            <FzButton onClick={toggleTo}>
              {data.dateto !== undefined ? '-' : '+'}
            </FzButton>

            {data.dateto && (
              <FzField
                name={'_' + dataType}
                inputType={'date'}
                dataType={'moment'}
                value={data.dateto}
                onChange={(value) => handleChange({newTo: value})} />
            )}
          </div>
        </FzCol>

        <FzCol span={2}>
          <span style={deleteContainer}>
            <span style={spacement}/>
            <FzButton fzStyle={'alert'} onClick={() => {
              onChange(false);
            }}>
              <p style={deleteButton}>X</p>
            </FzButton>
          </span>
        </FzCol>
      </FzRow>
    </div>
  )
}