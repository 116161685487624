import React from 'react';
import { AggregationSearch } from '../../pages/aggregations/aggregation-search';
import { SimpleSearch } from '../../pages/aggregations/simple-search';

export function AggregationSearching({ ns, activeKey, toggleBlocking }) {
  switch (activeKey) {
    case "simple":
      return <SimpleSearch ns={ns} toggleBlocking={toggleBlocking} />
    case "advanced":
      return <AggregationSearch ns={ns} toggleBlocking={toggleBlocking} />
    default:
      return null
  }
}


