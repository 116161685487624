import React, { useState } from 'react'
import { get as getLodash } from 'lodash'
import BarChart from '@material-ui/icons/BarChart'
import List from '@material-ui/icons/List'

import { FzRow, FzCol, FzSplitLine } from '../../../ui/fz/layout/'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzNavSelect } from '../../../ui/fz/nav'
import { FzButton } from '../../../ui/fz/form'
import { StatsDetailHeader } from '../../../statistics/stats-components'
import {
  defaulColumnSummaries,
  builtMetaSummaries,
} from '../../../biz/metadatas/stats/formalization/unico'
import { formatMillisecondsToDate } from '../../../ui/fz/formatter/data-formatter'
import { buildPeriodFilters } from '../../../components/table-standard'
import { FzToast } from '../../../ui/fz/form/notification/toast'
import { SummariesGraphics } from './graphics'

const Summaries = ({
  data,
  ns,
  handleSearchReport,
  handleTostClose,
  showTost,
  suppliers,
}) => {
  const [showChart, setShowChart] = useState(false)
  const [periodSelected, setPeriodSelectedt] = useState('')
  const [exibitionSelect, setExibitionSelect] = useState({ code: '', label: ''})
  const [tableData, setTableData] = useState({
    totals: {},
    scoreRangeAggregation: {},
    avgTimespan: {},
    approvals: {},
  })
  
  const handleChangeSelect = (period) => {
    const periods = {
      "day": "Daily",
      "week": "Weekly",
      "month": "Monthly"
    }
    setExibitionSelect({ code: period, label: periods[period] })
    handleSearchReport(period)
  }

  const parseAvgSpanTime = item => {
    for(let key in item) {
      if(key !== 'description' && key !== 'total') {
        item = ({
          ...item,
          [key]: formatMillisecondsToDate(item[key])
        })
      }

      if(key === 'total') {
        const itemLength = Object.keys(item).filter(i => i !== 'description' && i !== 'total').length
        item = ({
          ...item,
          [key]: formatMillisecondsToDate((item[key] / itemLength))
        })
      }
    }

    return item
  }

  const handleChangePeriod = (period) => {
    setPeriodSelectedt(period)   
    if(data && data.statistics) {
      const defaultValue = { 
        totals: [], 
        scoreRangeAggregation: [], 
        avgTimespan: [], 
        approvals: []
      }
      const statistics = getLodash(data, ['statistics'], defaultValue)
      const findPeriod = item => item.period === period
      const totals = statistics.totals.find(findPeriod).data
      const scoreRangeAggregation = statistics.scoreRangeAggregation.find(findPeriod).data 
      const avgTimespan = statistics.avgTimespan.find(findPeriod).data
      const approvals = statistics.approvals.find(findPeriod).data

      setTableData({
        totals: {
          data: totals,
          meta: builtMetaSummaries(totals, {
            ...suppliers, 
            description: { name: 'Envios' },
            total: { name: 'Total' },
          })
        },
        scoreRangeAggregation: {
          data: scoreRangeAggregation,
          meta: builtMetaSummaries(scoreRangeAggregation, {
            ...suppliers, 
            description: { name: 'Faixa de Score da Único' },
            total: { name: 'Total' },
          })
        },
        avgTimespan: {
          data: avgTimespan.map(parseAvgSpanTime),
          meta: builtMetaSummaries(avgTimespan, {
            ...suppliers, 
            description: { name: 'Tempo de validação' },
            total: { name: 'Média geral' },
          })
        },
        approvals: {
          data: approvals,
          meta: builtMetaSummaries(approvals, {
            ...suppliers, 
            description: { name: 'Aprovação de selfies' },
            total: { name: 'Total' },
          })
        }
      })
    }
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <FzCard>
        <FzCard.Title>
          <FzCard.Header className='w-100'>
            <FzSplitLine>
              <FzSplitLine.Left>
              </FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzNavSelect
                  selected={exibitionSelect.code}
                  onChange={handleChangeSelect}
                  options={[
                    { "code": "day", "description": "Daily" },
                    { "code": "week", "description": "Weekly" },
                    { "code": "month", "description": "Monthly" },
                  ]}
                />
              </FzSplitLine.Right>
            </FzSplitLine>
            <FzRow>
              <FzCol span={12}>
                <StatsDetailHeader
                  title={`Summaries Único`}
                  stats={data}
                  periods={buildPeriodFilters(getLodash(data, ['statistics', 'totals'], []))}
                  currPeriod={periodSelected}
                  onChange={handleChangePeriod}
                />
              </FzCol>
            </FzRow>
          </FzCard.Header>
        </FzCard.Title>
        <FzCard.Body>
          <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <FzButton fzStyle='outline-secondary' onClick={() => setShowChart(!showChart)}>
              {showChart ? <List /> : <BarChart />}
            </FzButton>
          </div>
          
        { showChart && <SummariesGraphics tableData={tableData} /> }
        { !showChart && (
          <>
            <div className={'mt-3'}>
              <FzTable
                data={getLodash(tableData, ['totals', 'data'], [])}
                metas={getLodash(tableData, ['totals', 'meta'], defaulColumnSummaries)}
                ns={ns}
                exportDownload={false}
                clipboard={false}
                trClassFunction={() => 'fz-row-anti-flash-white'}
                customColumnFormatters={{
                  description: (cell) => (<strong>{cell}</strong>)
                }}
              />
          </div>

          <div className={'mt-3'}>
            <FzTable
              data={getLodash(tableData, ['approvals', 'data'], [])}
              metas={getLodash(tableData, ['approvals', 'meta'], defaulColumnSummaries)}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              trClassFunction={() => 'fz-row-anti-flash-white'}
            />
          </div>
          
          <div className={'mt-3'}>
            <FzTable
              data={getLodash(tableData, ['scoreRangeAggregation', 'data'], [])}
              metas={getLodash(tableData, ['scoreRangeAggregation', 'meta'], defaulColumnSummaries)}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              trClassFunction={({ description }) => {
                const order = Number(description.split(" ")[0])
                if(order >= -40 && order <= 0) {
                  return 'fz-row-danger' 
                }
                return 'fz-row-anti-flash-white'
              }}
            />
          </div>

          <div className={'mt-3'}>
            <FzTable
              data={getLodash(tableData, ['avgTimespan', 'data'], [])}
              metas={getLodash(tableData, ['avgTimespan', 'meta'], defaulColumnSummaries)}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              trClassFunction={() => 'fz-row-anti-flash-white'}
            />
          </div>
        </>
        )}

        </FzCard.Body>
      </FzCard>
      <FzToast
        fzStyle="danger"
        close={handleTostClose}
        show={showTost}
        delay={5000}
        headerMessageTost="Error"
        bodyMessageTost="Erro interno, tente novamente!"
        autoHide={true}
      />
    </div>
  )
}

export { Summaries }
