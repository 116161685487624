import api, {campaignApi, misApi} from './client-api';


export const getSummary = () => {
  return api.Get('/campaigns/summary');
};

export const getApplicant = (applicantId) => {
  return api.Get('/campaigns/' + applicantId);
};

export const couldntTalk = (applicantId, data) => {
  return api.Post('/campaigns/' + applicantId + '/postpone', data);
};

export const inactive = (applicantId) => {
  return api.Post('/campaigns/' + applicantId + '/inactive');
};

export const findApplicants = (personalNumber, telphoneNumber, email) => {
  return api.Get('/campaigns/all?' +
    'personalNumber=' + (personalNumber || '') +
    (telphoneNumber ? '&telphoneNumber=' + (telphoneNumber || '') : '') +
    email ? '&email=' + (email || '') : '',
  );
};

export const getCampaignApplicants = (campaign, active, converted, page, pageSize) => {
  let url = '/campaigns/all?campaign=' + campaign;
  url += ('&page=' + (page ? page : 1));
  if (pageSize !== undefined && pageSize !== null) {
    url += '&pageSize=' + pageSize;
  }
  if (active !== undefined && active !== null) {
    url += '&active=' + active;
  }
  if (converted !== undefined && converted !== null) {
    url += '&converted=' + converted;
  }
  return api.Get(url);
};

export const initNewCampaign = (campaign) => api.Post('campaigns/init', campaign);

export const initPresetCampaign = (preset, durationDays = 0, skipNotify = true, generateToken = false, generatePreAuth = false, scheduleDate, subject: string = "", generateFinanvc: boolean = false) => {

  let data = {
    presetName: preset,
    duration: durationDays,
    skipNotify,
    generatePreAuth,
    generateToken,
    scheduleDate,
    generateFinanvc
  }

  if(subject !== "") {
    data['notificationSubject'] = subject;
  }

  return campaignApi.Post('campaigns/create/preset', data);
};

export const getCampaigns = (filterType, filterTypeSelected, startDate, endDate) => {
  const query: string[] = []

  const convertDate = (dateString) => {
    let dateParts = dateString.split("/");

    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  };
  
  if (startDate) {
    query.push(`createdFrom=${convertDate(startDate)}`)
  }

  if (endDate) {
    query.push(`createdTo=${convertDate(endDate)}`)
  }

  if (filterType) {
    if (filterType === 'event') {
      query.push(`notificationEvent=${filterTypeSelected}`)
    } else if (filterType === 'campaignName') {
      query.push(`campaignName=${filterTypeSelected}`)
    }
  }

  const queryBuilded = query.length > 0 ? '?' + query.join('&') : ''
  
  return campaignApi.Get('/campaigns' + queryBuilded);
};

export const getCampaignsSummary = async (filterType, aggregation, isManual, type) => {
  const stringQueries: string[] = []
  if (isManual) stringQueries.push(`isManual=${isManual}`)
  if (type) stringQueries.push(`type=${type}`)

  const data = await misApi.Get(`/campaigns/summary/${filterType}/${aggregation}?${stringQueries.join('&')}`)

  return data.statistics;
};

export const activateCampaignById = (campaignId, skipNotify) => {
  return campaignApi.Post('campaigns/'+campaignId+'/build', {skipNotify: skipNotify})
}

export const stopBuildCampaign = (campaignName: string) => {
  return campaignApi.Post('campaigns/'+campaignName+'/stop-build')
}

export const notifyCampaignById = (campaignId) => {
  return campaignApi.Post('campaigns/'+campaignId+'/notify')
}

export const notifyCampaignUnread = (campaignId) => {
  return campaignApi.Post('campaigns/'+campaignId+'/notifyunread')
}

export const updateCampaignStatistics = (campaignId) => {
  return campaignApi.Post('campaigns/'+campaignId+'/update-summary')
}

export const activateCampaign = (campaign) => {
  return api.Post('/campaigns/build/' + campaign, {});
};

export const getUtmSource = () => {
  return api.Get('/parameters/campaigns/utm_source/', {});
};
