import { useState } from "react";
import { FzField } from "../../../../../fz/form";

export const FieldMetaPartner = ({ isEditing, ns, onChange, allProducts, index, setSaveDisabled, meta }) => {
  const [value, setValue] = useState(allProducts[index][meta.path]);
  const [invalidValue, setInvalidValue] = useState(false);

  const handleChange = (event) => {
    if (event || event == 0) {
      if (meta.regexValidation) {
        if (meta.regexValidation.test(event)) {
          allProducts[index][meta.path] = event

          onChange([...allProducts], false)
        } else {
          setInvalidValue(true)
          setSaveDisabled(true)
        }
      } else {
        allProducts[index][meta.path] = event
        onChange([...allProducts], false)
      }
      setValue(event)
    } else {
      allProducts[index][meta.path] = ""
      onChange([...allProducts], false)
      setValue("")
    }
  }

  return (
    <FzField
      readOnly={!isEditing.isEditing || isEditing.disabled}
      name={meta.path}
      dataType={meta.dataType}
      onChange={handleChange}
      inputType={meta.inputType}
      customClass={meta.customClass}
      value={value}
      label={meta.label}
      tip={meta.tip}
      placeHolder={meta.placeholder}
      mandatory={meta.isMandatory}
      validationMessage={meta.validationMessage}
      invalid={invalidValue}
      isJSON={meta.isJSON}
      informationMessage={meta.informationMessage}
      doNormalize={meta.normalize}
    />
  )
}