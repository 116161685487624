import React from 'react'
import BlockUi from 'react-block-ui'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { FzButton } from '../../../ui/fz/form'
import { FzCol, FzRow } from '../../../ui/fz/layout';
import FieldConnected from '../../../ui/components/form/field-connected';
import { ContractColumns, contractFilters } from '../../../biz/metadatas/Contract'
import { ContractResponse } from '../../../services/api/contract/types'
import { FzToast } from '../../../ui/fz/form/notification/toast';
import { get as getLodash } from 'lodash'

interface Types {
  ns: any,
  data: Array<ContractResponse>,
  handleUpdateContract: (contract: ContractResponse) => void,
  loading: boolean,
  toastInfo: any,
  handleToastClose: () => void,
  onPageChange: (page: number, sizePerPage: number) => void,
  filters: any,
  options: any,
  handleSearchContract: () => void,
  clearFilters: () => void,
}

const ContractList = ({ 
  ns, 
  data, 
  handleUpdateContract,
  loading,
  toastInfo,
  handleToastClose,
  onPageChange,
  filters,
  options,
  handleSearchContract,
  clearFilters,
}: Types) => {
  const UpdateContract = (_, row) => (
    <FzButton
      customStyle={{
        borderColor: '#5923DD',
        width: '100%',
        color: '#fff',
        background: '#5923DD'
      }}
      size="small"
      fzStyle=""
      onClick={() => handleUpdateContract(row)}
    >
      {row.status === 'inactive' ? 'Ativar' : 'Inativar'}
    </FzButton>
  )

  contractFilters
    .product 
    .inputProps
    .options = getLodash(options, ['products'], [])

  contractFilters
    .supplierinternalname
    .inputProps
    .options = getLodash(options, ['suppliers'], [])

  const formItems = Object.keys(contractFilters)
  const formItem = item => (
    <FzCol span={2} key={item}>
      <FieldConnected 
        meta={contractFilters[item]}
        data={filters}
        ns={ns}
      />
    </FzCol>
  )
  const buildFormItem = formItems.map(formItem)

  return (
    <BlockUi tag="div" blocking={loading} message="Processando informações...">
      <div style={{ display: "flex", padding: "20px" }}>
        <FzCard>
        <FzCard.Header>
          <FzCard.Title>
            Contratos
          </FzCard.Title>
        </FzCard.Header>
        <div className={"fz-panel"}>
            <div className={"fz-panel-border-padding"} style={{ display: "block", border: "none" }}>
                <div>
                  <small>É necessário preencher pelo menos um dos campos abaixo:</small>
                </div>
                <FzRow>
                  {buildFormItem}
                  <div  style={{ margin: "25px 10px 0 17px"}}>
                    <FzButton 
                      fzStyle="discrete" 
                      onClick={handleSearchContract}
                    >
                      Buscar Contrato
                    </FzButton>
                  </div>
                  <div  style={{ margin: "25px 10px 0 0"}}>
                    <FzButton 
                      fzStyle="info" 
                      onClick={clearFilters}
                    >
                      Limpar filtros
                    </FzButton>
                  </div>
                </FzRow>
            </div>
          </div>
          <FzCard.Body>
            <FzTable
              data={getLodash(data, ['contracts'], [])}
              dataTotalSize={getLodash(data, ['total'], 0)}
              remote={true}
              onPageChange={onPageChange}
              metas={ContractColumns}
              ns={ns}
              exportDownload={false}
              clipboard={false}
              pagination
              customColumnFormatters={{
                _id: (cell, row) => UpdateContract(cell, row)
              }}
            />
          </FzCard.Body>
        </FzCard>
        <FzToast {...toastInfo} close={handleToastClose} />
      </div>
    </BlockUi>
  )
}

export default ContractList
