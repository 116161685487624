import api from './client-api';
import { normalizeApplications } from '../biz/normalize';
import { misApi } from './client-api';

interface ContactsPerAgentOutcomeResponse {
  generatedAt: Date;
  statisticsType: string;
  consolidationPeriod: string;
  queryStartDate: Date;
  queryEndDate: Date;
  statistics: StatisticsResult[];
}
interface StatisticsResult {
  agent: string;
  key: number;
  outcome: string;
  period: Date;
  quantity: number;
  queue: string;
}

const getCsTreatmentsSummary = (period) => {
  return misApi.Get('/lending/applications/cstreatments/' + period);
};

const getAgentsProductivity = async (period: string) => {
  return await misApi.Get('/lending/applications/agentsproductivity/' + period);
};

const getCallsPerAgent = async (
  period: string
): Promise<ContactsPerAgentOutcomeResponse> => {
  return await misApi.Get('/lending/applications/callsperagent/' + period);
};

const getProductivityDS = () => {
  return api.Get('/parameters/datastudio.content.iframe/productivity');
};

// export const getContactsPerAgentOutcome = async (period): Promise<ContactsPerAgentOutcomeResponse> => {
//   return api.Get('/crm/contacts/agent/' + period);
// }

// const getFormalizationProductivity = (period) => {
//   return api.Get('/crm/contacts/productivity/' + period);
// }

const getContactsPerAgentTreatments = (period) => {
  return misApi.Get('/lending/applications/callspertreatments/' + period);
};

const getQueuesAdvisor = (queueName, queryObject) => {
  return api
    .Get('/crm/queues/' + queueName, queryObject)
    .then((data) => normalizeApplications(data));
};

const startCustomerService = (customerId, category, channel) => {
  const data = {
    channel: channel,
    category: category,
  };
  return api.Post('/crm/customer/' + customerId + '/start', data);
};

const getCustomer = (customerId) => {
  return api.Get('/crm/customer/' + customerId);
};

const getContactsForApplicationId = (applicationId) => {
  return api.Get('/crm/contacts/' + applicationId);
};

export {
  getCsTreatmentsSummary,
  getContactsPerAgentTreatments,
  getQueuesAdvisor,
  startCustomerService,
  getCustomer,
  getAgentsProductivity,
  getCallsPerAgent,
  getProductivityDS,
  getContactsForApplicationId,
};
