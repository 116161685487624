
const addZerosLeft = (s, len) => {
  for (let x = s.length; x < len; x++) {
    s = "0" + s
  }
  return s
}

// Banco do Brasil - START
const bb = {
  checkAgency: (data) => {
    return bb.check(data, "agency")
  },
  checkAccount: (data) => {
    return bb.check(data, "account")
  },
  check: (data, f) => {
    const props = bankProps[data.bank]
    return new Promise((resolve, reject) => {
      if (props[f + "Peso"]) {
        const peso = props[f + "Peso"].split("")
        const d = data[f]
        let sum = 0
        d.split("").forEach((item, i) => {
          sum += (d[i] * peso[i])
        });
        let calculatedDigit = (11 - ((sum % 11) === 0 ? 11 : (sum % 11))) === 10 ? "X" : (11 - ((sum % 11) === 0 ? 11 : (sum % 11))).toString()
        if ((calculatedDigit.toString() === data[f + "Digit"].toUpperCase())) {
          resolve(data)
        } else {
          reject({ expected: calculatedDigit, received: data[f + "Digit"].toUpperCase(), nsName: "bank-info", error: "validation-" + f + "-" + data.bank, field: f, valid: (calculatedDigit.toString() === data[f + "Digit"].toUpperCase()), bank: data.bank })
        }
      } else {
        resolve(data)
      }
    })
  }
}
// Banco do Brasil - END

// Santander - START
const santander = {
  checkAgency: (data) => {
    return santander.check(data, "agency")
  },
  checkAccount: (data) => {
    return santander.check(data, "account")
  },
  check: (data, f) => {
    const props = bankProps[data.bank]
    return new Promise((resolve, reject) => {
      if (props.peso) {
        const peso = props.peso.split("")
        const d = data.agency + "00" + data.accountType + data.account
        let sum = 0
        d.split("").forEach((item, i) => {
          sum += (d[i] * peso[i])
        });
        const calculatedDigit = 10 - parseInt(sum.toString().substr(-1), 10)
        if (calculatedDigit.toString() === data.accountDigit || (parseInt(sum.toString().substr(-1), 10) === 0 && data.accountDigit === "0")) {
          data.normalized = true
          resolve(data)
        } else {
          reject({ expected: calculatedDigit, received: data[f + "Digit"].toUpperCase(), nsName: "bank-info", error: "validation-" + f + "-" + data.bank, field: f, valid: (calculatedDigit.toString() === data[f + "Digit"].toUpperCase()), bank: data.bank })
        }
      } else {
        resolve(data)
      }
    })
  },
  normalize: (data) => {
    data.agency = addZerosLeft(data.agency ? data.agency : "", bankProps[data.bank].agencyLen)
    data.agencyDigit = addZerosLeft(data.agencyDigit ? data.agencyDigit : "", bankProps[data.bank].agencyDigitLen)
    data.accountDigit = addZerosLeft(data.accountDigit ? data.accountDigit : "", bankProps[data.bank].accountDigitLen)
    data.accountType = addZerosLeft(data.account ? data.account.substring(0, 2) : "", bankProps[data.bank].accountTypeLen)
    data.account = addZerosLeft(data.account ? data.account.substring(2, data.account.length) : "", bankProps[data.bank].accountLen)
    return data
  }
}
// Santander - END

// Caixa - START
const caixa = {
  accountValidator: (data) => {
    const f = "account"
    const props = bankProps[data.bank]
    return new Promise((resolve, reject) => {
      if (props.peso) {
        const peso = props.peso.split("")
        const d = data.agency.toString() + data.accountType.toString() + data.account.toString()
        let sum = 0
        d.split("").forEach((item, i) => {
          sum += (d[i] * peso[i])
        });
        const calculatedDigit = (sum * 10) % 11 === 10 ? 0 : (sum * 10) % 11
        if (calculatedDigit.toString() === data.accountDigit) {
          resolve(data)
        } else {
          reject({ expected: calculatedDigit, received: data[f + "Digit"].toUpperCase(), nsName: "bank-info", error: "validation-" + f + "-" + data.bank, field: f, valid: (calculatedDigit.toString() === data[f + "Digit"].toUpperCase()), bank: data.bank })
        }
      } else {
        resolve(data)
      }
    })
  }
}
// Caixa - END

// Itau - START
const itau = {
  accountValidator: (data) => {
    const f = "account"
    const props = bankProps[data.bank]
    return new Promise((resolve, reject) => {
      if (props.peso) {
        const peso = props.peso.split("")
        const d = data.agency.toString() + data.account.toString()
        let sum = 0
        d.split("").forEach((item, i) => {
          const mult = (d[i] * peso[i])
          sum = mult.toString().length > 1 ? sum + mult.toString().split("").reduce((prev, curr, index, list) => { return parseInt(prev, 10) + parseInt(curr, 10) }, 0) : sum + mult
        });
        const mod = (sum % 10) > 10 ? (sum % 10) / 10 : (sum % 10)
        const calculatedDigit = mod === 0 ? mod : 10 - mod
        if (calculatedDigit.toString() === data.accountDigit) {
          resolve(data)
        } else {
          reject({ expected: calculatedDigit, received: data[f + "Digit"].toUpperCase(), nsName: "bank-info", error: "validation-" + f + "-" + data.bank, field: f, valid: (calculatedDigit.toString() === data[f + "Digit"].toUpperCase()), bank: data.bank })
        }
      } else {
        resolve(data)
      }
    })
  }
}
// Itau - END

// Bradesco - START
const bradesco = {
  accountValidator: (data) => {
    const f = "account"
    const props = bankProps[data.bank]
    return new Promise((resolve, reject) => {
      if (props.accountPeso) {
        const peso = props.accountPeso.split("")
        const d = data.account.toString()
        let sum = 0
        d.split("").forEach((item, i) => {
          sum += (d[i] * peso[i])
        });
        const mod = (sum % 11)
        const calculatedDigit = mod < 2 ? (mod === 0 ? 0 : "P") : 11 - mod
        if (calculatedDigit.toString() === data.accountDigit.toUpperCase()) {
          resolve(data)
        } else {
          reject({ expected: calculatedDigit, received: data[f + "Digit"].toUpperCase(), nsName: "bank-info", error: "validation-" + f + "-" + data.bank, field: f, valid: (calculatedDigit.toString() === data[f + "Digit"].toUpperCase()), bank: data.bank })
        }
      } else {
        resolve(data)
      }
    })
  }
}
// Bradesco - END

// Banrisul - START
const banrisul = {
  accountValidator: (data) => {
    const f = "account";
    const props = bankProps[data.bank]
    return new Promise((resolve, reject) => {
      if (props.accountPeso) {
        const peso = props.accountPeso.split("")
        const d = (data.accountType.toString() + data.account.toString())
        let sum = 0
        const compares = []

        d.split("").forEach((item, i) => {
          compares.push({ ac: d[i], p: peso[i], m: d[i] * peso[i] })
          sum += (d[i] * peso[i])
        });
        const mod = (sum % 11)
        const calculatedDigit = [0, 1].indexOf(mod) > -1 ? (mod === 0 ? 0 : 6) : 11 - mod
        if (calculatedDigit.toString() === data.accountDigit.toUpperCase()) {
          resolve(data)
        } else {
          reject({ expected: calculatedDigit, received: data[f + "Digit"].toUpperCase(), nsName: "bank-info", error: "validation-" + f + "-" + data.bank, field: f, valid: (calculatedDigit.toString() === data[f + "Digit"].toUpperCase()), bank: data.bank, compares, sum, mod })
        }
      } else {
        resolve(data)
      }
    })
  },
  normalize: (data) => {
    data.agency = addZerosLeft(data.agency, bankProps[data.bank].agencyLen)
    data.agencyDigit = addZerosLeft(data.agencyDigit, bankProps[data.bank].agencyDigitLen)
    data.accountDigit = addZerosLeft(data.accountDigit, bankProps[data.bank].accountDigitLen)
    data.accountType = addZerosLeft(data.account.substring(0, 2), bankProps[data.bank].accountTypeLen)
    data.account = addZerosLeft(data.account.substring(2, data.account.length), bankProps[data.bank].accountLen)
    return data
  }
}
// Banrisul - END

const normalize = (data) => {
  return new Promise((resolve, reject) => {
    if (!bankProps[data.bank].normalize) {
      data.agency = addZerosLeft(data.agency ? data.agency : "", bankProps[data.bank].agencyLen)
      data.agencyDigit = addZerosLeft(data.agencyDigit ? data.agencyDigit : "", bankProps[data.bank].agencyDigitLen)
      data.account = addZerosLeft(data.account ? data.account : "", bankProps[data.bank].accountLen)
      data.accountType = addZerosLeft(data.accountType ? data.accountType : "", bankProps[data.bank].accountTypeLen)
      data.accountDigit = addZerosLeft(data.accountDigit ? data.accountDigit : "", bankProps[data.bank].accountDigitLen)
      resolve(data)
    } else {
      resolve(bankProps[data.bank].normalize(data))
    }
  })
}

const checkLength = (data) => {
  return new Promise((resolve, reject) => {
    let compares = []
    const props = bankProps[data.bank]
    if (Object.keys(data).map((f) => {
      compares.push({ expected: props[f + "Len"], received: data[f].length, field: f, valid: (props[f + "Len"] === data[f].length), bank: data.bank })
      return props[f + "Len"] === 0 ? true : props[f + "Len"] === data[f].length
    }).filter(e => e).length === (Object.keys(data).length - 1)) {
      resolve(data)
    } else {
      const f = compares.filter(e => !e.valid && e.field !== 'bank' && e.field !== 'accountType')[0]
      reject({ step: "checkLength", nsName: "bank-info", error: "length-" + f.field + "-" + data.bank, errors: compares.filter(e => !e.valid && e.field !== 'bank' && e.field !== 'accountType') })
    }
  })
}

export const bankProps = {
  // this one is here so validation doesn't fail if bank is set to 000
  "000": {
    name: "Não tenho conta em banco",
    agencyLen: 0,
    agencyDigitLen: 0,
    accountTypeLen: 0,
    accountLen: 0,
    accountDigitLen: 0
  },
  "001": {
    name: "Banco do Brasil",
    agencyLen: 4,
    agencyDigitLen: 1,
    // agencyValidator: bb.checkAgency, // we are not checking agency for any bank for now, we do not have agencyDigit
    agencyPeso: "5432",
    accountTypeLen: 0,
    accountLen: 8,
    accountDigitLen: 1,
    accountValidator: bb.checkAccount,
    accountPeso: "98765432",
  },
  "033": {
    name: "Santander Banespa",
    agencyLen: 4,
    agencyDigitLen: 0,
    agencyValidator: santander.checkAgency,
    accountTypeLen: 2,
    accountLen: 6,
    accountDigitLen: 1,
    agencyPeso: 0,
    normalize: (data) => santander.normalize(data),
    accountValidator: santander.checkAccount,
    peso: "97310097131973",
  },
  "041": { // TO-DO
    name: "Banrisul",
    agencyLen: 4,
    agencyDigitLen: 2,
    accountTypeLen: 2,
    accountLen: 7,
    accountDigitLen: 1,
    accountValidator: banrisul.accountValidator,
    accountPeso: "324765432",
    normalize: banrisul.normalize,
  },
  "104": {
    name: "Caixa Econômica Federal",
    agencyLen: 4,
    agencyDigitLen: 0,
    accountTypeLen: 3,
    accountTypes: {
      "special": "001",
      "checking": "001",
      "salary": "002",
      "savings": "013",
      "simplified": "023"
    },
    accountLen: 8,
    accountDigitLen: 1,
    accountValidator: caixa.accountValidator,
    peso: "876543298765432",
  },
  "237": {
    name: "Bradesco",
    agencyLen: 4,
    agencyDigitLen: 1,
    accountTypeLen: 0,
    accountLen: 7,
    accountDigitLen: 1,
    accountValidator: bradesco.accountValidator,
    agencyPeso: "5432",
    accountPeso: "2765432",
  },
  "341": {
    name: "Itaú",
    agencyLen: 4,
    agencyDigitLen: 0,
    accountTypeLen: 0,
    accountLen: 5,
    accountDigitLen: 1,
    accountValidator: itau.accountValidator,
    peso: "212121212",
  },
  "356": {
    name: "Real",
    agencyLen: 4,
    agencyDigitLen: 0,
    accountTypeLen: 0,
    accountLen: 7,
    accountDigitLen: 1,
    pesoAcc: "81472259395",
  },
  "399": {
    name: "HSBC",
    agencyLen: 4,
    agencyDigitLen: 0,
    accountTypeLen: 0,
    accountLen: 6,
    accountDigitLen: 1,
    peso: "8923456789",
  },
  "745": {
    name: "Citibank",
    agencyLen: 4,
    agencyDigitLen: 0,
    accountTypeLen: 0,
    accountLen: 10,
    accountDigitLen: 1,
  }
}



export const validateAccount = (value, app) => {
  const data = {
    bank: app.bankName,
    agency: app.agency,
    agencyDigit: "",
    account: value.split("-")[0],
    accountType: bankProps[app.bankName] && bankProps[app.bankName].accountTypes ? bankProps[app.bankName].accountTypes[app.accountType] : app.accountType,
    accountDigit: value.split("-")[1]
  }
  return bankValidator(data)
}

export const bankValidator = ({ bank, agency, agencyDigit, account, accountType, accountDigit }) => {
  if (bankProps[bank]) {
    return normalize({ bank, agency, agencyDigit, account, accountType, accountDigit })
      .then(checkLength)
      .then(bankProps[bank].agencyValidator)
      .then(bankProps[bank].accountValidator)
  } else {
    return Promise.resolve(true)
  }
}
