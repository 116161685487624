import { FzForm } from '../../fz/layout';
import { Component } from 'react';
import { blocklistCustomPlaceholder } from './blocklist-custom-fields';


export class MyCustomBodyModalBlocklist extends Component<any, any, any> {
  constructor(props) {
    super(props);
    this.state = {
      placeholderValue: 'Digite ...',
      type: '',
      fullblock: '',
      value: '',
      isValidType: true,
      isValidValue: true,
      isValidFullblock: true
    };
  }

  placeholderForDocType(docType: string) {
    this.setState({ placeholderValue: blocklistCustomPlaceholder(docType) })
  }

  getFieldValue() {
    const newRow = {};
    this.props.columns.map((column: HTMLFormElement) => {
      if (column.field === 'value' || column.field === 'type' || column.field === 'fullblock') {
        this.handleIsInvalid(this.state[column.field], [column.field])
      }
      newRow[column.field] = this.state[column.field];
    }, this);
    return newRow;
  }

  handleIsInvalid(value, field) {
    if (!value) {
      let f = Array.isArray(field) ? field[0] : field
      f === 'type' ? this.setState({ isValidType : false }) : 
      f === 'value' ? this.setState({ isValidValue : false }) :
        this.setState({ isValidFullblock : false });
      return
    }

    let f = Array.isArray(field) ? field[0] : field
      f === 'type' ? this.setState({ isValidType : true }) : 
      f === 'value' ? this.setState({ isValidValue : true }) :
        this.setState({ isValidFullblock : true });
    return
  }

  render() {
    const daysBlocklist =
      this.state.fullblock === 'true' ? 1825
        : this.state.type === 'email' || this.state.type === 'telephone' ? 90
        : 180;


    return (
      <div className="modal-body">
        {this.props.columns.map((column, i) => {
          const { editable, field, name, hiddenOnInsert } = column;

          if (hiddenOnInsert || !editable || name === "Ativo na blocklist") {
            return null;
          }

          return (
            <div className="form-group" key={field}>
              <FzForm.Label> {name} *</FzForm.Label>
              {field === 'value' ? (
                <FzForm.Control
                  as="input"
                  name="value"
                  placeholder={this.state.placeholderValue}
                  value={this.state.value}
                  isInvalid={!this.state.isValidValue}
                  onChange={(e) => {
                    this.setState({ value: e.target.value });
                    this.handleIsInvalid(e.target.value, field);
                  }}
                />
              ) : (
                <FzForm.Control
                  as="select"
                  name={field}
                  placeholder="Selecione..."
                  value={this.state[field]}
                  isInvalid={field === 'type' ? !this.state.isValidType : !this.state.isValidFullblock}
                  onChange={(e) => {
                    this.setState({ [field]: e.target.value });
                    this.handleIsInvalid(e.target.value, field);
                    field === 'type'
                      ? this.placeholderForDocType(e.target.value)
                      : null;
                  }}
                >
                  {editable.options.values.map((opt, index) => {
                    return (
                      <option key={index} value={opt.value}>
                        {opt.text}
                      </option>
                    );
                  })}
                </FzForm.Control>
              )}
            </div>
          );
        })}
        {this.state.fullblock && this.state.type && this.state.value && (
          <div className="form-group" key="daysBlocklist">
            <strong>
              Dias de permanência na blocklist: {daysBlocklist} dias
            </strong>
          </div>
        )}
      </div>
    );
  }
}
