import React, { useState, ReactNode } from 'react';
import { FzCard } from '../../../../../fz/layout/FzCard';
import { FzNavSelect } from '../../../../../fz/nav';
import { ScreeningAnalytics } from '../../screening-analytics';
import { ScreeningSummaries } from '../../screening-summaries';

function ScreeningReport({ toggleBlocking, ns, api }) {

  const [typeView, setTypeView] = useState('')

  const contents = {
    analytics: <ScreeningAnalytics ns={ns} toggleBlocking={toggleBlocking} />,
    summaries: <ScreeningSummaries ns={ns} toggleBlocking={toggleBlocking} />
  }
  let defaultSubmenuOptions = [
    { code: "analytics", description: "Analytics" },
    { code: "summaries", description: "Summaries" },
  ]
  let screenerTypeView: any = null
  if(api.isUserInRole("screener")){
    defaultSubmenuOptions = [
      { code: "summaries", description: "Summaries" },
    ]
    screenerTypeView = "summaries"
  }

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0, marginLeft: '0px' }}
          value={screenerTypeView || typeView}
          options={defaultSubmenuOptions}
          onChange={(value) => {
            setTypeView(value)
          }}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[screenerTypeView || typeView]}
      </FzCard>
    </>
  )
}

export { ScreeningReport }