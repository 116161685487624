import { Meta } from "../../../types/metas";

export const QueueDetailsMeta: Meta = {
  queueName: {
    path: "currentQueue.queueName",
    label: "Nome da fila",
    dataType: "string",
    inputType: "text",
    placeholder: "Digite o nome da fila",
    validationMessage: "Este campo é obrigatório",
    isReadOnly: () => false,
    isMandatory: (app)=> true,
  },

  delay: {
    path: "currentQueue.delay",
    label: "",
    dataType: "number",
    inputType: "number",
    isReadOnly: () => false,
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> true,
  },

  validade: {
    path: "currentQueue.validade",
    label: "Validade em dias (ticket)",
    dataType: "number",
    inputType: "number",
    isReadOnly: () => false,
    isMandatory: (app) => false
  },

  cadencia: {
    path: "currentQueue.cadencia",
    label: "Cadência",
    dataType: "string",
    inputType: "text",
    placeholder: "Ex.: 0; 1; 2; 3...",
    isReadOnly: () => false,
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app) => true,
  },

  products: {
    path: "currentQueue.products[0]",
    label: "Produto",
    dataType: "string",
    inputType: "select",
    isReadOnly: () => false,
    inputProps: {
      options: undefined
    },
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> true,
  },

  suppliers: {
    path: "currentQueue.suppliers[0].supplier",
    label: "Parceiro",
    dataType: "string",
    inputType: "auto-complete",
    isReadOnly: () => false,
    inputProps: {
      options: undefined,
    },
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> false,
  },

  rule: {
    path: "currentQueue.suppliers[0].rule",
    label: "Inclusão/Exclusão (Branches)",
    dataType: "string",
    inputType: "select",
    isReadOnly: () => false,
    inputProps: {
      options: [
        {code: 'include', description: 'Incluir'},
        {code: 'exclude', description: 'Excluir'}
      ]
    },
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> false,
  },

  branch: {
    path: "branches",
    label: "Branch",
    dataType: "string",
    inputType: "select",
    isReadOnly: () => false,
    inputProps: {
      options: undefined
    },
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> false,
  },

  orderBy: {
    path: "currentQueue.orderBy",
    label: "Ordenação",
    dataType: "string",
    inputType: "select",
    isReadOnly: () => false,
    inputProps: {
      options: [
        {code: 1, description: 'FIFO'},
        {code: -1, description: 'LIFO'}
      ]
    },
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> true,
  },

  events: {
    path: "currentQueue.events[0]",
    label: "Eventos",
    dataType: "string",
    inputType: "select",
    isReadOnly: () => false,
    domainName: "events-hermes",
    filterType: "domain",
    inputProps: {
      domainName: "events-hermes",
    },
    validationMessage: "Este campo é obrigatório",
    isMandatory: (app)=> true,
  },
}