import React from 'react';
import { FzTable } from '../fz/grid/table';
import { Application } from '../../biz/metadatas/application'
import { ApplicationDetail } from '../../crm/active-applications'

import api from './../../api/client-api';


class ActiveApplications extends React.Component {
  constructor(props) {
    super(props)
    this.expandComponent = this.expandComponent.bind(this)
  }

  expandComponent(row, customer, ns, basePath, history, onNeedUpdate) {
    return !api.isUserInRole("admin") ? (
    <ApplicationDetail 
      app={row}
      customer={customer}
      ns={ns}
      basePath={basePath}
      history={history}
      ticket={this.props.ticket}
      onNeedUpdate={onNeedUpdate} 
    />) : null
  }

  render() {
    const { priorityAttendance, customer, ns, basePath, history, onNeedUpdate, applications } = this.props

    if (!applications) {
      return null
    }

    const expanding = []
    if (priorityAttendance) {
      expanding.push(priorityAttendance.id)
    }

    return (
      <div>
        <FzTable
          data={applications}
          metas={Application.fields}
          visibleColumns={["id", "created", "product", "applicationState", "auction.endDate", "city", "appliedAmount",
            "loanPeriodInMonths", "loanReason", "monthlyIncome", "bankName"]}
          ns={ns}
          rowExpand={{
            expandableRow: () => true,
            expandComponent: (row) => this.expandComponent(row, customer, ns, basePath, history, onNeedUpdate),
            onExpandRow: () => expanding
          }}
          context={"activeApplications"}
          exportDownload={false}
        />
      </div>
    );
  }
}

export { ActiveApplications }