
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';


const alertStyles = {
  "alert": "danger",
  "attention": "warning",
}

class FzAlert extends React.Component {

  static propTypes = {
    fzStyle: PropTypes.string,
    title: PropTypes.string
  };

  render() {
    const {fzStyle, children, title } = this.props;
    const bsClass = alertStyles[fzStyle]
    return <Alert bsClass={bsClass} title={title} ><React.Fragment>{children}</React.Fragment> </Alert>
  }
}

export { FzAlert }