import { useState } from 'react';
import FieldConnected from '../components/form/field-connected';
import { FzCard } from '../fz/layout/FzCard';
import { FzCol, FzRow } from '../fz/layout/index';
import { cloneDeep } from 'lodash';
import { TraceModal } from './integration-queues';
import { getTrace } from '../../services/api/bureau/index';
import { FzButton } from '../fz/form';
import api from '../../api/client-api';

interface ApplicationSectionProps {
  ns: any;
  data: any;
  metas: any;
  title: string;
  readOnly?: boolean;
  supplierInternalName?: any;
}

export function ApplicationSection({
  metas,
  data,
  ns,
  title,
  readOnly,
  supplierInternalName,
}: ApplicationSectionProps) {
  let fields = metas;
  let fieldsOverrides: string[] = [];

  if (supplierInternalName) {
    const bid = data.auction.bids.find(
      (bid) => bid.supplier.internalName === supplierInternalName
    );
    fieldsOverrides =
      bid && bid.fieldsOverrides ? Object.keys(bid.fieldsOverrides) : [];
  }

  const fieldElms = Object.keys(fields).map((field) => {
    if (supplierInternalName && fieldsOverrides.length > 0) {
      if (fieldsOverrides.indexOf(fields[field].path) !== -1) {
        fields[field] = cloneDeep(fields[field]);
        fields[field].inputProps.highlightFields = true;
      }
    }
    return (
      <FieldConnected
        key={field}
        meta={fields[field]}
        data={data}
        ns={ns}
        readOnly={readOnly}
      />
    );
  });

  let rows: any = [];
  let lastRow: any = [];
  for (
    var iteratorFieldElms = 0;
    iteratorFieldElms < fieldElms.length;
    iteratorFieldElms++
  ) {
    if (iteratorFieldElms % 2 === 0) {
      lastRow = [];
      rows.push(lastRow);
    }
    lastRow.push(
      <FzCol key={iteratorFieldElms}>{fieldElms[iteratorFieldElms]}</FzCol>
    );
  }

  let fieldsContent = rows.map((rowContent, index) => {
    return <FzRow key={index}>{rowContent}</FzRow>;
  });

  const [showTrace, setShowTrace] = useState(false);
  const [traceContent, setTraceContent] = useState<Promise<any>>()

  const displayTraceServiceBureau = () => {
    const domainsService = {
      'Detalhes do Veiculo': 'infocar-vehicle',
      'Dados da Residência': 'boavista-addressproof',
      'Dados de Trabalho': 'boavista-incomeproof',
    };

    const serviceGetTrace = Object.entries(domainsService).find((service) =>
      service[0] === title ? service[1] : null
    );

    if (api.isUserInRole("admin")) {
      if (serviceGetTrace) {
        return (
          <FzButton onClick={async () => getTraceServiceBureau(serviceGetTrace)}>
            Trace
          </FzButton>
        );
      }
    }
    return null;
  };

  const getTraceServiceBureau = async (serviceGetTrace: any[]) => {
    setShowTrace(true);
    const trace = await getTrace(data.id, serviceGetTrace[1]);
    if (trace) {
      setTraceContent(trace);
    }
    return
  };

  return (
    <FzCard>
      <FzCard.Header>
        <FzCard.Title
          fzStyle={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {title}
          {displayTraceServiceBureau()}
        </FzCard.Title>
      </FzCard.Header>
      <FzCard.Body>
        <TraceModal
          show={showTrace}
          traceContent={traceContent}
          onHide={() => setShowTrace(!showTrace)}
        />
        {fieldsContent}
      </FzCard.Body>
    </FzCard>
  );
}
