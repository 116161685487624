import React, { useState } from 'react'
import '../../../css/screening/screening.css'
import { FzButton, FzField } from '../../../ui/fz/form'
import { FzToast } from '../../../ui/fz/form/notification/toast'
import { routes, ScreeningDetails, ScreeningDocumentStatesMeta, ScreeningTitle } from '../../../ui/pages/screening/screening-index'
import { DocumentImage } from '../../../ui/blocks/screening/images/document-image'
import FieldConnected from '../../../ui/components/form/field-connected'
import { FzModal } from '../../../ui/fz/fz-modal'
import { Modal } from 'react-bootstrap'
import UploadDocument from '../../../application-components/upload-document'
import { FzMultiSelect, FzSelect } from '../../../ui/fz/form/old-simple-input'
import { useHistory } from 'react-router-dom'
import { ApplicationFieldsForDocumentContainer } from './ApplicationFieldsForDocument/index'
import { Paginator } from '../../../components/Paginator/index'
import PdfView from '../../../components/PdfView'
import { DocumentText } from '../../../ui/blocks/screening/document-text'

const IndividualAnalizeContainer = ({
  ns,
  showFullScreen,
  urlImage,
  doc,
  docDomains,
  screening_response,
  nOfSelect,
  documentUrl,
  ruleKeysNotToSend,
  previousPage,
  editingFields,
  currDocumentIndex,
  applicationId,
  bidId,
  setUrlImage,
  uploadModal,
  optionsReasons,
  reason,

  nextPage,
  setHeaderMessageTost,
  setBodyMessageTost,
  seColorTost,
  setShow,

  postponeModalOpen,
  handlePostponeModalClose,

  handlePostpone,
  setAsPending,
  setShowFullScreen,
  handleSetDocsValid,
  goTo,
  setReason,
  setShowRejectDocModal,
  handleClose,
  handlePostponeModalOpen,
  setUploadModal,
  handleAskNewBureauDoc,
  toggleEditingFields,
  getFormalizationDocumentUrl,
  handleClickImage,
  handleUploadFileScreening,
  showRejectDocModal,
  setRejectApplication,
  formalization_documents,

  documentSubType,
  totalNumberOfDocs,
  currentDocIndex,

  partnerSpecificChecklistMeta,
  nonPartnerSpecificChecklistMeta,
  handleSuccessButton,

  colorTost,
  show,
  headerMessageTost,
  bodyMessageTost,
  setFieldsOverride


}) => {
  const [documentsToInsertValue, setDocumentsToInsertValue] = useState([])

  function handleDocumentsToInsert(values) {
    setDocumentsToInsertValue(values)
  }

  function handleInsertDocuments() {
    handleSuccessButton(documentsToInsertValue)
    setDocumentsToInsertValue([])
  }

  const history = useHistory()

  let optionsOfDocumentsToInsert = []
  if (screening_response && screening_response.documentsToInsert) {

    optionsOfDocumentsToInsert = screening_response.documentsToInsert
      .filter(doc => !doc.inserted)
      .map(doc => {
        const docType = formalization_documents
          .find((it) => it.code === doc.documentType)?.description

        if (doc.finanzeroGenerated) {
          return {
            finanzeroGenerated: doc.finanzeroGenerated,
            code: `${doc.documentType}-boa-vista`,
            description: `${docType} - Boa Vista (consulta manual)`
          }
        }

        return {
          code: doc.documentType,
          description: docType,
          finanzeroGenerated: doc.finanzeroGenerated
        }
      })
  }

  function handleRenderDocument(): React.ReactChild {
    if (!doc) {
      return <></>
    }
    if (doc.mimeType && doc.mimeType.includes('application/pdf')) {
      return <PdfView url={documentUrl} documentType={doc.documentType} />
    }
    // TODO: change to mimetype later
    else if (doc.documentType === 'address-eletricbill-number') {
      return <>
        <DocumentText
          url={String(documentUrl)}
          documentType={String(doc.documentType)}
          bidId={bidId}
          applicationId={applicationId}
          ns={ns} />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
          <img
            alt={doc.documentType}
            style={{ width: '49%' }}
            src={getFormalizationDocumentUrl(applicationId, bidId, 'address-eletric-bill', 'png', 'address-eletric-bill')}
            onClick={() => {
              handleClickImage(setShowFullScreen, applicationId, bidId, 'address-eletric-bill', setUrlImage, 'address-eletric-bill');
            }}
          />
        </div>
      </>
    } else {
      return <DocumentImage
        btnStyle={{ marginTop: '10px', display: 'flex' }}
        style={{ width: '100%' }}
        alt={docDomains.description}
        url={documentUrl}
        doc={doc}
        mimeType={doc.mimeType} />

    }
  }

  return <>

    {showFullScreen === true ?
      <div onClick={() => setShowFullScreen(!showFullScreen)}>
        <img
          style={{
            backgroundSize: 'auto',
            width: '100vw',
            position: 'absolute',
            zIndex: 1100,
          }}
          src={urlImage}
          alt="url image"
        />
      </div>
      : <>
        <ScreeningTitle step={routes.INDIVIDUAL_ANALYSIS} docName={docDomains.description} docSubType={documentSubType} totalNumberOfDocs={totalNumberOfDocs} currentDocIndex={currentDocIndex + 1} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ScreeningDetails className="mb-3" screeningResponse={screening_response} ns={ns}/>
          <div style={{ textTransform: "capitalize" }}>
            <FzButton
              id={`${doc.documentType}-postpone`}
              className={"mr-3"}
              fzStyle={"primary"}
              onClick={handlePostponeModalOpen}
            >
              Pendência Interna
            </FzButton>
            {ScreeningDocumentStatesMeta[doc.state] ? "Status: " + ScreeningDocumentStatesMeta[doc.state].label : "Status não definido"}
          </div>
        </div>
        {doc.documentType !== 'selfie' ?
          (
            <ApplicationFieldsForDocumentContainer
              className="mb-3 p-3 screening-fields"
              handleEditing={toggleEditingFields}
              setHeaderMessageTost={setHeaderMessageTost}
              setBodyMessageTost={setBodyMessageTost}
              seColorTost={seColorTost}
              setShow={setShow}
              isEditing={editingFields}
              ns={ns}
              data={screening_response.customerFields}
              setFieldsOverride={setFieldsOverride}
              style={{
                display: "flex",
                width: "100%",
                flexFlow: "wrap",
                border: "1px solid #ced4da",
                borderRadius: "0.25rem"
              }}
            />
          ) : null
        }
        <div style={{ display: "flex" }}>
          <div style={{ width: '100%', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: '37px' }}>
            { handleRenderDocument() }
          </div>
          <div className="ml-3" style={{ marginTop: '37px' }}>
            {doc.isValidated === true || doc.isValidated === false ?
              <div style={{ textTransform: "capitalize" }}>
                {doc.isValidated === false ? "É um documento novo" : "Não é um documento novo"}
                <br />
                {doc.lastState !== null ?
                  <strong> Histórico Status: {ScreeningDocumentStatesMeta[doc.lastState].label} </strong>
                  : null}
                <p></p>
              </div>
              : null}
            <div>
              <FieldConnected customClass={'col-12 pl-0 pr-0'} meta={partnerSpecificChecklistMeta} ns={ns} data={ns.getChanged() || { 'partnerSpecificChecklistMeta': doc.pendedRules }} />
              <FieldConnected customClass={'col-12 pl-0 pr-0'} meta={nonPartnerSpecificChecklistMeta} ns={ns} data={ns.getChanged()} />
            </div>

            <div className={"mt-4"} style={{ display: "flex" }}>
              <div style={{ minWidth: "calc(100%/2)", padding: '4px' }}>
                <FzButton
                  customStyle={{ width: '100%' }}
                  tip={"Registrar que todas as regras deste documento passaram."}
                  fzStyle={"regular"}
                  disabled={
                    nOfSelect > 0 ||
                    doc.pendedRules.indexOf('doc-is-blank') !== -1 ||
                    // TODO: remove the "address-eletricbill-number" later
                    (doc.mimeType === null && doc.documentType !== "address-eletricbill-number")}
                  onClick={() => {
                    handleSetDocsValid(doc.documentType, ns, () => goTo(history, nextPage), setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow, history)
                  }}
                >
                  Validar
                </FzButton>

                <FzButton
                  customStyle={{ width: '100%' }}
                  tip={"Registrar que existem regras que não passaram neste documento."}
                  className={"mt-3"} fzStyle={"attention"}
                  disabled={nOfSelect === 0 || doc.pendedRules.indexOf('doc-is-blank') !== -1}
                  onClick={() => {
                    const allRuleKeys = doc.rules.map(rule => rule.key)
                    setAsPending(doc.documentType, ruleKeysNotToSend, allRuleKeys, ns, () => goTo(history, nextPage), setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow, history)
                  }}
                >
                  Pendenciar
                </FzButton>

                <FzButton
                  customStyle={{ width: '100%' }}
                  tip={"Rejeitar este documento, e finalizar a avaliação."}
                  disabled={doc.pendedRules.indexOf('doc-is-blank') !== -1}
                  className={"mt-3"}
                  fzStyle={"alert"}
                  onClick={() => { setShowRejectDocModal(true) }}
                >
                  Rejeitar
                </FzButton>
              </div>
              <div style={{ minWidth: "calc(100%/2)", padding: '4px' }}>
                <FzButton
                  id={`${doc.documentType}-upload`}
                  disabled={doc.documentType.indexOf('boa-vista') >= 0}
                  customStyle={{
                    borderColor: '#5923DD',
                    width: '100%',
                    color: '#fff',
                    background: '#5923DD'
                  }}
                  fzStyle={""}
                  onClick={() => {
                    console.log("upload")
                    setUploadModal(true)
                  }}
                >
                  Upload
                </FzButton>

                {
                  doc.finanzeroGenerated && (
                    <FzModal
                      showButtonType='button'
                      showButtonText='Inserir novo doc'
                      headerTitle='Selecione abaixo qual (is) espaço (s) para documentos deseja inserir:'
                      handleSuccessButton={handleInsertDocuments}
                      cancelText='Fechar'
                      successText='Inserir'
                      fzStyle='primary'
                      buttonDisabled={optionsOfDocumentsToInsert.length === 0}
                      buttonCustomStyle={{
                        borderColor: '#2A6BA3',
                        width: '100%',
                        color: '#fff',
                        background: '#2A6BA3',
                        marginTop: '1rem'
                      }}
                    >
                      <div>
                        <FzMultiSelect
                          value={documentsToInsertValue}
                          customClass="col-md-12"
                          direction="column"
                          options={optionsOfDocumentsToInsert}
                          onChange={handleDocumentsToInsert}
                        ></FzMultiSelect>
                      </div>
                    </FzModal>
                  )}
              </div>
            </div>
            <Paginator
              root="/screening/"
              className={"mt-4"}
              previous={previousPage}
              next={nextPage}
              previousDisabled={currDocumentIndex === 0}
              nextDisable={doc.state === "not-initialized"}
            />
          </div>
        </div>
        {doc.documentType === 'selfie' && (
          <div style={{ display: 'flex', width: '100%', overflow: 'hidden', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: '10px', padding: '10px' }}>
            <div className="mt-3">
              <img
                className="mr-3"
                alt={doc.documentType}
                style={{ width: "49%" }}
                src={getFormalizationDocumentUrl(applicationId, bidId, 'rg-front', 'png', 'rg-front')}
                onClick={() => {
                  handleClickImage(setShowFullScreen, applicationId, bidId, 'rg-front', setUrlImage, 'rg-front');
                }}
              />
              <img
                alt={doc.documentType}
                style={{ width: "49%" }}
                src={getFormalizationDocumentUrl(applicationId, bidId, 'rg-back', 'png', 'rg-back')}
                onClick={() => {
                  handleClickImage(setShowFullScreen, applicationId, bidId, 'rg-back', setUrlImage, 'rg-back');
                }}
              />
            </div>
          </div>
        )}
        <Modal
          size="sm"
          centered
          show={uploadModal}
        >
          <Modal.Body>
            <p>O upload de um novo documento substituirá o documento atual. Deseja prosseguir?</p>
          </Modal.Body>
          <Modal.Footer>
            <UploadDocument
              handleUploadFile={handleUploadFileScreening}
            >
              <div
                style={{
                  backgroundColor: '#419641',
                  border: '1px solid transparent',
                  padding: '0.375rem 0.75rem',
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  cursor: 'pointer',
                  borderRadius: '0.25rem',
                  backgroundImage: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)',
                  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0)",
                  backgroundRepeat: "repeat-x",
                  borderColor: "#3e8f3e",
                  color: '#fff',
                  transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                }}
                role="button"
              >
                Sim
              </div>
            </UploadDocument>
            <FzButton
              fzStyle='alert'
              onClick={() => setUploadModal(false)}
            >
              Não
            </FzButton>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          centered
          show={showRejectDocModal}
        >
          <Modal.Header>
            <Modal.Title>
              Rejeitar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>A avaliação dos documentos será rejeitada e finalizada. Selecione o motivo da rejeição: </p>
            <FzSelect
              name="Reasons"
              readOnly={false}
              value={null}
              onChange={(value) => setReason(value)}
              options={optionsReasons}
            />
            <br />
            <p><strong>Atenção!</strong> Esta ação não poderá ser desfeita!</p>
          </Modal.Body>
          <Modal.Footer>
            <FzButton
              fzStyle='secondary'
              onClick={() => {
                setShowRejectDocModal(false)
              }}
            >
              Cancelar
            </FzButton>
            <FzButton
              fzStyle={'alert'}
              disabled={reason === '' || reason === "undefined"}
              onClick={() => {
                setRejectApplication(doc.documentType, reason, ns, () => history.push(`/screening/queue`), setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow, history)
              }}
            >
              Confirmar
            </FzButton>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          centered
          show={postponeModalOpen}
          onHide={handlePostponeModalClose}
        >
          <Modal.Header>
            <Modal.Title >
              Desviar para pendência interna ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              A proposta ficará na fila interna por até 1 hora. Após isso, voltará para a fila inicial.
              <br />
              Deseja prosseguir ?
            </div>
          </Modal.Body>
          <Modal.Footer>

            <FzButton
              fzStyle='success'
              onClick={handlePostpone}
            >
              Sim
            </FzButton>

            <FzButton
              fzStyle='alert'
              onClick={handlePostponeModalClose}
            >
              Não
            </FzButton>
          </Modal.Footer>
        </Modal>
      </>
    }

    <FzToast
      fzStyle={colorTost}
      close={handleClose}
      show={show}
      delay={5000}
      headerMessageTost={headerMessageTost}
      bodyMessageTost={bodyMessageTost}
      autoHide={true}
    />

  </>
}

export { IndividualAnalizeContainer }


