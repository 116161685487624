import { getAgentQueues } from '../../../../../api/integrations'
import { FzAlert } from '../../../../fz/form/alert'
import { FzTable } from "../../../../fz/grid/table"
import { Link } from "react-router-dom";
import { agentQueuesSummary } from './queuesTable-meta'

export function QueuesTable (props) {

  const agent = props.agent
  const queues = props.ns.get(props.name)
  if (!queues) {
    getAgentQueues(agent).then((q) => props.ns.set(props.name, q)).catch(() => props.ns.set(props.name, []))

    return (<FzAlert fzStyle="attention">Carregando mensagens do servidor...</FzAlert>);
  }

  return (
    <FzTable
      data={queues}
      metas={agentQueuesSummary}
      ns={props.ns}
      visibleColumns={["key", "queue", "qtty", "oldest", "newest", "running"]}
      customColumnFormatters={{ "queue": (col) => <Link to={props.pathname + "/" + col} >{col}</Link> }}
    />

  )
  
}