import React, { useState, useEffect } from 'react';
import qs from 'qs';

import clone from 'lodash/clone';
import { FzCard } from '../../fz/layout/FzCard';
import { FzTable } from '../../fz/grid/table';
import { getQueuesAdvisor } from '../../../api/crm';
import { Application } from '../../../biz/metadatas/application';
import { getUsers } from '../../../api/users';
import { FzButton } from '../../fz/form';
import { FzSplitLine } from '../../fz/layout';
import { FzSelect } from '../../fz/form/old-simple-input';
import { StatsDetailHeader } from '../../../statistics/stats-components';

interface QueueNowProps {
  ns: any;
  toggleBlocking: (callback?: any) => void;
}

export function QueueNow({ ns, toggleBlocking }: QueueNowProps) {
  const [users, setUsers] = useState([]);
  const [requestState, setRequestState] = useState('idle');
  const [urlChecked, setUrlChecked] = useState(false);
  const [filters, setFilters] = useState({ queue: 'fup', userId: '' });

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString();
    }
  }, [filters]);

  useEffect(() => {
    loadUsers();
    getQueryStringFromUrl();
  }, []);

  async function loadUsers() {
    const usersrResult = await getUsers('advisor');
    const users = usersrResult.map((it) => ({
      code: it.id,
      description: it.email,
    }));
    setUsers(users);
  }

  function getFilters() {
    const query =
      filters.queue + qs.stringify(filters, { addQueryPrefix: true });
    return query;
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true });
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      query;
    history.pushState({ path: newurl }, '', newurl);
    getQueues();
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.queue || params.user) {
      setFilters(params);
    } else {
      setUrlQueryString();
    }
    setUrlChecked(true);
  }

  async function getQueues() {
    const queueName = [
      'missing-signature',
      'docs-refused',
      'docs-incomplete',
      'docs-not-started',
      'has-bids',
      'docs-refused-low-priority',
      'docs-incomplete-low-priority',
      'docs-not-started-low-priority',
      'has-bids-low-priority',
      'followup',
    ];

    try {
      setRequestState('requesting');
      toggleBlocking();
      const queueNowResult = await getQueuesAdvisor('fup', {
        user: filters.userId,
      });
      let resultQueue = queueNowResult;
      resultQueue.forEach((q) => {
        q.queueName = queueName[q.orderPriority];
      });
      ns.set('queues', resultQueue);

      toggleBlocking();
      setRequestState('idle');
    } catch (err) {
      let error: $TsFixMe = err;
      setRequestState('idle');
      console.warn('API ERROR', error);
      toggleBlocking();
    }
  }

  const tableFields = clone(Application.fields);
  tableFields.product.tableProps.filter = undefined;

  tableFields['extraData.FormalizationBranchCode'] = {
    path: 'extraData.Formalization.BranchCode',
    label: 'Status',
    section: 'no-section',
    dataType: 'string',
    inputType: 'text',
    // save
    tableProps: {
      viewsTable: 'application',
      hidden: false,
      shortLabel: 'Branch',
      width: '14ch',
    },

    isMandatory: (application) => false,
  };

  let data = ns.get('queues', []);
  if (!data && requestState === 'requesting') {
    return null;
  }

  return (
    <div>
      <FzCard>
        <FzCard.Header>
          <FzCard.Title>
            <FzSplitLine>
              <FzSplitLine.Left>
                <FzSelect
                  value={filters.userId}
                  options={users}
                  onChange={(value) => {
                    setFilters({ ...filters, userId: value });
                  }}
                />
              </FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzButton fzStyle="regular" onClick={() => getQueues()}>
                  Buscar
                </FzButton>
              </FzSplitLine.Right>
            </FzSplitLine>
            <div className="mt-3">
              <StatsDetailHeader
                title={'Fila Advisor (Fup)'}
                periods={undefined}
                currPeriod={undefined}
                onChange={undefined}
                stats={data}
              />
            </div>
          </FzCard.Title>
        </FzCard.Header>
      </FzCard>
      <FzCard>
        <FzCard.Body>
          <FzTable
            data={data}
            ns={ns}
            pagination
            visibleColumns={[
              'id',
              'product',
              'extraData.FormalizationPartner',
              'extraData.FormalizationBranchCode',
              'created',
              'auction.endDate',
              'extraData.FormalizationCreated',
              'extraData.FormalizationEndDate',
              'extraData.FormState',
              'appliedAmount',
              'auction.bidsWeight',
              'salesLockUntil',
              'callbackTime',
            ]}
            metas={tableFields}
          />
        </FzCard.Body>
      </FzCard>
    </div>
  );
}
