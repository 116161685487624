import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Application } from '../../../biz/metadatas/application'
import { FzCard, FzRow, FzCol } from '../../fz/layout/index'
import { FzForm} from '../../fz/layout/index'
import { FzTable } from '../../fz/grid/table';
import { rejectCustomer } from '../../../api/suppliers';
import { FzButtonGroup } from '../../fz/form';
import { FzIcons } from '../../fz/form/icon';
import { getBiddableCurrentSupplier } from '../../../api/suppliers';
import { FilterProductsSupplierPage } from '../../blocks/supplier/product-filters';
import { getCurrentSupplier } from '../../../api/auth';

class RejectApplicationsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { rejectReason: "" }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.setState({ rejectReason: e.target.value })
  }
  render() {
    var buttons = [
      {
        fzStyle: "alert",
        description: "Rejeitar as " + this.props.selected + " propostas",
        onClick: () => {
          alert("Você rejeitou " + this.props.selected + " propostas")
          this.props.rejectApplications(this.state.rejectReason)
        },
        id: this.props.btn_id + "_a"

      },
      {
        fzStyle: "discrete",
        description: "Cancelar",
        onClick: this.props.cancelReject,
        id: this.props.btn_id + "_c"
      },
    ];

    return (
      <FzCard bsStyle="danger">
        <FzCard.Heading>
          <FzCard.Title>Rejeitar as Propostas Selecionadas</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzRow>
            <FzCol componentClass={FzForm.Label} span={2}>
              <span>Motivo da Rejeição</span>
            </FzCol>
            <FzCol span={3}>
              <FzForm.Control
                as="select"
                name="rejectReasonList"
                placeholder="selecione..."
                value={this.state.rejectReason}
                onChange={this.handleChange}>
                <option value="">Selecione...</option>
                <option value="not-matching|Produto">Produto não interessa</option>
                <option value="not-matching|Perfil do Cliente">Perfil do cliente não é aderente</option>
                <option value="not-matching|Localização">Região do cliente não é atendida</option>
                <option value="credit-score">Rejeitado pela área de crédito</option>
                <option value="other">Outras razões</option>
              </FzForm.Control>
            </FzCol>
          </FzRow>
          <FzRow>&nbsp;</FzRow>
          <FzRow>
            <FzCol span={11}>
              <FzButtonGroup buttons={buttons} disabled={this.state.rejectReason.length === 0} />
              <FzForm.Label>Atenção: Essa ação não pode ser desfeita!</FzForm.Label>
            </FzCol>
          </FzRow>
        </FzCard.Body>
      </FzCard>
    );

  }
}

export class SupplierSentApplications extends Component {

  constructor(props) {
    super(props)
    this.state = { selectedApplications: 0, showRejectDialog: false };
    this.massRejection = 0;

    this.onRowSelect = this.onRowSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.askConfirmRejectApplications = this.askConfirmRejectApplications.bind(this);
    this.cancelRejectApplications = this.cancelRejectApplications.bind(this);
    this.rejectApplications = this.rejectApplications.bind(this);

    this.rejectionCompleted = this.rejectionCompleted.bind(this);
    this.rejectionError = this.rejectionError.bind(this);

    this.handleChange = this.handleChange.bind(this)
    this.state = { checked: "*" }
  }


  handleChange(value) {
    this.setState({ checked: value, product: value }, () => this.getData())
  }

  rejectionCompleted() {
    this.massRejection--;
    if (this.massRejection < 1) {
      this.getData()
    }
  }
  rejectionError(error) {
    this.massRejection--;

    if (this.massRejection < 1) {
      this.getData()
    }
  }

  askConfirmRejectApplications() {
    this.setState({ showRejectDialog: true })
  }

  onRowSelect({ id }, isSelected) {
    const { ns } = this.props

    if (isSelected) {
      ns.push("selected", id);
    } else {
      ns.pull("selected", id);
    }
  }

  onSelectAll(isSelected) {
    const { ns } = this.props
    const applications = ns.get("biddable-supplier")

    ns.unset("selected");
    if (isSelected) {
      for (let app of applications) {
        ns.push("selected", app.id);
      }
    }
  }

  cancelRejectApplications() {
    this.setState({ showRejectDialog: false })
  }


  async rejectApplications(reason) {
    const { ns } = this.props
    const applicationsSelected = ns.get("selected", [])

    this.setState({ showRejectDialog: false })
    this.massRejection = applicationsSelected.length
    const supplier = getCurrentSupplier()
    for (let i = 0; i < applicationsSelected.length; ++i) {
      let id = applicationsSelected[i]
      try {
        await rejectCustomer(id, supplier.internalName, reason)
        this.rejectionCompleted()
      } catch (err) {
        this.rejectionError(err)
      }
    }
    this.props.ns.clear()
  }


  getData() {
    const { ns } = this.props

    let product = this.state.product || this.state.checked
    return getBiddableCurrentSupplier(1, product, null, true,)
      .then((resultData) => {
        ns.set("biddable-supplier", resultData)
      })
      .catch(err => {
        console.error(err)
      })
  }


  componentDidMount() {
    this.getData()
  }


  render() {
    const { ns, applications } = this.props
    const applicationsSelected = this.props.ns.get("selected", [])
    const biddable_supplier = ns.get("biddable-supplier")

    let selectProp
    selectProp = {
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      multiple: true,
      selectedKeys: ns.get("selected", [])
    };

    const idColumn = (cell, row) => {
      return (<Button title={row['id']} bsSize="xsmall" onClick={() => { this.props.history.push("/suppliers/" + row["id"]); }}>
        < FzIcons icon={"pencil"} fzStyle={{ width: "15px", height: "15px" }} /> Ver
      </Button>);
    }

    const rejectButton = <div>
      <Button bsSize="small"
        disabled={applicationsSelected.length <= 0}
        onClick={this.askConfirmRejectApplications}
      >Rejeitar propostas selecionadas</Button>
      <span className="badge">{applicationsSelected.length}</span>
    </div>

    const rejectPanel = <RejectApplicationsPanel
      selected={applicationsSelected.length}
      rejectApplications={this.rejectApplications}
      cancelReject={this.cancelRejectApplications} />

    return (
      <FzCard>
        <FilterProductsSupplierPage checked={this.state.checked} onChange={this.handleChange} />

        <FzCard.Heading>
          <FzCard.Title>Propostas digitadas aguardando</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {this.state.showRejectDialog ? rejectPanel : rejectButton}
          <FzTable
            pagination
            data={applications || biddable_supplier}
            ns={ns}
            metas={Application.fields}
            visibleColumns={["id", "product", "customer", "personalNumber", "city", "appliedAmount", "auction.endDate"]}
            clipboard={false}
            exportDownload={false}
            rowSelection={selectProp}
            customColumnFormatters={{ id: (cell, bid, formatExtraData, idx) => idColumn(cell, bid, formatExtraData, idx) }}
          />
        </FzCard.Body>
      </FzCard>
    );
  }
}
