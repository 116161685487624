import { useEffect, useState } from "react";
import { FzCard, FzCol, FzRow } from "../../layout";
import { FzField } from "../field";
import { FzButton } from "../button";

export function FzCommissionRanges(props) {
  const { readOnly, value, onChange } = props;
  const [fieldsArray, setFieldsArray] = useState([]);

  useEffect(() => {
    setFieldsArray(value ? value : []);
  }, [value]);

  function addNewField() {
    setFieldsArray([...fieldsArray, { from: null, to: null, percent: null }])
  }

  function changeField(index, value, type) {
    fieldsArray[index][type] = parseFloat(value);
    setFieldsArray(fieldsArray);
    onChange(fieldsArray);
  }

  function removeField(index) {
    let newArray = [...fieldsArray]
    newArray.splice(index, 1)
    setFieldsArray(newArray)
    onChange(newArray)
  }

  return (
    <FzCard >
      <div style={{ padding: 15 }}>
        {fieldsArray && fieldsArray.length > 0 && fieldsArray.map((element, indexField) => (
          <FzRow key={indexField}>
            {
              metaFields.map((field, index) => {
                const fieldProps = field;
                return (
                  <FzCol key={index} span={fieldProps.col}>
                    <FzField
                      value={element[field.name]}
                      onChange={(e) => {
                        changeField(indexField, e, field.name)
                      }}
                      readOnly={readOnly}
                      {...fieldProps}
                    />
                  </FzCol>
                )
              })
            }
            <FzCol key={metaFields.length + 1} span={3}>
              <FzButton
                fzStyle="alert"
                disabled={readOnly}
                onClick={() => removeField(indexField)}
                className={'w-100 d-flex align-items-center justify-content-center'}
                customStyle={{ marginLeft: '0px', marginTop: '1.5rem', height: "55%" }}>
                -
              </FzButton>
            </FzCol>
          </FzRow>
        ))}
        <FzButton onClick={addNewField} disabled={readOnly} fzStyle="discrete">
          <i className="fa-solid fa-plus"></i> Adicionar Novo
        </FzButton>
      </div>
    </FzCard>
  )
}

const metaFields = [
  {
    name: "from",
    dataType: "float",
    label: "De:",
    inputType: "currency",
    fzStyle: "small",
    placeHolder: "Ex.: 0",
    col: 3
  },
  {
    name: "to",
    dataType: "float",
    label: "Até:",
    inputType: "currency",
    fzStyle: "small",
    placeHolder: "Ex.: 1000",
    col: 3
  },
  {
    name: "percent",
    dataType: "float",
    label: "Porcentagem:",
    inputType: "text",
    fzStyle: "small",
    placeHolder: "Ex.: 20",
    col: 3,
    inputProps: { percent: true }
  },
];