import { Meta } from '../../types/metas'



export const RulesCustom: Meta = {
  "key": {
    "path": "key",
    "label": "Key",
    "nameExportCsv": "key",
    "dataType": "string",
    "tableProps": {
      "filter": {
        type: "TextFilter",
        delay: 500
      },
      "width": "10ch",
      "dataSort": false,
      "dataAlign": "center",
      "isKey": true,
      "editable": {
        type: "text",
        validatorName: 'fzTableEditTextValidator'
      }
    },
    "isMandatory": () => false
  },
  "active": {
    "path": "active",
    "label": "Ativo",
    "nameExportCsv": "active",
    "dataType": "boolean",
    "tableProps": {
      "width": "6ch",
      "dataSort": true,
      "dataAlign": "center",
      "isKey": false,
      "columnFormat": "booleanColumn",
      "filter": { type: 'SelectFilter', options: "filters.boolean" },
      "editable": {
        type: "select",
        options: [
          { description: "Sim", code: true },
          { description: "Não", code: false }
        ],
        validatorName: 'fzTableEditSelectValidator'
      }
    },
    "isMandatory": () => false
  },
  "description": {
    "path": "description",
    "label": "Descrição",
    "nameExportCsv": "description",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "isKey": false,
      "dataAlign": "center",
      "filter": {
        type: "TextFilter",
        delay: 500
      },
      "editable": {
        type: "textarea",
        validatorName: 'fzTableEditTextValidator'
      }
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Tipo de documento",
    "nameExportCsv": "documentType",
    "dataType": "string",
    "domainName": "formalization-documents",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "isKey": false,
      "dataAlign": "center",
      "filter": { type: "SelectDomain" },
      "editable": {
        type: "select",
        validatorName: 'fzTableEditSelectValidator'
      }
    },
    "isMandatory": () => false
  },
  "documentSubType": {
    "path": "documentSubType",
    "label": "Subtipo de documento",
    "nameExportCsv": "documentSubType",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "isKey": false,
      "dataAlign": "center",
      "filter": {
        type: "TextFilter",
        delay: 500
      },
      "editable": {
        type: "textarea",
      }
    },
    "isMandatory": () => false
  },
  "supplierInternalName": {
    "path": "supplierInternalName",
    "label": "Parceiro",
    "nameExportCsv": "supplierInternalName",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "isKey": false,
      "dataAlign": "center",
      "filter": { 
        type: 'SelectFilter', 
        options: "buildSupplierFilters" 
      },
      "editable": {
        type: "select",
        options: []
      }
    },
    "isMandatory": () => false
  },
  "type": {
    "path": "type",
    "label": "Tipo de regra",
    "nameExportCsv": "type",
    "dataType": "string",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "isKey": false,
      "dataAlign": "center",
      "filter": {
        type: "SelectFilter",
        options: "filterRulesDocumentType",
      },
     "editable": {
        "type": "select",
        "options": [
          { description: "Pendenciado", code: "pend" },
          { description: "Rejeitado", code: "reject" }
        ],
        "validatorName": 'fzTableEditSelectValidator'
      }
    },
    "isMandatory": () => false
  },
  "customerDescription": {
    "path": "customerDescription",
    "label": "Descrição cliente",
    "nameExportCsv": "customerDescription",
    "dataType": "string",
    "domainName": "formalization-documents",
    "tableProps": {
      "width": "10ch",
      "dataSort": true,
      "isKey": false,
      "dataAlign": "center",
      "filter": {
        type: "TextFilter",
        delay: 500
      },
      "editable": {
        type: "textarea",
        validatorName: 'fzTableEditTextValidator'
      }
    },
    "isMandatory": () => false
  },
}
