import React, { Component } from 'react';
import {
  Form, FormGroup, Modal, Button
} from 'react-bootstrap';
import { FzCol, FzForm } from '../ui/fz/layout/index';
import { FzAlert } from '../ui/fz/form/alert';
import { FzButton } from '../ui/fz/form/button.js';

class CloseApplications extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      closedReason: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveButton = this.saveButton.bind(this);

  }

  onKeyPressEvent(event) {
    if (event.keyCode === 27) {
      this.props.closeButton();
    }

  }
  componentDidMount() {
  }

  handleChange(e) {
    this.setState({ closedReason: e.target.value })
  }

  saveButton() {
    this.props.saveButton(this.state.closedReason);
  }
  getValidationState() {
    return 'success';
  }


  render() {
    return (
      <Modal style={{ marginTop: "50px" }} bsSize="large" show={this.props.show} onHide={this.props.closeButton} dialogClassName="modal-form" keyboard={true} >
        <Modal.Body>
          <Form horizontal>
            <FormGroup>
              <FzCol componentClass={FzForm.Label} span={10}>
                Razão do fechamento
              </FzCol>
              <FzCol span={10}>
                <FzForm.Control
                  as="select"
                  name="closedReasonList"
                  placeholder="Selecione..."
                  value={this.state.closedReason}
                  onChange={this.handleChange}>
                  <option value="">Selecione...</option>
                  <option value="duplicated">Proposta duplicada</option>
                  <option value="rejected">Proposta rejeitada por todos parceiros</option>
                  <option value="customer-rejected">Proposta rejeitada pelo cliente</option>
                  <option value="not-matching">Fora dos filtros</option>
                </FzForm.Control>
              </FzCol>
              <FzCol componentClass={FzForm.Label} span={3}>
                ... ou ...
              </FzCol>
              <FzCol span={10}>
                <FzForm.Control
                  type="text"
                  ref="closedReason"
                  name="closedReason"
                  value={this.state.closedReason}
                  placeholder="Descreva a razão do fechamento da proposta"
                  onChange={this.handleChange}
                />

              </FzCol>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          Atenção: essa operação irá fechar <strong>{this.props.applicationsToClose.length}</strong> proposta(s).
          <div>
            <FzButton
              fzStyle="regular"
              onClick={this.props.closeButton}
            >
              Cancelar
            </FzButton>
            <FzButton
              fzStyle="alert"
              onClick={this.saveButton}
              disabled={this.state.closedReason.length === 0}
            >
              Confirmar Fechamento
            </FzButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

class ExcludeCustomers extends Component {
  constructor(...args) {
    super(...args);
    this.state = { hasConfirmed: false };
    this.confirmButton = this.confirmButton.bind(this);
    this.closeButton = this.closeButton.bind(this);
    this.successCb = this.successCb.bind(this);
    this.errorCb = this.errorCb.bind(this);

  }

  onKeyPressEvent(event) {
    if (event.keyCode === 27) {
      this.closeButton();
    }
  }

  successCb(data) {
    this.setState({ successData: data, hasConfirmed: true })
  }
  errorCb(err) {
    this.setState({ errorMessage: "API Error: " + err, hasConfirmed: true })
  }

  confirmButton() {
    this.props.saveButton(this.successCb, this.errorCb);
  }

  closeButton() {
    this.props.closeButton();
    this.setState({ errorMessage: null, successData: null, hasConfirmed: false })
  }

  render() {
    let errorMessage
    if (this.state.errorMessage) {
      errorMessage = <FzAlert fzStyle="alert"> {this.state.errorMessage} </FzAlert>
    }
    let successMessage
    if (this.state.successData) {
      let message = ""
      if (this.state.successData.length > 0) {
        message = `${this.state.successData.length} foram excluídos junto com suas applications e campanhas`
      } else {
        if (this.state.successData.applicationsCount > 1) {
          message += this.state.successData.applicationsCount + " propostas, "
        } else if (this.state.successData.applicationsCount === 1) {
          message += this.state.successData.applicationsCount + " proposta, "
        }
        if (this.state.successData.campaignsCount > 1) {
          message += this.state.successData.campaignsCount + " campanhas, "
        } else if (this.state.successData.campaignsCount === 1) {
          message += this.state.successData.campaignsCount + " campanha, "
        }
        if (this.state.successData.userId) {
          message += " e 1 usuário foram excludidos"
        } else {
          message += " e 0 usuário foram excludidos"
        }
      }

      successMessage = <FzAlert fzStyle="attention"> {message}</FzAlert>
    }
    let buttons = (<div>
      <FzButton
        fzStyle="regular"
        onClick={this.closeButton}
      >
        Cancelar
      </FzButton >
      <FzButton
        fzStyle="alert"
        onClick={this.confirmButton}
      >
        Confirmar Exclusão
      </FzButton >
    </div>)
    if (this.state.hasConfirmed) {
      buttons = <Button onClick={this.closeButton}>Fechar</Button>
    }
    return (
      <Modal style={{ marginTop: "50px" }} bsSize="large" show={this.props.show} onHide={this.closeButton} dialogClassName="modal-form" keyboard={true} >
        <Modal.Body>
          <FzAlert fzStyle="attention">
            <strong>Atenção:</strong> essa operação irá tentar excluir <strong>{this.props.usersToExclude.length} proposta(s)</strong> e a(s) <strong>campanha(s)</strong> relacionadas.
          </FzAlert>
          {errorMessage}
          {successMessage}
        </Modal.Body>
        <Modal.Footer>
          {buttons}
        </Modal.Footer>
      </Modal>
    );
  }
}

export { CloseApplications, ExcludeCustomers };