import { Modal } from 'react-bootstrap';
import { FzButton } from '../../../ui/fz/form';

const ModalQueueDelete = ({ deleteModalOpen, handleDeleteModalClose, deleteQ, isLoading }) => {
  return (
    <div style={{padding: "20px"}}>
        <Modal size="sm" centered show={deleteModalOpen} onHide={handleDeleteModalClose}>
          <Modal.Body>
            <div>
              Tem certeza que deseja excluir a fila? 
              <br/>
              A ação não pode ser desfeita,
              e os tickets existentes serão
              deletados.
              <br/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{"alignContent":"center","textAlign":"center","margin":"auto"}}>
              <FzButton 
                fzStyle='discrete' 
                onClick={handleDeleteModalClose}
                disabled={isLoading}  
              >
                Cancelar
              </FzButton>
            </div>
            <div style={{"margin":"auto"}}>
              <FzButton 
                fzStyle='alert' 
                onClick={()=>{deleteQ("delete")}}
                disabled={isLoading}  
              >
                Excluir
              </FzButton>
            </div>
          </Modal.Footer>
        </Modal>

    </div>
  )
}

export { ModalQueueDelete }