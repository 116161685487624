import { FzCard } from '../../ui/fz/layout/FzCard';
import { FzTableEdit } from '../../ui/fz/grid/fz-table-edit/FzTableEdit';
import { FzToast } from '../../ui/fz/form/notification/toast';

export const BlocklistReportContainers = ({
  data,
  ns,
  handleUpdateBlocklist,
  handleInsert,
  handleUploadFile,
  insertModalBody,
  meta,
  toast,
}) => {
  return (
    <>
      <FzCard.Body>
        <FzTableEdit
          data={data}
          metas={meta}
          ns={ns}
          context="BlocklistReport"
          cellEditConfig={{
            onAfterSaveCell: handleUpdateBlocklist,
            onBeforeSaveCell: () => null,
          }}
          selectColumns={true}
          onInsertRow={handleInsert}
          importCsv={{ onUploadFile: handleUploadFile }}
          titleModal="Novo Cadastro"
          search={false}
          insertModalBody={insertModalBody}
        />
      </FzCard.Body>

      <FzToast {...toast} />
    </>
  );
};
