import React from 'react'
import { _getExtraData } from "../../../components/table-standard";
import FieldConnected from "../../../ui/components/form/field-connected";
import { FzButton } from "../../../ui/fz/form";
import { FzIcons } from "../../../ui/fz/form/icon";
import { FzTable } from "../../../ui/fz/grid/table";
import { FzCard } from "../../../ui/fz/layout/FzCard";
import { analyticsFilter, customerDeletedReportsAnalytics } from "./metadata";
import { AnalyticsCustomerDeletedReportProps } from "./types";
import { Link } from 'react-router-dom';
import { getDomain } from '../../../scripts/utils/cache-helpers';

export function AnalyticsCustomerDeletedReport({
  data,
  ns,
  filters,
  handleSearchReport,
}: AnalyticsCustomerDeletedReportProps) {

  function idColumn(cell, row, formatExtraData, idx, ns) {
    let applicationId = cell;
    let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
    url += applicationId;
    let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a>
    ) : null;
    return (
      <div>
        {newTab}&nbsp;
        <Link to={url} title={cell}>
          {'...' + cell.slice(-6)}
        </Link>
      </div>
    );
  }

  function deletedReasonDomainNormalized(cell, formatExtraData, ns) {
    const domain = getDomain(ns, formatExtraData, cell);
    if (domain && typeof domain === "string") {
       return domain
    }
    return 'Não informado'
  }

  return (
    <FzCard>
      <FzCard.Header></FzCard.Header>
      <FzCard.Body>
        <>
          <div className={"fz-panel"}>
            <div className={"fz-panel-border-padding"} style={{ display: "block", borderBottom: "3px solid #dedede" }}>
              <div style={{ display: "flex" }}>
                <FieldConnected meta={analyticsFilter.createdFrom} data={filters} ns={ns} />
                <FieldConnected meta={analyticsFilter.createdTo} data={filters} ns={ns} />
              </div>
            </div>
            <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>
              <FieldConnected meta={analyticsFilter.personalNumber} data={filters} ns={ns} />
            </div>
            <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>
              <FieldConnected meta={analyticsFilter.email} data={filters} ns={ns} />
            </div>
            <div className={"fz-panel-border-padding d-flex"} style={{ display: "block", border: "none", borderRight: "3px solid #dedede", borderBottom: "3px solid #dedede" }}>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ margin: "15px 0px 0 0" }}>
                  <FzButton
                    fzStyle="discrete"
                    onClick={() => {
                      const createdFrom = ns.getChanged("createdFrom")
                      const createdTo = ns.getChanged("createdTo")
                      const email = ns.getChanged("email")
                      const personalNumber = ns.getChanged("personalNumber")

                      handleSearchReport({
                        createdFrom,
                        createdTo,
                        email,
                        personalNumber
                      })
                    }}
                    className={"btn btn-success"}
                  >
                    Buscar
                  </FzButton>
                </div>
              </div>
            </div>
          </div>
          <FzCard.Body>
            <FzTable
              ns={ns}
              metas={customerDeletedReportsAnalytics}
              data={data.statistics.length > 0 ? data.statistics: []}
              exportDownload={false}
              clipboard={false}
              customColumnFormatters={{
                applicationId: (cell, row, formatExtraData, idx, ns) => idColumn(cell, row, formatExtraData, idx, ns),
                deletedReason: (cell, row, formatExtraData, idx, ns) => deletedReasonDomainNormalized(cell, formatExtraData, ns)
              }}
            />
          </FzCard.Body>
        </>
      </FzCard.Body>
    </FzCard>
  )
}

