import { Modal } from 'react-bootstrap';
import { FzButton, FzCheckbox } from '../../../ui/fz/form';


const ModalQueuePause = ({ postponeModalOpen, handlePostponeModalClose, checked, handleChangeCheckbox, pauseQ, isLoading }) => {
  return (
    <div style={{padding: "20px"}}>
      <Modal size="sm" centered show={postponeModalOpen} onHide={handlePostponeModalClose}>
        <Modal.Body>
          <div>
            Tem certeza que deseja parar a fila? 
            <br/>
            Nenhum ticket será gerado nessa fila,
            mas os já gerados terão de ser 
            <br/>
            atendidos.
            <br/>
            <br/>
            <FzCheckbox checked={checked} inline={true} onChange={handleChangeCheckbox}>Limpar os tickets da fila</FzCheckbox>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{"alignContent":"center","textAlign":"center","margin":"auto"}}>
            <FzButton 
              fzStyle='discrete' 
              onClick={handlePostponeModalClose}
              disabled={isLoading}  
            >
              Cancelar
            </FzButton>
          </div>
          <div style={{"margin":"auto"}}>
            <FzButton 
              fzStyle='attention' 
              onClick={()=>{ pauseQ("pause") }}
              disabled={isLoading}  
            >
              Pausar
            </FzButton>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { ModalQueuePause }