import React, { useState } from 'react'
import { get as getLodash } from 'lodash'

import { FzRow, FzCol } from '../../../ui/fz/layout/'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { StatsDetailHeader } from '../../../statistics/stats-components'
import { HermesQueueSummaryResponse } from '../../../biz/metadatas/hermesSummariesTickets'
import { buildPeriodFilters } from '../../../components/table-standard'
import { FzToast } from '../../../ui/fz/form/notification/toast'

const Summaries = ({
  data,
  ns,
  handleTostClose,
  showTost,
}) => {
  const [periodSelected, setPeriodSelectedt] = useState('')
  const [tableData, setTableData] = useState(data)

  const handleChangePeriod = (period) => {
    setPeriodSelectedt(period)   
    const statistics = getLodash(data, ['statistics'], [])
    const findPeriod = item => (item.period === period || item._id.period === period)
    setTableData({ statistics: statistics.filter(findPeriod) })
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <FzCard>
        <FzCard.Title>
          <FzCard.Header className='w-100'>
            <FzRow>
              <FzCol span={12}>
                <StatsDetailHeader
                  title="Capacity Review (Tickets)  "
                  stats={data}
                  periods={buildPeriodFilters(getLodash(data, ['statistics'], []))}
                  currPeriod={periodSelected}
                  onChange={handleChangePeriod}
                />
              </FzCol>
            </FzRow>
          </FzCard.Header>
        </FzCard.Title>
        <FzCard.Body>
            <div className={'mt-3'}>
              <FzTable
                data={getLodash(tableData, ['statistics'], [])}
                metas={HermesQueueSummaryResponse}
                ns={ns}
                exportDownload={false}
                clipboard={false}
                trClassFunction={() => 'fz-row-anti-flash-white'}
                customColumnFormatters={{
                  description: (cell) => (<strong>{cell}</strong>)
                }}
              />
          </div>
        </FzCard.Body>
      </FzCard>
      <FzToast
        fzStyle="danger"
        close={handleTostClose}
        show={showTost}
        delay={5000}
        headerMessageTost="Error"
        bodyMessageTost="Erro interno, tente novamente!"
        autoHide={true}
      />
    </div>
  )
}

export { Summaries }
