
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

type FzOption =

  {
    title: {
      text: string
    },

    yAxis: {
      title: {
        text: string
      }
    },

    xAxis: {
      allowDecimals: false,
      type: string,
      tickInterval: boolean,
      labels: {
        rotation: number
      },
      accessibility: {
        rangeDescription: string
      }
    },

    legend: {
      layout: string,
      align: string,
      verticalAlign: string
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: boolean
        },
        pointStart: number,
        pointIntervalUnit: string,
        pointInterval: number
      }
    },

    series: [],

    responsive: {
      rules: [{
        condition: {
          maxWidth: number
        },
        chartOptions: {
          legend: {
            layout: string,
            align: string,
            verticalAlign: string
          }
        }
      }]
    }
  }



const metaLine = {

  options: {
    title: {
      text: null,
    },

    yAxis: {
      title: {
        text: 'Number of Applications'
      }
    },

    xAxis: {
      allowDecimals: false,
      type: 'datetime',
      tickInterval: null,
      labels: {
        rotation: -45
      },
      accessibility: {
        rangeDescription: null
      }
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        pointStart: null,
        pointIntervalUnit: null,
        pointInterval: null
      }
    },

    series: [],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  }
}

type FzFunnelProps = {
  options: FzOption,
}


class FzLine extends React.Component<FzFunnelProps, any> {

  render() {
    const { options } = this.props

    return (
      <div style={{width: "100%"}}>
        {options ? (<HighchartsReact
          highcharts={Highcharts}
        options={options} />) : null}
      </div>

    )
  }

}
export { metaLine, FzLine }