import React, { useState, useEffect } from 'react';
import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';

import { panelOverwriteDocuments, overwriteDocumentAnalyticsMeta } from '../../../../biz/metadatas/stats/screening/overwrite-documents';
import { FzCard } from '../../../fz/layout/FzCard';
import { FzButton } from '../../../fz/form/button';
import { FzTable } from '../../../fz/grid/table';
import FieldConnected from '../../../components/form/field-connected';
import { _getExtraData } from '../../../../components/table-standard';
import { FzIcons } from '../../../fz/form/icon';
import { getScreeningDocumentsOverwriteAnalystics } from '../../../../services/api/screening';

function ScreeningDocumentsOverwriteAnalytics(props) {

  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters]: any = useState({
    createdAfter: null,
    createdBefore: null,
  })
  const { ns } = props

  useEffect(() => {
    if (urlChecked) {
      getStatistics()
    }
  }, [filters])

  useEffect(() => {
    ns.startEditing({
      createdAfter: moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0 }).local(),
      createdBefore: moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).local()
    })
    getQueryStringFromUrl()
  }, [])

  function setUrlQueryString() {
    if (ns.getChanged()) {
      var lastChanged = ns.getChanged()
      setFilters(lastChanged)

      var url = {
        createdAfter: lastChanged.createdAfter instanceof moment ? lastChanged.createdAfter._d : "",
        createdBefore: lastChanged.createdBefore instanceof moment ? lastChanged.createdBefore._d : "",
      }

      const query = qs.stringify(url, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;

      history.pushState({ path: newurl }, '', newurl);
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());

    if (params.createdAfter || params.createdBefore || params.supplierInternalName) {

      if (params.createdAfter instanceof moment) {
        params.createdAfter = params.createdAfter._d
      }
      if (params.createdBefore instanceof moment) {
        params.createdBefore = params.createdBefore._d
      }

      ns.startEditing(params)
      setFilters(params)
    } else {
      var dateI = moment().utc().set({ day: -7, hour: 0, minute: 0, second: 0, millisecond: 0, }).local()
      var dateB = moment().utc().set({ hour: 23, minute: 59, second: 59, millisecond: 0, }).local()
      ns.startEditing({
        createdAfter: dateI,
        createdBefore: dateB
      })
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getStatistics() {
    setRequestState("requesting")

    const limit = 100
    let skip = 0
    let keepLoading = true
    
    if (filters) {
      ns.saveChange(filters)
    }

    while (keepLoading) {
      try {
        let analyticsResult = await getScreeningDocumentsOverwriteAnalystics(ns.getChanged(), limit, skip)
        if (Array.isArray(analyticsResult.data) && analyticsResult.data.length <= limit) {
          skip += limit
          ns.set("screeningAnalytics", analyticsResult.data)
          setRequestState("idle")
          if (analyticsResult.data.length !== limit) {
            keepLoading = false
          }
        } else {
          keepLoading = false
        }
      } catch (err) {
        let error: $TsFixMe = err
        console.log(error.response)
        keepLoading = false
      }
    }
  }

  function idColumn(cell, row, formatExtraData, idx, ns) {
    let applicationId = cell;
    let url = _getExtraData(formatExtraData, 'baseRoute', true, '/applications/');
    url += applicationId;
    let newTab = _getExtraData(formatExtraData, 'newTab', false, true) ? (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <FzIcons icon={"window-restore"} fzStyle={{ width: "15px", height: "15px" }} />
      </a>
    ) : null;
    return (
      <div>
        {newTab}&nbsp;
        <Link to={url} title={cell}>
          {'...' + cell.slice(-6)}
        </Link>
      </div>
    );
  }

  const analyticsDataResult = ns.get('screeningAnalytics', [])
  let data = ns.getChanged()

  return (
    <FzCard>
      <FzCard>
        <div className={"fz-panel"} >
          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={data} meta={panelOverwriteDocuments["createdAfter"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"}>
            <FieldConnected data={data} meta={panelOverwriteDocuments["createdBefore"]} ns={ns} />
          </div>

          <div className={"fz-panel-border-padding"}>
            <FzButton
              onClick={
                setUrlQueryString
              }
              tip={"buscar"}
              disabled={false}
              className={null}
              block={true}
              fzStyle={"regular"}
            >
              Buscar
            </FzButton>
          </div>
        </div>
      </FzCard>

      <FzCard.Body>
        <FzTable
          data={analyticsDataResult}
          metas={overwriteDocumentAnalyticsMeta}
          context={"screening-documents-overwrite"}
          ns={ns}
          pagination
          customColumnFormatters={{
            applicationId: (cell, row, formatExtraData, idx, ns) => idColumn(cell, row, formatExtraData, idx, ns),
          }}
        />
      </FzCard.Body>
    </FzCard >
  )
}

export { ScreeningDocumentsOverwriteAnalytics }