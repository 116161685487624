import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReprocessing } from '../../../api/integrations';
import { ReprocessingViewContainer } from '../../../Containers/Bifrost/ReprocessingViewContainer';
import { FzCard } from '../../fz/layout';

export function ReprocessingView({ ns }) {
  const { id } = useParams<{ id: string }>();
  const [cardTitle, setCardTitle] = useState<string>()

  useEffect(() => {
    getReprocessingData()
  }, [])

  async function getReprocessingData() {
    const { data } = await getReprocessing(id)
    ns.set("reprocessing", data)
    setCardTitle(data.cardId)
  }

  return (
    <>
      <FzCard.Body fzStyle={{ padding: '1.5rem' }}>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title>Reprocessamento {cardTitle}</FzCard.Title>
          </FzCard.Heading>
        </FzCard>
        <ReprocessingViewContainer
          ns={ns}
        />
      </FzCard.Body>
    </>
  )
}