import React, { useEffect, useState } from "react";
import { finishAttendance, addComment } from "../../../api/applications";
import { setCustomerDataToNs } from "../../../biz/user";
import { FzButton, FzField } from "../../../ui/fz/form";
import { FzBadge, FzCard, FzSplitLine } from "../../../ui/fz/layout";
import { FormattedDate, FormattedTime } from 'react-intl';
import api from '../../../api/client-api';
import { postInboundTicket } from "../../../services/api/hermes";


function compareDates(a, b) {
  const d1 = a.created;
  const d2 = b.created;

  let comparison = 0;
  if (d1 > d2) {
    comparison = -1;
  } else if (d1 < d2) {
    comparison = 1;
  }
  return comparison;
}

const ExistingComments = ({ ns, app, ...props }) => {
  let actualComents : $TsFixMe = [];
  app.comments.sort(compareDates);

  const tooltip = (type) => <span>Foi enviado um <b>{type}</b> com esse comentário para o cliente</span>;

  for (let i = 0; i < app.comments.length; ++i) {
    actualComents.push(
      <FzField
        key={i}
        readOnly
        name={"comment" + i}
        dataType="string"
        inputType="textarea"
        value={app.comments[i].comment}
        label={(<FzSplitLine>
          <FzSplitLine.Left>
            {app.comments[i].userName} em <FormattedDate value={app.comments[i].created} /> <FormattedTime
              value={app.comments[i].created} />
          </FzSplitLine.Left>
          <FzSplitLine.Right>
            {app.comments[i].notify && <FzBadge fzStyle="discrete" glyph="envelope" tip={tooltip("Email")} />}
            {app.comments[i].notifySMS && <FzBadge fzStyle="discrete" glyph="comment" tip={tooltip("SMS")} />}
          </FzSplitLine.Right>
        </FzSplitLine>)}
        data={app}
        {...props}
      />
    );

  }
  if (actualComents.length > 0) {
    return (
      <FzCard fzStyle="discrete" nested>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title>Histórico</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {actualComents}
        </FzCard.Body>
      </FzCard>
    );
  } else {
    return null;
  }
}

const AttendanceOutcome: $TsFixMe = (props) => {
  const [requestBusy, setRequestBusy] = useState(false)
  const [comment, setComment]: $TsFixMe = useState("")
  const [notify, setNotify]: $TsFixMe = useState("")

  const [notifySMS, setNotifySMS]: $TsFixMe = useState("")
  const [notifyWhats, setNotifyWhats]: $TsFixMe = useState("")
  const [notifyPhone, setNotifyPhone]: $TsFixMe = useState("")
  const [notifyChat, setNotifyChat]: $TsFixMe = useState("")
  const [notifyEmail, setNotifyEmail]: $TsFixMe = useState("")
  const [relationsList, setRelationsList]: $TsFixMe = useState([
    {code: 'unreleated-to-offer', description: 'Não Relacionado a propostas'},
    {code: 'no-offer', description: 'Cliente sem propostas'},
    {code: 'lead', description: 'Cliente em cadastro'}
  ])
  const [relation, setRelation]: $TsFixMe = useState(null)
  const [bidsList, setBidsList]: $TsFixMe = useState()

  const [selectedSupplier, setSelectedSupplier]: $TsFixMe = useState()
  const [selectedBidId, setSelectedBidId]: $TsFixMe = useState()
  const [selectedBranch, setSelectedBranch]: $TsFixMe = useState()

  const [exitAttendance, setExitAttendance]: $TsFixMe = useState()
  const [treatmentOptions, setTreatmentOptions]: $TsFixMe = useState()

  useEffect(() => {
    loadDomains()
    includeBidsInRelationsList()
  }, [])

  function onTextChange(newText) {
    setComment(newText)
  }

  function includeBidsInRelationsList() {
    const app = props.app
    const bidsList: Object[] = []
    let bidIdsDictionary = {}
    if(app.auction && app.auction.bids && app.auction.bids.length > 0){
      app.auction.bids.map((bid) => {
        const bidOption = {code: bid.supplier.internalName, description: bid.supplier.name, id: bid.id}
        bidIdsDictionary[bid.supplier.internalName] = {id: bid.id, branch: bid.supplier.branchCode}
        bidsList.push(bidOption)
      })
      setRelationsList([...relationsList, ...bidsList])
      setBidsList(bidIdsDictionary)
    }
  }

  function onNotifyChange(newList) {
    setNotifySMS(newList.indexOf("sms") >= 0 ? " * Notificado por SMS." : "" )
    setNotify(newList.indexOf("email") >= 0 ? " * Notificado por E-mail." : "" )
  }

  function onNotifyFurtherChange(newList) {
    setNotifyWhats(newList.indexOf("whats") >= 0 ? " * Notificado por Whatsapp." : "")
    setNotifyPhone(newList.indexOf("phone") >= 0 ? " * Notificado por Telefone." : "")
    setNotifyChat(newList.indexOf("chat") >= 0 ? " * Notificado via Chat." : "")
    setNotifyEmail(newList.indexOf("email") >= 0 ? " * Notificado via e-mail." : "")
  }

  function onExitAttendance(newValue) {
    setExitAttendance(newValue)
  }

  function onRelationChange(newValue) {
    if(bidsList && bidsList[newValue]){
      setSelectedSupplier(newValue)
      setSelectedBidId(bidsList[newValue].id)
      setSelectedBranch(bidsList[newValue].branch)
    }else {
      setSelectedSupplier(null)
      setSelectedBidId(null)
      setSelectedBranch(null)
    }
    setRelation(newValue)
  }

  function getNotificationList() {
    let notifications: String[] = []
    if (notify) {
      notifications.push("email")
    }
    if (notifySMS) {
      notifications.push("sms")
    }
    return notifications
  }

  function getFurtherNotificationList() {
    let notify: String[] = []
    if (notifyWhats) {
      notify.push("whats")
    }
    if (notifyPhone) {
      notify.push("phone")
    }
    if (notifyChat) {
      notify.push("chat")
    }
    if (notifyEmail) {
      notify.push("email")
    }
    return notify
  }

  async function loadDomains() {
    const treatments = await api.domains.getDomainValues("attendance-treatments")
    setTreatmentOptions(treatments)

    if (props.readOnly) {
      console.warn("AttendanceOutcome does not work as a read-only component")
    }
  }

  function finishAttendanceOK() {
    finishThisAttendance(true);
  }

  async function createInboundTicket(app, ticketInbound) {
    try{
      const contactIds: String[] = []
      app.comments.map((c) => {
        contactIds.push(c.id)
      })
  
      const ticketPayload: $TsFixMe = {
        applicationId: app.id,
        outcome: exitAttendance,
        relation: relation,
        ...ticketInbound,
      }
  
      if(selectedBidId){
        ticketPayload.supplierInternalName = selectedSupplier
        ticketPayload.bidId = selectedBidId
        ticketPayload.branch = selectedBranch
      }
      const result = await postInboundTicket(ticketPayload)
      if(result){
        console.log('Inbound ticket created')
      }
      return result
    }catch(err){
      console.error(err)
    }
  }

  function finishThisAttendance(success_talk) {
    setRequestBusy(true)
    const cd = props.ns.get("customer");
    if (!cd) {
      return;
    }

    const app = props.app;

    let treatments = props.ns.get("crm.treatments." + app.id) || "";
    const treatmentsArray = props.ns.get("crm.treatmentsArray." + app.id) || [];

    let newComment = comment ? comment : "";
    let treatmentsComment = (
      treatmentOptions.find(item => item.code === exitAttendance).description
      || 'Tratamento não selecionado!'
    )

    if (!success_talk) {
      treatmentsComment += " * Não Consegui Falar";
    }

    treatmentsComment +=
      notify +
      notifySMS +
      notifyWhats +
      notifyChat +
      notifyEmail +
      notifyPhone +
      (treatments ? " * " + treatments : "");
    if (notify.length > 0) {
      treatments += " * Notificado por E-mail";
      treatmentsArray.push('notified-email');
    }
    if (notifySMS.length > 0) {
      treatments += " * Notificado por SMS";
      treatmentsArray.push('notified-sms');
    }
    if (notifyEmail.length > 0) {
      treatments += " * Notificado por email";
      treatmentsArray.push('notified-email');
    }

    if (notifyWhats.length > 0) {
      treatments += " * Notificado por Whatsapp";
      treatmentsArray.push('notified-whatsapp');
    }
    if (notifyPhone.length > 0) {
      treatments += " * Notificado por Telefone";
      treatmentsArray.push('notified-phone');
    }
    if (notifyChat.length > 0) {
      treatments += " * Notificado via Chat";
      treatmentsArray.push('notified-chat');
    }
    if (exitAttendance !== "undefined") {
      treatmentsArray.push(exitAttendance);
    }

    const contactId = cd.contactid;
    let contacts: any = null;
    const appId = app.id;
    if (cd.contacts)
      contacts = cd.contacts.find(function (x) {
        return x.applicationId === appId;
      });
    const product = contacts && contacts.applicationId === app.id ? contacts.product : null;

    const notifyCustomer = notify ? true : false;
    const notifySMSCustomer = notifySMS ? true : false;

    newComment = newComment + ' * ' + treatmentsComment;
    const contactActionsTaken = treatmentsArray.map(act => ({
      "applicationid": appId,
      "product": product,
      "treatment": act
    }))

    finishInboundTicketCreation(app, newComment, contactActionsTaken, treatments)
  }

  async function finishInboundTicketCreation(app, newComment, contactActionsTaken, treatments){
    const notificationsSent = getNotificationList()
    const moreNotifications = getFurtherNotificationList()

    try{
      const contactPayload = {
        applicationId: app.id,
        applicationState: app.applicationState,
        contacts: [
          {
            contactId: app.contactId,
            comment: newComment,
            notificationsSent,
            moreNotifications,
            treatments: contactActionsTaken,
            treatmentsSummary: treatments,
            contactTarget: app.telephoneNumber,
            personalNumber: app.personalNumber,
            product: app.product,
            customerId: app.customerId,
            queue: "hermes"
          }
        ]
      }
  
      const ticketResult = await createInboundTicket(app, contactPayload)
      if(ticketResult){
        if(notificationsSent.length > 0){
          await addComment(app.id, comment, notificationsSent.includes('email'), notificationsSent.includes('sms'))
        }
        props.ns.set("application", app)
        setTimeout(() => {
          setRequestBusy(false)
          alert(newComment)
          props.history.push(props.basePath);
        }, 1000);
      }

    }catch(err){
      console.warn("FinishAttendance ERROR:", err);
    }
  }

  function exitTreatmentOptions() {
    const options: any = [];

    if (treatmentOptions) {
      const treatments = treatmentOptions;
      treatments.forEach(treatment => {

        options.push({
          code: treatment.code,
          description: treatment.description
        })
      });

    }
    return options;
  }
  
  const cd = props.ns.get("customer");

  if (!cd) {
    return null
  }

  const notificationModes = [{ code: "sms", description: "Por SMS" }, { code: "email", description: "Por Email" }]
  const furtherNotificationModes = [
    {
      code: "whats",
      description: "Whatsapp"
    },
    {
      code: "phone",
      description: "Por Telefone"
    },
    {
      code: "chat",
      description: "Via Chat"
    },
    {
      code: "email",
      description: "Via e-mail"
    }
  ]

  return (
    <FzCard fzStyle="discrete" nested>
      <FzCard.Heading fzStyle={{}}>
        <FzCard.Title>Resultado do Atendimento</FzCard.Title>
      </FzCard.Heading>
      <FzCard.Body>
        <FzField
          inputType="textarea"
          dataType="string"
          value={comment}
          name="comment"
          inputProps={{
            showLength: true,
            maxSize: notifySMS ? 140 : 4000,
          }}
          validationMessage="Texto é muito grande para enviar por SMS"
          onChange={onTextChange}
        />
        <FzField
          inputType="select"
          dataType="string"
          label="Relação"
          value={relation}
          name="relation"
          inputProps={{ options: relationsList }}
          validationMessage="Preenchimento obrigatório"
          onChange={onRelationChange}
        />
        <FzField
          inputType="multiselect"
          dataType="array"
          label="Enviar notificações para o cliente"
          value={getNotificationList()}
          name="notify"
          inputProps={{ options: notificationModes }}
          validationMessage="Preenchimento obrigatório"
          onChange={onNotifyChange}
        />
        <FzField
          inputType="multiselect"
          dataType="array"
          label="Demais notificações utilizadas"
          value={getFurtherNotificationList()}
          name="furtherNotify"
          inputProps={{ options: furtherNotificationModes }}
          validationMessage="Preenchimento obrigatório"
          onChange={onNotifyFurtherChange}
        />
        <FzField
          inputType="select"
          dataType="string"
          label="Informe o tratamento efetuado"
          value={exitAttendance}
          name="exitAttendance"
          inputProps={{ options: exitTreatmentOptions() }}
          validationMessage="Preenchimento obrigatório"
          onChange={onExitAttendance}
        />
        <div style={{ display: "flex", gap: "1rem", alignItems: "end" }}>
          <FzButton
            fzStyle="regular"
            disabled={!relation || !exitAttendance || requestBusy}
            onClick={finishAttendanceOK}
            block={true}
          >
            Concluir atendimento
          </FzButton>
        </div>
      </FzCard.Body>
    </FzCard>
  );
}

const CustomerAttendance = (props) => {

  useEffect(()=> {
    setCustomerDataToNs(props.ns.get("application.customerId"), props.ns)
  }, [])
  
  const { onNeedUpdate, history, ns, app, category } = props
  let newComment: $TsFixMe = null
  if (api.isUserSales() && !ns.isEditing()) {
    newComment = (
      <AttendanceOutcome
        ns={ns}
        app={app}
        history={history}
        onApplicationUpdated={onNeedUpdate}
        basePath={props.basePath}
      />
    )
  }

  return (
    <>
      <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title>Comentários</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <React.Fragment>
            {category !== "activeHermes" && (<div> {newComment} </div>)}
            {app.comments && app.comments.length > 0 && <ExistingComments ns={ns} app={app} />}
          </React.Fragment>
        </FzCard.Body>
      </FzCard>
    </>
  )

}


export { CustomerAttendance };

function getLatestRevison(apps, fallback) {
  return apps.sort((a, b) => b.revision - a.revision !== 0 ? b.revision - a.revision : b[fallback].length - a[fallback].length)[0];
}
