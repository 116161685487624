
import { Meta } from "../../../types/metas";

export const analyticsColumns: Meta = {

  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "tableProps": {
      "isKey": true,
      "filter": { type: 'TextFilter', delay: 500 },
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "created": {
    "path": "created",
    "label": "Data da consulta",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "periodWithTimeColumn"
    },
    "isMandatory": () => false
  },
  "documentType": {
    "path": "documentType",
    "label": "Tipo de doc",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
    "isMandatory": () => false
  },
  "match": {
    "path": "match",
    "label": "Match",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "columnFormat": "booleanColumn"
    },
    "isMandatory": () => false
  },
  "isCached": {
    "path": "matchType",
    "label": "Lugar de consulta",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "filter": { type: 'SelectFilter', options: "buildSupplierFilters" },

    },
    "isMandatory": () => false
  },
}

function normalizeDate(value) {
  return typeof (value) === "object" && value !== null
    ? value.toISOString()
    : value
}

export const analyticsFilter: Meta = {
  "createdFrom": {
    "path": "createdFrom",
    "label": "De",
    "inputType": "date",
    "tip": "Inicio do período para pesquisa da data de exclusão da proposta",
    "isReadOnly": () => false,
    "isMandatory": () => true,
    normalize: normalizeDate
  },
  "createdTo": {
    "path": "createdTo",
    "label": "Até",
    "inputType": "date",
    "tip": "Final do período para pesquisa da data de exclusão da proposta (Máximo: 33 dias).",
    "isReadOnly": () => false,
    "isMandatory": () => true,
    normalize: normalizeDate
  },
  "personalNumber": {
    "path": "personalNumber",
    "label": "CPF",
    "dataType": "string",
    "inputType": "masked",
    "inputProps": {
      "mask": "111.111.111-11",
      "removeMask": true
    },
    "isMandatory": () => false,
    "isReadOnly": () => false
  },
  "email": {
    "path": "email",
    "label": "E-mail",
    "dataType": "string",
    "inputType": "text",
    "isMandatory": () => false,
    "inputProps": {},
    "validationMessage": "Email inválido",
    "pattern": /^.{10,128}$/,
    "placeholder": "joao.carlos@exemplo.com",
    "normalize": (value) => { return value !== undefined ? value.toLowerCase() : value },
    "extraValidation": "email",
  },

}

export const customerDeletedReportsAnalytics: Meta = {
  "applicationId": {
    "path": "applicationId",
    "dataType": "string",
    "label": "ID",
    "tableProps": {
      isKey: true,
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
  },
  "applicationCreated": {
    "path": "applicationCreated",
    "dataType": "string",
    "label": "Data de criação da proposta",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      'columnFormat': 'periodWithTimeColumn'
    },
  },
  "applicationUpdated": {
    "path": "applicationUpdated",
    "dataType": "string",
    "label": "Última atualização",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      'columnFormat': 'periodWithTimeColumn'
    },
  },
  "deletedDate": {
    "path": "deletedDate",
    "dataType": "string",
    "label": "Data de exclusão",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      'columnFormat': 'periodWithTimeColumn'
    },
  },
  "productName": {
    "path": "product",
    "dataType": "string",
    "label": "Produto",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
  },
  "amount": {
    "path": "appliedAmount",
    "dataType": "string",
    "label": "Valor solicitado",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
  },
  "deletedReason": {
    "path": "deletedReason",
    "dataType": "string",
    "label": "Motivo de exclusão",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
      "formatExtraData": "deleted-reasons",
    },
  },

  "customerDeletedReason": {
    "path": "customerDeletedReason",
    "dataType": "string",
    "label": "Motivo de exclusão personalizada",
    "tableProps": {
      "width": "9rem",
      "dataSort": true,
      'expandable': false,
    },
  },
}







