import React from 'react';
import { FzRow } from '../../fz/layout/index'
import FieldConnected from '../../components/form/field-connected'
import { FzButtonGroup, FzButton } from '../../fz/form'


interface buttons {
    fzStyle: string;
    description: string;
    onClick: () => void;
    itemDisabled: () => {};
    className: string;
}

interface PresenterParametersSystemProps {
  key: string;
  meta: { path: string };
  data: {};
  ns: any;
  buttons: buttons[];
  readOnly: boolean;
}

export function PresenterParametersSystem({ key, meta, data, ns, buttons, readOnly }: PresenterParametersSystemProps) {
  return (
    <FzRow>
      <div style={{ display: 'flex' }}>
        <FieldConnected key={1} meta={meta} data={data} ns={ns} readOnly={readOnly} />
        <div className="ml-3" style={{ alignSelf: 'flex-end' }}>
          <FzButtonGroup id={key} buttons={buttons} />
        </div>
      </div>
    </FzRow>
  )
}