import React, { Component } from 'react';

import { AggregationSearching } from '../../blocks/aggregations/aggregations-search';
import { FzCard } from '../../fz/layout/index';
import { TableOptions } from '../../../components/table-standard';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import BlockUi from 'react-block-ui';

export class AggregationsConsole extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        suppliersList: [],
        blockStatsConsole: false,
        subItem: "simple"
    };
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
  }

  toggleBlocking(callback = function () { }) {
    this.setState({ blockStatsConsole: !this.state.blockStatsConsole }, callback);
  }

  handlePageSelect = (eventKey) => {
    this.setState({subItem: eventKey})
  }

  render() {
    const { ns, sharedNS, api } = this.props;

    let navbar = <Navbar className="report-navbar" fluid>
      <Nav className="adjusted-nav" variant="tabs" onSelect={this.handlePageSelect} activeKey={this.state.subItem} >
        <Nav.Link eventKey="simple" title="Busca Simples"> Busca Simples </Nav.Link>
        <Nav.Link eventKey="advanced" title="Busca Avançada"> Busca Avançada </Nav.Link>
      </Nav>
    </Navbar>
    
    return (
      <BlockUi tag="div" >
        <FzCard>
          <FzCard.Heading fzStyle={{ paddingBottom: "0", marginBottom: "0" }}>
              <FzCard.Title fzStyle={{ marginBottom: "0" }}>{navbar}</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <AggregationSearching
                  ns={ns}
                  activeKey={this.state.subItem}
                  tableOptions={TableOptions}
                  sharedNS={sharedNS}
                  toggleBlocking={this.toggleBlocking}
            />
          </FzCard.Body>
        </FzCard >
      </BlockUi>
    )
  }

}


