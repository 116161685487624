import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { FzForm } from '../layout';
import { FzButton } from './button';

interface InputCurrencyProps {
  name: string;
  value: number | string;
  onChange: (value: string, dataType: string) => any;
  readOnly: boolean;
  placeHolder: string;
  dataType: string;
}

export function FzCurrency({ name, value, readOnly, placeHolder, onChange, dataType }: InputCurrencyProps) {

  function handleValue(value) {
    onChange(value, dataType);
  }

  return (
    <CurrencyInput
      id={name}
      name={name}
      placeholder={placeHolder}
      value={value}
      allowDecimals={false}
      onValueChange={handleValue}
      className="form-control"
      intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
      disabled={readOnly}
    />
  )
}

interface FzCheckboxProps {
  children: React.ReactNode;
  checked: boolean;
  inline: boolean;
  onChange: (checked: boolean, key: string) => void;
  name?: string;
  isDisabled?: boolean;
  value?: any,
}

export function FzCheckbox({ 
  children, 
  inline, 
  onChange, 
  checked, 
  isDisabled, 
  name,
  value = null,
}: FzCheckboxProps) {
  const props = value ? { value } : {}
  return (
    <FzForm.Check
      isDisabled={isDisabled}
      name={name}
      checked={checked}
      inline={inline}
      {...props}
      onChange={(e, key) => onChange(e.target.checked, key)}>
      <React.Fragment>{children}</React.Fragment>
    </FzForm.Check>
  );
}

export function FzDynamicFieldsList({
  readOnly,
  value,
  onChange,
  inputProps
}) {
  const [fieldsArray, setFieldsArray]: $TsFixMe = useState(value ? value : [])

  function addNewField(){
    setFieldsArray([...fieldsArray, ''])
  }

  function saveChanges() {
    if(!readOnly){
      onChange(fieldsArray)
    }
  }

  function saveOnEnter(event) {
    if (event.keyCode === 13) {
      saveChanges()
    }
  }

  function changeField(index, event) {
    let newArray = [...fieldsArray]
    newArray[index] = event.target.value
    setFieldsArray(newArray)
  }

  function removeField(index) {
    let newArray = [...fieldsArray]
    newArray.splice(index, 1)
    setFieldsArray(newArray)
    onChange(newArray)
  }

  function lastIsNotFilled(array) {
    if(array.length > 0){
     for(let i = 0; i < array.length; i++){
       if(array[i].length === 0) {
         return true
       }
     }
    }
    return false
  }

  const tagFields = fieldsArray.map((it, index) => 
    <React.Fragment key={index}>
      <div style={{display: 'flex'}}>
        <input 
          type='text' 
          readOnly={readOnly} 
          key={index} 
          onBlur={saveChanges} 
          onChange={(e) => changeField(index, e)} 
          onKeyDown={saveOnEnter}
          value={it} 
          className='form-control mb-2' />
        <FzButton 
          fzStyle="alert" 
          disabled={readOnly} 
          onClick={() => removeField(index)} 
          customStyle={{marginLeft: '5px', marginBottom: '0.5rem'}}
        >
          <i className="fa-solid fa-xmark"></i>
        </FzButton>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      <div className='form-group' style={{border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '15px'}}>
      {tagFields}
      <FzButton onClick={addNewField} disabled={readOnly || lastIsNotFilled(fieldsArray)} fzStyle="discrete">
        <i className="fa-solid fa-plus"></i> Adicionar Novo
      </FzButton>
      </div>
    </div>
  )
}
