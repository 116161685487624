import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import '../../fz/style/menu.css';

export class FzMenuLogOut extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      screening: false,
      route_funnel: false,
      route_teste: false,
      route_selected: false,
    };

    this.goToRoute = this.goToRoute.bind(this);
    this.showOptionsMenu = this.showOptionsMenu.bind(this);
  }

  goToRoute(route: string) {
    const { history } = this.props;
    history.push(`/${route}`);
  }

  showOptionsMenu(name) {
    if (this.state.show_options !== name) {
      this.setState({ show_options: name });
    } else if (this.state.show_options === name) {
      this.setState({ show_options: undefined });
    }
  }

  render() {
    return (
      <div>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem
            button
            onClick={() => this.goToRoute('logout')}
            className={clsx('', {
              ['fz-route-selected']:
                this.state.route_selected === 'route_about',
            })}
          >
            <ListItemText className={'item-menu'} primary="Logout" />
          </ListItem>
        </List>
      </div>
    );
  }
}
