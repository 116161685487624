import { useEffect, useState } from 'react';
import { getCustomer } from '../../../../api/crm';
import { TicketApplications } from '../../../../Containers/Hermes/pickup/ticketApplications';
import { TicketDetails } from '../../../../Containers/Hermes/pickup/ticketDetails';
import { FzToast } from '../../../fz/form/notification/toast';
import { FzJumbotron } from '../../../fz/layout';
import { FzCard, FzCardGroup } from '../../../fz/layout/FzCard';


export const AttendenceConsoleHermes = ({ ns, history, basePath }) => {
  const [show, setShow] = useState(false);
  const [headerMessageTost, setHeaderMessageTost] = useState('');
  const [bodyMessageTost, setBodyMessageTost] = useState('');
  const [colorTost, seColorTost] = useState('');
  const [customerAttendance, setCustomerAttendance] = useState();
  const [ticketAttendance, setTicketAttendance] = useState();

  const handleClose = () => {
    setShow(false);
  };

  const showResultToast = (header, message, color) => {
    setHeaderMessageTost(header);
    setBodyMessageTost(message);
    seColorTost(color);
    setShow(true);
  };

  useEffect(() => {
    async function getData() {
      try {
        const _ticket = ns.getShared("ticket");
        setTicketAttendance(_ticket);
        const customer = await getCustomer(_ticket.application.customerId);
        customer.applications.forEach(app => {
          ns.set("crm.treatments." + app.id, "");
          ns.set("crm.treatmentsArray." + app.id, []);
        });
        ns.set("application", _ticket.application);
        ns.set("customer", customer)
        setCustomerAttendance(customer);
      } catch (err) {
        showResultToast(
          'Erro no servidor',
          'Erro ao localizar o cliente',
          'danger'
        );
        console.error(err);
      }
    }

    getData()
  }, []);

  return (
    <>
      { ticketAttendance && customerAttendance ? (
        <FzJumbotron>
          <FzCardGroup>
            <TicketDetails 
              customer={ticketAttendance.customer} 
              ns={ns} 
            />
          </FzCardGroup>
          <TicketApplications
            customer={customerAttendance}
            applications={customerAttendance?.applications}
            ticket={ticketAttendance}
            ns={ns}
            history={history}
            basePath={basePath}
            onNeedUpdate={() => ns.unset('ticket')}
            priorityAttendance={ticketAttendance.application}
          />
        </FzJumbotron>
      ) : (
        <FzCard.Header>
          <span> Carregando as informações ... </span>
        </FzCard.Header>
      )}

      <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
      />
    </>
  );
};
