import React from 'react'
import FieldConnected from '../../../ui/components/form/field-connected'
import { FzCheckbox, FzButton } from '../../../ui/fz/form'
import { FzCard } from '../../../ui/fz/layout/FzCard'
import { FzTable } from '../../../ui/fz/grid/table'
import { emailHistories, emailHistoriesColumns } from '../../../biz/metadatas/email-histories'

const History = ({
  ns,
  data,
  visibleColumns,
  handleCheckBox,
  isChecked,
  handleSearch,
  role,
  filters,
}) => {
  return (
    <div style={{ display: "flex", width: "1330px", padding: "20px" }}>
      <FzCard>
        <FzCard.Header>
          <FzCard.Title>
            Histórico de e-mails enviados
          </FzCard.Title>
        </FzCard.Header>
        <div className={"fz-panel"}>
            <div className={"fz-panel-border-padding"} style={{ display: "block" }}>
              <div style={{ display: "flex" }}>
                <FieldConnected meta={emailHistories.from} data={filters} ns={ns} />
                <FieldConnected meta={emailHistories.to} data={filters} ns={ns} />
              </div>
              <div style={{ display: "block" }}>
                <FzCheckbox 
                  checked={"24h" === isChecked}
                  value="24h"
                  onChange={handleCheckBox}
                  onChangeType="event"
                  name="last24h"
                  inline
                > 
                  Last 24 hours
                </FzCheckbox>
                <FzCheckbox 
                  checked={"7" === isChecked}
                  value="7"
                  onChange={handleCheckBox} 
                  onChangeType="event"
                  name="7days"
                  inline
                >
                  Last 7 days
                </FzCheckbox>
                <FzCheckbox 
                  checked={"10" === isChecked}
                  value="10"
                  onChange={handleCheckBox} 
                  onChangeType="event"
                  name="10days"
                  inline
                >
                  Last 10 days
                </FzCheckbox>
              </div>
            </div>
            <div className={"fz-panel-border-padding"} style={{ display: "block", border: "none" }}>
                <div>
                  <small>É necessário preencher pelo menos um dos campos abaixo:</small>
                </div>
                <div style={{ display: "flex" }}>
                  <div  style={{ margin: "0 10px 0 0"}}>
                    <FieldConnected 
                      meta={emailHistories.personalNumber}
                      data={filters}
                      ns={ns}
                    />
                  </div>
                  <div  style={{ margin: "0 10px 0 0"}}>
                    <FieldConnected 
                      meta={emailHistories.email}
                      data={filters}
                      ns={ns}
                    />
                  </div>
                  <div  style={{ margin: "0 10px 0 0"}}>
                    <FieldConnected 
                      meta={emailHistories.applicationId}
                      data={filters}
                      ns={ns}
                    />
                  </div>
                  <div  style={{ margin: "25px 10px 0 0"}}>
                    <FzButton 
                      fzStyle="discrete" 
                      onClick={handleSearch}
                    >
                      Buscar Cliente
                    </FzButton>
                  </div>
                </div>
            </div>
          </div>

          <FzCard.Body>
            <FzTable
              ns={ns}
              metas={emailHistoriesColumns}
              data={data.statistics}
              exportDownload={role === 'admin'}
              visibleColumns={visibleColumns}
            />
          </FzCard.Body>
      </FzCard>
    </div>
  )
}

export { History }
