import { FzIcons } from '../../../ui/fz/form/icon'
import './style.css'
import { QueueMeta } from '../queues/metadata'
import { FzTable } from '../../../ui/fz/grid/table'
import LinkOffIcon from '@material-ui/icons/LinkOff';
import RemoveIcon from '@material-ui/icons/Remove';
import DoneIcon from '@material-ui/icons/Done';
import FieldConnected from '../../../ui/components/form/field-connected';
import { QueueOrganizer } from '../../../components/dynamic-table/queue-organizer';
import { FzCol } from '../../../ui/fz/layout';
import { ModalQueueDetails } from '../modal/queueDetails';
import { useEffect, useState } from 'react';
import { getFieldsQueryBuilder, translationsConfig } from '../../../scripts/utils/jsonLogic-helpers';
import { getDomains } from '../../../api/domains';
import { MuiConfig } from '@react-awesome-query-builder/mui';
import { segmentationsHermesMeta } from '../queues/metaQueryBuilder';
import { compareConsultants } from '../../../scripts/utils/hermes-helpers';

export const UserDetailsContainer = ({
  user,
  users,
  ns,
  queuesOptions,
  assignedQueues,
  userQueues,
  isEditing,
  addQueue,
  toggleAdvisorEdit,
  removeQueue,
  moveQueue,
  toggleAddQueue,
  addNewQueueToUser,
  cancelAdvisorEdit,
  saveAdvisor,
  isLoading
}) => {

  const [info, setInfo] = useState({});
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [domainsHelper, setDomainsHelper] = useState({});
  const [config, setConfig] = useState({
    ...MuiConfig,
    ...translationsConfig,
  });

  useEffect(()=> {
    getDomains().then((domains) => {
      setDomainsHelper(domains);
      setConfig({
        ...config,
        fields: { ...getFieldsQueryBuilder(domains, segmentationsHermesMeta) },
      });
    });
  }, [])

  /*** Open details modal*/
  function handleDetailsModalOpen(row) {
    row["linkedUsers"] = undefined
    let completed = compareConsultants([row],users)[0]
    setInfo(completed)
    setDetailsModalOpen(true)
  }

  /*** Close details modal*/
  function handleDetailsModalClose() {
    setDetailsModalOpen(false);
  }

  return (
      <div style={{padding: "20px"}}>
        <div className="user-info">
          <b>{user?.name}</b>
          <p>Email: {user?.email}</p>
          <p>Telefone: {user?.telephoneNumber}</p>
        </div>
        <div className="fz-splitline custom-split-line" />
        <div className="user-info" style={{marginTop: "20px"}}>
          <b>Filas do consultor</b>
        </div>
        <div className="mt-1 queues-btn-container">
          {!isEditing && <button className="btn btn-primary" onClick={toggleAdvisorEdit}><FzIcons icon="pencil" color="#FFFFFF" fzStyle={{width: "15px", height: "15px"}}/></button>}
          {isEditing && <button className="btn btn-primary" disabled={isLoading} onClick={saveAdvisor}>Salvar</button>}
          {isEditing && <button className="btn btn-default ml-2" disabled={isLoading} onClick={cancelAdvisorEdit}>Cancelar</button>}
        </div>
        <div className="mt-2 queues-table-container">
        <FzTable
          data={assignedQueues}
          ns={ns}
          metas={QueueMeta}
          exportDownload={false}
          clipboard={false}
          customColumnFormatters={{
            'details': (cell, row, formatExtraData, idx) => {
              return <button className="btn btn-primary" onClick={()=>{handleDetailsModalOpen(row)}}>Ver detalhes</button>
            },
            'priority': (cell, row, formatExtraData, idx) => {
              return <QueueOrganizer idx={idx} userQueues={userQueues} moveQueue={moveQueue} isEditing={isEditing} isLoading={isLoading}/>
            },
            'linked': (cell, row, formatExtraData, idx) => {
              return <button 
                className="btn btn-danger" 
                style={{padding: "4px"}} 
                disabled={!isEditing} 
                onClick={() => removeQueue(idx)}>
                  <LinkOffIcon />
                </button>
            }
          }}
        />
        <ModalQueueDetails showModal={detailsModalOpen} hideModal={handleDetailsModalClose} infoModal={info}/>
        </div>
        {isEditing && !isLoading && <div className="user-info mt-3">
          <b>Vincular filas</b>
          <div className="mt-1" style={{width: "100%"}}>
            <button className="btn btn-primary" disabled={!isEditing || addQueue} onClick={() => toggleAddQueue()}>Adicionar nova</button>
            {addQueue && queuesOptions.inputProps.options.length > 0 && <div className="row mt-1">
              <FzCol span={3}>
                <FieldConnected meta={queuesOptions} data={ns.getChanged()} ns={ns}/>
              </FzCol>
              <div className="col-9" style={{display: 'flex'}}>
                <button 
                  className="btn btn-danger" 
                  style={{alignSelf: 'flex-end', marginBottom: '7px', padding: "4px"}} 
                  onClick={() => toggleAddQueue()}
                >
                  <RemoveIcon />
                </button>
                <button className="btn btn-success ml-2"
                  style={{alignSelf: 'flex-end', marginBottom: '7px', padding: "4px"}}
                  onClick={() => addNewQueueToUser()}
                >
                  <DoneIcon />
                </button>
              </div>
            </div>
            }
          </div>
        </div>}
      </div>
  )
  
  
}