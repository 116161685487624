import { MuiConfig } from "@react-awesome-query-builder/mui";
import { Utils as QbUtils } from '@react-awesome-query-builder/mui';
import { segmentationsHermesMeta } from "../../Containers/Hermes/queues/metaQueryBuilder";
import get from 'lodash/get';

export const InitialConfig = MuiConfig;
const hermesMeta = segmentationsHermesMeta;

export const translateOperators = (label) => {
  switch (label) {
    case 'All':
      return 'Todos';
    case 'Between':
      return 'Entre';
    case 'Ends with':
      return 'Termina com';
    case '==':
      return 'Igual a';
    case '>':
      return 'Maior que';
    case '>=':
      return 'Maior ou igual que';
    case 'Is empty':
      return 'Vazio';
    case 'Is not empty':
      return 'Não é vazio';
    case 'Is not null':
      return 'Não é nulo';
    case 'Is null':
      return 'Nulo';
    case '<':
      return 'Menor que';
    case '<=':
      return 'Menor ou igual a';
    case 'Contains':
      return 'Contém';
    case 'Equals':
      return 'Igual (Vários)';
    case 'Not contains':
      return 'Não contém';
    case 'Not equals':
      return 'Diferente (Vários)';
    case 'None':
      return 'Nenhum';
    case 'Not between':
      return 'Não está entre';
    case '!=':
      return 'Diferente';
    case 'Not contains':
      return 'Não contém';
    case 'Proximity search':
      return 'Busca por proximidade';
    case 'Any in':
      return 'Qualquer';
    case '==':
      return 'Igual';
    case 'Not in':
      return 'Não é (vários)';
    case 'Some':
      return 'Alguns';
    case 'Starts with':
      return 'Começa com';
    default:
      return label;
  }
};

export const operatorsTranslation = {};

Object.keys(InitialConfig.operators).forEach((operator) => {
  operatorsTranslation[operator] = {
    ...InitialConfig.operators[operator],
    label: translateOperators(InitialConfig.operators[operator].label),
  };
});

export const translationsConfig = {
  conjunctions: {
    AND: {
      ...InitialConfig.conjunctions.AND,
      label: 'E',
    },
    OR: {
      ...InitialConfig.conjunctions.OR,
      label: 'OU',
    },
  },
  settings: {
    ...InitialConfig.settings,
    addCaseLabel: 'Adicionar condição',
    addDefaultCaseLabel: 'Adicionar condição padrão',
    addGroupLabel: 'Adicionar grupo',
    addRuleLabel: 'Adicionar regra',
    addSubRuleLabel: 'Adicionar sub-regra',
    defaultGroupConjunction: 'E',
    fieldLabel: 'Campo',
    fieldPlaceholder: 'Selecionar campo',
    funcLabel: 'Função',
    funcPlaceholder: 'Seleciona a função',
    lockLabel: 'Bloquear',
    lockedLabel: 'Bloqueado',
    notLabel: 'NÃO',
    maxNesting: 1,
  },
  operators: {
    ...operatorsTranslation
  },
};

export const getFieldsQueryBuilder = (domains, meta) => {
  let fields = {};

  Object.keys(meta).forEach((fieldPath) => {
    let field = meta[fieldPath];

    if (field && field.label) {
      let queryField = {
        label: field.label,
        type: field.inputType,
      };

      if (field.filterType === 'domain') {
        if (domains[field.inputProps.domainName]) {
          queryField.fieldSettings = {
            listValues: domains[field.inputProps.domainName].map((option) => ({
              value: option.code,
              title: option.description,
            })),
          };
        }
      } else {
        if (field.inputProps?.options && field.inputProps.options.length > 0) {
          queryField.valueSources = ['value'];

          queryField.fieldSettings = {
            listValues: field.inputProps.options.map((option) => ({
              value: option.code,
              title: option.description,
            })),
          };
        }
      }

      fields[fieldPath] = queryField;
    }
  });

  return fields;
};