import {Meta} from "../../types/metas";

export const partnerMeta: Meta = {
  campaign: {
    path: "campaign",
    label: "Nome da campanha",
    isMandatory: () => true,
  },
  supplierInternalName: {
    path: "supplierInternalName",
    label: "Nome do interno do parceiro",
    isMandatory: () => false,
  },
  campaignType: {
    path: "campaignType",
    label: "Tipo de Campanha",
    inputType: "select",
    inputProps: {
      options: [
        {code: "online", description: "Campanha Online"},
        {code: "offline", description: "Campanha Offline"},
        {code: "reminder", description: "Reminder"},
      ]
    },
    isMandatory: () => true,
  },
  notificationEvent: {
    path: "notificationEvent",
    label: "Qual o nome da notificação que será disparada?",
    tip: "Ir para a guia MENSAGERIA para criar ou editar novos templates de notificação",
    inputType: "select",
    inputProps: {
      options: []
    },
    isMandatory: () => true,
  },
  startDate: {
    path: "startDate",
    label: "Data de início da campanha",
    isMandatory: () => true,
    inputType: "date"
  },
  endDate: {
    path: "endDate",
    label: "Data de fim da campanha",
    isMandatory: () => true,
    inputType: "date"
  },
  scheduleDate: {
    path: "scheduleDate",
    label: "Definir data e hora de envio",
    isMandatory: () => false,
    inputType: "dateTime",
    inputProps: {
      maxValue: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
      minValue: new Date(),
    },
    utc: false,
  },
  "auth": {
    path: "auth",
    label: "Pré autenticação (qualquer item checkado irá manter o osuário ativo até o fim da campanha)",
    isMandatory: () => true,
    inputType: "multiselect",
    inputProps: {
      options: [
        {code: "generateToken", description: "Entrar logado"},
        {code: "generatePreAuth", description: "SEMPRE entrar logado (perigoso!)"},
      ]
    }
  },
  origin: {
    path: "origin",
    label: "Origem",
    inputType: "select",
    inputProps: {
      options: [
        {code: "csv", description: "CSV"},
        {code: "alexandria", description: "Alexandria"},
      ]
    },
    isMandatory: () => true,
  },
  table: {
    path: "table",
    label: "Tabela",
    inputType: "select",
    inputProps: {
      options: [
        {code: "manual_campaign_1", description: "Campanha Manual 1"},
        {code: "manual_campaign_2", description: "Campanha Manual 2"},
      ]
    },
    isMandatory: () => true,
  },
  "filterConditions.targets": {
    path: "filterConditions.targets",
    label: "(Target) - Para quem enviar",
    tip: " Os resultados deste filtro representam o grupo de usuários que você <strong>DESEJA</strong> enviar a campanha.",
    inputProps: {
      options: [
        {code: "range", description: "Ranges"},
        {code: "domain", description: "Domains"},
        // {code: "campaigns", description: "Campanhas"},
      ]
    },
  },
  "filterConditions.exceptions": {
    path: "filterConditions.exceptions",
    label: "(Exception) - Para quem não enviar{' '}",
    tip: "Os resultados deste filtro representam o grupo de usuários que você <strong>NÃO DESEJA</strong> enviar a campanha.", 
    inputProps: {
      options: [
        {code: "range", description: "Ranges"},
        {code: "domain", description: "Domains"},
        {code: "campaigns", description: "Campanhas"},
      ]
    },
  },
  "targets": {
    path: "targets",
    label: "(Target) - Para quem enviar",
    tip: " Os resultados deste filtro representam o grupo de usuários que você <strong>DESEJA</strong> enviar a campanha.",
    inputProps: {
      options: [
        {code: "range", description: "Ranges"},
        {code: "domain", description: "Domains"},
        // {code: "campaigns", description: "Campanhas"},
      ]
    },
  },
  "exceptions": {
    path: "exceptions",
    label: "(Exception) - Para quem não enviar{' '}",
    tip: "Os resultados deste filtro representam o grupo de usuários que você <strong>NÃO DESEJA</strong> enviar a campanha.", 
    inputProps: {
      options: [
        {code: "range", description: "Ranges"},
        {code: "domain", description: "Domains"},
        {code: "campaigns", description: "Campanhas"},
      ]
    },
  }
};
