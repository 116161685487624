import { DashRunRatePanel } from '../../pages/statistics/funnel/dash-run-rate';
import { OverviewPanel } from '../../pages/statistics/funnel/overview-panel'
import { OverviewPartnersPanel } from '../../pages/statistics/funnel/overview-partners-panel'
import { CustomersBookPanel } from '../../pages/statistics/funnel/customers-book';


const FunnelStats = ({ ns, activeKey, toggleBlocking, tableOptions, products }) => {
  switch (activeKey) {
    case "overview_panel":
      return <OverviewPanel ns={ns} toggleBlocking={toggleBlocking} />
    case "partners_panel":
      return <OverviewPartnersPanel ns={ns} toggleBlocking={toggleBlocking} products={products} />
    case "run_rate":
      return <DashRunRatePanel ns={ns} toggleBlocking={toggleBlocking} /> 
    case "customers_book":
        return <CustomersBookPanel ns={ns} toggleBlocking={toggleBlocking} /> 
    default:
      return null;
  }
}
export default FunnelStats;
