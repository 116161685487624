
import { useEffect, useState } from 'react';
import qs from 'qs';
import { getAllReprocessing } from '../../../api/integrations';
import { getUsers } from '../../../api/users';
import { ItemListType, UserType, FiltersType, ReprocessingResponseType } from '../../../biz/metadatas/bifrost/reprocessing-metadata';
import { ReprocessingFiltersContainer } from '../../../Containers/Bifrost/ReprocessingListFiltersContainer';
import { ReprocessingListContainer } from '../../../Containers/Bifrost/ReprocessingListTableContainer';
import { getSuppliersList } from '../../../scripts/utils/cache-helpers';
import { FzCard } from '../../fz/layout';
import { sortSuppliers, distinctSuppliers } from '../aggregations/utils';

export function ReprocessingSearch({ ns }) {
  const [filtersObject, setFiltersObject] = useState<FiltersType>()
  const [suppliersList, setSuppliersList] = useState<ItemListType[]>([])
  const [usersList, setUsersList] = useState<ItemListType[]>([])
  const [reprocessingList, setReprocessingList] = useState<ReprocessingResponseType[]>()
  let changed = ns.getChanged()

  useEffect(() => {
    ns.unset('userId')
    ns.unset('supplierInternalName')
    ns.unset('limit')
    ns.unset('offset');
    getQueryString();
    setFilters();
    setUsers();
    setSuppliers();
  }, [])

  useEffect(() => {
    setFilters()
  }, [ns.getChanged()])

  function setFilters() {
    const nsChanged = ns.getChanged()

    const filters = {
      ...(nsChanged && nsChanged.userId && { userId: nsChanged.userId }),
      ...(nsChanged && nsChanged.supplierInternalName && { supplierInternalName: nsChanged.supplierInternalName }),
      ...(nsChanged && nsChanged.createdFrom && { createdFrom: new Date(nsChanged.createdFrom).toJSON() }),
      ...(nsChanged && nsChanged.createdTo && { createdTo: new Date(nsChanged.createdTo).toJSON() }),
      ...(nsChanged && nsChanged.limit && { limit: nsChanged.limit }),
      ...(nsChanged && nsChanged.offset && { offset: nsChanged.offset }),
    }
    setFiltersObject(filters)
  }

  async function setUsers() {
    const admins = await getUsers('admin');
    const users = admins.filter((user: UserType) => user.active && /\b(su|asgd)\b/.test(user.group))
      .map((user: UserType): ItemListType => ({ code: user.id, description: user.name }));

    setUsersList(users);
  }

  async function setSuppliers() {
    const listOfSuppliers = await getSuppliersList(ns)

    listOfSuppliers
      .sort(sortSuppliers)
      .filter(distinctSuppliers);

    setSuppliersList(listOfSuppliers)
  }

  async function handleSearch() {
    setQueryString()
    const { data } = await getAllReprocessing(filtersObject)
    setReprocessingList(data)
  }

  function setQueryString() {
    const query = qs.stringify(filtersObject, { addQueryPrefix: true })
    const url = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: url }, '', url);
  }

  function getQueryString() {
    const queryString = new URLSearchParams(window.location.search);
    let params: any = Object.fromEntries(queryString.entries());

    const timeZoneOffSet = new Date().getTimezoneOffset() * 60 * 1000;
    if (params.createdFrom && !isNaN(Date.parse(String(params.createdFrom)))) {
      params.createdFrom = new Date(params.createdFrom).toJSON()
    } else {
      params.createdFrom = new Date(new Date().setHours(-(24 * 7), 0, 0, 0) - timeZoneOffSet).toJSON()
    }

    if (params.createdTo && !isNaN(Date.parse(String(params.createdTo)))) {
      params.createdTo = new Date(params.createdTo).toJSON()
    } else {
      params.createdTo = new Date(new Date().setHours(23, 59, 59, 0) - timeZoneOffSet).toJSON()
    }

    ns.startEditing(params)
    setFilters()
  }

  return (
    <>
      <FzCard.Body fzStyle={{ padding: '1.5rem' }}>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title>Reprocessamentos</FzCard.Title>
          </FzCard.Heading>
          <ReprocessingFiltersContainer
            data={changed}
            ns={ns}
            suppliersList={suppliersList}
            usersList={usersList}
            handleSearch={handleSearch}
          />
        </FzCard>
      </FzCard.Body>

      <FzCard.Body>
        <ReprocessingListContainer
          ns={ns}
          reprocessingList={reprocessingList}
          suppliersList={suppliersList}
        />
      </FzCard.Body>
    </>
  )
}

