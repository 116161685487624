import React from 'react';
import moment from 'moment'
import clone from 'lodash/clone';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  FzTextarea,
  FzSelect,
  FzText,
  FzDate,
  FzDateV2,
  FzMasked,
  FzMultiSelect,
  FzMultiSelectAutoComplete,
  FzMultiSelectAutoCompleteSortable,
  FzNumber,
  FzAutoComplete,
  FzDateTime,
  FzCodeEditor,
  FzTextAreaOptionsEmojicon,
  FzMapStringString,
} from './old-simple-input';
import '../style/form.css';
import { FzForm } from '../layout'
import { FzNavSelect, FzNavDropdown } from '../nav';
import { FzCurrency, FzCheckbox, FzDynamicFieldsList } from './simple-input';
import FzDynamicFieldsListWithIcons from './SelectIcon';
import { FzWYSIWYG } from './WYSIWYG';
import FzConfirmationSteps from './confirmation-steps';
import { FzCommissionRanges } from './FzCommissionRanges';

const getNativeValue = (value, type) => {

  const convert = {
    'number': Number,
    'map': (value) => JSON.parse(value), // TODO trocar para json
    'json': (value) => JSON.parse(value),
    'date': (d) => new Date(d),
    'moment': (d) => moment(d),
    'bool': (v) => {
      return (v === true
        || v.toString().toLowerCase() === 't'
        || v.toString().toLowerCase() === 'true'
        || v.toString().toLowerCase() === 's'
        || v.toString().toLowerCase() === 'sim'
      );
    },
  };
  if (convert[type]) {
    return convert[type](value);
  }
  return value;
};


class FzField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    dataType: PropTypes.string,  // Javascript datatype
    onChange: PropTypes.func,
    extraChangeEffect: PropTypes.func,
    inputType: PropTypes.string, // number, currency, date, datetime, text, textarea, select, bool
    inputProps: PropTypes.object,           // inputType specif params, such as mask,domain, select options, etc.
    readOnly: PropTypes.bool,
    value: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tip: PropTypes.string,
    placeHolder: PropTypes.string,
    invalid: PropTypes.bool,
    mandatory: PropTypes.bool,
    minValue: PropTypes.any,
    maxValue: PropTypes.any,
    minSize: PropTypes.number,
    maxSize: PropTypes.number,
    validationMessage: PropTypes.string,
    doNormalize: PropTypes.func,             // Invoke before onChange to normalize input data
    fzStyle: PropTypes.string,
    isJSON: PropTypes.bool,
  };
  static VALIDATION_ERROR = -1;
  static VALIDATION_WARNING = 0;
  static VALIDATION_OK = 1;

  constructor(props) {
    super(props);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(newVal, type) {
    const cProps = this.props.inputProps || {};
    if (this.props.doNormalize !== undefined && newVal !== undefined) {
      newVal = this.props.doNormalize(newVal);
    }
    if (cProps.percent) {
      newVal = newVal / 100
    }
    if (this.props.onChange)
      this.props.onChange(getNativeValue(newVal, type));
  }

  render() {
    const { inputType, value, mandatory, tip } = this.props;
    let { label } = this.props;

    let cProps = clone(this.props.inputProps || {});
    cProps.onChange = this.onChangeValue;
    cProps.name = this.props.name;
    cProps.utc = this.props.utc;
    cProps.displayTimeZone = this.props.displayTimeZone;
    cProps.readOnly = this.props.readOnly || !this.props.onChange;
    cProps.placeHolder = this.props.placeHolder;
    cProps.validationMessage = this.props.validationMessage;
    cProps.invalid = this.props.invalid;
    cProps.dataType = this.props.dataType;
    cProps.inputType = inputType;
    cProps.customClass = this.props.customClass;
    if (this.props.extraChangeEffect) {
      cProps.extraChangeEffect = this.props.extraChangeEffect
    }

    if (cProps.percent) {
      cProps.value = value * 100;
    } else {
      cProps.value = value;
    }

    const components = {
      'text': FzText,
      'textarea': FzTextarea,
      'textarea-options': FzTextAreaOptionsEmojicon,
      'select': FzSelect,
      'multiselect': FzMultiSelect,
      'multiselect-autocomplete': FzMultiSelectAutoCompleteSortable,
      'date': FzDate,
      'dateV2': FzDateV2,
      'dateTime': FzDateTime,
      'masked': FzMasked,
      'maskedPhone': FzMasked,
      'number': FzNumber,
      'auto-complete': FzAutoComplete,
      'checkbox': FzCheckbox,
      'code': FzCodeEditor,
      'nav-select': FzNavSelect,
      'nav-dropdown': FzNavDropdown,
      'currency': FzCurrency,
      'dynamic-fields-list': FzDynamicFieldsList,
      'dynamic-fields-list-with-icons': FzDynamicFieldsListWithIcons,
      'commissionRanges': FzCommissionRanges,
      'objectString': FzMapStringString,
      'WYSIWYG': FzWYSIWYG,
      'confirmation-steps': FzConfirmationSteps
    };

    let validationState = null;
    if (this.props.invalid) {
      validationState = `validationCustom${this.props.name}`;
    } else if (cProps.minSize || cProps.maxSize) {
      let bias = Math.min(Math.floor(cProps.maxSize / 10), 10);
      if (String(value).length > cProps.maxSize - bias) {
        validationState = 'warning';
      }
    }

    const inputComponent = components[inputType || "text"];
    let controlLabel;
    if (label) {
      if (typeof (label) === 'string' && mandatory) {
        label += ' *';
      }
      if (cProps.altInputLayout) {
        label += ':';
      }

      controlLabel = (
        <FzForm.Label>
          <React.Fragment>
            {label}
            {tip &&
              <i>
                <span>i</span>
                <p className='labelTip'>{tip}</p>
              </i>
            }
          </React.Fragment>
        </FzForm.Label>
      );
    }

    let validationMessage = validationState && this.props.validationMessage ?
      <FzForm.Control.Feedback >{this.props.validationMessage}</FzForm.Control.Feedback> : null;

    let bsSize = 'sm';
    if (this.props.fzStyle === 'large' || this.props.fzStyle === 'lg' || this.props.fzStyle === 'big') {
      bsSize = 'large';
    }

    if (inputComponent) {
      return (
        <Form.Group size={bsSize} controlId={validationState}>
          {controlLabel}

          {cProps.highlightFields ? (
            <div className="fz-highlightFields">
              {React.createElement(inputComponent, cProps)}
            </div>
          ) : (<>
            {React.createElement(inputComponent, cProps)}
          </>)}
          {validationMessage}
          <strong>
            {this.props.informationMessage && this.props.informationMessage(value)}
          </strong>
        </Form.Group>
      );
    } else {
      return null;
    }
  }
}

export { FzField };