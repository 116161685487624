import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import '../../fz/style/menu.css'


export class FzMenuSupplier extends React.Component<any, any>   {

  constructor(props) {
    super(props)

    this.state = {
      stats: false,
      route_funnel: false,
      route_teste: false,
      route_selected: false
    }

    this.goToRoute = this.goToRoute.bind(this)
    this.showOptionsMenu = this.showOptionsMenu.bind(this)
  }

  goToRoute(route, name) {
    const { history } = this.props
    this.setState({ route_selected: name }, () => history.push(`/${route}`))
  }

  showOptionsMenu(name) {
    if (this.state.show_options !== name) {
      this.setState({ show_options: name })
    } else if (this.state.show_options === name) {
      this.setState({ show_options: undefined })
    }

  };

  render() {

    return (
      <div>

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >

          <ListItem button onClick={() => this.goToRoute("suppliers/biddable", "route_biddable")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_biddable"
          })}>
            <ListItemText className={"item-menu"} primary="Novos leads" />
          </ListItem>


          <ListItem button onClick={() => this.goToRoute("suppliers/accepted", "route_accepted")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_accepted"
          })}>
            <ListItemText className={"item-menu"} primary="Propostas aceitas" />
          </ListItem>

          <ListItem button onClick={() => this.goToRoute("suppliers/bids", "route_bids")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_bids"
          })}>
            <ListItemText className={"item-menu"} primary="Pré-aprovados" />
          </ListItem>

          <ListItem button onClick={() => this.goToRoute("suppliers/biddable-sent", "route_biddable-sent")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_biddable-sent"
          })}>
            <ListItemText className={"item-menu"} primary="Digitados aguardando" />
          </ListItem>

          <ListItem button onClick={() => this.goToRoute("suppliers/change-bid", "route_change-bid")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_change-bid"
          })}>
            <ListItemText className={"item-menu"} primary="Reporte de Rejeição" />
          </ListItem>

          <ListItem button onClick={() => this.goToRoute("integration", "route_integration")} className={clsx("fz-list-item", {
            ["fz-route-selected"]: this.state.route_selected === "route_integration"
          })}>
            <ListItemText className={"item-menu"} primary="Integrações" />
          </ListItem>

          <ListItem button onClick={() => this.goToRoute("about", "route_about")} className={clsx("", {
            ["fz-route-selected"]: this.state.route_selected === "route_about"
          })}>
            <ListItemText className={"item-menu"} primary="Sobre" />
          </ListItem>


        </List>
      </div>


    )
  }



}
