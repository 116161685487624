import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PublishIcon from '@material-ui/icons/Publish';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GetAppIcon from '@material-ui/icons/GetApp';

export const QueueOrganizer = ({
  idx,
  userQueues,
  moveQueue,
  isEditing,
  isLoading
}) => {

  function queueMove(arr, oldIdx, newIdx) {
    let arrCopy = JSON.parse(JSON.stringify(arr))
    if (newIdx > arrCopy.length) {
      newIdx = arrCopy.length
    }
    const element = arrCopy.splice(oldIdx, 1)[0]
    arrCopy.splice(newIdx, 0, element);
    moveQueue(arrCopy)
    return arrCopy;
  };

  function moveQueueTop() {
    if (idx != 0) {
      queueMove(userQueues, idx, 0)
    }
  }

  function moveQueueBottom() {
    if (idx < userQueues.length) {
      queueMove(userQueues, idx, userQueues.length)
    }
  }

  function moveQueueUp() {
    if (idx > 0) {
      queueMove(userQueues, idx, idx - 1)
    }
  }

  function moveQueueDown() {
    if (idx < userQueues.length) {
      queueMove(userQueues, idx, idx + 1)
    }
  }

  return (
    <div>
      {idx + 1}
      <button
        className="btn btn-default"
        style={{ marginLeft: "10px", padding: "4px", marginRight: "10px" }}
        disabled={(!isEditing || isLoading)}
        onClick={() => moveQueueUp()}>
        <KeyboardArrowUpIcon />
      </button>
      <button
        className="btn btn-default"
        style={{ padding: "4px", marginRight: "10px" }}
        disabled={(!isEditing || isLoading)}
        onClick={() => moveQueueDown()}>
        <KeyboardArrowDownIcon />
      </button>
      <button
        className="btn btn-default"
        style={{ padding: "4px", marginRight: "10px" }}
        disabled={(!isEditing || isLoading)}
        onClick={() => moveQueueTop()}>
        <PublishIcon />
      </button>
      <button
        className="btn btn-default"
        style={{ padding: "4px" }}
        disabled={(!isEditing || isLoading)}
        onClick={() => moveQueueBottom()}>
        <GetAppIcon />
      </button>
    </div>
  )
}