
import { Meta } from "../../types/metas";


export const panelControl: Meta = {
  "agregado": {
    "path": "agregado",
    "label": "Agregado por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "day", "description": "Daily" },
        { "code": "week", "description": "Weekly" },
        { "code": "month", "description": "Month" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "pivot": {
    "path": "pivot",
    "label": "Visualizar por: ",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "product", "description": "Produto" },
        { "code": "period", "description": "Periodo" }
      ],
    },
    "isMandatory": (application) => false,
  },

  "product": {
    "path": "product",
    "label": "Escolha um período",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => false,
  },
  "period": {
    "path": "period",
    "label": "Escolha um produto",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => false,
  },
  "view": {
    "path": "view",
    "label": "Tipo de Visualização ",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "table", "description": "Tabela" },
        { "code": "charts", "description": "Gráfico" }
      ],
    },
    "isMandatory": (application) => false,
  },
  "typeChart": {
    "path": "typeChart",
    "label": "Tipo de grafíco",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "line", "description": "Line" },
        { "code": "funnel", "description": "Funil" }
      ],
    },
    "isMandatory": (application) => false,
  },
}

export const setMetaOptionsOverviewPartners = (productOptions): Meta => ({
  "agregado": {
    "path": "agregado",
    "label": "Agregado por",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "day", "description": "Daily" },
        { "code": "week", "description": "Weekly" },
        { "code": "month", "description": "Month" },
      ],
    },
    "isMandatory": (application) => true,
  },
  "pivot": {
    "path": "pivot",
    "label": "Visualizar por: ",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "supplier", "description": "Parceiro" },
        { "code": "period", "description": "Periodo" }
      ],
    },
    "isMandatory": (application) => false,
  },
  "supplier": {
    "path": "supplier",
    "label": "Escolha um periodo",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => false,
  },
  "period": {
    "path": "period",
    "label": "Escolha um parceiro",
    "dataType": "string",
    "inputType": "select",
    "inputProps": {
      "options": undefined,
    },
    "isMandatory": (application) => false,
  },
  "view": {
    "path": "view",
    "label": "Tipo de Visualização ",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "table", "description": "Tabela" },
        { "code": "charts", "description": "Gráfico" }
      ],
    },
    "isMandatory": (application) => false,
  },
  "typeChart": {
    "path": "typeChart",
    "label": "Tipo de grafíco",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": [
        { "code": "overlaid-bar", "description": "Overlaid Bar" },
      ],
    },
    "isMandatory": (application) => false,
  },
  "product": {
    "path": "product",
    "label": "Visualizar por periodo ",
    "dataType": "string",
    "inputType": "nav-select",
    "inputProps": {
      "options": productOptions.map(product => ({ code: product, description: product })),
    },
    "isMandatory": (application) => true,
  },
})