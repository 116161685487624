import { Meta } from '../../../../types/metas';

export const screeningTMAMeta: Meta = {
  "key": {
    "path": "key",
    "dataType": "number",
    "label": "#",
    "tableProps": {
      "width": "3ch",
      "hidden": true,
      "isKey": true,
      "dataSort": true,
      "dataAlign": "center",
    }
  },

  "screenerName": {
    "path": "screenerName",
    "label": "Analista",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "screenerName",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "screenerId": {
    "path": "screenerId",
    "label": "Analista Id",
    "dataType": "string",
    "tableProps": {
      "viewsTable": "screenerId",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "total": {
    "path": "total",
    "label": "Total de análises",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "total",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "pended": {
    "path": "pended",
    "label": "Análises pendenciadas",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "pended",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "success": {
    "path": "success",
    "label": "Análises validadas",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "success",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "rejected": {
    "path": "rejected",
    "label": "Análises rejeitadas",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "rejected",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "closed": {
    "path": "closed",
    "label": "Análises fechadas",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "closed",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },

  "analysisTime": {
    "path": "analysisTime",
    "label": "Tempo médio em análise",
    "dataType": "number",
    "tableProps": {
      "viewsTable": "analysisTime",
      "width": "16ch",
      "dataSort": true,
      "dataAlign": "left",
    }
  },
}