import React, { useState, useEffect } from 'react';
import { FzCard, FzRow, FzCol } from '../../../fz/layout/index'
import FieldConnected from '../../../components/form/field-connected';
import { buildPeriodFilters, buildSupplierFilters } from '../../../../components/table-standard';
import { getStatisticsFunnelOpePartnersTest, getStatisticsFunnelPartnersOverviewDS } from '../../../../api/stats';
import cloneDeep from "lodash/cloneDeep";
import { buildDataForOverlaidBar } from '../../../fz/formatter/charts'
import { setMetaOptionsOverviewPartners } from '../../../../biz/metadatas/panel-control-funnel'
import { FzButton } from '../../../fz/form';
import { OperationalPartners } from './tables/operational-overview-partners';
import { setTTL } from '../../../../business/misc-functions';
import { FzOverlaidBar } from '../../../fz/charts/overlaid-bar';
import qs from 'qs';

export function OverviewPartnersPanel(props) {
  const { ns } = props
  const [requestState, setRequestState] = useState('idle')
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({
    period: null,
    checked: null
  })
  const [showDataStudio, setShowDataStudio] = useState(false)
  const [dataStudioValue, setDataStudioValue] = useState(null)
  const panelControlOverviewPartners = setMetaOptionsOverviewPartners(props.products)
  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [ns])

  useEffect(() => {
    ns.startEditing()
    getQueryStringFromUrl()
    getStatisticsFunnelPartnersOverviewDS().then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get FUNNEL-OVERVIEW-PARTNERS Data Studio iframe", err)
    })
  }, [])

  function setUrlQueryString() {
    let changes = ns.getChanged()
    if (changes) {
      let filtersParams = {
        agregado: changes.agregado,
        view: changes.view,
        pivot: changes.pivot,
        supplier: changes.supplier,
        product: changes.product,
        typeChart: changes.typeChart
      }
      const query = qs.stringify(filtersParams, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      history.pushState({ path: newurl }, '', newurl);
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    const listOfQueryParams = Object.keys(params)
    if (listOfQueryParams.length > 0) {
      for (let i = 0; i < listOfQueryParams.length; i++) {
        ns.saveChange(listOfQueryParams[i], params[listOfQueryParams[i]])
      }
    }
    setUrlChecked(true)
  }

  function getStatistics(period) {
    props.toggleBlocking()
    setRequestState("requesting")
    setFilters({ ...filters, period: period })
      getStatisticsFunnelOpePartnersTest(period)
        .then((data) => {
          props.products.map((product) => {
            const dataClone = cloneDeep(data);
            dataClone.statistics = dataClone.statistics.filter(it => it.product === product)
            ns.set("funnelOpePartners_" + product, dataClone, setTTL(5))
            ns.saveChange("filter.periods_" + product, buildPeriodFilters(dataClone.statistics), null)
            ns.saveChange("filter.suppliers_" + product, buildSupplierFilters(dataClone.statistics), null)
            setRequestState('idle')
            return dataClone
          })
          props.toggleBlocking()
        })
        .catch((err) => {
          console.warn("API ERROR", err)
          props.toggleBlocking()
        })
  }

  function handleChangeSelect() {
    let filterValues2 = ns.getChanged("pivot") === "product" ? ns.getChanged("filter.product") : ns.getChanged("filter.periods");
    if (filterValues2) {
      const keysOfSelectedView = Object.keys(filterValues2);
      const funnelsKeys = keysOfSelectedView.reduce((acumulador, current, index) => {
        return { ...acumulador, ["funnel" + index]: current };
      }, {});

      ns.saveChange("funnels", funnelsKeys);
    }
  }

  function handleShowDataStudio() {
    setShowDataStudio(!showDataStudio)
  }

  function refreshData() {
    const rawData = getRawData(ns) || {}
    const path = ns.getChanged("pivot")
    let selectedProduct = ns.getChanged('product')

    buildDataForOverlaidBar(rawData.statistics, ns.getChanged("filter.suppliers_" + selectedProduct), ns.getChanged(path), ns)

  }

  function getRawData(ns) {
    return ns.get("funnelOpePartners_" + ns.getChanged('product'));
  }

  function setValuePivot(ns) {
    if (ns.getChanged("view") === "charts" && !ns.getChanged('pivot')) {
      ns.saveChange("pivot", "supplier");
    }
  }

  let data = ns.getChanged() || {};
  let selectedProduct = ns.getChanged('product')
  const rawData = getRawData(ns) || {}
  const path = ns.getChanged("pivot")

  let filterValues = ns.getChanged("pivot") === "supplier" ? ns.getChanged("filter.periods_" + selectedProduct) : ns.getChanged("filter.suppliers_" + selectedProduct)

  let filterMeta
  if (ns.getChanged("pivot") !== undefined && getRawData(ns) !== undefined) {

    filterMeta = cloneDeep(panelControlOverviewPartners[path]);
    if (filterValues) {
      filterMeta.inputProps.options = (Object.keys(filterValues) || []).map(it => ({
        code: it,
        description: filterValues[it]
      }));
    }
  }

  if (ns.getChanged("agregado") !== undefined && filters.period !== ns.getChanged("agregado") && requestState === 'idle') {
    getStatistics(ns.getChanged("agregado"))
  }


  // TODO remover do render
  if (ns.get('dataOverlaidBarChart') === undefined) {
    buildDataForOverlaidBar(rawData.statistics, ns.getChanged("filter.suppliers_" + selectedProduct), ns.getChanged(path), ns)
  }

  setValuePivot(ns);

  return (
    <>
      {!showDataStudio && dataStudioValue ? <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>Data Studio View</button> : <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>COF View</button>}
      {showDataStudio && dataStudioValue ? <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} /> :
        <FzCard>
          <FzCard>
            <FzCard.Heading fzStyle={{}}>
              <div></div>
            </FzCard.Heading>
            <FzCard.Body>
              <FzRow>
                <div className={"fz-panel"} >
                  <FzCol span={2}>
                    <div className={"fz-panel-border-padding"}>
                      <FieldConnected data={data} meta={panelControlOverviewPartners["agregado"]} ns={ns} />
                    </div>
                  </FzCol>
                  <FzCol span={2}>
                    <div className={"fz-panel-border-padding"}>
                      <FieldConnected data={data} meta={panelControlOverviewPartners["view"]} ns={ns} />
                    </div>
                  </FzCol>
                  <FzCol span={4}>
                    <div className={"fz-panel-border-padding"}>
                      <FieldConnected data={data} meta={panelControlOverviewPartners["product"]} ns={ns} />
                    </div>
                  </FzCol>
                  {ns.getChanged("view") === "charts" ? (
                    <FzCol span={1}>
                      <div className={"fz-panel-border-padding"}>
                        < FieldConnected data={data} meta={panelControlOverviewPartners["typeChart"]} ns={ns} />
                      </div>
                    </FzCol>) :
                    null
                  }
                  <FzCol span={2}>
                    <div className={"fz-panel-border-padding"}>
                      < FieldConnected data={data} meta={panelControlOverviewPartners["pivot"]} ns={ns} />
                    </div>
                  </FzCol>
                  <FzCol span={2}>
                    <div className={"fz-panel-border-padding"}>
                      {ns.getChanged("pivot") !== undefined && getRawData(ns) !== undefined ?
                        (<FieldConnected ns={ns} meta={filterMeta} data={data} />) : null}
                    </div>
                  </FzCol>
                  <FzCol span={1}>
                    <div style={{ display: "inline-grid" }}>
                      <FzButton onClick={refreshData}>{"Atualizar "}</FzButton>
                    </div>
                  </FzCol>
                </div>
              </FzRow>
            </FzCard.Body>
          </FzCard>

          {ns.getChanged("view") !== undefined ? (
            <FzCard>
              {ns.getChanged("view") === "table" ? (
                <FzCard.Body>
                  <OperationalPartners
                    data={rawData}
                    pivotColumnName={ns.getChanged("pivot")}
                    filterValue={ns.getChanged(path)}
                  />
                </FzCard.Body>) : null}
              {ns.getChanged("view") === "charts" && ns.getChanged("typeChart") === "overlaid-bar" ? (
                <FzCard.Body>
                  <FzRow>
                    <FzOverlaidBar
                      options={ns.get("dataOverlaidBarChart")} />
                  </FzRow>
                </FzCard.Body>) : null}

            </FzCard>) : null}

        </FzCard>}
    </>
  )
}
