import { ReactNode } from "react";
import { FzCard } from "../../../ui/fz/layout/FzCard";
import { FzNavSelect } from "../../../ui/fz/nav";
import { AnalyticsReport } from "./analytics";
import { SummariesReport } from "./summaries";
import { EditionApplicationReportsContainerProps } from "./types";

export function EditionApplicationReportsContainer({
  handlePage,
  typeView,
  ns,
  role,
  filters,
  data,
  handleSearchReport
}: EditionApplicationReportsContainerProps) {

  const contents = {
    analytics: (
      <AnalyticsReport
        filters={filters}
        role={role}
        ns={ns}
        data={data}
        handleSearchReport={handleSearchReport}
      />
    ),
    summaries: (
      <SummariesReport
        filters={filters}
        role={role}
        ns={ns}
        data={data}
        handleSearchReport={handleSearchReport}
      />
    ),
  }

  return (
    <>
      <div>
        <FzNavSelect
          fzStyle={{ marginRight: 0 }}
          value={typeView}
          options={[
            { code: "analytics", description: "Analytics" },
            { code: "summaries", description: "Summaries" },
          ]}
          onChange={handlePage}
        />
      </div>
      <br></br>
      <FzCard>
        {contents[typeView] || 'Aguardando seleção de relatório'}
      </FzCard>
    </>
  )
}