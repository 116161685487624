import React, { Component } from 'react';
import { CampaignFilter } from '../../../biz/metadatas/campaign-filter';
import { FzField, FzButton } from '../../fz/form';
import { FzCol, FzRow } from '../../fz/layout/index';

const styles = {
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  deleteButton: {
    fontSize: '18px',
  },
  inputTitle: {
    marginBottom: '8px',
  },
  spacement: {
    margin: '6px',
  },
};

class MatchRange extends Component {
  state = {
    from: false,
    to: false,
    initialValue: {
      type: 'int',
      from: null,
      to: null,
    },
    newFromType: {
      date: new Date().toISOString(),
      number: 0,
    }
  };

  componentDidMount() {
    const fields = CampaignFilter;
    const appFields = {};
    const options = Object.keys(fields)
      .map(key => {
        appFields[key.toLowerCase()] = fields[key];
        return key.toLowerCase();
      })
      .map(key => appFields[key].filterType === 'range' ? appFields[key] : undefined)
      .filter(field => field)
      .map(field => ({
        code: field.path.toLowerCase(),
        description: field.label,
        dataType: field.dataType,
      }));

    this.setState({ options });
  }

  handleSelectDate = (field) => {
    const { data, onChange } = this.props;
    const { options } = this.state;
    const selected = options.find(opt => opt.code === field);

    this.setState({ dataType: selected.dataType })
    onChange({
      ...data,
      field,
      dataType: selected.dataType,
      from: undefined,
      to: undefined,
    });
  };

  handleChange = (newFrom, newTo) => {
    const { data, onChange } = this.props;
    const { from, to, dataType, numberfrom, numberto } = data;
    const negativeValue = 'Não é possível inserir valores negativos neste campo';
    let dataChange = {
      ...data,
    };

    switch (dataType) {
      case 'date':
        if (newFrom !== null) {
          const currentDateFrom = new Date(newFrom ? newFrom : from || new Date()).setHours(0, 0, 0)
          newFrom = new Date(currentDateFrom).toISOString();
          dataChange.from = newFrom;
          dataChange[`${dataType}from`] = newFrom;
        }
        if (newTo !== null) {
          const currentDateTo = new Date(newTo ? newTo : to || new Date()).setHours(23, 59, 59)
          newTo = new Date(currentDateTo).toISOString();
          dataChange.to = newTo;
          dataChange[`${dataType}to`] = newTo;
        }
        break;
      case 'number':
        if (newFrom !== null) {
          if (newFrom < 0) {
            alert(negativeValue);
            return;
          }
          newFrom = (newFrom ? newFrom : numberfrom) || undefined;
          dataChange.from = newFrom;
          dataChange[`${dataType}from`] = newFrom;
        }
        if (newTo !== null) {
          if (newTo < 0) {
            alert(negativeValue);
            return;
          }
          newTo = (newTo ? newTo : numberto) || undefined;
          dataChange.to = newTo;
          dataChange[`${dataType}to`] = newTo;
        }
        break;
      default:
        break;
    }

    onChange(dataChange);
  };

  toggleFrom = () => {
    const { data, onChange } = this.props;
    const { from, dataType } = data;
    const { newFromType } = this.state;

    let value = from !== undefined ? undefined : newFromType[dataType].toString();
    value = value && new Date(new Date(value).setUTCHours(3, 0, 0, 0))
    onChange({
      ...data,
      from: value,
      [`${dataType}from`]: value,
    });
  };
  
  toggleTo = () => {
    const { data, onChange } = this.props;
    const { to, dataType } = data;
    const { newFromType } = this.state;

    let value = to !== undefined ? undefined : newFromType[dataType].toString();
    value = value && new Date(new Date(value).setUTCHours(26, 59, 59, 59))
    onChange({
      ...data,
      to: value,
      [`${dataType}to`]: value,
    });
  };

  render() {
    const { deleteContainer, deleteButton, inputTitle, spacement } = styles;
    const { data, onChange } = this.props;
    const { options } = this.state;
    const { dataType, field } = data;

    function getMaxValueByField(field) {
      let dateBefore18Years = new Date();
      dateBefore18Years = dateBefore18Years.setMonth(dateBefore18Years.getMonth() - 216)
      if ( field === 'birthdate' ) {
        return new Date(dateBefore18Years)
      }
      let dateAfter5Years = new Date();
      dateAfter5Years = dateAfter5Years.setMonth(dateAfter5Years.getMonth() + 60)
      if( field === "paid.lastinstallmentdate" ) {
        return new Date(dateAfter5Years)
      }
      let dateAfter1Year = new Date();
      dateAfter1Year = dateAfter1Year.setMonth(dateAfter1Year.getMonth() + 12)
      if( field === "paid.firstinstallmentdate" ) {
        return new Date(dateAfter1Year)
      }
      return new Date()
    }
    const dateFields = (
      <>
        <FzCol span={3}>
          <h4 style={inputTitle}>De: (inclusive)</h4>
          <div className={'flex-line'}>
            <FzButton onClick={this.toggleFrom}>
              {data[`${dataType}from`] !== undefined ? '-' : '+'}
            </FzButton>

            {data[`${dataType}from`] && (
              <FzField
                name={'_' + dataType}
                inputType={dataType}
                dataType={dataType}
                value={data[`${dataType}from`]}
                inputProps={{
                  minValue: new Date(1901, 1),
                  maxValue: getMaxValueByField(field),
                }}
                onChange={(value) => this.handleChange(value, null)}
              />
            )}
          </div>
        </FzCol>
        <FzCol span={3}>
          <h4 style={inputTitle}>Até: (exclusivo)</h4>
          <div className={'flex-line'}>
            <FzButton onClick={this.toggleTo}>
              {data[`${dataType}to`] !== undefined ? '-' : '+'}
            </FzButton>

            {data[`${dataType}to`] && (
              <FzField
                name={'_' + dataType}
                inputType={dataType}
                dataType={dataType}
                inputProps={{
                  minValue: new Date(1901, 1),
                  maxValue:
                    new Date(
                      getMaxValueByField(field).getTime() + 24 * 60 * 60 * 1000
                    ) || getMaxValueByField(field),
                }}
                value={data[`${dataType}to`]}
                onChange={(value) => this.handleChange(null, value)}
              />
            )}
          </div>
        </FzCol>
      </>
    );

    return (
      <div className="fz-rule rule-range">
        <FzRow>
          <FzCol span={4}>
            <h4 style={inputTitle}>Campo:</h4>
            <FzField
              value={field}
              name={'ranges-name'}
              dataType={'text'}
              inputType={'select'}
              inputProps={{ options }}
              onChange={this.handleSelectDate} />
          </FzCol>

          {field && (dateFields)}

          <FzCol span={2}>
            <div style={deleteContainer}>
              <div style={spacement} />
              <FzButton fzStyle={'alert'} onClick={() => {
                onChange(false);
              }}>
                <p style={deleteButton}>X</p>
              </FzButton>
            </div>
          </FzCol>
        </FzRow>
      </div>
    );
  }
}

export default MatchRange;