import React, { useState, useEffect } from 'react';
import { StatsDetailHeader } from '../../../../statistics/stats-components'
import { setTTL } from '../../../../business/misc-functions';
import { getPaidApplications, getPaidApplicationsDS } from '../../../../api/stats';
import { FzTable } from '../../../fz/grid/table';
import { FzCard } from '../../../fz/layout/index'
import { FzNavSelect } from '../../../fz/nav/index'
import qs from 'qs';
import { PERIOD_DAY_WEEK_MONTH } from '../../../../api/constants';

export function PaidApplications (props) {
  const [requestState, setRequestState] = useState('idle')
  const [currentPeriod, setCurrentPeriod]: any = useState(null)
  const [urlChecked, setUrlChecked] = useState(false)
  const [showDataStudio, setShowDataStudio] = useState(false)
  const [dataStudioValue, setDataStudioValue] = useState(null)
  const { ns, meta } = props

  useEffect(() => {
    if(urlChecked) {
      setUrlQueryString()
    }
  }, [currentPeriod])

  useEffect(() => {
    getQueryStringFromUrl()
    getPaidApplicationsDS().then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get PAID Data Studio iframe", err)
    })
  }, [])

  function setUrlQueryString() {
    if(currentPeriod){
      const query = qs.stringify({currentPeriod: currentPeriod}, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      history.pushState({path:newurl},'',newurl);
      getPaid(currentPeriod)
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if(params.currentPeriod) {
      setCurrentPeriod(params.currentPeriod)
    }else {
      setCurrentPeriod('day')
    }
    setUrlChecked(true)
  }

  function gotoApplicationsPaidSearch(cell, row) {
    let data = ns.get("paid")

    return (<a onClick={() => {
      setApplicationsPaidFilters(row, data.consolidationPeriod)
      props.history.push("/applications")
    }} href="#nop" >{cell}</a>);
  }

  function setApplicationsPaidFilters(row, consolidationPeriod) {
    //TODO recriar olhando para o campo product e sharedNS
  }

  function handleShowDataStudio() {
    setShowDataStudio(!showDataStudio)
  }

  function getPaid(period) {
    setRequestState('requesting')
    props.toggleBlocking(() => {
      getPaidApplications(period)
        .then((paid) => {
          ns.set("paid", paid, setTTL(5));
        })
        .catch((err) => {
          ns.set("paid", {statistics: []}, setTTL(5));
          console.warn("API ERROR", err);
        }).finally(() => {
          setRequestState('idle')
          props.toggleBlocking()
        })
    })
  }

  function handlePeriod(periodo) {
    setCurrentPeriod(periodo)
  }

  let data = ns.get("paid")

  if (!data && requestState === "requesting") {
    return null
  }

  if (!data && requestState !== "requesting") {
    return null
  }

  return (
    <>
      {!showDataStudio && dataStudioValue ? <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>Data Studio View</button> : <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>COF View</button>}
      {showDataStudio && dataStudioValue ? <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} />
    : <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title>
            <StatsDetailHeader stats={data} title={null} periods={null} currPeriod={null} onChange={null}/>
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzNavSelect
            fzStyle={{marginLeft: 0}}
            onChange={handlePeriod}
            options={[
              { "code": "day", "description": "Daily" },
              { "code": "week", "description": "Weekly" },
              { "code": "month", "description": "Monthly" },
            ]}
            selected={currentPeriod}
          />
          <div className={'mt-3'}>
            <FzTable
              data={data.statistics}
              pagination
              ns={ns}
              metas={meta}
              visibleColumns={["key", "perpaidAvgiod", "product", "supplier", "count", 'paidAvg', "hoursAvg", "paidAmount"]}
              customColumnFormatters={{
                count: (cell, row) => gotoApplicationsPaidSearch(cell, row)
              }}
              context={"paid"}
            />
          </div>
        </FzCard.Body>
      </FzCard>}
    </>
  )
}