import "core-js";
import 'whatwg-fetch'

import React from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider, addLocaleData} from 'react-intl';
import ptLocaleData from 'react-intl/locale-data/pt';
import App from './app';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import './css/fz-bootstrap.css';
import './css/app.css';
import './index.css';
import './css/forms.css';
import './css/datetime-picker.css';
import './css/split-line.css';

addLocaleData(ptLocaleData);

ReactDOM.render(
  <IntlProvider locale="pt-BR">
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
