import { Meta } from "../../../types/metas"

export const summaryMetaBoaVista: Meta = {
  "description": {
    "path": "description",
    "dataType": "string",
    "label": "",
    "tableProps": {
      "isKey": true,
      "width": "6rem",
      "dataSort": true,
      'expandable': false,
      rowSpan: "3"
    },
    "isMandatory": () => false
  },
  
  "totalGlobalMatch": {
    "path": "----",
    "label": "totalGlobalMatch",
    "tableProps": {
      hidden: true,
      "dataSort": true,
      "spanColumns": {
        columns: [
          {
            "label": "Renda",
            dataAlign: "center",
            row: '0',
            colSpan: "4",
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Endereço",
            row: "0",
            colSpan: "4",
            dataAlign: "left",
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Total",
            row: "0",
            colSpan: "4",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Boa Vista",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Cache",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Boa Vista",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Cache",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Boa Vista",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Cache",
            row: "1",
            colSpan: "2",
            dataAlign: "left",
            "columnFormat": "amountColumn",
            pathExtraData: false,
            width: "4rem",
            hasDynamicPath: false
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "income-boa-vista",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "income-boa-vista",
            formatExtraData: "totalBV",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "income-cache",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "income-cache",
            formatExtraData: "totalCache",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "address-boa-vista",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "address-boa-vista",
            formatExtraData: "totalBV",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "address-cache",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "address-cache",
            formatExtraData: "totalCache",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalBV",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalBV",
            formatExtraData: "totalGlobal",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "Count",
            row: "2",
            "columnFormat": "amountColumn",
            dataAlign: "left",
            path: "totalCache",
            hasDynamicPath: false,
            width: "4rem",
          },
          {
            "label": "%",
            row: "2",
            "columnFormat": "pctColumn",
            dataAlign: "left",
            path: "totalCahe",
            formatExtraData: "totalGlobal",
            hasDynamicPath: false,
            width: "4rem",
          }
        ]
      },
    },
    "isMandatory": () => false
  }
}
