import hooks from '../../../ui/fz/formatter/column-formatter'
import _ from 'lodash'

function createExportBasedOnMetas (metas, columnsPaths, jsonList) {
  const columns = _buildExportMeta(metas, columnsPaths)
  let csvContent = _exportHeader(columns)

  for (let row of jsonList) {
    let s = _exportOneLine(columns, row)
    if (s.length) {
      csvContent += s + "\n"
    }
  }
  return csvContent
}

function jsonToCsv (jsonList, fields) {
  let str = '';
  let row = null;

  for (let i = 0; i < fields.length; i++) {
    str += fields[i].name + '\t';
  }
  str += '\n';

  let generalFormat = function (value) {
    if (value === undefined || value === null) {
      return "";
    }
    return String(value);
  }

  for (let i = 0; i < jsonList.length; i++) {
    row = jsonList[i];
    for (let j = 0; j < fields.length; j++) {
      let formatFunc

      if (fields[j].arrayFormat) {
        formatFunc = fields[j].arrayFormat
        str += formatFunc(_.get(row, fields[j].key.split(".")[0], "")) + '\t';
      } else {
        formatFunc = fields[j].format || generalFormat;
        str += formatFunc(_.get(row, fields[j].key, "")) + '\t';
      }

    }
    str += '\n';
  }

  return str;
}

function _buildExportMeta (metas, columnsPaths) {
  let colsMeta: any = []

  for (let path of columnsPaths) {
    let metaObject = _.get(metas, path)
    if (!metaObject)
      continue;
    if (Array.isArray(metaObject)) {
      colsMeta = colsMeta.concat(metaObject.map(it => {
        return {
          field: it.path,
          label: it.section + path + it.tableProps.shortLabel || it.label,
          exportFormat: _getFormatter(it),
          path: it.tableProps.formatExtraData,
          supplier: path
        }
      }))
    } else {
      colsMeta.push(
        {
          field: metaObject.path,
          label: metaObject.label,
          exportFormat: _getFormatter(metaObject),
          formatExtraData: metaObject.tableProps.formatExtraData,
        })
    }
  }
  return colsMeta
}

function _getFormatter(items) {
  let formatter = hooks[items.tableProps.exportFormat];
  if (!formatter) {
    formatter = function (formatter) { return String(formatter); };
  }
  return formatter;
}

function _exportHeader (columnsMeta) {
  let str = ""
  for (let metaObject of columnsMeta) {
    str += (metaObject.label || "") + "\t"
  }
  return str + "\n"
}

function _exportOneLine (columnsMeta, row) {
  let str = ""
  columnsMeta.forEach((meta) => {

    if (meta.field.startsWith("auction") && row.auction && Array.isArray(row.auction.bids) && row.auction.bids.length > 0) {
      const bid = row.auction.bids.find(bid => bid.supplier.internalName === meta.supplier)
      str += meta.exportFormat(_.get(bid, meta.path, "-")) + "\t"
    } else if (meta.field.startsWith("supplierData") && row.supplierData) {
      const supplierData = row.supplierData[meta.supplier]
      str += meta.exportFormat(_.get(supplierData, meta.path, "-")) + "\t"
    } else if (meta.field.startsWith("scores") && row.scores && Array.isArray(row.scores) && row.scores.length > 0) {
      row.scores.map(it => {
        return str += meta.exportFormat(_.get(it, "score", "-")) + "\t"

      })
    } else {
      str += meta.exportFormat(_.get(row, meta.field, "-")) + "\t"
    }
  })
  return str
}

export { jsonToCsv, createExportBasedOnMetas }
