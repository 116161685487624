import React from 'react';
import { FzCard, FzCol, FzRow } from '../../fz/layout/index'
import {
  channelDetails,
  channelDetailsE,
  channelDetailsB,
  channelDetailsS,
  channelDetailsW, channelDetailsD
} from '../../../biz/metadatas/message-metadata'
import FieldConnected from '../../components/form/field-connected';
import cloneDeep from "lodash/cloneDeep";
import get from 'lodash/get';

export class ChannelDetails extends React.Component<any, any>  {

  constructor(props) {
    super(props)

    this.createColumns = this.createColumns.bind(this)
  }

  createColumns(fieldElms: any[], n: number, type: string) {
    let lastRow: any[] = [];
    let rows: any[] = [];

    for (let iteratorFieldElms = 0; iteratorFieldElms < fieldElms.length; iteratorFieldElms++) {
      lastRow.push(<FzCol span={12} key={iteratorFieldElms}> {fieldElms[iteratorFieldElms]} </FzCol>)
      rows.push(<FzRow> { lastRow } </FzRow>);
      lastRow = []
    }
    return rows
  }

  getMetaForChannel(message: any, code: any) {
    switch (message.channels[code].type) {
      case "E": //Email
        return channelDetailsE;
      case "B": // Browser Push Notification
        return channelDetailsB;
      case "A": //Mobile Push Notification
        return channelDetailsB;
      case "S": // SMS
        return channelDetailsS;
      case "W": // WhatsApp Notification
        return channelDetailsW;
      case "D": // WhatsApp Direct Message
        return channelDetailsD;
      default:
        return channelDetails;
    }
  }

  render() {
    let { ns, message, code, readOnly, persistChanges, variablesList } = this.props

    if (!readOnly && persistChanges === undefined) {
      readOnly = true;
    }

    let meta = this.getMetaForChannel(message, code);

    if ( 
        message.channels[code].type === "D" || 
        message.channels[code].type === "S" || 
        message.channels[code].type === "B" || 
        message.channels[code].type === "A"
      ) {
        
      let metaCustomField = get( meta, ".template" )
      metaCustomField.inputProps.options = variablesList;
      meta[".template"] = metaCustomField;
    }



    let fieldElms = Object.keys(meta).map(fieldName => {
      let metaField = cloneDeep(meta[fieldName])

      metaField.path = "channels[" + code + "]" + fieldName
      return (
        <FieldConnected ns={ns} data={message} meta={metaField} />
      )
    });

    var fieldsContent = this.createColumns(fieldElms, fieldElms.length, message.channels[code].type)

    return (
      <FzCard onClick={(e) => { e.stopPropagation() }}>
        <FzCard.Body>
          {fieldsContent.map(it => it)}
        </FzCard.Body>
      </FzCard>
    )
  }
}

export default ChannelDetails;