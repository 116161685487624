
import { currentApi } from "../../../api/axios-client-api";
import { ContractResponse } from './types';
import qs from 'qs'

const api = currentApi

export const PutContract = async (id: string, data: ContractResponse): Promise<any> => {
  const serviceUrl = `contracts/${id}`;
  return api.Put(serviceUrl, data);
}

export const GetAllContracts = async (page = 1, pageSize = 50, query = {}): Promise<any> => {
  let queryParse = qs.stringify({...query, page, pageSize })
  const serviceUrl = `contracts?${queryParse}`;
  return api.Get(serviceUrl);
}

export const GetContractById = async (id: string): Promise<any> => {
  const serviceUrl = `contracts/${id}`;
  return api.Get(serviceUrl);
}
