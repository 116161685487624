import React from 'react';
import PropTypes from 'prop-types'; 
//import { InputGroup, FormGroup} from 'react-bootstrap';


const InputBool = ({value, onChange, disabled, ...options}) => {

    return (
        <div disabled={disabled} className="form-control form-control" >
                <label className="radio-inline">Sim</label> 
                    <input  type="radio"  
                            className="radio-inline"  
                            disabled={disabled} 
                            checked={value===true || value ==='true'}
                            onChange={() => onChange(true)} />
                <label className="radio-inline">Não</label>
                    <input  type="radio" 
                            className="radio-inline"  
                            disabled={disabled} 
                            checked={value===false || value ==='false'} 
                            onChange={() => onChange(false)} />
        </div>
    );
};

InputBool.propTypes = {
  domId: PropTypes.string,
  value: PropTypes.bool,
  original: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  notifyInputChanges: PropTypes.func
};

export default InputBool;

