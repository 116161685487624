import { ScreeningTMA } from '../../pages/statistics/screening-stats/screening-tma';
import { PendencyDocuments } from '../../pages/statistics/screening-stats/pendency-documents';
import { ScreeningCausesOfPendency } from '../../pages/statistics/screening-stats/screening-causes-of-pendency';
import { ScreeningDocumentsOverwrite } from '../../pages/statistics/screening-stats/Containers/OverwriteDocuments/index';
import { ScreeningReport } from '../../pages/statistics/screening-stats/Containers/ScreeningReports/index';
import { PrioritizedBids } from '../../pages/statistics/screening-stats/Containers/PrioritizedBids';
import { ReportEditionApplication } from '../../pages/statistics/EditionApplicationReports';
import { UserDocumentsReport } from '../../../Containers/Screening/UserDocumentsReport';

export function ScreeningStats({ ns, activeKey, toggleBlocking, ...props }) {
  switch (activeKey) {
    case "tma":
      return <ScreeningTMA ns={ns} toggleBlocking={toggleBlocking} />
    case "ocr_analytics":
      return <UserDocumentsReport ns={ns} api={props.api} />
    case "pendency":
      return <PendencyDocuments ns={ns} toggleBlocking={toggleBlocking} />
    case "causes_of_pendency":
      return <ScreeningCausesOfPendency ns={ns} toggleBlocking={toggleBlocking} />
    case "overwrite_documents":
      return <ScreeningDocumentsOverwrite ns={ns} toggleBlocking={toggleBlocking} />
    case "prioritized_bids":
      return <PrioritizedBids ns={ns} toggleBlocking={toggleBlocking} />
    case "screening":
      return <ScreeningReport ns={ns} toggleBlocking={toggleBlocking} api={props.api}/>
    case "edition_application":
      return <ReportEditionApplication ns={ns} toggleBlocking={toggleBlocking} />
    default:
      return null
  }
}




