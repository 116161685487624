import { useState, useEffect } from 'react'
import clone from "lodash/cloneDeep"
import get from 'lodash/get';

import { FzRow, FzCol, FzSplitLine } from '../../../ui/fz/layout';
import { FzCard } from '../../../ui/fz/layout/FzCard';
import { FzTable } from '../../../ui/fz/grid/table';
import { FzNavSelect } from '../../../ui/fz/nav'
import { StatsDetailHeader } from '../../../statistics/stats-components';
import { hsReportSummariesMeta, statusMeta } from './metadata';
import { FzToolbarCustom } from '../../../components/FzToolbarCustom';



function buildTotalGlobalStatus(resultStatus: any) {
  return Object.keys(resultStatus.suppliers).reduce((acc, current) => {
    return {
      ["totalGlobalStatus_InAnalysis"]: acc.totalGlobalStatus_InAnalysis + resultStatus.suppliers[current].status.InAnalysis,
      ["totalGlobalStatus_pending"]: acc.totalGlobalStatus_pending + resultStatus.suppliers[current].status.pending,
      ["totalGlobalStatus_rejected"]: acc.totalGlobalStatus_rejected + resultStatus.suppliers[current].status.rejected,
      ["totalGlobalStatus_validated"]: acc.totalGlobalStatus_validated + resultStatus.suppliers[current].status.validated,
      ["totalGlobalStatus_Protocoled"]: acc.totalGlobalStatus_Protocoled + resultStatus.suppliers[current].status.Protocoled,
      ["totalGlobalStatus"]: resultStatus.totalGlobalStatus
    };
  }, {
    "totalGlobalStatus_InAnalysis": 0,
    "totalGlobalStatus_pending": 0,
    "totalGlobalStatus_rejected": 0,
    "totalGlobalStatus_validated": 0,
    "totalGlobalStatus_Protocoled": 0,
    "totalGlobalStatus": 0
  });
}


function buildDataForStatusReportHS(resultStatus: any) {
  const totalGlobalStatusData = buildTotalGlobalStatus(resultStatus);

  const allStatusBySupplier = Object.keys(resultStatus.suppliers).reduce((acc, supplier) => {
    const statusBySupplier = Object.keys(resultStatus.suppliers[supplier].status).reduce((accumulador, status) => {
      return {
        ...accumulador,
        [`${supplier}_${status}`]: resultStatus.suppliers[supplier]["status"][status],
        [`${supplier}_totalStatus`]: resultStatus.suppliers[supplier]["totalStatus"]
      };
    }, {});

    return {
      ...acc,
      ...statusBySupplier,
      totalGlobalStatus: resultStatus.totalGlobalStatus,
      status: "Resultado"
    };
  }, {});

  return {
    ...allStatusBySupplier,
    ...totalGlobalStatusData
  };
}



function buildMetaForReportStatusHS(supplierList: string[]) {
  const clonedStatusMeta = clone(statusMeta)
  const metas = supplierList.reduce((acc, current) => {
    let templete = clone(get(clonedStatusMeta, 'templete'));

    templete.tableProps.spanColumns.columns = templete.tableProps.spanColumns.columns.map((it) => {

      if (it.hasDynamicPath) {
        if (it.formatExtraData) {
          return {
            ...it,
            path: `${current}_${it.path}`,
            formatExtraData: `${current}_totalStatus`
          };
        } else {
          return {
            ...it,
            path: `${current}_${it.path}`,
          };
        }
      }

      return it;
    });

    const meta = {
      ...templete,
      path: `suppliers.${current}`,
      hidden: false,
    };
    meta.tableProps.spanColumns.columns[0].label = current;

    return { ...acc, [`suppliers.${current}`]: meta };
  }, {});

  delete clonedStatusMeta.templete;
  return {
    ...clonedStatusMeta,
    ...metas
  };
}

function buildMetaForReportReasonsForPendingAndRejectionInHS(supplierList: string[]) {
  const clonedHSReportSummariesMeta = clone(hsReportSummariesMeta);
  const metas = supplierList.reduce((acc, current) => {

    let templete = clone(get(clonedHSReportSummariesMeta, 'suppliers'));

    const meta = {
      ...templete,
      path: `suppliers.${current}`,
      hidden: false,
      label: current
    };
    meta.tableProps.spanColumns.columns[0].label = current;

    return { ...acc, [`suppliers.${current}`]: meta };
  }, {});

  delete clonedHSReportSummariesMeta.suppliers;
  return {
    ...clonedHSReportSummariesMeta,
    ...metas
  };
}

const Summaries = ({ ns, data, handleSearchReport, handlePeriodSummary }) => {

  const [selectIn, setSelect] = useState("")
  const [exibitionSelect, setExibitionSelect] = useState("Daily")
  const [reasonsResult, setReasonsResult] = useState([])
  const [statusOfDocsResult, setStatusOfDocsResult] = useState<{ [key: string]: number }[]>([])
  const [suppliersColumns, setSuppliersColumns] = useState<string[]>([])
  const [tableReasons, setTableReasons] = useState<boolean>(false)

  useEffect(() => {
    getData(data)
  }, [data.selectedPeriod])


  function handleFilterDates() {
    const resultReasons = data.reasons.length > 0 ? data.reasons.filter(registro => registro.period === data.selectedPeriod)[0] : [];
    const resultStatus = data.status.length > 0 ? data.status.filter(registro => registro.period === data.selectedPeriod)[0] : [];
    const suppliersListStatus = Object.keys(resultStatus.suppliers);
    const supplierListReasons = resultReasons?.suppliersList ? resultReasons.suppliersList : [];

    return { resultReasons, resultStatus, suppliersListStatus, supplierListReasons }
  }


  const handleChangeSelect = (period) => {
    setSelect(period);

    switch (period) {
      case "day":
        setExibitionSelect("Daily")
        handlePeriodSummary("", true)
        break;
      case "week":
        setExibitionSelect("Weekly")
        handlePeriodSummary("", true)
        break;
      case "month":
        setExibitionSelect("Monthly")
        handlePeriodSummary("", true)
        break;
    }
    setStatusOfDocsResult([])
    setReasonsResult([])
    handleSearchReport({ period })
  }

  function handleChangePeriod(event) {
    handlePeriodSummary(event)
  }

  function getData(data) {
    setTableReasons(false)
    
    if (data && data.selectedPeriod) {
      let { resultReasons, resultStatus } = handleFilterDates()

      if (resultStatus?.suppliers) {
        const data = buildDataForStatusReportHS(resultStatus);
        setStatusOfDocsResult([data])
      }

      if (resultReasons?.reasons) {
        setTableReasons(true)
        setReasonsResult(resultReasons.reasons)
      }
    }
  }

  let customMetaForReportReasonsForPendingAndRejectionInHS: any = hsReportSummariesMeta
  let customMetaForReportStatusHS: any = statusMeta
  let customMetaForFzTableCustomToolBar = {}

  if (data && data.selectedPeriod) {
    let { suppliersListStatus, supplierListReasons } = handleFilterDates()
    const supplierList = supplierListReasons ? supplierListReasons : suppliersListStatus

    customMetaForReportReasonsForPendingAndRejectionInHS = buildMetaForReportReasonsForPendingAndRejectionInHS(supplierList);
    customMetaForReportStatusHS = buildMetaForReportStatusHS(supplierList);
    const list = supplierList.map(supplierName => `suppliers.${supplierName}`)
    if (list.length !== suppliersColumns.length) {
      setSuppliersColumns(list)
    }

    customMetaForFzTableCustomToolBar = supplierList.reduce((acc, currentSupplier) => {
      const meta = get(customMetaForReportReasonsForPendingAndRejectionInHS, `suppliers.${currentSupplier}`)
      return { ...acc, [`suppliers.${currentSupplier}`]: meta }
    }, {})
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <FzCard>
        <FzCard.Title>
          <FzCard.Header className='w-100'>
            <FzSplitLine>
              <FzSplitLine.Left>
              </FzSplitLine.Left>
              <FzSplitLine.Right>
                <FzNavSelect
                  selected={selectIn}
                  onChange={handleChangeSelect}
                  options={[
                    { "code": "day", "description": "Daily" },
                    { "code": "week", "description": "Weekly" },
                    { "code": "month", "description": "Monthly" },
                  ]}
                />
              </FzSplitLine.Right>
            </FzSplitLine>
            <FzRow>
              <FzCol span={12}>
                <StatsDetailHeader
                  title={`Summaries H2 ${exibitionSelect}`}
                  stats={data}
                  periods={data.periods}
                  currPeriod={data.selectedPeriod}
                  onChange={handleChangePeriod}
                />
              </FzCol>
            </FzRow>
          </FzCard.Header>
        </FzCard.Title>
        <FzCard.Body>
          <div className={'mt-3'}>

            <FzToolbarCustom
              keyToolBar={data.selectedPeriod}
              context={'hs'}
              data={undefined}
              ns={ns}
              metas={customMetaForFzTableCustomToolBar}
              columnsPaths={ns.getShared("DynamicTable.hs") || [...suppliersColumns]}
              exportDownload={false}
              clipboard={false}
              selectColumns={true}
            />

            <FzTable
              data={statusOfDocsResult}
              metas={customMetaForReportStatusHS}
              ns={ns}
              visibleColumns={["status", ...suppliersColumns, "totalGlobalStatus"]}
              exportDownload={false}
              clipboard={false}
              context={'hs'}
              selectColumns={false}
              hasSubsetVisibleColumns={true}
            />

            { tableReasons ? 
              (
                <FzTable
                  data={reasonsResult}
                  metas={customMetaForReportReasonsForPendingAndRejectionInHS}
                  pagination={false}
                  ns={ns}
                  visibleColumns={["description", ...suppliersColumns, "totalReason"]}
                  exportDownload={false}
                  clipboard={false}
                  context={'hs'}
                  selectColumns={false}
                  hasSubsetVisibleColumns={true}
                />
              ) : (null)
            }
            
          </div>
        </FzCard.Body>
      </FzCard>

    </div>
  )
}

export { Summaries }

