import React, { Component } from 'react';
import { StatsDetailHeader } from '../../../../../statistics/stats-components';
import { funnelSummary, pivotMeta } from '../../../../../biz/metadatas/funnel'
import { FzCard } from '../../../../fz/layout/index';
import { FzPivotTable } from '../../../../fz/grid/pivot-table'

class FunnelOpeOverview extends Component {
  constructor(props) {
    super(props)
    this.state = { currProduct: "CP", };
  }

  render() {
    const { ns, filterColumnName, filterValue, data, pivotColumnName } = this.props;

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title> <StatsDetailHeader stats={data} title="Funnel Operational Overview" /> </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzCard>
            <FzCard.Body>
              <FzPivotTable
                data={data.statistics || []}
                ns={ns}
                metas={funnelSummary}
                pivotMetas={pivotMeta}
                context={"funnelOpeOverview_" + this.state.currProduct}
                filterColumnName={filterColumnName}
                filterValue={filterValue}
                pivotColumnName={pivotColumnName}
              />
            </FzCard.Body>
          </FzCard>
        </FzCard.Body>
      </FzCard>
    )
  }
}

export { FunnelOpeOverview }